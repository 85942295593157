import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";

import { getSplicedFrameTypes } from "libs/getSplicedFrameData";

import { EDITOR_MODES } from "../constants";

const Wrapper = styled.div`
  border-bottom: 1px solid #999;
  color: #fff;
  margin-top: 25px;
  padding: 0 20px 10px;
`;

// TODO: Why is this an H4?
const Heading = styled.h4`
  color: #ffcf33;
  margin-bottom: 5px;
`;
export default function FrameTemplate({
  canEdit,
  frameTemplate,
  manufacturerName = "",
  onSelectFrameTemplate,
}) {
  const {
    totalHeight,
    sleeveHeight,
    sleeveSpacer,
    sacLegHeight,
    sleeve,
    sleeveHeightRear,
    sleeveSpacerRear,
    sacLegHeightRear,
    sleeveRear,
  } = frameTemplate;
  let frontHeight = totalHeight + sleeveHeight + sleeveSpacer;
  let rearHeight = totalHeight + sleeveHeightRear + sleeveSpacerRear;
  if (!sleeve) {
    frontHeight += sacLegHeight;
  }
  if (!sleeveRear) {
    rearHeight += sacLegHeightRear;
  }
  /** if there area rear splices, determine the height based on the highest front or rear frame */
  const height = rearHeight > frontHeight ? rearHeight : frontHeight;

  return (
    <Wrapper>
      <Heading>{frameTemplate.name}</Heading>
      <Row>
        <Col className="item-information" sm={7}>
          <b>Manufacturer:</b> {manufacturerName || "N/A"}
          <br />
          <b>Series:</b> {frameTemplate.SeriesName || "N/A"}
          <br />
          <b>Height:</b> {height}
          mm
          <br />
          <b>Frame Width:</b> {frameTemplate.type.width}
          mm
          <br />
          <b>Frame Depth:</b> {frameTemplate.depth}
          mm
          <br />
          <b>Duty:</b> {getSplicedFrameTypes(frameTemplate)}
          <br />
        </Col>
        {canEdit && (
          <Col sm={5}>
            <ButtonGroup className="pull-right">
              <Button
                className="text-btn"
                onClick={() =>
                  onSelectFrameTemplate(frameTemplate.id, EDITOR_MODES.edit)
                }
              >
                Edit Frame
              </Button>
              <Button
                className="text-btn"
                onClick={() =>
                  onSelectFrameTemplate(frameTemplate.id, EDITOR_MODES.copy)
                }
              >
                Copy Frame
              </Button>
            </ButtonGroup>
          </Col>
        )}
      </Row>
    </Wrapper>
  );
}

FrameTemplate.propTypes = {
  manufacturerName: PropTypes.string,
  canEdit: PropTypes.bool,
  frameTemplate: PropTypes.shape({
    depth: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    sacLegHeight: PropTypes.number,
    SeriesName: PropTypes.string,
    sleeve: PropTypes.bool,
    sleeveHeight: PropTypes.number,
    sleeveSpacer: PropTypes.number,
    totalHeight: PropTypes.number,
    type: PropTypes.shape({ width: PropTypes.string }),
    sleeveHeightRear: PropTypes.number,
    sleeveSpacerRear: PropTypes.number,
    sacLegHeightRear: PropTypes.number,
    sleeveRear: PropTypes.bool,
  }),
  onSelectFrameTemplate: PropTypes.func,
};
