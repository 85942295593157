import useSWR from 'swr';

import { getFrameTemplates, getFrameTemplatesDepths } from 'libs/api';

export function useFrameTemplates(siteId) {
  const { data, error, mutate, isLoading } = useSWR(
    siteId ? `/template/frames/${siteId}` : null,
    async () => getFrameTemplates(siteId),
  );

  return {
    frameTemplates: data,
    isLoading,
    isError: error,
    mutate,
  };
}

export function useFrameTemplatesDepths(ManufacturerId, SiteId, depth) {
  const { data, error, mutate, isLoading } = useSWR(
    ManufacturerId && SiteId && depth
      ? `/template/frame/manufacturer/${ManufacturerId}/${SiteId}/${depth}`
      : null,
    async () => getFrameTemplatesDepths(SiteId, ManufacturerId, depth),
  );

  return {
    frameTemplates: data ?? [],
    isError: error,
    isLoading,
    mutate,
  };
}
