/** @jsxImportSource @emotion/react */

import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";

import { useCurrentUser } from "hooks/user";
import { PRIMARY_DATE_FORMAT } from "libs/constants";

import InspectionActionButtons from "views/components/ActionButtons/set-inspection";
import StatusIndicator from "views/components/StatusIndicator";

export function PaddingRow({
  showClient,
  showSite,
  showInspector,
  showCompleted,
}) {
  return (
    <tr>
      {showClient ? <td /> : null}
      {showSite ? <td /> : null}
      <td />
      <td />
      {showInspector ? <td /> : null}
      <td />
      {showCompleted ? <td /> : null}
    </tr>
  );
}

PaddingRow.propTypes = {
  showClient: PropTypes.bool,
  showSite: PropTypes.bool,
  showCompleted: PropTypes.bool,
  showInspector: PropTypes.bool,
};

export default function InspectionRow({
  inspection,
  showClient = false,
  showSite = false,
  showCompleted = false,
  showInspector = true,
}) {
  const [showButtons, setShowButtons] = useState(false);
  const { user } = useCurrentUser();
  if (!inspection) return null;
  return (
    <tr
      onMouseDown={() => setShowButtons(true)}
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      {showClient ? (
        <td>{inspection?.ClientName ?? inspection?.client?.name}</td>
      ) : null}
      {showSite ? (
        <td>{inspection?.SiteName ?? inspection?.site?.name}</td>
      ) : null}
      <td>{inspection.reference}</td>
      {showInspector && (
        <td>
          {inspection.inspectors
            ? inspection.inspectors
                .map((inspector) => inspector.name)
                .join(", ")
            : "-"}
        </td>
      )}
      <td>
        {
          <StatusIndicator
            status={
              // eslint-disable-next-line no-nested-ternary
              inspection.completedAt
                ? inspection.preparedAt
                  ? "Complete"
                  : "Prepare"
                : "Incomplete"
            }
          />
        }
      </td>
      <td>
        {inspection.completedAt
          ? moment(inspection.completedAt).format(PRIMARY_DATE_FORMAT)
          : "-"}
      </td>
      {showCompleted && (
        <td>
          {inspection.createdAt
            ? moment(inspection.createdAt).format(PRIMARY_DATE_FORMAT)
            : "-"}
        </td>
      )}
      {showButtons && (
        <InspectionActionButtons
          UserId={user.id}
          condensed
          inspection={inspection}
        />
      )}
    </tr>
  );
}

InspectionRow.propTypes = {
  inspection: PropTypes.shape({
    ClientName: PropTypes.string,
    SiteName: PropTypes.string,
    id: PropTypes.string,
    reference: PropTypes.string,
    completedAt: PropTypes.string,
    createdAt: PropTypes.string,
    preparedAt: PropTypes.string,
    inspectors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    site: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  showClient: PropTypes.bool,
  showSite: PropTypes.bool,
  showCompleted: PropTypes.bool,
  showInspector: PropTypes.bool,
};
