/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { Checkbox } from "react-bootstrap";

export default function StatusOption({ title, options, changeFunc }) {
  const [expanded, setExpanded] = useState(false);
  const [status, setStatus] = useState(null);

  const onHandleCheckboxChange = useCallback(
    (checked, option) => {
      if (checked) {
        changeFunc(option);
        setStatus(option);
      }
    },
    [changeFunc],
  );

  return (
    <div
      css={css`
        padding: 10px;
        padding-bottom: 5px;
        color: #6c6c6c;
        .form-control {
          background-color: #f2f2f4 !important;
          color: #9b9b9c !important;
          border-radius: 5px;
          margin-bottom: 5px;
          margin-top: 10px;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
        }
      `}
    >
      <div onClick={() => setExpanded(!expanded)}>
        {title}
        <span
          css={css`
            float: right;
          `}
        >
          <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
        </span>
      </div>
      {expanded && (
        <div>
          {options.map((option) => (
            <Checkbox
              key={option}
              onChange={(e) =>
                onHandleCheckboxChange(e.target.checked, option, changeFunc)
              }
              value={option === status}
            >
              {option}
            </Checkbox>
          ))}
        </div>
      )}
    </div>
  );
}

StatusOption.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeFunc: PropTypes.func.isRequired,
};
