/* eslint-disable react/jsx-props-no-spreading */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faBars,
  faCheckCircle,
  faPlus,
  faTimesCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import "react-quill/dist/quill.snow.css";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module-react";
import { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Table, Image, FormControl } from "react-bootstrap";
import ReactQuill, { Quill } from "react-quill";
import { toast } from "react-toastify";

import {
  getInspectionReport,
  getReport,
  editReport,
  completeReport,
  getMaterials,
} from "libs/api";
import { GREEN_COLOUR, RED_COLOUR } from "libs/constants";
import downloadPDF from "libs/downloadPDF";
import getOptimisedImageUrl from "libs/getOptimisedImageUrl";

import WithCompatWrap from "libs/with-compact-wrap";
import FilterChip from "views/components/FilterChip";
import Loading from "views/components/Loading";
import ConfirmModal from "views/components/Modals/ConfirmModal";
import ImageLightBox from "views/components/Modals/ImageLightBox";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

const defaultSections = [
  {
    title: "Pallet Racking System",
    subsections: [],
  },
  {
    id: "guidelines",
    title: "Guidelines",
    subsections: [],
  },
  {
    title: "General Inspection",
    subsections: [],
  },
  {
    title: "Repairs from Previous Report",
    subsections: [],
  },
  {
    title: "Observations and Recommendations",
    subsections: [],
  },
];

class InspectionEditor extends Component {
  state = {
    currentImage: null,
    deletedSections: [],
    showDeleteSectionId: false,
    showDeleteSubSectionId: false,
    materials: [],
    isLoading: true,
    appendixRemoved: false,
    bottomSections: ["Appendix"],
    prepopulatedSections: ["Guidelines"],
    appendixSection: [
      {
        title: "Appendix",
        subsections: [],
      },
    ],
    fixedSections: ["Damage Report", "Inspection Images", "Bill of Materials"],
  };

  modules = {
    imageDrop: true,
    imageResize: {
      parchment: Quill.import("parchment"),
      toolbarStyles: {
        display: "none",
      },
    },
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }],
      ["image"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["clean"],
    ],
  };

  componentDidMount() {
    this.init();
  }

  async onSaveReport() {
    const { report, deletedSections } = this.state;
    this.setState({ saving: true });
    deletedSections.forEach((id) =>
      report.sections.push({ id, deleted: true }),
    );
    report.sections.forEach((section) => {
      // eslint-disable-next-line no-unused-expressions
      section.deletedSubsections &&
        section.deletedSubsections.forEach((id) =>
          section.subsections.push({ id, deleted: true }),
        );
    });
    try {
      await editReport(report);
      toast.success("Saved");
      this.getData();
    } catch (e) {
      toast.error("Unable to save");
    }
    this.setState({ saving: false });
  }

  onReorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { report } = this.state;
    const { sections } = report;

    report.sections = this.onReorder(
      sections,
      result.source.index,
      result.destination.index,
    );

    this.setState({ report });
  }

  onDragEndSub(result, subsections, onChangeSubsectionOrder) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    result = this.onReorder(
      subsections,
      result.source.index,
      result.destination.index,
    );

    onChangeSubsectionOrder(result);
  }

  async onCompleteReport() {
    await this.onSaveReport();
    const {
      history,
      match: {
        params: { InspectionId },
      },
    } = this.props;
    const res = await completeReport({ InspectionId });
    if (res.ok) {
      history.push("/inspections");
    }
  }

  async onDownloadPDF() {
    const { inspection } = this.state;
    const toastId = toast.info("Exporting report", {
      closeButton: false,
      autoClose: false,
    });
    await downloadPDF(inspection.id, inspection, !inspection.preparedAt);
    toast.update(toastId, {
      render: "Report exported",
      type: toast.TYPE.SUCCESS,
      autoClose: 4000,
    });
  }

  setDefaultSections() {
    const { match } = this.props;
    const { params } = match;
    const { InspectionId } = params;
    const { report } = this.state;
    let { deletedSections } = this.state;
    if (report.sections) {
      deletedSections = deletedSections.concat(
        report.sections
          .filter((section) => section.id !== undefined)
          .map((section) => section.id),
      );
    }
    report.sections = [];
    defaultSections.forEach((section) => {
      report.sections.push({
        InspectionId,
        title: section.title,
        content: "",
        subsections: section.subsections.map((subsection) => ({
          title: subsection.title,
          content: "",
        })),
      });
    });
    this.setState({ report, deletedSections }, () => {
      toast("Setting Report to Default", {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        type: toast.TYPE.INFO,
        autoClose: 3000,
        pauseOnHover: false,
        bottomSections: ["Appendix"],
      });
    });
  }

  setAppendixSections(appendixState = true) {
    const { match } = this.props;
    const { params } = match;
    const { InspectionId } = params;
    const { report, appendixSection, fixedSections } = this.state;
    const reportContainsAppendix = report.sections.find(
      (curSection) => curSection.title === "Appendix",
    );
    const updatedReport = { ...report };
    if (!reportContainsAppendix) {
      appendixSection.forEach((section) => {
        updatedReport.sections.push({
          InspectionId,
          title: section.title,
          content: "",
          order: fixedSections.length + updatedReport.sections.length,
          subsections: [],
        });
      });
      this.setState({
        report: updatedReport,
        bottomSections: ["Appendix"],
        appendixRemoved: !appendixState,
      });
    }
  }

  setClearSections() {
    const { report } = this.state;
    let deletedSections = [];
    if (report.sections) {
      deletedSections = report.sections
        .filter((section) => section.id !== undefined)
        .map((section) => section.id);
    }
    report.sections = [];
    this.setState({ report, deletedSections }, () => {
      toast("Clearing Sections", {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        type: toast.TYPE.INFO,
        autoClose: 3000,
        pauseOnHover: false,
      });
    });
    this.deleteAppendixSection();
  }

  async getData() {
    const { match } = this.props;
    const { appendixRemoved } = this.state;
    const { params } = match;
    const { InspectionId } = params;
    const inspection = await getInspectionReport(InspectionId);
    const materials = await getMaterials(InspectionId);
    const report = await getReport(InspectionId);
    this.setState(
      {
        inspection,
        report,
        materials,
        isLoading: false,
      },
      () => {
        if (!report.sections.length) {
          this.setDefaultSections();
          this.setAppendixSections();
          this.onSaveReport();
        }
        if (!appendixRemoved) {
          this.setAppendixSections();
        }
      },
    );
  }

  async init() {
    this.getData();
  }

  deleteAppendixSection() {
    const { report } = this.state;
    const { sections } = report;
    const updatedSections = sections.filter(
      (curSection) => curSection.title !== "Appendix",
    );
    const updatedReport = { ...report, sections: updatedSections };
    this.setState({
      report: updatedReport,
      bottomSections: [],
      appendixRemoved: true,
    });
  }

  renderPrepopulatedSubsectionHeader(index, subsectionIndex, title) {
    return (
      <div>
        <div className="anchor" id={`${index + 1}.${subsectionIndex}`} />
        <div className="heading">
          <span className="section-number">{`${
            index + 1
          }.${subsectionIndex}`}</span>
          <FormControl placeholder="Title" value={title} />
        </div>
      </div>
    );
  }

  renderGuidelines(index) {
    return (
      <div className="body">
        <div className="subsection">
          {this.renderPrepopulatedSubsectionHeader(index, 1, "Risk Categories")}
          <div className="body-prepopulated">
            The inspection was carried out generally in accordance with SEMA
            “Guide to Conduct of Pallet Racking and Shelving Surveys”. The
            extent of the damage recommended in the SEMA guide categorising such
            damage as
            <span className="risk-red"> RED RISK,</span>
            <span className="risk-amber"> AMBER RISK</span> or
            <span className="risk-green"> GREEN RISK </span>
            has been used. For information, the definition of the categories are
            given below:
            <br />
            <br />
            <span className="risk-red">
              Red Risk – Very serious damage requiring immediate action
            </span>
            <br />
            These are situations where a critical level of damage is identified
            which warrants an area of racking being immediately offloaded and
            isolated from future use until repair work is carried out. Such
            repair work would usually be by replacement of the damaged
            component. You should have a method of isolating areas to ensure
            that they do not come back into use before the repair work is
            carried out. For example, a particular bay could be offloaded in the
            presence of the inspector and roped off to prevent further use.
            <br />
            <br />
            <span className="risk-amber">
              Amber Risk – Hazardous damage requiring action as soon as possible
            </span>
            <br />
            This would identify an area where the damage was sufficiently severe
            to warrant remedial work but not so severe as to warrant the
            immediate offloading of the rack. Once the load is removed from a
            damaged component, the component should not be reloaded until
            repairs have been carried out. The user should have a method of
            isolating such racks to ensure that they do not come back into use
            until the necessary repairs have been carried out and the equipment
            certified as safe. For example, use dated adhesive labels, which
            indicate racks that are not to be reloaded until rectified. Any
            racking with amber risk category damage should be re-designated red
            risk if remedial work has not been carried out within four weeks of
            the original designation.
            <br />
            <br />
            <span className="risk-green">
              Green Risk – Requiring surveillance only
            </span>
            <br />
            Green level risk indicates that the limit does not require a
            reduction in rack carrying capacity or an immediate repair of the
            system. This would indicate racking components that are considered
            to be safe and serviceable. Such components should be recorded as
            suitable for further service until the next management inspection
            but should be clearly identified for specific re-examination and
            reassessment at future inspections. Exceeding the green level should
            be considered damage and causes risk to the racking system.
            <br />
            <br />
            <span className="black-text bold">
              To ensure a safe structure, all Amber and Red Risk Categories
              should be implemented within their prescribed time.
            </span>
            limits
          </div>
        </div>
        <div className="subsection">
          {this.renderPrepopulatedSubsectionHeader(index, 2, "Damage Criteria")}
          <div className="body-prepopulated">
            <p>
              <span className="black-text bold">
                Assessment of damage to uprights
              </span>
            </p>
            <ol>
              <li>
                A straight edge, 1,000mm in length, should be placed in contact
                with a flat surface on the concave side of the damaged member,
                such that the damaged area lies centrally, as near as possible,
                to the length of the straight edge.
              </li>
              <li>
                For an upright bent in the direction of the racking beams, the
                maximum gap between the uprights and the straight edge should
                not exceed 5.0mm.
              </li>
              <li>
                For an upright bent in the plane of the frame bracing, the
                maximum gap between the upright and the straight edge should not
                exceed 3.0mm.
              </li>
              <li>
                For an upright which has been damaged such that there is a
                simultaneous bend in both longitudinal and lateral directions,
                the deformation should be measured separately, as noted in 2 and
                3, and the appropriate observed.
              </li>
            </ol>
            <p>
              These rules only apply to damage which produces an overall bend in
              a member. They do not apply to highly localised damage such as
              dents, buckles, tears and splits. Members subjected to these
              defects should be replaced.
            </p>
            <br />
            <p>
              <span className="black-text bold">
                Assessment of damage to bracing
              </span>
            </p>
            <p>
              Bracing should be inspected using the same method as measuring
              upright damage. For bracing members bent in either plane, the gap
              between the 1,000mm straight edge and the bracing member should
              not exceed 10mm.
            </p>
            <br />
            <div className="image-container">
              <img
                alt="bracing-damage"
                src="https://econform-production.s3.eu-west-2.amazonaws.com/img/pdf/bracing-damage.png"
                width="50%"
              />
            </div>
            <br />
            <br />
            <p>
              <span className="black-text bold">
                Evaluation of Pallet Racking Frames
              </span>
            </p>
            <br />
            <table className="table">
              <tr>
                <th className="column"></th>
                <th className="column-header green">GREEN</th>
                <th className="column-header amber">AMBER</th>
                <th className="column-header red">RED</th>
              </tr>
              <tr>
                <td>Uprights: Down-aisle</td>
                <td className="table-cell">&#60; 5mm</td>
                <td className="table-cell">5mm to 10mm</td>
                <td className="table-cell">&#62; 10mm</td>
              </tr>
              <tr>
                <td>Uprights: Cross-aisle</td>
                <td className="table-cell">&#60; 3mm</td>
                <td className="table-cell">3mm to 6mm</td>
                <td className="table-cell">&#62; 6mm</td>
              </tr>
              <tr className="row-spacer">
                <td colSpan="4"></td>
              </tr>
              <tr>
                <td>Bracings</td>
                <td className="table-cell">&#60; 10mm</td>
                <td className="table-cell">10mm to 20mm</td>
                <td className="table-cell">&#62; 20mm</td>
              </tr>
            </table>
            <p>
              <span className="black-text bold">
                Assessment of damage to beams
              </span>
            </p>
            <p>
              Beams will deflect under loading to a maximum permissible
              deflection of span/200. Damage should be measured against the
              following criteria.
            </p>
            <br />
            <ol>
              <li>
                Residual vertical deformation when the beam is unloaded should
                not exceed span/1000.
              </li>
              <li>
                Residual lateral deformation in the cross aisle direction when
                the beam is unloaded should not exceed span/500.
              </li>
              <li>
                Beam and end connectors which show any clearly visible
                deformation should be unloaded and expert advice sought from the
                supplier.
              </li>
              <li>
                Welded connections between the beam section and the end
                connector should not show any signs of cracking.
              </li>
            </ol>
            <br />
            <p>
              Any missing locking pins should be replaced immediately to prevent
              the accidental dislodging of the beams. It is recommended that a
              supply of beam locking pins is held on site.
            </p>
            <br />
            <br />
            <br />
            <div className="image-container">
              <img
                alt="beam-damage"
                src="https://econform-production.s3.eu-west-2.amazonaws.com/img/pdf/beam-damage.png"
                width="80%"
              />
            </div>
            <br />
            <br />
            <br />
            <p>
              <span className="black-text bold">
                Evaluation of Pallet Racking Beams
              </span>
            </p>
            <br />
            <table className="table">
              <tr>
                <th className="column"></th>
                <th className="column-header green">GREEN</th>
                <th className="column-header amber">AMBER</th>
                <th className="column-header red">RED</th>
              </tr>
              <tr>
                <td>Vertical Deflection</td>
                <td className="table-cell">&#60; Span/1000</td>
                <td className="table-cell">Span/1000 to Span/500</td>
                <td className="table-cell">&#62; Span/500</td>
              </tr>
              <tr>
                <td>Lateral Deformation</td>
                <td className="table-cell">&#60; Span/500</td>
                <td className="table-cell">Span/500 to Span/250</td>
                <td className="table-cell">&#62; Span/250</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }

  renderPrepopulatedSection(section, index) {
    const { title } = section;
    switch (title) {
      case "Guidelines":
        return this.renderGuidelines(index);
      default:
        return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderSection(
    section,
    index,
    onChangeTitle = false,
    onChangeContents = false,
    onDeleteSection,
    onChangeSubTitle,
    onChangeSubContents,
    onDeleteSubsection,
  ) {
    const {
      report,
      deletedSections,
      showDeleteSectionId,
      bottomSections,
      prepopulatedSections,
    } = this.state;
    const { sections } = report;
    const { title, content } = section;
    const sectionIsDeleted = deletedSections.includes(section.id);
    const isBottomSection = bottomSections.includes(section.title);
    const i = index + 0;
    const sectionIsPrepopulated = prepopulatedSections.includes(section.title);
    return (
      <div className="section">
        <div className="anchor" id={index + 1} />
        <div className="heading">
          <span className="section-number">{`${index + 1}.0`}</span>
          <FormControl
            onChange={(e) => onChangeTitle(e.target.value)}
            placeholder="Title"
            value={title}
          />
          {!sectionIsPrepopulated && (
            <span className="header-btns">
              {!isBottomSection && (
                <Button
                  className="add-section-btn"
                  onClick={() => {
                    sections.splice(index + 1, 0, {
                      id: sections.length + 1,
                      title: "",
                      content: "",
                      custom: true,
                      subsections: [],
                    });
                    this.setState({ report });
                  }}
                >
                  Add Section <FontAwesomeIcon icon={faPlus} />{" "}
                </Button>
              )}
              <span
                className={
                  isBottomSection ? "section-delete-right" : "section-delete"
                }
              >
                <Button
                  onClick={() => this.setState({ showDeleteSectionId: index })}
                >
                  {sectionIsDeleted ? (
                    <FontAwesomeIcon icon={faUndo} />
                  ) : (
                    <Image responsive src="/img/icons/trash-white.png" />
                  )}
                </Button>
              </span>
            </span>
          )}
        </div>
        {sectionIsPrepopulated ? (
          <div>{this.renderPrepopulatedSection(section, index)}</div>
        ) : (
          <span>
            <div className="body">
              <ReactQuill
                key={`${section.id}${section.title}`}
                modules={this.modules}
                onChange={(text) => onChangeContents(text)}
                value={content ?? ""}
              />
              <div className="subsection-btn">
                <Button
                  className="add-subsection-btn-large"
                  onClick={() => {
                    section.subsections.splice(0, 0, {
                      id: section.subsections.length + 1,
                      title: "",
                      content: "",
                      custom: true,
                    });
                    this.setState({ report });
                  }}
                >
                  Add Subsection <FontAwesomeIcon icon={faPlus} />{" "}
                </Button>
              </div>
              {section.subsections
                ? section.subsections
                    .filter((sect) => !sect.deleted)
                    .map((subsection, index2) =>
                      this.renderSubsection(
                        section,
                        subsection,
                        index,
                        index2,
                        onChangeSubTitle,
                        onChangeSubContents,
                        onDeleteSubsection,
                      ),
                    )
                : "No Subsections"}
            </div>
            <ConfirmModal
              onConfirm={() => {
                if (section.id) {
                  onDeleteSection(i);
                }
                if (section.title === "Appendix") {
                  this.deleteAppendixSection();
                }
                this.setState({ showDeleteSectionId: false });
              }}
              onHide={() => this.setState({ showDeleteSectionId: false })}
              show={showDeleteSectionId === index}
              title="Delete Section"
            >
              <h4>Are you sure you want to delete this section?</h4>
              <h4>
                This can not be undone, and all related data will also be
                deleted.
              </h4>
            </ConfirmModal>
          </span>
        )}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSubsection(
    section,
    subsection,
    sectionIndex,
    index,
    onChangeTitle = false,
    onChangeContents = false,
    onDeleteSection,
  ) {
    const { report, showDeleteSubSectionId } = this.state;
    const { title, content } = subsection;
    return (
      <div className="subsection">
        <div className="anchor" id={`${sectionIndex + 1}.${index + 1}`} />
        <div className="heading">
          <span className="section-number">{`${sectionIndex + 1}.${
            index + 1
          }`}</span>
          <FormControl
            disabled={subsection.deleted}
            onChange={(e) => onChangeTitle(e.target.value, index)}
            placeholder="Title"
            value={title}
          />
          <span className="header-btns">
            <Button
              className="add-subsection-btn"
              onClick={() => {
                const newSection = {
                  title: "",
                  content: "",
                };
                if (!section.subsections) {
                  section.subsections = [newSection];
                } else {
                  section.subsections.splice(index + 1, 0, newSection);
                }
                this.setState({ report });
              }}
            >
              Add Subsection <FontAwesomeIcon icon={faPlus} />{" "}
            </Button>
            <span className="section-delete">
              <Button
                onClick={() => {
                  this.setState({ showDeleteSubSectionId: subsection.id });
                }}
              >
                <Image responsive src="/img/icons/trash-white.png" />
              </Button>
            </span>
          </span>
        </div>
        {subsection.deleted || (
          <div className="body">
            <ReactQuill
              modules={this.modules}
              onChange={(text) => onChangeContents(text, index)}
              value={content ?? ""}
            />
          </div>
        )}
        <ConfirmModal
          onConfirm={() => {
            onDeleteSection(index);
            this.setState({ showDeleteSubSectionId: false });
          }}
          onHide={() => this.setState({ showDeleteSubSectionId: false })}
          show={showDeleteSubSectionId === subsection.id}
          title="Delete Subsection"
        >
          <h4>Are you sure you want to delete this subsection?</h4>
          <h4>
            This can not be undone, and all related data will also be deleted.
          </h4>
        </ConfirmModal>
      </div>
    );
  }

  renderBillOfMaterials() {
    const { materials, report, bottomSections } = this.state;
    const { sections } = report;
    const sectionNumber = sections.length + 3 - bottomSections.length;
    if (!sections) {
      return null;
    }
    return (
      <div className="section">
        <div className="anchor" id={sectionNumber} />
        <div className="heading">
          <span className="section-number">{`${sectionNumber}.0`}</span>
          <FormControl disabled placeholder="Title" value="Bill of Materials" />
        </div>
        <div className="body">
          {!materials.length && "No Materials to Display"}
          {materials.map((item) => (
            <div key={item.id}>
              <h5>
                Risk:{" "}
                <span className={`risk-${item.risk.toLowerCase()}`}>
                  {item.risk}
                </span>
              </h5>
              <div>
                {item.manufacturers.map((manufacturer) => (
                  <div key={manufacturer.id}>
                    {manufacturer.series.map((series) => (
                      <div
                        key={`Manufacturer_${manufacturer.name}_${series.name}`}
                      >
                        <h5>{`Manufacturer: ${manufacturer.name}, ${series.name}`}</h5>
                        <Table className="material-table">
                          <thead>
                            <tr>
                              <th className="other">Action</th>
                              <th className="component">Component</th>
                              <th className="other">Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {series.classifications.map((classification) => (
                              <tr key={classification.id}>
                                <td>
                                  {classification.action ||
                                    classification.miscAction}
                                </td>
                                <td>
                                  {classification.componentTitle ||
                                    classification.component ||
                                    classification.miscComponent}
                                </td>
                                <td>{classification.quantity || 1}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderImage(image, index) {
    return (
      <div
        className="damage-images"
        onClick={() => this.setState({ currentImage: index })}
      >
        <div className="header">{`Figure ${index + 1}`}</div>
        <div className="subheader">{image.imageLocation}</div>
        <Image
          alt={image.caption}
          responsive
          src={getOptimisedImageUrl(`${image.remotePath}`)}
        />
        <div className="caption">
          <FormControl
            componentClass="textarea"
            placeholder="Add Caption"
            value={image.caption}
          />
        </div>
      </div>
    );
  }

  renderImages() {
    const { inspection, report, bottomSections } = this.state;
    const { sections } = report;
    const { images } = inspection;
    if (!images) {
      return null;
    }
    const sectionNumber = sections.length + 2 - bottomSections.length;
    return (
      <div className="section">
        <div className="anchor" id={sectionNumber} />
        <div className="heading">
          <span className="section-number">{`${sectionNumber}.0`}</span>
          <FormControl disabled placeholder="Title" value="Images" />
        </div>
        <div className="body">
          {!images.length && <div>No Images to display</div>}
          {images.map((image, index) => this.renderImage(image, index))}
        </div>
      </div>
    );
  }

  renderClassification(classification) {
    return [
      <tr key={classification.id}>
        <td>{classification.WarehouseName}</td>
        <td>{classification.AreaName || "-"}</td>
        <td>{classification.RackName}</td>
        <td>{classification.location}</td>
        <td>
          {classification.beam
            ? classification.beam.level
            : classification.level || "N/A"}
        </td>
        <td className="risk">
          <span className={`risk-${classification.risk.toLowerCase()}`}>
            {classification.risk}
          </span>
        </td>
        <td>
          {classification.componentTitle ||
            classification.component ||
            classification.miscComponent}
        </td>
        <td>{classification.position || classification.miscPosition}</td>
        <td>{classification.defect || classification.miscDefect}</td>
        <td>{classification.action || classification.miscAction}</td>
        <td>{classification.quantity || 1}</td>
      </tr>,
      classification.customerReference || classification.notes ? (
        <tr>
          {!!classification.customerReference && (
            <td colSpan={classification.notes ? 3 : 11}>
              Customer ID: {classification.customerReference}
            </td>
          )}
          {!!classification.notes && (
            <td colSpan={classification.customerReference ? 8 : 11}>
              {classification.notes}
            </td>
          )}
        </tr>
      ) : (
        ""
      ),
    ];
  }

  renderPartialInspectionRacks() {
    const { inspection, report } = this.state;
    const { sections } = report;
    const { warehouses = [] } = inspection;
    const columnLimit = 9;
    return (
      <div>
        <h5>{`${sections.length + 1}.1 Inspected Rack`}</h5>
        {warehouses.map((warehouse) =>
          warehouse.areas.map((area) => {
            const result = [];
            const curColumnLimit =
              area.racks.length < columnLimit ? area.racks.length : columnLimit;
            for (let i = 0; i < area.racks.length; i += curColumnLimit) {
              const rows = [];
              rows.push(
                <div className="racks">
                  {area.racks
                    .filter(
                      (rack, index) => index >= i && index < i + curColumnLimit,
                    )
                    .map((rack) => (
                      <div
                        className="rack"
                        css={css`
                          width: calc(100% / ${curColumnLimit});
                        `}
                        key={rack.id}
                      >
                        <div className="rack-name">{rack.name}</div>
                        <div
                          className={`rack-status ${
                            rack.inspected ? "complete" : "incomplete"
                          }`}
                        >
                          {rack.inspected ? (
                            <FontAwesomeIcon icon={faCheckCircle} />
                          ) : (
                            <FontAwesomeIcon icon={faTimesCircle} />
                          )}
                        </div>
                      </div>
                    ))}
                </div>,
              );
              if (i + curColumnLimit < area.racks.length) {
                rows.push(<div className="filler" />);
              }
              result.push(rows);
            }
            return (
              <div
                css={css`
                  i {
                    font-size: 15pt;
                    padding: 5px;
                  }
                  .rack {
                    border-bottom: 1px solid black;
                    border-left: 1px solid black;
                    text-align: center;
                    display: inline-block;
                  }
                  .racks:first-of-type {
                    border-top: 1px solid black;
                  }
                  .rack:last-of-type {
                    border-right: 1px solid black;
                  }
                  .rack-status {
                    border-top: 1px solid black;
                  }
                  .complete {
                    i {
                      color: ${GREEN_COLOUR};
                    }
                  }
                  .incomplete {
                    i {
                      color: ${RED_COLOUR};
                    }
                  }
                  .filler {
                    height: 10px;
                    background-color: lightgrey;
                    border-left: 1px black solid;
                    border-right: 1px black solid;
                    border-bottom: 1px black solid;
                  }
                `}
                key={area.id}
              >
                <h5>{`${warehouse.name}: ${area.name}`}</h5>
                {result}
              </div>
            );
          }),
        )}
      </div>
    );
  }

  renderClassifications() {
    const { inspection, report, bottomSections } = this.state;
    const { sections } = report;
    const {
      groupedClassifications,
      groupedConfirmedClassifications,
      reclassifiedClassifications,
    } = inspection;
    // TODO: When we rewrite this page we should return this data in correct format from API and remove this logic
    /** groupedClassifications data structure [{ id, series: [{ id, classifications: [{ id }]}]}] */
    /** Only include reclassified damage  */
    const reclassifiedFromGrouped = groupedClassifications.map((group) => {
      const series = group.series.map((s) => {
        const classifications = s.classifications.filter((classification) =>
          reclassifiedClassifications.some(
            (reclassifiedClassification) =>
              reclassifiedClassification.ReclassifiedClassificationId ===
              classification.id,
          ),
        );
        return {
          ...s,
          classifications,
        };
      });
      return {
        ...group,
        series,
      };
    });
    /** Only include added damage  */
    const damageFromGrouped = groupedClassifications.map((group) => {
      const series = group.series.map((s) => {
        const classifications = s.classifications.filter(
          (classification) =>
            !reclassifiedClassifications.some(
              (reclassifiedClassification) =>
                reclassifiedClassification.ReclassifiedClassificationId ===
                classification.id,
            ),
        );
        return {
          ...s,
          classifications,
        };
      });
      return {
        ...group,
        series,
      };
    });
    const classificationsToShow = [
      {
        title: "Previously Reported Damage",
        classifications: groupedConfirmedClassifications,
      },
      {
        title: "Newly Reported Damage",
        classifications: damageFromGrouped,
      },
      {
        title: "Reclassified Damage",
        classifications: reclassifiedFromGrouped,
      },
    ];

    if (!groupedClassifications) {
      return null;
    }
    const sectionNumber = sections.length + 1 - bottomSections.length;
    return (
      <div className="section">
        <div className="anchor" id={sectionNumber} />
        <div className="heading">
          <span className="section-number">{`${sectionNumber}.0`}</span>
          <FormControl disabled placeholder="Title" value="Damage Report" />
        </div>
        <div className="body">
          {inspection.partial && this.renderPartialInspectionRacks()}
          {inspection.partial && <h5>{`${sectionNumber}.2 Damage`}</h5>}
          {!groupedConfirmedClassifications.length && (
            <div>
              <h4
                css={css`
                  color: #2d72ed !important;
                `}
              >
                Previously Reported Damage
              </h4>
              <h5>No Damage Reported</h5>
            </div>
          )}
          {classificationsToShow.map((c) =>
            c.classifications.map((manufacturer, idx) => (
              <div key={manufacturer.id}>
                {idx === 0 && (
                  <h4
                    css={css`
                      color: #2d72ed !important;
                    `}
                  >
                    {c.title}
                  </h4>
                )}

                {manufacturer.series.map((series) => (
                  <div key={series.id}>
                    <h5>{`Manufacturer: ${manufacturer.name}, ${series.name}`}</h5>
                    <Table>
                      <thead
                        css={css`
                          tr > .fixed-width {
                            width: 6.5vw;
                          }
                        `}
                      >
                        <tr>
                          <th className="fixed-width">Warehouse</th>
                          <th className="fixed-width">Area</th>
                          <th className="fixed-width">Rack</th>
                          <th className="fixed-width">Location</th>
                          <th className="fixed-width">Level</th>
                          <th className="fixed-width">Risk</th>
                          <th className="fixed-width">Component</th>
                          <th className="fixed-width">Position</th>
                          <th className="fixed-width">Defect</th>
                          <th className="fixed-width">Action</th>
                          <th className="fixed-width">QTY</th>
                        </tr>
                      </thead>
                      <tbody>
                        {series.classifications.map((classification) =>
                          this.renderClassification(classification),
                        )}
                      </tbody>
                    </Table>
                  </div>
                ))}
              </div>
            )),
          )}
        </div>
      </div>
    );
  }

  renderTableOfContents() {
    const {
      report,
      showResetModal,
      showClearModal,
      bottomSections,
      fixedSections,
    } = this.state;
    const { sections } = report;
    const grid = 2;
    const reportAppendix = sections.filter(
      (curSection) => curSection.title === "Appendix",
    );
    const defaultAppendix = bottomSections.length ? bottomSections : [];
    const mainReportContent = sections.filter((curSection) =>
      bottomSections.some((sectionName) => sectionName !== curSection.title),
    );
    const mainReport = bottomSections.length ? mainReportContent : sections;
    const bottomReport = reportAppendix.length
      ? reportAppendix
      : defaultAppendix;
    return (
      <div className="ToC">
        <div className="ToC-header">Table of Contents</div>
        <div className="ToC-body">
          <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
            <Droppable droppableId="droppable">
              {(provided, sshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: sshot.isDraggingOver ? "lightblue" : "#30333c",
                  }}
                >
                  {mainReport.map((section, index) => (
                    <Draggable
                      draggableId={`${index}${section.id}${section.title}`}
                      index={index}
                      key={section.id}
                    >
                      {(provided, sshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            userSelect: "none",
                            padding: grid,
                            margin: `${grid}px 0 ${grid}px 0`,
                            background: sshot.isDragging
                              ? "lightgreen"
                              : "#30333c",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <div
                            {...provided.dragHandleProps}
                            className="ToC-handle"
                          >
                            {" "}
                            <FontAwesomeIcon icon={faBars} />{" "}
                          </div>
                          <div className="ToC-line">
                            <a href={`#${index + 1}`}>{`${index + 1}.0 ${
                              section.title || "-"
                            }`}</a>
                          </div>
                          <DragDropContext
                            onDragEnd={(result) =>
                              this.onDragEndSub(
                                result,
                                section.subsections,
                                (subsections) => {
                                  section.subsections = subsections;
                                  this.setState({ report });
                                },
                              )
                            }
                          >
                            <Droppable droppableId="droppable">
                              {(provided2, sshot2) => (
                                <div
                                  {...provided2.droppableProps}
                                  ref={provided2.innerRef}
                                  style={{
                                    background: sshot2.isDraggingOver
                                      ? "lightblue"
                                      : "#30333c",
                                    padding:
                                      section.subsections &&
                                      section.subsections.length
                                        ? grid
                                        : 0,
                                  }}
                                >
                                  {section.subsections &&
                                    section.subsections.map(
                                      (subsection, index2) => (
                                        <Draggable
                                          draggableId={`${index2}${subsection.id}${subsection.title}`}
                                          index={index2}
                                          key={subsection.id}
                                        >
                                          {(provided2, sshot2) => (
                                            <div
                                              ref={provided2.innerRef}
                                              {...provided2.draggableProps}
                                              style={{
                                                userSelect: "none",
                                                padding: grid,
                                                margin: `${grid / 2}px 0 ${
                                                  grid / 2
                                                }px 0`,
                                                background: sshot2.isDragging
                                                  ? "lightgreen"
                                                  : "#30333c",
                                                ...provided2.draggableProps
                                                  .style,
                                              }}
                                            >
                                              <div
                                                {...provided2.dragHandleProps}
                                                className="ToC-handle"
                                              >
                                                {" "}
                                                <FontAwesomeIcon
                                                  icon={faBars}
                                                />{" "}
                                              </div>
                                              <div className="ToC-subline">
                                                <a
                                                  href={`#${index + 1}.${
                                                    index2 + 1
                                                  }`}
                                                >
                                                  {`${index + 1}.${
                                                    index2 + 1
                                                  } ${subsection.title || "-"}`}
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      ),
                                    )}
                                  {provided2.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {fixedSections.map((section, index) => (
            <div
              className="ToC-default"
              key={`${index + 1 + sections.length - bottomSections.length}`}
            >
              <a
                href={`#${index + 1 + sections.length - bottomSections.length}`}
              >
                {`${
                  index + 1 + sections.length - bottomSections.length
                }.0 ${section}`}
              </a>
            </div>
          ))}
          <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
            <Droppable droppableId="droppable">
              {(provided, sshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    background: sshot.isDraggingOver ? "lightblue" : "#30333c",
                  }}
                >
                  {bottomReport.map((section, index) => (
                    <Draggable
                      draggableId={`${index}${section.id}${section.title}`}
                      index={index}
                      key={section.id}
                    >
                      {(provided, sshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            userSelect: "none",
                            padding: grid,
                            margin: `${grid}px 0 ${grid}px 0`,
                            background: sshot.isDragging
                              ? "lightgreen"
                              : "#30333c",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <div className="ToC-default">
                            <a
                              href={`#${
                                index + fixedSections.length + sections.length
                              }`}
                            >{`${
                              index + fixedSections.length + sections.length
                            }.0 ${section.title || "-"}`}</a>
                          </div>
                          <DragDropContext
                            onDragEnd={(result) =>
                              this.onDragEndSub(
                                result,
                                section.subsections,
                                (subsections) => {
                                  section.subsections = subsections;
                                  this.setState({ report });
                                },
                              )
                            }
                          >
                            <Droppable droppableId="droppable">
                              {(provided2, sshot2) => (
                                <div
                                  {...provided2.droppableProps}
                                  ref={provided2.innerRef}
                                  style={{
                                    background: sshot2.isDraggingOver
                                      ? "lightblue"
                                      : "#30333c",
                                    padding:
                                      section.subsections &&
                                      section.subsections.length
                                        ? grid
                                        : 0,
                                  }}
                                >
                                  {section.subsections &&
                                    section.subsections.map(
                                      (subsection, index2) => (
                                        <Draggable
                                          draggableId={`${
                                            fixedSections.length +
                                            sections.length
                                          }${subsection.id}${subsection.title}`}
                                          index={
                                            fixedSections.length +
                                            sections.length
                                          }
                                          key={subsection.id}
                                        >
                                          {(provided2, sshot2) => (
                                            <div
                                              ref={provided2.innerRef}
                                              {...provided2.draggableProps}
                                              style={{
                                                userSelect: "none",
                                                padding: grid,
                                                margin: `${grid / 2}px 0 ${
                                                  grid / 2
                                                }px 0`,
                                                background: sshot2.isDragging
                                                  ? "lightgreen"
                                                  : "#30333c",
                                                ...provided2.draggableProps
                                                  .style,
                                              }}
                                            >
                                              <div
                                                {...provided2.dragHandleProps}
                                                className="ToC-handle"
                                              >
                                                {" "}
                                                <FontAwesomeIcon
                                                  icon={faBars}
                                                />{" "}
                                              </div>
                                              <div className="ToC-subline">
                                                <a
                                                  href={`#${index + 1}.${
                                                    fixedSections.length +
                                                    sections.length +
                                                    1
                                                  }`}
                                                >
                                                  {`${
                                                    fixedSections.length +
                                                    sections.length
                                                  }.${index2 + 1} ${
                                                    subsection.title || "-"
                                                  }`}
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      ),
                                    )}
                                  {provided2.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="ToC-footer">
          <Button onClick={() => this.setState({ showResetModal: true })}>
            Reset
          </Button>
          <Button onClick={() => this.setState({ showClearModal: true })}>
            Clear
          </Button>
        </div>
        <ConfirmModal
          confirmButtonText="Reset"
          onConfirm={() =>
            this.setState({ showResetModal: false }, () =>
              this.setDefaultSections(),
            )
          }
          onHide={() => this.setState({ showResetModal: false })}
          show={showResetModal}
          title="Reset Report"
        >
          <h4>Are you sure you want to reset this Inspection Report?</h4>
          <h4>
            This can not be undone, and all related data will also be deleted
            and set to default.
          </h4>
        </ConfirmModal>
        <ConfirmModal
          confirmButtonText="Clear"
          onConfirm={() =>
            this.setState({ showClearModal: false }, () =>
              this.setClearSections(),
            )
          }
          onHide={() => this.setState({ showClearModal: false })}
          show={showClearModal}
          title="Clear Report"
        >
          <h4>Are you sure you want to clear this Inspection Report?</h4>
          <h4>
            This can not be undone, and all related data will also be deleted.
          </h4>
        </ConfirmModal>
      </div>
    );
  }

  render() {
    const {
      inspection,
      report,
      currentImage,
      showConfirmReportModal,
      isLoading,
      saving,
      bottomSections,
      fixedSections,
    } = this.state;
    if (isLoading || !inspection || !report) {
      return (
        <Loading
          height="calc(100vh - 50px)"
          text="Loading Inspection Report..."
        />
      );
    }
    const { images } = inspection;
    const { sections, client, site } = report;
    const reportCompleted = !!report.inspection.preparedAt;
    const mainReportContent = sections.filter((curSection) =>
      bottomSections.some((sectionName) => sectionName !== curSection.title),
    );
    const bottomReportContent = sections.filter((curSection) =>
      bottomSections.some((sectionName) => sectionName === curSection.title),
    );
    const mainReport = bottomSections.length ? mainReportContent : sections;
    const bottomReport = bottomSections.length ? bottomReportContent : [];
    return (
      <div
        css={css`
          margin-top: 10px;
          margin-bottom: 10px;
          .ToC {
            z-index: 1000;
            overflow-y: auto;
            overflow-x: hidden;
            position: fixed;
            right: 0px;
            top: 50px;
            background-color: #30333c;
            height: calc(100vh - 50px);
            width: 225px;
            padding-left: 0px;
            color: #ffffff;
            .ToC-handle {
              display: inline-block;
              padding-right: 5px;
              padding-left: 5px;
              height: 100%;
            }
            .ToC-line {
              display: inline-block;
              max-width: calc(100% - 25px);
            }
            .ToC-subline {
              display: inline-block;
              max-width: 85%;
              padding-left: 5px;
            }
            .ToC-default {
              padding-left: 5px;
            }
            a {
              color: #ffffff;
              font-size: 10pt;
              font-weight: bold;
              background-color: #30333c;
            }
            .subsection {
              padding-left: 20px;
            }
            .ToC-header {
              background-color: #30333c;
              height: 46px;
              font-size: 14pt;
              padding: 10px;
              color: #ffcf33;
            }
            .ToC-body {
              height: calc(100vh - 46px - 52px - 52px);
              overflow-y: auto;
              background-color: #30333c;
            }
            .ToC-footer {
              background-color: #30333c;
              height: 52px;
              font-size: 14pt;
              .btn {
                width: 45%;
              }
            }
          }
          .header-btns {
            width: 200px;
            float: right;
          }
          .subsection-btn {
            width: 100%;
            text-align: center;
          }
          .add-section-btn-large,
          .add-subsection-btn-large,
          .add-subsection-btn,
          .add-section-btn {
            margin-top: 0px;
            margin-bottom: 0px;
            width: 150px;
            height: auto;
            font-size: 10pt;
            padding: 5px 15px;
            i {
              padding-left: 5px;
            }
          }
          .add-section-btn-large {
          }
          .add-subsection-btn-large {
            margin-top: 10px;
            margin-right: auto;
            margin-left: auto;
          }
          .inspection-image {
            display: inline-block;
            margin: 10px;
            width: 20%;
          }
          .top-bar {
            padding-top: 10px;
            padding-left: 100px;
            background-color: #eeeeee;
            top: 50px;
            width: 100%;
            z-index: 999;
            .right,
            .left {
              display: inline-block;
            }
            .right {
              position: absolute;
              Button {
                width: 150px;
                margin: 0px;
                margin-right: 5px;
              }
              right: calc(450px);
            }
          }
          .bottom-bar {
            padding-top: 7px;
            padding-bottom: 5px;
            padding-left: 15px;
            background-color: #494c55;
            position: fixed;
            height: 54px;
            bottom: 0px;
            width: 100%;
            z-index: 999;
            .right,
            .left {
              display: inline-block;
            }
            .right {
              padding-right: 95px;
              position: absolute;
              Button {
                width: 150px;
                margin: 0px;
                margin-right: 5px;
              }
              right: calc(450px);
            }
          }
          .sections-parent {
            padding-top: 10px;
            padding-bottom: 80px;
            margin-right: 225px;
          }
          .sections {
            padding-right: 100px;
            padding-left: 100px;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            /* background-color: #ffffff; */
            .controls {
              width: 100%;
            }
          }
          .section-button-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding-bottom: 10px;
          }
          .section {
            font-size: 12px;
            background-color: #ffffff;
            border-radius: 5px;
            margin-bottom: 10px;
            .anchor {
              display: block;
              position: relative;
              top: -110px;
              visibility: hidden;
            }
            .subsection {
              .body {
                background-color: #eeeeee !important;
              }
              .body-prepopulated {
                color: #494c55;
                background-color: #ffffff;
                padding: 10px;
              }
              .table {
                width: 100%;
              }
              .column {
                width: 25%;
              }
              .column-header {
                text-align: center;
                width: 25%;
                background-color: #ffffff;
              }
              .column-header.green {
                color: #4caf50;
              }
              .column-header.amber {
                color: #f57c00;
              }
              .column-header.red {
                color: #f44336;
              }
              .table-cell {
                text-align: center;
              }
              .row-spacer {
                height: 35px;
              }
              .black-text {
                color: #000000;
              }
              .black-text.bold {
                font-weight: bold;
              }
              .image-container {
                text-align: center;
              }
              .heading {
                background-color: #494c55;
                color: #ffffff;
                padding: 5px;
                margin-top: 10px;
                border-radius: 0px;
                .section-number {
                  width: 30px;
                  padding-right: 10px;
                  padding-left: 5px;
                  font-size: 12pt;
                }
              }
            }
            .heading {
              background-color: #494c55;
              color: #ffffff;
              padding: 5px;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              .section-number {
                width: 50px;
                padding-right: 10px;
                padding-left: 5px;
                font-size: 14pt;
              }
              .form-control {
                display: inline-block;
                width: calc(100% - 250px);
                background-color: #616571;
                color: #ffffff;
              }
              .section-delete {
                width: 50px;
                height: 40px;
                .btn {
                  margin: 0px;
                  height: 35px;
                  width: 35px;
                  outline: none;
                  background-color: #00000000;
                }
              }
              .section-delete-right {
                width: 50px;
                height: 40px;
                float: right;
                .btn {
                  margin: 0px;
                  height: 35px;
                  width: 35px;
                  outline: none;
                  background-color: #00000000;
                }
              }
              .form-control:disabled {
                background-color: #494c55;
                cursor: default;
              }
            }
            .body {
              color: #494c55;
              background-color: #ffffff;
              padding: 10px;
            }
            .form-control {
              background-color: #f2f2f4;
              box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
              width: 100%;
              color: #494c55;
            }
            .report-buttons {
              .btn {
                background-color: #999900;
                height: 52px;
              }
            }
          }
          .material-table > thead > tr > .component {
            width: 50%;
          }
          .risk-green {
            color: #4caf50;
          }

          .risk-amber {
            color: #f57c00;
          }

          .risk-red {
            color: #f44336;
          }
          .material-table > thead > tr > .other {
            width: 25%;
          }
          .damage-images {
            width: 49%;
            display: inline-block;
            height: auto;
            padding-bottom: 5px;
            margin-bottom: 5px;
            overflow: visible;
            text-align: center;
            text-overflow: ellipsis;
          }

          .damage-images > img {
            width: auto;
            height: 385px;
            margin-left: auto;
            margin-right: auto;
            align-content: center;
            padding-bottom: 10px;
          }
          .damage-images > .header {
            padding-top: 10px;
            padding-bottom: 5px;
            font-size: 12pt;
          }
          .damage-images > .subheader {
            padding-bottom: 10px;
            font-size: 10pt;
          }
          .damage-images > .caption {
            font-size: 10pt;
            width: 90%;
            margin-right: auto;
            margin-left: auto;
          }
          table {
            border: #30333b 1px solid;
            color: #30333b !important;
            width: 100%;
            background-color: #fff !important;
          }
          td,
          th {
            border: 1px solid #000000 !important;
            margin: 0;
            padding: 5px;
          }

          .risk-green {
            color: #4caf50;
          }

          .risk-amber {
            color: #f57c00;
          }

          .risk-red {
            color: #f44336;
          }

          td.risk {
            font-weight: bold;
            text-transform: uppercase;
          }
        `}
      >
        {this.renderTableOfContents()}
        <div className="top-bar">
          <div className="left">
            <Image
              css={css`
                height: 40px;
                width: 40px;
                margin: 5px;
                margin-top: 0px;
                border-radius: 50%;
              `}
              src={
                client.logoUrl ? `${client.logoUrl}` : "/img/defaults/logo.png"
              }
            />
            <FilterChip
              icon={
                client.logoUrl ? `${client.logoUrl}` : "/img/defaults/logo.png"
              }
              text={client.name}
              type="clients"
            />
            <FilterChip text={site.name} type="sites" />
            <FilterChip
              text={`Ref. ${inspection.reference}`}
              type="reference"
            />
          </div>
        </div>
        <div className="bottom-bar">
          <div className="right">
            <div className="report-buttons">
              <Button
                disabled={reportCompleted}
                onClick={() => this.onSaveReport()}
              >
                {saving ? "Saving..." : "Save"}
              </Button>
              <Button
                disabled={reportCompleted}
                onClick={async () => this.onDownloadPDF()}
              >
                Export Draft
              </Button>
              <Button
                disabled={reportCompleted}
                onClick={() => this.setState({ showConfirmReportModal: true })}
              >
                {`Complete${reportCompleted ? "d" : ""}`}
              </Button>
            </div>
          </div>
        </div>
        <div className="sections-parent">
          <div className="sections">
            {!!sections.length || (
              <div className="section-button-container">
                <Button
                  className="add-section-btn-large"
                  onClick={() => {
                    sections.push({
                      id: sections.length + 1,
                      title: "",
                      content: "",
                      custom: true,
                      subsections: [],
                    });
                    this.setState({ report });
                  }}
                >
                  Add Section <FontAwesomeIcon icon={faPlus} />{" "}
                </Button>
              </div>
            )}
            {mainReport.map((section, index) =>
              this.renderSection(
                section,
                index,
                (title) => {
                  section.title = title;
                  section.changed = true;
                  this.setState({ report });
                },
                (content) => {
                  section.content = content;
                  section.changed = true;
                  this.setState({ report });
                },
                () => {
                  const { deletedSections, showDeleteSectionId } = this.state;
                  if (section.id) {
                    deletedSections.push(section.id);
                    report.sections = report.sections.filter(
                      (curSection) => section.id !== curSection.id,
                    );
                  } else {
                    report.sections.splice(showDeleteSectionId, 1);
                  }
                  this.setState({ report, deletedSections });
                },
                (title, i) => {
                  section.subsections[i].title = title;
                  section.subsections[i].changed = true;
                  this.setState({ report });
                },
                (content, i) => {
                  section.subsections[i].content = content;
                  section.subsections[i].changed = true;
                  this.setState({ report });
                },
                (i) => {
                  let deletedSubsections = [];
                  if (section.deletedSubsections) {
                    deletedSubsections = section.deletedSubsections;
                  }
                  const { showDeleteSubSectionId } = this.state;
                  const subsection = section.subsections[i];
                  if (section.id) {
                    deletedSubsections.push(section.subsections[i].id);
                    section.subsections = section.subsections.filter(
                      (curSubsection) => subsection.id !== curSubsection.id,
                    );
                  } else {
                    report.sections.splice(showDeleteSubSectionId, 1);
                  }
                  section.deletedSubsections = deletedSubsections;
                  this.setState({ report });
                },
              ),
            )}
            {this.renderClassifications()}
            {this.renderImages()}
            {this.renderBillOfMaterials()}
            {bottomReport.map((section, index) =>
              this.renderSection(
                section,
                index + sections.length + fixedSections.length - 1,
                (title) => {
                  section.title = title;
                  section.changed = true;
                  this.setState({ report });
                },
                (content) => {
                  section.content = content;
                  section.changed = true;
                  this.setState({ report });
                },
                () => {
                  const { deletedSections, showDeleteSectionId } = this.state;
                  if (section.id) {
                    deletedSections.push(section.id);
                    report.sections = report.sections.filter(
                      (curSection) => section.id !== curSection.id,
                    );
                  } else {
                    report.sections.splice(showDeleteSectionId, 1);
                  }
                  this.setState({ report, deletedSections });
                },
                (title, i) => {
                  section.subsections[i].title = title;
                  section.subsections[i].changed = true;
                  this.setState({ report });
                },
                (content, i) => {
                  section.subsections[i].content = content;
                  section.subsections[i].changed = true;
                  this.setState({ report });
                },
                (i) => {
                  let deletedSubsections = [];
                  if (section.deletedSubsections) {
                    deletedSubsections = section.deletedSubsections;
                  }
                  const subsection = section.subsections[i];
                  if (section.id) {
                    deletedSubsections.push(section.subsections[i].id);
                    section.subsections = section.subsections.filter(
                      (curSubsection) => subsection.id !== curSubsection.id,
                    );
                  }
                  section.deletedSubsections = deletedSubsections;
                  this.setState({ report });
                },
              ),
            )}
            {!bottomSections.length && (
              <div className="section-button-container">
                <Button
                  className="add-section-btn-large"
                  onClick={() => {
                    this.setAppendixSections(true);
                  }}
                >
                  Add Appendix <FontAwesomeIcon icon={faPlus} />{" "}
                </Button>
              </div>
            )}
          </div>
        </div>
        {currentImage !== null && (
          <ImageLightBox
            currentImage={currentImage}
            focuseCaption
            images={images.map((image) =>
              Object.assign(image, {
                src: `${image.remotePath}`,
                caption: image.caption,
                id: image.id,
                createdAt: image.createdAt,
              }),
            )}
            isOpen
            onChangeCaption={(ImageId, caption) => {
              images.map((image) => {
                if (image.id === ImageId) {
                  image.caption = caption;
                }
                return image;
              });
              this.setState({ inspection });
            }}
            onClose={() => this.setState({ currentImage: null })}
            onNext={() => this.setState({ currentImage: currentImage + 1 })}
            onPrevious={() => this.setState({ currentImage: currentImage - 1 })}
          />
        )}

        {showConfirmReportModal && (
          <ConfirmModal
            confirmButtonText="Confirm"
            onConfirm={() => this.onCompleteReport()}
            onHide={() => this.setState({ showConfirmReportModal: false })}
            show={showConfirmReportModal}
            title="Complete Report"
          >
            Are you sure you want to complete this report? You will no longer be
            able to edit the report.
          </ConfirmModal>
        )}
      </div>
    );
  }
}

InspectionEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default WithCompatWrap(InspectionEditor);
