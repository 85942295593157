import PropTypes from "prop-types";

import {
  ActionButtonsOverlay,
  InspectionContinueButton,
  InspectionStartButton,
} from "views/components/ActionButtons";

function MyScheduleActionButtons({
  available,
  disabled,
  inspectionStarted,
  onContinueClick,
  onStartClick,
}) {
  if (!inspectionStarted && !available) return null;
  return (
    <ActionButtonsOverlay>
      {inspectionStarted && (
        <InspectionContinueButton
          disabled={disabled}
          onClick={onContinueClick}
        />
      )}
      {!inspectionStarted && available && (
        <InspectionStartButton disabled={disabled} onClick={onStartClick} />
      )}
    </ActionButtonsOverlay>
  );
}

MyScheduleActionButtons.propTypes = {
  available: PropTypes.bool,
  disabled: PropTypes.bool,
  inspectionStarted: PropTypes.bool,
  onContinueClick: PropTypes.func,
  onStartClick: PropTypes.func,
};

export default MyScheduleActionButtons;
