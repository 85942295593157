/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom'; // compat

import {
  ActionButtonsOverlay,
  SiteOverviewButton,
  ViewWarehouseButton,
} from 'views/components/ActionButtons';

export default function SitesActionButtons({ site, permission, user }) {
  const navigate = useNavigate();
  const { warehouses, buildMode } = site;
  const { permissions } = user;
  const { clientAdmin, organisation } = permissions;
  const userCanBuild =
    !organisation.filter((c) => c === site.ClientId).length ||
    !clientAdmin.filter((c) => c === site.ClientId).length;
  return (
    <ActionButtonsOverlay>
      <ViewWarehouseButton
        disabled={!(!site.buildMode || userCanBuild)}
        hasPermission={!permission.level.includes('client')}
        hasWarehouses={warehouses?.length}
        isBuildMode={buildMode}
        onClick={() => navigate(`/blueprint/${site.id}`)}
      />
      <SiteOverviewButton onClick={() => navigate(`/clients/sites/${site.id}/overview`)} />
    </ActionButtonsOverlay>
  );
}

SitesActionButtons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  site: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  permission: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
};
