/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useFilters } from "hooks/useFilter";
import ActionStatusButton from "views/pages/Actions/components/ActionStatusButton";

export default function ActionStatusButtons({ actionStats }) {
  const totalTodo = actionStats["To Do"] || 0;
  const totalOngoing = actionStats["On Going"] || 0;
  const totalUnfinished = actionStats.Unfinished || 0;
  const totalCompleted = actionStats.Completed || 0;

  const { actionStatus, setActionStatus } = useFilters();
  return (
    <div>
      <ActionStatusButton
        count={totalTodo}
        onClick={setActionStatus}
        status={actionStatus}
        text="To Do"
      />
      <ActionStatusButton
        count={totalOngoing}
        onClick={setActionStatus}
        status={actionStatus}
        text="On Going"
      />
      <ActionStatusButton
        count={totalUnfinished}
        onClick={setActionStatus}
        status={actionStatus}
        text="Unfinished"
      />
      <ActionStatusButton
        count={totalCompleted}
        onClick={setActionStatus}
        status={actionStatus}
        text="Completed"
      />
    </div>
  );
}

ActionStatusButtons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actionStats: PropTypes.object,
};
