import PropTypes from "prop-types";
import { Button, ButtonGroup, Image } from "react-bootstrap";

import formatBayLocation from "libs/formatBayLocation";

import { StyledFormControl } from "../../SharedControls/Generic";

export default function Bay({
  bay,
  bayTemplates = [],
  frameTemplates = [],
  onDeleteBay,
  onUpdateBay,
  onUpdateFrameTemplate,
  rack,
}) {
  if (!rack || !bay) return null;

  const selectors = [
    <StyledFormControl
      componentClass="select"
      key={`L${bay.FrameTemplateLId}`}
      onChange={(e) => onUpdateFrameTemplate(e.target.value, true)}
      value={bay.FrameTemplateLId}
    >
      {bay.FrameLId}
      {frameTemplates.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </StyledFormControl>,
    <StyledFormControl
      componentClass="select"
      key={`R${bay.FrameTemplateRId}`}
      onChange={(e) => onUpdateFrameTemplate(e.target.value, false)}
      value={bay.FrameTemplateRId}
    >
      {bay.FrameRId}
      {frameTemplates.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </StyledFormControl>,
  ];
  return (
    <tr key={JSON.stringify(bay)}>
      <td>
        {rack.name.length > 3 ? `${rack.name.substring(0, 3)}...` : rack.name}
        {formatBayLocation(bay.location)}
      </td>
      <td>
        <StyledFormControl
          componentClass="select"
          onChange={(e) =>
            onUpdateBay({ BayTemplateId: e.target.value, changed: true })
          }
          placeholder="Bay Types"
          value={bay.BayTemplateId}
        >
          {bayTemplates.map((bt) => (
            <option key={bt.id} value={bt.id}>
              {bt.name}
            </option>
          ))}
        </StyledFormControl>
      </td>
      {/* Frame Left and Frame Right */}
      <td>{selectors[rack.arrangement ? 1 : 0]}</td>
      <td>{selectors[rack.arrangement ? 0 : 1]}</td>
      <td>
        <ButtonGroup>
          <Button className="transparent" onClick={() => onDeleteBay()}>
            <Image responsive src="/img/icons/trash-white.png" />
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
}

Bay.propTypes = {
  bay: PropTypes.shape({
    BayTemplateId: PropTypes.string,
    location: PropTypes.number,
    changed: PropTypes.bool,
    deleted: PropTypes.bool,
    FrameRId: PropTypes.string,
    FrameLId: PropTypes.string,
    FrameTemplateRId: PropTypes.string,
    FrameTemplateLId: PropTypes.string,
  }),
  rack: PropTypes.shape({
    arrangement: PropTypes.bool,
    name: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  bayTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  // eslint-disable-next-line react/forbid-prop-types
  frameTemplates: PropTypes.array,
  onUpdateBay: PropTypes.func.isRequired,
  onDeleteBay: PropTypes.func.isRequired,
  onUpdateFrameTemplate: PropTypes.func.isRequired,
};
