import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";

import {
  getAreas,
  getRack,
  getSite,
  getSites,
  deleteSite,
  editSite,
} from "libs/api";

export function useSites(filters) {
  const { data, error, mutate, isLoading } = useSWR(
    ["/site", filters],
    async () => getSites(filters),
  );

  const handleEditSite = useCallback(
    async (site) => {
      await editSite(site);
      // TODO: Also mutate individual site
      mutate();
    },
    [mutate],
  );

  return {
    sites: data?.results ?? [],
    total: data?.total ?? 0,
    isLoading,
    isError: error,
    mutate,
    handleEditSite,
  };
}

export function useSite(id) {
  const navigate = useNavigate();
  const { data, error, isLoading, mutate } = useSWR(
    id ? `/site/${id}` : null,
    async () => getSite(id),
  );

  const handleDelete = useCallback(async () => {
    if (!data) return;
    try {
      await deleteSite(id);
      toast(`Deleted site: ${data.name}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        type: toast.TYPE.INFO,
        autoClose: true,
        pauseOnHover: true,
      });

      navigate(`/clients/${data.ClientId}/sites`);
      mutate();
    } catch (error) {
      throw new Error("Problem deleting site");
    }
  }, [data, id, mutate, navigate]);

  return {
    site: data,
    isLoading,
    isError: error,
    mutate,
    handleDelete,
  };
}

export function useAreas(WarehouseId, filters) {
  const { data, error, mutate, isLoading } = useSWR(
    WarehouseId ? [`/area/warehouse/${WarehouseId}`, filters] : null,
    async () => getAreas(WarehouseId, filters),
  );
  return {
    areas: data ?? [],
    isError: error,
    isLoading,
    mutate,
  };
}

export function useRack(RackId) {
  const { data, error, mutate, isLoading } = useSWR(
    RackId ? `/rack/${RackId}` : null,
    async () => getRack(RackId),
  );

  return {
    rack: data,
    isError: error,
    isLoading,
    mutate,
  };
}
