import PropTypes from "prop-types";
import { Button, ButtonGroup, Col, Row, ListGroupItem } from "react-bootstrap";

import { EDITOR_MODES } from "../constants";
import ExpandingTable from "../SharedControls/ExpandingTable";

export default function BayTemplate({
  bayTemplate,
  canEdit = false,
  manufacturer,
  onSelectBayTemplate,
}) {
  if (!bayTemplate || !manufacturer) {
    return null;
  }
  const { beamTemplates = [] } = bayTemplate;
  return (
    <ListGroupItem header={bayTemplate.name} key={bayTemplate.id}>
      <Row>
        <Col className="item-information" sm={7}>
          <b>Manufacturer:</b> {manufacturer.name || "N/A"}
          <br />
          <b>Series:</b> {bayTemplate.SeriesName || "N/A"}
          <br />
          <b>Width:</b> {bayTemplate.width}
          mm
          <br />
          <b>Depth:</b> {bayTemplate.depth || 0}
          mm
          <br />
          <b>1st Beam Pitch:</b> {bayTemplate.firstPitch || 0}
          mm
          <br />
          <b>Beam Pitch:</b> {bayTemplate.pitch || 0}
          mm
          <br />
        </Col>
        {canEdit && (
          <Col sm={5}>
            <ButtonGroup className="pull-right">
              <Button
                className="text-btn"
                onClick={() =>
                  onSelectBayTemplate(bayTemplate.id, EDITOR_MODES.edit)
                }
              >
                Edit Bay
              </Button>
              <Button
                className="text-btn"
                onClick={() =>
                  onSelectBayTemplate(bayTemplate.id, EDITOR_MODES.copy)
                }
              >
                Copy Bay
              </Button>
            </ButtonGroup>
          </Col>
        )}
        <ExpandingTable title={`${beamTemplates.length} Beams`}>
          <thead>
            <tr>
              <th>Level</th>
              <th>Type</th>
              <th>Downweld</th>
            </tr>
          </thead>
          <tbody>
            {beamTemplates.map((beamTemplate, i) => (
              <tr key={beamTemplate.id}>
                <td>{i + 1 > 9 ? i + 1 : `0${i + 1}`}</td>
                <td>{beamTemplate.type}</td>
                <td>
                  {beamTemplate.standardDownweld
                    ? `${beamTemplate.standardDownweld}mm`
                    : "Standard"}
                </td>
              </tr>
            ))}
          </tbody>
        </ExpandingTable>
      </Row>
    </ListGroupItem>
  );
}

BayTemplate.propTypes = {
  bayTemplate: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    beamTemplates: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        standardDownweld: PropTypes.string,
      }),
    ),
    showBeams: PropTypes.bool,
    SeriesName: PropTypes.string,
    pitch: PropTypes.number,
    firstPitch: PropTypes.number,
    width: PropTypes.number,
    depth: PropTypes.number,
  }),
  manufacturer: PropTypes.shape({
    name: PropTypes.string,
  }),
  canEdit: PropTypes.bool,
  onSelectBayTemplate: PropTypes.func,
};
