/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom"; // compat

import { useDownloader } from "hooks/useDownloader";
import { toggleInspectionArchive, toggleBookmarkInspection } from "libs/api";

import {
  ActionButtonsOverlay,
  ArchiveButton,
  BookmarkButton,
  DoActionButton,
  EditActionButton,
  ExportButton,
  ShareButton,
  ViewActionButton,
} from "views/components/ActionButtons";

export default function ActionsActionButtons({
  onRequestPDF,
  onRequestShare,
  onSelectAction,
  getData,
  action,
  condensed = false,
}) {
  const navigate = useNavigate();
  const { status } = action;
  const { isDownloading } = useDownloader();
  return (
    <ActionButtonsOverlay>
      {!condensed && (
        <BookmarkButton
          bookmarked={!!action.bookmarked}
          onClick={async () => {
            await toggleBookmarkInspection(action.id, true);
            getData();
          }}
        />
      )}
      <ExportButton
        isDownloading={isDownloading(action.id)}
        key={`export${action.id}`}
        onClick={onRequestPDF}
      />
      {!condensed && <ShareButton onClick={onRequestShare} />}
      {status === "Completed" ? (
        <>
          <ViewActionButton
            onClick={() => navigate(`/actions/view/${action.id}`)}
          />
          {!condensed && (
            <ArchiveButton
              archived={action.actionArchivedAt}
              onClick={async () => {
                await toggleInspectionArchive(action.id, true);
                getData();
              }}
            />
          )}
        </>
      ) : (
        <>
          {status === "To Do" || status === "On Going" ? (
            <EditActionButton
              disabled={!action.hasEditActionAccess}
              onClick={onSelectAction}
            />
          ) : null}
          <DoActionButton
            disabled={!action.hasRepairResolveAccess}
            onClick={() => navigate(`/actions/view/${action.id}`)}
            started={status === "On Going"}
          />
        </>
      )}
    </ActionButtonsOverlay>
  );
}

ActionsActionButtons.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string,
    bookmarked: PropTypes.bool,
    hasRepairResolveAccess: PropTypes.bool,
    hasEditActionAccess: PropTypes.bool,
    actionArchivedAt: PropTypes.string,
    status: PropTypes.string,
  }),
  getData: PropTypes.func,
  onRequestPDF: PropTypes.func.isRequired,
  onRequestShare: PropTypes.func.isRequired,
  onSelectAction: PropTypes.func.isRequired,
};
