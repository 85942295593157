/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import moment from "moment";
import PropTypes from "prop-types";
import { Component } from "react";
import {
  Button,
  FormControl,
  ButtonGroup,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";

import "react-multi-email/style.css";
import {
  getShareURL,
  sendShareEmail,
  getUser,
  getActionShareURL,
} from "libs/api";
import { PRIMARY_DATETIME_FORMAT } from "libs/constants";

export default class ShareModal extends Component {
  state = {
    shareURL: {},
    emails: [],
    emailBody: null,
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const { InspectionId, type } = this.props;
    const res = await getUser();
    const user = await res.json();
    const shareURL =
      type === "action"
        ? await getActionShareURL(InspectionId, { env: process.env.NODE_ENV })
        : await getShareURL(InspectionId, { env: process.env.NODE_ENV });
    const emailBody = `Hello,

Have a look at this ${type} I have shared with you via the Econform Web App

${shareURL.URL}

Please note this link will expire at ${moment(shareURL.expiresAt).format(
      PRIMARY_DATETIME_FORMAT,
    )}

Thanks,

${user.name}`;
    this.setState({ shareURL, emailBody });
  }

  copyURL() {
    const copyTextarea = document.querySelector("#shareURL");
    copyTextarea.focus();
    copyTextarea.select();
    const copiedSuccess = document.execCommand("copy");
    this.setState({ copiedSuccess }, () =>
      setTimeout(() => this.setState({ copiedSuccess: null }), 5000),
    );
  }

  render() {
    const { onHide } = this.props;
    const {
      shareURL,
      emails,
      emailBody,
      copiedSuccess,
      sendSuccess,
      sendErrMsg,
    } = this.state;
    // if (sendSuccess) {
    //   setTimeout(() => onHide(), 5000);
    // }
    return (
      <div
        css={css`
          color: #666666;
          margin: 0;
          width: "0px";
          label {
            input {
              background-color: #00000000;
              border-radius: 0px;
              border: solid 1px #979797;
              width: 15px;
              height: 15px;
              margin-left: 10px;
            }
            input:checked {
              background-color: #ffcf33;
            }
            input:after {
              border-radius: 0px;
              height: 7px;
              width: 7px;
              left: 3px;
              top: 3px;
            }
          }
          .bottom-btn-group {
            padding-top: 10px;
            padding-right: 10px;
            float: right;
            .btn {
              width: 150px;
              &:disabled {
                background-color: #7e8496;
                color: #ffffffaa;
              }
            }
          }
          .form-control {
            background-color: #f2f2f4;
            color: #747474;
            border: 0px;
            box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
          }
        `}
      >
        {sendSuccess ? (
          <div>
            Email successfully sent to:
            {emails.map((email) => (
              <div key={email}>{`• ${email}`}</div>
            ))}
            <Row>
              <ButtonGroup className="bottom-btn-group">
                <Button className="cancel" onClick={() => onHide()}>
                  Close
                </Button>
              </ButtonGroup>
            </Row>
          </div>
        ) : (
          <Tabs
            animation={false}
            className="clearfix"
            css={css`
              ul > li {
                width: 49%;
                a {
                  color: #666666;
                  text-align: center;
                  border: 0px;
                }
                border-bottom: 3px #2d71ed22 solid;
              }
              ul > li.active,
              ul > li:hover,
              ul > li:focus {
                a,
                a:hover,
                a:focus {
                  color: #2d71ed;
                  border: 0px;
                  background-color: #00000000;
                }
                border-bottom: 3px #2d71ed solid;
              }
              .tab-content {
                margin-top: 15px;
              }
            `}
          >
            <Tab eventKey={1} title="Via Link">
              <FormControl
                id="shareURL"
                placeholder="Share Inspection"
                value={shareURL.URL}
              />
              {`Expires at ${moment(shareURL.expiresAt).format(
                PRIMARY_DATETIME_FORMAT,
              )}`}
              <Row>
                <ButtonGroup className="bottom-btn-group">
                  <Button className="cancel" onClick={() => onHide()}>
                    Cancel
                  </Button>
                  <Button
                    css={css`
                      ${copiedSuccess ? "background-color: #ffcf33" : ""}
                    `}
                    onClick={() => this.copyURL(shareURL)}
                  >
                    {copiedSuccess ? "Copied!" : "Copy"}
                  </Button>
                </ButtonGroup>
              </Row>
            </Tab>
            <Tab eventKey={2} title="Via Email">
              <ReactMultiEmail
                emails={emails}
                onChange={(updatedEmails) =>
                  this.setState({ emails: updatedEmails })
                }
                placeholder="Emails"
                validateEmail={(email) => isEmail(email)}
                // eslint-disable-next-line react/jsx-sort-props
                getLabel={(email, index) => (
                  <div data-tag key={index}>
                    {email}
                    <span
                      data-tag-handle
                      onClick={() =>
                        this.setState({
                          emails: emails.filter((_e, i) => i !== index),
                        })
                      }
                    >
                      ×
                    </span>
                  </div>
                )}
              />
              <FormControl
                componentClass="textarea"
                css={css`
                  height: 300px !important;
                `}
                onChange={(e) => this.setState({ emailBody: e.target.value })}
                placeholder="Add Notes"
                value={emailBody}
              />
              <ButtonGroup className="bottom-btn-group">
                <Button className="cancel" onClick={() => onHide()}>
                  Cancel
                </Button>
                <Button
                  // disabled={sendSuccess || emails.length < 1}
                  css={css`
                    ${sendSuccess ? "background-color: #ffcf33" : ""}
                  `}
                  onClick={async () => {
                    const res = await sendShareEmail(emails, emailBody);
                    this.setState({
                      sendSuccess: res.ok,
                      sendErrMsg:
                        !res.ok && "Issue sending email, try again later",
                    });
                  }}
                >
                  {sendSuccess ? "Sent!" : "Send"}
                </Button>
              </ButtonGroup>
              {sendErrMsg}
            </Tab>
          </Tabs>
        )}
      </div>
    );
  }
}

ShareModal.propTypes = {
  InspectionId: PropTypes.string,
  type: PropTypes.oneOf(["action"]),
  onHide: PropTypes.func,
};
