import {
  faChevronLeft,
  faExpand,
  faMagnifyingGlass,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "@pixi/react";
import PropTypes from "prop-types";
import { useState, useId, useRef, useMemo } from "react";

import { Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { usePermission } from "hooks/permission";
import useBlueprintSizer from "hooks/use-blueprint-sizer";
import { useScaledSiteBlueprint } from "hooks/use-site-blueprint";
import BlueprintFilterOptions from "views/components/Blueprint/BlueprintFilterOptions";
import Area from "views/components/Blueprint/canvas-elements/area";
import Beams from "views/components/Blueprint/canvas-elements/beams";
import {
  FrameLabel,
  FramesForBays,
} from "views/components/Blueprint/canvas-elements/frames";
import Grid from "views/components/Blueprint/canvas-elements/grid";
import Rack from "views/components/Blueprint/canvas-elements/rack";
import StageViewport from "views/components/Blueprint/canvas-elements/stage-viewport";
import ZoomSelect from "views/components/Blueprint/canvas-elements/zoom-select";
import NavigationBar from "views/components/Blueprint/navigation-bar";
import {
  BackLink,
  BottomTabs,
  MainAndSidebar,
  MainContainer,
  NavigationSection,
  RoundButton,
  Sidebar,
  StageContainer,
} from "views/components/Blueprint/styles";
import BuildModeSidebar from "views/components/BuildModeSidebar";
import ClientLogo from "views/components/ClientLogo";
import FilterChip from "views/components/FilterChip";
import Loading from "views/components/Loading";
import SidebarButton from "views/components/Sidebar/SidebarButton";

export default function PageBlueprintBuild({ SiteId, WarehouseId }) {
  const warehouseTabsId = useId(); // Accessible ID for the tabs
  const  [ showSidebar, setShowSidebar ] = useState(true); // prettier-ignore
  const viewportRef = useRef(); // Access to the pixi viewport instance
  const navigate = useNavigate();

  // Lock the pan when the user is zooming
  const [isPanLocked, setPanLock] = useState(false);

  const { permission, isLoading: isLoadingPermission } = usePermission("site", SiteId); // prettier-ignore
  const gridRef = useRef(); // Access to the grid instance

  const { site, warehouse, areas, isLoading } = useScaledSiteBlueprint(SiteId, WarehouseId); // prettier-ignore
  const [filters, setFilters] = useState({
    showDisplay: true,
    showNone: false,
    SiteId,
  });

  const { handleFocus, focusOnWarehouse, stageContainerRef, width, height, setAllowFocus } = useBlueprintSizer(viewportRef, warehouse, isLoading); // prettier-ignore

  /**
   * Memorize array components to avoid re-rendering them on every update.
   */
  const memoRacks = useMemo(() => {
    return warehouse?.racks.map((r) => (
      <Rack key={r.id} rack={r}>
        <Beams rackDepth={r.totalY} rackWidth={r.totalX} />
        <FramesForBays bays={r.bays} />
        {r.bays.map((b, i) => {
          // Bay Labels, not interactive
          return (
            <Container cullable key={b.id} x={b.offset} y={0}>
              {i === 0 ? (
                <FrameLabel
                  depth={b.FrameTemplateL.depth}
                  label={b.FrameTemplateL.name}
                  width={b.FrameTemplateL.width}
                  x={0}
                />
              ) : null}
              <FrameLabel
                depth={b.FrameTemplateR.depth}
                label={b.FrameTemplateR.name}
                width={b.FrameTemplateR.width}
                x={b.width}
              />
            </Container>
          );
        })}
      </Rack>
    ));
  }, [warehouse?.racks]);

  const memoAreas = useMemo(() => {
    return areas.map((a) => (
      <Area
        key={a.id}
        maxX={a.maxX}
        maxY={a.maxY}
        minX={a.minX}
        minY={a.minY}
        name={a.name}
      />
    ));
  }, [areas]);

  if (isLoading || isLoadingPermission) {
    return <Loading text="Loading Blueprint" />;
  }

  return (
    <MainAndSidebar>
      <SidebarButton
        onToggleSidebar={() => setShowSidebar((s) => !s)}
        showSidebar={showSidebar}
      />
      {showSidebar ? (
        <Sidebar>
          <BuildModeSidebar
            SiteId={SiteId}
            WarehouseId={WarehouseId}
            permission={permission}
          />
        </Sidebar>
      ) : null}
      <MainContainer>
        <NavigationBar>
          <NavigationSection>
            <BackLink to={`/clients/sites/${SiteId}/overview`}>
              <FontAwesomeIcon fixedWidth icon={faChevronLeft} /> Site Overview
            </BackLink>
            <ClientLogo src={site?.ClientLogoUrl} />
            <FilterChip
              onChipPress={() => navigate(`/clients/sites/${SiteId}/overview`)}
              text={site?.SiteName ?? site?.name}
              type="sites"
            />
          </NavigationSection>
          <NavigationSection>
            <RoundButton
              active={isPanLocked}
              onClick={() => setPanLock((z) => !z)}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </RoundButton>
            <RoundButton onClick={focusOnWarehouse}>
              <FontAwesomeIcon icon={faExpand} />
            </RoundButton>
            <BlueprintFilterOptions
              components={[]}
              hideAmber={filters.hideAmber}
              hideGreen={filters.hideGreen}
              hideRed={filters.hideRed}
              selectedComponent={filters.searchText}
              setColorFilters={(e) => setFilters((f) => ({ ...f, ...e }))}
              setSelectedComponent={(e) =>
                setFilters((f) => ({ ...f, searchText: e }))
              }
            />
          </NavigationSection>
        </NavigationBar>
        <StageContainer ref={stageContainerRef}>
          <StageViewport
            onScale={(e) => gridRef.current?.setScaleFromPixiEvent(e)}
            ref={viewportRef}
            screenHeight={height}
            screenWidth={width}
            worldHeight={warehouse.sizeY}
            worldWidth={warehouse.sizeX}
          >
            <Grid
              ref={gridRef}
              sizeX={warehouse.sizeX}
              sizeY={warehouse.sizeY}
            />
            {memoAreas}
            {memoRacks}
            {isPanLocked ? (
              <ZoomSelect
                height={warehouse.sizeY}
                width={warehouse.sizeX}
                zoomTo={handleFocus}
              />
            ) : null}
          </StageViewport>
        </StageContainer>
        <BottomTabs
          activeKey={WarehouseId}
          id={warehouseTabsId}
          onSelect={(key) => {
            if (key === "CREATE") {
              navigate(`/clients/sites/${SiteId}/warehouses/add`);
            } else {
              setAllowFocus();
              navigate(`/blueprint/${SiteId}/${key}`);
            }
          }}
        >
          {site.warehouses.map(({ id, name }) => (
            <Tab eventKey={id} key={id} title={name} />
          ))}
          <Tab eventKey={"CREATE"} title={<FontAwesomeIcon icon={faPlus} />} />
        </BottomTabs>
      </MainContainer>
    </MainAndSidebar>
  );
}

PageBlueprintBuild.propTypes = {
  SiteId: PropTypes.string.isRequired,
  WarehouseId: PropTypes.string,
};
