export function getSentryEnvironment() {
  if (process.env.NODE_ENV !== 'production') {
    return 'development';
  }

  if (!process.env.REACT_APP_STAGING) {
    return 'production';
  }

  if (
    process.env.REACT_APP_IS_PULL_REQUEST === true ||
    process.env.REACT_APP_IS_PULL_REQUEST === 'true'
  ) {
    return 'preview';
  }

  return 'staging';
}
