import PropTypes from "prop-types";
import { FormGroup, Col } from "react-bootstrap";

import { useBasePlateTypes } from "hooks/types";

import {
  ControlLabelWithLoading,
  StyledFormControl,
} from "../../SharedControls/Generic";

export default function BasePlate({
  disabled,
  BasePlateId,
  ManufacturerId,
  onChange,
  SeriesId,
}) {
  const { basePlateTypes, isLoading } = useBasePlateTypes(ManufacturerId, {
    SeriesId,
  });
  const localDisabled = disabled || isLoading || basePlateTypes.length === 0;
  return (
    <FormGroup>
      <Col sm={6}>
        <ControlLabelWithLoading
          disabled={disabled}
          isLoading={isLoading}
          label="Base Plate"
        />
      </Col>
      <Col sm={6}>
        <StyledFormControl
          componentClass="select"
          disabled={localDisabled}
          onChange={(e) => onChange(e.target.value)}
          value={BasePlateId ?? ""}
        >
          {basePlateTypes.length === 0 && !isLoading ? (
            <option disabled selected>
              No base plates found
            </option>
          ) : null}
          {basePlateTypes.length > 0 && (
            <option disabled value="">
              Select Base Plate...
            </option>
          )}
          {basePlateTypes.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </StyledFormControl>
      </Col>
    </FormGroup>
  );
}

BasePlate.propTypes = {
  disabled: PropTypes.bool,
  BasePlateId: PropTypes.string,
  ManufacturerId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  SeriesId: PropTypes.string,
};
