// Function to rotate a point around the origin
function rotatePoint(point, origin, angle) {
  let rotatedX =
    Math.cos(angle) * (point.x - origin.x) -
    Math.sin(angle) * (point.y - origin.y) +
    origin.x;
  let rotatedY =
    Math.sin(angle) * (point.x - origin.x) +
    Math.cos(angle) * (point.y - origin.y) +
    origin.y;
  return { x: Math.round(rotatedX), y: Math.round(rotatedY) };
}

/**
 * Given a rectangle's origin, width, depth, and angle, return the coordinates of its corners
 *
 * If the width and depth are negative then swap the bottom/top, left/right as appropriate
 * This is needed for where we are offsetting with negative offsets to move left
 *
 * @param {number} originX The x coordinate of the origin of the rectangle
 * @param {number} originY The y coordinate of the origin of the rectangle
 */
export function rectangleCorners(originX, originY, width, depth, angle) {
  if (
    isNaN(originX) ||
    isNaN(originY) ||
    isNaN(width) ||
    isNaN(depth) ||
    isNaN(angle)
  ) {
    throw new Error("rectangleCorners requires numbers for all arguments");
  }
  // Convert the angle to radians
  let angleRad = (angle * Math.PI) / 180;

  // Calculate the coordinates of the rectangle before rotation
  let topLeft = { x: originX, y: originY };
  let topRight = { x: originX + width, y: originY };
  let bottomLeft = { x: originX, y: originY + depth };
  let bottomRight = { x: originX + width, y: originY + depth };

  // Rotate each corner
  let rotatedTopLeft = rotatePoint(topLeft, topLeft, angleRad);
  let rotatedTopRight = rotatePoint(topRight, topLeft, angleRad);
  let rotatedBottomLeft = rotatePoint(bottomLeft, topLeft, angleRad);
  let rotatedBottomRight = rotatePoint(bottomRight, topLeft, angleRad);

  const rect = {
    topLeft: roundXY(rotatedTopLeft),
    topRight: roundXY(rotatedTopRight),
    bottomLeft: roundXY(rotatedBottomLeft),
    bottomRight: roundXY(rotatedBottomRight),
  };

  const shouldFlipX = width < 0;
  const shouldFlipY = depth < 0;

  if (shouldFlipX) {
    const tempTop = rect.topLeft;
    rect.topLeft = rect.topRight;
    rect.topRight = tempTop;

    const tempBottom = rect.bottomLeft;
    rect.bottomLeft = rect.bottomRight;
    rect.bottomRight = tempBottom;
  }

  if (shouldFlipY) {
    const tempLeft = rect.topLeft;
    rect.topLeft = rect.bottomLeft;
    rect.bottomLeft = tempLeft;

    const tempRight = rect.topRight;
    rect.topRight = rect.bottomRight;
    rect.bottomRight = tempRight;
  }

  return rect;
}

function roundXY(point) {
  if (isNaN(point?.x) || isNaN(point?.y)) {
    throw new Error("roundXY requires an object with x and y properties");
  }

  return {
    x: Math.round(point.x),
    y: Math.round(point.y),
  };
}

/**
 * Given a warehouse, calculate the dimensions of the racks within it,
 * i.e. the area of the warehouse with things in it.
 */
export function calculateWarehouseDimensions(warehouse) {
  // If there is no warehouse, then return zeros
  if (!warehouse) {
    return {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    };
  }

  // Default for if there are no racks in the warehouse
  if (!warehouse.racks || warehouse.racks.length === 0) {
    return {
      minX: 0,
      maxX: warehouse?.sizeX ?? 0,
      minY: 0,
      maxY: warehouse?.sizeY ?? 0,
    };
  }

  const racksWithCornerCoordinates = warehouse.racks.map((r) => {
    const { originX, originY, totalX, totalY, rotation } = r;
    const { topLeft, topRight, bottomLeft, bottomRight } = rectangleCorners(
      originX,
      originY,
      totalX,
      totalY,
      rotation,
    );

    return {
      ...r,
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
    };
  });

  const allX = racksWithCornerCoordinates
    .map((r) => [r.topLeft.x, r.topRight.x, r.bottomLeft.x, r.bottomRight.x])
    .flat();
  const allY = racksWithCornerCoordinates
    .map((r) => [r.topLeft.y, r.topRight.y, r.bottomLeft.y, r.bottomRight.y])
    .flat();

  return {
    minX: Math.min(...allX),
    maxX: Math.max(...allX),
    minY: Math.min(...allY),
    maxY: Math.max(...allY),
  };
}

// Note: we can't use the racks in the area object, as they dont include
// totalX and totalY, which we need to calculate the area dimensions
export function calculateAreaMinMaxXY(area, racks) {
  const rackIdsInArea = area.racks.map((r) => r.id);

  // Construct an array of racks with the necessary information
  const racksInAreaWithDimensions = rackIdsInArea
    .map((rackId) => {
      const rack = racks.find((r) => r.id === rackId);
      if (!rack) {
        // We don't throw an error here, as the data comes from different endpoints
        // so if it is not found, we assume it will settle eventually.
        return false;
      }

      const { id, originX, originY, totalX, totalY, rotation } = rack;

      return {
        id,
        originX,
        originY,
        totalX,
        totalY,
        rotation,
      };
    })
    .filter(Boolean);

  // For each of the racks with dimensions, get the corner coordinates of the rack
  // when it is placed and rotated on the blueprint.
  const racksInAreaWithCornerCoordinates = racksInAreaWithDimensions.map(
    (rack) => {
      const { originX, originY, totalX, totalY, rotation } = rack;
      const { topLeft, topRight, bottomLeft, bottomRight } = rectangleCorners(
        originX,
        originY,
        totalX,
        totalY,
        rotation,
      );
      return {
        ...rack,
        topLeft,
        topRight,
        bottomLeft,
        bottomRight,
      };
    },
  );

  // Flatten all the x and y coords to an array.
  const allX = racksInAreaWithCornerCoordinates
    .map((r) => [r.topLeft.x, r.topRight.x, r.bottomLeft.x, r.bottomRight.x])
    .flat();
  const allY = racksInAreaWithCornerCoordinates
    .map((r) => [r.topLeft.y, r.topRight.y, r.bottomLeft.y, r.bottomRight.y])
    .flat();

  // Return the max and min for each x and y to get the coords of our area.
  return {
    id: area.id,
    name: area.name,
    minX: Math.min(...allX),
    maxX: Math.max(...allX),
    minY: Math.min(...allY),
    maxY: Math.max(...allY),
  };
}

/**
 * Used to extract data from a rack from the API, used in the conversion to the
 * structure that is used by the blueprint. Note: this is also useful for
 * determining what data is consumed by the front end, and what we might be able
 * to remove when refactoring the API.
 */
function extractRackInformation(rack) {
  const {
    name,
    id,
    originX,
    originY,
    orientation,
    arrangement,
    totalX,
    totalY,
    rotation,
    AreaId, // Required for build mode sidebar areas editor
    inspected, // Required for setting low opacity on non-inspected racks if an isolated or partial inspection
  } = rack;

  return {
    name,
    id,
    originX,
    originY,
    orientation,
    arrangement,
    totalX,
    totalY,
    AreaId,
    rotation,
    inspected,
  };
}

function sortBayByLocationAndArrangement(a, b, arrangement) {
  const byBayOrder = a.bayOrder < b.bayOrder ? -1 : 1;
  return arrangement ? byBayOrder * -1 : byBayOrder;
}

function findFrameTemplate(frameTemplates, frameId) {
  return frameTemplates.find((frameTemplate) => frameTemplate.id === frameId);
}

/**
 * Layout information is attached to each bay to make the blueprint rendering
 * simple. The blueprint will just render what it's told, we can tweak the
 * data here.
 */
function addBayLayoutInfo(rack, bayTemplates = [], frameTemplates = []) {
  return [...rack.bays]
    .sort((a, b) => sortBayByLocationAndArrangement(a, b, rack.arrangement))
    .reduce((acc, cur, idx, arr) => {
      const bayTemplate = bayTemplates.find(
        (bt) => bt.id === cur.BayTemplateId,
      );

      /**
       * If the rack arrangment is reversed, we also need to flip the left and right frames
       */
      const FrameTemplateL = findFrameTemplate(frameTemplates, rack.arrangement ? cur.frameR.FrameTemplateId : cur.frameL.FrameTemplateId); // prettier-ignore
      const FrameTemplateR = findFrameTemplate(frameTemplates, rack.arrangement ? cur.frameL.FrameTemplateId : cur.frameR.FrameTemplateId); // prettier-ignore
      const frameL = rack.arrangement ? cur.frameR : cur.frameL;
      const frameR = rack.arrangement ? cur.frameL : cur.frameR;

      const bayTemplateWidth = bayTemplate.width;
      const frameLWidth = FrameTemplateL.width;
      const frameRWidth = FrameTemplateR.width;
      /**
       * Find the current bay width.
       * The offset is the sum of the previous bays widths (that include the frames)
       * Our current offset is the offset of the previous, plus width of the previous
       *
       * If we are the first bay, then the left frame is not shared, so include the full
       * width, and not just half the width.
       *
       * If we are the last bay then the right frame is not shared, so include the full width
       * not just half the width.
       */
      const curWidth =
        bayTemplateWidth +
        (idx === 0 ? frameLWidth : frameLWidth / 2) +
        (idx === arr.length - 1 ? frameRWidth : frameRWidth / 2);
      const curOffset = acc.length
        ? acc[acc.length - 1].offset + acc[acc.length - 1].width
        : 0;

      const bay = {
        ...cur,
        frameL,
        frameR,
        offset: curOffset,
        width: curWidth,
        FrameTemplateL: {
          depth: FrameTemplateL.depth,
          name: FrameTemplateL.name,
          width: frameLWidth,
        },
        FrameTemplateR: {
          depth: FrameTemplateR.depth,
          name: FrameTemplateR.name,
          width: frameRWidth,
        },
        bayTemplate: {
          name: bayTemplate.name,
        },
      };

      return acc.concat(bay);
    }, []);
}

/**
 * Convert rack and blueprint data into a structure for the blueprint component
 */
export function rackToBlueprintData(r, bayTemplates, frameTemplates) {
  if (!r || !bayTemplates) {
    return null;
  }
  const info = extractRackInformation(r);
  return {
    ...info,
    bays: addBayLayoutInfo(r, bayTemplates, frameTemplates),
  };
}

// Pick the damage out of the damage types tree
export function getDamage(damage, keys) {
  if (damage === undefined) return;
  if (Object.keys(damage).length === 0)
    return { displayDamage: "None", classifications: [] };
  if (keys?.length > 0) {
    return getDamage(damage[keys[0]], keys.slice(1));
  }
  return damage;
}

/**
 * Calculates totalX of a rack, taking into account deleted bays
 */
export function calculateTotalXFromBays(bays, bayTemplates, frameTemplates) {
  return bays
    .filter((b) => !b.deleted)
    .reduce((acc, cur) => {
      const bayTemplate = bayTemplates.find(
        (bt) => bt.id === cur.BayTemplateId,
      );

      const FrameTemplateL = findFrameTemplate(
        frameTemplates,
        cur.FrameTemplateLId,
      );
      const FrameTemplateR = findFrameTemplate(
        frameTemplates,
        cur.FrameTemplateRId,
      );

      const bayTemplateWidth = bayTemplate.width;
      const frameLWidth = FrameTemplateL.width;
      const frameRWidth = FrameTemplateR.width;

      const curWidth = bayTemplateWidth + frameLWidth / 2 + frameRWidth / 2;

      return acc + curWidth;
    }, 0);
}
