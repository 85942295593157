import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

export const ControlsWrapper = styled.div`
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
`;

export const FiltersWrapper = styled.div`
  display: flex;
`;

export const ControlsButtonWrapper = styled.div`
  margin-left: auto;
`;

export const ControlsButton = styled(Button)`
  height: 40px;
  width: 175px;
  padding: 6px 12px;
  margin: 0;
`;
