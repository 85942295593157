/* eslint-disable react/function-component-definition */

import { useLocation, useNavigate, useParams } from "react-router-dom"; // compat

import { useCurrentUser } from "hooks/user";

export default function WithCompatWrap(Component) {
  const WithCompatWrapComponent = (props) => {
    const { user } = useCurrentUser();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        history={{ push: (url) => navigate(url), goBack: () => navigate(-1) }}
        location={location}
        match={{ params }}
        navigate={navigate}
        user={user}
      />
    );
  };
  return WithCompatWrapComponent;
}
