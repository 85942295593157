import "@pixi/events";
import { Stage } from "@pixi/react";

import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import PixiViewport from "views/components/Blueprint/canvas-elements/viewport";

const stageOptions = {
  antialias: true,
  autoDensity: true,
  resolution: window.devicePixelRatio,
  backgroundColor: 0x222222,
  backgroundAlpha: 1,
};

/**
 * Render a PIXI stage with a viewport for scaling and panning.
 */
const StageViewport = forwardRef(
  (
    {
      drag = true,
      screenHeight,
      screenWidth,
      worldHeight,
      worldWidth,
      onScale,
      children,
    },
    ref,
  ) => {
    return (
      <Stage
        height={screenHeight - 1}
        options={stageOptions}
        width={screenWidth}
      >
        <PixiViewport
          drag={drag}
          onZoomedEnd={onScale}
          ref={ref}
          screenHeight={screenHeight}
          screenWidth={screenWidth}
          worldHeight={worldHeight}
          worldWidth={worldWidth}
        >
          {children}
        </PixiViewport>
      </Stage>
    );
  },
);

StageViewport.displayName = "StageViewport";

StageViewport.propTypes = {
  children: PropTypes.node.isRequired,
  drag: PropTypes.bool,
  onScale: PropTypes.func.isRequired,
  screenHeight: PropTypes.number.isRequired,
  screenWidth: PropTypes.number.isRequired,
  worldHeight: PropTypes.number.isRequired,
  worldWidth: PropTypes.number.isRequired,
};

export default React.memo(StageViewport);
