import styled from "@emotion/styled";

import { useCurrentUser } from "hooks/user";
import ClientList from "../ClientList";
import { useState } from "react";

const Wrapper = styled.div`
  color: #ffffff;
  border-bottom: 1px solid #444;
  padding: 15px;
`;

const SubTitle = styled.div`
  color: #898b90;
  font-size: 10px;
  margin-top: 5px;
  overflow-y: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const ClientsLink = styled.a`
  color: #ffffff;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    text-decoration: none;
  }
`;

export default function UserWidget() {
  const { user, isLoading, isError } = useCurrentUser();
  const [showClientList, setShowClientList] = useState(false);

  if (isLoading || isError) return null;

  const {
    organisations,
    organisationsAdmin,
    clientOrganisation,
    client,
    clientAdmin,
    siteClient,
    siteGroupClient,
    installerOrganisationClient,
    mainInstallerOrganisationName,
  } = user;

  const onLinkClick = (e) => {
    e.preventDefault();
    setShowClientList(!showClientList);
  };

  return (
    <Wrapper>
      <div>{user.name}</div>
      <SubTitle>
        {!!organisations.length && (
          <>
            <div>Org Management</div>
            <div className="access-name">{`${organisations.map(
              (organisation) => `${organisation.name}`,
            )}`}</div>
          </>
        )}
        {!!organisationsAdmin.length && (
          <>
            <div>Org Admin</div>
            <div className="access-name">{`${organisationsAdmin.map(
              (organisation) => `${organisation.name}`,
            )}`}</div>
          </>
        )}
        {!!clientOrganisation.length && (
          <>
            <div>Org User</div>
            <div className="access-name">{`${clientOrganisation.map(
              (c) => `${c.name}`,
            )}`}</div>
          </>
        )}
        {!organisations.length &&
          !organisationsAdmin.length &&
          !!client.length && (
            <>
              <div>Management User</div>
              <div className="access-name">{`${client.map(
                (organisation) => `${organisation.name}`,
              )}`}</div>
            </>
          )}
        {!organisations.length &&
          !organisationsAdmin.length &&
          !!clientAdmin.length && (
            <>
              <div>Admin User</div>
              <div className="access-name">{`${clientAdmin.map(
                (organisation) => ` ${organisation.name}`,
              )}`}</div>
            </>
          )}
        {!organisations.length &&
          !organisationsAdmin.length &&
          !!siteClient.length && (
            <>
              <div>Site User</div>
              <div className="access-name">{`${siteClient.length} Site${
                siteClient.length > 1 ? "s" : ""
              }`}</div>
            </>
          )}
        {!!siteGroupClient.length && (
          <>
            <div>Group User</div>
            <div className="access-name">{`${siteGroupClient.length} Group${
              siteGroupClient.length > 1 ? "s" : ""
            }`}</div>
          </>
        )}
        {mainInstallerOrganisationName && user.userType === "Installer" && (
          <div>
            {mainInstallerOrganisationName} (
            <ClientsLink href to="" onClick={onLinkClick}>
              {`${showClientList ? "Hide" : "Show"} ${
                installerOrganisationClient?.length
              } Clients`}
            </ClientsLink>
            )
            {showClientList && (
              <ClientList clients={installerOrganisationClient ?? []} />
            )}
          </div>
        )}
        {!(
          !siteClient.length &&
          !client.length &&
          !clientAdmin.length &&
          !organisationsAdmin.length &&
          !organisations.length &&
          !clientOrganisation.length &&
          !siteGroupClient.length &&
          !installerOrganisationClient.length &&
          !user.loading
        ) || <div>No Assigned Items</div>}
      </SubTitle>
    </Wrapper>
  );
}
