/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";

export default function TableHeader({
  largeLeftTitle,
  largeLeftTagline,
  left,
  right,
}) {
  return (
    <div
      css={{
        width: "100%",
        padding: "10px",
        fontWeight: "bold",
        fontSize: 12,
        backgroundColor: "#E2E2E4",
        color: "#636363",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "5px",
      }}
    >
      <span>
        {left || (
          <span>
            <span
              css={css`
                font-size: 15px;
              `}
            >
              {largeLeftTitle}
            </span>
            <span>{largeLeftTagline && ` | ${largeLeftTagline}`}</span>
          </span>
        )}
      </span>
      <span
        css={{
          float: "right",
        }}
      >
        {right}
      </span>
    </div>
  );
}

TableHeader.propTypes = {
  largeLeftTagline: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.node,
  largeLeftTitle: PropTypes.string,
};
