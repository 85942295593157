import styled from "@emotion/styled";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Nav, NavItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const BackLinkWrap = styled(NavItem)`
  background-color: #30333b;
  width: 50px;

  a,
  a.active {
    color: white;
  }
`;

export function BackLink({ to }) {
  return (
    <BackLinkWrap>
      <NavLink to={to}>
        <FontAwesomeIcon fixedWidth icon={faChevronLeft} />
      </NavLink>
    </BackLinkWrap>
  );
}

BackLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export const StyledNav = styled(Nav)`
  a {
    color: white;
    text-decoration: none;

    &.active,
    &:hover {
      color: #ffcf33;
    }
  }
`;
