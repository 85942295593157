/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";

import { StyledFormControl, YellowHeading } from "../../SharedControls/Generic";

import Bay from "./Bay";

// eslint-disable-next-line no-unused-vars
const Wrapper = styled.div`
  background-color: #30333c;
  padding: 5px 20px;

  input,
  select {
    background-color: #494c55 !important;
  }

  td,
  th {
    border: 0 !important;
    padding: 5px 10px 0 0 !important;
  }
`;

export default function SectionBays({
  bayTemplates = [],
  depth,
  frameTemplates = [],
  onAddBay,
  onDeleteBay,
  onUpdateBay,
  onUpdateFrameTemplate,
  rack,
}) {
  const [addBayNumber, setAddBayNumber] = useState(1);
  const [selectedBayTemplateId, setSelectedBayTemplateId] = useState(null);
  const [selectedFrameTemplateId, setSelectedFrameTemplateId] = useState(null);

  // Select the first bay template if it's the only bay template
  useEffect(() => {
    if (bayTemplates.length === 1) {
      setSelectedBayTemplateId(bayTemplates[0].id);
    }
  }, [bayTemplates]);

  // Select the first frame template if it's the only frame template
  useEffect(() => {
    if (frameTemplates.length === 1) {
      setSelectedFrameTemplateId(frameTemplates[0].id);
    }
  }, [frameTemplates]);

  return (
    <Wrapper>
      <YellowHeading disabled={!depth}>Add Bays</YellowHeading>
      <Table responsive>
        <thead>
          <tr>
            <th>Number</th>
            <th>Type</th>
            <th>Frame</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <StyledFormControl
                disabled={!rack.ManufacturerId || !depth}
                onChange={(e) => setAddBayNumber(parseInt(e.target.value, 10))}
                style={{ width: "60px" }}
                type="number"
                value={addBayNumber}
              />
            </td>
            <td>
              <StyledFormControl
                componentClass="select"
                disabled={!rack.ManufacturerId || !depth}
                onChange={(e) => setSelectedBayTemplateId(e.target.value)}
                placeholder="Bay Types"
                value={selectedBayTemplateId ?? ""}
              >
                <option disabled value="">
                  Please select
                </option>
                {bayTemplates.map((bayTemplate) => (
                  <option key={bayTemplate.id} value={bayTemplate.id}>
                    {bayTemplate.name}
                  </option>
                ))}
              </StyledFormControl>
            </td>
            <td>
              <StyledFormControl
                componentClass="select"
                disabled={!rack.ManufacturerId || !depth}
                onChange={(e) => setSelectedFrameTemplateId(e.target.value)}
                placeholder="Frame Types"
                value={selectedFrameTemplateId ?? ""}
              >
                <option disabled value="">
                  Please select
                </option>
                {frameTemplates.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </StyledFormControl>
            </td>
            <td>
              <Button
                className="action-btn"
                disabled={
                  !rack.ManufacturerId ||
                  !depth ||
                  !selectedBayTemplateId ||
                  !selectedFrameTemplateId
                }
                onClick={() =>
                  onAddBay(
                    {
                      BayTemplateId: selectedBayTemplateId,
                      FrameTemplateId: selectedFrameTemplateId,
                    },
                    addBayNumber,
                  )
                }
              >
                Add Bay
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <YellowHeading>Added Bays</YellowHeading>
      <Table className="bay-table" responsive>
        <thead>
          <tr>
            <th>Loc</th>
            <th>Name</th>
            <th>Frame Left</th>
            <th>Frame Right</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {rack.bays?.length > 0 ? (
            rack.bays
              .map((b, i) => ({ ...b, originalIndex: i })) // Record the original index for deletion
              .filter((b) => !b.deleted) // Remove the ones marked as deleted
              .map((b, i) => ({ ...b, location: i + 1 })) // Give every non-deleted bay a location
              .map((bay, i) => (
                <Bay
                  bay={bay}
                  bayTemplates={bayTemplates}
                  frameTemplates={frameTemplates}
                  key={`${bay.location}-${bay.BayTemplateId}-${bay.FrameTemplateLId}-${bay.FrameTemplateRId}`}
                  onDeleteBay={() => {
                    onDeleteBay(bay.originalIndex);
                  }}
                  onUpdateBay={(fields) =>
                    onUpdateBay(bay.originalIndex, fields)
                  }
                  onUpdateFrameTemplate={(frameTemplateId, isLeftFrame) =>
                    onUpdateFrameTemplate(i, frameTemplateId, isLeftFrame)
                  }
                  rack={rack}
                />
              ))
          ) : (
            <tr>
              <td colSpan="4">
                <h5>Added Bays will appear here</h5>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Wrapper>
  );
}

SectionBays.propTypes = {
  rack: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    bays: PropTypes.array,
    ManufacturerId: PropTypes.string,
  }),
  depth: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  bayTemplates: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  frameTemplates: PropTypes.array,
  onDeleteBay: PropTypes.func.isRequired,
  onUpdateFrameTemplate: PropTypes.func.isRequired,
  onAddBay: PropTypes.func.isRequired,
  onUpdateBay: PropTypes.func.isRequired,
};
