import styled from "@emotion/styled";
import * as Switch from "@radix-ui/react-switch";
import PropTypes from "prop-types";

import { ControlLabelWithLoading } from "views/components/BuildModeSidebar/SharedControls/Generic";

const Wrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;

const Label = styled.label`
  color: #fff;
  font-weight: 800;
  width: 50%;
`;

const Control = styled.div`
  width: 50%;
`;

const SwitchRoot = styled(Switch.Root)`
  all: unset;

  width: 42px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 9999px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3);
  }

  &[data-state="checked"] {
    background-color: #2d72ed;
  }
`;

const SwitchThumb = styled(Switch.Thumb)`
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;

  &[data-state="checked"] {
    transform: translateX(19px);
  }
`;

export default function FieldOrientation({ orientation, disabled, onUpdate }) {
  return (
    <Wrapper>
      <Label>
        <ControlLabelWithLoading disabled={disabled}>
          Flip front orientation?
        </ControlLabelWithLoading>
      </Label>
      <Control>
        <SwitchRoot
          checked={orientation}
          onCheckedChange={() => onUpdate({ orientation: !orientation })}
        >
          <SwitchThumb className="SwitchThumb" />
        </SwitchRoot>
      </Control>
    </Wrapper>
  );
}

FieldOrientation.propTypes = {
  orientation: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
