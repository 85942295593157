import styled from "@emotion/styled";
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";

const Warehouse = styled.div`
  color: #666666;
  font-size: 12px;
  margin: 10px 0;

  .progress {
    background-color: #d8d8d8;
    width: 70%;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    height: 10px;
    margin-bottom: 0;
    .progress-bar {
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
      background-color: #fece47;
    }
  }
`;

const WarehousesWrapper = styled.div`
  padding: 10px;
  margin-bottom: 10px;
`;

const WarehouseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WarehouseName = styled.div`
  display: inline-block;
  width: 30%;
  svg {
    color: ${(props) => (props.isCompleted ? "#238823" : "#7E8496")};
    margin-right: 10px;
  }
`;

const Areas = styled.div`
  margin-left: 20px;
`;
const Area = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const AreaName = styled(WarehouseName)``;

const PostProgress = styled.span`
  width: 20%;
  float: right;
  text-align: right;
  padding-right: 15px;
`;

// Shows progress for warehouses and areas
export default function WarehouseProgress({ warehouses }) {
  return (
    <WarehousesWrapper>
      {warehouses.map((warehouse) => (
        <Warehouse key={warehouse.id}>
          <WarehouseHeader>
            <WarehouseName isCompleted={warehouse.completePercentage === 100}>
              <FontAwesomeIcon
                icon={
                  warehouse.completePercentage === 100
                    ? faCircleCheck
                    : faCircle
                }
                size="xl"
              />
              {warehouse.name}
            </WarehouseName>

            {warehouse.progressOverride ? (
              <div>{warehouse.progressOverride}</div>
            ) : (
              <>
                <ProgressBar now={warehouse.completePercentage} />
                {warehouse.postProgress ? (
                  <PostProgress>{warehouse.postProgress}</PostProgress>
                ) : null}
              </>
            )}
          </WarehouseHeader>
          <Areas>
            {warehouse.areas
              .filter((a) => a.name !== "All Areas")
              .map((area) => (
                <Area key={area.AreaId ?? area.id}>
                  <WarehouseHeader>
                    <AreaName isCompleted={area.completePercentage === 100}>
                      <FontAwesomeIcon
                        icon={
                          area.completePercentage === 100
                            ? faCircleCheck
                            : faCircle
                        }
                        size="xl"
                      />
                      {area.name}
                    </AreaName>
                    {area.progressOverride ? (
                      <div>{area.progressOverride}</div>
                    ) : (
                      <>
                        <ProgressBar now={area.completePercentage} />
                        {area.postProgress ? (
                          <PostProgress>{area.postProgress}</PostProgress>
                        ) : null}
                      </>
                    )}
                  </WarehouseHeader>
                </Area>
              ))}
          </Areas>
        </Warehouse>
      ))}
    </WarehousesWrapper>
  );
}

WarehouseProgress.propTypes = {
  warehouses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      completePercentage: PropTypes.number.isRequired,
      progressOverride: PropTypes.string, // Override the progress bar

      areas: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          completePercentage: PropTypes.number.isRequired,
          progressOverride: PropTypes.string, // Override the progress bar
        }),
      ),
    }),
  ),
};
