/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import DatePicker from "react-datepicker";

export default function DateRangeOption({
  title,
  startDate,
  endDate,
  startDateChangeFunc,
  endDateChangeFunc,
}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      css={css`
        padding: 10px;
        padding-bottom: 10px;
        color: #6c6c6c;
        .react-datepicker-wrapper > div > input {
          background-color: #f2f2f4 !important;
          color: #9b9b9c !important;
          border-radius: 5px;
          margin-bottom: 5px;
          margin-top: 10px;
          width: 95%;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
        }
        .react-datepicker-wrapper > div > input:nth-child(2) {
          float: right;
        }
        .react-datepicker-wrapper {
          width: 50%;
          display: inline-block;
        }
        .react-datepicker__input-container {
          width: 100%;
          display: inline-block;
        }
      `}
    >
      <div onClick={() => setExpanded(!expanded)}>
        {title}
        <span
          css={css`
            float: right;
          `}
        >
          <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
        </span>
      </div>
      {expanded && (
        <div>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            maxDate={endDate ? moment(endDate).toDate() : null}
            onChange={(date) => startDateChangeFunc(date)}
            placeholderText="Start Date"
            selected={
              startDate ? moment(startDate).toDate() : moment().toDate()
            }
          />
          <DatePicker
            dateFormat="dd/MM/yyyy"
            minDate={startDate ? moment(startDate).toDate() : null}
            onChange={(date) => endDateChangeFunc(date)}
            placeholderText="End Date"
            selected={endDate ? moment(endDate).toDate() : moment().toDate()}
          />
        </div>
      )}
    </div>
  );
}

DateRangeOption.propTypes = {
  title: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  startDateChangeFunc: PropTypes.func.isRequired,
  endDateChangeFunc: PropTypes.func.isRequired,
};
