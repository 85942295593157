import styled from "@emotion/styled";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { Alert, Button } from "react-bootstrap";

import { useInspection } from "hooks/inspections";
import { completeRack, uncompleteRack } from "libs/api";
const UPDATING_STATE = {
  completing: "completing",
  undoing: "undoing",
};

const CompleteButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isComplete",
})`
  &:disabled {
    background-color: #888 !important;
  }

  ${(props) =>
    props.isComplete ? "background-color: #ffcf33 !important;" : ""}
  width: 200px;
`;

function InspectionCompleteRack({
  InspectionId,
  RackId,
  isCompletable = true,
}) {
  const { inspection, mutate, isLoading, isError } =
    useInspection(InspectionId);
  const [updateError, setUpdateError] = useState(null);
  const [isUpdating, setIsUpdating] = useState(null);
  const isRackComplete = !!inspection?.completedInspectionRacks?.find(
    (r) => r.RackId === RackId,
  );

  if (isLoading) return null;
  return (
    <>
      <CompleteButton
        disabled={isUpdating || !isCompletable}
        isComplete={isRackComplete}
        onClick={async () => {
          setUpdateError(null);
          try {
            if (!isRackComplete) {
              setIsUpdating(UPDATING_STATE.completing);
              await completeRack(RackId, InspectionId);
            } else {
              setIsUpdating(UPDATING_STATE.undoing);
              await uncompleteRack(RackId, InspectionId);
            }
            await mutate();
          } catch (e) {
            setUpdateError("Error completing rack");
          }
          setIsUpdating(null);
        }}
      >
        {isUpdating === UPDATING_STATE.completing ? "Completing" : null}
        {isUpdating === UPDATING_STATE.undoing ? "Undoing" : null}
        {!isUpdating && isRackComplete ? "Undo Complete" : null}
        {!isUpdating && !isRackComplete ? "Complete Rack" : null}

        {isUpdating ? (
          <FontAwesomeIcon fixedWidth icon={faSpinner} spin />
        ) : null}
      </CompleteButton>
      {isCompletable ? null : (
        <div>
          The complete rack button will become active when all existing damage
          classifications have either been confirmed or reclassified.
        </div>
      )}
      {isError || updateError ? (
        <Alert bsStyle="danger">There was an error</Alert>
      ) : null}
    </>
  );
}

InspectionCompleteRack.propTypes = {
  InspectionId: PropTypes.string,
  RackId: PropTypes.string,
  isCompletable: PropTypes.bool,
};

export default InspectionCompleteRack;
