import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { GreenButton } from "./colors";

export default function InspectionRestoreButton({ disabled, onClick }) {
  return (
    <GreenButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={faUndo} size="2xl" />
      Restore
    </GreenButton>
  );
}

InspectionRestoreButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
