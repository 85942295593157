import PropTypes from "prop-types";

import ArchiveButton from "views/components/ActionButtons/button-archive";
import BookmarkButton from "views/components/ActionButtons/button-bookmark";
import InspectionDeleteButton from "views/components/ActionButtons/button-delete-inspection";
import DoActionButton from "views/components/ActionButtons/button-do-action";
import EditActionButton from "views/components/ActionButtons/button-edit-action";
import EditScheduleButton from "views/components/ActionButtons/button-edit-schedule";
import EditUserButton from "views/components/ActionButtons/button-edit-user";
import EditWarehouseButton from "views/components/ActionButtons/button-edit-warehouse";
import ExportButton from "views/components/ActionButtons/button-export";
import InspectionExportPDFButton from "views/components/ActionButtons/button-export-inspection";
import InspectionContinueButton from "views/components/ActionButtons/button-inspection-continue";
import InspectionReportButton from "views/components/ActionButtons/button-inspection-report";
import InspectionStartButton from "views/components/ActionButtons/button-inspection-start";
import PauseResumeScheduleButton from "views/components/ActionButtons/button-pause-resume-schedule";
import InspectionRestoreButton from "views/components/ActionButtons/button-restore-inspection";
import ShareButton from "views/components/ActionButtons/button-share";
import ShareInspectionButton from "views/components/ActionButtons/button-share-inspection";
import SiteOverviewButton from "views/components/ActionButtons/button-site-overview";
import ViewActionButton from "views/components/ActionButtons/button-view-action";
import ViewBlueprintButton from "views/components/ActionButtons/button-view-blueprint";
import InspectionViewButton from "views/components/ActionButtons/button-view-inspection";
import ViewWarehouseButton from "views/components/ActionButtons/button-view-warehouse";

function ActionButtonsOverlay({ children }) {
  return <td className="hover-buttons">{children}</td>;
}

ActionButtonsOverlay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array,
};

export {
  ActionButtonsOverlay,
  ArchiveButton,
  BookmarkButton,
  DoActionButton,
  EditActionButton,
  EditScheduleButton,
  EditUserButton,
  EditWarehouseButton,
  ExportButton,
  InspectionContinueButton,
  InspectionDeleteButton,
  InspectionExportPDFButton,
  InspectionReportButton,
  InspectionRestoreButton,
  InspectionStartButton,
  InspectionViewButton,
  PauseResumeScheduleButton,
  ShareButton,
  ShareInspectionButton,
  SiteOverviewButton,
  ViewActionButton,
  ViewBlueprintButton,
  ViewWarehouseButton,
};
