/* eslint-disable react/prop-types */
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
} from "react";

import ImageLightBox from "../../../components/Modals/ImageLightBox";

/**
 * The ClassificationsLightbox is used on the Inspections page in conjunction
 * with the classifications tables. The tables display the classifications and
 * images. When an image is clicked then this lightbox, rendered in the parent
 * is popped open with the classificationId and the image index that was clicked.
 *
 * The lightbox calls an images lightbox component, which allows for the deletion
 * and updating the captions of the images.
 */
// eslint-disable-next-line react/display-name
export default forwardRef(({ images = [], onDelete, onChangeCaption }, ref) => {
  const [currentImage, setCurrentImage] = useState(null);
  /**
   * This imperitive handle allows other components to open this lightbox with
   * an index.
   */
  const openWith = useCallback((idx) => {
    setCurrentImage(idx ?? 0);
  }, []);

  useImperativeHandle(ref, () => ({
    openWith,
  }));

  // Keep current image and images in sync for deletion, etc.
  useEffect(() => {
    if (currentImage === null) return;
    if (currentImage > images.length - 1) {
      setCurrentImage(images.length - 1);
    }

    if (currentImage < 0) {
      setCurrentImage(0);
    }
  }, [images, currentImage]);

  // Don't display the modal if we don't have images
  if (!images || images.length < 1 || currentImage === null) return null;

  return (
    <ImageLightBox
      // Protect against our index getting out of sync when deleting
      currentImage={currentImage}
      getImages={() => {}}
      hideSaveButton
      images={images
        .filter((i) => !!i.src)
        .map((i, idx) => ({ ...i, id: idx, newImage: true }))}
      isOpen={currentImage !== null}
      onChangeCaption={onChangeCaption}
      onClose={() => setCurrentImage(null)}
      onDelete={() => onDelete(currentImage)}
      onNext={() => setCurrentImage(currentImage + 1)}
      onPrevious={() => setCurrentImage(currentImage - 1)}
    />
  );
});
