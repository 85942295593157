import { rectangleCorners } from "libs/blueprint";

/**
 * A valid rack should contain at least one bay
 * The racks passed _should include the rack being created!
 */
export function getIsRackValid(rack) {
  const { bays = [] } = rack;
  return bays.length > 0 && rack?.name?.length > 0;
}

export function markBayDeleted(bayIndex, bays = []) {
  if (!Array.isArray(bays)) throw new Error("Bays must be an array");
  if (bayIndex < 0 || bayIndex + 1 > bays.length) return bays;
  if (bays.length > 0) {
    const leftBayIdx = bays.findLastIndex((b, i) => i < bayIndex && !b.deleted);
    const rightBayIdx = bays.findIndex((b, i) => i > bayIndex && !b.deleted);

    const updatedBays = [...bays];

    if (leftBayIdx > -1 && rightBayIdx > -1) {
      updatedBays[leftBayIdx].changed = true;
      updatedBays[leftBayIdx].FrameTemplateRId =
        updatedBays[rightBayIdx].FrameTemplateLId;
    }

    updatedBays[bayIndex].deleted = true;
    return updatedBays;
  }

  return [];
}

/**
 * Take an array of bays, the index to edit and the new FrameTemplateId, plus
 * whether it is the left or right frame being edited.
 *
 * Return an array of bays with the bay at the index edited, plus the appropriate
 * adjacent bay on the left or right.
 *
 */
export function updateFrameTemplate(
  index,
  FrameTemplateId,
  isLeftFrame,
  bays = [],
) {
  if (index < 0) return bays;

  if (bays.length > 0) {
    const updatedBays = [...bays];

    if (isLeftFrame) {
      updatedBays[index].FrameTemplateLId = FrameTemplateId;
      updatedBays[index].changed = true;

      const prevLeftNotDeletedIdx = updatedBays.findLastIndex(
        (b, i) => i < index && !b.deleted,
      );

      if (prevLeftNotDeletedIdx >= 0) {
        updatedBays[prevLeftNotDeletedIdx].FrameTemplateRId = FrameTemplateId;
        updatedBays[prevLeftNotDeletedIdx].changed = true;
      }

      return updatedBays;
    }

    // Right Frame
    updatedBays[index].FrameTemplateRId = FrameTemplateId;
    updatedBays[index].changed = true;

    const nextRightNotDeletedIdx = updatedBays.findIndex(
      (b, i) => i > index && !b.deleted,
    );
    if (nextRightNotDeletedIdx >= 0) {
      updatedBays[nextRightNotDeletedIdx].FrameTemplateLId = FrameTemplateId;
      updatedBays[nextRightNotDeletedIdx].changed = true;
    }

    return updatedBays;
  }

  return bays;
}

/**
 * The rack width is the width of all bays, and the shared frames
 * plus the width of the "first" frame.
 */
export function calculateRackWidth(
  rack,
  frameTemplates = [],
  bayTemplates = [],
) {
  if (!rack.bays) return 0;

  let rackWidth = 0;
  for (let i = 0; i < rack.bays.length; i += 1) {
    const bay = rack.bays[i];
    const rackBayTemplate = bayTemplates.find(
      (bt) => bay.BayTemplateId === bt.id,
    );
    const rackFrameLTemplate = frameTemplates.find(
      (ft) => bay.FrameTemplateLId === ft.id,
    );
    const rackFrameRTemplate = frameTemplates.find(
      (ft) => bay.FrameTemplateRId === ft.id,
    );

    if (!rackBayTemplate) {
      throw new Error("Bay Template not found for rack width calculation");
    }

    if (!rackFrameLTemplate || !rackFrameRTemplate) {
      throw new Error("Frame Template not found for rack width calculation");
    }

    rackWidth += rackBayTemplate.width;

    // This is the only left frame that isn't also a right frame of another bay
    if (i === 0) {
      rackWidth += parseInt(rackFrameLTemplate.width, 10);
    }

    rackWidth += parseInt(rackFrameRTemplate.width, 10);
  }
  return rackWidth;
}

export function addBayToBays(
  BayTemplateId,
  FrameTemplateId,
  numberToAdd = 1,
  bays = [],
) {
  // If we already have bays, then our leftmost Frame must match the rightmost of the existing bays
  if (!BayTemplateId || !FrameTemplateId) {
    throw new Error("Bay and Frame templates must both be specified");
  }

  if (typeof numberToAdd !== "number" || numberToAdd < 1) {
    throw new Error("Number of bays to add must be specified");
  }
  const firstAdded = {
    BayTemplateId,
    new: true,
    FrameTemplateRId: FrameTemplateId,
    FrameTemplateLId:
      bays.length > 0
        ? bays[bays.length - 1].FrameTemplateRId
        : FrameTemplateId,
  };
  const restAdded = {
    BayTemplateId,
    new: true,
    FrameTemplateRId: FrameTemplateId,
    FrameTemplateLId: FrameTemplateId,
  };
  const addedBays = Array.from({ length: numberToAdd }, () => ({
    ...restAdded,
  }));
  addedBays[0] = firstAdded;

  return [...bays, ...addedBays];
}

function calculateOppAdj(offset, rotation) {
  let rotationInRad = rotation * (Math.PI / 180);
  return {
    opp: Math.round(offset * Math.sin(rotationInRad)),
    adj: Math.round(offset * Math.cos(rotationInRad)),
  };
}

// eslint-disable-next-line no-unused-vars
function rotatePoint(origin, offset, angleDegrees) {
  // Convert degrees to radians
  const angleRadians = angleDegrees * (Math.PI / 180);

  // Calculate the new coordinates
  const rotatedX =
    Math.cos(angleRadians) * offset.x -
    Math.sin(angleRadians) * offset.y +
    origin.x;
  const rotatedY =
    Math.sin(angleRadians) * offset.x +
    Math.cos(angleRadians) * offset.y +
    origin.y;

  return { x: Math.round(rotatedX), y: Math.round(rotatedY) };
}

const DIRECTION_TO_OFFSET = {
  FRONT: "front",
  BACK: "back",
  LEFT: "left",
  RIGHT: "right",
};
function getDirectionConsideringFlippedFace(
  offsetDirection,
  flippedOrientation,
) {
  if (
    (offsetDirection === DIRECTION_TO_OFFSET.FRONT && !flippedOrientation) ||
    (offsetDirection === DIRECTION_TO_OFFSET.BACK && flippedOrientation)
  ) {
    return DIRECTION_TO_OFFSET.FRONT;
  }

  if (
    (offsetDirection === DIRECTION_TO_OFFSET.FRONT && flippedOrientation) ||
    (offsetDirection === DIRECTION_TO_OFFSET.BACK && !flippedOrientation)
  ) {
    return DIRECTION_TO_OFFSET.BACK;
  }

  if (
    (offsetDirection === DIRECTION_TO_OFFSET.LEFT && !flippedOrientation) ||
    (offsetDirection === DIRECTION_TO_OFFSET.RIGHT && flippedOrientation)
  ) {
    return DIRECTION_TO_OFFSET.LEFT;
  }

  if (
    (offsetDirection === DIRECTION_TO_OFFSET.LEFT && flippedOrientation) ||
    (offsetDirection === DIRECTION_TO_OFFSET.RIGHT && !flippedOrientation)
  ) {
    return DIRECTION_TO_OFFSET.RIGHT;
  }
}

function getOriginConsideringFlippedOrientation(
  corners,
  offsetDirection,
  flippedOrientation,
) {
  if (!flippedOrientation) {
    return corners["topLeft"];
  }

  if (offsetDirection === DIRECTION_TO_OFFSET.FRONT) {
    return corners["bottomLeft"];
  }

  if (offsetDirection === DIRECTION_TO_OFFSET.BACK) {
    return corners["topLeft"];
  }

  if (offsetDirection === DIRECTION_TO_OFFSET.LEFT) {
    return corners["topLeft"];
  }

  if (offsetDirection === DIRECTION_TO_OFFSET.RIGHT) {
    return corners["topRight"];
  }
}

export function calculateRelativeOrigins(
  rack, // The rack being added,
  compareRack, // The rack we are comparing to
  backToBack,
) {
  if (!rack.depth) {
    throw new Error("Rack depth must be specified");
  }

  // TODO: Rename compare corners from top/bottom/left/right to origin, originDepth, originLength, originDepthLength
  // This is because top/bottom/front/back/left/right should be relative to the rack orientation itself.
  // Get the four corners of the rack we are comparing to.
  const compareCorners = rectangleCorners(compareRack.originX, compareRack.originY, compareRack.totalX, compareRack.totalY, compareRack.rotation); // prettier-ignore
  const originConsideringFlippedOrientation =
    getOriginConsideringFlippedOrientation(
      compareCorners,
      rack.offsetDirection,
      compareRack.orientation,
    );
  /**
   * If the rack is back to back, then figure out if it should be placed at the origin,
   * or opposite the origin.
   *
   * Back to Back means no gap between the racks.
   */
  if (backToBack) {
    // Front is flipped, origin is bottom left
    if (compareRack.orientation) {
      const offset = calculateOppAdj(
        -(rack.depth + compareRack.totalY),
        compareRack.rotation,
      );
      return {
        x: originConsideringFlippedOrientation.x - offset.opp,
        y: originConsideringFlippedOrientation.y + offset.adj,
      };
    }
    // Front is at origin, top left
    const distance = rack.depth;
    const offset = calculateOppAdj(distance, compareRack.rotation);
    return {
      x: originConsideringFlippedOrientation.x - offset.opp,
      y: originConsideringFlippedOrientation.y + offset.adj,
    };
  }

  const direction = getDirectionConsideringFlippedFace(
    rack.offsetDirection,
    compareRack.orientation,
  );

  if (direction === DIRECTION_TO_OFFSET.LEFT) {
    const distance = compareRack.orientation
      ? rack.offset
      : rack.offset + rack.totalX;
    const offset = calculateOppAdj(distance, -compareRack.rotation);
    return {
      x: originConsideringFlippedOrientation.x + offset.adj,
      y: originConsideringFlippedOrientation.y - offset.opp,
    };
  }

  if (direction === DIRECTION_TO_OFFSET.RIGHT) {
    const offset = calculateOppAdj(
      rack.offset + rack.totalX,
      -compareRack.rotation,
    );
    return {
      x: originConsideringFlippedOrientation.x - offset.adj,
      y: originConsideringFlippedOrientation.y + offset.opp,
    };
  }

  if (direction === DIRECTION_TO_OFFSET.FRONT) {
    const offset = calculateOppAdj(
      -(rack.offset + rack.depth),
      compareRack.rotation,
    );
    return {
      x: originConsideringFlippedOrientation.x - offset.opp,
      y: originConsideringFlippedOrientation.y + offset.adj,
    };
  }

  const distance = compareRack.orientation
    ? rack.offset
    : rack.offset + rack.depth;
  const offset = calculateOppAdj(distance, compareRack.rotation);
  return {
    x: originConsideringFlippedOrientation.x - offset.opp,
    y: originConsideringFlippedOrientation.y + offset.adj,
  };
}
