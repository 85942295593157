import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  display: block;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
`;

export default function NavigationBar({ children }) {
  return (
    <Wrapper>
      <Inner>{children}</Inner>
    </Wrapper>
  );
}

NavigationBar.propTypes = {
  children: PropTypes.node.isRequired,
};
