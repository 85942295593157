import { useCallback } from "react";
import useSWR, { mutate as globalMutate } from "swr";

import { getActions, getActionStats, getInspectionActions } from "../libs/api";

export function useActions(filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    [`/actions/`, filters],
    async () => getActions(filters),
  );
  return {
    actions: data?.results ?? [],
    isLoading,
    isError: error,
    total: data?.total ?? 0,
    mutate,
    isValidating,
  };
}

export function useActionStats(filters) {
  const { data, error, mutate, isValidating } = useSWR(
    [`/action/stats/`, filters],
    async () => getActionStats(filters),
  );

  return {
    actionStats: data ?? {
      "To Do": 0,
      Completed: 0,
      "On Going": 0,
      Unfinished: 0,
      null: 0,
    },
    isValidating,
    mutate,
    isError: error,
    isLoading: !error && !data,
  };
}

export function useInspectionActions(InspectionId, filters) {
  const { data, error, mutate, isLoading, isValidating } = useSWR(
    [`/action/inspection/${InspectionId}`, filters],
    async () => getInspectionActions(InspectionId, filters),
  );

  const mutateWithGlobal = useCallback(() => {
    mutate();
    globalMutate((key) => {
      const shouldMutate =
        Array.isArray(key) &&
        key[0] === "/inspection/display" &&
        key[1].InspectionId === InspectionId;
      return shouldMutate;
    });
  }, [InspectionId, mutate]);

  return {
    warehouses: data ?? [],
    isLoading,
    isError: error,
    mutate: mutateWithGlobal,
    isValidating,
  };
}
