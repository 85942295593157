import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function getManufacturers(filters) {
  const res = await apiCall(`/manufacturer${filterUrlGenerator(filters)}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error("Problem getting Manufacturers");
}

export async function getSeries(ManufacturerId, filters) {
  const res = await apiCall(
    `/manufacturer/series/${ManufacturerId}${filterUrlGenerator(filters)}`,
  );
  if (res.status === 200) {
    return res.json();
  }
  throw new Error(`Problem getting series: ${res.status}`);
}
