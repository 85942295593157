import PropTypes from "prop-types";
import { FormGroup, Col } from "react-bootstrap";

import {
  ControlLabelWithLoading,
  StyledFormControl,
} from "../../SharedControls/Generic";

export default function Name({ name = "" }) {
  return (
    <FormGroup controlId="formInlineName">
      <Col sm={6}>
        <ControlLabelWithLoading disabled label="Name" />
      </Col>
      <Col sm={6}>
        <StyledFormControl
          disabled
          placeholder="Name"
          type="text"
          value={name}
        />
      </Col>
    </FormGroup>
  );
}

Name.propTypes = {
  name: PropTypes.string,
};
