import {
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";

import { confirmClassification, unconfirmClassification } from "libs/api";

import { RiskButton } from "views/pages/Inspection/styles";

// TODO: Add Error Handling and States here
/**
 * Button to confirm or unconfirm a classification during an inspection
 */
export default function ConfirmButton({
  ClassificationId,
  InspectionId,
  isConfirmed,
  onChange,
  risk,
}) {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isError, setIsError] = useState(false);

  if (isConfirmed) {
    return (
      <RiskButton
        onClick={async () => {
          try {
            setIsError(false);
            setIsConfirming(true);
            // TODO: Why oh god why would you pass an object for one func and arguments for another
            await unconfirmClassification({ ClassificationId, InspectionId });
            await onChange();
            setIsConfirming(false);
          } catch (e) {
            setIsError(true);
            setIsConfirming(false);
          }
        }}
        risk={risk}
        showRisk
      >
        Confirmed {isConfirming && <FontAwesomeIcon icon={faSpinner} spin />}{" "}
        {isError && <FontAwesomeIcon icon={faTriangleExclamation} />}
      </RiskButton>
    );
  }
  return (
    <RiskButton
      onClick={async () => {
        try {
          setIsError(false);
          setIsConfirming(true);
          await confirmClassification(ClassificationId, InspectionId);
          await onChange();
          setIsConfirming(false);
        } catch (e) {
          setIsError(true);
          setIsConfirming(false);
        }
      }}
      risk={risk}
    >
      Confirm {isConfirming && <FontAwesomeIcon icon={faSpinner} spin />}{" "}
      {isError && <FontAwesomeIcon icon={faTriangleExclamation} />}
    </RiskButton>
  );
}

ConfirmButton.propTypes = {
  risk: PropTypes.string.isRequired,
  ClassificationId: PropTypes.string.isRequired,
  InspectionId: PropTypes.string.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
