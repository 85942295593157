import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faMagnifyingGlassMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import {
  MiniFilterTab,
  MiniFilterWrapper,
} from "views/components/Tables/TableControls";

const MY_SCHEDULES_TAB = 0;
const MY_MANAGED_SCHEDULES_TAB = 1;
const MY_MISSED_SCHEDULES_TAB = 2;

export function MiniFilter({
  allSchedulesSelected,
  setAllSchedulesSelected,
  setPage,
  tabSelected,
}) {
  const scheduleIcon = tabSelected === 2 ? faMagnifyingGlassMinus : faCalendar;
  return (
    <MiniFilterWrapper>
      <MiniFilterTab
        onClick={() => {
          setAllSchedulesSelected(false);
          setPage(0);
        }}
        selected={!allSchedulesSelected}
      >
        {!allSchedulesSelected ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={scheduleIcon} />
        )}
        {tabSelected === MY_SCHEDULES_TAB && "My Schedules"}
        {tabSelected === MY_MANAGED_SCHEDULES_TAB && "My Managed Schedules"}
        {tabSelected === MY_MISSED_SCHEDULES_TAB && "My Missed Inspections"}
      </MiniFilterTab>
      <MiniFilterTab
        onClick={() => {
          setAllSchedulesSelected(true);
          setPage(0);
        }}
        selected={allSchedulesSelected}
      >
        {allSchedulesSelected ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={scheduleIcon} />
        )}
        {tabSelected === MY_SCHEDULES_TAB && "All Schedules"}
        {tabSelected === MY_MANAGED_SCHEDULES_TAB && "All Managed Schedules"}
        {tabSelected === MY_MISSED_SCHEDULES_TAB && "All Missed Inspections"}
      </MiniFilterTab>
    </MiniFilterWrapper>
  );
}

MiniFilter.propTypes = {
  allSchedulesSelected: PropTypes.bool,
  setAllSchedulesSelected: PropTypes.func,
  setPage: PropTypes.func,
  tabSelected: PropTypes.number,
};
