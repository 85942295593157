import styled from '@emotion/styled';

export const ControlsWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const ControlsButtonWrapper = styled.div`
  margin-left: auto;
  padding: 0px;
  .btn-group > .btn:first-of-type:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0px !important;
    width: 150px;
    border-bottom-right-radius: 0px !important;
    margin-right: 0px;
  }
  .dropdown-toggle {
    width: 25px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: 1px solid #1459d4;
  }
  .btn {
    margin: 0px;
    height: 40px;
    display: inline-block;
  }
  .btn span {
    padding: 0px;
  }
`;
