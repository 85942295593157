import styled from "@emotion/styled";
import { Table as BootstrapTable } from "react-bootstrap";

export const Table = styled(BootstrapTable, {
  // Don't pass our style props down to the dom element
  shouldForwardProp: (prop) => prop !== "withTabs",
})`
  border-radius: ${(props) =>
    props.withTabs ? "0 5px 0 0" : "5px 5px 0 0"} !important;
  overflow: hidden;
  border: 0;
  color: #ffffff;
  font-size: 12px;

  thead {
    background-color: #494c55;

    th {
      border-bottom: 0 !important;
      border-top: 0 !important;

      &.hover-buttons {
        display: none;
      }
    }
  }

  tbody {
    background-color: #ffffff;
    border: 0 !important;
    color: #000000;

    tr {
      position: relative;

      .hover-buttons {
        display: none;
      }

      &:hover {
        .hover-buttons {
          display: flex;
        }
      }
    }
    tr:last-of-type {
      border-bottom: 0;
    }

    td {
      border-bottom: 1px solid #a3a3a3;
      color: #666666;
      height: 55px;
      padding: 0 10px !important;

      &.hover-buttons {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border: 0;
        height: 100%;
        padding: 0 !important;
      }
    }
  }
`;
