/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function getAllClassificationComponents() {
  const res = await apiCall("/classify/all");
  if (res.status === 200) {
    return res.json();
  }
  throw new Error(
    `Problem getting all classification components: ${res.status}`,
  );
}

export async function getComponents(type, filter) {
  const res = await apiCall(
    `/classify/component/${type}${filterUrlGenerator(filter)}`,
  );
  if (res.status === 200) {
    return res.json();
  }
  throw new Error(`Problem getting components: ${res.status}`);
}

export async function addClassification(Classification) {
  const res = await apiCall("/classify", {
    method: "POST",
    body: JSON.stringify(Classification),
  });

  if (res.ok) {
    return res;
  }

  throw new Error(`Problem adding classification (code: ${res.status})`);
}

export async function reclassifyClassification(Classification) {
  const res = await apiCall("/classify/reclassify", {
    method: "POST",
    body: JSON.stringify(Classification),
  });

  if (res.ok) {
    return res;
  }

  throw new Error(`Problem reclassifying classification: ${res.status}`);
}

export async function unreclassifyClassification(Classification) {
  const res = await apiCall(`/classify/reclassify/${Classification}`, {
    method: "DELETE",
  });
  if (res.ok) {
    return res;
  }

  throw new Error(`Problem unreclassifying classification: ${res.status}`);
}

export async function deleteClassification(Classification) {
  const res = await apiCall(`/classify/${Classification}`, {
    method: "DELETE",
  });

  if (res.ok) {
    return res;
  }

  throw new Error(`Problem deleting classification: ${res.status}`);
}

export async function getClassification(ClassificationId) {
  const res = await apiCall(`/classify/${ClassificationId}`);
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting classification: ${res.status}`);
  return false;
}

export function addClassificationFile(file) {
  return apiCall("/classify/file", {
    method: "POST",
    body: JSON.stringify(file),
  });
}

// TODO: The misc route does not exist
export function deleteClassificationFile(fileId, misc = false) {
  return apiCall(`/classify/${misc ? "misc/" : ""}file/${fileId}`, {
    method: "DELETE",
  });
}

export async function getComponentTypes(filter) {
  const res = await apiCall(
    `/classify/componentType${filterUrlGenerator(filter)}`,
  );
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  throw new Error(`Problem getting component types: ${res.status}`);
}

export async function getClassificationFiles(classificationId) {
  const res = await apiCall(`/classify/file/${classificationId}`);
  if (res.ok) {
    return res.json();
  }

  throw new Error(`Problem getting classification files: ${res.status}`);
}

export async function editClassificationNote(ClassificationId, notes) {
  return apiCall("/classify/notes", {
    method: "PUT",
    body: JSON.stringify({
      ClassificationId,
      notes,
    }),
  });
}

export async function editClassificationFileCaption(ImageId, caption) {
  const res = await apiCall("/classify/file", {
    method: "PUT",
    body: JSON.stringify({ ImageId, caption }),
  });
  if (res.ok) {
    return;
  }

  throw new Error(`Problem editing file caption: ${res.status}`);
}

export async function getClassifySuggestion(type, options) {
  const res = await apiCall(
    `/classify/suggest/${type}${filterUrlGenerator(options)}`,
  );
  if (res.status === 200) {
    return res.json();
  }
  throw new Error(`Problem getting classification suggestion: ${res.status}`);
}

export async function offloadLocations(locations, InspectionId) {
  const res = await apiCall("/classify/offload", {
    method: "POST",
    body: JSON.stringify({ locations, InspectionId }),
  });

  if (res.ok) {
    return res;
  }

  throw new Error(`Problem adding offloaded locations (code: ${res.status})`);
}
