/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import {
  Col,
  Tab,
  Row,
  Panel,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
} from "react-bootstrap";

import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import { useClientsToVerify } from "hooks/client";
import { useCurrentUser } from "hooks/user";
import {
  editUser,
  verifyClientOrganisation,
  rejectVerifyClientOrganisation,
} from "libs/api";

import PageWrapper from "views/components/common/PageWrapper";
import UnviewedIndicator from "views/components/common/UnviewedIndicator";
import Loading from "views/components/Loading";
import EmailChangeModal from "views/components/Modals/EmailChangeModal";
import SettingsPanel from "views/components/SettingsPanel";
import TableHeader from "views/components/Tables/TableHeader";

import Tabs from "views/components/Tabs";

import ChangePassword from "./components/change-password";
import { ClientName, ClientPanel } from "./styles";

export default function PageSettings() {
  const { user, mutate: refreshUser } = useCurrentUser();
  const [userOverrideFields, setUserOverrideFields] = useState({});

  const [key, setKey] = useState(1);
  const [email, setEmail] = useState(null);
  const [showEmailChangeModal, setShowEmailChangeModal] = useState();
  const [userSavedSuccessfully, setUserSavedSuccessfully] = useState();
  const [userSaving, setUserSaving] = useState();
  const [userError, setUserError] = useState();

  const { clientsToVerify, mutate: refreshVerifications } =
    useClientsToVerify();

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setUserSavedSuccessfully(false);
    }
  }, [user]);

  if (!user || !user.position) {
    return <Loading height="calc(100vh - 50px)" />;
  }

  const { emailVerifiedAt, userType, SARILicenseNumber } = user;
  const verified = !!emailVerifiedAt;

  const showVerifications =
    user.position.includes("Org") || clientsToVerify.length > 0;

  return (
    <PageWrapper>
      <Helmet>
        <title>Econform / Settings</title>
      </Helmet>
      <TableHeader largeLeftTitle="Settings" />
      <Tabs activeKey={key} onSelect={setKey}>
        <Tab eventKey={1} title="Account">
          <div
            css={css`
              .section-group {
                .section-option:first-child:not(:last-child) {
                  border-bottom-right-radius: 0px;
                  border-bottom-left-radius: 0px;
                }
                .section-option:not(:last-child):not(:first-child) {
                  border-top: 1px solid #66666666;
                  border-bottom-right-radius: 0px;
                  border-bottom-left-radius: 0px;
                  border-top-right-radius: 0px;
                  border-top-left-radius: 0px;
                }
                .section-option:last-child:not(:first-child) {
                  border-top: 1px solid #66666666;
                  border-top-right-radius: 0px;
                  border-top-left-radius: 0px;
                }
              }
            `}
          >
            <div
              css={css`
                margin-top: 25px;
              `}
            >
              <Row sm={12}>
                <Col sm={6}>
                  <SettingsPanel>
                    <Panel.Heading>Account Information</Panel.Heading>
                    <Panel.Body>
                      <FormGroup controlId="formHorizontalWidth">
                        <Col sm={12}>
                          <Col sm={12}>
                            <ControlLabel>
                              User Name
                              <span className="required">*</span>
                            </ControlLabel>
                          </Col>
                          <Col sm={12}>
                            <FormControl
                              onChange={(e) =>
                                setUserOverrideFields({ name: e.target.value })
                              }
                              value={userOverrideFields.name ?? user.name}
                            />
                          </Col>
                        </Col>
                        <Col sm={12}>
                          <Col sm={12}>
                            <ControlLabel>
                              User Email
                              <span className="required">*</span>
                            </ControlLabel>
                          </Col>
                          <Col sm={12}>
                            <Col
                              css={css`
                                padding-left: 0px;
                              `}
                              sm={!verified ? 6 : 9}
                            >
                              <FormControl
                                onClick={() => setShowEmailChangeModal(true)}
                                value={email}
                              />
                            </Col>
                            <Col
                              css={css`
                                padding-left: 5px;
                                padding-right: 0px;
                              `}
                              sm={!verified ? 3 : 3}
                            >
                              <div>
                                <Button
                                  css={css`
                                    border-radius: 5px !important;
                                    padding: 7px !important;
                                    margin: 0;
                                    width: 100%;
                                  `}
                                  onClick={() => setShowEmailChangeModal(true)}
                                >
                                  Change
                                </Button>
                              </div>
                            </Col>
                          </Col>
                        </Col>
                        <Col sm={12}>
                          <Col sm={userType === "SARI" ? 3 : 6}>
                            <Col sm={userType === "SARI" ? 20 : 12}>
                              <ControlLabel>User Type</ControlLabel>
                            </Col>
                            <Col sm={userType === "SARI" ? 20 : 12}>
                              <FormControl disabled value={userType} />
                            </Col>
                          </Col>
                          <Col sm={userType === "SARI" ? 5 : 6}>
                            <Col sm={userType === "SARI" ? 20 : 12}>
                              <ControlLabel>User Position</ControlLabel>
                            </Col>
                            <Col sm={userType === "SARI" ? 20 : 12}>
                              <FormControl disabled value={user.position} />
                            </Col>
                          </Col>
                          {userType === "SARI" && (
                            <Col sm={4}>
                              <Col sm={20}>
                                <ControlLabel>SARI Reg. Number</ControlLabel>
                              </Col>
                              <Col sm={20}>
                                <FormControl
                                  disabled
                                  value={SARILicenseNumber}
                                />
                              </Col>
                            </Col>
                          )}
                        </Col>
                      </FormGroup>
                      <div
                        css={css`
                          float: right;
                          clear: both;
                          button {
                            width: 125px;
                          }
                          padding-right: 20px;
                          .form-group {
                            margin-bottom: 0;
                          }
                        `}
                      >
                        <Button
                          disabled={userSaving}
                          onClick={async () => {
                            setUserSaving(true);
                            const editedUser = {
                              id: user.id,
                              name: user.name,
                              newEmail: user.email !== email,
                              userType,
                              addedPermissions: [],
                              deletedPermissions: [],
                            };
                            const res = await editUser(editedUser);
                            if (res.ok) {
                              setUserSaving(false);
                              setUserSavedSuccessfully(true);
                              refreshUser();
                            } else {
                              const body = await res.json();
                              setUserSaving(false);
                              setUserSavedSuccessfully(false);
                              setUserError(body ? body.errors : null);
                              refreshUser();
                            }
                          }}
                        >
                          {userSaving ? "Updating..." : "Update"}
                        </Button>
                      </div>
                      <div
                        style={{
                          float: "left",
                          marginRight: 40,
                          marginLeft: 40,
                        }}
                      >
                        {userError && (
                          <p
                            className="text-grey-dark text-xs"
                            style={{
                              color: "red",
                              marginTop: 15,
                              marginBottom: 15,
                            }}
                          >
                            {userError}
                          </p>
                        )}
                        {userSavedSuccessfully && (
                          <p
                            className="text-grey-dark text-xs"
                            style={{
                              color: "green",
                              marginTop: 15,
                              marginBottom: 15,
                            }}
                          >
                            Details successfully updated.
                          </p>
                        )}
                      </div>
                    </Panel.Body>
                  </SettingsPanel>
                </Col>
                <Col sm={6}>
                  <ChangePassword />
                </Col>
              </Row>
            </div>
          </div>
        </Tab>
        {showVerifications ? (
          <Tab
            eventKey={3}
            title={
              <div>
                <span
                  css={css`
                    margin-right: 5px;
                  `}
                >
                  Verifications
                </span>
                <UnviewedIndicator
                  amount={clientsToVerify?.length ?? 0}
                  maxAmount={99}
                />
              </div>
            }
          >
            {clientsToVerify.length === 0 ? (
              <div>No clients to verify</div>
            ) : null}
            {clientsToVerify.map((client) => (
              <ClientPanel key={client.id}>
                <ClientName>{client.name}</ClientName>
                <Button
                  onClick={async () => {
                    try {
                      await verifyClientOrganisation(
                        client.id,
                        client.NewOrganisationId,
                        client.NewOrganisationVerifyCode,
                      );
                      refreshVerifications();
                      toast(`Successfully verified client`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        closeButton: true,
                        type: toast.TYPE.SUCCESS,
                        autoClose: true,
                        pauseOnHover: true,
                      });
                    } catch (e) {
                      toast(`There was an error verifying this client`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        closeButton: true,
                        type: toast.TYPE.ERROR,
                        autoClose: true,
                        pauseOnHover: true,
                      });
                    }
                    refreshUser();
                  }}
                >
                  Verify
                </Button>
                <Button
                  onClick={async () => {
                    try {
                      await rejectVerifyClientOrganisation(
                        client.id,
                        client.NewOrganisationId,
                        client.NewOrganisationVerifyCode,
                      );
                      refreshVerifications();
                      toast(`Client rejected`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        closeButton: true,
                        type: toast.TYPE.INFO,
                        autoClose: true,
                        pauseOnHover: true,
                      });
                    } catch (e) {
                      toast(`There was an error rejecting this client`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        closeButton: true,
                        type: toast.TYPE.ERROR,
                        autoClose: true,
                        pauseOnHover: true,
                      });
                    }
                    refreshUser();
                  }}
                >
                  Reject
                </Button>
              </ClientPanel>
            ))}
          </Tab>
        ) : null}
      </Tabs>
      {showEmailChangeModal && (
        <EmailChangeModal
          email={email}
          onHide={() => setShowEmailChangeModal(false)}
          user={user}
        />
      )}
    </PageWrapper>
  );
}
