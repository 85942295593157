/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { useBayTemplates } from "hooks/bay-templates";
import { useFrameTemplates } from "hooks/frame-templates";
import { useNewFrameName } from "hooks/templates";
import {
  addFrameTemplate,
  getFrameTemplate,
  editFrameTemplate,
  deleteFrameTemplate,
} from "libs/api";

import Loading from "views/components/Loading";

import { EDITOR_MODES, getTextForMode } from "../constants";
import Header from "../SharedControls/Header";
import { SidebarBody, SidebarEditorPanel } from "../SharedControls/styles";

import Body from "./Body";
import Footer from "./Footer";

export default function FrameEditor({ SiteId, onHide, mode, frameTemplateId }) {
  const [frameTemplate, setFrameTemplate] = useState({
    depth: 0,
    id: null,
    ManufacturerId: null,
    notes: "",
    pitch: null,
    SiteId,
    spliced: [],
    splicedFrames: [],
    rearFrames: [],
    standardDownweld: null,
    type: null,
    width: 90,
  });
  const { name: newFrameName } = useNewFrameName(SiteId);
  const [errorMsg, setErrorMsg] = useState();

  const { bayTemplates, isLoading: bayTemplatesLoading } =
    useBayTemplates(SiteId);
  const { frameTemplates } = useFrameTemplates(SiteId);

  // Load the frame template when the frameTemplateId changes
  // TODO: Handle error with loading the frame template different to a form error
  useEffect(() => {
    async function load() {
      setFrameTemplate(null);
      try {
        const ft = await getFrameTemplate(frameTemplateId);
        setFrameTemplate({
          ...ft,
          name: mode === EDITOR_MODES.copy ? `Copy of ${ft.name}` : ft.name,
        });
      } catch (e) {
        setErrorMsg("Error: Unable to load frame template");
      }
    }
    if (mode === EDITOR_MODES.edit || mode === EDITOR_MODES.copy) {
      load();
    }
  }, [frameTemplateId, mode]);

  if (!frameTemplate) return <Loading darkMode height="calc(100vh - 50px)" />;
  return (
    <SidebarEditorPanel>
      <Header onHide={onHide} title={`${getTextForMode(mode)} Frame Type`} />
      <SidebarBody>
        <Body
          frameTemplate={{
            ...frameTemplate,
            name: mode === EDITOR_MODES.new ? newFrameName : frameTemplate.name,
          }}
          isCopy={false}
          siteId={SiteId}
          updateFrameTemplate={(fields) => setFrameTemplate({ ...frameTemplate, ...fields })} // prettier-ignore
        />
      </SidebarBody>
      <Footer
        errorMsg={errorMsg}
        frameTemplate={frameTemplate}
        isNew={mode === EDITOR_MODES.new || mode === EDITOR_MODES.copy}
        onDelete={async () => {
          if (mode === EDITOR_MODES.edit) {
            // Prevent deletion of a frame template that is used in a bay template
            const bayTemplatesWithSameDepth = bayTemplates.filter(
              (bt) => bt.depth === frameTemplate.depth,
            );
            const frameTemplatesWithSameDepth = frameTemplates.filter(
              (ft) => ft.depth === frameTemplate.depth,
            );
            if (
              bayTemplatesWithSameDepth.length > 0 ||
              frameTemplatesWithSameDepth.length === 1
            ) {
              setErrorMsg("This frame template is used in a bay");
              return;
            }

            try {
              await deleteFrameTemplate(frameTemplate.id);
              onHide();
            } catch (e) {
              setErrorMsg(e.message);
            }
          }
        }}
        onSave={async () => {
          // TODO: Why do we strip SeriesId here?
          const { SeriesId, ...ft } = frameTemplate;
          try {
            if (mode === EDITOR_MODES.edit) {
              await editFrameTemplate(ft);
            } else {
              await addFrameTemplate(ft);
            }
            onHide();
          } catch {
            setErrorMsg("Error: Unable to save frame type");
          }
        }}
      />
    </SidebarEditorPanel>
  );
}

FrameEditor.propTypes = {
  frameTemplateId: PropTypes.string,
  mode: PropTypes.oneOf(Object.values(EDITOR_MODES)),
  onHide: PropTypes.func,
  SiteId: PropTypes.string,
};
