/**
 * Transform reducer state and inspection into a new misc classification for the API
 */
export function buildMiscClassificationObjectToSend(InspectionId, state) {
  return {
    action: state.action,
    BeamId: state.componentType === "Beam" ? state.level : null,
    component: state.component,
    componentType: state.componentType,
    customerReference: state.customerReference,
    defect: state.defect,
    FrameId: state.componentType === "Frame" ? state.location : null,
    InspectionId: InspectionId,
    level: state.level,
    misc: true,
    notes: state.notes,
    position: state.position,
    quantity: state.quantity,
    risk: state.risk,
    specification: state.componentSpecification,
    runspacerSize: state.runspacerSize,
  };
}

export function buildMiscReclassificationObjectToSend(
  InspectionId,
  classification,
  state,
) {
  const {
    BeamId,
    customerReference,
    FrameId,
    id,
    level,
    miscComponent,
    miscComponentType,
    miscPosition,
    quantity,
    runspacerSize,
    section,
    specification,
  } = classification;

  return {
    action: state.action,
    BeamId,
    ClassificationId: id,
    component: miscComponent,
    componentType: miscComponentType,
    customerReference: state.customerReference ?? customerReference,
    defect: state.defect,
    FrameId,
    InspectionId,
    level,
    misc: true,
    notes: state.notes,
    position: miscPosition,
    quantity,
    risk: state.risk,
    specification,
    runspacerSize,
    section,
  };
}

/**
 * Transform reducer state into objects to send to the API
 */
export function buildReclassificationObjectToSend(
  InspectionId,
  classification,
  state,
) {
  const {
    id,
    componentType,
    component,
    position,
    quantity,
    FrameId,
    BeamId,
    specification,
    customerReference,
    misc,
    section,
    ComponentsClassificationId,
  } = classification;

  return {
    action: "",
    BeamId,
    ClassificationId: id,
    component,
    ComponentsClassificationId: state.action ?? ComponentsClassificationId,
    componentType,
    customerReference: state.customerReference ?? customerReference,
    defect: state.defect,
    FrameId,
    InspectionId,
    misc,
    notes: state.notes,
    position,
    quantity,
    risk: state.risk,
    section,
    specification: specification ?? "",
  };
}

/**
 * Transform reducer state into objects to send to the API
 */
export function buildClassificationObjectToSend(InspectionId, state) {
  const newClassification =
    state.componentType === "Frame"
      ? {
          InspectionId,
          risk: state.risk,
          ComponentsClassificationId: state.action,
          FrameId: state.location,
          notes: state.notes,
          quantity: state.quantity,
          level: state.level,
          customerReference: state.customerReference,
          section: state.section,
          runspacerSize: state.runspacerSize,
        }
      : {
          InspectionId,
          risk: state.risk,
          ComponentsClassificationId: state.action,
          BeamId: state.level,
          notes: state.notes,
          quantity: state.quantity,
          customerReference: state.customerReference,
        };

  return newClassification;
}

export function getSecondaryLocations(
  racks,
  rackId,
  bayId,
  frameOffloadLocations,
) {
  const selectedRack = racks.find((r) => r.id === rackId);
  if (!selectedRack || !bayId) return [];
  return selectedRack.offloadLocations.frames
    .filter(
      (f) =>
        f.BayId === bayId &&
        !frameOffloadLocations.find((ol) => ol.id === f.id),
    )
    .map((b) => ({
      value: b,
      label: `${b.name}`,
      disabled: b.offloadedAt && !b.loadedAt,
    }));
}

export function getBayLocations(racks, rackId) {
  const selectedRack = racks.find((r) => r.id === rackId);
  if (!selectedRack) return [];
  return selectedRack.bays.map((b) => ({
    value: b.id,
    label: `${b.name}`,
  }));
}

export function getRackLocations(racks) {
  return racks.map((r) => ({
    value: r.id,
    label: `${r.name}`,
  }));
}

export function groupByPalletId(items) {
  return items.reduce((groups, item) => {
    const { PalletId } = item;
    if (!groups[PalletId]) {
      groups[PalletId] = [];
    }
    groups[PalletId].push(item);
    return groups;
  }, {});
}
