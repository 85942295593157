import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { RedButton } from "./colors";

export default function InspectionDeleteButton({ disabled, onClick }) {
  return (
    <RedButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={faTrash} size="2xl" />
      Delete
    </RedButton>
  );
}

InspectionDeleteButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
