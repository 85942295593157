/* eslint-disable no-nested-ternary */
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useMemo } from "react";

const Wrapper = styled.div`
  background-color: #24262d;
  white-space: nowrap;
  overflow-x: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  max-width: 100%;
  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #494c55;
  }
  ::-webkit-scrollbar-thumb {
    background: #2d72ed;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const RackName = styled.div`
  border-left: 1px solid #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  cursor: pointer;
  height: 30px;
  min-width: 70px;
  margin-top: 12px;
  display: inline-block;

  color: ${(props) => (props.active ? "#FECE46" : "#ffffff")};
  ${(props) => (props.active ? "font-weight: 700;" : "")};
  border-bottom: ${(props) =>
    props.complete ? "5px solid #FECE46" : "5px solid transparent"};

  &:first-of-type {
    border-left: 0;
  }
`;

const AreaName = styled.div`
  color: #ffffff;
  display: inline-block;
  height: 32px;
  margin-top: 12px;
  min-width: 80px;
  cursor: pointer;
  text-align: center;
  margin-top: -5px;
  padding: 5px;
  margin-left: 5px;
  margin-right: 0px;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  text-align: center;
  ${(props) => (props.active ? "font-weight: 700;" : "")};
  color: ${(props) =>
    props.allComplete ? "#333333;" : props.active ? "#FECE46" : "#ffffff"};
  ${(props) => (props.allComplete ? "background-color: #FECE46;" : "")}
  ${(props) => (props.areaComplete ? "color: #333333;" : "")}
  ${(props) => (props.areaComplete ? "background-color: #FECE46;" : "")}
`;

const Area = styled.div`
  display: inline-block;
`;

function InspectionRackSelect({
  areas = [],
  isCompleted,
  onClickArea,
  onClickRack,
  racks,
  selectedAreaId,
  selectedRackId,
}) {
  // Areas should be displayed alphabetically
  const sortedAreas = useMemo(
    () => areas.sort((a, b) => (a.name > b.name ? 1 : -1)),
    [areas],
  );

  return (
    <Wrapper>
      {sortedAreas
        .filter((area) => !selectedAreaId || area.id === selectedAreaId)
        .map((area) => {
          const racksInArea = racks.filter((r) =>
            area.id ? r.AreaId === area.id : r.AreaId === null,
          );
          if (racksInArea.length === 0) return null;
          const isSelectedRackInArea = racksInArea.find(
            (r) => r.id === selectedRackId,
          );
          return (
            <Area key={area.id}>
              <AreaName
                active={area.id === selectedAreaId || isSelectedRackInArea}
                allComplete={isCompleted}
                areaComplete={area.completePercentage === 100}
                onClick={() => onClickArea(area.id)}
              >
                {area.name === "All Areas" ? "General" : area.name}
              </AreaName>
              <span>
                {racksInArea.map((rack) => (
                  <RackName
                    active={selectedRackId === rack.id}
                    complete={rack.completePercentage === 100 || isCompleted}
                    key={rack.id}
                    onClick={() => onClickRack(rack.id)}
                  >
                    Rack {rack.name}
                  </RackName>
                ))}
              </span>
            </Area>
          );
        })}
    </Wrapper>
  );
}

InspectionRackSelect.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  isCompleted: PropTypes.bool,
  onClickArea: PropTypes.func.isRequired,
  onClickRack: PropTypes.func.isRequired,
  selectedAreaId: PropTypes.string,
  selectedRackId: PropTypes.string,
  racks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      AreaId: PropTypes.string,
      completePercentage: PropTypes.number,
    }),
  ),
};

export default InspectionRackSelect;
