import styled from "@emotion/styled";
import { Tabs, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow: hidden;
`;

export const BackLink = styled(Link)`
  align-items: center;
  background-color: #fff;
  border-radius: 0;
  color: #2d72ed;
  cursor: pointer;
  display: block;
  display: flex;
  font-size: 12pt;
  height: 100%;
  margin: 0;
  padding-left: 10px;
  padding-right: 20px;

  transition:
    background-color 0.1s ease,
    color 0.1s ease;

  &:hover {
    background-color: #364c71;
    color: #fff;
    text-decoration: none;
  }
`;

export const StageContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  max-height: calc(100vh - 90px);
  cursor: ${(props) => (props.select ? "crosshair" : "default")};
`;

export const BottomTabs = styled(Tabs)`
  background-color: white;
  bottom: 0;
  margin-top: 0;
  padding-top: 0;
  position: absolute;

  ul > li > a {
    border-radius: 0;
    border: 0;
    color: #666666;
    margin-top: 0;
    text-align: center;
  }
  ul > li > a:focus,
  ul > li > a:hover,
  ul > li.active > a:focus,
  ul > li.active > a:hover,
  ul > li.active > a {
    color: #2d72ed;
    border: 0;
    border-top: 3px solid #2d72ed;
    border-radius: 0;
  }
`;

BottomTabs.displayName = "BottomTabs";

export const MainAndSidebar = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 50px);
  width: 100%;
  position: relative;
`;
MainAndSidebar.displayName = "MainAndSidebar";

export const Sidebar = styled.div`
  max-height: calc(100vh - 50px);
  min-width: 400px;
  overflow-y: scroll;
  position: relative;
  width: 400px;
`;
Sidebar.displayName = "Sidebar";

export const NavigationSection = styled.div`
  display: flex;
  align-items: center;
  ${(props) => (props.padded ? "padding: 0 10px" : "")}
`;
NavigationSection.displayName = "NavigationSection";

export const RoundButton = styled(Button)`
  align-items: center;
  border-radius: 50%;
  color: white;
  display: flex;
  height: 35px;
  justify-content: center;
  width: 35px;
`;
