import PropTypes from "prop-types";
import { useState } from "react";

import { useInspection } from "hooks/inspections";
import { useSite } from "hooks/site";

import ButtonCompleteCheck, {
  getInspectionCompletableState,
  COMPLETABLE_STATE,
} from "views/components/Buttons/ButtonCompleteCheck";
import DetailsModal from "views/pages/Inspection/components/modals/modal-details";
import SignoffModal from "views/pages/Inspection/components/modals/modal-signoff";

const MODALS = {
  overview: "overview",
  signoff: "signoff",
};

/**
 * The complete button bundles together the button with the details and signoff
 * modals.
 *
 * The button is disabled if the inspection is not completable.
 * The button has three colour states corresponding to not completable,
 * partially completable, and completable.
 */
export default function CompleteButton({ InspectionId, onComplete }) {
  const { inspection } = useInspection(InspectionId);
  const { site } = useSite(inspection?.SiteId);
  const [showModal, setShowModal] = useState(null);
  const inspectionCompleteableState = getInspectionCompletableState(inspection);
  return (
    <>
      <ButtonCompleteCheck
        disabled={inspectionCompleteableState === COMPLETABLE_STATE.complete}
        onClick={() => setShowModal(MODALS.details)}
        state={inspectionCompleteableState}
      />
      {showModal === MODALS.details ? (
        <DetailsModal
          ClientId={site?.ClientId}
          InspectionId={InspectionId}
          onClickComplete={() => setShowModal(MODALS.signoff)}
          onHide={() => setShowModal(null)}
        />
      ) : null}
      {showModal === MODALS.signoff ? (
        <SignoffModal
          InspectionId={InspectionId}
          onComplete={onComplete}
          onHide={() => setShowModal(null)}
        />
      ) : null}
    </>
  );
}

CompleteButton.propTypes = {
  InspectionId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
};
