import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

function getStatusColour(status) {
  switch (status) {
    case "Incomplete":
      return "#e30021";
    case "In Progress":
    case "Prepare":
      return "#ff7700";
    case "Complete":
    default:
      return "#008b00";
  }
}

const IndicatorWithColor = styled.span`
  span {
    color: ${(props) => props.color};
  }
`;

/**
 * Displayed a coloured indication of the inspection status, and whether or not
 * the inspection is partial inspection.
 */
function StatusIndicator({ status, partial }) {
  if (!status) return null;
  return (
    <IndicatorWithColor color={getStatusColour(status)}>
      <span>{status}</span>
      {partial && <> (Partial)</>}
    </IndicatorWithColor>
  );
}

export default React.memo(StatusIndicator);

StatusIndicator.propTypes = {
  status: PropTypes.oneOf(["Incomplete", "In Progress", "Complete", "Prepare"]),
  partial: PropTypes.bool,
};
