/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  Button,
  FormControl,
  ButtonGroup,
  Row,
  Checkbox,
  Image,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom"; // compat

import { assignUserToInspectionActions } from "libs/api";
import { PRIMARY_DATE_FORMAT } from "libs/constants";
import { InspectionProp } from "tests/prop-types";

import Switch from "views/components/common/Switch";

import { ModalPaddedBody } from "views/components/Modals/FlatModal";

import UserSearch from "../../UserSearch";

export default function ActionAssigner({
  populateDefaultData,
  inspection,
  onHide,
  getData,
  clientName,
  user,
  shouldNavigateOnSave = false,
}) {
  const navigate = useNavigate();
  const defaultAssignee = { id: user?.id, name: user?.name };
  const defaultDueAt = moment().add(28, "day");

  const [dueAt, setDueAt] = useState(
    populateDefaultData ? defaultDueAt : inspection?.dueAt,
  );
  const [assignee, setAssignee] = useState(populateDefaultData ? defaultAssignee : inspection?.assignees?.[0]); // prettier-ignore
  const [photoEvidenceRequired, setPhotoEvidenceRequired] = useState(inspection?.photoEvidenceRequired); // prettier-ignore

  const filters = {
    InspectionId: inspection.InspectionId,
    SiteId: inspection.SiteId,
  };

  const isCustom = !moment().add(7, 'days').isSame(dueAt, 'day') && !moment().add(14, 'days').isSame(dueAt, 'day') && !moment().add(28, 'days').isSame(dueAt, 'day'); // prettier-ignore
  const completedDate = inspection.completedAt || new Date();
  return (
    <div
      css={css`
        color: #666666;
        margin: 0;
        label {
          input {
            background-color: #00000000;
            border-radius: 0px;
            border: solid 1px #979797;
            width: 15px;
            height: 15px;
            margin-left: 10px;
          }
          input:checked {
            background-color: #ffcf33;
          }
          input:after {
            border-radius: 0px;
            height: 7px;
            width: 7px;
            left: 3px;
            top: 3px;
          }
        }
        .bottom-btn-group {
          padding-top: 10px;
          padding-right: 10px;
          float: right;
          .btn {
            width: 150px;
            &:disabled {
              background-color: #7e8496;
              color: #ffffffaa;
            }
          }
        }
        .form-control {
          background-color: #f2f2f4;
          color: #747474;
          border: 0px;
          box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
        }
      `}
    >
      <ModalPaddedBody>
        <h5>Inspection</h5>
        <FormControl
          placeholder="Enter Damage"
          value={`${inspection.ClientName || clientName}, ${moment(
            completedDate,
          ).format(PRIMARY_DATE_FORMAT)}, Ref. ${
            inspection.InspectionReference || inspection.reference
          } (${inspection.InspectionType || inspection.type})`}
        />
        <h5>Assignee</h5>
        <UserSearch
          filters={filters}
          groupOptions={[
            { label: "PRRS User Type", value: "PRRS" },
            { label: "SARI User Type", value: "SARI" },
            { label: "Installer User Type", value: "Installer" },
          ]}
          onChangeUser={setAssignee}
          onClearUser={() => setAssignee(null)}
          selectedUser={assignee}
        />
        <Checkbox
          checked={dueAt !== null}
          onChange={(e) =>
            setDueAt(e.target.checked ? moment().add(28, "day") : null)
          }
        >
          Due Date
        </Checkbox>
        {dueAt && (
          <div>
            <ButtonGroup
              css={css`
                width: 100%;
                .btn {
                  border-radius: 0px;
                  background-color: #f2f2f4;
                  color: #6d6d6d;
                  margin: 0px;
                  width: 25%;
                }
                .selected {
                  background-color: #2d72ed !important;
                  color: #ffffff !important;
                  border: 0px;
                }
              `}
            >
              <Button
                className={
                  moment().add(7, "days").isSame(dueAt, "day") ? "selected" : ""
                }
                css={css`
                  border-radius: 5px !important;
                  border-top-right-radius: 0px !important;
                  border-bottom-right-radius: 0px !important;
                  border-right: 2px solid #dedede !important;
                `}
                onClick={() => setDueAt(moment().add(7, "days"))}
              >
                7 Days
              </Button>
              <Button
                className={
                  moment().add(14, "days").isSame(dueAt, "day")
                    ? "selected"
                    : ""
                }
                css={css`
                  border-radius: 0px !important;
                  border-right: 2px solid #dedede !important;
                `}
                onClick={() => setDueAt(moment().add(14, "days"))}
              >
                14 Days
              </Button>
              <Button
                className={
                  moment().add(28, "days").isSame(dueAt, "day")
                    ? "selected"
                    : ""
                }
                css={css`
                  border-radius: 0px !important;
                  border-right: 2px solid #dedede !important;
                `}
                onClick={() => setDueAt(moment().add(28, "days"))}
              >
                28 Days
              </Button>
              <Button
                className={isCustom ? "selected" : ""}
                css={css`
                  border-radius: 5px !important;
                  border-top-left-radius: 0px !important;
                  border-bottom-left-radius: 0px !important;
                `}
                onClick={() => setDueAt(moment().add(0, "days"))}
              >
                Custom
              </Button>
            </ButtonGroup>
            {isCustom && (
              <DatePicker
                css={css`
                  margin-top: 15px;
                  margin-bottom: 5px;
                  height: 40px !important;
                `}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                onChange={setDueAt}
                placeholderText="Select Date"
                selected={moment(dueAt).toDate()}
              />
            )}
            <div
              css={css`
                margin-top: 15px;
                margin-bottom: 15px;
                img {
                  width: 35px;
                  height: auto;
                  padding-right: 10px;
                }
              `}
            >
              <Image src="/img/sidebar/schedules-grey-icon.png" />
              {moment(dueAt).format(PRIMARY_DATE_FORMAT)}
            </div>
          </div>
        )}
        <Switch
          handleToggle={() => setPhotoEvidenceRequired(!photoEvidenceRequired)}
          isOn={photoEvidenceRequired}
          label="Photo evidence required"
          onColor="#2B72EE"
        />
        <Row>
          <ButtonGroup className="bottom-btn-group">
            {onHide && (
              <Button className="cancel" onClick={() => onHide()}>
                Cancel
              </Button>
            )}
            <Button
              disabled={!assignee}
              onClick={async () => {
                const res = await assignUserToInspectionActions(
                  inspection.InspectionId || inspection.id,
                  assignee.id,
                  dueAt,
                  photoEvidenceRequired,
                );
                if (res) {
                  onHide();
                  if (shouldNavigateOnSave) {
                    navigate("/actions");
                  }
                  getData();
                }
              }}
            >
              Assign Action
            </Button>
          </ButtonGroup>
        </Row>
      </ModalPaddedBody>
    </div>
  );
}

ActionAssigner.propTypes = {
  clientName: PropTypes.string,
  getData: PropTypes.func,
  inspection: InspectionProp,
  onHide: PropTypes.func,
  populateDefaultData: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  shouldNavigateOnSave: PropTypes.bool,
};
