export default function getUsersRole(user) {
  if (!user) {
    return { RoleName: "No Role", CompanyName: "No Company" };
  }
  const {
    clientAdmin,
    client,
    siteClient,
    siteGroupClient,
    organisations,
    organisationsAdmin,
    clientOrganisation,
    installerOrganisationClient,
  } = user;
  if (organisationsAdmin?.length) {
    return {
      RoleName: "Org Admin",
      CompanyName: organisationsAdmin[0].name,
    };
  }
  if (organisations?.length) {
    return {
      RoleName: "Org Management",
      CompanyName: organisations[0].name,
    };
  }
  if (clientOrganisation?.length) {
    return {
      RoleName: "Org User",
      CompanyName: `${clientOrganisation[0].OrganisationName} (${
        clientOrganisation.length
      } Client${clientOrganisation.length > 1 ? "s" : ""})`,
    };
  }
  if (clientAdmin?.length) {
    return { RoleName: "Admin User", CompanyName: clientAdmin[0].name };
  }
  if (client?.length) {
    return { RoleName: "Management User", CompanyName: client[0].name };
  }
  if (siteClient?.length) {
    return {
      RoleName: "Site User",
      CompanyName: `${siteClient[0].ClientName} (${siteClient.length} Site${
        siteClient.length > 1 ? "s" : ""
      })`,
    };
  }
  if (siteGroupClient?.length) {
    return {
      RoleName: "Group User",
      CompanyName: `${siteGroupClient[0].ClientName} (${
        siteGroupClient.length
      } Group${siteGroupClient.length > 1 ? "s" : ""})`,
    };
  }
  if (installerOrganisationClient?.length) {
    return {
      RoleName: "Installer User",
      CompanyName: `${installerOrganisationClient[0].name} (${
        installerOrganisationClient.length
      } Client${installerOrganisationClient.length > 1 ? "s" : ""})`,
    };
  }
  return { RoleName: "No Role", CompanyName: "No Company" };
}
