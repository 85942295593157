import PropTypes from "prop-types";
import { useState } from "react";
import { Panel } from "react-bootstrap";

import RackFilters from "./Filter";
import RacksList from "./RacksList";

export default function Racks({
  areas = [],
  canEdit,
  manufacturers,
  onSelectRack,
  racks = [],
  showFilters,
}) {
  const [filterOptions, setFilterOptions] = useState({
    searchText: "",
    ManufacturerId: null,
    minLength: null,
    minBays: null,
    maxLength: null,
    maxBays: null,
  });
  return (
    <Panel>
      <Panel.Body className="no-padding-panel">
        {showFilters && (
          <RackFilters
            areas={areas}
            manufacturers={manufacturers}
            racksFilterOptions={filterOptions}
            setFilterOptions={(key, value) =>
              setFilterOptions({
                ...filterOptions,
                [key]: value,
              })
            }
          />
        )}
        <RacksList
          areas={areas}
          canEdit={canEdit}
          manufacturers={manufacturers}
          onSelectRack={onSelectRack}
          racks={racks}
          racksFilterOptions={filterOptions}
        />
      </Panel.Body>
    </Panel>
  );
}

Racks.propTypes = {
  showFilters: PropTypes.bool,
  manufacturers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  // eslint-disable-next-line react/forbid-prop-types
  racks: PropTypes.array,
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  canEdit: PropTypes.bool,
  onSelectRack: PropTypes.func,
};
