import { OTHER_COLOUR_ID } from "constants";

import PropTypes from "prop-types";
import { FormGroup, ControlLabel, Col } from "react-bootstrap";

import { useColours } from "hooks/types";

import {
  ControlLabelWithLoading,
  StyledFormControl,
} from "../../SharedControls/Generic";

export default function Colour({
  ManufacturerId,
  disabled,
  onChange,
  colourOther,
  ColourId,
  filters = {
    showFrameColours: true,
  },
}) {
  const { colours, isLoading } = useColours(ManufacturerId, filters);
  const isOtherColour =
    ColourId === OTHER_COLOUR_ID || (ColourId === null && colourOther);
  return (
    <FormGroup className={disabled ? "greyed" : ""}>
      <Col sm={6}>
        <ControlLabelWithLoading
          disabled={disabled}
          isLoading={isLoading}
          label="Colour"
        />
      </Col>
      <Col sm={6}>
        <StyledFormControl
          componentClass="select"
          disabled={disabled}
          onChange={(e) => {
            const val = e.target.value;
            onChange({
              ColourId: val,
              colourOther: val !== OTHER_COLOUR_ID ? null : colourOther,
            });
          }}
          placeholder="Colour"
          value={isOtherColour ? OTHER_COLOUR_ID : ColourId ?? ""}
        >
          {!isLoading && colours.length === 0 ? (
            <option disabled value="">
              No colours found
            </option>
          ) : null}
          {colours.length > 0 && (
            <option disabled value="">
              Select Colour
            </option>
          )}
          {colours.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </StyledFormControl>
      </Col>
      {ColourId === OTHER_COLOUR_ID || (ColourId === null && colourOther) ? (
        <div>
          <Col sm={6}>
            <ControlLabel />
          </Col>
          <Col sm={6}>
            <div style={{ marginTop: 10 }}>
              <StyledFormControl
                onChange={(e) => onChange({ colourOther: e.target.value })}
                placeholder="Enter Colour"
                type="text"
                value={colourOther ?? ""}
              />
            </div>
          </Col>
        </div>
      ) : null}
    </FormGroup>
  );
}

Colour.propTypes = {
  disabled: PropTypes.bool,
  ManufacturerId: PropTypes.string,
  /** Called with an object with colour keys, not a single value */
  onChange: PropTypes.func,
  ColourId: PropTypes.string,
  colourOther: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
};
