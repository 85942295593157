import styled from "@emotion/styled";

export const ClientPanel = styled.div`
  border: 1px grey solid;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
`;

export const ClientName = styled.div`
  padding-right: 50px;
  font-weight: 15px;
`;
