/**
 * Config Variables
 */
export const PAGE_SIZE = 1000;
export const emptyPermissions = {
  organisationAdmin: [],
  organisation: [],
  clientOrganisation: [],
  clientAdmin: [],
  client: [],
  siteGroup: [],
  site: [],
};

export const USER_PERMISSION_TYPES = {
  NONE: "none",
  ADMIN: "clientAdmin",
  ORGADMIN: "orgAdmin",
  USER: "clientUser",
  ORGUSER: "orgUser",
  CLIENTORGUSER: "clientOrgUser",
  INSTALLERADMIN: "installerAdmin",
  SITEGROUP: "sitegroup",
  SITE: "site",
};
