import PropTypes from 'prop-types';

import { Button, ButtonGroup, Col, Image, Panel, Row } from 'react-bootstrap';

import { EDITORS } from '../constants';

function getTitle(editor) {
  switch (editor) {
    case EDITORS.frames:
      return 'Frame Types';
    case EDITORS.bays:
      return 'Bay Types';
    case EDITORS.areas:
      return 'Areas';
    case EDITORS.racks:
      return 'Racks';
    default:
      return 'Editor';
  }
}

export default function FilterAddControl({
  canEdit,
  editor,
  onAdd,
  onToggleShowFilters,
  showFilters,
}) {
  return (
    <Panel.Heading>
      <Row>
        <Col xs={12}>
          <h4 className="aligned-header">{getTitle(editor)}</h4>
          <ButtonGroup className="pull-right">
            {editor !== EDITORS.areas && (
              <Button
                className={`icon-btn ${showFilters && 'selected'}`}
                onClick={onToggleShowFilters}
              >
                <Image responsive src={`/img/icons/filter-${showFilters ? 'blue' : 'white'}.png`} />
              </Button>
            )}
            {canEdit && (
              <Button className="icon-btn" onClick={onAdd}>
                <Image responsive src="/img/icons/plus-white.png" />
              </Button>
            )}
          </ButtonGroup>
        </Col>
      </Row>
    </Panel.Heading>
  );
}

FilterAddControl.propTypes = {
  canEdit: PropTypes.bool,
  editor: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
  onToggleShowFilters: PropTypes.func.isRequired,
  showFilters: PropTypes.bool,
};
