/* eslint-disable react/jsx-sort-props */
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState, useReducer, useCallback } from "react";
import {
  Panel,
  ListGroup,
  ListGroupItem,
  Tab,
  Tabs,
  TabContainer,
} from "react-bootstrap";

import "views/styles/Editors.css";

import { useBayTemplates } from "hooks/bay-templates";
import { useFrameTemplates } from "hooks/frame-templates";
import { useManufacturers } from "hooks/manufacturers";
import { useAreas } from "hooks/site";
import useSiteBlueprint from "hooks/use-site-blueprint";

import Loading from "../Loading";

import AreasEditor from "./AreasEditor";
import AreasList from "./AreasList";
import BayEditor from "./BayEditor";
import BayTypes from "./BayTypesList";
import { EDITORS, EDITOR_MODES, ACTION_TYPES } from "./constants";
import FrameEditor from "./FrameEditor";
import FrameTypes from "./FrameTypesList";
import { editorsReducer, getType } from "./helpers";
import RackEditor from "./RackEditor";
import Racks from "./RacksList";
import FilterAddControl from "./SharedControls/FilterAddControl";
import { StyledTabsWrapper } from "./styles";

function LoadingListGroup() {
  return (
    <ListGroup className="scroll-bar-contents">
      <ListGroupItem>
        <Loading darkMode height="calc(100vh - 200px)" />
      </ListGroupItem>
    </ListGroup>
  );
}

const PanelNoMargin = styled(Panel)`
  margin-bottom: 0;
`;

/**
 * Renders either lists of areas, racks, or frame/bay types, or editors for
 * those items
 */
export default function BuildModeSidebar({ permission, SiteId, WarehouseId }) {
  // TODO: Combine areas setter into useSiteBlueprint
  const { areas, mutate: refreshAreas } = useAreas(WarehouseId);
  const { frameTemplates, isLoading: frameTemplatesLoading, mutate: refreshFrameTemplates } = useFrameTemplates(SiteId); // prettier-ignore
  const { bayTemplates, isLoading: bayTemplatesLoading, mutate: refreshBayTemplates } = useBayTemplates(SiteId); // prettier-ignore
  const { manufacturers, isLoading: manufactuersLoading } = useManufacturers();

  const { warehouse, mutate: refreshBlueprint } = useSiteBlueprint(
    SiteId,
    WarehouseId,
  );

  const { racks } = warehouse;

  // Tab and list state
  const [showFilters, setShowFilters] = useState(false);
  const [currentSidebarTab, onSelectSidebarTab] = useState(1);

  // Determines which editor to show, and what mode it is in
  const [state, dispatch] = useReducer(editorsReducer, {});

  const handleRacksUpdated = useCallback(() => {
    refreshAreas();
    refreshBlueprint();
  }, [refreshBlueprint, refreshAreas]);

  const handleSetAreaId = useCallback((id) => {
    if (!id) {
      throw new Error("Area ID is required");
    }
    dispatch({
      type: ACTION_TYPES.edit,
      editor: EDITORS.areas,
      mode: EDITOR_MODES.edit,
      id,
    });
  }, []);

  const handleOnHideAreaEditor = useCallback(() => {
    dispatch({ type: ACTION_TYPES.reset });
    refreshBlueprint();
  }, [refreshBlueprint]);

  if (state.editor === EDITORS.areas) {
    return (
      <AreasEditor
        areaId={state.id}
        areas={areas}
        onDataChanged={handleRacksUpdated}
        onHide={handleOnHideAreaEditor}
        racks={racks}
        setAreaId={handleSetAreaId}
        warehouseId={WarehouseId}
      />
    );
  }

  if (state.editor === EDITORS.bays) {
    return (
      <BayEditor
        SiteId={SiteId}
        bayTemplateId={state.id}
        mode={state.mode}
        onHide={() => {
          dispatch({ type: ACTION_TYPES.reset });
          refreshBayTemplates();
          refreshBlueprint();
        }}
        permission={permission}
      />
    );
  }

  if (state.editor === EDITORS.frames) {
    return (
      <FrameEditor
        SiteId={SiteId}
        frameTemplateId={state.id}
        mode={state.mode}
        onHide={() => {
          refreshFrameTemplates();
          dispatch({ type: ACTION_TYPES.reset });
          refreshBlueprint();
        }}
      />
    );
  }

  if (state.editor === EDITORS.racks) {
    return (
      <RackEditor
        RackId={state.id}
        onAddAnother={async id => {
          await refreshBlueprint();
          await refreshAreas();
          dispatch({ type: ACTION_TYPES.edit, editor: EDITORS.racks, mode: EDITOR_MODES.copy, id })
        }} // prettier-ignore
        SiteId={SiteId}
        WarehouseId={WarehouseId}
        mode={state.mode}
        onHide={async () => {
          dispatch({ type: ACTION_TYPES.reset });
          await refreshBlueprint();
          await refreshAreas();
        }}
        racks={racks}
      />
    );
  }

  const loading =
    manufactuersLoading || frameTemplatesLoading || bayTemplatesLoading;
  const type = getType(currentSidebarTab);
  const canEdit = permission.level.includes("organisation");

  return (
    <PanelNoMargin>
      <div className="table table-container">
        <StyledTabsWrapper
          className="table-row sticky-header type-tabs"
          currentSidebarTab={currentSidebarTab}
        >
          <TabContainer id="tabcontainer">
            <Tabs
              activeKey={currentSidebarTab}
              animation={false}
              className="clearfix"
              defaultActiveKey={currentSidebarTab}
              onSelect={onSelectSidebarTab}
            >
              <Tab eventKey={1} title="Frames" />
              <Tab eventKey={2} title="Bays" />
              <Tab eventKey={3} title="Racks" />
              <Tab eventKey={4} title="Areas" />
            </Tabs>
          </TabContainer>
          <FilterAddControl
            canEdit={canEdit}
            editor={type}
            onAdd={() =>
              dispatch({
                type: ACTION_TYPES.edit,
                editor: type,
                mode: EDITOR_MODES.new,
              })
            }
            onToggleShowFilters={() => setShowFilters(!showFilters)}
          />
        </StyledTabsWrapper>
        <div className="table-row body">
          <div className="body-content-wrapper">
            <div className="body-content dark">
              {loading ? (
                <LoadingListGroup />
              ) : (
                <>
                  {currentSidebarTab === 1 ? (
                    <FrameTypes
                      canEdit={canEdit}
                      frameTemplates={frameTemplates}
                      onSelectFrameTemplate={(id, mode) =>
                        dispatch({
                          type: ACTION_TYPES.edit,
                          editor: EDITORS.frames,
                          id,
                          mode,
                        })
                      }
                      showFilters={showFilters}
                    />
                  ) : null}

                  {currentSidebarTab === 2 ? (
                    <BayTypes
                      bayTemplates={bayTemplates}
                      canEdit={canEdit}
                      manufacturers={manufacturers}
                      onSelectBayTemplate={(id, mode) =>
                        dispatch({
                          type: ACTION_TYPES.edit,
                          editor: EDITORS.bays,
                          id,
                          mode,
                        })
                      }
                      showFilters={showFilters}
                    />
                  ) : null}

                  {currentSidebarTab === 3 ? (
                    <Racks
                      areas={areas}
                      canEdit={canEdit}
                      manufacturers={manufacturers}
                      onSelectRack={(id, mode) => {
                        dispatch({
                          type: ACTION_TYPES.edit,
                          editor: EDITORS.racks,
                          id,
                          mode,
                        });
                      }}
                      racks={racks}
                      showFilters={showFilters}
                    />
                  ) : null}

                  {currentSidebarTab === 4 ? (
                    <AreasList
                      areas={areas}
                      canEdit={canEdit}
                      onClickEdit={(id) => {
                        dispatch({
                          type: ACTION_TYPES.edit,
                          editor: EDITORS.areas,
                          id,
                          mode: EDITOR_MODES.edit,
                        });
                      }}
                    />
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </PanelNoMargin>
  );
}

BuildModeSidebar.propTypes = {
  areas: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  permission: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  racks: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  SiteId: PropTypes.string,
  WarehouseId: PropTypes.string,
};
