import { createContext, useMemo, useCallback, useContext, useReducer } from 'react';

const DownloaderContext = createContext([]);

function useDownloader() {
  return useContext(DownloaderContext);
}

// eslint-disable-next-line import/no-unused-modules
export function reducer(state = [], action = null) {
  if (!Array.isArray(state)) throw new Error('State must be an array');
  if (!action) return state;
  switch (action.type) {
    case 'start':
      if (!action.payload) throw new Error('Id must be passed to checkbox reducer');
      return [...state, action.payload];
    case 'end':
      if (!action.payload) throw new Error('Id must be passed to checkbox reducer');
      return state.filter((id) => id !== action.payload);
    case 'reset':
      return [];
    default:
      throw new Error();
  }
}

// TODO: Move toasts to the context?
// eslint-disable-next-line react/prop-types
function DownloaderProvider({ children }) {
  const [downloads, dispatch] = useReducer(reducer, []);
  const onStart = useCallback((id) => dispatch({ type: 'start', payload: id }), []);
  const onEnd = useCallback((id) => dispatch({ type: 'end', payload: id }), []);
  const isDownloading = useCallback((id) => downloads.includes(id), [downloads]);
  const value = useMemo(
    () => ({ onStart, onEnd, isDownloading, downloads }),
    [onStart, onEnd, isDownloading, downloads],
  );
  return <DownloaderContext.Provider value={value}>{children}</DownloaderContext.Provider>;
}

export { DownloaderProvider, useDownloader };
