import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { useSites } from "hooks/site";

import EmptyTableIndicator from "views/components/EmptyTableIndicator";

import TableHeader from "views/components/Tables/TableHeader";

import { Table } from "../../Tables/styles";
import { renderPadding } from "../helpers";

import SiteRow, { PaddingRow } from "./SiteRow";

/**
 * A mini sites table for a specific client
 */
export default function Sites({ ClientId }) {
  const { sites, total, isLoading } = useSites({ ClientId, amount: 4 });
  const paddingAmount = isLoading ? 0 : 4 - sites.length;
  const showPadding = !isLoading && paddingAmount !== 4; // Loaded, and min 1 row
  const showEmptyTableIndicator =
    isLoading || (!isLoading && sites.length === 0); // Loading or no rows
  return (
    <>
      <TableHeader
        left="Sites"
        right={
          <Link to={`/clients/${ClientId}/sites/`}>
            View all {total} sites <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        }
      />
      <Table>
        <thead>
          <tr>
            <th>Site</th>
            <th>Address</th>
            <th>Contact Name</th>
            <th className="hover-buttons">Controls</th>
          </tr>
        </thead>
        <tbody>
          {sites.map((site) => (
            <SiteRow key={site.id} site={site} />
          ))}
          {showPadding
            ? renderPadding(() => <PaddingRow />, paddingAmount)
            : null}
        </tbody>
      </Table>
      {showEmptyTableIndicator ? (
        <EmptyTableIndicator isLoading={isLoading} type="Sites" />
      ) : null}
    </>
  );
}

Sites.propTypes = {
  ClientId: PropTypes.string.isRequired,
};
