import styled from "@emotion/styled";
import { faEnvelope, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const ViewedIndicatorContainer = styled.span`
  .btn {
    width: 40px;
    border-radius: 5px !important;
    height: 40px;
    padding: 0px;
    color: #2870ed;
    background-color: #d9dfee;
  }
`;

function ViewedIndicator(props) {
  const { onClick, viewed, disabled } = props;
  function getIconType() {
    if (viewed) {
      return true;
    }
    return false;
  }

  return (
    <ViewedIndicatorContainer>
      <Button
        className={viewed ? "btn-viewed" : "btn-unviewed"}
        disabled={disabled}
        onClick={onClick}
      >
        {getIconType() ? (
          <FontAwesomeIcon icon={faEnvelopeOpen} />
        ) : (
          <FontAwesomeIcon icon={faEnvelope} />
        )}
      </Button>
    </ViewedIndicatorContainer>
  );
}

ViewedIndicator.propTypes = {
  onClick: PropTypes.func,
  viewed: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ViewedIndicator;
