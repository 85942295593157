import PropTypes from 'prop-types';

import { FormControl, FormGroup, Col, Row, ControlLabel } from 'react-bootstrap';

/**
 * Form control for the Schedule Editor for setting the schedule interval
 */
export default function ControlRepeatSchedule({ type, onSetType }) {
  return (
    <FormGroup controlId="formType">
      <Row sm={12}>
        <Col sm={12}>
          <Col sm={12}>
            <ControlLabel>Repeat Schedule</ControlLabel>
          </Col>
          <Col sm={12}>
            <FormControl
              componentClass="select"
              onChange={(e) => {
                onSetType(e.target.value);
              }}
              value={type}
            >
              <option value="daily">Daily</option>
              <option value="weekdaily">Every Weekday</option>
              <option value="weekly">Weekly</option>
              <option value="fortnightly">Fortnightly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </FormControl>
          </Col>
        </Col>
      </Row>
    </FormGroup>
  );
}

ControlRepeatSchedule.propTypes = {
  /** String for the interval type */
  type: PropTypes.oneOf(['daily', 'weekdaily', 'weekly', 'fortnightly', 'monthly', 'yearly'])
    .isRequired,
  /** Function to update the schedule interval type */
  onSetType: PropTypes.func.isRequired,
};
