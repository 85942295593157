/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Checkbox } from "react-bootstrap";

function SiteGroupOption({ siteGroup, disabled, onChange, permissions }) {
  return (
    <div>
      <Checkbox
        checked={
          permissions &&
          (permissions.siteGroup.includes(siteGroup.id) || disabled)
        }
        disabled={disabled}
        onChange={(e) => onChange(!!e.target.checked)}
      >
        {siteGroup.name} ({siteGroup.sites.length} Sites
        {siteGroup.sites.length
          ? `: ${siteGroup.sites.map((site) => site.name).join(", ")}`
          : ""}
        )
      </Checkbox>
    </div>
  );
}

SiteGroupOption.propTypes = {
  siteGroup: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  }),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  permissions: PropTypes.shape({
    siteGroup: PropTypes.arrayOf(PropTypes.string),
  }),
};

function SiteGroupSelector({
  siteGroups = [],
  disabled,
  permissions,
  onAddPermissions,
  onDeletePermissions,
  userId,
}) {
  if (!siteGroups) {
    return null;
  }
  return (
    <div
      css={css`
        max-height: 150px;
        background-color: #eee;
        border: 1px solid #ddd;
        overflow-y: scroll;
        padding: 2px;
      `}
    >
      {siteGroups.map((siteGroup) => (
        <SiteGroupOption
          disabled={disabled}
          key={siteGroup.id}
          onChange={(added) =>
            added
              ? onAddPermissions(userId, "siteGroup", [siteGroup.id])
              : onDeletePermissions(userId, "siteGroup", [siteGroup.id])
          }
          permissions={permissions}
          siteGroup={siteGroup}
        />
      ))}
    </div>
  );
}

SiteGroupSelector.propTypes = {
  onAddPermissions: PropTypes.func,
  onDeletePermissions: PropTypes.func,
  siteGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      sites: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
    }),
  ),
  permissions: PropTypes.shape({
    clientAdmin: PropTypes.arrayOf(PropTypes.string),
    client: PropTypes.arrayOf(PropTypes.string),
    siteGroup: PropTypes.arrayOf(PropTypes.string),
  }),
  userId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SiteGroupSelector;
