import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function getTypes(type, ManufacturerId = false, filter = null) {
  const urlEnd = ManufacturerId ? `/manufacturer/${ManufacturerId}` : "";
  const res = await apiCall(
    `/type/${type}${urlEnd}${filterUrlGenerator(filter)}`,
  );
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem getting ${type} types`);
}
