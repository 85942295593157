/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useId, useState } from "react";
import { Checkbox, DropdownButton, MenuItem, Radio } from "react-bootstrap";

import { useComponentTypes, useComponents } from "hooks/classifications";

import RiskCircles from "./RiskCircles";

const Wrapper = styled.div`
  display: flex;
  padding-top: 5px;
  .btn span {
    padding: 0px;
  }
  .btn {
    border-radius: 5px !important;
    background-color: #ffffff;
    color: #337ab7;
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
`;
function RiskDropdown({
  hideRed,
  hideAmber,
  hideGreen,
  setColorFilters,
  isAction,
}) {
  const a11yDropdownId = useId();
  const riskTitle =
    (!hideRed && !hideAmber && !hideGreen) ||
    (hideRed && hideAmber && hideGreen) ? (
      <span
        style={{
          marginRight: 5,
        }}
      >
        Filter By Risk
      </span>
    ) : (
      <RiskCircles
        hideAmber={hideAmber}
        hideGreen={hideGreen}
        hideRed={hideRed}
        type={isAction ? "action" : null}
      />
    );
  return (
    <DropdownButton
      id={a11yDropdownId}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      title={riskTitle}
    >
      <MenuItem
        onClick={() =>
          setColorFilters({
            hideRed: !(hideRed || hideAmber || hideGreen),
            hideAmber: !(hideRed || hideAmber || hideGreen),
            hideGreen: !(hideRed || hideAmber || hideGreen),
          })
        }
      >
        <Checkbox
          checked={!hideRed && !hideAmber && !hideGreen}
          onChange={() =>
            setColorFilters({
              hideRed: !(hideRed || hideAmber || hideGreen),
              hideAmber: !(hideRed || hideAmber || hideGreen),
              hideGreen: !(hideRed || hideAmber || hideGreen),
            })
          }
        >
          All
        </Checkbox>
      </MenuItem>
      <MenuItem
        onClick={() =>
          setColorFilters({
            hideRed: !hideRed,
          })
        }
      >
        <Checkbox
          checked={!hideRed}
          onChange={() =>
            setColorFilters({
              hideRed: !hideRed,
            })
          }
        >
          Red
        </Checkbox>
      </MenuItem>
      <MenuItem
        onClick={() =>
          setColorFilters({
            hideAmber: !hideAmber,
          })
        }
      >
        <Checkbox
          checked={!hideAmber}
          onChange={() =>
            setColorFilters({
              hideAmber: !hideAmber,
            })
          }
        >
          Amber
        </Checkbox>
      </MenuItem>
      {!isAction ? (
        <MenuItem
          onClick={() =>
            setColorFilters({
              hideGreen: !hideGreen,
            })
          }
        >
          <Checkbox
            checked={!hideGreen}
            onChange={() =>
              setColorFilters({
                hideGreen: !hideGreen,
              })
            }
          >
            Green
          </Checkbox>
        </MenuItem>
      ) : null}
    </DropdownButton>
  );
}

RiskDropdown.propTypes = {
  hideRed: PropTypes.bool,
  hideAmber: PropTypes.bool,
  hideGreen: PropTypes.bool,
  isAction: PropTypes.bool,
  setColorFilters: PropTypes.func,
};

function ComponentMenuSection({
  type,
  setSelectedComponent,
  selectedComponent,
  toggleComponentMenu,
}) {
  const { components } = useComponents(type);
  return (
    <>
      <MenuItem disabled>{`${type} Components`}</MenuItem>
      {components.map((option) => (
        <MenuItem
          key={option}
          onClick={() => {
            setSelectedComponent(option);
            toggleComponentMenu();
          }}
        >
          <Radio
            checked={selectedComponent === option}
            onChange={() => setSelectedComponent(option)}
          >
            {option}
          </Radio>
        </MenuItem>
      ))}
      ;
    </>
  );
}

ComponentMenuSection.propTypes = {
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  setSelectedComponent: PropTypes.func,
  toggleComponentMenu: PropTypes.func,
  selectedComponent: PropTypes.string,
};

function BlueprintFilterOptions({
  hideAmber,
  hideGreen,
  hideRed,
  isAction,
  selectedComponent,
  setColorFilters,
  setSelectedComponent,
}) {
  const a11yDropdownId = useId();
  const [componentMenuOpened, toggleComponentMenu] = useState(false);

  const { componentTypes } = useComponentTypes();

  if (!componentTypes) {
    return null;
  }

  const componentTitle = selectedComponent || "Filter By Component";

  return (
    <Wrapper>
      <DropdownButton
        id={a11yDropdownId}
        onToggle={() => toggleComponentMenu((t) => !t)}
        open={componentMenuOpened}
        title={componentTitle}
      >
        <MenuItem onClick={() => setSelectedComponent(null)}>
          <Radio
            checked={!selectedComponent}
            onChange={() => setSelectedComponent(null)}
          >
            All
          </Radio>
        </MenuItem>
        {componentTypes.map((componentType) => (
          <ComponentMenuSection
            key={componentType}
            selectedComponent={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            toggleComponentMenu={() => toggleComponentMenu((t) => !t)}
            type={componentType}
          />
        ))}
        <MenuItem disabled>Miscellaneous</MenuItem>
        <MenuItem onClick={() => setSelectedComponent("Misc. Damage")}>
          <Radio
            checked={selectedComponent === "Misc. Damage"}
            onChange={() => setSelectedComponent("Misc. Damage")}
          >
            Misc Damage
          </Radio>
        </MenuItem>
      </DropdownButton>
      <RiskDropdown
        hideAmber={hideAmber}
        hideGreen={hideGreen}
        hideRed={hideRed}
        isAction={isAction}
        setColorFilters={setColorFilters}
      />
    </Wrapper>
  );
}

BlueprintFilterOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.array,
  hideAmber: PropTypes.bool,
  hideGreen: PropTypes.bool,
  hideRed: PropTypes.bool,
  setSelectedComponent: PropTypes.func,
  setColorFilters: PropTypes.func,
  selectedComponent: PropTypes.string,
  toggleComponentMenu: PropTypes.func,
  componentMenuOpened: PropTypes.bool,
  isAction: PropTypes.bool,
};
export default BlueprintFilterOptions;
