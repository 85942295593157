import styled from "@emotion/styled";
import {
  faCamera,
  faChevronDown,
  faChevronRight,
  faFileLines,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { Alert, Button, Col, FormControl, Row, Table } from "react-bootstrap";

import { useInspectionActions } from "hooks/actions";
import { useClassificationImages } from "hooks/classifications";
import { useInspection, usePreviousClassifications } from "hooks/inspections";
import {
  editClassificationNote,
  resolveAction,
  unresolveAction,
} from "libs/api";

import { PRIMARY_DATETIME_FORMAT_SHORT } from "libs/constants";
import DamageCircle from "views/components/DamageCircle";
import Details from "views/components/InspectionClassificationTable/Details";
import Images from "views/components/InspectionClassificationTable/Images";
import FlatModal from "views/components/Modals/FlatModal";
import classificationReducer, {
  ACTION_TYPES,
} from "views/components/Modals/NewClassificationEditor/reducer";
import RiskUpdatedIndicator from "views/components/RiskUpdatedIndicator";
import ClassificationsLightbox from "views/pages/Inspection/components/ClassificationsLightbox";

const ClassificationTable = styled(Table)`
  background-color: #ffffff;
  color: #494c55;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 0;

  thead {
    background-color: #494c55;
    color: #ffffff;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
  }

  th {
    border-bottom: 0 !important;
  }
`;

const SectionExpansionPanel = styled.div`
  background-color: #ffffff;
  border-top: 1px solid #99999944;
  color: #494c55;
  margin-bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const ClassificationWrapper = styled.div`
  margin-bottom: 20px;
`;

const ControlsHeaderRow = styled.tr`
  cursor: pointer;
`;

const ControlsHeader = styled.th`
  text-align: right;
  width: 6%;
`;

// TODO: This was previously vw. Why? TODO: Choose a better format here.
const TableHeaderCell = styled.th`
  width: 6%;
`;

const AttachmentIcons = styled.span`
  svg {
    color: #fece46;
    margin-left: 5px;
  }
`;

const NotesFieldWrap = styled.div`
  textarea {
    background: #eeeeee;
    border-radius: 5px;
    border: 0;
    box-shadow: inset 0 0px 0px rgb(0 0 0 / 0%);
    color: #747474;
    margin-top: 10px;
    padding: 6px 12px;
    width: 100%;
  }
`;

const Controls = styled.td`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

const NotesControls = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SaveButton = styled(Button)`
  margin: 0;
  padding: 2px 10px;

  &:disabled {
    margin: 0;
    padding: 2px 10px;
  }
`;

const HeaderWithButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const ModalWrapper = styled.div`
  align-items: center;
  margin: 10px;
`;

// This is here because editing the underlying function would require further
// refactoring.
// TODO: Refactor this lib function
const wrappedEditClassificationNote = async (id, notes) => {
  const res = await editClassificationNote(id, notes);
  if (res.ok) {
    return res;
  }
  throw new Error("Failed to edit classification note");
};

function NotesField({
  InspectionId,
  classificationId,
  notes,
  isActive = false,
}) {
  const { inspection, mutate: refreshInspection } = useInspection(InspectionId);
  const { mutate: refreshPrevious } = usePreviousClassifications(
    inspection?.SiteId,
    InspectionId,
  );

  // Local State allows the over-writing of the saved value
  const [notesValue, setNotesValue] = useState(notes);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  return (
    <NotesFieldWrap>
      <NotesControls>
        Notes {error ? <Alert bsStyle="danger">{error}</Alert> : null}
        {notesValue !== notes ? (
          <SaveButton
            bsSize="xs"
            disabled={saving}
            onClick={async () => {
              setSaving(true);
              setError(null);
              try {
                await wrappedEditClassificationNote(
                  classificationId,
                  notesValue,
                );
              } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                setError("Error saving. Please try again.");
              }
              await refreshInspection();
              await refreshPrevious();
              setSaving(false);
            }}
            type="button"
          >
            {saving ? "Saving..." : "Save"}
          </SaveButton>
        ) : null}
      </NotesControls>
      <textarea
        disabled={!isActive}
        onChange={(e) => setNotesValue(e.target.value)}
        placeholder="(No notes)"
        value={notesValue ?? ""}
      />
    </NotesFieldWrap>
  );
}

NotesField.propTypes = {
  InspectionId: PropTypes.string.isRequired,
  classificationId: PropTypes.string.isRequired,
  notes: PropTypes.string,
  isActive: PropTypes.bool,
};

function ActionDetails({ classification }) {
  return (
    <>
      <h5>Action Details</h5>

      <div>
        {classification.resolvedAt ? "Date Resolved: " : "Date Unresolved: "}

        {moment(
          classification.resolvedAt ?? classification.cantResolveAt,
        ).format(PRIMARY_DATETIME_FORMAT_SHORT)}
      </div>
      <div>
        {classification.resolvedAt ? "Resolved By: " : "Unresolved By: "}
        {classification.resolver
          ? classification.resolver.name
          : classification.id}
      </div>
    </>
  );
}

ActionDetails.propTypes = {
  classification: PropTypes.shape({
    cantResolveAt: PropTypes.string,
    id: PropTypes.string.isRequired,
    resolver: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    resolvedAt: PropTypes.string,
  }).isRequired,
};

function OffloadedLocations({ classification }) {
  // Group locations by PalletName
  const groupedByPallet = classification.offloadedLocations?.reduce(
    (acc, location) => {
      const { PalletName } = location;
      if (!acc[PalletName]) {
        acc[PalletName] = [];
      }
      acc[PalletName].push(location);
      return acc;
    },
    {},
  );

  return (
    <div
      style={{
        marginLeft: "20px",
      }}
    >
      <h5>Associated Offloaded Locations</h5>

      {groupedByPallet && Object.keys(groupedByPallet).length ? (
        <div style={{ display: "flex", gap: "20px" }}>
          {Object.keys(groupedByPallet).map((palletName) => (
            <div key={palletName} style={{ flex: 1 }}>
              <ul style={{ listStyle: "none", padding: 0 }}>
                {groupedByPallet[palletName].map(
                  ({ id, RackName, PalletName, LocationName, loadedAt }) => (
                    <li
                      key={id}
                      style={{
                        padding: "5px 0",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: loadedAt ? "green" : "red",
                          marginRight: "10px",
                        }}
                      ></span>
                      {RackName} {PalletName} {LocationName}
                      <span
                        style={{
                          display: "inline-block",
                          color: loadedAt ? "green" : "red",
                          marginLeft: "10px",
                        }}
                      >
                        {loadedAt ? "(Reopened)" : " (Currently offloaded)"}
                      </span>
                    </li>
                  ),
                )}
              </ul>
            </div>
          ))}
        </div>
      ) : (
        <p>No offloaded locations</p>
      )}
    </div>
  );
}

OffloadedLocations.propTypes = {
  classification: PropTypes.shape({
    offloadedLocations: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
};

/**
 * Note: the classification.InspectionId is the original inspection ID that the
 * classification was added on, not the current InspectionId, hence the need to
 * pass in the current InspectionId.
 */
export default function Classification({
  children,
  classification,
  InspectionId,
  isActive = false,
  showAction = false,
  showOffloadedLocations = false,
  isRepair = false,
}) {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [panelExpanded, setPanelExpanded] = useState(false);
  const { inspection } = useInspection(InspectionId);
  const { images, mutate: refreshImages } = useClassificationImages(
    classification?.id,
  );
  const [state, dispatch] = useReducer(classificationReducer, {});
  const classificationLightbox = useRef();
  const imagesFiltered = images?.filter((image) => !image.actionFile) ?? [];
  const actionImages = images?.filter((image) => !!image.actionFile) ?? [];
  const [saving, setSaving] = useState(false);
  const { mutate: refreshActions } = useInspectionActions(InspectionId, {
    showGreen: false,
  });

  useEffect(() => {
    refreshImages();
  }, [classification]);
  const handleNoteSave = useCallback(async () => {
    setSaving(true);
    if (!state.actionNote.length && classification.cantResolveAt) {
      await unresolveAction(classification.id);
    } else {
      await resolveAction(
        classification.id,
        !!classification.cantResolveAt,
        state.actionNote,
      );
    }
    await refreshActions();
    setSaving(false);
  }, [
    classification.cantResolveAt,
    classification.id,
    refreshActions,
    state.actionNote,
  ]);
  return (
    <ClassificationWrapper key={classification.id}>
      <ClassificationTable>
        <thead>
          <ControlsHeaderRow onClick={() => setPanelExpanded(!panelExpanded)}>
            <TableHeaderCell>Location</TableHeaderCell>
            <TableHeaderCell>Level</TableHeaderCell>
            <TableHeaderCell>Component</TableHeaderCell>
            <TableHeaderCell>Position</TableHeaderCell>
            <TableHeaderCell>Risk</TableHeaderCell>
            <TableHeaderCell>Quantity</TableHeaderCell>
            {classification.riskUpdated && isRepair && (
              <TableHeaderCell></TableHeaderCell>
            )}
            {classification.customerReference ? (
              <TableHeaderCell>Customer ID</TableHeaderCell>
            ) : null}
            <ControlsHeader>
              <AttachmentIcons>
                {imagesFiltered.length > 0 ? (
                  <>
                    {imagesFiltered.length}{" "}
                    <FontAwesomeIcon fixedWidth icon={faCamera} />
                  </>
                ) : null}
                {classification.notes ? (
                  <FontAwesomeIcon fixedWidth icon={faFileLines} />
                ) : null}
              </AttachmentIcons>
              <FontAwesomeIcon
                fixedWidth
                icon={panelExpanded ? faChevronDown : faChevronRight}
              />
            </ControlsHeader>
          </ControlsHeaderRow>
        </thead>
        <tbody>
          <tr>
            <td>{classification.location}</td>
            <td>
              {classification.beam
                ? classification.beam.level
                : classification.level || "N/A"}
            </td>
            <td>
              {classification.componentTitle ||
                classification.component ||
                classification.miscComponent}
            </td>
            <td>{classification.position || classification.miscPosition}</td>
            <td>
              <DamageCircle RiskName={classification.risk} />
            </td>
            <td>{classification.quantity || "1"}</td>
            {classification.riskUpdated && isRepair && (
              <td>
                <ControlsHeaderRow onClick={() => setShowInfoModal(true)}>
                  <RiskUpdatedIndicator />
                </ControlsHeaderRow>
              </td>
            )}
            {classification.customerReference ? (
              <td>{classification.customerReference}</td>
            ) : null}
            {children ? <Controls>{children}</Controls> : <td />}
          </tr>
        </tbody>
      </ClassificationTable>
      {panelExpanded ? (
        <>
          <SectionExpansionPanel>
            <Row>
              <Col sm={5}>
                <Details classification={classification} />
              </Col>

              <Col sm={3}>
                <NotesField
                  InspectionId={InspectionId}
                  classificationId={classification.id}
                  isActive={isActive}
                  notes={classification.notes}
                />
              </Col>
              <Col sm={3}>
                <Images
                  classificationId={classification.id}
                  images={imagesFiltered ?? []}
                  isBlueprint={false}
                  onClickImage={(idx) => {
                    classificationLightbox?.current?.openWith(
                      classification.id,
                      idx,
                    );
                    classificationLightbox?.current?.isActionFile(false);
                  }}
                  refreshImages={refreshImages}
                  showUploader={isActive}
                />
              </Col>
            </Row>
          </SectionExpansionPanel>
          {showAction ? (
            <SectionExpansionPanel>
              <Row>
                <Col sm={3}>
                  <Col sm={12}>
                    <ActionDetails classification={classification} />
                  </Col>
                </Col>
                <Col sm={4}>
                  <HeaderWithButtonWrapper>
                    <h5>Action Notes</h5>

                    {classification.actionNote !== state.actionNote &&
                    state.actionNote &&
                    state.actionNote !== "" ? (
                      <SaveButton onClick={handleNoteSave}>
                        Save{" "}
                        {saving ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : null}
                      </SaveButton>
                    ) : null}
                  </HeaderWithButtonWrapper>
                  <FormControl
                    componentClass="textarea"
                    disabled={inspection.status === "Completed"}
                    onChange={(e) => {
                      dispatch({
                        type: ACTION_TYPES.SET_ACTION_NOTE,
                        actionNote: e.target.value,
                      });
                    }}
                    placeholder={`${
                      inspection?.completedAt ? "No" : "Add"
                    } Notes`}
                    value={state.actionNote ?? classification.actionNote ?? ""}
                  />
                </Col>
                <Col sm={4}>
                  <h5>Action Evidence Images</h5>
                  <Images
                    classificationId={classification.id}
                    images={actionImages ?? []}
                    isBlueprint={false}
                    onClickImage={(idx) => {
                      classificationLightbox?.current?.openWith(
                        classification.id,
                        idx,
                      );
                      classificationLightbox?.current?.isActionFile(true);
                    }}
                    refreshImages={refreshImages}
                    showUploader={isActive}
                  />
                </Col>
              </Row>
            </SectionExpansionPanel>
          ) : null}
          {showOffloadedLocations ? (
            <SectionExpansionPanel>
              <Row>
                <Col sm={12}>
                  <OffloadedLocations classification={classification} />
                </Col>
              </Row>
            </SectionExpansionPanel>
          ) : null}
        </>
      ) : null}
      {/**
       * TODO: The lightbox doesn't close, but it appears that staging doesn't either, so this
       * is not a regression, but a bug to fix.
       */}
      <ClassificationsLightbox
        hideSaveButton={!!inspection?.completedAt}
        readOnly={inspection?.completedAt}
        ref={classificationLightbox}
      />
      <FlatModal
        closeButton
        onHide={() => setShowInfoModal(false)}
        show={showInfoModal}
        title="Info"
      >
        <ModalWrapper>
          A damage classification(s) has been reclassified from amber to red.
        </ModalWrapper>
      </FlatModal>
    </ClassificationWrapper>
  );
}

Classification.propTypes = {
  InspectionId: PropTypes.string.isRequired,
  classification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    beam: PropTypes.shape({
      level: PropTypes.string.isRequired,
    }),
    component: PropTypes.string,
    componentTitle: PropTypes.string,
    customerReference: PropTypes.string,
    level: PropTypes.string,
    location: PropTypes.string.isRequired,
    miscComponent: PropTypes.string,
    miscPosition: PropTypes.string,
    notes: PropTypes.string,
    position: PropTypes.string,
    quantity: PropTypes.number,
    risk: PropTypes.string.isRequired,
    actionNote: PropTypes.string,
    cantResolveAt: PropTypes.string,
    resolvedAt: PropTypes.string,
    riskUpdated: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  showAction: PropTypes.bool,
  showOffloadedLocations: PropTypes.bool,
  isRepair: PropTypes.bool,
};
