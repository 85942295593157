import PropTypes from "prop-types";
import { useState, useId } from "react";
import { MenuItem, SplitButton } from "react-bootstrap";

import FlatModal from "views/components/Modals/FlatModal";
import AddDamageEditor from "views/components/Modals/NewClassificationEditor/add-editor";
import MiscDamageEditor from "views/components/Modals/NewClassificationEditor/misc-editor";
import { AddDamageWrapper } from "views/pages/Inspection/styles";
import OffloadLocationEditor from "views/components/Modals/NewClassificationEditor/offload-editor.jsx";

// Constants for the modals available onthe page
const MODALS = {
  misc: "misc",
  add: "add",
  offload: "offload",
};

/**
 * Split button for adding regular or misc damage, coupled with modals for each
 */
export default function AddDamageButton({ InspectionId, warehouseId, RackId }) {
  const [showModal, setShowModal] = useState(false);
  const [frameLocationId, setFrameLocationId] = useState(null);
  const [beamLevel, setBeamLevel] = useState(null);
  const [classificationId, setClassificationId] = useState(null);
  const id = useId();
  if (!InspectionId || !warehouseId || !RackId) return null;
  return (
    <>
      <AddDamageWrapper>
        <SplitButton
          id={id}
          onClick={() => setShowModal(MODALS.add)}
          pullRight
          title="Add Damage"
        >
          <MenuItem onClick={() => setShowModal(MODALS.misc)}>
            Add Misc Damage
          </MenuItem>
        </SplitButton>
      </AddDamageWrapper>
      {showModal === MODALS.add ? (
        <FlatModal
          closeButton
          onHide={() => setShowModal(false)}
          show
          title="Add Damage"
        >
          <AddDamageEditor
            InspectionId={InspectionId}
            RackId={RackId}
            onHide={() => setShowModal(false)}
            warehouseId={warehouseId}
            setShowOffloadModal={() => setShowModal(MODALS.offload)}
            setFrameLocationId={setFrameLocationId}
            setBeamLevel={setBeamLevel}
            setClassificationId={setClassificationId}
          />
        </FlatModal>
      ) : null}

      {showModal === MODALS.misc ? (
        <FlatModal
          closeButton
          onHide={() => setShowModal(false)}
          show
          title="Add Misc Damage"
        >
          <MiscDamageEditor
            InspectionId={InspectionId}
            RackId={RackId}
            onHide={() => setShowModal(false)}
            warehouseId={warehouseId}
            setShowOffloadModal={() => setShowModal(MODALS.offload)}
            setFrameLocationId={setFrameLocationId}
            setBeamLevel={setBeamLevel}
            setClassificationId={setClassificationId}
          />
        </FlatModal>
      ) : null}
      {showModal === MODALS.offload && classificationId ? (
        <FlatModal
          closeButton
          onHide={() => setShowModal(false)}
          show
          title="Offload Locations"
        >
          <OffloadLocationEditor
            InspectionId={InspectionId}
            ClassificationId={classificationId}
            RackId={RackId}
            onHide={() => setShowModal(false)}
            warehouseId={warehouseId}
            frameLocationId={frameLocationId}
            beamLevel={beamLevel}
          />
        </FlatModal>
      ) : null}
    </>
  );
}

AddDamageButton.propTypes = {
  InspectionId: PropTypes.string,
  warehouseId: PropTypes.string,
  RackId: PropTypes.string,
};
