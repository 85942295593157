import PropTypes from "prop-types";

import { deleteClassification, unreclassifyClassification } from "libs/api";

import ConfirmModal from "../Modals/ConfirmModal";
/**
 * This modal is used to confirm that a re-classified classification should be
 * deleted.
 */
export default function DeleteModal({
  classificationId,
  isReclassification,
  show = false,
  onHide = () => {},
  onDelete = () => {},
}) {
  if (!classificationId) return null;
  return (
    <ConfirmModal
      onConfirm={async () => {
        if (isReclassification) {
          await unreclassifyClassification(classificationId);
        } else {
          await deleteClassification(classificationId);
        }
        onDelete();
      }}
      onHide={onHide}
      show={show}
      title={`Delete ${isReclassification ? "Re" : ""}Classification?`}
    >
      {isReclassification
        ? `Are you sure you want to delete this Reclassified Classification? The previous classification will be set to unconfirmed.`
        : `Are you sure you want to delete this Classification? This can not be undone.`}
    </ConfirmModal>
  );
}

DeleteModal.propTypes = {
  show: PropTypes.bool,
  classificationId: PropTypes.string,
  isReclassification: PropTypes.bool,
  onHide: PropTypes.func,
  onDelete: PropTypes.func,
};
