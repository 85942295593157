import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useId } from "react";
import { FormControl } from "react-bootstrap";

const StyledFormControl = styled(FormControl)`
  display: inline-block;
  background-color: #e2e2e4;
  color: #676767;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
  margin-bottom: 10px;
  height: 34px;
  width: ${(props) => (props.small ? "160px" : "200px")};

  margin-left: 5px;
`;

/**
 * A drop down menu that displays a list of areas to choose from
 */
export default function InspectionAreaSelect({
  areas,
  selectedAreaId,
  onChange,
}) {
  const id = useId();
  if (!areas || !areas.length) {
    return null;
  }
  return (
    <StyledFormControl
      componentClass="select"
      id={id}
      onChange={(e) => onChange(e.target.value)}
      value={selectedAreaId ?? "All Areas"}
    >
      <option value="All Areas">All Areas</option>
      {areas
        .filter((area) => area.id)
        .map((area) => (
          <option key={area.id} value={area.id}>
            {area.name}
          </option>
        ))}
    </StyledFormControl>
  );
}

InspectionAreaSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  areas: PropTypes.array,
  onChange: PropTypes.func,
  selectedAreaId: PropTypes.string,
};
