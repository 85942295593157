import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faBookmark as faBookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { PurpleButton } from './colors';

export default function BookmarkButton({ disabled, onClick, bookmarked }) {
  return (
    <PurpleButton disabled={disabled} onClick={onClick}>
      {bookmarked ? (
        <FontAwesomeIcon icon={faBookmarkSolid} />
      ) : (
        <FontAwesomeIcon icon={faBookmark} />
      )}
      {bookmarked ? 'Bookmarked' : 'Bookmark'}
    </PurpleButton>
  );
}

BookmarkButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  bookmarked: PropTypes.bool,
};
