import PropTypes from "prop-types";
import { useState } from "react";

import { useSitePermission } from "hooks/schedules";
import { useCurrentUser } from "hooks/user";
import { deleteSchedule, toggleStatusSchedule } from "libs/api";
import { getScheduleTypeLabel } from "libs/schedule";

import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import ConfirmModal from "views/components/Modals/ConfirmModal";
import { Table } from "views/components/Tables/styles";

import ManageScheduleActionButtons from "../../components/ActionButtons/set-schedules-manage";

function ScheduleRow({
  schedule,
  userId,
  selectedClientId,
  selectedSiteId,
  onSchedulePauseResume,
  setDeleteScheduleId,
}) {
  const [showActionButtonSchedule, setShowActionButtonSchedule] =
    useState(false);

  const { permission } = useSitePermission(schedule.SiteId);

  const userCanEditSchedule =
    permission.level.includes("organisation") ||
    permission.level.includes("clientAdmin") ||
    schedule.AssigneeId === userId;
  return (
    <tr
      onMouseDown={() => {
        setShowActionButtonSchedule(schedule.id);
      }}
      onMouseEnter={() => {
        setShowActionButtonSchedule(schedule.id);
      }}
      onMouseLeave={() => {
        setShowActionButtonSchedule(null);
      }}
    >
      {!selectedClientId && !selectedSiteId && <td>{schedule.ClientName}</td>}
      {!selectedSiteId && <td>{schedule.SiteName}</td>}
      <td>{`${getScheduleTypeLabel(schedule.type)} ${
        schedule.pausedAt ? "(Paused)" : ""
      }`}</td>
      <td>{schedule.Assigner.name}</td>
      <td>{schedule.Assignee.name}</td>
      {showActionButtonSchedule === schedule.id && (
        <ManageScheduleActionButtons
          isPaused={!!schedule.pausedAt}
          onDeleteClick={
            userCanEditSchedule &&
            (() => {
              setDeleteScheduleId(schedule.id);
            })
          }
          onPauseResumeClick={
            userCanEditSchedule &&
            (async () => {
              await toggleStatusSchedule(schedule.id);
              onSchedulePauseResume();
            })
          }
        />
      )}
    </tr>
  );
}

ScheduleRow.propTypes = {
  onSchedulePauseResume: PropTypes.func,
  schedule: PropTypes.shape({
    Assignee: PropTypes.shape({
      name: PropTypes.string,
    }),
    AssigneeId: PropTypes.string,
    Assigner: PropTypes.shape({
      name: PropTypes.string,
    }),
    ClientName: PropTypes.string,
    SiteId: PropTypes.string,
    SiteName: PropTypes.string,
    id: PropTypes.string,
    pausedAt: PropTypes.string,
    type: PropTypes.string,
  }),
  selectedClientId: PropTypes.string,
  selectedSiteId: PropTypes.string,
  setDeleteScheduleId: PropTypes.func,
  userId: PropTypes.string,
};

function ManageSchedulesTab({
  schedules,
  selectedClientId,
  selectedSiteId,
  isLoading,
  onScheduleDeleted,
  onSchedulePauseResume,
}) {
  const { user } = useCurrentUser();
  const [deleteScheduleId, setDeleteScheduleId] = useState(null);
  const [deleteScheduleError, setDeleteScheduleError] = useState(null);

  return (
    <>
      <Table>
        <thead>
          <tr>
            {!selectedClientId && !selectedSiteId && <th>Client</th>}
            {!selectedSiteId && <th>Site</th>}
            <th>Repeat</th>
            <th>Assigned By</th>
            <th>Assignee</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            schedules.map((schedule) => (
              <ScheduleRow
                key={schedule.id}
                onSchedulePauseResume={onSchedulePauseResume}
                schedule={schedule}
                selectedClientId={selectedClientId}
                selectedSiteId={selectedSiteId}
                setDeleteScheduleId={setDeleteScheduleId}
                userId={user.id}
              />
            ))}
        </tbody>
      </Table>
      {(isLoading || !schedules.length) && (
        <EmptyTableIndicator
          isLoading={isLoading}
          searchable
          type="Schedules"
        />
      )}
      <ConfirmModal
        error={deleteScheduleError}
        onConfirm={async () => {
          const res = await deleteSchedule(deleteScheduleId);
          if (res.ok) {
            setDeleteScheduleId(null);
            onScheduleDeleted();
          } else {
            setDeleteScheduleError(res.status);
          }
        }}
        onHide={() => {
          setDeleteScheduleId(null);
        }}
        show={deleteScheduleId}
        title="Delete Schedule"
      >
        Are you sure you want to delete this schedule?
      </ConfirmModal>
    </>
  );
}

ManageSchedulesTab.propTypes = {
  isLoading: PropTypes.bool,
  onScheduleDeleted: PropTypes.func,
  onSchedulePauseResume: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  schedules: PropTypes.array,
  selectedClientId: PropTypes.string,
  selectedSiteId: PropTypes.string,
};

export default ManageSchedulesTab;
