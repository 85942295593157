import { Sprite } from "@pixi/react";
import { Texture } from "pixi.js";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import { SCALE_FACTOR } from "hooks/use-site-blueprint";

const BEAM_DEPTH = 60 * SCALE_FACTOR;

/**
 * Individual beams, used for interactivity during inspections
 */
function Beam({ x, y, width, onClick }) {
  const [isHovered, setIsHovered] = useState(false);

  const onHoverIn = useCallback(() => {
    setIsHovered(!!onClick);
  }, [onClick]);

  const onHoverOut = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <Sprite
      cullable
      cursor={onClick ? "pointer" : "default"}
      eventMode={onClick ? "static" : "none"}
      height={BEAM_DEPTH}
      pointerdown={onClick}
      pointerout={onHoverOut}
      pointerover={onHoverIn}
      texture={Texture.WHITE}
      tint={isHovered ? "0x337ab7" : "0x999999"}
      width={width}
      x={x}
      y={y}
    />
  );
}

Beam.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

/**
 * Bay Beams - gray bars at the front and rear of the rack
 * Beams aren't drawn for each individual bay, they are drawn for the entire rack
 */
function Beams({ rackWidth, rackDepth }) {
  return (
    <>
      <Sprite
        cullable
        height={BEAM_DEPTH}
        texture={Texture.WHITE}
        tint={"0x999999"}
        width={rackWidth}
        x={0}
        y={0}
      />
      <Sprite
        cullable
        height={BEAM_DEPTH}
        texture={Texture.WHITE}
        tint={"0x999999"}
        width={rackWidth}
        x={0}
        y={rackDepth - BEAM_DEPTH}
      />
    </>
  );
}

export default React.memo(Beams);

Beams.propTypes = {
  rackWidth: PropTypes.number.isRequired,
  rackDepth: PropTypes.number.isRequired,
};

export function BeamsForBays({ bays, rackDepth, onClick }) {
  const rackWidth = bays.reduce((acc, b) => acc + b.width, 0);
  if (onClick) {
    return bays.map((b) => (
      <React.Fragment key={b.id}>
        <Beam
          key={`${b.id}_top`}
          onClick={() => onClick(b.id)}
          width={b.width}
          x={b.offset}
          y={0}
        />
        <Beam
          key={`${b.id}_bottom`}
          onClick={() => onClick(b.id)}
          width={b.width}
          x={b.offset}
          y={rackDepth - BEAM_DEPTH}
        />
      </React.Fragment>
    ));
  }
  return <Beams rackDepth={rackDepth} rackWidth={rackWidth} />;
}

BeamsForBays.propTypes = {
  bays: PropTypes.arrayOf(PropTypes.shape({})),
  rackDepth: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};
