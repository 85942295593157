/* eslint-disable no-console */
import { OTHER_COLOUR_ID } from "constants";

import { apiCall } from "libs/api/helpers";

export async function getNewBayName(SiteId) {
  const res = await apiCall(`/template/bayName/${SiteId}`);
  if (res.ok) {
    const body = await res.json();
    return body.name;
  }
  throw new Error("Problem getting new bay name");
}

// TODO: Refactor this so it returns the JSON and not the fetch response
export async function deleteFrameTemplate(FrameTemplateId) {
  const res = await apiCall(`/template/frame/${FrameTemplateId}`, {
    method: "DELETE",
  });

  if (res.status === 200) {
    return res.json();
  }

  throw new Error("Problem deleting frame template");
}

// TODO: Refactor this so it returns the JSON and not the fetch response
export async function addFrameTemplate(FrameTemplate) {
  const formattedFrameTemplate = {
    ...FrameTemplate,
    ColourId:
      FrameTemplate.ColourId === OTHER_COLOUR_ID
        ? null
        : FrameTemplate.ColourId,
  };
  const res = await apiCall("/template/frame", {
    method: "POST",
    body: JSON.stringify(formattedFrameTemplate),
  });
  if (!res.ok) {
    throw new Error("Problem adding frame template");
  }
  return res;
}

// TODO: Refactor this so it returns the JSON and not the fetch response
export async function editFrameTemplate(FrameTemplate) {
  const formattedFrameTemplate = {
    ...FrameTemplate,
    ColourId:
      FrameTemplate.ColourId === OTHER_COLOUR_ID
        ? null
        : FrameTemplate.ColourId,
  };

  const res = await apiCall("/template/frame", {
    method: "PUT",
    body: JSON.stringify(formattedFrameTemplate),
  });
  return res;
}

export async function getFrameTemplate(FrameTemplateId) {
  const res = await apiCall(`/template/frame/${FrameTemplateId}`);
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  throw new Error("Problem getting frame template");
}

// TODO: Refactor this so it returns the JSON and not the fetch response
export async function addBayTemplate(bayTemplate) {
  const formattedBayTemplate = {
    ...bayTemplate,
    beamTemplates: bayTemplate.beamTemplates.map((bt) => ({
      ...bt,
      ColourId: bt.ColourId === OTHER_COLOUR_ID ? null : bt.ColourId,
    })),
  };
  const res = await apiCall("/template/bay/", {
    method: "POST",
    body: JSON.stringify(formattedBayTemplate),
  });
  const body = await res.json();
  if (res.status !== 200) {
    throw new Error(body.error);
  }
  return res;
}

// TODO: Refactor this so it returns the JSON and not the fetch response
export async function deleteBayTemplate(BayTemplateId) {
  const res = await apiCall(`/template/bay/${BayTemplateId}`, {
    method: "DELETE",
  });

  const body = await res.json();
  if (res.status !== 200) {
    throw new Error(body);
  }

  return body;
}

// TODO: Refactor this so it returns the JSON and not the fetch response
export async function editBayTemplate(bayTemplate) {
  const formattedBayTemplate = {
    ...bayTemplate,
    beamTemplates: bayTemplate.beamTemplates.map((bt) => ({
      ...bt,
      ColourId: bt.ColourId === OTHER_COLOUR_ID ? null : bt.ColourId,
    })),
  };
  const res = await apiCall("/template/bay/", {
    method: "PUT",
    body: JSON.stringify(formattedBayTemplate),
  });
  if (res.status === 200) {
    return res.json();
  }
  throw new Error("Problem editing bay template");
}

export async function getBayDepths(ManufacturerId, SiteId, SeriesId) {
  const res = await apiCall(
    `/template/bay/depth/${SiteId}/${ManufacturerId}/${SeriesId}`,
  );
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  throw new Error("Problem getting bay depths");
}

export async function getFrameDepths(ManufacturerId, SiteId, SeriesId) {
  const res = await apiCall(
    `/template/frame/depth/${SiteId}/${ManufacturerId}/${SeriesId}`,
  );
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  throw new Error("Problem getting frame depths");
}

export async function getFrameTemplates(SiteId) {
  const res = await apiCall(`/template/frames/${SiteId}`);
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  throw new Error("Problem getting frame templates");
}

export async function getFrameTemplatesDepths(
  SiteId,
  ManufacturerId = false,
  depth = false,
  SeriesId = null,
) {
  const res = await apiCall(
    `/template/frame/manufacturer/${ManufacturerId}/${SiteId}${
      depth ? `/${depth}` : ""
    }${SeriesId ? `/${SeriesId}` : ""}`,
  );
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  throw new Error("Problem getting frame template depths");
}

export async function getBayTemplates(SiteId) {
  const res = await apiCall(`/template/bays/${SiteId}`);
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  throw new Error("Problem getting bay templates");
}

export async function getBayTemplatesDepths(
  SiteId,
  ManufacturerId = false,
  depth = false,
  SeriesId = null,
) {
  const res = await apiCall(
    `/template/bay/manufacturer/${ManufacturerId}/${SiteId}${
      depth ? `/${depth}` : ""
    }${SeriesId ? `/${SeriesId}` : ""}`,
  );
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  throw new Error("Problem getting bay template depths");
}

export async function getBayTemplate(BayTemplateId) {
  const res = await apiCall(`/template/bay/${BayTemplateId}`);
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  throw new Error("Problem getting bay template");
}

export async function getNewFrameName(SiteId) {
  const res = await apiCall(`/template/frameName/${SiteId}`);
  if (res.ok) {
    const body = await res.json();
    return body.name;
  }
  throw new Error("Problem getting new frame name");
}
