import { Navigate, useParams } from "react-router-dom";

import { useSite } from "hooks/site";

import Loading from "views/components/Loading";
import PageBlueprintBuild from "views/pages/blueprint-site/page-blueprint-site-build";
import BlueprintView from "views/pages/blueprint-site/page-blueprint-site-view";

export default function PageBlueprint() {
  // Decide if this is a build mode blueprint or an inspection mode blueprint
  const { SiteId, WarehouseId } = useParams();
  const { site, isLoading } = useSite(SiteId);

  if (isLoading) {
    return <Loading text="Loading Blueprint" />;
  }

  // If there are no warehouses, then redirect the user to add some.
  if (!site.warehouses || site.warehouses.length === 0) {
    return <Navigate to={`/clients/sites/${SiteId}/warehouses/add`} />;
  }

  // Ensure we have a WarehouseID
  if (!WarehouseId && site.warehouses.length > 0) {
    return (
      <Navigate replace to={`/blueprint/${SiteId}/${site.warehouses[0].id}`} />
    );
  }

  /**
   * If the site is in build mode then we just show the current layout of the
   * warehouses with no classifications. The user can edit the layout.
   */
  if (site.buildMode) {
    return <PageBlueprintBuild SiteId={SiteId} WarehouseId={WarehouseId} />;
  }

  /**
   * Site is in view mode. We overlay current classifications on the blueprint.
   * The user cannot edit anything.
   */
  return (
    <BlueprintView
      SiteId={SiteId}
      WarehouseId={WarehouseId}
      key={`${SiteId}${WarehouseId}`}
    />
  );
}
