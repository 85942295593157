import PropTypes from "prop-types";
import { useState } from "react";
import { Panel, ListGroup, ListGroupItem } from "react-bootstrap";

import { useManufacturers } from "hooks/manufacturers";

import FrameFilters from "./Filters";
import FrameTemplate from "./FrameTemplate";
import { filterFrameTemplates } from "./helpers";

export default function FrameTypes({
  canEdit = false,
  frameTemplates = [],
  onSelectFrameTemplate,
  showFilters = false,
}) {
  const [filterOptions, setFilterOptions] = useState({
    searchText: "",
    ManufacturerId: null,
    minHeight: null,
    minWidth: null,
    maxHeight: null,
    maxWidth: null,
    duty: "",
  });

  const { manufacturers } = useManufacturers();
  const filteredFrameTemplates = filterFrameTemplates(
    frameTemplates,
    filterOptions,
  );

  return (
    <Panel>
      <Panel.Body className="no-padding-panel">
        {showFilters && (
          <FrameFilters
            filterOptions={filterOptions}
            manufacturers={manufacturers}
            setFilterOption={(key, value) =>
              setFilterOptions({
                ...filterOptions,
                [key]: value,
              })
            }
          />
        )}
        <ListGroup className="scroll-bar-contents">
          {filteredFrameTemplates.length > 0 ? (
            filteredFrameTemplates.map((ft) => (
              <FrameTemplate
                canEdit={canEdit}
                frameTemplate={ft}
                key={ft.id}
                manufacturerName={
                  manufacturers.find((m) => m.id === ft.ManufacturerId)?.name
                }
                onSelectFrameTemplate={onSelectFrameTemplate}
              />
            ))
          ) : (
            <ListGroupItem>No Frames Found</ListGroupItem>
          )}
        </ListGroup>
      </Panel.Body>
    </Panel>
  );
}

FrameTypes.propTypes = {
  canEdit: PropTypes.bool,
  frameTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      depth: PropTypes.number,
      id: PropTypes.string,
      name: PropTypes.string,
      sacLegHeight: PropTypes.number,
      SeriesName: PropTypes.string,
      sleeve: PropTypes.bool,
      sleeveHeight: PropTypes.number,
      sleeveSpacer: PropTypes.number,
      totalHeight: PropTypes.number,
      // TODO: Why is the width a string?!
      type: PropTypes.shape({ width: PropTypes.string }),
      ManufacturerId: PropTypes.string,
    }),
  ),
  onSelectFrameTemplate: PropTypes.func,
  showFilters: PropTypes.bool,
};
