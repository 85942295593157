import styled from "@emotion/styled";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, Table } from "react-bootstrap";

import { LightStyledFormControl } from "views/components/BuildModeSidebar/SharedControls/Generic";

const StyledTable = styled(Table)`
  background-color: #30333c !important;

  > tbody > tr > td > input {
    width: 90px !important;
    background-color: #494c55;
  }

  &.table > thead > tr > th {
    border-bottom: 0px;
  }
`;

const YellowHeading = styled.h4`
  color: ${(props) => (props.disabled ? "#7e6d43" : "#ffcf33")};
  display: inline-block;
  padding-right: 68px;
`;

export default function AddFrame({
  disabled,
  frameTypes = [],
  onAddSpliceFrame,
  setSpliceFrameTypeId,
  setSpliceHeight,
  spliceFrameTypeId,
  spliceHeight,
}) {
  const [showAddFrame, setShowAddFrame] = useState(false);
  // TODO: Ensure that a frame type is selected
  return (
    <>
      <YellowHeading disabled={disabled}>Add Frame</YellowHeading>
      <Button
        className="transparent"
        onClick={() => setShowAddFrame(!showAddFrame && !disabled)}
        style={{
          color: disabled ? "#919399" : "#FFF",
        }}
      >
        <FontAwesomeIcon icon={showAddFrame ? faChevronDown : faChevronRight} />
      </Button>
      {showAddFrame && (
        <StyledTable>
          <thead>
            <tr>
              <th>Height</th>
              <th>Type</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <LightStyledFormControl
                  disabled={disabled}
                  onChange={(e) =>
                    setSpliceHeight(parseInt(e.target.value, 10))
                  }
                  type="number"
                  value={spliceHeight}
                />
              </td>
              <td>
                {frameTypes.length ? (
                  <LightStyledFormControl
                    componentClass="select"
                    disabled={disabled}
                    onChange={(e) => setSpliceFrameTypeId(e.target.value)}
                    placeholder="Bay Type"
                    value={spliceFrameTypeId ?? ""}
                  >
                    <option disabled value="">
                      Please select
                    </option>
                    {frameTypes.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </LightStyledFormControl>
                ) : (
                  <span>No frame types.</span>
                )}
              </td>
              <td>
                <Button
                  className="action-button"
                  disabled={disabled || !spliceHeight || !spliceFrameTypeId}
                  onClick={() =>
                    onAddSpliceFrame({
                      height: spliceHeight,
                      FrameTypeId: spliceFrameTypeId,
                    })
                  }
                >
                  Add Splice
                </Button>
              </td>
            </tr>
          </tbody>
        </StyledTable>
      )}
    </>
  );
}

AddFrame.propTypes = {
  disabled: PropTypes.bool,
  onAddSpliceFrame: PropTypes.func.isRequired,
  frameTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  spliceHeight: PropTypes.number,
  setSpliceHeight: PropTypes.func.isRequired,
  setSpliceFrameTypeId: PropTypes.func.isRequired,
  spliceFrameTypeId: PropTypes.string,
};
