import PropTypes from 'prop-types';
import { FormControl, FormGroup, Col, Row, ControlLabel } from 'react-bootstrap';

export default function ControlSites({
  site,
  siteOptions = [],
  siteId,
  scheduleId,
  clientId,
  disabled,
  selectSite,
}) {
  return (
    <FormGroup controlId="formSite">
      <Row sm={12}>
        <Col sm={12}>
          <Col sm={12}>
            <ControlLabel>Site</ControlLabel>
          </Col>
          <Col sm={12}>
            {site && <FormControl disabled value={site.name} />}
            {siteOptions && siteOptions.length === 0 && !site && (
              <FormControl componentClass="select" disabled>
                <option>No Sites</option>
              </FormControl>
            )}
            {siteOptions && siteOptions.length > 0 && !site && (
              <FormControl
                componentClass="select"
                disabled={scheduleId || !clientId || disabled}
                onChange={(e) => selectSite(e.target.value)}
                value={siteId}
              >
                {((clientId && !siteId) || !clientId) && <option value="">Select Site...</option>}
                {siteOptions &&
                  siteOptions.map((curSite) => (
                    <option
                      disabled={
                        !curSite.warehouses ||
                        !curSite.racks ||
                        !curSite.warehouses.length ||
                        !curSite.racks.length
                      }
                      key={curSite.id}
                      value={curSite.id}
                    >
                      {`${curSite.name} ${
                        curSite.warehouses && curSite.warehouses.length ? '' : '(No Warehouses)'
                      } ${curSite.racks && curSite.racks.length ? '' : '(No Racks)'}`}
                    </option>
                  ))}
              </FormControl>
            )}
          </Col>
        </Col>
      </Row>
    </FormGroup>
  );
}

ControlSites.propTypes = {
  clientId: PropTypes.string,
  disabled: PropTypes.bool,
  scheduleId: PropTypes.string,
  selectSite: PropTypes.func,
  site: PropTypes.shape({
    name: PropTypes.string,
  }),
  siteId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  siteOptions: PropTypes.array,
};
