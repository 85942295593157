import styled from "@emotion/styled";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button } from "react-bootstrap";

function DropDownButton(props) {
  const { buttons } = props;

  const [showOptions, setShowOptions] = useState(false);

  const DropDownButtonDiv = styled.div`
    margin-right: 10px;
    .btn {
      background-color: #00000000;
      float: right;
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0px !important;
    }
  `;

  const DropDownButtonOptions = styled.div`
    z-index: 9999;
    position: absolute;
    background-color: #fff;
    margin-top: 25px;
    margin-right: 15px;
    color: #666666;
    width: 160px;
    right: 20px;
    border: 1px #666666 solid;
  `;

  const DropDownButtonOption = styled.div`
    padding: 10px;
    &:hover {
      background-color: #d9dfee;
    }
    cursor: pointer;
  `;

  const DropDownBackground = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9998;
  `;

  return (
    <DropDownButtonDiv>
      <Button onClick={() => setShowOptions(!showOptions)}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </Button>
      {showOptions && (
        <>
          <DropDownButtonOptions>
            {buttons.map((button) => (
              <DropDownButtonOption
                key={button.title}
                onClick={async () => {
                  await button.onClick();
                  setShowOptions(false);
                }}
              >
                {button.title}
              </DropDownButtonOption>
            ))}
          </DropDownButtonOptions>
          <DropDownBackground onClick={() => setShowOptions(false)} />
        </>
      )}
    </DropDownButtonDiv>
  );
}

DropDownButton.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

export default DropDownButton;
