import moment from "moment";
import PropTypes from "prop-types";
import { FormGroup, Col, Row, ControlLabel } from "react-bootstrap";
import DatePicker from "react-datepicker";

const isWeekday = (date) => {
  const day = moment(date).day();
  return day !== 0 && day !== 6;
};

/**
 * Displays a Start Date and a Due Date. Both are controlled. Start Date allows
 * input which is handled in the wrapper form which calculates the due date
 * based on the interval.
 */
export default function ControlDates({
  startAt,
  onChange,
  selectedDue,
  weekdayOnly,
}) {
  return (
    <FormGroup controlId="formStartAt">
      <Row sm={12}>
        <Col sm={6}>
          <Col sm={12}>
            <ControlLabel>Start Date</ControlLabel>
          </Col>
          <Col sm={12}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              filterDate={weekdayOnly ? isWeekday : () => true}
              minDate={moment().startOf("day").toDate()}
              onChange={onChange}
              selected={moment(startAt).toDate()}
            />
          </Col>
        </Col>
        <Col sm={6}>
          <Col sm={12}>
            <ControlLabel>Due Date</ControlLabel>
          </Col>
          <Col sm={12}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              disabled
              selected={selectedDue}
            />
          </Col>
        </Col>
      </Row>
    </FormGroup>
  );
}

ControlDates.defaultProps = {
  startAt: moment().startOf("day").toDate(),
  weekdayOnly: false,
};

ControlDates.propTypes = {
  startAt: PropTypes.instanceOf(Date),
  /** Handler for startAt date change */
  onChange: PropTypes.func.isRequired,
  selectedDue: PropTypes.instanceOf(Date),
  /** Disable weekends from being selected */
  weekdayOnly: PropTypes.bool,
};
