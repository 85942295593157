/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import moment from "moment";
import PropTypes from "prop-types";

import { PRIMARY_DATE_FORMAT } from "libs/constants";

const Container = styled.div`
  position: relative;
  background-color: #feeeee;
  color: #e04d4e;
  border-radius: 25px;
  padding: 5px 5px;
  border: 1px #fbdfe0 solid;
  width: 75px;
  font-weight: 900;
  text-align: center;
`;

export default function ActionOverdue({ action }) {
  if (action.signedOffAt) {
    return moment(action.signedOffAt).format(PRIMARY_DATE_FORMAT);
  }
  if (
    !action.signedOffAt &&
    (moment(action.fromDueAt).isAfter() || moment(action.toDueAt).isAfter())
  ) {
    return "-";
  }
  if (!action.fromDueAt && !action.toDueAt) {
    return null;
  }
  return <Container>Overdue</Container>;
}

ActionOverdue.propTypes = {
  action: PropTypes.shape({
    signedOffAt: PropTypes.string,
    fromDueAt: PropTypes.string,
    toDueAt: PropTypes.string,
  }),
};
