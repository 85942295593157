import { faPencilRuler } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

import { useFilters } from "hooks/useFilter";

import FilterOptionsAndChips from "views/components/FilterOptionsAndChips";

import { ControlsWrapper } from "./styles";
import "views/styles/Home.css";

export default function Controls() {
  const { siteId } = useFilters();
  return (
    <ControlsWrapper>
      <FilterOptionsAndChips hideFilterOptions={siteId} />
      <Button disabled={!siteId} href={`/blueprint/${siteId}`}>
        <FontAwesomeIcon icon={faPencilRuler} style={{ marginRight: 10 }} />
        Blueprint
      </Button>
    </ControlsWrapper>
  );
}
