import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { useNewBayName } from "hooks/templates";
import {
  addBayTemplate,
  deleteBayTemplate,
  editBayTemplate,
  getBayTemplate,
} from "libs/api";
import Loading from "views/components/Loading";

import { EDITOR_MODES, getTextForMode } from "../constants";
import Header from "../SharedControls/Header";
import Notes from "../SharedControls/Notes";
import { SidebarBody, SidebarEditorPanel } from "../SharedControls/styles";

import BeamsSection from "./BeamsSection";
import DetailsSection from "./DetailsSection";
import Footer from "./Footer";

const defaultFields = {
  notes: null,
  updatedAt: null,
  deletedAt: null,
  width: 0,
  pitch: 0,
  firstPitch: 0,
  depth: null,
  beamTemplates: [],
};

export default function BayEditor({ SiteId, onHide, mode, bayTemplateId }) {
  const [bayTemplate, setBayTemplate] = useState({
    ...defaultFields,
    SiteId,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState(null);
  const { name: newBayName } = useNewBayName(SiteId);

  // If this is a copy or edit, load the bay template into our state
  // TODO: Error handle a failed load
  useEffect(() => {
    async function loadEditBay() {
      setBayTemplate(null);
      const bt = await getBayTemplate(bayTemplateId);
      setBayTemplate(bt);
      setIsLoading(false);
    }

    async function loadCopyBay() {
      setBayTemplate(null);
      try {
        const {
          // eslint-disable-next-line no-unused-vars
          id,
          name: originalName,
          ...bt
        } = await getBayTemplate(bayTemplateId);
        const name = `Copy of ${originalName}`;
        setBayTemplate({ ...bt, name });
        setIsLoading(false);
      } catch (err) {
        setErrorMessage(err.message);
        setIsLoading(false);
      }
    }

    if (mode === EDITOR_MODES.copy) {
      setIsLoading(true);
      loadCopyBay();
    } else if (mode === EDITOR_MODES.edit) {
      setIsLoading(true);
      loadEditBay();
    }
  }, [bayTemplateId, mode, SiteId]);

  if (isLoading || !bayTemplate) {
    return <Loading darkMode height="calc(100vh - 50px)" />;
  }

  const beams =
    bayTemplate?.beamTemplates.filter((template) => !template.deleted) ?? [];

  return (
    <SidebarEditorPanel>
      <Header onHide={onHide} title={`${getTextForMode(mode)} Bay`} />
      <SidebarBody>
        <DetailsSection
          SiteId={SiteId}
          bayTemplate={{
            ...bayTemplate,
            name: mode === EDITOR_MODES.new ? newBayName : bayTemplate?.name,
          }}
          isCopyBay={false}
          updateBayTemplate={(fields) =>
            setBayTemplate({ ...bayTemplate, ...fields })
          }
        />
        <BeamsSection
          bayTemplate={bayTemplate}
          setBayTemplate={setBayTemplate}
          setErrorMessage={setErrorMessage}
        />
        <Notes
          notes={bayTemplate.notes}
          setNotes={(notes) => setBayTemplate({ ...bayTemplate, notes })}
        />
      </SidebarBody>
      <Footer
        disableSave={!beams.length || !bayTemplate.width}
        errorMsg={errorMsg}
        isNew={mode === EDITOR_MODES.new || mode === EDITOR_MODES.copy}
        onDelete={async () => {
          if (mode === EDITOR_MODES.edit) {
            try {
              await deleteBayTemplate(bayTemplate.id);
              onHide();
            } catch (e) {
              setErrorMessage(e.message);
            }
          } else {
            onHide();
          }
        }}
        onError={setErrorMessage}
        onSave={async () => {
          // Only strip deleted if we're creating a new bay, otherwise send to
          // the server so it can be deleted
          const beamTemplates =
            mode === EDITOR_MODES.new
              ? bayTemplate.beamTemplates.filter(
                  (curTemplate) => !curTemplate.deleted,
                )
              : bayTemplate.beamTemplates;

          const template = {
            ...bayTemplate,
            name: EDITOR_MODES.new ? newBayName : bayTemplate.name,
            beamTemplates,
          };

          let updatedErrorMsg = errorMsg;
          if (template.width < 1) {
            updatedErrorMsg += "Bay Type Width must be more than 1mm";
          }
          if (errorMsg) {
            updatedErrorMsg = `Error: ${updatedErrorMsg}`;
            setErrorMessage(updatedErrorMsg);
          } else {
            try {
              if (mode === EDITOR_MODES.edit) {
                await editBayTemplate(template);
              } else {
                // new or copy
                await addBayTemplate(template);
              }
              onHide();
            } catch (e) {
              setErrorMessage(e.message);
            }
          }
        }}
      />
    </SidebarEditorPanel>
  );
}

BayEditor.propTypes = {
  onHide: PropTypes.func.isRequired,
  SiteId: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(Object.values(EDITOR_MODES)).isRequired,
  bayTemplateId: PropTypes.string,
};
