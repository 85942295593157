import styled from "@emotion/styled";
import moment from "moment";
import PropTypes from "prop-types";
import { Button, Col, Row, Image } from "react-bootstrap";

import { useClient } from "hooks/client";
import { useInspection } from "hooks/inspections";
import { PRIMARY_DATE_FORMAT } from "libs/constants";

import FlatModal, {
  ModalControls,
  ModalPaddedBody,
} from "views/components/Modals/FlatModal";
import WarehouseProgress from "views/components/WarehouseProgress";

const ClientLogo = styled(Image)`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 10px;
`;

const DetailsHeader = styled.div`
  padding: 15px;
  background-color: #23262d;
  color: white;

  .date {
    color: #ffcf33;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .name {
    font-size: 1.4em;
    margin-bottom: 5px;
  }
`;
/**
 * The details modal shows the details of an inspection, including the Warehouse
 * complete percentages, and each area complete percentage.
 */
export default function DetailsModal({
  ClientId,
  InspectionId,
  onClickComplete,
  onHide,
}) {
  const { client } = useClient(ClientId);
  const { inspection, isLoading } = useInspection(InspectionId);

  const allClassifications = !isLoading
    ? [
        ...(inspection?.classifications ?? []),
        ...(inspection?.confirmedClassifications ?? []),
      ]
    : [];

  /**
   * We filter the warehouses, if it's an isolated inspection, just show the
   * touched warehouses. If it's a general inspection, show all warehouses.
   *
   * Note, we use the WarehouseId or id, so that this works for both archival
   * and active inspections
   */
  const filteredWarehouses = inspection?.isolated
    ? inspection?.warehouses.filter((w) => {
        const added = allClassifications.filter(
          (c) => c.WarehouseId === (w.WarehouseId || w.id),
        );
        return inspection.isolated && added.length > 0;
      })
    : inspection?.warehouses ?? [];

  if (!InspectionId) return null;

  const warehouseProgress = filteredWarehouses.map((w) => ({
    id: w.WarehouseId ?? w.id,
    name: w.name,
    completePercentage: inspection.completedAt ? 100 : w.completePercentage,
    progressOverride: inspection.isolated
      ? `Added damage: ${
          allClassifications.filter(
            (c) => c.WarehouseId === (w.WarehouseId ?? w.id),
          ).length ?? 0
        }`
      : null,
    areas: w.areas.map((a) => ({
      id: a.AreaId ?? a.id,
      name: a.name,
      completePercentage: inspection.completedAt ? 100 : a.completePercentage,
      progressOverride: inspection.isolated
        ? `Added damage: ${
            allClassifications.filter((c) => c.AreaId === (a.AreaId ?? a.id))
              .length ?? 0
          }`
        : null,
    })),
  }));

  return (
    <FlatModal
      closeButton
      onHide={onHide}
      show
      title={
        inspection?.isCompletable || inspection?.isPartialCompletable
          ? `Complete ${
              inspection?.isPartialCompletable && !inspection?.isCompletable
                ? "Partial "
                : ""
            }Inspection`
          : "Ongoing Inspection"
      }
      width={500}
    >
      <DetailsHeader>
        <Row>
          <Col sm={2}>
            <ClientLogo src={client?.logoUrl ?? "/img/defaults/logo.png"} />
          </Col>
          <Col sm={10}>
            <div>
              <div className="date">
                {moment(inspection?.createdAt).format(PRIMARY_DATE_FORMAT)}
              </div>
              <div className="name">{client?.name}</div>
              <div>{inspection?.name}</div>
            </div>
          </Col>
        </Row>
      </DetailsHeader>
      <ModalPaddedBody>
        <WarehouseProgress warehouses={warehouseProgress} />
      </ModalPaddedBody>
      {!isLoading && !inspection?.completedAt ? (
        <ModalControls>
          {!isLoading && !inspection?.isCompletable ? (
            <Button onClick={onHide}>Continue Inspection</Button>
          ) : null}
          <Button
            disabled={
              !inspection?.isCompletable && !inspection?.isPartialCompletable
            }
            onClick={onClickComplete}
          >
            Complete{" "}
            {inspection?.isPartialCompletable && !inspection?.isCompletable
              ? "Partial "
              : ""}
            Inspection
          </Button>
        </ModalControls>
      ) : null}
    </FlatModal>
  );
}

DetailsModal.propTypes = {
  ClientId: PropTypes.string.isRequired,
  InspectionId: PropTypes.string.isRequired,
  onClickComplete: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
