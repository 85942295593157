import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState } from "react";

import { useFrameTypes } from "hooks/types";

import { YellowHeading } from "../../SharedControls/Generic";

import AddFrame from "./AddFrame";

import HasSacLeg from "./HasSacLeg";
import SacLegSleeve from "./SacLegSleeve";
import Splices from "./Splices";
import RearFrame from "./RearFrame";

const ItemsSection = styled.div`
  background-color: #30333c;
  padding: 5px 20px;
  line-height: 34px;
`;

export default function SectionFrameComponents({
  disabled,
  frameTemplate,
  updateFrameTemplate,
}) {
  const { frameTypes } = useFrameTypes(frameTemplate.ManufacturerId, {
    SeriesId: frameTemplate.SeriesId || frameTemplate.type?.SeriesId,
  });
  const [spliceHeight, setSpliceHeight] = useState(0);
  const [rearHeight, setRearHeight] = useState(0);
  const [spliceFrameTypeId, setSpliceFrameTypeId] = useState(null);
  const hasRearFrames = frameTemplate?.splicedFrames?.filter(
    (s) => s.isRear,
  )?.length;
  return (
    <ItemsSection>
      <YellowHeading disabled={disabled}>Frames</YellowHeading>
      <HasSacLeg
        disabled={disabled}
        isSelected={frameTemplate.sacLeg}
        onChange={(value) => {
          updateFrameTemplate({
            sacLeg: value === "1" || value === 1,
            sleeve: false,
            sacLegHeight: 0,
            sacSpec: null,
            sleeveHeight: 0,
            sleeveSpacer: 0,
          });
        }}
      />
      {frameTemplate.sacLeg && (
        <SacLegSleeve
          sacLegHeight={frameTemplate.sacLegHeight}
          sacSpec={frameTemplate.sacSpec}
          setSacLegHeight={(val) => updateFrameTemplate({ sacLegHeight: val })}
          setSacSpec={(val) => updateFrameTemplate({ sacSpec: val })}
          setSleeve={(val) => {
            updateFrameTemplate({
              sleeve: val === "1" || val === 1,
              sleeveHeight: 0,
              sleeveSpacer: 0,
            });
          }}
          setSleeveHeight={(val) => updateFrameTemplate({ sleeveHeight: val })}
          setSleeveSpacer={(val) => updateFrameTemplate({ sleeveSpacer: val })}
          sleeve={frameTemplate.sleeve}
          sleeveHeight={frameTemplate.sleeveHeight}
          sleeveSpacer={frameTemplate.sleeveSpacer}
        />
      )}
      <Splices
        disabled={disabled}
        frameTemplate={frameTemplate}
        // Don't pass the frame types if we haven't got a manufacturer
        frameTypes={frameTemplate.ManufacturerId ? frameTypes : []}
        updateFrameTemplate={updateFrameTemplate}
      />
      <AddFrame
        disabled={disabled}
        frameTypes={frameTypes}
        onAddSpliceFrame={(newSplicedFrame) =>
          updateFrameTemplate({
            splicedFrames: [...frameTemplate.splicedFrames, newSplicedFrame],
          })
        }
        setSpliceFrameTypeId={setSpliceFrameTypeId}
        setSpliceHeight={setSpliceHeight}
        spliceFrameTypeId={spliceFrameTypeId}
        spliceHeight={spliceHeight}
      />
      <RearFrame
        disabled={disabled}
        frameTypes={frameTypes}
        onAddRearFrame={(newRearFrame) =>
          updateFrameTemplate({
            splicedFrames: [...frameTemplate.splicedFrames, newRearFrame],
          })
        }
        setSpliceFrameTypeId={setSpliceFrameTypeId}
        setRearHeight={setRearHeight}
        spliceFrameTypeId={spliceFrameTypeId}
        rearHeight={rearHeight}
        frontSacLeg={frameTemplate.sacLeg}
        hasRearFrames={hasRearFrames}
      />
      {hasRearFrames ? (
        <>
          <HasSacLeg
            disabled={disabled}
            isSelected={frameTemplate.sacLegRear}
            onChange={(value) => {
              updateFrameTemplate({
                sacLegRear: value === "1" || value === 1,
                sleeveRear: false,
                sacLegHeightRear: 0,
                sacSpecRear: null,
                sleeveHeightRear: 0,
                sleeveSpacerRear: 0,
              });
            }}
          />
          {frameTemplate.sacLegRear && (
            <SacLegSleeve
              sacLegHeight={frameTemplate.sacLegHeightRear}
              sacSpec={frameTemplate.sacSpecRear}
              setSacLegHeight={(val) =>
                updateFrameTemplate({ sacLegHeightRear: val })
              }
              setSacSpec={(val) => updateFrameTemplate({ sacSpecRear: val })}
              setSleeve={(val) => {
                updateFrameTemplate({
                  sleeveRear: val === "1" || val === 1,
                  sleeveHeightRear: 0,
                  sleeveSpacerRear: 0,
                });
              }}
              setSleeveHeight={(val) =>
                updateFrameTemplate({ sleeveHeightRear: val })
              }
              setSleeveSpacer={(val) =>
                updateFrameTemplate({ sleeveSpacerRear: val })
              }
              sleeve={frameTemplate.sleeveRear}
              sleeveHeight={frameTemplate.sleeveHeightRear}
              sleeveSpacer={frameTemplate.sleeveSpacerRear}
            />
          )}
          <Splices
            disabled={disabled}
            frameTemplate={frameTemplate}
            // Don't pass the frame types if we haven't got a manufacturer
            frameTypes={frameTemplate.ManufacturerId ? frameTypes : []}
            updateFrameTemplate={updateFrameTemplate}
            isRear
          />
        </>
      ) : null}
    </ItemsSection>
  );
}

SectionFrameComponents.propTypes = {
  disabled: PropTypes.bool,
  frameTemplate: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    splicedFrames: PropTypes.array,
    notes: PropTypes.string,
    ManufacturerId: PropTypes.string,
    ColourId: PropTypes.string,
    BasePlateId: PropTypes.string,
    sacLegHeight: PropTypes.number,
    sacLeg: PropTypes.bool,
    sleeve: PropTypes.bool,
    sacSpec: PropTypes.string,
    sleeveHeight: PropTypes.number,
    sleeveSpacer: PropTypes.number,
    sleeveHeightRear: PropTypes.number,
    sleeveSpacerRear: PropTypes.number,
    sleeveRear: PropTypes.bool,
    sacLegHeightRear: PropTypes.number,
    sacLegRear: PropTypes.bool,
    sacSpecRear: PropTypes.string,
    colourOther: PropTypes.string,
    depth: PropTypes.number,
    rearFrames: PropTypes.arrayOf(
      PropTypes.shape({
        height: PropTypes.number,
        FrameTypeId: PropTypes.string,
      }),
    ),
    SeriesId: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    type: PropTypes.object,
  }).isRequired,
  updateFrameTemplate: PropTypes.func.isRequired,
};
