/* eslint-disable react/prop-types */
import moment from "moment";
import { Col } from "react-bootstrap";

import { SHORT_DATE_FORMAT } from "libs/constants";

function Details({ classification }) {
  return (
    <>
      <Col sm={12}>
        <h5>{`Details ${classification.misc ? "(Misc. Damage)" : ""}`}</h5>
      </Col>
      <Col sm={6}>
        {classification.risk !== "None" && (
          <>
            {`Defect: ${
              classification.otherDefect ||
              classification.defect ||
              classification.miscDefect ||
              "-"
            }`}
            <br />
            {`Action: ${
              classification.otherAction ||
              classification.action ||
              classification.miscAction ||
              "-"
            }`}
            <br />
          </>
        )}
        {`Date Added: ${moment(classification.createdAt).format(
          SHORT_DATE_FORMAT,
        )}`}
        <br />
        {`Added By: ${
          classification.creator ? classification.creator.name : "-"
        }`}
      </Col>
      <Col sm={6}>
        {`Manufacturer: ${
          classification.manufacturer ? classification.manufacturer.name : ""
        }, ${classification.series ? classification.series.name : ""}`}
        <br />
        {`Component Spec: ${
          classification.specification ||
          classification.componentName ||
          classification.componentTitle ||
          classification.component
        }`}
      </Col>
    </>
  );
}

export default Details;
