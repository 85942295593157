/** @jsxImportSource @emotion/react */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useInspectionActions } from "hooks/actions";
import { useInspection, useInspectionProgress } from "hooks/inspections";

import PageWrapper from "views/components/common/PageWrapper";
import Loading from "views/components/Loading";
import FixedProgressBar from "views/components/ProgressBar";
import RackSelector from "views/components/RackSelector";
import ButtonCompleteAction from "views/pages/Action/components/button-complete";
import BlueprintButton from "views/pages/Inspection/components/buttons/button-blueprint";
import Info from "views/pages/Inspection/components/Info";
import InspectionAreaSelect from "views/pages/Inspection/components/InspectionAreaSelect";
import InspectionWarehouseSelect from "views/pages/Inspection/components/InspectionWarehouseSelect";
import Section from "views/pages/Inspection/components/section";
import useInspectionView from "views/pages/Inspection/inspectionViewReducer";
import { FlexJustifyEnd, Wrapper } from "views/pages/Inspection/styles";

import ActionLine from "./components/action-line";

function convertInspectionActionsWarehouseToInspectionWarehouseStructure(
  warehouses,
) {
  return warehouses.map((warehouse) => ({
    ...warehouse,
    racks: warehouse.areas.map((a) => a.racks).flat(),
  }));
}

/**
 * An action is a "view" into a classification.
 *
 * Notes:
 *
 * - There are two different usages of "unresolve", one is a final status of
 *  "Unresolved" and the other is a handler to undo a resolve or unresolve 🤷‍♂️
 *
 * The areas, and therefore warehouses that we show are only the ones that have
 * actions associated with them. Areas without actions are hidden.
 *
 */
export default function ActionPage() {
  const { ActionId } = useParams();
  const { inspection, isLoading: isLoadingInspection } =
    useInspection(ActionId);
  const { warehouses, isLoading: isLoadingActions } = useInspectionActions(
    ActionId,
    {
      showGreen: false,
    },
  );

  // Fake inspection to pass to the inspection view
  const fakeInspection = useMemo(
    () => ({
      warehouses:
        convertInspectionActionsWarehouseToInspectionWarehouseStructure(
          warehouses,
        ),
    }),
    [warehouses],
  );
  const { warehouseId, areaId, rackId, setWarehouseId, setAreaId, setRackId, selectArea } = useInspectionView(fakeInspection); // prettier-ignore

  const { progress, isLoading: isLoadingProgress } =
    useInspectionProgress(ActionId);
  const warehouseProgress = progress?.warehouses.find(
    (w) => w.id === warehouseId,
  );
  const areasProgress = progress?.warehouses.map((w) => w.areas).flat() ?? [];
  const racksProgress = areasProgress.map((a) => a.racks).flat();
  const rackProgress = racksProgress?.find((r) => r.id === rackId);
  const isLoading =
    isLoadingInspection || isLoadingActions || isLoadingProgress;

  const areasInWarehouse =
    warehouses.find((w) => w.id === warehouseId)?.areas ?? [];
  const racksInWarehouseWithActions = areasInWarehouse
    .map((a) => a.racks?.filter((r) => r.actions?.length > 0))
    // Add progress from racks progress
    .map((racks) =>
      racks.map((r) => ({
        ...r,
        completePercentage:
          racksProgress?.find((rack) => rack.id === r.id)?.completePercentage ??
          0,
      })),
    )
    .flat();
  const rack = racksInWarehouseWithActions?.find((r) => r.id === rackId);
  const areasWithProgress = areasInWarehouse.map((a) => ({
    ...a,
    completePercentage:
      areasProgress.find((p) => p.id === a.id)?.completePercentage ?? 0,
  }));

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageWrapper>
      <Wrapper>
        <Row>
          <Col sm={8}>
            <Info
              reference={inspection?.reference}
              siteId={inspection?.SiteId}
            />
            <InspectionWarehouseSelect
              onChange={setWarehouseId}
              selectedWarehouseId={warehouseId}
              warehouses={warehouses}
            />
            <InspectionAreaSelect
              areas={areasInWarehouse}
              onChange={(id) =>
                id === "All Areas" ? setAreaId(null) : setAreaId(id)
              }
              selectedAreaId={areaId}
            />
          </Col>
          <Col sm={4}>
            <FlexJustifyEnd>
              <BlueprintButton
                inspectionId={ActionId}
                isAction
                siteId={inspection?.SiteId}
                warehouseId={warehouseId}
              />
              <ButtonCompleteAction InspectionId={ActionId} />
            </FlexJustifyEnd>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <RackSelector
              areas={areasWithProgress}
              isCompleted={false}
              onClickArea={selectArea}
              onClickRack={setRackId}
              racks={racksInWarehouseWithActions}
              selectedAreaId={areaId}
              selectedRackId={rackId}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Section
              icon={<FontAwesomeIcon icon={faCheck} />}
              title="Repairs"
              total={
                isLoadingProgress
                  ? "..."
                  : `
                ${
                  rackProgress?.actionsResolved +
                  (inspection?.status === "Unfinished"
                    ? 0
                    : rackProgress?.actionsCantResolve)
                }/${rackProgress?.actionsTotal}`
              }
            >
              {rack?.actions.map((a) => (
                <ActionLine ActionId={ActionId} classification={a} key={a.id} />
              ))}
            </Section>
          </Col>
        </Row>
      </Wrapper>
      <FixedProgressBar percentage={warehouseProgress?.completePercentage} />
    </PageWrapper>
  );
}
