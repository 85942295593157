import {
  faCheck,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { MiniFilterTab } from "views/components/Tables/TableControls";
import VerticalDivider from "views/components/Tables/VerticalDivider";

export default function MiniFilter({ selected = "my", toggle }) {
  return (
    <div>
      <MiniFilterTab onClick={toggle} selected={selected === "my"}>
        {selected === "my" ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
        )}
        My Inspections
      </MiniFilterTab>
      <MiniFilterTab onClick={toggle} selected={selected === "all"}>
        {selected === "all" ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
        )}
        All Inspections
      </MiniFilterTab>
      <VerticalDivider />
    </div>
  );
}

MiniFilter.propTypes = {
  selected: PropTypes.oneOf(["all", "my"]).isRequired,
  toggle: PropTypes.func.isRequired,
};
