/** @jsxImportSource @emotion/react */
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";

import { useActions } from "hooks/actions";
import { useCurrentUser } from "hooks/user";

import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import ActionAssigner from "views/components/Modals/ActionAssigner";
import FlatModal from "views/components/Modals/FlatModal";
import TableHeader from "views/components/Tables/TableHeader";

import { Table } from "../../Tables/styles";
import { renderPadding, getActionsLink } from "../helpers";

import ActionRow, { PaddingRow } from "./ActionRow";

export default function Actions({
  siteId,
  ClientId,
  InspectorId,
  filters: additionalFilters = {},
}) {
  const { actions, total, isLoading, mutate } = useActions({
    SiteId: siteId,
    ClientId,
    InspectorId,
    amount: 4,
    ...additionalFilters,
  });
  const { user } = useCurrentUser();
  const [selectedAction, setSelectedAction] = useState(null);

  const paddingAmount = isLoading ? 0 : 4 - actions.length;
  const showPadding = !isLoading && paddingAmount !== 4; // Loaded, and min 1 row
  const showEmptyTableIndicator =
    isLoading || (!isLoading && actions.length === 0); // Loading or no rows

  return (
    <>
      <TableHeader
        left="Actions"
        right={
          <Link to={getActionsLink(ClientId, siteId, true)}>
            View all {total} actions <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        }
      />
      <Table>
        <thead>
          <tr>
            {!ClientId ? <th>Client</th> : null}
            {!siteId ? <th>Site</th> : null}
            <th>Ref</th>
            <th>Assignee</th>
            <th>Status</th>
            <th>Due Date</th>
            <th>Completed</th>
            <th className="hover-buttons">Controls</th>
          </tr>
        </thead>
        <tbody>
          {actions.map((action) => (
            <ActionRow
              action={action}
              key={action.id}
              onRefresh={mutate}
              setSelectedAction={setSelectedAction}
              showClient={!ClientId}
              showSite={!siteId}
            />
          ))}
          {showPadding
            ? renderPadding(
                () => <PaddingRow showClient={!ClientId} showSite={!siteId} />,
                paddingAmount,
              )
            : null}
        </tbody>
      </Table>
      {showEmptyTableIndicator && (
        <EmptyTableIndicator isLoading={isLoading} type="Actions" />
      )}
      {selectedAction && (
        <FlatModal
          closeButton
          onHide={() => setSelectedAction(null)}
          show
          title="Assign Action"
          width={500}
        >
          <ActionAssigner
            getData={mutate}
            inspection={selectedAction}
            onHide={() => setSelectedAction(null)}
            user={user}
          />
        </FlatModal>
      )}
    </>
  );
}

Actions.propTypes = {
  siteId: PropTypes.string,
  ClientId: PropTypes.string,
  InspectorId: PropTypes.string,
  filters: PropTypes.shape({}),
};
