import styled from "@emotion/styled";
import { Panel } from "react-bootstrap";

const EditorPanel = styled(Panel)`
  font-size: 12px;
  .panel-heading {
    background-color: #494c55;
    color: #ffffff;
  }
  .panel-body {
    color: #494c55;
    background-color: #ffffff;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 9px;
  }
  .form-control {
    background-color: #f2f2f4;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    width: 100%;
    margin-bottom: 15px;
    color: #494c55;
  }
`;

export default EditorPanel;
