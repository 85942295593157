import * as Sentry from '@sentry/browser';

import { EDITORS, EDITOR_MODES, ACTION_TYPES } from './constants';

export function getType(currentSidebarTab) {
  switch (currentSidebarTab) {
    case 1:
      return EDITORS.frames;
    case 2:
      return EDITORS.bays;
    case 4:
      return EDITORS.areas;
    case 3:
    default:
      return EDITORS.racks;
  }
}

/**
 * Manage the state of the editors in the build mode sidebar
 */
export function editorsReducer(state = {}, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.edit:
      // Validate the editor mode. If invalid, return a reset state
      if (!action.mode || !Object.values(EDITOR_MODES).includes(action.mode)) {
        Sentry.captureException(new Error(`Invalid editor mode: ${action.mode}`));
        return state;
      }
      // If it's a copy or edit and we don't have an ID, it's invalid
      if ((action.mode === EDITOR_MODES.copy || action.mode === EDITOR_MODES.edit) && !action.id) {
        Sentry.captureException(new Error(`Invalid editor mode: ${action.mode} with no ID`));
        return state;
      }
      return { editor: action.editor, id: action.id, mode: action.mode };
    case ACTION_TYPES.reset:
      return {};
    default:
      Sentry.captureException(new Error(`Invalid action type: ${action.type}`));
      return {};
  }
}
