import moment from 'moment';

function replaceBegEnd(str) {
  return str.replace('Beg', '0').replace('End', '99999999');
}

export function sortClassifications(a, b, reverse = false) {
  // Location can either be a number or a string (!)
  const aLocations =
    typeof a.location === 'string' ? a.location.split('/').map(replaceBegEnd) : [a.location];
  const bLocations =
    typeof b.location === 'string' ? b.location.split('/').map(replaceBegEnd) : [b.location];

  // Get the number we compare against. For beams we just have 1
  // entry, so if our comparitor is empty (i.e. idx 1) then grab
  // the first and only value
  const aComparator = parseInt(aLocations[1] ?? aLocations[0], 10);
  const bComparator = parseInt(bLocations[1] ?? bLocations[0], 10);

  // If one of a or b is a single location then sort it behind the other
  // e.g. 10 vs 9/10 or 10/End, sort 10 behind 9/10, but in front of 10/End
  if (aLocations.length === 1 && bLocations.length > 1) {
    // If the values are the same, a behind b
    if (aComparator === bComparator) {
      return reverse ? -1 : 1;
    }
  }
  if (bLocations.length === 1 && aLocations.length > 1) {
    // If the values are the same, b behind a
    if (aComparator === bComparator) {
      return reverse ? 1 : -1;
    }
  }

  // Otherwise, sort by the location as normal
  if (aComparator < bComparator) {
    return reverse ? 1 : -1;
  }
  if (aComparator > bComparator) {
    return reverse ? -1 : 1;
  }

  // If the locations are equal then sort by createdAt date
  const aCreated = moment(a.createdAt);
  const bCreated = moment(b.createdAt);

  if (aCreated.isBefore(bCreated)) {
    return reverse ? 1 : -1;
  }

  if (aCreated.isAfter(bCreated)) {
    return reverse ? 1 : -1;
  }

  return 0;
}
