import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import {
  AMBER_COLOUR,
  DEFAULT_COLOUR,
  GREEN_COLOUR,
  NONE_COLOUR,
  RED_COLOUR,
} from "libs/constants";

function getColor(RiskName) {
  switch (RiskName) {
    case "Green":
      return GREEN_COLOUR;
    case "Amber":
      return AMBER_COLOUR;
    case "Red":
      return RED_COLOUR;
    case "None":
      return NONE_COLOUR;
    default:
      return DEFAULT_COLOUR;
  }
}
export default function DamageCircle({ large, RiskName }) {
  return (
    <FontAwesomeIcon
      icon={faCircle}
      size={large ? "2xl" : "xl"}
      style={{ color: getColor(RiskName), padding: 3 }}
      title={RiskName}
    />
  );
}

DamageCircle.propTypes = {
  large: PropTypes.bool,
  RiskName: PropTypes.oneOf(["Green", "Amber", "Red", "None"]),
};
