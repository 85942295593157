/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";

function FormValidationText({ show, text }) {
  if (!show) {
    return null;
  }
  return (
    <div
      css={css`
        padding-bottom: 10px;
        margin-top: -10px;
        color: red;
      `}
    >
      {text}
    </div>
  );
}

FormValidationText.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
};

export default FormValidationText;
