import styled from "@emotion/styled";
import { faCalendar, faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faChartSimple,
  faBriefcase,
  faMagnifyingGlassPlus,
  faList,
  faGear,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import { useClientsToVerify } from "hooks/client";
import { useCurrentUser } from "hooks/user";

import UnviewedIndicator from "views/components/common/UnviewedIndicator";
import UserMissedInspectionsIndicator from "views/components/UserMissedInspectionsIndicator";

const StyledNavLink = styled(NavLink)`
  color: #ffffff;
  display: block;
  padding: 15px;
  text-decoration: none;
  border-left: 3px solid #30333c;

  &.active {
    border-left: 3px solid rgb(45, 114, 237);
    background-color: rgb(73, 76, 85) !important;
    color: rgb(255, 207, 51) !important;
    text-decoration: none;
  }

  &:hover {
    border-left: 3px solid rgb(45, 114, 237);
    color: #ffcf33;
    text-decoration: none;
  }

  svg {
    margin-right: 20px;
  }
`;

const UnviewedIndicatorWrapper = styled.div`
  display: inline;
  position: relative;
`;

export default function NavigationWidget() {
  const { clientsToVerify } = useClientsToVerify();
  const { user, isLoading, isError } = useCurrentUser();

  if (isLoading || isError) return null;

  const {
    organisations,
    organisationsAdmin,
    clientOrganisation,
    client,
    clientAdmin,
    siteClient,
    permissions,
    siteGroupClient,
    installerOrganisationClient,
  } = user;
  const isOrganisationUser = user && (!!organisations.length || !!clientOrganisation.length || !!organisationsAdmin.length); // prettier-ignore
  const isSingleSiteUser = user && !siteGroupClient.length && !client.length && !clientAdmin.length && !isOrganisationUser && siteClient.length === 1; // prettier-ignore
  const isClientUser = !isOrganisationUser && !isSingleSiteUser;
  const isInstallerUser =
    installerOrganisationClient?.length || user.userType === "InstallerAdmin";
  if (isInstallerUser) {
    return (
      <div>
        <StyledNavLink to="/actions">
          <FontAwesomeIcon fixedWidth icon={faList} size="2xl" />
          Actions
        </StyledNavLink>
        <StyledNavLink to="/users">
          <FontAwesomeIcon fixedWidth icon={faUser} size="2xl" />
          Users
        </StyledNavLink>
        <StyledNavLink to="/settings">
          <UnviewedIndicatorWrapper>
            <UnviewedIndicator
              amount={clientsToVerify && clientsToVerify.length}
              borderSize={2}
              maxAmount={9}
              size={20}
              small
            />
          </UnviewedIndicatorWrapper>
          <FontAwesomeIcon fixedWidth icon={faGear} size="2xl" />
          Settings
        </StyledNavLink>
      </div>
    );
  }
  return (
    <div>
      <StyledNavLink to="/">
        <FontAwesomeIcon fixedWidth icon={faChartSimple} size="2xl" />
        Dashboard
      </StyledNavLink>
      {isOrganisationUser && (
        <StyledNavLink to="/clients">
          <FontAwesomeIcon fixedWidth icon={faBriefcase} size="2xl" />
          Clients
        </StyledNavLink>
      )}
      {user && !user.loading && isClientUser && !isSingleSiteUser && (
        <StyledNavLink
          to={`/clients/${
            permissions.clientAdmin[0] ||
            permissions.client[0] ||
            (siteClient.length && siteClient[0].ClientId) ||
            (siteGroupClient.length && siteGroupClient[0].ClientId)
          }/overview`}
        >
          <FontAwesomeIcon fixedWidth icon={faBriefcase} size="2xl" />
          Client
        </StyledNavLink>
      )}
      {user && !user.loading && !isClientUser && isSingleSiteUser && (
        <StyledNavLink
          to={`/clients/sites/${user.permissions.site[0]}/overview`}
        >
          <FontAwesomeIcon fixedWidth icon={faLocationDot} size="2xl" />
          Site
        </StyledNavLink>
      )}
      <StyledNavLink to="/inspections">
        <FontAwesomeIcon fixedWidth icon={faMagnifyingGlassPlus} size="2xl" />
        Inspections
      </StyledNavLink>
      <StyledNavLink to="/schedules">
        <UnviewedIndicatorWrapper>
          <FontAwesomeIcon fixedWidth icon={faCalendar} size="2xl" />
          <UserMissedInspectionsIndicator />
        </UnviewedIndicatorWrapper>
        Schedules
      </StyledNavLink>
      <StyledNavLink to="/actions">
        <FontAwesomeIcon fixedWidth icon={faList} size="2xl" />
        Actions
      </StyledNavLink>
      <StyledNavLink to="/users">
        <FontAwesomeIcon fixedWidth icon={faUser} size="2xl" />
        Users
      </StyledNavLink>
      <StyledNavLink to="/settings">
        <UnviewedIndicatorWrapper>
          <FontAwesomeIcon fixedWidth icon={faGear} size="2xl" />
          <UnviewedIndicator
            amount={clientsToVerify && clientsToVerify.length}
            borderSize={2}
            maxAmount={9}
            size={20}
            small
          />
        </UnviewedIndicatorWrapper>
        Settings
      </StyledNavLink>
    </div>
  );
}
