/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from "./helpers";

export {
  assignUserToInspectionActions,
  getActionPDF,
  getActions,
  getActionShareURL,
  getActionStats,
  getInspectionActions,
  getInspectionProgress,
  resolveAction,
  unresolveAction,
} from "./action";

export { addArea, deleteArea, editArea, getAreas } from "./area";

export {
  addClassification,
  addClassificationFile,
  deleteClassification,
  deleteClassificationFile,
  editClassificationFileCaption,
  editClassificationNote,
  getAllClassificationComponents,
  getClassification,
  getClassificationFiles,
  getClassifySuggestion,
  getComponents,
  getComponentTypes,
  reclassifyClassification,
  unreclassifyClassification,
} from "./classify";

export {
  addClient,
  createSiteGroup,
  deleteClient,
  deleteSiteGroup,
  editClient,
  editSiteGroup,
  getClient,
  getClients,
  getClientsGroups,
  getClientsToVerify,
} from "./client";

export {
  completeAction,
  completeInspection,
  completeRack,
  confirmClassification,
  createInspection,
  deleteInspection,
  getDisplayClassifications,
  getInspection,
  getInspectionPDF,
  getInspectionReport,
  getInspections,
  getMaterials,
  getPreviousClassifications,
  getShareURL,
  toggleBookmarkInspection,
  toggleInspectionArchive,
  uncompleteRack,
  unconfirmClassification,
} from "./inspection";

export { getManufacturers, getSeries } from "./manufacturer";

export {
  getInstallerClients,
  getInstallerOrgsById,
  getOrganisation,
  getOrganisations,
  rejectVerifyClientOrganisation,
  verifyClientOrganisation,
} from "./organisation";

export { addRack, deleteRack, editRack, editRackArea, getRack } from "./rack";

export { getReport, editReport, completeReport } from "./report";

export {
  userRequestPassword,
  userRequestConfirmPassword,
  userCheckResetCode,
} from "./reset";

export {
  createSchedule,
  deleteSchedule,
  editSchedule,
  getMissedSchedules,
  getMySchedules,
  getSchedule,
  getSchedules,
  toggleStatusSchedule,
  unviewMissedInspection,
  viewMissedInspection,
} from "./schedule";

export {
  addSite,
  deleteSite,
  editSite,
  getSite,
  getSites,
  toggleSiteBuildMode,
  getLocationReport,
} from "./site";

export {
  addBayTemplate,
  addFrameTemplate,
  deleteBayTemplate,
  deleteFrameTemplate,
  editBayTemplate,
  editFrameTemplate,
  getBayDepths,
  getBayTemplate,
  getBayTemplates,
  getBayTemplatesDepths,
  getFrameDepths,
  getFrameTemplate,
  getFrameTemplates,
  getFrameTemplatesDepths,
  getNewBayName,
  getNewFrameName,
} from "./template";

export { getTypes } from "./type";

export {
  addUser,
  deleteUser,
  editUser,
  getPermission,
  getUser,
  getCurrentUser,
  getUsers,
  userEmailVerifyChange,
  userPasswordChange,
  userRequestEmailChange,
} from "./user";

export {
  addWarehouse,
  deleteWarehouse,
  editWarehouse,
  getWarehouse,
  getWarehouses,
} from "./warehouse";

const { REACT_APP_API_URL } = process.env;

export async function userLogIn(email, password) {
  localStorage.removeItem("user");
  const res = await apiCall("/login", {
    method: "POST",
    body: JSON.stringify({ email: email.toLowerCase(), password }),
  });

  if (res.ok) {
    return res.json;
  }
  throw new Error("Invalid email or password");
}

export async function userLogOut() {
  const res = await apiCall("/logout", {
    method: "POST",
  });
  localStorage.setItem("authState", "loggedOut");
  localStorage.removeItem("user");
  return res;
}

export async function uploadImage(formData) {
  const res = await fetch(`${REACT_APP_API_URL}/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
    headers: {
      withCredentials: true,
    },
  });
  if (res.ok) {
    return res.json();
  }
  throw new Error("Error uploading image");
}

export async function getDashboardData(filters) {
  const res = await apiCall(`/dashboard${filterUrlGenerator(filters)}`);
  if (res.status === 200) {
    return res.json();
  }
  throw new Error("Error getting dashboard data");
}

export async function sendShareEmail(toAddresses, body) {
  const res = await apiCall("/email/inspection", {
    method: "POST",
    body: JSON.stringify({ toAddresses, body }),
  });
  return res;
}
