import PropTypes from "prop-types";
import { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";

import { editArea, editRackArea, deleteArea } from "libs/api";
import FooterWrapper from "views/components/BuildModeSidebar/SharedControls/Footer";

export default function Footer({
  area,
  areaId,
  name,
  onDataChanged,
  onHide,
  racks = [],
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const buttonClass = areaId
    ? "action-button delete-button"
    : "action-button cancel";
  return (
    <FooterWrapper errorMsg={errorMsg}>
      <ButtonGroup>
        <Button
          className={buttonClass}
          onClick={async () => {
            if (!areaId) {
              onHide();
            } else {
              try {
                await deleteArea(area.id);
                onDataChanged();
                onHide();
              } catch (e) {
                setErrorMsg(e.message);
              }
            }
          }}
        >
          {!areaId ? "Cancel" : "Delete"}
        </Button>
        <Button
          className="action-button save-button"
          onClick={async () => {
            try {
              await Promise.all(
                racks.map(async (rack) => {
                  if (rack.changed) {
                    await editRackArea(rack);
                  }
                }),
              );
              await editArea({ ...area, name });
            } catch (e) {
              setErrorMsg(e.message);
            }
            onDataChanged();
            onHide();
          }}
        >
          Save
        </Button>
      </ButtonGroup>
    </FooterWrapper>
  );
}

Footer.propTypes = {
  area: PropTypes.shape({
    id: PropTypes.string,
  }),
  areaId: PropTypes.string,
  onDataChanged: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  name: PropTypes.string,
  racks: PropTypes.arrayOf(
    PropTypes.shape({
      change: PropTypes.bool,
    }),
  ),
};
