import PropTypes from 'prop-types';

export default function ErrorsDisplay({ errors = [] }) {
  if (errors.length === 0) return null;
  return (
    <div>
      Problem Saving:
      {errors.map((error) => (
        <div key={error.message}>{error.message}</div>
      ))}
    </div>
  );
}

ErrorsDisplay.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({ message: PropTypes.string })),
};
