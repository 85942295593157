import PropTypes from "prop-types";
import { FormGroup, Col } from "react-bootstrap";

import {
  ControlLabelWithLoading,
  StyledFormControl,
} from "views/components/BuildModeSidebar/SharedControls/Generic";

export default function FrameDepth({ disabled, onChange, depth }) {
  return (
    <FormGroup className={disabled ? "greyed" : ""}>
      <Col sm={6}>
        <ControlLabelWithLoading disabled={disabled} label="Frame Depth (mm)" />
      </Col>
      <Col sm={6}>
        <StyledFormControl
          disabled={disabled}
          min="0"
          onChange={(e) => onChange(parseInt(e.target.value, 10))}
          placeholder="Enter Depth"
          type="number"
          value={depth}
        />
      </Col>
    </FormGroup>
  );
}

FrameDepth.propTypes = {
  depth: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
