import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, Row, Col, FormControl, Grid } from "react-bootstrap";

import FlatModal, {
  ModalControls,
  ModalPaddedBody,
} from "views/components/Modals/FlatModal";

const WideButton = styled(Button)`
  ${(props) =>
    props.className === "delete" ? "background-color: #dc3545;" : ""}
  width: 150px;
`;

export default function ConfirmModal({
  children,
  confirmButtonText,
  confirmText,
  cancelButtonText,
  error,
  onConfirm,
  onHide,
  primary,
  title,
  show,
}) {
  const [curConfirmText, setCurConfirmText] = useState("");

  return (
    <FlatModal onHide={onHide} show={show} title={title}>
      <ModalPaddedBody>
        {children}
        {!!confirmText && (
          <div>
            {`Enter the following text to confirm this deletion: ${confirmText}`}
            <FormControl
              onChange={(e) => setCurConfirmText(e.target.value)}
              value={curConfirmText}
            />
          </div>
        )}
        <Grid fluid>
          <Row>{error ? <Col sm={12}>{error}</Col> : null}</Row>
        </Grid>
      </ModalPaddedBody>
      <ModalControls>
        <WideButton className="cancel" onClick={onHide}>
          {cancelButtonText || "Cancel"}
        </WideButton>
        <WideButton
          className={primary ? "primary" : "delete"}
          disabled={confirmText && curConfirmText !== confirmText}
          onClick={onConfirm}
        >
          {confirmButtonText || "Delete"}
        </WideButton>
      </ModalControls>
    </FlatModal>
  );
}

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  onConfirm: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  error: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmText: PropTypes.string,
  primary: PropTypes.bool,
  cancelButtonText: PropTypes.string,
};
