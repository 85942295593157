/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { useState } from "react";
import { Link } from "react-router-dom";

import { useCurrentUser, useUsers } from "hooks/user";
import getUsersRole from "libs/userRole";

import UsersActionButtons from "views/components/ActionButtons/set-users";
import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import { Table } from "views/components/Tables/styles";
import TableHeader from "views/components/Tables/TableHeader";

function renderPadding(renderFunc, paddingAmount) {
  const padding = [];
  for (let i = 0; i < paddingAmount; i += 1) {
    padding.push(renderFunc());
  }
  return padding;
}

function User({ user, padding = false }) {
  const userRole = getUsersRole(user);
  const [showButtons, setShowButtons] = useState(false);
  return (
    <tr
      className={padding ? "padding-tr" : ""}
      css={css`
        ${!padding ? "cursor: pointer;" : ""}
      `}
      onMouseDown={() => setShowButtons(true)}
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <td>{padding || user.name}</td>
      <td>{padding || userRole.RoleName}</td>
      <td>{padding || user.userType}</td>
      <td>{padding || userRole.CompanyName}</td>
      {showButtons ? (
        <UsersActionButtons UserId={user.id} canEditUser={user.canEditUser} />
      ) : null}
    </tr>
  );
}

User.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
    canEditUser: PropTypes.bool.isRequired,
  }).isRequired,
  padding: PropTypes.bool,
};

export default function Users({ siteId }) {
  const { user } = useCurrentUser();
  const { users, total, isLoading } = useUsers({
    SiteId: siteId,
    amount: 4,
    userType: user?.userType ?? "PRRS",
  });
  const paddingAmount = 4 - users ? users.length : 0;
  return (
    <>
      <TableHeader
        left="Users"
        right={
          <Link to={`/clients/sites/${siteId}/users`}>
            View my {total} {user?.userType ?? "PRRS"} users{" "}
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        }
      />
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Type</th>
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0
            ? users.slice(0, 4).map((u) => <User key={u.id} user={u} />)
            : null}
          {!users ||
            !users.length ||
            renderPadding(() => <User padding />, paddingAmount)}
        </tbody>
      </Table>
      {users.length === 0 ? (
        <EmptyTableIndicator isLoading={isLoading} type="Users" />
      ) : null}
    </>
  );
}

Users.propTypes = {
  siteId: PropTypes.string.isRequired,
};
