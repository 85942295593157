/* eslint-disable no-unused-vars */
import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function getRack(RackId, filters) {
  const res = await apiCall(`/rack/${RackId}${filterUrlGenerator(filters)}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error("Problem getting rack");
}

export async function addRack(Rack) {
  const res = await apiCall("/rack/", {
    method: "POST",
    body: JSON.stringify(Rack),
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error("Error adding rack");
}

// TODO: What is all this for?
export async function editRack(racks) {
  // eslint-disable-next-line no-param-reassign
  let slimmedRacks = JSON.parse(JSON.stringify(racks));
  // Note: Pick params you want to discard, and spread the rest you do
  // eslint-disable-next-line no-unused-vars
  slimmedRacks = slimmedRacks.map(({ bays, locations, ...keepRack }) => {
    let slimmedBays = JSON.parse(JSON.stringify(bays));
    slimmedBays = slimmedBays.map(
      ({
        FrameTemplateL,
        FrameTemplateR,
        bayTemplate,
        beams,
        frameL,
        frameR, // prettier-ignore
        ...keepBay
      }) => keepBay,
    );
    return { ...keepRack, bays: slimmedBays };
  });
  const res = await apiCall("/rack/", {
    method: "PUT",
    body: JSON.stringify({ racks: slimmedRacks }),
  });
  if (res.ok) {
    return racks;
  }
  console.error(`Problem posting racks: ${res.status}`);
  return false;
}

export async function deleteRack(RackId) {
  const res = await apiCall(`/rack/${RackId}`, {
    method: "DELETE",
  });
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem delete rack: ${res.status}`);
  return false;
}

export async function editRackArea(rack) {
  const res = await apiCall("/rack/area", {
    method: "PUT",
    body: JSON.stringify({ id: rack.id, AreaId: rack.AreaId }),
  });
  if (res.ok) {
    return true;
  }
  console.error(`Problem adding rack from area: ${res.status}`);
  return false;
}
