import PropTypes from "prop-types";
import { FormGroup, Col } from "react-bootstrap";

import { Radio } from "views/components/BuildModeSidebar/SharedControls/Generic";

export default function FieldBackToBackOffset({
  disabled,
  backToBack,
  onChangeBackToBack,
  onUpdate,
}) {
  return (
    <FormGroup className="position-radio-buttons">
      <Col sm={6}>
        <Radio
          checked={backToBack}
          disabled={disabled}
          inline
          onChange={(e) => {
            onChangeBackToBack(!!(e.target.value === "on"));
            onUpdate({
              backToBack: e.target.value,
              offsetX: 0,
              offsetY: 0,
            });
          }}
        >
          Back to Back
        </Radio>
      </Col>
      <Col sm={6}>
        <Radio
          checked={!backToBack}
          disabled={disabled}
          inline
          onChange={(e) => {
            onChangeBackToBack(!(e.target.value === "on"));
            onUpdate({
              backToBack: !e.target.value,
              offsetX: 0,
              offsetY: 0,
            });
          }}
        >
          Offset
        </Radio>
      </Col>
    </FormGroup>
  );
}

FieldBackToBackOffset.propTypes = {
  backToBack: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChangeBackToBack: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
