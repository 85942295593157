/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormControl,
  Row,
  Grid,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // compat
import SignatureCanvas from "react-signature-canvas";

import { completeAction, completeInspection } from "libs/api";
import { InspectionProp } from "tests/prop-types";
import FlatModal from "views/components/Modals/FlatModal";

function InspectionComplete({
  getData,
  inspection,
  isAction,
  onCompleteInspection,
  onHide,
  selectedUserId,
  showCompleteInspection,
}) {
  const navigate = useNavigate();
  const [completionError, setCompletionError] = useState(null);
  const [trimmedDataURL, setTrimmedDataURL] = useState([]);

  let sigPad = {};
  return (
    <FlatModal
      closeButton
      onHide={onHide}
      show={showCompleteInspection}
      title={`Sign Off ${isAction ? "Action" : "Inspection"}`}
    >
      <div
        css={css`
          .form-control {
            background-color: #f2f2f4;
            color: #747474;
            border: 0px;
            box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
          }
          .btn-group {
            padding-top: 10px;
            float: right;
            .btn {
              width: 125px;
              &:disabled {
                background-color: #7e8496;
                color: #ffffffaa;
              }
            }
          }
        `}
      >
        <Col sm={12}>
          <h5>Inspection</h5>
          <FormControl
            placeholder="Enter Damage"
            value={`[${inspection.type}] Inspection ${inspection.reference}`}
          />
        </Col>
        <Col
          css={css`
            .sigContainer {
              background-color: #f2f2f4;
              width: 100%;
              border-radius: 5px;
              height: 100px;
            }
            .sigCanvas {
              width: 100%;
              border-radius: 5px;
              height: 100px;
            }
          `}
          sm={12}
        >
          <Row>
            <Col sm={6}>
              <h5>Digital Signature</h5>
            </Col>
            {trimmedDataURL && (
              <Col sm={6}>
                <Button
                  css={css`
                    padding: 5px;
                    float: right;
                    border-radius: 5px;
                    font-size: 12px;
                  `}
                  onClick={() => {
                    sigPad.clear();
                    setTrimmedDataURL(null);
                  }}
                >
                  Clear
                </Button>
              </Col>
            )}
          </Row>
          <div className="sigContainer">
            <SignatureCanvas
              canvasProps={{ className: "sigCanvas" }}
              onEnd={() =>
                setTrimmedDataURL(
                  sigPad.getTrimmedCanvas().toDataURL("image/png"),
                )
              }
              penColor="black"
              ref={(ref) => {
                sigPad = ref;
              }}
            />
          </div>
        </Col>
        <Grid fluid>
          <Row>
            <Col sm={12}>
              <ButtonGroup>
                <Button className="cancel" onClick={onHide}>
                  Cancel
                </Button>
                <Button
                  disabled={!trimmedDataURL}
                  onClick={async () => {
                    if (isAction) {
                      const res = await completeAction(
                        inspection.id,
                        null,
                        trimmedDataURL,
                        inspection.updatedAt,
                      );
                      if (res.ok) {
                        onHide();
                        navigate("/actions");
                      } else {
                        setCompletionError(
                          "There was a problem completing the action. Please refresh the page to get latest data and try again.",
                        );
                      }
                    } else {
                      const res = await completeInspection(
                        inspection.id,
                        selectedUserId,
                        null,
                        trimmedDataURL,
                        inspection.updatedAt,
                      );
                      if (res.ok) {
                        const body = await res.json();
                        const showAssignActions =
                          body.hasActions && !body.hasOnlyGreen;
                        if (showAssignActions) {
                          onCompleteInspection();
                          getData();
                        } else {
                          navigate("/inspections");
                        }
                      } else {
                        setCompletionError(
                          "There was a problem completing the inspection. Please refresh the page to get latest data and try again.",
                        );
                      }
                    }
                  }}
                >
                  Complete
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Grid>
        <h4>{completionError}</h4>
      </div>
    </FlatModal>
  );
}

InspectionComplete.propTypes = {
  getData: PropTypes.func,
  inspection: InspectionProp,
  isAction: PropTypes.bool,
  onCompleteInspection: PropTypes.func,
  onHide: PropTypes.func,
  selectedUserId: PropTypes.string,
  showCompleteInspection: PropTypes.bool,
};

export default InspectionComplete;
