import PropTypes from "prop-types";
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { useClassificationImages } from "hooks/classifications";

import ImageLightBox from "views/components/Modals/ImageLightBox";

/**
 * The ClassificationsLightbox is used on the Inspections page in conjunction
 * with the classifications tables. The tables display the classifications and
 * images. When an image is clicked then this lightbox, rendered in the parent
 * is popped open with the classificationId and the image index that was clicked.
 *
 * The lightbox calls an images lightbox component, which allows for the deletion
 * and updating the captions of the images.
 */
// eslint-disable-next-line react/display-name
const ClassificationsLightbox = forwardRef(
  ({ hideSaveButton, readOnly }, ref) => {
    const [classificationId, setClassificationId] = useState(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [editedCaptions, setEditedCaptions] = useState([]);
    const [actionEvidence, setActionEvidence] = useState(false);

    let { images, mutate: refreshImages } =
      useClassificationImages(classificationId);
    images = images.filter((i) =>
      actionEvidence ? !!i.actionFile : !i.actionFile,
    );
    /**
     * This imperitive handle allows other components to open this lightbox with
     * a classification ID (to load the image files) and an image index.
     *
     * Since the images are loaded via a hook that uses SWR, if the images have
     * been loaded elsewhere by a display component, the results will be read from
     * the cache, rather than refetched from the network.
     */
    const openWith = useCallback((id, idx) => {
      setClassificationId(id);
      setCurrentImage(idx ?? 0);
    }, []);

    const isActionFile = useCallback((isActionFile) => {
      setActionEvidence(isActionFile);
    }, []);

    useImperativeHandle(ref, () => ({
      openWith,
      isActionFile,
    }));
    // Keep current image and images in sync for deletion, etc.
    useEffect(() => {
      if (currentImage > images.length - 1) {
        setCurrentImage(images.length - 1);
      }
    }, [images, currentImage]);

    const imagesWithCaptions = useMemo(
      () =>
        images.map((image) => ({
          ...image,
          src: `${image.remotePath}`,
          // Do we have a local editing state that over-rides the db caption?
          caption:
            editedCaptions.find((i) => i.id === image.id)?.caption ??
            image.caption,
          id: image.id,
          createdAt: image.createdAt,
        })),
      [editedCaptions, images],
    );

    // Don't display the modal if we don't have a classificationId or images loaded
    if (!classificationId || !images || images.length < 1) return null;

    return (
      <ImageLightBox
        // Protect against our index getting out of sync when deleting
        currentImage={Math.min(currentImage, images.length - 1)}
        getImages={refreshImages}
        hideSaveButton={hideSaveButton}
        images={imagesWithCaptions}
        isOpen={!!classificationId}
        // onChangeCaption manages the local editing state, NOT db updates
        onChangeCaption={(ImageId, caption) => {
          setEditedCaptions([
            ...editedCaptions.filter((i) => i.id !== ImageId),
            { id: ImageId, caption },
          ]);
        }}
        onClose={() => setClassificationId(null)}
        onDelete={refreshImages}
        onNext={() => setCurrentImage(currentImage + 1)}
        onPrevious={() => setCurrentImage(currentImage - 1)}
        readOnly={readOnly}
      />
    );
  },
);

ClassificationsLightbox.propTypes = {
  readOnly: PropTypes.bool,
  hideSaveButton: PropTypes.bool,
};

export default ClassificationsLightbox;
