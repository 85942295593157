/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { useClient } from "hooks/client";
import { useCurrentUser } from "hooks/user";
import { parseAddress } from "libs/parseAddress";
import {
  ButtonBlueprint,
  ButtonSiteOverview,
} from "views/components/MiniTables/buttons";

export function PaddingRow() {
  return (
    <tr>
      <td />
      <td />
      <td />
      <td className="hover-buttons" />
    </tr>
  );
}

export default function SiteRow({ site }) {
  const { client } = useClient(site?.ClientId);
  return (
    <tr>
      <td>{site.name}</td>
      <td>{parseAddress(site)}</td>
      <td>{site.contactName ?? client?.contactName}</td>
      <SiteActionButtons OrganisationId={client.OrganisationId} site={site} />
    </tr>
  );
}

SiteRow.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    contactName: PropTypes.string,
    ClientId: PropTypes.number,
  }).isRequired,
};

function SiteActionButtons({ site, OrganisationId }) {
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const { permissions } = user;
  const { clientAdmin, organisation, organisationAdmin } = permissions;
  const userCanBuild =
    !organisation.filter((c) => c === site.ClientId).length ||
    !clientAdmin.filter((c) => c === site.ClientId).length ||
    !organisationAdmin.filter((org) => org === OrganisationId).length;
  return (
    <td className="hover-buttons">
      <ButtonBlueprint
        canUserBuild={userCanBuild}
        isBuildMode={site.buildMode}
        isStarted={site.warehouses.length > 0}
        onClick={() => navigate(`/blueprint/${site.id}`)}
      />
      <ButtonSiteOverview
        onClick={() => navigate(`/clients/sites/${site.id}/overview`)}
      />
    </td>
  );
}

SiteActionButtons.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.number,
    buildMode: PropTypes.bool,
    name: PropTypes.string,
    address: PropTypes.string,
    contactName: PropTypes.string,
    ClientId: PropTypes.number,
    warehouses: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  OrganisationId: PropTypes.string,
};
