import moment from 'moment';

export function dueAtSorter(a, b) {
  const dateA = moment(a.dueAt);
  const dateB = moment(b.dueAt);

  if (dateA.isSame(dateB)) {
    return 0;
  }

  if (dateA.isBefore(dateB)) {
    return -1;
  }

  return 1;
}

export function getNextDueHeader(value, group) {
  if (value === '0' && group === 'days') {
    return 'Starting today';
  }

  if (value === '1' && group === 'days') {
    return 'Starting tomorrow';
  }

  if (group === 'hours') {
    return `Starting within ${value} ${value === '1' ? 'hour' : 'hours'}`;
  }

  return `Starting in ${value} ${value === '1' ? group.substring(0, group.length - 1) : group}`;
}

export const groupSchedules = (mySchedules) => {
  if (!mySchedules.length) {
    return null;
  }

  const groupedSchedules = {
    hours: {},
    days: {},
    weeks: {},
    months: {},
    years: {},
  };

  mySchedules.forEach((schedule) => {
    for (let hour = 1; hour <= 24; hour += 1) {
      /*
       * For hours, check the window it is within, and that it is today
       * this avoids "starting in 23 hours", when it should be listed
       * as tomorrow
       */
      if (
        moment(schedule.currentScheduledAt).isBetween(
          moment(),
          moment().add(hour, 'hour'),
          undefined,
          '[)',
        ) &&
        moment(schedule.currentScheduledAt).isSame(moment(), 'day')
      ) {
        if (groupedSchedules.hours[hour]) {
          groupedSchedules.hours[hour].push(schedule);
        } else {
          groupedSchedules.hours[hour] = [schedule];
        }
        return;
      }
    }
    for (let day = 0; day < 7; day += 1) {
      if (
        moment()
          .add(day, 'day')
          .isSame(moment(schedule.currentScheduledAt, 'YYYY-MM-DD HH:mm:ss'), 'day')
      ) {
        if (groupedSchedules.days[day]) {
          groupedSchedules.days[day].push(schedule);
        } else {
          groupedSchedules.days[day] = [schedule];
        }
        return;
      }
    }
    for (let week = 0; week < 4; week += 1) {
      if (
        moment()
          .add(week, 'week')
          .isSame(moment(schedule.currentScheduledAt, 'YYYY-MM-DD HH:mm:ss'), 'week')
      ) {
        if (groupedSchedules.weeks[week]) {
          groupedSchedules.weeks[week].push(schedule);
        } else {
          groupedSchedules.weeks[week] = [schedule];
        }
        return;
      }
    }
    for (let month = 0; month < 12; month += 1) {
      if (
        moment()
          .add(month, 'month')
          .isSame(moment(schedule.currentScheduledAt, 'YYYY-MM-DD HH:mm:ss'), 'month')
      ) {
        if (groupedSchedules.months[month]) {
          groupedSchedules.months[month].push(schedule);
        } else {
          groupedSchedules.months[month] = [schedule];
        }
        return;
      }
    }
    for (let year = 1; year < 20; year += 1) {
      if (
        moment()
          .add(year, 'year')
          .isSame(
            moment(schedule.currentScheduledAt, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
            'year',
          )
      ) {
        if (groupedSchedules.years[year]) {
          groupedSchedules.years[year].push(schedule);
        } else {
          groupedSchedules.years[year] = [schedule];
        }
        return;
      }
    }
  });
  return groupedSchedules;
};
