/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import {
  faBriefcase,
  faLocationDot,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useClients } from "hooks/client";
import { useSchedule } from "hooks/schedules";
import { useSite, useSites } from "hooks/site";
import { createInspection } from "libs/api";
import getSiteNameWithInfo from "libs/siteNameParser";

import FlatModal, {
  ModalControls,
  ModalPaddedBody,
} from "views/components/Modals/FlatModal";

function isSiteInspectionStartable(site) {
  if (!site) return false;
  return !(
    site.buildMode ||
    !site.racks.length ||
    !site.warehouses.length ||
    site.inspections.length
  );
}

const WideButton = styled(Button)`
  background-color: #2d72ed;
  border-radius: 50px;
  min-width: 150px;

  &:disabled,
  &.secondary {
    background-color: #7e8496;
  }

  &:disabled:hover {
    background-color: #7e8496;
  }
`;

const PaddedInputGroup = styled(InputGroup)`
  padding: 10px;
  margin-bottom: 10px;
`;

export default function InspectionStarter({
  onHide,
  isIsolatedDamage,
  ClientId: controlledClientId,
  SiteId: controlledSiteId,
  startScheduleId,
  startScheduledInspectionId,
}) {
  const navigate = useNavigate();

  // Client and Sites that we have selected
  const [ClientId, setClientId] = useState(controlledClientId ?? "");
  const [SiteId, setSiteId] = useState(controlledSiteId ?? "");

  // Data Loading
  const { clients, isLoading: isLoadingClients } = useClients({ amount: 1000 });
  const {
    sites,
    isLoading: isLoadingSites,
    isError: isErrorSites,
  } = useSites({ ClientId, amount: 1000 });
  const { site } = useSite(SiteId);

  // Reset the site id when the client id changes
  useEffect(() => {
    setSiteId(controlledSiteId ?? ""); // Empty string needed for selecting blank option
  }, [ClientId, controlledSiteId]);

  // If we have a controlled site Id, ensure out client Id is correct
  useEffect(() => {
    if (controlledSiteId && site) {
      setClientId(site.ClientId);
    }
  }, [controlledSiteId, site]);

  const title = `Start ${startScheduleId ? 'Scheduled' : 'New'} ${isIsolatedDamage ? 'Isolated Damage' : ''} Inspection`; // prettier-ignore
  return (
    <FlatModal closeButton onHide={onHide} show title={title} width={500}>
      <ModalPaddedBody>
        <PaddedInputGroup>
          <InputGroup.Addon>
            {isLoadingClients ? (
              <FontAwesomeIcon fixedWidth icon={faSpinner} size="sm" spin />
            ) : (
              <FontAwesomeIcon fixedWidth icon={faBriefcase} size="sm" />
            )}
          </InputGroup.Addon>
          <FormControl
            componentClass="select"
            disabled={!!controlledClientId || !!controlledSiteId}
            onChange={(e) => setClientId(e.target.value)}
            value={ClientId}
          >
            <option disabled value="">
              Select Client
            </option>
            {clients.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </FormControl>
        </PaddedInputGroup>
        {ClientId ? (
          <PaddedInputGroup>
            <InputGroup.Addon>
              {isLoadingSites ? (
                <FontAwesomeIcon fixedWidth icon={faSpinner} size="sm" spin />
              ) : (
                <FontAwesomeIcon fixedWidth icon={faLocationDot} size="sm" />
              )}
            </InputGroup.Addon>
            <FormControl
              componentClass="select"
              disabled={!!controlledSiteId}
              onChange={(e) => setSiteId(e.target.value)}
              value={SiteId}
            >
              {!isLoadingSites && !isErrorSites && sites.length > 0 ? (
                <option disabled value="">
                  Select Site
                </option>
              ) : null}
              {!isLoadingSites && !isErrorSites && !sites.length ? (
                <option value="">No sites found</option>
              ) : null}
              {sites.map((s) => (
                <option
                  disabled={!isSiteInspectionStartable(s) || !!controlledSiteId}
                  key={s.id}
                  value={s.id}
                >
                  {getSiteNameWithInfo(s)}
                </option>
              ))}
            </FormControl>
          </PaddedInputGroup>
        ) : null}
      </ModalPaddedBody>

      <ModalControls>
        <WideButton className="secondary" onClick={onHide}>
          Cancel
        </WideButton>
        <WideButton
          disabled={!isSiteInspectionStartable(site)}
          onClick={async () => {
            const newInspection = await createInspection(
              SiteId,
              startScheduleId,
              isIsolatedDamage,
              startScheduledInspectionId,
            );
            if (newInspection) {
              navigate(`/inspections/view/${newInspection.id}`);
            }
          }}
        >
          Start
        </WideButton>
      </ModalControls>
    </FlatModal>
  );
}

InspectionStarter.propTypes = {
  ClientId: PropTypes.string,
  isIsolatedDamage: PropTypes.bool,
  onHide: PropTypes.func,
  SiteId: PropTypes.string,
  startScheduledInspectionId: PropTypes.string,
  startScheduleId: PropTypes.string,
};

/**
 * Inspection starter for an inspection. Pass the schedule IDs, and it will
 * lock the inspection starter to the right site.
 */
export function ScheduledInspectionStarter({
  isIsolatedDamage,
  onHide,
  startScheduledInspectionId,
  startScheduleId,
}) {
  const { schedule } = useSchedule(startScheduleId);
  const { site } = useSite(schedule?.SiteId);

  if (!site) return null;
  return (
    <InspectionStarter
      ClientId={site?.ClientId}
      SiteId={site?.id}
      isIsolatedDamage={isIsolatedDamage}
      onHide={onHide}
      startScheduleId={startScheduleId}
      startScheduledInspectionId={startScheduledInspectionId}
    />
  );
}

ScheduledInspectionStarter.propTypes = {
  isIsolatedDamage: PropTypes.bool,
  onHide: PropTypes.func,
  startScheduledInspectionId: PropTypes.string,
  startScheduleId: PropTypes.string,
};
