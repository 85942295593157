import moment from "moment";

import { SHORT_DATE_FORMAT } from "./constants";

export default function formatDueDates(
  fromDueAt,
  toDueAt,
  dateFormat = SHORT_DATE_FORMAT,
) {
  if (!fromDueAt && !toDueAt) {
    return "None";
  }
  if (fromDueAt === toDueAt || (fromDueAt && !toDueAt)) {
    return moment(fromDueAt).format(dateFormat);
  }
  if (!fromDueAt && toDueAt) {
    return moment(toDueAt).format(dateFormat);
  }
  return `${moment(fromDueAt).format(dateFormat)} - ${moment(toDueAt).format(
    dateFormat,
  )}`;
}
