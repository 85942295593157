import PropTypes from "prop-types";
import { FormGroup, Col } from "react-bootstrap";

import {
  ControlLabelWithLoading,
  StyledFormControl,
} from "views/components/BuildModeSidebar/SharedControls/Generic";

export default function FieldOffsetFromRack({
  areas = [],
  racks = [],
  relativeToRackId,
  disabled,
  onChangeCompareRack,
}) {
  return (
    <FormGroup>
      <Col sm={4}>
        <ControlLabelWithLoading disabled={disabled}>
          Relative to Rack
        </ControlLabelWithLoading>
      </Col>
      <Col sm={8}>
        <StyledFormControl
          componentClass="select"
          disabled={disabled}
          onChange={(e) => onChangeCompareRack(e.target.value)}
          placeholder="Compare Rack"
          value={relativeToRackId}
        >
          {racks.map((r) => {
            const rackArea = areas?.find((curArea) => curArea.id === r.AreaId);
            const areaName = rackArea ? `, ${rackArea.name}` : "";
            return (
              <option key={r.id} value={r.id}>
                {r.name}
                {areaName}
              </option>
            );
          })}
        </StyledFormControl>
      </Col>
    </FormGroup>
  );
}

FieldOffsetFromRack.propTypes = {
  relativeToRackId: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeCompareRack: PropTypes.func.isRequired,
  racks: PropTypes.array.isRequired,
  areas: PropTypes.array.isRequired,
};
