import styled from "@emotion/styled";
import {
  Alert,
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Panel,
} from "react-bootstrap";

import usePasswordChange, { statuses } from "hooks/use-password-changer";

import SettingsPanel from "views/components/SettingsPanel";

const FormWrapper = styled.div`
  padding: 0 20px;
`;

/**
 * A settings panel that allows the user to change their current password.
 */
export default function ChangePassword() {
  /** Form submission state */
  const {
    canChangePassword,
    handleChangePassword,
    setConfirmPassword,
    setCurrentPassword,
    setNewPassword,
    state: formState,
  } = usePasswordChange();

  const { status, errorMessage } = formState; // Form submission status
  const { currentPassword, newPassword, confirmPassword } = formState; // Form inputs

  return (
    <SettingsPanel>
      <Panel.Heading>Change Password</Panel.Heading>
      <Panel.Body>
        <FormWrapper>
          <FormGroup>
            <ControlLabel>
              Current Password
              <span className="required">*</span>
            </ControlLabel>

            <FormControl
              onChange={(e) => setCurrentPassword(e.target.value)}
              type="password"
              value={currentPassword}
            />

            <p>
              Passwords must be 7 characters long and contain at least one
              number and one letter.
            </p>

            <ControlLabel>
              New Password
              <span className="required">*</span>
            </ControlLabel>

            <FormControl
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              value={newPassword}
            />

            <ControlLabel>
              Confirm Password
              <span className="required">*</span>
            </ControlLabel>

            <FormControl
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              value={confirmPassword}
            />
          </FormGroup>
          <div>
            <Button
              disabled={!canChangePassword}
              onClick={handleChangePassword}
            >
              {status === statuses.SAVING ? "Saving..." : "Change Password"}
            </Button>
          </div>
          <div>
            {status === statuses.ERROR && (
              <Alert bsStyle="danger">{errorMessage}</Alert>
            )}
            {status === statuses.SUCCESS && (
              <Alert bsStyle="success">Password successfully changed.</Alert>
            )}
          </div>
        </FormWrapper>
      </Panel.Body>
    </SettingsPanel>
  );
}
