/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Checkbox } from "react-bootstrap";

function SiteSelectorOption({ site, disabled, permissions, onChange }) {
  return (
    <div>
      <Checkbox
        checked={
          permissions && (permissions.site.includes(site.id) || disabled)
        }
        disabled={disabled}
        onChange={(e) => onChange(!!e.target.checked)}
      >
        {site.name}
      </Checkbox>
    </div>
  );
}

SiteSelectorOption.propTypes = {
  disabled: PropTypes.bool,
  permissions: PropTypes.shape({
    site: PropTypes.arrayOf(PropTypes.string),
  }),
  site: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

function SiteSelector({
  disabled,
  onAddPermissions,
  onDeletePermissions,
  permissions,
  sites,
  userId,
}) {
  if (!sites) {
    return null;
  }
  return (
    <div
      css={css`
        max-height: 200px;
        background-color: #eee;
        border: 1px solid #ddd;
        overflow-y: scroll;
        padding: 2px;
      `}
    >
      {sites.map((site) => (
        <SiteSelectorOption
          disabled={disabled}
          key={site.id}
          onChange={(added) =>
            added
              ? onAddPermissions(userId, "site", [site.id])
              : onDeletePermissions(userId, "site", [site.id])
          }
          permissions={permissions}
          site={site}
        />
      ))}
    </div>
  );
}

SiteSelector.propTypes = {
  onAddPermissions: PropTypes.func,
  onDeletePermissions: PropTypes.func,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  permissions: PropTypes.shape({
    clientAdmin: PropTypes.arrayOf(PropTypes.string),
    client: PropTypes.arrayOf(PropTypes.string),
    site: PropTypes.arrayOf(PropTypes.string),
  }),
  disabled: PropTypes.bool,
  userId: PropTypes.string,
};

export default SiteSelector;
