import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const Wrapper = styled.div`
  display: inline-block;
  left: ${({ showSidebar }) => (showSidebar ? "400px" : "0")};
  position: absolute;
  top: 40%;
  z-index: 1000;
`;

const ToggleButton = styled(Button)`
  background-color: #494c55;
  border-radius: 0 25px 25px 0 !important;
  cursor: pointer;
  color: #eee;
  font-weight: 600;
  height: 35px;
  line-height: 15px;
  margin: 0;
  padding: 0;
  width: 25px;

  .fa-chevron-left:before {
    padding-right: 5px;
  }
`;

/**
 * Small tab button shown at the side of the blueprint to toggle the sidebar
 * open and closed.
 */
export default function SidebarButton({ showSidebar, onToggleSidebar }) {
  return (
    <Wrapper showSidebar={showSidebar}>
      <ToggleButton onClick={onToggleSidebar}>
        {showSidebar ? (
          <FontAwesomeIcon icon={faChevronLeft} />
        ) : (
          <FontAwesomeIcon icon={faChevronRight} />
        )}
      </ToggleButton>
    </Wrapper>
  );
}

SidebarButton.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  onToggleSidebar: PropTypes.func.isRequired,
};
