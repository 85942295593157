import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const LinkButtonWide = styled(Link)`
  background-color: ${(props) => (props.primary ? "#2d72ed" : "#7e8496")};
  border-radius: 50px !important;
  border-width: 0;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 40%;
  line-height: 1.42857143;
  margin: 5px;
  min-width: 150px;
  outline: none;
  overflow: hidden;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: background-color 0.3s;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
`;
export default LinkButtonWide;
