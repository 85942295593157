import PropTypes from "prop-types";
import { useState } from "react";

import FlatModal from "views/components/Modals/FlatModal";
import { ShareModal } from "views/components/Modals/ShareModal/ShareModal.stories";

import ShareButton from "./button-share";

export default function ShareInspectionButton({ disabled, InspectionId }) {
  const [showModal, setShowModal] = useState(false);
  if (!InspectionId) return null;
  return (
    <>
      {showModal && (
        <FlatModal
          closeButton
          onHide={() => setShowModal(false)}
          show
          title="Share Inspection Report"
          width={500}
        >
          <ShareModal
            InspectionId={InspectionId}
            onHide={() => setShowModal(false)}
            type="inspection"
          />
        </FlatModal>
      )}
      <ShareButton disabled={disabled} onClick={() => setShowModal(true)} />
    </>
  );
}

ShareInspectionButton.propTypes = {
  disabled: PropTypes.bool,
  InspectionId: PropTypes.string.isRequired,
};
