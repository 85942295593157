import { useMemo, useCallback } from "react";

import { useAreas, useSite } from "hooks/site";
import { useWarehouse } from "hooks/warehouse";
import { editRack } from "libs/api";

import { calculateAreaMinMaxXY, rackToBlueprintData } from "libs/blueprint";

export const SCALE_FACTOR = 0.1;

/**
 * Sits on top of the site blueprint hook, scaling all relevant dimensions.
 * Used for the display of the blueprint, so we can deal with massive sites
 */
export function useScaledSiteBlueprint(
  SiteId,
  WarehouseId,
  InspectionId,
  archival,
) {
  const { areas, isLoading, site, mutate, warehouse, updateRackLocation } =
    useSiteBlueprint(SiteId, WarehouseId, InspectionId, archival);

  const scaledAreas = useMemo(
    () =>
      areas.map((area) => ({
        ...area,
        maxX: Math.round(area.maxX * SCALE_FACTOR),
        maxY: Math.round(area.maxY * SCALE_FACTOR),
        minX: Math.round(area.minX * SCALE_FACTOR),
        minY: Math.round(area.minY * SCALE_FACTOR),
      })),
    [areas],
  );

  const scaledSite = useMemo(
    () =>
      site
        ? {
            ...site,
            racks: site.racks.map((rack) => ({
              ...rack,
              originX: Math.round(rack.originX * SCALE_FACTOR),
              originY: Math.round(rack.originY * SCALE_FACTOR),
            })),
            warehouses: site.warehouses.map((warehouse) => ({
              ...warehouse,
              sizeX: Math.round(warehouse.sizeX * SCALE_FACTOR),
              sizeY: Math.round(warehouse.sizeY * SCALE_FACTOR),
            })),
          }
        : null,
    [site],
  );

  const scaledWarehouse = useMemo(
    () =>
      warehouse
        ? {
            ...warehouse,
            sizeX: Math.round(warehouse.sizeX * SCALE_FACTOR),
            sizeY: Math.round(warehouse.sizeY * SCALE_FACTOR),
            racks: warehouse.racks.map((rack) => ({
              ...rack,
              originX: Math.round(rack.originX * SCALE_FACTOR),
              originY: Math.round(rack.originY * SCALE_FACTOR),
              totalX: Math.round(rack.totalX * SCALE_FACTOR),
              totalY: Math.round(rack.totalY * SCALE_FACTOR),
              bays: rack.bays.map((bay) => ({
                ...bay,
                offset: Math.round(bay.offset * SCALE_FACTOR),
                width: Math.round(bay.width * SCALE_FACTOR),
                FrameTemplateL: bay.FrameTemplateL
                  ? {
                      ...bay.FrameTemplateL,
                      depth: Math.round(
                        bay.FrameTemplateL.depth * SCALE_FACTOR,
                      ),
                      width: Math.round(
                        bay.FrameTemplateL.width * SCALE_FACTOR,
                      ),
                    }
                  : null,
                FrameTemplateR: bay.FrameTemplateR
                  ? {
                      ...bay.FrameTemplateR,
                      depth: Math.round(
                        bay.FrameTemplateR.depth * SCALE_FACTOR,
                      ),
                      width: Math.round(
                        bay.FrameTemplateR.width * SCALE_FACTOR,
                      ),
                    }
                  : null,
              })),
            })),
          }
        : null,
    [warehouse],
  );

  return {
    areas: scaledAreas,
    isLoading,
    site: scaledSite,
    mutate,
    warehouse: scaledWarehouse,
    updateRackLocation,
  };
}

/**
 * Returns data useful for the rendering of a site blueprint, with a specific
 * warehouse and optional inspection (to target archival sites)
 */
export default function useSiteBlueprint(
  SiteId,
  WarehouseId,
  InspectionId,
  archival,
) {
  // TODO: Add errors
  const {
    site,
    isLoading: isLoadingSite,
    mutate: mutateSite,
  } = useSite(SiteId);
  const { warehouse, isLoading: isLoadingWarehouse, mutate: mutateWarehouse } = useWarehouse(WarehouseId, { InspectionId }); // prettier-ignore
  const { areas, isLoading: isLoadingAreas } = useAreas(WarehouseId, {
    archival,
  });

  // Convert this into something nice we can use. The rack layout is correct,
  // and the bays contain their own layout information
  const rackToBlueprintDataWithBayTemplates = useCallback(
    (r) =>
      rackToBlueprintData(
        r,
        warehouse?.snapshot.bayTemplates,
        warehouse?.snapshot.frameTemplates,
      ),
    [warehouse],
  );

  const racks = useMemo(
    () =>
      warehouse?.snapshot?.racks.map(rackToBlueprintDataWithBayTemplates) ?? [],
    [warehouse, rackToBlueprintDataWithBayTemplates],
  );

  const areasWithDimensions = useMemo(() => {
    if (!racks || racks.length === 0 || !areas) return [];
    return areas?.map((a) => calculateAreaMinMaxXY(a, racks)) ?? [];
  }, [areas, racks]);

  const mutate = useCallback(() => {
    mutateWarehouse();
    mutateSite();
  }, [mutateSite, mutateWarehouse]);

  const warehouseWithRacks = useMemo(
    () => (warehouse ? { ...warehouse, racks } : undefined),
    [warehouse, racks],
  );

  const updateRackLocation = useCallback(
    async (rackId, x, y) => {
      const rack = warehouseWithRacks?.snapshot?.racks.find(
        (r) => r.id === rackId,
      );
      await editRack([{ ...rack, originX: x, originY: y }]);
      mutate();
    },
    [mutate, warehouseWithRacks?.snapshot?.racks],
  );

  return {
    areas: areasWithDimensions,
    isLoading: isLoadingSite || isLoadingWarehouse || isLoadingAreas,
    site,
    mutate,
    warehouse: warehouseWithRacks,
    updateRackLocation,
  };
}
