import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { FiltersProvider, FILTER_KEYS, useFilters } from "hooks/useFilter";
import { useCurrentUser, useUsers } from "hooks/user";
import getUsersRole from "libs/userRole";

import UsersActionButtons from "views/components/ActionButtons/set-users";
import PageWrapper from "views/components/common/PageWrapper";
import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import PageButtons from "views/components/Tables/PageButtons";
import { Table } from "views/components/Tables/styles";
import {
  MiniFilterTab,
  MiniFilterWrapper,
  TableControlsWrapper,
} from "views/components/Tables/TableControls";
import TableHeader from "views/components/Tables/TableHeader";
import Controls from "views/pages/Users/components/Controls";

const PAGE_SIZE = 10;

function getCanUserAddEditUsers(user) {
  if (!user) {
    return false;
  }
  const { permissions } = user;
  return !!permissions.organisationAdmin.length;
}

function UsersPage() {
  const { user } = useCurrentUser();

  const { clientId, siteId, site, client, searchText } = useFilters();

  const [page, setPage] = useState(0);
  const [showAll, setShowAll] = useState(null);
  const [organisation] = useState(null);

  const searchFilters = useMemo(
    () => ({
      ClientId: clientId,
      SiteId: siteId,
      searchText,
      page,
      amount: PAGE_SIZE,
      userType: showAll ? false : user?.userType,
    }),
    [clientId, siteId, searchText, page, showAll, user],
  );

  /**
   * Data Loading Hooks
   */
  const {
    users,
    isLoading: isUsersLoading,
    total: totalUsers,
  } = useUsers(searchFilters);

  const getAddUserUrl = () => {
    let start = "";
    if (organisation) {
      start = `/organisation/${organisation.id}`;
    }
    if (client) {
      start = `/clients/${client.id}`;
    }
    if (site) {
      start = `/clients/sites/${site.id}`;
    }

    return `${start}/users/add`;
  };

  const showActionButton = getCanUserAddEditUsers(user);

  const totalPages = Math.ceil(totalUsers / PAGE_SIZE);
  const userType =
    user?.userType === "InstallerAdmin" ? "Installer" : user?.userType;
  return (
    <PageWrapper>
      <Helmet>
        <title>Econform / Users</title>
      </Helmet>
      <Controls
        organisation={organisation}
        redirectUrl={getAddUserUrl}
        showActionButton={showActionButton}
      />
      <TableHeader
        largeLeftTagline="List of all your users"
        largeLeftTitle="Users"
        right={`Showing ${users && users.length} of ${totalUsers || 0} Users`}
      />
      <TableControlsWrapper>
        {/** Needed to align mini filter right. This should be fixed! */}
        <div />
        {user?.userType ? (
          <MiniFilterWrapper>
            <MiniFilterTab
              onClick={() => {
                setShowAll(false);
                setPage(0);
              }}
              selected={!showAll}
            >
              {!showAll ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faCalendar} />
              )}
              My {userType} Users
            </MiniFilterTab>
            {userType !== "Installer" && (
              <MiniFilterTab
                onClick={() => {
                  setShowAll(true);
                  setPage(0);
                }}
                selected={showAll}
              >
                {showAll ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  <FontAwesomeIcon icon={faCalendar} />
                )}
                All Users
              </MiniFilterTab>
            )}
          </MiniFilterWrapper>
        ) : null}
      </TableControlsWrapper>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Type</th>
            <th>Email</th>
            <th>Company Name</th>
          </tr>
        </thead>
        <tbody>
          {!isUsersLoading && users && users.length
            ? users.map((curUser) => {
                const userRole = getUsersRole(curUser);
                return (
                  <tr key={curUser.id}>
                    <td>{curUser.name}</td>
                    <td>{userRole.RoleName}</td>
                    <td>{curUser.userType}</td>
                    <td>{curUser.email}</td>
                    <td>
                      {curUser.mainInstallerOrganisationName ||
                        userRole.CompanyName}
                    </td>
                    <UsersActionButtons
                      ClientId={clientId}
                      UserId={curUser.id}
                      canEditUser={curUser.canEditUser}
                    />
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
      {(isUsersLoading || !users.length) && (
        <EmptyTableIndicator
          isLoading={isUsersLoading}
          noTabs
          searchable
          type="Users"
        />
      )}
      <PageButtons
        curPage={page}
        onChangePage={setPage}
        totalPages={totalPages}
      />
    </PageWrapper>
  );
}

/**
 * A users page can be the global one, as part of a client, or as part of a
 * site. This wrapping component provides the filter context for the page.
 */
function UsersPageInURLContext() {
  const { ClientId: urlClientId, SiteId: urlSiteId } = useParams();

  const controlledValues = {
    [FILTER_KEYS.clientId]: urlClientId,
    [FILTER_KEYS.siteId]: urlSiteId,
  };

  const enabledFilters = [
    !urlClientId && !urlSiteId && FILTER_KEYS.clientId, // Don't allow changes when client or site Id is specified
    !urlSiteId && FILTER_KEYS.siteId,
  ].filter(Boolean);

  return (
    <FiltersProvider
      controlledValues={controlledValues}
      enabledFilters={enabledFilters}
      key={`${urlClientId}_${urlSiteId}`}
    >
      <UsersPage />
    </FiltersProvider>
  );
}

export default UsersPageInURLContext;
