/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { Image, Button } from "react-bootstrap";

import { addClassificationFile } from "libs/api";
import getOptimisedImageUrl from "libs/getOptimisedImageUrl";
import UploadButton from "views/components/UploadButton";

import { ImageWrapper } from "./styles";

const numDisplayImages = 2;

const ImageThumbnail = styled(Image)`
  width: 80px;
  height: 80px;
`;

/**
 * Displays a set of images, a function that triggers a modal overlay, an uploader
 * and a function to refresh the images once uploaded.
 */
function Images({
  classificationId,
  disableUploader,
  images = [],
  isBlueprint,
  onClickImage,
  refreshImages,
  showUploader,
}) {
  const [uploadingImage, setUploadingImage] = useState(false);
  return (
    <>
      {showUploader && (
        <UploadButton
          disabled={disableUploader}
          onFileUploadFailed={() => setUploadingImage(false)}
          onFileUploadStarted={() => setUploadingImage(true)}
          onFileUploaded={async (file) => {
            const res = await addClassificationFile({
              remotePath: file.location,
              ClassificationId: classificationId,
            });
            if (res.ok) {
              await refreshImages();
              setUploadingImage(false);
            }
          }}
        >
          <FontAwesomeIcon
            icon={uploadingImage ? faSpinner : faPlus}
            pulse={uploadingImage}
          />{" "}
        </UploadButton>
      )}
      {images.map(
        (image, index) =>
          index < numDisplayImages && (
            <ImageWrapper
              key={`${image.remotePath}`}
              onClick={() => onClickImage(isBlueprint ? images : index, index)}
            >
              <ImageThumbnail
                src={
                  image.remotePath
                    ? getOptimisedImageUrl(`${image.remotePath}`, "thumb")
                    : "/img/defaults/logo.png"
                }
              />
              {image.caption && (
                <div className="caption-denoter">
                  <Image src="/img/misc/caption-icon.png" />
                </div>
              )}
            </ImageWrapper>
          ),
      )}
      {images.length >= numDisplayImages && (
        <Button
          css={css`
            width: 80px;
            height: 80px;
            margin: 0px;
            margin-left: -85px;
            margin-right: 5px;
            background-color: #666666aa;
            color: #eeeeee;
            border-radius: 0px !important;
            z-index: 999;
          `}
          onClick={() => onClickImage(numDisplayImages - 1)}
        >
          {`+ ${images.length - (numDisplayImages - 1)} more`}
        </Button>
      )}
    </>
  );
}

Images.propTypes = {
  classificationId: PropTypes.string,
  disableUploader: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      remotePath: PropTypes.string,
      caption: PropTypes.string,
    }),
  ),
  onClickImage: PropTypes.func,
  /** Called on successful upload */
  refreshImages: PropTypes.func.isRequired,
  showUploader: PropTypes.bool,
  isBlueprint: PropTypes.bool,
};

export default Images;
