import styled from "@emotion/styled";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { RoundLink } from "views/pages/Inspection/styles";

export const COMPLETABLE_STATE = {
  completable: "completable",
  complete: "complete",
  notCompletable: "notCompletable",
  partiallyCompletable: "partiallyCompletable",
};

/**
 * Returns the appropriate button state for an inspection
 */
export function getInspectionCompletableState(inspection) {
  if (
    !inspection ||
    (!inspection.isCompletable && !inspection.isPartialCompletable)
  ) {
    return COMPLETABLE_STATE.notCompletable;
  }

  if (inspection.isPartialCompletable && !inspection.isCompletable) {
    return COMPLETABLE_STATE.partiallyCompletable;
  }

  if (inspection.signedOffAt) {
    return COMPLETABLE_STATE.complete;
  }

  return COMPLETABLE_STATE.completable;
}

/**
 * Returns the appropriate button state for an action
 */
export function getActionsCompleteableState(action) {
  if (!action) return COMPLETABLE_STATE.notCompletable;
  if (action.isCompletable || action.isPartialCompletable) {
    return COMPLETABLE_STATE.completable;
  }
  return COMPLETABLE_STATE.notCompletable;
}

const StyledButton = styled(RoundLink, {
  shouldForwardProp: (prop) => prop !== "state",
})`
  &,
  &:active,
  &:hover,
  &:focus,
  &:disabled {
    ${(props) =>
      props.state === COMPLETABLE_STATE.completable ||
      props.state === COMPLETABLE_STATE.complete
        ? "background-color: #FECE46;"
        : ""}
    ${(props) =>
      props.state === COMPLETABLE_STATE.notCompletable
        ? "background-color: #7E849633;"
        : ""}
    ${(props) =>
      props.state === COMPLETABLE_STATE.partiallyCompletable
        ? "background-color: #7E8496;"
        : ""}
  }
`;

/**
 * Round button for actions and inspections used in the signoff workflow.
 *
 */
export default function ButtonCompleteCheck({
  disabled = false,
  onClick,
  state = COMPLETABLE_STATE.completable,
}) {
  return (
    <StyledButton disabled={disabled} onClick={onClick} state={state}>
      <FontAwesomeIcon fixedWidth icon={faCheck} />
    </StyledButton>
  );
}

ButtonCompleteCheck.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  state: PropTypes.oneOf(Object.values(COMPLETABLE_STATE)),
};
