import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom"; // compat

import { useClients } from "hooks/client";
import { FiltersProvider, useFilters } from "hooks/useFilter";
import { parseAddress } from "libs/parseAddress";

import PageWrapper from "views/components/common/PageWrapper";
import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import PageButtons from "views/components/Tables/PageButtons";
import { Table } from "views/components/Tables/styles";
import TableHeader from "views/components/Tables/TableHeader";

import Controls from "./Controls";

const PointerRow = styled.tr`
  cursor: pointer;
`;

const ClientLogo = styled(Image)`
  height: 40px;
  width: 40px;
  margin-left: 10px;
  margin-right: 0px;
  border-radius: 50%;
`;

function ClientsPage() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { searchText } = useFilters();

  // Reset page to 0 when search text changes
  useEffect(() => {
    setPage(0);
  }, [searchText]);

  const amount = 10;
  const { clients, total, isLoading } = useClients({
    searchText,
    page,
    amount,
  });
  const totalPages = Math.ceil(total / amount);

  return (
    <PageWrapper>
      <Helmet>
        <title>Econform / Clients</title>
      </Helmet>
      <Controls />
      <TableHeader
        largeLeftTagline="List of clients assigned to you"
        largeLeftTitle="Clients"
        right={`Showing ${clients.length || 0} of ${total || 0} Clients`}
      />
      <Table>
        <thead>
          <tr>
            <th />
            <th>Client Name</th>
            <th>Sites</th>
            <th>Address</th>
            <th>Contact Name</th>
            <th>Contact Number</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => {
            const sites = client.sites.filter((curSite) => !curSite.deletedAt);
            return (
              <PointerRow
                key={client.id}
                onClick={() => navigate(`/clients/${client.id}/overview`)}
              >
                <td>
                  <ClientLogo
                    src={
                      client.logoUrl
                        ? `${client.logoUrl}`
                        : "/img/defaults/logo.png"
                    }
                  />
                </td>
                <td>{client.name}</td>
                <td>{sites.length}</td>
                <td>{parseAddress(client)}</td>
                <td>{client.contactName}</td>
                <td>{client.contactNumber}</td>
              </PointerRow>
            );
          })}
        </tbody>
      </Table>
      {!clients.length && (
        <EmptyTableIndicator
          isLoading={isLoading}
          noTabs
          searchable
          type="Clients"
        />
      )}
      <PageButtons
        curPage={page}
        onChangePage={setPage}
        totalPages={totalPages}
      />
    </PageWrapper>
  );
}

export default function ClientsPageWithFilters() {
  return (
    <FiltersProvider>
      <ClientsPage />
    </FiltersProvider>
  );
}
