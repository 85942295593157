import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'react-bootstrap';

import FooterWrapper from '../SharedControls/Footer';

import { isFrameTemplateValid } from './helpers';

export default function Footer({ frameTemplate, isNew, onSave, onDelete, errorMsg }) {
  const isFrameValid = isFrameTemplateValid(frameTemplate);
  return (
    <FooterWrapper errorMsg={errorMsg}>
      <ButtonGroup>
        <Button
          className={`action-button ${isNew ? 'cancel' : 'delete-button'}`}
          onClick={onDelete}
        >
          {isNew ? 'Cancel' : 'Delete'}
        </Button>
        <Button className="action-button save-button" disabled={isFrameValid} onClick={onSave}>
          {isNew ? 'Add' : 'Save'}
        </Button>
      </ButtonGroup>
    </FooterWrapper>
  );
}

Footer.propTypes = {
  isNew: PropTypes.bool,
  frameTemplate: PropTypes.shape({
    id: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
};
