/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function getWarehouse(WarehouseId, filters) {
  const res = await apiCall(
    `/warehouse/${WarehouseId}${filterUrlGenerator(filters)}`,
  );
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem getting warehouse`);
}

export async function getWarehouses(filters) {
  const res = await apiCall(`/warehouse${filterUrlGenerator(filters)}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem getting warehouses: ${res.status}`);
}

export async function addWarehouse(warehouse) {
  try {
    const res = await apiCall("/warehouse", {
      method: "POST",
      body: JSON.stringify(warehouse),
    });

    if (res.ok) {
      return res.json();
    }
  } catch (e) {
    throw new Error(`Problem adding warehouse`);
  }
}

export async function editWarehouse(warehouse) {
  try {
    const res = await apiCall("/warehouse", {
      method: "PUT",
      body: JSON.stringify(warehouse),
    });

    return res.json();
  } catch (e) {
    throw new Error(`Problem editing warehouse`);
  }
}

export async function deleteWarehouse(WarehouseId) {
  const res = await apiCall(`/warehouse/${WarehouseId}`, {
    method: "DELETE",
  });
  return res;
}
