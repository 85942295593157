import { useCurrentUser } from "hooks/user";

import UnviewedIndicator from "../common/UnviewedIndicator";

export default function UserMissedInspectionsIndicator() {
  const { user } = useCurrentUser();
  return (
    <UnviewedIndicator
      amount={user?.userMissedInspections?.length}
      borderSize={2}
      maxAmount={9}
      size={20}
      small
    />
  );
}
