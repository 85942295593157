/** @jsxImportSource @emotion/react */
import { useId, useState } from "react";
import { MenuItem, SplitButton } from "react-bootstrap";

import { useFilters } from "hooks/useFilter";
import FilterOptionsAndChips from "views/components/FilterOptionsAndChips";
import InspectionStarter from "views/components/Modals/InspectionStarter";

import { ControlsButtonWrapper, ControlsWrapper } from "./styles";

export default function Controls() {
  const { clientId, siteId } = useFilters();
  const [showModal, setShowModal] = useState(false);

  // Accessibility
  const splitButtonId = useId();

  return (
    <>
      <ControlsWrapper>
        <FilterOptionsAndChips showSearch />
        <ControlsButtonWrapper>
          <SplitButton
            id={splitButtonId}
            onClick={() => setShowModal(true)}
            pullRight
            title="New Inspection"
          >
            <MenuItem onClick={() => setShowModal("isolatedDamage")}>
              Isolated Rack Damage
            </MenuItem>
          </SplitButton>
        </ControlsButtonWrapper>
      </ControlsWrapper>

      {!!showModal && (
        <InspectionStarter
          ClientId={clientId}
          SiteId={siteId}
          isIsolatedDamage={showModal === "isolatedDamage"}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
