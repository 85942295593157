/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export function ImageWrapper({ children, onClick }) {
  return (
    <div
      css={css`
        display: inline-block;
        width: 85px;
        position: relative;
        cursor: pointer;
        .caption-denoter {
          position: absolute;
          top: 0;
          background-color: #33333333;
          width: 80px;
          height: 80px;
          z-index: 998;
          img {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 15px;
            height: auto;
          }
        }
      `}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export function PanelWrapper({ children, expanded }) {
  return (
    <div
      css={css`
        display: ${expanded ? 'show' : 'none'};
        padding-bottom: 10px;
        margin-bottom: 5px;
        height: 140px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #ffffffff;
        .form-control {
          background-color: #eeeeee !important;
          color: #747474;
          border: 0px;
          box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
        }
        .detail-col {
          border-top: 1px solid #99999944;
          height: 120px;
          padding-top: 5px;
          padding: 0px;
          color: #494c55;
        }
        .image-section {
          padding-top: 10px;
        }
        .damage-picture > div {
          display: inline;
        }
      `}
    >
      {children}
    </div>
  );
}

export function Wrapper({ children, expanded }) {
  return (
    <div
      css={css`
        margin-bottom: 15px;
        table {
          border-collapse: collapse;
          border-radius: 5px;
          ${expanded ? 'border-bottom-left-radius: 0px' : ''};
          ${expanded ? 'border-bottom-right-radius: 0px' : ''};
          overflow: hidden;
          margin-bottom: ${expanded ? '0' : '5px'};
        }
        th {
          border-bottom: 0px !important;
        }
        thead {
          cursor: pointer;
          background-color: #494c55;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          height: 35px;
        }
        th,
        td {
          border-bottom: 0px solid white;
        }
        td {
          background-color: #ffffff;
          height: 45px;
          padding-top: 4px !important;
          padding-bottom: 4px !important;
          color: #494c55;
        }
        .chevron {
          float: right;
          margin-right: 10px;
          width: 90px;
          text-align: right;
          .chevron-icon {
            padding-left: 5px;
          }
          img {
            height: 15px;
            width: auto;
            margin-bottom: 3px;
            margin-left: 5px;
            margin-right: 3px;
          }
        }
      `}
    >
      {children}
    </div>
  );
}
