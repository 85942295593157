import { Container } from "@pixi/react";
import * as PIXI from "pixi.js";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

function GridClone({ height, width, lineThickness, gridSize, onmousemove }) {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;

    const verticalLine = new PIXI.Graphics();
    const horizontalLine = new PIXI.Graphics();

    verticalLine.lineStyle(lineThickness, 0xaaaaaa, 1);
    verticalLine.moveTo(0, 0);
    verticalLine.lineTo(0, height);

    horizontalLine.lineStyle(lineThickness, 0xaaaaaa, 1);
    horizontalLine.moveTo(0, 0);
    horizontalLine.lineTo(width, 0);

    const backdrop = new PIXI.Graphics();
    backdrop.beginFill(0xffffff, 1);
    backdrop.drawRect(0, 0, width, height);
    backdrop.endFill();
    container.addChild(backdrop);

    // Calculate the number of rows and columns
    const rows = Math.ceil(height / gridSize);
    const cols = Math.ceil(width / gridSize);

    // Draw the grid
    for (let i = 0; i <= rows; i++) {
      const line = horizontalLine.clone();
      line.y = i * gridSize;
      container.addChild(line);
    }
    for (let j = 0; j <= cols; j++) {
      const line = verticalLine.clone();
      line.x = j * gridSize;
      container.addChild(line);
    }

    return () => {
      container.removeChildren();
    };
  }, [height, width, lineThickness, gridSize]);

  return <Container onmousemove={onmousemove} ref={containerRef} />;
}

GridClone.propTypes = {
  gridSize: PropTypes.number,
  height: PropTypes.number.isRequired,
  lineThickness: PropTypes.number,
  onmousemove: PropTypes.func,
  width: PropTypes.number.isRequired,
};

export default GridClone;
