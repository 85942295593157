import styled from "@emotion/styled";
import { faCheck, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PropTypes from "prop-types";
import { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { useClient } from "hooks/client";
import { useInspection, usePreviousClassifications } from "hooks/inspections";
import { useSite } from "hooks/site";

import PageWrapper from "views/components/common/PageWrapper";
import Loading from "views/components/Loading";
import InspectionRackSelect from "views/components/RackSelector";
import BlueprintButton from "views/pages/Inspection/components/buttons/button-blueprint";
import SortButton from "views/pages/Inspection/components/buttons/button-sort";
import Info from "views/pages/Inspection/components/Info";
import InspectionAreaSelect from "views/pages/Inspection/components/InspectionAreaSelect";
import InspectionWarehouseSelect from "views/pages/Inspection/components/InspectionWarehouseSelect";
import { sortClassifications } from "views/pages/Inspection/helpers";
import useInspectionView from "views/pages/Inspection/inspectionViewReducer";
import { FlexJustifyEnd, Wrapper } from "views/pages/Inspection/styles";

import CompleteButton from "./components/buttons/button-complete";
import Classification from "./components/classification";
import Section from "./components/section";

const NoDamageWrapper = styled.div`
  align-items: center;
  background-color: #eeeeee;
  border-radius: 5px;
  color: #666666;
  display: flex;
  flex-direction: column;
  height: 500px;
  margin-top: 50px;
  padding-top: 100px;
  text-align: center;
  width: 100%;
  img {
    height: 100px;
    width: 100px;
  }
`;

/**
 * Conversation about endpoints for data loading
 * https://econform.slack.com/archives/CD46MQW8M/p1677061767076179?thread_ts=1677014956.596709&cid=CD46MQW8M
 */
export default function PageCompleteInspection({ InspectionId }) {
  // Data
  const { inspection, isLoading } = useInspection(InspectionId);
  const { previousClassifications, isLoading: isLoadingPrevious } =
    usePreviousClassifications(inspection?.SiteId, InspectionId);
  const { site } = useSite(inspection?.SiteId);
  const { client } = useClient(site?.ClientId);

  // View Reducer hook
  const {
    warehouseId,
    areaId,
    rackId,
    setWarehouseId,
    setAreaId,
    setRackId,
    selectArea,
  } = useInspectionView(inspection);

  const [isDescending, setIsDescending] = useState(false);

  if (isLoading) {
    return <Loading />;
  }

  const warehouse = inspection?.warehouses?.find((w) => w.id === warehouseId);
  const areas = warehouse?.areas;
  const rack = warehouse?.racks?.find((r) => r.id === rackId);

  const reclassifiedClassifications =
    inspection?.reclassifiedClassifications ?? [];
  const reclassifiedClassificationAddedIds = reclassifiedClassifications.map(
    (r) => r.ReclassificationId,
  );

  // Reclassifications have a ReclassificationId that points to the id of the
  // added classification. The added classification is the actual "reclassification"
  // Note: we use RackId and not id here as the rack is an archival rack.
  const addedClassificationsForRack =
    inspection?.classifications
      .filter((c) => c.RackId === rack?.RackId)
      .filter((c) => !reclassifiedClassificationAddedIds.includes(c.id))
      .sort((a, b) => sortClassifications(a, b, isDescending)) ?? [];

  const previousClassificationsForRack =
    previousClassifications
      ?.filter((c) => c.RackId === rack?.RackId)
      .sort((a, b) => sortClassifications(a, b, isDescending)) ?? [];

  const reclassifiedClassificationsForRack =
    inspection?.classifications
      .filter((c) => c.RackId === rack?.RackId)
      .filter((c) => reclassifiedClassificationAddedIds.includes(c.id))
      .sort((a, b) => sortClassifications(a, b, isDescending)) ?? [];
  return (
    <>
      <Helmet>
        <title>{`Econform / ${client?.name} / ${site?.name} / ${inspection?.type} Inspection Ref. ${inspection?.reference}`}</title>
      </Helmet>
      <PageWrapper>
        <Wrapper>
          <Row>
            <Col sm={8}>
              <Info
                reference={inspection?.reference}
                siteId={inspection?.SiteId}
              />
              <InspectionWarehouseSelect
                onChange={setWarehouseId}
                selectedWarehouseId={warehouseId}
                warehouses={inspection?.warehouses}
              />
              <InspectionAreaSelect
                areas={areas}
                onChange={(id) =>
                  id === "All Areas" ? setAreaId(null) : setAreaId(id)
                }
                selectedAreaId={areaId}
              />
            </Col>

            <Col sm={4}>
              <FlexJustifyEnd>
                <SortButton
                  isDescending={isDescending}
                  onClick={(direction) => setIsDescending(direction)}
                />
                <BlueprintButton
                  inspectionId={InspectionId}
                  siteId={inspection?.SiteId}
                  warehouseId={warehouseId}
                />
                <CompleteButton InspectionId={InspectionId} />
              </FlexJustifyEnd>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <InspectionRackSelect
                areas={areas}
                isCompleted={inspection.completedAt}
                onClickArea={selectArea}
                onClickRack={setRackId}
                racks={
                  inspection?.warehouses.find((w) => w.id === warehouseId)
                    ?.racks
                }
                selectedAreaId={areaId}
                selectedRackId={rackId}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {isLoadingPrevious ? null : (
                <>
                  <Section
                    icon={<FontAwesomeIcon icon={faCheck} />}
                    title="Confirmed Damage"
                    total={previousClassificationsForRack.length}
                  >
                    {previousClassificationsForRack.map((c) => (
                      <Classification
                        InspectionId={InspectionId}
                        classification={c}
                        key={c.id}
                      />
                    ))}
                  </Section>
                  <Section
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    title="Added Damage"
                    total={addedClassificationsForRack.length}
                  >
                    {addedClassificationsForRack.map((c) => (
                      <Classification
                        InspectionId={InspectionId}
                        classification={c}
                        key={c.id}
                      />
                    ))}
                  </Section>
                  <Section
                    icon={<FontAwesomeIcon icon={faMinus} />}
                    title="Reclassified Damage"
                    total={reclassifiedClassificationsForRack.length}
                  >
                    {reclassifiedClassificationsForRack.map((c) => (
                      <Classification
                        InspectionId={InspectionId}
                        classification={c}
                        key={c.id}
                      />
                    ))}
                  </Section>

                  {addedClassificationsForRack.length === 0 &&
                    previousClassificationsForRack.length === 0 && (
                      <NoDamageWrapper>
                        <Image src="/img/misc/no-racks.png" />
                        <h4>No Damage Assigned</h4>
                        <p>
                          No damage was added to this Rack during this
                          inspection
                        </p>
                      </NoDamageWrapper>
                    )}
                </>
              )}
            </Col>
          </Row>
        </Wrapper>
      </PageWrapper>
    </>
  );
}

PageCompleteInspection.propTypes = {
  InspectionId: PropTypes.string,
};
