export function renderPadding(renderFunc, paddingAmount) {
  const padding = [];
  for (let i = 0; i < paddingAmount; i += 1) {
    padding.push(renderFunc());
  }
  return padding;
}

export function getActionsLink(ClientId, SiteId, isAllActions) {
  if (SiteId) {
    return `/clients/sites/${SiteId}/actions/${isAllActions ? 1 : ''}`;
  }

  if (ClientId) {
    return `/clients/${ClientId}/actions/${isAllActions ? 1 : ''}`;
  }

  if (isAllActions) {
    return '/actions/1';
  }

  return '/actions';
}

export function getInspectionsLink(ClientId, SiteId, isAllInspections) {
  if (SiteId) {
    return `/clients/sites/${SiteId}/inspections/${isAllInspections ? 1 : ''}`;
  }

  if (ClientId) {
    return `/clients/${ClientId}/inspections/${isAllInspections ? 1 : ''}`;
  }

  if (isAllInspections) {
    return '/inspections/1';
  }

  return '/inspections';
}

export function getSchedulesLink(ClientId, SiteId, isAllSchedules) {
  if (SiteId) {
    return `/clients/sites/${SiteId}/schedules/2`;
  }

  if (ClientId) {
    return `/clients/${ClientId}/schedules/2`;
  }

  if (isAllSchedules) {
    return '/schedules/1';
  }

  return '/schedules';
}
