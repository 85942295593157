/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function getActions(filters) {
  const res = await apiCall(`/action/new${filterUrlGenerator(filters)}`);
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting actions: ${res.status}`);
  return false;
}

export async function resolveAction(
  ClassificationId,
  cantResolve = false,
  actionNote = null,
) {
  const res = await apiCall("/action/resolve", {
    method: "PUT",
    body: JSON.stringify({
      ClassificationId,
      cantResolve,
      actionNote,
    }),
  });
  return res.ok;
}

export async function unresolveAction(ClassificationId) {
  const res = await apiCall("/action/unresolve", {
    method: "PUT",
    body: JSON.stringify({
      ClassificationId,
    }),
  });
  return res.ok;
}

export async function assignUserToInspectionActions(
  InspectionId,
  AssigneeId,
  dueAt,
  photoEvidenceRequired,
) {
  const res = await apiCall("/action/assign", {
    method: "PUT",
    body: JSON.stringify({
      InspectionId,
      AssigneeId,
      dueAt,
      photoEvidenceRequired,
    }),
  });
  return res.ok;
}

export async function getInspectionActions(InspectionId, filters) {
  const res = await apiCall(
    `/action/inspection/${InspectionId}${filterUrlGenerator(filters)}`,
  );
  if (res.status === 200) {
    return res.json();
  }
  throw new Error(`Problem getting inspection actions: ${res.status}`);
}

export async function getInspectionProgress(InspectionId) {
  const res = await apiCall(`/action/inspection/progress/${InspectionId}`);
  if (res.status === 200) {
    return res.json();
  }
  throw new Error(`Problem getting inspection progress: ${res.status}`);
}

export async function getActionShareURL(InspectionId, options = false) {
  const res = await apiCall(
    `/action/shareURL/${InspectionId}${filterUrlGenerator(options)}`,
  );
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting share url: ${res.status}`);
  return false;
}

export async function getActionStats(filters) {
  const res = await apiCall(`/action/stats${filterUrlGenerator(filters)}`);
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting actions stats: ${res.status}`);
  return false;
}

export async function getActionPDF(InspectionId) {
  return apiCall(`/action/report/${InspectionId}`);
}
