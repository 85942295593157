import PropTypes from 'prop-types';

import { userRequestPassword, userCheckResetCode } from 'libs/api';

export default function EnterCode({
  email,
  error,
  passwordResetCode,
  setPasswordResetCode,
  setError,
  setLoading,
  setMode,
}) {
  return (
    <div className="form-group">
      <div className="form-description">
        Check your email for your password recovery code and enter it below.
      </div>
      <input
        className="form-control"
        key="code"
        onChange={(e) => setPasswordResetCode(e.target.value)}
        placeholder="Enter your code"
        type="text"
      />
      <div>
        <p className="error-message">{error}</p>
      </div>
      <div style={{ display: 'flex', marginTop: 40 }}>
        {error && (
          <div
            className="flex items-center justify-between"
            style={{
              flex: 5,
              marginRight: 10,
            }}
          >
            <button
              className="btn btn-primary btn-block"
              onClick={async () => {
                setLoading('request-password');
                const res = await userRequestPassword(email);
                if (res.ok) {
                  setError('');
                  setPasswordResetCode('');
                } else {
                  const body = await res.json();
                  setError(body.error);
                }
              }}
              type="button"
            >
              Resend Code
            </button>
          </div>
        )}
        <div
          className="flex items-center justify-between"
          style={{
            flex: 5,
            marginLeft: error ? 10 : 0,
          }}
        >
          <button
            className="btn btn-primary btn-block"
            disabled={!passwordResetCode || !passwordResetCode.match(/^\d{6}$/)}
            onClick={async () => {
              setLoading('check-code');
              const res = await userCheckResetCode(email, passwordResetCode);
              if (res.ok) {
                setMode('newPassword');
              } else {
                const body = await res.json();
                setError(body.error);
              }
            }}
            type="button"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

EnterCode.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  passwordResetCode: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  setPasswordResetCode: PropTypes.func.isRequired,
};
