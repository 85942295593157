import styled from "@emotion/styled";
import { Image, Button } from "react-bootstrap";

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  background-color: #000;
  color: #fff;
  padding: 10px;
  max-width: 100%;
  width: 66%;
`;

export const UnderImageControls = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DisplayImage = styled(Image)`
  display: block;
  height: auto;
  margin: 0 auto 10px;
  max-height: 70vh;
  max-width: 100%;
  width: auto;
`;

export const ImageControls = styled.div`
  flex-grow: 1;
`;

export const DeleteButton = styled(Button)`
  background-color: #ad2b2b;
`;
