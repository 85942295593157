import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import { CompatRouter } from 'react-router-dom'; // compat

import { getSentryEnvironment } from "libs/sentry";

import App from "./App";
import { unregister } from "./registerServiceWorker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./App.css";

if (process.env.NODE_ENV === "production") {
  const dsn = process.env.REACT_APP_SENTRY_DSN;
  const environment = getSentryEnvironment();
  const isPR = environment === "preview";
  const release = isPR
    ? `econform-web@${process.env.REACT_APP_VERSION}-pr-${process.env.RENDER_GIT_BRANCH}`
    : `econform-web@${process.env.REACT_APP_VERSION}`;
  const tags = isPR ? { preview: process.env.RENDER_GIT_BRANCH } : {};

  Sentry.init({
    dsn,
    integrations: [new Integrations.BrowserTracing()],
    release,
    tracesSampleRate: 0.1,
    environment,
    tags,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

unregister();
