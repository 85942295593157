import PropTypes from "prop-types";
import { useState } from "react";
import { Panel, ListGroup, ListGroupItem } from "react-bootstrap";

import BayFilters from "./BayFilters";
import BayTemplate from "./BayTemplate";

import { filterBayTemplates } from "./helpers";

export default function BayTypes({
  bayTemplates = [],
  canEdit,
  manufacturers = [],
  onSelectBayTemplate,
  showFilters,
}) {
  const [filterOptions, setFilterOptions] = useState({
    searchText: "",
    ManufacturerId: null,
    minDepth: null,
    minPitch: null,
    minWidth: null,
    minBeams: null,
    maxDepth: null,
    maxPitch: null,
    maxWidth: null,
    maxBeams: null,
    numberOfLevels: null,
  });

  const filteredBayTemplates = filterBayTemplates(bayTemplates, filterOptions);
  return (
    <Panel>
      <Panel.Body className="no-padding-panel">
        {showFilters && (
          <BayFilters
            bayTemplatesFilterOptions={filterOptions}
            manufacturers={manufacturers}
            setFilterOption={(key, value) =>
              setFilterOptions({
                ...filterOptions,
                [key]: value,
              })
            }
          />
        )}
        <ListGroup className="scroll-bar-contents">
          {filteredBayTemplates.length > 0 ? (
            filteredBayTemplates.map((bt) => (
              <BayTemplate
                bayTemplate={bt}
                canEdit={canEdit}
                key={bt.id}
                manufacturer={manufacturers.find(
                  (m) => m.id === bt.ManufacturerId,
                )}
                onSelectBayTemplate={onSelectBayTemplate}
              />
            ))
          ) : (
            <ListGroupItem>
              <div>No Bay Types Found</div>
            </ListGroupItem>
          )}
        </ListGroup>
      </Panel.Body>
    </Panel>
  );
}

BayTypes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bayTemplates: PropTypes.array,
  canEdit: PropTypes.bool,
  manufacturers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onSelectBayTemplate: PropTypes.func,
  showFilters: PropTypes.bool,
};
