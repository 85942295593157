import { useCallback, useMemo, useState } from "react";
import { Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useClient } from "hooks/client";
import { useClientInstallerOrganisations } from "hooks/installer-organisations";
import { editClient } from "libs/api";
import ButtonWide from "views/components/Buttons/button-wide";
import LinkButtonWide from "views/components/Buttons/link-button-wide";
import PageWrapper from "views/components/common/PageWrapper";

import TableHeader from "views/components/Tables/TableHeader";

import DeleteButtonWithModal from "views/pages/ClientsEditor/components/delete-button-w-modal";

import OrganisationChange from "views/pages/ClientsEditor/components/organisation-change";

import ClientAddress from "./components/client-address";
import ClientContactDetails from "./components/client-contact-details";
import ClientDetails from "./components/client-details";

import InstallerOrganisations from "./components/installer-organisations";
import SiteGroups from "./components/site-groups";
import { ControlsWrapper } from "./styles";

export default function PageEditClient() {
  const { ClientId } = useParams();
  const { client, mutate: refreshClient } = useClient(ClientId);
  const { clientInstallerOrganisations } =
    useClientInstallerOrganisations(ClientId);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const [clientEditedFields, setClientEditedFields] = useState({});
  const clientFields = useMemo(
    () => ({ ...client, ...clientEditedFields }),
    [client, clientEditedFields],
  );

  const handleFieldChange = useCallback(
    (field, value) => {
      setClientEditedFields((curr) => ({
        ...curr,
        [field]: value,
      }));
    },
    [setClientEditedFields],
  );

  const handleSave = useCallback(async () => {
    try {
      const {
        id,
        name,
        addressLine1,
        addressLine2,
        city,
        country,
        postcode,
        clientEmail,
        clientNumber,
        contactEmail,
        contactName,
        contactNumber,
        logoUrl,
        notes,
        OrganisationId,
      } = clientFields;

      const editedClient = await editClient({
        id,
        name,
        addressLine1,
        addressLine2,
        city,
        country,
        postcode,
        clientEmail,
        clientNumber,
        contactEmail,
        contactName,
        contactNumber,
        logoUrl,
        notes,
        OrganisationId,
      });

      refreshClient(editedClient);
      toast(`Edited client: ${editedClient.name}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        type: toast.TYPE.SUCCESS,
        autoClose: true,
        pauseOnHover: true,
      });
      navigate(`/clients/${editedClient.id}/overview`);
    } catch (error) {
      setError(error.message);
    }
  }, [clientFields, navigate, refreshClient]);

  const isEditDisabled =
    !clientFields.name ||
    !clientFields.clientNumber ||
    !clientFields.clientEmail ||
    !clientFields.contactName ||
    !clientFields.contactNumber ||
    !clientFields.contactEmail ||
    !clientFields.addressLine1 ||
    !clientFields.city ||
    !clientFields.postcode;

  const showInstallerOrganisations =
    client &&
    client.permission &&
    (client.permission.level === "clientAdmin" ||
      client.permission.level === "organisationAdmin" ||
      client.permission.level.includes("Organisation"));

  const showOrganisationChange = true;
  // client.permission &&
  // (client.permission.level === "clientAdmin" ||
  // client.permission.level.includes("Organisation"));
  if (!client) return null;
  return (
    <PageWrapper>
      <Helmet>
        <title>Econform / Clients / Edit</title>
      </Helmet>
      <TableHeader left="Edit Client" />

      <ClientDetails client={clientFields} onChangeField={handleFieldChange} />

      {showOrganisationChange ? (
        <OrganisationChange
          OrganisationId={clientFields.OrganisationId}
          isNotVerified={
            (client.NewOrganisationId &&
              client.NewOrganisationId !== client.OrganisationId) ||
            client.OrganisationId !== clientFields.OrganisationId
          }
          onChange={(val) => handleFieldChange("OrganisationId", val)}
        />
      ) : null}
      {showInstallerOrganisations ? (
        <InstallerOrganisations
          clientInstallerOrganisations={clientInstallerOrganisations}
          onChangeOrganisations={(orgs) =>
            handleFieldChange("installerOrganisations", orgs)
          }
        />
      ) : null}

      {client?.hasAdminPermission ? <SiteGroups ClientId={ClientId} /> : null}

      <ClientContactDetails
        client={clientFields}
        onChangeField={handleFieldChange}
      />
      <ClientAddress client={clientFields} onChangeField={handleFieldChange} />

      <span className="required">* Mandatory Fields</span>
      <ControlsWrapper>
        {client?.hasDeletePermission ? (
          <DeleteButtonWithModal clientId={ClientId} />
        ) : null}
        <div>
          <LinkButtonWide to={`/clients/${ClientId}/overview`}>
            Cancel
          </LinkButtonWide>
          <ButtonWide disabled={isEditDisabled} onClick={handleSave} primary>
            Save
          </ButtonWide>
        </div>
      </ControlsWrapper>

      {error ? <Alert bsStyle="danger">{error}</Alert> : null}
    </PageWrapper>
  );
}
