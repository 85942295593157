/* eslint-disable react/jsx-props-no-spreading */
// https://codepen.io/inlet/pen/yLVmPWv
import { PixiComponent, useApp, applyDefaultProps } from "@pixi/react";
import { Viewport as PixiViewport } from "pixi-viewport";
// import * as PIXI from "pixi.js";
import { forwardRef } from "react";
import { ErrorBoundary } from "react-error-boundary";

// create and instantiate the viewport component
// we share the ticker and interaction from app
const PixiViewportComponent = PixiComponent("PIXIViewport", {
  create: (props) => {
    const {
      worldHeight,
      worldWidth,
      screenHeight,
      screenWidth,
      app,
      ...viewportProps
    } = props;

    const { events } = app.renderer;

    const viewport = new PixiViewport({
      screenHeight,
      screenWidth,
      worldHeight,
      worldWidth,
      passiveWheel: false,
      events,
      ...viewportProps,
    });

    // Activate plugins
    viewport.drag().pinch().wheel().decelerate();

    // Set max scale, so we don't get pixilated text
    viewport.clampZoom({
      maxScale: 1,
    });

    // Used to handle the grid scale
    viewport.on("zoomed-end", (e) => {
      if (props?.onZoomedEnd) {
        props?.onZoomedEnd(e);
      }
    });
    return viewport;
  },

  applyProps(viewport, oldProps, newProps) {
    applyDefaultProps(viewport, oldProps, newProps);

    if (oldProps.drag !== newProps.drag && !newProps.drag) {
      viewport.plugins.pause("drag");
      viewport.plugins.pause("wheel");
      viewport.plugins.pause("pinch");
    } else if (oldProps.drag !== newProps.drag) {
      viewport.plugins.resume("drag");
      viewport.plugins.resume("wheel");
      viewport.plugins.resume("pinch");
    }
  },
  willUnmount(instance) {
    // workaround because the ticker is already destroyed by this point by the stage
    // https://github.com/davidfig/pixi-viewport/issues/438
    // instance.options.noTicker = true;
    instance.plugins.removeAll();
    instance.destroy({ children: true, texture: true, baseTexture: true });
  },
});

export default forwardRef(function ViewportWrapper(props, ref) {
  const app = useApp();
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <PixiViewportComponent app={app} ref={ref} {...props} />
    </ErrorBoundary>
  );
});
