import { Text } from "@pixi/react";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { SCALE_FACTOR } from "hooks/use-site-blueprint";
import formatBayLocation from "libs/formatBayLocation";
const textStyle = {
  align: "center",
  fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
  fontSize: 180 * SCALE_FACTOR,
  fontWeight: 800,
  fill: 0xffffff,
};

/**
 * Display the bay location formatted correctly in the center of the bay
 */
function BayLabel({ location, bayWidth, bayDepth }) {
  const text = useMemo(() => formatBayLocation(location), [location]);
  return (
    <Text
      anchor={0.5}
      eventMode="auto"
      isSprite
      style={textStyle}
      text={text}
      x={bayWidth / 2}
      y={bayDepth / 2}
    />
  );
}

export default React.memo(BayLabel);

BayLabel.propTypes = {
  bayDepth: PropTypes.number.isRequired,
  bayWidth: PropTypes.number.isRequired,
  location: PropTypes.number.isRequired,
};
