import useSWR from 'swr';

import { getBayTemplates, getBayTemplatesDepths } from 'libs/api';

export function useBayTemplates(siteId) {
  const { data, error, mutate, isLoading } = useSWR(
    siteId ? `/template/bays/${siteId}` : null,
    async () => getBayTemplates(siteId),
  );

  return {
    bayTemplates: data,
    isLoading,
    isError: error,
    mutate,
  };
}

export function useBayTemplatesDepths(ManufacturerId, SiteId, depth) {
  const { data, error, isLoading } = useSWR(
    ManufacturerId && SiteId && depth
      ? `/template/bay/manufacturer/${ManufacturerId}/${SiteId}/${depth}`
      : null,
    async () => getBayTemplatesDepths(SiteId, ManufacturerId, depth),
  );

  return {
    bayTemplates: data ?? [],
    isError: error,
    isLoading,
  };
}
