/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function getOrganisation(OrganisationId) {
  const res = await apiCall(`/organisation/${OrganisationId}`);
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting organisation: ${res.status}`);
  return false;
}

export async function getOrganisations(filters) {
  const res = await apiCall(`/organisation${filterUrlGenerator(filters)}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem getting organisations: ${res.status}`);
}

export async function getInstallerOrganisations() {
  const res = await apiCall(`/organisation/installers/`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem getting installer organisations: ${res.status}`);
}

export async function getClientInstallerOrganisations(ClientId) {
  const res = await apiCall(`/organisation/installers/${ClientId}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(
    `Problem getting client installer organisations: ${res.status}`,
  );
}

export async function getInstallerClients(filters) {
  const res = await apiCall(
    `/organisation/installers/clients${filterUrlGenerator(filters)}`,
  );
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem getting installer clients: ${res.status}`);
}

export async function getInstallerOrgsById(OrganisationId) {
  const res = await apiCall(
    `/organisation/installers/organisations/${OrganisationId}`,
  );
  if (res.ok) {
    return res.json();
  }
  throw new Error(
    `Problem getting installer organisations by OrganisationId: ${res.status}`,
  );
}

export async function verifyClientOrganisation(
  ClientId,
  NewOrganisationId,
  NewOrganisationVerifyCode,
) {
  const res = await apiCall("/organisation/verify", {
    method: "POST",
    body: JSON.stringify({
      ClientId,
      NewOrganisationId,
      NewOrganisationVerifyCode,
    }),
  });

  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem verifying client organisation: ${res.status}`);
}

export async function rejectVerifyClientOrganisation(
  ClientId,
  NewOrganisationId,
  NewOrganisationVerifyCode,
) {
  const res = await apiCall("/organisation/verify", {
    method: "DELETE",
    body: JSON.stringify({
      ClientId,
      NewOrganisationId,
      NewOrganisationVerifyCode,
    }),
  });

  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem rejecting client organisation: ${res.status}`);
}
