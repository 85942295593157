import { useMemo } from "react";
import useSWR from "swr";

import {
  getAllClassificationComponents,
  getClassificationFiles,
  getClassifySuggestion,
  getComponentTypes,
  getComponents,
} from "../libs/api";

export function useComponentTypes(filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    [`/classify/componentType`, filters],
    async () => getComponentTypes(filters),
  );

  return {
    componentTypes: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useComponents(type, filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    type ? [`/classify/component/${type}`, filters] : null,
    async () => getComponents(type, filters),
  );

  return {
    components: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useAllComponentClassifications() {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    "/classify/all",
    getAllClassificationComponents,
  );

  return {
    componentClassifications: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useClassificationImages(id) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    id ? `/classify/file/${id}` : null,
    async () => getClassificationFiles(id),
  );

  const sortedImages = useMemo(
    () => data?.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)),
    [data],
  );

  return {
    images: sortedImages ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useClassificationSuggestions(type, componentType, searchText) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    type && componentType
      ? [
          `/classify/suggest/${type}/${componentType}/${searchText}`,
          type,
          componentType,
          searchText,
        ]
      : null,

    async () => getClassifySuggestion(type, { componentType, searchText }),
  );
  const defaultLevels = [
    {
      label: "N/A",
      level: "N/A",
    },
  ];
  return {
    suggestions: type === "level" ? defaultLevels : data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}
