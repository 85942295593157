import moment from "moment";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { Button, ButtonGroup, Col, Row } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";

import {
  getSchedule,
  getSites,
  editSchedule,
  createSchedule,
  getClients,
  getSite,
  getClient,
  getUser,
} from "libs/api";
import { getNextScheduleDate } from "libs/schedule";

import ControlClient from "./ControlClient";
import ControlDates from "./ControlDates";
import ControlRepeatSchedule from "./ControlRepeatSchedule";
import ControlSites from "./ControlSites";
import ControlTime from "./ControlTime";
import ControlUser from "./ControlUser";
import { ScheduleEditorWrapper } from "./styles";

// Amount is the page-size for API calls
const amount = 100;

export default function ScheduleEditor({
  // These control the granularity of what can be Loaded i.e. if the form is
  // partially controlled as to what can be set.
  ScheduleId,
  SiteId: controlledSiteId,
  ClientId: controlledClientId,
  clientSelectorDisabled,
  siteSelectorDisabled,
  onHide,
  onSaveSuccessful,
  Client,
}) {
  const [AssigneeId, setAssigneeId] = useState("self");
  const [startAt, setStartAt] = useState(
    moment(`${moment().format("YYYY-MM-DD")} 09:00`).toDate(),
  );
  const [type, setType] = useState("daily");
  const [user, setUser] = useState(null);
  const [assignee, setAssignee] = useState(null);

  // Holds the potential values for clients and sites
  const [clientOptions, setClientOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);

  // Holds the selected client and site
  const [client, setClient] = useState(Client);
  const [site, setSite] = useState(null);

  const groupOptions = [{ label: "PRRS User Type", value: "PRRS" }];

  // Only a SARI user should be able to assign a SARI user
  if (user && user.userType === "SARI") {
    groupOptions.push({ label: "SARI User Type", value: "SARI" });
  }

  // Local state that we can over-write, synchronised with props
  // TODO: Is this always controlled? Can we do away with this sync?
  const [ClientId, setClientId] = useState(controlledClientId);
  const [SiteId, setSiteId] = useState(controlledSiteId);

  useEffect(() => {
    setClientId(controlledClientId);
    setSiteId(controlledSiteId);
  }, [controlledClientId, controlledSiteId]);

  /**
   * ScheduleId Sync
   *
   * If no schedule, present client options
   * If we do have a schedule, update the full info
   */
  useEffect(() => {
    async function getScheduleData() {
      const schedule = await getSchedule(ScheduleId);
      setSiteId(schedule.SiteId);
      setAssigneeId(schedule.AssigneeId);
      setAssignee(schedule.Assignee);
      setType(schedule.type);
      setStartAt(schedule.currentScheduledAt || schedule.nextScheduledAt);
    }

    async function getClientsAndUserData() {
      // TODO: Why is getUser a fetch result, but the other get utils are
      // the final objects?
      const resClients = await getClients({ amount });
      const resUser = await getUser();

      if (resUser.ok) {
        setUser(await resUser.json());
        setClientOptions(resClients?.results || []);
      }
    }

    if (ScheduleId) {
      getScheduleData();
    } else {
      getClientsAndUserData();
    }
  }, [ScheduleId]);

  /**
   * Sync Site with SiteId
   */
  useEffect(() => {
    async function getData() {
      const newSite = await getSite(SiteId);
      setSite(newSite);
      setClientId(newSite.ClientId);
    }
    if (SiteId) {
      getData();
    } else {
      setSite(null);
    }
  }, [SiteId]);

  /**
   * Sync Client and SiteOptions with provided ClientId
   */
  useEffect(() => {
    async function getData() {
      const [resClient, { results: resSites }] = await Promise.all([
        getClient(ClientId),
        getSites({ ClientId, amount }),
      ]);
      setClient(resClient);
      setSiteOptions(resSites);
    }
    if (ClientId) {
      getData();
    } else {
      setClient(null);
    }
  }, [ClientId]);

  return (
    <ScheduleEditorWrapper>
      <ControlClient
        clearClient={
          !Client
            ? () => {
                setClientId(null);
                setSiteId(null);
              }
            : null
        }
        client={client}
        clientId={ClientId}
        clientOptions={clientOptions}
        disabled={clientSelectorDisabled}
        onChangeClient={(id) => {
          setClientId(id);
        }}
        scheduleId={ScheduleId}
      />
      <ControlSites
        clientId={ClientId}
        disabled={siteSelectorDisabled}
        scheduleId={ScheduleId}
        selectSite={(id) => setSiteId(id)}
        site={site}
        siteId={SiteId}
        siteOptions={siteOptions}
      />
      {SiteId && (
        <ControlUser
          assignee={assignee}
          assigneeId={AssigneeId}
          filters={{ ClientId, SiteId }}
          groupOptions={groupOptions}
          onChangeUser={(newAssignee) => {
            setAssignee(newAssignee);
            setAssigneeId(null);
          }}
          onClearUser={() => setAssigneeId(null)}
          user={user}
        />
      )}
      <ControlRepeatSchedule
        onSetType={(t) => {
          const date = moment(startAt);
          const day = date.day();
          if (day === 0) {
            setStartAt(date.add(1, "days"));
          } else if (day === 6) {
            setStartAt(date.add(2, "days"));
          }
          setType(t);
        }}
        type={type}
      />
      <ControlDates
        onChange={(updatedStartAt) => setStartAt(updatedStartAt)}
        selectedDue={getNextScheduleDate(type, startAt)}
        startAt={startAt}
        weekdayOnly={type === "weekdaily"}
      />
      <ControlTime
        onChange={(updatedStartAt) => setStartAt(updatedStartAt)}
        startAt={startAt}
      />
      <Row sm={12}>
        <Col sm={12}>
          <ButtonGroup>
            <Button className="bottom-btn cancel" onClick={onHide}>
              Cancel
            </Button>
            <Button
              className="bottom-btn"
              disabled={!SiteId || !ClientId || (!assignee && !AssigneeId)}
              onClick={async () => {
                const saveFunction = ScheduleId ? editSchedule : createSchedule;
                try {
                  const saved = await saveFunction({
                    id: ScheduleId,
                    AssigneeId:
                      (assignee && assignee.id === "self") ||
                      (!assignee && AssigneeId === "self")
                        ? user.id
                        : assignee.id,
                    SiteId,
                    startAt,
                    type,
                  });
                  if (saved) {
                    onSaveSuccessful();
                  }
                } catch (err) {
                  // TODO: Error Handle
                }
              }}
            >
              {`${ScheduleId ? "Edit" : "Save"}`}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </ScheduleEditorWrapper>
  );
}

ScheduleEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  Client: PropTypes.object,
  ClientId: PropTypes.string,
  ScheduleId: PropTypes.string,
  SiteId: PropTypes.string,
  clientSelectorDisabled: PropTypes.bool,
  onHide: PropTypes.func,
  onSaveSuccessful: PropTypes.func,
  siteSelectorDisabled: PropTypes.bool,
};
