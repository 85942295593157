import PropTypes from 'prop-types';
import { FormControl, ControlLabel, Col, Form, FormGroup } from 'react-bootstrap';

export default function BayFilters({ bayTemplatesFilterOptions, manufacturers, setFilterOption }) {
  return (
    <div className="filters">
      <Form horizontal onKeyPress={(e) => (e.which === 13 ? e.preventDefault() : '')}>
        <FormGroup controlId="formHorizontalManufacturer">
          <Col sm={4}>
            <ControlLabel>Manufacturer</ControlLabel>
          </Col>
          <Col sm={8}>
            <FormControl
              componentClass="select"
              onChange={(e) => {
                setFilterOption('ManufacturerId', e.target.value);
              }}
              placeholder="Frame Template"
              value={bayTemplatesFilterOptions.ManufacturerId}
            >
              <option value="">Any</option>{' '}
              {manufacturers.map((manufacturer) => (
                <option key={manufacturer.id} value={manufacturer.id}>
                  {manufacturer.name}
                </option>
              ))}
            </FormControl>
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalWidth">
          <Col sm={4}>
            <ControlLabel>Width</ControlLabel>
          </Col>
          <Col sm={4}>
            <FormControl
              min="0"
              onChange={(e) => {
                setFilterOption('minWidth', parseInt(e.target.value, 10));
              }}
              placeholder="Min"
              type="number"
              value={bayTemplatesFilterOptions.minWidth}
            />
          </Col>
          <Col sm={4}>
            <FormControl
              min="0"
              onChange={(e) => {
                setFilterOption('maxWidth', parseInt(e.target.value, 10));
              }}
              placeholder="Max"
              type="number"
              value={bayTemplatesFilterOptions.maxWidth}
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalWidth">
          <Col sm={4}>
            <ControlLabel>Levels</ControlLabel>
          </Col>
          <Col sm={4}>
            <FormControl
              min="0"
              onChange={(e) => {
                setFilterOption('numberOfLevels', parseInt(e.target.value, 10));
              }}
              placeholder="Levels"
              type="number"
              value={bayTemplatesFilterOptions.minBays}
            />
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
}

BayFilters.propTypes = {
  bayTemplatesFilterOptions: PropTypes.shape({
    numberOfLevels: PropTypes.number,
    minBays: PropTypes.number,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    ManufacturerId: PropTypes.string,
  }),
  manufacturers: PropTypes.arrayOf(PropTypes.shape({})),
  setFilterOption: PropTypes.func.isRequired,
};
