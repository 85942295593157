/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState } from "react";
import { Col, Panel, FormGroup, ControlLabel, Checkbox } from "react-bootstrap";

import EditorPanel from "views/components/EditorPanel";

import { USER_PERMISSION_TYPES } from "./config";
import OrganisationPermissionSelector from "./OrganisationPermissionsSelector";
import OrganisationSelector from "./OrganisationSelector";
import PermissionChanges from "./PermissionChanges";
import { RoleName, RoleDescription } from "./styles";

function OrganisationPermissions({
  addedPermissions,
  clients,
  curUser,
  deletedPermissions,
  clearPermissions,
  loading,
  onAddPermissions,
  onChangeClient,
  onChangeSearchText,
  onClearClient,
  onDeletePermissions,
  organisation,
  OrganisationId,
  organisations,
  permissions,
  searchText,
  user,
  userPermissionType,
  isInstallerUser,
  isInstallerAdmin,
  onUpdateInstallerUserField,
}) {
  const isOrganisationAdmin =
    userPermissionType === USER_PERMISSION_TYPES.ORGADMIN;
  const isOrganisationUser =
    userPermissionType === USER_PERMISSION_TYPES.ORGUSER;
  const isClientOrganisationUser =
    userPermissionType === USER_PERMISSION_TYPES.CLIENTORGUSER;
  const isInstallerAdminUser =
    userPermissionType === USER_PERMISSION_TYPES.INSTALLERADMIN;
  let organisationSelection = organisations;
  organisationSelection = isInstallerAdmin
      ? organisations.filter((org) => org.isInstaller)
      : organisations.filter((org) => !org.isInstaller); // prettier-ignore
  // Show we show the client selector even if we don't have a type specified
  // i.e. if we want to select clients for client org user, but haven't yet done so.
  const [showOverride, setShowOverride] = useState(false);

  if (!user) {
    return null;
  }
  return (
    <EditorPanel>
      <Panel.Heading>Organisation Permissions</Panel.Heading>
      <Panel.Body>
        {!loading && (
          <Col sm={12}>
            {permissions ? (
              <OrganisationSelector
                disabled={addedPermissions.length || deletedPermissions.length}
                isInstallerAdmin={isInstallerAdmin}
                onChangeClient={onChangeClient}
                onChangeSearchText={onChangeSearchText}
                onClearClient={onClearClient}
                organisationId={
                  isInstallerAdmin
                    ? organisation?.installerOrgId
                    : organisation?.id
                }
                organisations={organisationSelection}
                searchText={searchText}
              />
            ) : null}
          </Col>
        )}
        {organisation &&
          !organisation.isInstaller &&
          !isInstallerUser &&
          !isInstallerAdmin && (
            <Col sm={12}>
              <Col sm={6}>
                <ControlLabel>
                  Organisation Permissions
                  <span className="required">*</span>
                </ControlLabel>
                <Checkbox
                  checked={isOrganisationAdmin}
                  disabled={
                    !user.canEditUser &&
                    !curUser.permissions.organisationAdmin?.includes(
                      OrganisationId,
                    ) &&
                    !curUser.isSuperAdmin &&
                    !isClientOrganisationUser
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      onDeletePermissions(user.id, "organisation", [
                        organisation.id,
                      ]);
                      onDeletePermissions(user.id, 'clientOrganisation', clients.map((client) => client.id)); // prettier-ignore
                      onAddPermissions(user.id, "organisationAdmin", [
                        organisation.id,
                      ]);
                      setShowOverride(false);
                    } else {
                      onDeletePermissions(user.id, "organisationAdmin", [
                        organisation.id,
                      ]);
                    }
                  }}
                >
                  <RoleName>Org Admin</RoleName>
                  <RoleDescription>
                    Can edit the organisation, and add users to the
                    organisation.
                  </RoleDescription>
                </Checkbox>
                <Checkbox
                  checked={isOrganisationUser}
                  disabled={
                    !user.canEditUser &&
                    !curUser.permissions.organisationAdmin?.includes(
                      OrganisationId,
                    ) &&
                    !curUser.isSuperAdmin &&
                    !isClientOrganisationUser
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      onDeletePermissions(user.id, "organisationAdmin", [
                        organisation.id,
                      ]);
                      onDeletePermissions(user.id, 'clientOrganisation', clients.map((client) => client.id)); // prettier-ignore
                      onAddPermissions(user.id, "organisation", [
                        organisation.id,
                      ]);
                      setShowOverride(false);
                    } else {
                      onDeletePermissions(user.id, "organisation", [
                        organisation.id,
                      ]);
                    }
                  }}
                >
                  <RoleName>Org Management</RoleName>
                  <RoleDescription>
                    Can create and modify any inspection created for any client
                    owned by this organisation.
                  </RoleDescription>
                </Checkbox>
                <Checkbox
                  checked={isClientOrganisationUser || showOverride}
                  disabled={!clients.length}
                  onChange={(e) => {
                    setShowOverride(e.target.checked);
                    onDeletePermissions(user.id, "organisation", [
                      organisation.id,
                    ]);
                    onDeletePermissions(user.id, "organisationAdmin", [
                      organisation.id,
                    ]);
                    if (isClientOrganisationUser) {
                      onDeletePermissions(user.id, 'clientOrganisation', clients.map((client) => client.id)); // prettier-ignore
                    }
                  }}
                >
                  <RoleName>Org User</RoleName>
                  <RoleDescription>
                    Can create and modify any inspection created for specified
                    client owned by this organisation.
                  </RoleDescription>
                </Checkbox>
              </Col>
              <Col sm={6}>
                {userPermissionType !== USER_PERMISSION_TYPES.NONE ||
                showOverride ? (
                  <FormGroup controlId="formControlsSelectMultiple">
                    <ControlLabel>Individual Clients</ControlLabel>
                    <OrganisationPermissionSelector
                      allSelectedByDefault={
                        isOrganisationAdmin || isOrganisationUser
                      }
                      clientPermissionGrantedIds={
                        permissions?.clientOrganisation
                      }
                      clients={clients}
                      onAddPermissions={onAddPermissions}
                      onDeletePermissions={onDeletePermissions}
                      userId={user?.id}
                    />
                  </FormGroup>
                ) : null}
              </Col>
            </Col>
          )}
        {organisation && isInstallerUser && (
          <Col sm={12}>
            <Col sm={6}>
              <ControlLabel>
                Organisation Permissions
                <span className="required">*</span>
              </ControlLabel>
              <Checkbox
                checked={isInstallerUser}
                disabled={!clients.length}
                onChange={(e) => {
                  setShowOverride(e.target.checked);
                  onDeletePermissions(user.id, "organisation", [
                    organisation.id,
                  ]);
                  onDeletePermissions(user.id, "organisationAdmin", [
                    organisation.id,
                  ]);
                  if (isClientOrganisationUser) {
                    onDeletePermissions(user.id, 'clientOrganisation', clients.map((client) => client.id)); // prettier-ignore
                  }
                }}
              >
                <RoleName>Installer Client User</RoleName>
                <RoleDescription>
                  Can be assigned to actions within the client sites, carry out
                  repairs, sign off actions and download the action report.
                </RoleDescription>
              </Checkbox>
            </Col>
            <Col sm={6}>
              {isInstallerUser ||
              userPermissionType !== USER_PERMISSION_TYPES.NONE ||
              showOverride ? (
                <FormGroup controlId="formControlsSelectMultiple">
                  <ControlLabel>Individual Clients</ControlLabel>
                  <OrganisationPermissionSelector
                    allSelectedByDefault={
                      isOrganisationAdmin || isOrganisationUser
                    }
                    clientPermissionGrantedIds={permissions?.clientOrganisation}
                    clients={clients}
                    onAddPermissions={onAddPermissions}
                    onDeletePermissions={onDeletePermissions}
                    userId={user?.id}
                  />
                </FormGroup>
              ) : null}
            </Col>
          </Col>
        )}
        {organisation && isInstallerAdmin && (
          <Col sm={12}>
            <Col sm={6}>
              <ControlLabel>
                Organisation Permissions
                <span className="required">*</span>
              </ControlLabel>
              <Checkbox
                checked={isInstallerAdminUser}
                onChange={(e) => {
                  setShowOverride(e.target.checked);
                  onDeletePermissions(user.id, "organisation", [
                    organisation.id,
                  ]);
                  onDeletePermissions(user.id, "organisationAdmin", [
                    organisation.id,
                  ]);
                  onAddPermissions(
                    user.id,
                    "clientOrganisation",
                    clients.map((client) => client.id),
                  );
                  onAddPermissions(user.id, "organisationAdmin", [
                    organisation.id,
                  ]);

                  if (isClientOrganisationUser) {
                    onDeletePermissions(user.id, 'clientOrganisation', clients.map((client) => client.id)); // prettier-ignore
                  }
                  onUpdateInstallerUserField(e.target.checked);
                }}
              >
                <RoleName>Installer Org Admin</RoleName>
                <RoleDescription>
                  Can add new installer users and edit installer users. Can be
                  assigned to actions within the client sites, carry out
                  repairs, sign off actions and download the action report.
                </RoleDescription>
              </Checkbox>
            </Col>
            <Col sm={6}>
              {isInstallerAdmin ||
              userPermissionType !== USER_PERMISSION_TYPES.NONE ||
              showOverride ? (
                <FormGroup controlId="formControlsSelectMultiple">
                  <ControlLabel>Individual Clients</ControlLabel>
                  <OrganisationPermissionSelector
                    allSelectedByDefault={
                      isOrganisationAdmin ||
                      isOrganisationUser ||
                      isInstallerAdmin
                    }
                    clientPermissionGrantedIds={permissions?.clientOrganisation}
                    clients={clients}
                    onAddPermissions={onAddPermissions}
                    onDeletePermissions={onDeletePermissions}
                    userId={user?.id}
                  />
                </FormGroup>
              ) : null}
            </Col>
          </Col>
        )}
        <PermissionChanges
          addedPermissions={addedPermissions}
          clearPermissions={() => {
            setShowOverride(false);
            clearPermissions();
          }}
          deletedPermissions={deletedPermissions}
          userId={user?.id}
        />
      </Panel.Body>
    </EditorPanel>
  );
}

OrganisationPermissions.propTypes = {
  onDeletePermissions: PropTypes.func,
  onAddPermissions: PropTypes.func,
  userPermissionType: PropTypes.string,
  addedPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      relationId: PropTypes.string,
      UserId: PropTypes.string,
    }),
  ),
  clients: PropTypes.arrayOf(PropTypes.shape({})),
  curUser: PropTypes.shape({
    isSuperAdmin: PropTypes.bool,
    permissions: PropTypes.shape({
      organisationAdmin: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  deletedPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      relationId: PropTypes.string,
      UserId: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  onChangeClient: PropTypes.func,
  onChangeSearchText: PropTypes.func,
  onClearClient: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  organisation: PropTypes.object,
  OrganisationId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  organisations: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  permissions: PropTypes.object,
  searchText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  clearPermissions: PropTypes.func.isRequired,
  isInstallerUser: PropTypes.bool,
  isInstallerAdmin: PropTypes.bool,
  onUpdateInstallerUserField: PropTypes.func,
};

export default OrganisationPermissions;
