import PropTypes from 'prop-types'

export const InspectionProp = PropTypes.shape({
  InspectionId: PropTypes.string,
  id: PropTypes.string,
  dueAt: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  assignees: PropTypes.array,
  photoEvidenceRequired: PropTypes.bool,
  SiteId: PropTypes.string,
  completedAt: PropTypes.string,
  ClientName: PropTypes.string,
  InspectionReference: PropTypes.string,
  reference: PropTypes.string,
  InspectionType: PropTypes.string,
  type: PropTypes.string,
  isolated: PropTypes.bool,
  partial: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  reclassifiedClassifications: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  classifications: PropTypes.array,
  completedPercentage: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  confirmedClassifications: PropTypes.array,
  createdAt: PropTypes.string,
  isCompletable: PropTypes.bool,
  isPartialCompletable: PropTypes.bool,
  name: PropTypes.string
})
