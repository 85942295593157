/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Component } from "react";
import { Col, Row, Checkbox, Button, ButtonGroup } from "react-bootstrap";

import { getSite, toggleSiteBuildMode } from "libs/api";

export default class SiteBlueprintViewer extends Component {
  state = {
    site: null,
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const { SiteId } = this.props;
    const site = await getSite(SiteId);
    this.setState({ site });
  }

  renderBluePrintInformation() {
    const { permission } = this.props;
    const { site } = this.state;
    return (
      <div>
        <Row>
          <Col sm={12}>
            <Col sm={6}>Added Warehouses</Col>
            <Col sm={6}>
              <div className="buildModeCheckbox">
                <Checkbox
                  checked={site.buildMode}
                  css={css`
                    margin: 0;
                    label {
                      input {
                        background-color: #00000000;
                        border-radius: 0px;
                        border: solid 1px #979797;
                        width: 15px;
                        height: 15px;
                        margin-left: 10px;
                      }
                      input:checked {
                        background-color: #ffcf33;
                      }
                      input:after {
                        border-radius: 0px;
                        height: 7px;
                        width: 7px;
                        left: 3px;
                        top: 3px;
                      }
                    }
                  `}
                  disabled={!permission.level.includes("organisation")}
                  onClick={() =>
                    this.setState({ showBuildModeConfirmation: true })
                  }
                >
                  Build Mode
                </Checkbox>
              </div>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ul
              css={css`
                list-style: none;
                margin-top: 25px;
                li {
                  margin-left: -4px;
                  margin-top: 5px;
                }
                li::before {
                  content: "●";
                  color: #2d71ed;
                  display: inline-block;
                  width: 1em;
                  margin-left: -20px;
                }
              `}
            >
              {site.warehouses.map((warehouse) => (
                <li key={warehouse.id}>{warehouse.name}</li>
              ))}
              {!site.warehouses.length && "No Warehouses Added"}
            </ul>
          </Col>
        </Row>
      </div>
    );
  }

  renderBuildModeConfirmation() {
    const { site, buildModeErr } = this.state;
    return (
      <div
        css={css`
          text-align: center;
          padding-top: 10px;
          .btn-group {
            padding-top: 10px;
          }
          .btn {
            width: 100px;
          }
        `}
      >
        <div>{`Are you sure you want turn ${
          site.buildMode ? "Off" : "On"
        } Build mode?`}</div>
        <ButtonGroup>
          <Button
            className="cancel"
            onClick={() => this.setState({ showBuildModeConfirmation: null })}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              const res = await toggleSiteBuildMode(site.id);
              if (res.ok) {
                this.setState({ showBuildModeConfirmation: null }, () =>
                  this.getData(),
                );
              } else {
                this.setState({
                  buildModeErr: `Unable to toggle Site's build mode.
${!site.buildMode ? "There may be an on-going inspection" : ""}
${site.buildMode ? "It may not contain a valid warehouse." : ""}`,
                });
              }
            }}
          >
            {`Turn ${site.buildMode ? "Off" : "On"}`}
          </Button>
        </ButtonGroup>
        <div>{buildModeErr}</div>
      </div>
    );
  }

  render() {
    const { site, showBuildModeConfirmation } = this.state;
    if (!site) {
      return null;
    }
    return (
      <div
        css={css`
          color: #696969;
          font-size: 12pt;
          .buildModeCheckbox {
            float: right;
          }
        `}
      >
        {showBuildModeConfirmation
          ? this.renderBuildModeConfirmation()
          : this.renderBluePrintInformation()}
      </div>
    );
  }
}

SiteBlueprintViewer.propTypes = {
  SiteId: PropTypes.string,
  permission: PropTypes.shape({
    level: PropTypes.arrayOf(PropTypes.string),
  }),
};
