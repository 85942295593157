import PropTypes from "prop-types";
import { useState } from "react";
import {
  Col,
  ControlLabel,
  Grid,
  InputGroup,
  Panel,
  Row,
} from "react-bootstrap";

import { useOrganisations } from "hooks/organisation";

import EditorPanel from "views/components/EditorPanel";
import ConfirmModal from "views/components/Modals/ConfirmModal";

import { OrgListWrapper, SearchWithList, ClearButton } from "../styles";

export default function OrganisationChange({
  OrganisationId,
  onChange,
  isNotVerified,
}) {
  const [searchText, setSearchText] = useState(null);
  const { organisations } = useOrganisations({ searchText, amount: 1000 });
  const [selectedOrganisationId, setSelectedOrganisationId] = useState(null);

  /**
   * If the search text is null, just use the client current organisation.
   * If the search text is a string, use that.
   * If we have selected an organisation, then use that regardless of search
   */
  const currentOrganisation =
    searchText ??
    `${
      organisations.find(
        (org) => org.id === (selectedOrganisationId ?? OrganisationId),
      )?.name
    }${isNotVerified ? " (Not Verified)" : ""}`;

  return (
    <EditorPanel>
      <Panel.Heading>Organisation</Panel.Heading>
      <Panel.Body>
        <Grid fluid>
          <Row>
            <Col sm={12}>
              <ControlLabel>
                Organisation
                <span className="required">*</span>
              </ControlLabel>
            </Col>
            <Col sm={12}>
              <InputGroup>
                <SearchWithList
                  onChange={(e) => setSearchText(e.target.value)}
                  value={currentOrganisation ?? ""}
                />
                <InputGroup.Button>
                  <ClearButton
                    onClick={() => {
                      setSearchText("");
                      setSelectedOrganisationId(null);
                    }}
                  >
                    Clear
                  </ClearButton>
                </InputGroup.Button>
              </InputGroup>
              {searchText ? (
                <OrgListWrapper>
                  {organisations.map((org) => (
                    <li
                      key={org.id}
                      onClick={() => setSelectedOrganisationId(org.id)}
                      role="button"
                    >
                      {org.label ?? org.name}
                    </li>
                  ))}
                </OrgListWrapper>
              ) : null}
            </Col>
          </Row>
        </Grid>
        <ConfirmModal
          confirmButtonText="Change"
          onConfirm={() => {
            onChange(selectedOrganisationId);
            setSearchText(null);
            setSelectedOrganisationId(null);
          }}
          onHide={() => setSelectedOrganisationId(null)}
          show={selectedOrganisationId}
          title="Change Organisation"
        >
          Are you sure you want to change the organisation? Reminder: You will
          still need to save your changes after this.
        </ConfirmModal>
      </Panel.Body>
    </EditorPanel>
  );
}

OrganisationChange.propTypes = {
  OrganisationId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isNotVerified: PropTypes.bool,
};
