import styled from "@emotion/styled";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { DownloaderProvider } from "hooks/useDownloader";
import { getSentryEnvironment } from "libs/sentry";

import ErrorFallback from "views/components/ErrorFallback";
import Main from "views/components/Main";

const { REACT_APP_VERSION } = process.env;
const showVersionNumber = getSentryEnvironment() !== "production";

const VersionNumber = styled.div`
  color: #ffffff;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  background-color: red;
  z-index: 9997;
`;

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DownloaderProvider>
        <Main />
        {showVersionNumber && (
          <VersionNumber>{`Econform © v${REACT_APP_VERSION}-${getSentryEnvironment()}`}</VersionNumber>
        )}
        <ToastContainer
          autoClose={4000}
          pauseOnHover
          position="bottom-right"
          theme="colored"
        />
      </DownloaderProvider>
    </ErrorBoundary>
  );
}

export default App;
