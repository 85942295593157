import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { GreenButton, GreyButton } from "./colors";

const ActionButtonProps = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default function PauseResumeScheduleButton({
  disabled,
  onClick,
  isPaused,
}) {
  const ColouredButton = isPaused ? GreyButton : GreenButton;
  return (
    <ColouredButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={isPaused ? faPlay : faPause} />
      {isPaused ? "Resume" : "Pause"}
    </ColouredButton>
  );
}

PauseResumeScheduleButton.propTypes = {
  ...ActionButtonProps,
  isPaused: PropTypes.bool,
};
