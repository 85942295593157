import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button } from "react-bootstrap";

const Container = styled.div(
  ({ showingOptions }) => `
  width: 100%;
  position: relative;
  height: 40px;
  z-index: ${showingOptions ? 10000 : 9000};
`,
);

const SearchBarContainer = styled.div(
  ({ showingOptions, showingClearButton }) => `
    input {
      display: inline-block;
      position: relative;
      z-index: 9999;
      border: 0px;
      height: 40px !important;
      margin-bottom: 0px !important;
      width: calc(100% - 50px);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      ${
        showingOptions
          ? `
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      `
          : ""
      }
      ${
        showingClearButton
          ? `
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    `
          : ""
      }
    padding-left: 10px;
      background-color: #f2f2f4;
      color: #747474;
      z-index: 9990;
    }
    .clear-btn {
      width: 50px;
      height: 39px !important;
      display: inline-block !important;
      border-radius: 5px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      margin: 0px;
      margin-top: -3px;
    }
`,
);

const OptionsContainerMain = styled.div`
  position: absolute;
  width: 100%;
  z-index: 9999;
`;

const ClickOffDiv = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 9998;
`;

const OptionsContainer = styled.div`
  position: relative;
  display: inline-block;
  top: 0px;
  bottom: 0px;
  width: calc(100% - 50px);
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 155px;
  overflow-y: auto;
  z-index: 9998;
  filter: drop-shadow(0 0.75rem 0.75rem #11111133);
`;

const OptionContainerWrapper = styled.div`
  padding: 5px 10px;
  border-top: 1px #00000011 solid;
  z-index: 9999;
  cursor: pointer;
`;

const GroupHeaderContainer = styled.div`
  padding-left: 5px;
  background-color: #f2f2f4;
  color: #2d72ed;
  z-index: 9999;
`;

function OptionContainer({ option, onClick, labelKey }) {
  if (!option) {
    return null;
  }
  return (
    <OptionContainerWrapper onClick={onClick}>
      {option?.label || option[labelKey]}
    </OptionContainerWrapper>
  );
}

OptionContainer.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  labelKey: PropTypes.string,
};

// TODO: Standardize the props for labels
function AutoCompleteSearch({
  disabled,
  groupOptions,
  labelKey,
  loading = false,
  onChangeSearchText,
  onClear,
  onClick = () => {},
  onSelectOption,
  options = [],
  placeholder,
  searchText,
  selectedOptionId,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const showingOptions =
    options && options.length && !selectedOptionId && showOptions;
  const showingClearButton = (searchText || selectedOptionId) && !disabled;

  return (
    <Container showingOptions={showingOptions}>
      <OptionsContainerMain>
        <SearchBarContainer
          showingClearButton={showingClearButton}
          showingOptions={showingOptions}
        >
          <input
            disabled={disabled}
            onChange={onChangeSearchText}
            onClick={() => {
              setShowOptions(true);
              onClick();
            }}
            placeholder={placeholder}
            value={searchText ?? ""}
          />
          <Button
            className="clear-btn"
            disabled={!showingClearButton}
            onClick={onClear}
          >
            Clear
          </Button>
          {loading && showOptions && (
            <OptionsContainer>
              <OptionContainer>Loading...</OptionContainer>
            </OptionsContainer>
          )}
          {!loading && showOptions && (
            <OptionsContainer>
              {groupOptions
                ? groupOptions.map((groupOption) => (
                    <>
                      {options.filter(
                        (curOption) => curOption.userType === groupOption.value,
                      ).length ? (
                        <GroupHeaderContainer>
                          {groupOption.label}
                        </GroupHeaderContainer>
                      ) : null}
                      {options
                        .filter(
                          (curOption) =>
                            curOption.userType === groupOption.value,
                        )
                        .map((option) => (
                          <OptionContainer
                            // TODO: This seems like it is doing too much with labels keys etc
                            key={option.id ?? option?.label ?? option[labelKey]}
                            onClick={() => {
                              setShowOptions(false);
                              onSelectOption(
                                option.id ?? option?.label ?? option[labelKey],
                              );
                            }}
                            option={option}
                          />
                        ))}
                    </>
                  ))
                : options.map((option) => (
                    <OptionContainer
                      // TODO: This seems like it is doing too much with labels keys etc
                      key={option.id ?? option?.label ?? option[labelKey]}
                      labelKey={labelKey}
                      onClick={() => {
                        setShowOptions(false);
                        onSelectOption(
                          option.id ?? option?.label ?? option[labelKey],
                        );
                      }}
                      option={option}
                    />
                  ))}
            </OptionsContainer>
          )}
        </SearchBarContainer>
      </OptionsContainerMain>
      {showOptions && <ClickOffDiv onClick={() => setShowOptions(false)} />}
    </Container>
  );
}

AutoCompleteSearch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  groupOptions: PropTypes.arrayOf(PropTypes.object),
  labelKey: PropTypes.string,
  loading: PropTypes.bool,
  onChangeSearchText: PropTypes.func,
  onClear: PropTypes.func,
  onClick: PropTypes.func,
  onSelectOption: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  searchText: PropTypes.string,
  selectedOptionId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AutoCompleteSearch;
