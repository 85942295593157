import moment from "moment";
import PropTypes from "prop-types";

import { useInspections } from "hooks/inspections";
import { useCheckboxSet } from "hooks/useCheckboxer";
import { useCurrentUser } from "hooks/user";
import { toggleInspectionArchive } from "libs/api";
import { PRIMARY_DATE_FORMAT } from "libs/constants";

import InspectionActionButtons from "views/components/ActionButtons/set-inspection";
import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import StatusIndicator from "views/components/StatusIndicator";
import { RowCheckbox } from "views/components/Tables/Checkboxes";
import { GrouperRow } from "views/components/Tables/GrouperRow";
import { Table } from "views/components/Tables/styles";
import { groupInspections } from "views/pages/Inspections/helpers";

// eslint-disable-next-line react/prop-types
function CheckboxInContext({ InspectionId, disabled }) {
  const { isChecked, onCheck, onUncheck } = useCheckboxSet();
  const selected = isChecked(InspectionId);
  return (
    <RowCheckbox
      checked={selected}
      disabled={disabled}
      onChange={() =>
        selected ? onUncheck(InspectionId) : onCheck(InspectionId)
      }
    />
  );
}

function Inspection({ inspection, onRefresh, ClientId, SiteId }) {
  const { user } = useCurrentUser();
  return (
    <tr>
      <td>
        <CheckboxInContext
          InspectionId={inspection.id}
          disabled={!(inspection.completedAt && inspection.preparedAt)}
        />
      </td>
      <td>{inspection.name}</td>
      {!ClientId && <td>{inspection.ClientName}</td>}
      {!SiteId && <td>{inspection.SiteName}</td>}
      <td>{inspection.reference}</td>
      <td>
        {inspection.inspectors
          ? inspection.inspectors.map((inspector) => inspector.name).join(", ")
          : "-"}
      </td>
      <td>
        <StatusIndicator
          partial={inspection.partial}
          status={
            // eslint-disable-next-line no-nested-ternary
            inspection.completedAt
              ? inspection.preparedAt
                ? "Complete"
                : "Prepare"
              : "Incomplete"
          }
        />
      </td>
      <td>{moment(inspection.createdAt).format(PRIMARY_DATE_FORMAT)}</td>
      <td>
        {inspection.completedAt
          ? moment(inspection.completedAt).format(PRIMARY_DATE_FORMAT)
          : "-"}
      </td>

      <InspectionActionButtons
        UserId={user.id}
        inspection={inspection}
        isAssign={inspection.assignees.length > 0}
        onArchiveInspection={async () => {
          await toggleInspectionArchive(inspection.id);
          onRefresh();
        }}
        onRefresh={onRefresh}
      />
    </tr>
  );
}

Inspection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inspection: PropTypes.object.isRequired,
  ClientId: PropTypes.string,
  SiteId: PropTypes.string,
  onRefresh: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/prop-types
function Group({ inspections = [], filters, onRefresh }) {
  const { checked, onCheckGroup, onUncheckGroup } = useCheckboxSet();

  if (inspections.length === 0) {
    return null;
  }
  const checkableGroupIds = inspections
    .filter((i) => i.preparedAt && i.completedAt)
    .map((i) => i.id);

  const allChecked =
    checked.filter((selectedInspection) =>
      checkableGroupIds.includes(selectedInspection),
    ).length >= checkableGroupIds.length && checkableGroupIds.length !== 0;
  return (
    <>
      <GrouperRow small>
        <td>
          <RowCheckbox
            checked={allChecked}
            disabled={checkableGroupIds.length === 0}
            onChange={() => {
              if (allChecked) {
                onUncheckGroup(checkableGroupIds);
              } else {
                onCheckGroup(checkableGroupIds);
              }
            }}
          />
        </td>
        <td colSpan="8">{moment(inspections[0].createdAt).fromNow()}</td>
      </GrouperRow>
      {inspections.map((inspection) => (
        <Inspection
          ClientId={filters?.ClientId}
          SiteId={filters?.SiteId}
          inspection={inspection}
          key={inspection.id}
          onRefresh={onRefresh}
        />
      ))}
    </>
  );
}

Group.propTypes = {
  filters: PropTypes.shape({
    ClientId: PropTypes.string,
    SiteId: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  inspections: PropTypes.arrayOf(PropTypes.object),
  onRefresh: PropTypes.func.isRequired,
};

export default function List({ filters, onRefresh }) {
  const { inspections, isLoading } = useInspections(filters);
  const groupedInspections = groupInspections(inspections) ?? {};
  const selectableInspections = inspections.filter(
    (i) => i.completedAt && i.preparedAt,
  );
  const selectableInspectionIds = selectableInspections.map((i) => i.id);

  const { onCheckGroup, reset, checked } = useCheckboxSet();
  const allChecked = selectableInspectionIds
    .map((i) => checked.includes(i))
    .every((i) => i);
  return (
    <>
      <Table withTabs>
        <thead>
          <tr>
            <th>
              <RowCheckbox
                checked={
                  inspections &&
                  selectableInspections.length &&
                  checked.length === selectableInspections.length
                }
                onChange={() =>
                  allChecked ? reset() : onCheckGroup(selectableInspectionIds)
                }
              />
            </th>
            <th>Name</th>
            {!filters?.ClientId && <th>Client</th>}
            {!filters?.SiteId && <th>Site</th>}
            <th>Ref</th>
            <th>Inspector</th>
            <th>Status</th>
            <th>Conducted</th>
            <th>Completed</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedInspections).map((group) =>
            Object.keys(groupedInspections[group]).map((value) => (
              <Group
                filters={filters}
                inspections={groupedInspections[group][value]}
                key={`${group}-${value}`}
                onRefresh={onRefresh}
              />
            )),
          )}
        </tbody>
      </Table>
      {((inspections && !inspections.length) || isLoading) && (
        <EmptyTableIndicator
          isLoading={isLoading}
          searchable
          type="Inspections"
        />
      )}
    </>
  );
}

List.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
  ClientId: PropTypes.string,
  SiteId: PropTypes.string,
  onRefresh: PropTypes.func.isRequired,
};
