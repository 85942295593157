import { OTHER_COLOUR_ID } from "constants";

import useSWR from "swr";

import { getTypes } from "libs/api";

export function useColours(ManufacturerId, filters) {
  const {
    data = [],
    error,
    mutate,
    isValidating,
    isLoading,
  } = useSWR(
    ManufacturerId ? [`/colour/manufacturer/${ManufacturerId}`, filters] : null,
    async () => getTypes("colour", ManufacturerId, filters),
  );

  return {
    colours: [...data, { name: "Other", id: OTHER_COLOUR_ID }] ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useBasePlateTypes(ManufacturerId, filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    ManufacturerId
      ? [`/basePlate/manufacturer/${ManufacturerId}`, filters]
      : [`/basePlate`, filters],
    async () => getTypes("basePlate", ManufacturerId, filters),
  );
  return {
    basePlateTypes: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useManufacturerSeries(ManufacturerId, filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    ManufacturerId
      ? [`/series/manufacturer/${ManufacturerId}`, filters]
      : [`/series`, filters],
    async () => getTypes("series", ManufacturerId, filters),
  );

  return {
    series: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useFrameTypes(ManufacturerId, filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    ManufacturerId
      ? [`/type/frame/manufacturer/${ManufacturerId}`, filters]
      : [`/type/frame`, filters],
    async () => getTypes("frame", ManufacturerId, filters),
  );
  return {
    frameTypes: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useBeamTemplateTypes(ManufacturerId, filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    ManufacturerId
      ? [`/type/beam/manufacturer/${ManufacturerId}`, filters]
      : [`/type/beam`, filters],
    async () => getTypes("beam", ManufacturerId, filters),
  );
  return {
    beamTemplateTypes: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}
