import PropTypes from 'prop-types';
import { FormControl, ControlLabel, Col, Form, FormGroup } from 'react-bootstrap';

export default function FrameFilters({ filterOptions, manufacturers, setFilterOption }) {
  return (
    <div className="filters">
      <Form horizontal onKeyPress={(e) => (e.which === 13 ? e.preventDefault() : '')}>
        <FormGroup controlId="formHorizontalManufacturer">
          <Col sm={4}>
            <ControlLabel>Manufacturer</ControlLabel>
          </Col>
          <Col sm={8}>
            <FormControl
              componentClass="select"
              onChange={(e) => {
                setFilterOption('ManufacturerId', e.target.value);
              }}
              placeholder="Frame Template"
              value={filterOptions.ManufacturerId}
            >
              <option value="">Any</option>
              {manufacturers.map((manufacturer) => (
                <option key={manufacturer.id} value={manufacturer.id}>
                  {manufacturer.name}
                </option>
              ))}
            </FormControl>
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalManufacturer">
          <Col sm={4}>
            <ControlLabel>Height</ControlLabel>
          </Col>
          <Col sm={4}>
            <FormControl
              min="0"
              onChange={(e) => {
                setFilterOption('minHeight', parseInt(e.target.value, 10));
              }}
              placeholder="Min"
              type="number"
              value={filterOptions.minHeight}
            />
          </Col>
          <Col sm={4}>
            <FormControl
              min="0"
              onChange={(e) => {
                setFilterOption('maxHeight', parseInt(e.target.value, 10));
              }}
              placeholder="Max"
              type="number"
              value={filterOptions.maxHeight}
            />
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
}

FrameFilters.propTypes = {
  setFilterOption: PropTypes.func.isRequired,
  manufacturers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  filterOptions: PropTypes.shape({
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,
    ManufacturerId: PropTypes.string,
  }),
};
