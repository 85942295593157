/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function userRequestEmailChange(email, password) {
  const res = await apiCall("/user/changeEmail", {
    method: "PUT",
    body: JSON.stringify({ email, password }),
  });
  if (res.ok) {
    return;
  }

  const { error } = await res.json();
  throw new Error(error ?? `Problem requesting email change`);
}

export async function userEmailVerifyChange(password, newEmailCode) {
  const res = await apiCall("/user/verifyChange", {
    method: "PUT",
    body: JSON.stringify({ password, newEmailCode }),
  });
  return res;
}

export async function userPasswordChange(password, newPassword) {
  const res = await apiCall("/user/password", {
    method: "PUT",
    body: JSON.stringify({ password, newPassword }),
  });
  if (res.ok) {
    return;
  }
  const { error } = await res.json();
  throw new Error(error ?? `Problem changing password`);
}

export async function getCurrentUser() {
  const res = await apiCall(`/user`);
  if (res.status === 200) {
    return res.json();
  }
  if (res.status === 401) {
    return null;
  }
  throw new Error(`Problem getting current user`);
}

export async function getUser(UserId) {
  const res = await apiCall(`/user${UserId ? `/${UserId}` : ""}`);
  return res;
}

export async function getUsers(filters) {
  const res = await apiCall(`/user/search${filterUrlGenerator(filters)}`);
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting users: ${res.status}`);
  return false;
}

export async function addUser(user) {
  const res = await apiCall("/user", {
    method: "POST",
    body: JSON.stringify(user),
  });
  return res;
}

export async function editUser(user) {
  const res = await apiCall("/user", {
    method: "PUT",
    body: JSON.stringify(user),
  });
  return res;
}

export async function deleteUser(UserId) {
  const res = await apiCall(`/user/${UserId}`, {
    method: "DELETE",
  });
  return res;
}

export async function getPermission(type, relationId) {
  const res = await apiCall(`/user/permission/${type}/${relationId}`);
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  throw new Error(`Problem getting permission`);
}
