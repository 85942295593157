import { faPencilRuler } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { RoundLink } from "views/pages/Inspection/styles";

export default function BlueprintButton({
  siteId,
  warehouseId,
  inspectionId,
  isAction = false,
}) {
  if (!siteId || !warehouseId || !inspectionId) return null;
  return (
    <RoundLink
      to={
        isAction
          ? `/actions/blueprint/${inspectionId}`
          : `/inspections/blueprint/${inspectionId}`
      }
    >
      <FontAwesomeIcon icon={faPencilRuler} />
    </RoundLink>
  );
}

BlueprintButton.propTypes = {
  inspectionId: PropTypes.string,
  siteId: PropTypes.string,
  warehouseId: PropTypes.string,
  isAction: PropTypes.bool,
};
