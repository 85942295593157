import useSWR from 'swr';

import { getPermission } from 'libs/api';

export function usePermission(type, id) {
  const { data, error, isValidating, isLoading } = useSWR(
    `/user/permission/${type}/${id}`,
    async () => getPermission(type, id),
  );
  return {
    permission: data ?? { level: [] },
    isLoading,
    error,
    isValidating,
  };
}
