import styled from "@emotion/styled";
import PropTypes from "prop-types";

const UnviewedIndicatorContainer = styled.span`
  background-color: #2870ed;
  border-radius: ${(props) => (props.amount > 9 ? "25px" : "100%")};
  border: solid ${(props) => props.borderSize}px #30333c;
  display: inline-block;
  font-size: ${(props) => props.size * 0.5}px;
  height: ${(props) => props.size}px !important;
  min-width: ${(props) => props.size}px !important;
  text-align: center;
  position: absolute;
  top: -10px;
  right: 10px;
`;

const UnviewedIndicatorIcon = styled.span`
  position: relative;
  color: #d9dfee;
  line-height: ${(props) => props.size - props.borderSize * 1.5}px;
  margin-top: -${(props) => props.borderSize}px;
  min-width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
`;

function getAmountDisplay(amount, maxAmount, small) {
  if (amount > maxAmount) {
    if (small) {
      return null;
    }
    return `${maxAmount}+`;
  }
  return amount;
}

function UnviewedIndicator({
  amount,
  size = 20,
  borderSize = 2,
  small,
  maxAmount = 9,
}) {
  if (!amount) {
    return null;
  }
  return (
    <UnviewedIndicatorContainer
      amount={amount}
      borderSize={borderSize}
      size={size}
    >
      <UnviewedIndicatorIcon borderSize={borderSize} size={size}>
        {getAmountDisplay(amount, maxAmount, small)}
      </UnviewedIndicatorIcon>
    </UnviewedIndicatorContainer>
  );
}

UnviewedIndicator.propTypes = {
  amount: PropTypes.number,
  borderSize: PropTypes.number,
  maxAmount: PropTypes.number,
  size: PropTypes.number,
  small: PropTypes.bool,
};

export default UnviewedIndicator;
