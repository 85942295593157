/** @jsxImportSource @emotion/react */
import { faCheck, faList, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useActions, useActionStats } from "hooks/actions";
import { useClient } from "hooks/client";
import { useSite } from "hooks/site";
import { useCheckboxSet, withCheckboxSet } from "hooks/useCheckboxer";
import { useDownloader } from "hooks/useDownloader";
import { FiltersProvider, FILTER_KEYS, useFilters } from "hooks/useFilter";
import { useCurrentUser } from "hooks/user";
import { toggleInspectionArchive } from "libs/api";
import downloadPDF from "libs/downloadPDF";

import PageWrapper from "views/components/common/PageWrapper";
import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import FlatModal from "views/components/Modals/FlatModal";
import ImageLightBox from "views/components/Modals/ImageLightBox";
import ShareModal from "views/components/Modals/ShareModal";
import { RowCheckbox } from "views/components/Tables/Checkboxes";
import PageButtons from "views/components/Tables/PageButtons";
import { Table } from "views/components/Tables/styles";
import {
  MiniFilterButton,
  MiniFilterTab,
  MiniFilterWrapper,
  TableControlsWrapper,
} from "views/components/Tables/TableControls";
import TableHeader from "views/components/Tables/TableHeader";
import TableTabs from "views/components/Tables/TableTabs";
import VerticalDivider from "views/components/Tables/VerticalDivider";

import ActionRows from "./components/ActionRows";
import Controls from "./components/Controls";

const PAGE_SIZE = 10;

function ActionsPage() {
  const { TabIndex: urlTabIndex } = useParams();

  const [resolved] = useState(null);
  const [images] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [page, setPage] = useState(0);

  const [, setSelectedAction] = useState(null);
  const [allActions, setAllActions] = useState(!!(Number(urlTabIndex) === 1));
  const [currentTab, setCurrentTab] = useState(null);

  const [shareInspectionId, setShareInspectionId] = useState(null);

  const { siteId, clientId, actionStatus, searchText } = useFilters();

  const { client, isLoading: isClientLoading } = useClient(clientId);
  const { site, isLoading: isSiteLoading } = useSite(siteId);
  const { user } = useCurrentUser();

  // Provider wraps the page
  const { checked, reset: resetChecked } = useCheckboxSet();
  // Untick everything on page navigate
  useEffect(() => {
    resetChecked();
  }, [page, resetChecked]);

  const { onStart, onEnd, downloads } = useDownloader();

  const searchFilters = useMemo(
    () => ({
      ClientId: clientId,
      SiteId: siteId,
      page,
      searchText,
      amount: PAGE_SIZE,
      showGreen: "false",
      resolved,
      status: actionStatus,
      allActions,
      archived: currentTab === "Archived",
    }),
    [
      clientId,
      siteId,
      searchText,
      page,
      allActions,
      currentTab,
      resolved,
      actionStatus,
    ],
  );

  /**
   * Data Loading Hooks
   */
  const {
    actions,
    isLoading: isActionsLoading,
    total: totalActions,
    mutate: refreshActions,
    isValidating,
  } = useActions(searchFilters);

  const { actionStats } = useActionStats(searchFilters);

  const onExportPDF = useCallback(
    async (action) => {
      onStart(action.id);
      const toastId = toast(`Exporting report (${action.reference})`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        type: toast.TYPE.INFO,
        autoClose: false,
        pauseOnHover: false,
      });
      await downloadPDF(action.id, action, false, true);
      toast.update(toastId, {
        render: `Exported report (${action.reference}))`,
        type: toast.TYPE.SUCCESS,
        autoClose: 4000,
      });
      onEnd(action.id);
    },
    [onEnd, onStart],
  );

  const totalPages = Math.ceil(totalActions / PAGE_SIZE);
  const selectableActions =
    actions && actions.filter((action) => action.status === "Completed");

  let tableTabs = ["Racking", "Archived"];
  /** We dont want to show archived actions to installer users */
  if (user?.userType === "Installer" || user?.userType === "InstallerAdmin") {
    tableTabs = ["Racking"];
  }

  const isAllSelected = !!(
    actions &&
    selectableActions.length &&
    checked.length === selectableActions.length
  );
  const isInstallerUser =
    user?.installerOrganisationClient?.length ||
    user.userType === "InstallerAdmin";

  const onAllCheckboxChange = useCallback(() => {
    resetChecked(
      isAllSelected
        ? []
        : actions.filter((a) => a.status === "Completed").map((a) => a.id),
    );
  }, [isAllSelected, actions, resetChecked]);

  const isLoading = isActionsLoading || isClientLoading || isSiteLoading;
  return (
    <PageWrapper>
      <Helmet>
        <title>
          {`Econform / ${client ? `${client.name} / ` : ""} ${
            site ? `${site.name} / ` : ""
          } Actions`}
        </title>
      </Helmet>
      <Controls actionStats={actionStats} />
      <TableHeader
        largeLeftTagline={
          allActions ? "List of all actions" : "List of actions assigned to you"
        }
        largeLeftTitle="Actions"
        right={`Showing ${actions && actions.length} of ${
          totalActions || 0
        } Actions`}
      />
      <TableControlsWrapper>
        {tableTabs.length > 0 && (
          <TableTabs
            initialTab={0}
            onSelectKey={(newCurrentTab) =>
              setCurrentTab(tableTabs[newCurrentTab])
            }
            titles={tableTabs}
            width="29%"
          />
        )}
        <MiniFilterWrapper>
          {currentTab !== "Archived" && !isInstallerUser ? (
            <div>
              <MiniFilterTab
                onClick={() => {
                  setAllActions(false);
                  setPage(0);
                }}
                selected={!allActions}
              >
                {!allActions ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  <FontAwesomeIcon icon={faList} />
                )}
                My Actions
              </MiniFilterTab>
              <MiniFilterTab
                onClick={() => {
                  setAllActions(true);
                  setPage(0);
                }}
                selected={allActions}
              >
                {allActions ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  <FontAwesomeIcon icon={faList} />
                )}{" "}
                All Actions
              </MiniFilterTab>
              <VerticalDivider />
            </div>
          ) : null}
          <MiniFilterButton
            disabled={!checked.length}
            onClick={async () => {
              checked.map(async (ActionId) => {
                await onExportPDF(
                  actions.find((action) => action.id === ActionId),
                );
              });
              resetChecked();
            }}
            selected={checked.length}
          >
            {downloads.length > 0 ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : null}
            Export
          </MiniFilterButton>
          {user?.userType !== "Installer" &&
            user?.userType !== "InstallerAdmin" && (
              <MiniFilterButton
                disabled={!checked.length}
                onClick={async () => {
                  await Promise.all(
                    checked.map(async (ActionId) => {
                      await toggleInspectionArchive(ActionId, true);
                    }),
                  );
                  resetChecked();
                  refreshActions();
                }}
                selected={checked.length}
              >
                {currentTab !== "Archived" ? "Archive" : "Restore"}
              </MiniFilterButton>
            )}
          <MiniFilterButton onClick={() => refreshActions()}>
            {isLoading || isValidating ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : null}
            Refresh
          </MiniFilterButton>
        </MiniFilterWrapper>
      </TableControlsWrapper>
      <Table withTabs>
        <thead>
          <tr>
            <th>
              <RowCheckbox
                checked={isAllSelected}
                onChange={(e) => onAllCheckboxChange(e)}
              />
            </th>
            {!client ? <th>Client</th> : null}
            {!site ? <th>Site</th> : null}
            <th>Ref</th>
            <th>Creator</th>
            <th>Assignee</th>
            <th>Resolvers</th>
            <th>Status</th>
            <th>Risk</th>
            <th>Due At</th>
            <th>Completed</th>
            <th className="hover-buttons">Controls</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading && (
            <ActionRows
              actions={actions}
              client={client}
              getData={refreshActions}
              onRequestPDF={(action) => onExportPDF(action)}
              onRequestShare={(action) => setShareInspectionId(action.id)}
              onSelectAction={(action) => setSelectedAction(action)}
              site={site}
            />
          )}
        </tbody>
      </Table>
      {(actions && actions.length === 0) || isLoading ? (
        <EmptyTableIndicator isLoading={isLoading} searchable type="Actions" />
      ) : null}
      <PageButtons
        curPage={page}
        onChangePage={setPage}
        totalPages={totalPages}
      />
      {shareInspectionId && (
        <FlatModal
          closeButton
          onHide={() => setShareInspectionId(false)}
          show
          title="Share Action Report"
          width={500}
        >
          <ShareModal
            InspectionId={shareInspectionId}
            onHide={() => setShareInspectionId(false)}
            type="action"
          />
        </FlatModal>
      )}
      {currentImage !== null && (
        <ImageLightBox
          currentImage={currentImage}
          images={images.map((image) =>
            Object.assign(image, {
              src: `${image.remotePath}`,
              caption: image.caption,
              id: image.id,
              createdAt: image.createdAt,
            }),
          )}
          isOpen
          onClose={() => setCurrentImage(null)}
          onNext={() => setCurrentImage(currentImage + 1)}
          onPrevious={() => setCurrentImage(currentImage - 1)}
        />
      )}
    </PageWrapper>
  );
}

/**
 * An actions page can be the global one, as part of a client, or as part of a
 * site. This wrapping component provides the filter context for the page.
 */
function ActionsPageInURLContext() {
  const { ClientId: urlClientId, SiteId: urlSiteId } = useParams();

  const controlledValues = {
    [FILTER_KEYS.clientId]: urlClientId,
    [FILTER_KEYS.siteId]: urlSiteId,
  };

  const enabledFilters = [
    !urlClientId && !urlSiteId && FILTER_KEYS.clientId, // Don't allow changes when client or site Id is specified
    !urlSiteId && FILTER_KEYS.siteId,
    FILTER_KEYS.actionStatus,
  ].filter(Boolean);

  return (
    <FiltersProvider
      controlledValues={controlledValues}
      enabledFilters={enabledFilters}
      key={`${urlClientId}_${urlSiteId}`}
    >
      <ActionsPage />
    </FiltersProvider>
  );
}

export default withCheckboxSet(ActionsPageInURLContext);
