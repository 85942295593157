import { useCallback, useMemo } from "react";

import { useDisplayClassifications } from "hooks/inspections";
import { SCALE_FACTOR } from "hooks/use-site-blueprint";
import { useWarehouse } from "hooks/warehouse";
import { rackToBlueprintData } from "libs/blueprint";

/**
 * This takes the blueprint display classifications and scales each of the
 * measurements so that we can display it without causing massive memory usage.
 */
export function useScaledBlueprintDisplayClassifications(filters, WarehouseId) {
  const { displayClassifications, isLoading, isError } =
    useBlueprintDisplayClassifications(filters, WarehouseId);

  const scaledDisplayClassifications = useMemo(
    () =>
      displayClassifications.map((rack) => ({
        ...rack,
        originX: Math.round(rack.originX * SCALE_FACTOR),
        originY: Math.round(rack.originY * SCALE_FACTOR),
        totalX: Math.round(rack.totalX * SCALE_FACTOR),
        totalY: Math.round(rack.totalY * SCALE_FACTOR),
        bays: rack.bays.map((bay) => ({
          ...bay,
          width: Math.round(bay.width * SCALE_FACTOR),
          offset: Math.round(bay.offset * SCALE_FACTOR),
          FrameTemplateR: {
            ...bay.FrameTemplateR,
            depth: Math.round(bay.FrameTemplateR.depth * SCALE_FACTOR),
            width: Math.round(bay.FrameTemplateR.width * SCALE_FACTOR),
          },
          FrameTemplateL: {
            ...bay.FrameTemplateL,
            depth: Math.round(bay.FrameTemplateL.depth * SCALE_FACTOR),
            width: Math.round(bay.FrameTemplateL.width * SCALE_FACTOR),
          },
        })),
      })),
    [displayClassifications],
  );

  return {
    displayClassifications: scaledDisplayClassifications,
    isLoading,
    isError,
  };
}

/**
 * Gathers classifications for a warehouse (with optional filters, e.g. for
 * an inspection) and adds layout data for display.
 */
function useBlueprintDisplayClassifications(filters, WarehouseId) {
  // TODO: Add proper errors and mutate
  const { data, isLoadingClassifications, isError } =
    useDisplayClassifications(filters);
  const { warehouse, isLoading: isLoadingWarehouse } = useWarehouse(WarehouseId); // prettier-ignore
  // Rack data is needed here for the layout

  const rackToBlueprintDataWithBayTemplates = useCallback(
    (r) =>
      rackToBlueprintData(
        r,
        warehouse?.snapshot.bayTemplates,
        warehouse?.snapshot.frameTemplates,
      ),
    [warehouse],
  );

  const addClassificationsToRack = useCallback(
    (rack) => {
      const classificationRacksInWarehouse = data?.warehouses.find(
        (w) => w.WarehouseId === WarehouseId,
      )?.racks;
      const classificationRack = classificationRacksInWarehouse?.find(
        (r) => r.RackId === rack.id,
      );
      return {
        ...rack,
        classifications: classificationRack,
      };
    },
    [WarehouseId, data],
  );

  const racks = useMemo(
    () =>
      warehouse?.snapshot?.racks.map(rackToBlueprintDataWithBayTemplates) ?? [],
    [warehouse, rackToBlueprintDataWithBayTemplates],
  );
  const racksWithClassifications = useMemo(
    () => racks.map(addClassificationsToRack),
    [racks, addClassificationsToRack],
  );

  return {
    displayClassifications: racksWithClassifications,
    isLoading: isLoadingClassifications || isLoadingWarehouse,
    isError,
  };
}
