import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import FilterOptionsAndChips from "../../../components/FilterOptionsAndChips";

import {
  ControlsButton,
  ControlsButtonWrapper,
  ControlsWrapper,
  FiltersWrapper,
} from "../styles";

function Controls({ redirectUrl, showActionButton }) {
  const navigate = useNavigate();
  const url = redirectUrl();
  return (
    <ControlsWrapper>
      <FiltersWrapper>
        <FilterOptionsAndChips showSearch />
      </FiltersWrapper>
      <ControlsButtonWrapper>
        {showActionButton ? (
          <ControlsButton onClick={() => navigate(url)}>
            New User
          </ControlsButton>
        ) : null}
      </ControlsButtonWrapper>
    </ControlsWrapper>
  );
}

Controls.propTypes = {
  redirectUrl: PropTypes.func,
  showActionButton: PropTypes.bool,
};

export default Controls;
