import PropTypes from "prop-types";
import { FormGroup, Col } from "react-bootstrap";

import {
  ControlLabelWithLoading,
  StyledFormControl,
} from "views/components/BuildModeSidebar/SharedControls/Generic";

export default function FieldRotation({ disabled, rotation, onUpdate }) {
  return (
    <FormGroup>
      <Col sm={4}>
        <ControlLabelWithLoading disabled={disabled}>
          Rotation
        </ControlLabelWithLoading>
      </Col>
      <Col sm={8}>
        <StyledFormControl
          disabled={disabled}
          onChange={(e) => {
            let number = parseInt(e.target.value, 10);
            if (isNaN(number)) {
              number = "";
            }
            if (number > -1 && number < 360) {
              onUpdate({ rotation: number });
            }
          }}
          placeholder="Enter Rotation"
          type="number"
          value={rotation}
        />
        0-360
      </Col>
    </FormGroup>
  );
}

FieldRotation.propTypes = {
  disabled: PropTypes.bool,
  rotation: PropTypes.number,
  onUpdate: PropTypes.func.isRequired,
};
