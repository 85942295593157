/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faBriefcase,
  faLocationDot,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Badge, Image } from "react-bootstrap";

const getTypeColour = (type) => {
  switch (type) {
    case "clients":
      return "#415B86";
    case "sites":
      return "#0094C8";
    case "inspection-status":
      return "#2D72ED";
    case "action-status":
      return "#2D72ED";
    case "status":
      return "#2D72ED";
    default:
      return "#00AE4F";
  }
};

const getTypeIcon = (type) => {
  switch (type) {
    case "date":
      return "/img/sidebar/schedules-icon.png";
    case "inspection-status":
    case "reference":
      return "/img/sidebar/inspections-icon.png";
    case "action-status":
      return "/img/sidebar/actions-icon.png";
    default:
      return `/img/sidebar/${type || "support"}-icon.png`;
  }
};

/**
 * Displays a "Chip" with Icon and text.
 */
function FilterChip({
  type,
  text,
  onRemove,
  onChipPress = () => {},
  hideRemove,
  isLoading = false,
}) {
  return (
    <Badge
      css={css`
        display: inline-block;
        margin-left: 5px;
        height: 40px;
        font-size: 12px;
        border-radius: 5px;
        background-color: ${getTypeColour(type)};
        padding-top: 0;
        padding-bottom: 0;
        font-weight: 500;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 5px;
        margin-top: 1px;
        cursor: pointer;

        svg {
          margin-right: 10px;
        }
      `}
      onClick={onChipPress}
    >
      <span>
        {type === "clients" && (
          <FontAwesomeIcon fade={isLoading} icon={faBriefcase} size="xl" />
        )}

        {type === "sites" && (
          <FontAwesomeIcon fade={isLoading} icon={faLocationDot} size="xl" />
        )}

        {type !== "clients" && type !== "sites" && (
          <Image
            css={css`
              height: 20px;
              padding-right: 10px;
            `}
            src={getTypeIcon(type)}
          />
        )}
      </span>
      <span
        css={{
          lineHeight: "40px",
        }}
      >
        {text}
      </span>

      {!hideRemove && onRemove && (
        <span
          css={{
            lineHeight: "40px",
            marginLeft: 10,
          }}
          onClick={onRemove}
          onKeyDown={onRemove}
        >
          <FontAwesomeIcon icon={faXmark} size="lg" />
        </span>
      )}
    </Badge>
  );
}

FilterChip.propTypes = {
  type: PropTypes.oneOf([
    "clients",
    "sites",
    "inspection-status",
    "action-status",
    "status",
    "date",
    "reference",
  ]),
  text: PropTypes.string,
  onRemove: PropTypes.func,
  onChipPress: PropTypes.func,
  hideRemove: PropTypes.bool,
  isLoading: PropTypes.bool,
};
export default FilterChip;
