import styled from "@emotion/styled";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { Button, ButtonGroup, Image } from "react-bootstrap";

import { LightStyledFormControl } from "views/components/BuildModeSidebar/SharedControls/Generic";

const HeightControl = styled(LightStyledFormControl)`
  width: 90px !important;
`;

export default function SplicesSection({
  disabled,
  splicedFrames = [],
  frameTypes,
  updateSplicedFrames,
  isRear,
}) {
  const patchSplice = useCallback(
    (idx, fields) => {
      updateSplicedFrames([
        ...splicedFrames.slice(0, idx),
        { ...splicedFrames[idx], ...fields },
        ...splicedFrames.slice(idx + 1),
      ]);
    },
    [updateSplicedFrames, splicedFrames],
  );

  return splicedFrames.map((splicedFrame, index) => (
    <tr
      key={`${splicedFrame.FrameTypeId}_${splicedFrame.height}_${splicedFrame.delete}`}
    >
      <td>{index + (isRear ? 1 : 2)}</td>
      <td>
        <HeightControl
          disabled={disabled}
          onChange={(e) => {
            patchSplice(index, { height: parseInt(e.target.value, 10) });
          }}
          type="number"
          value={splicedFrame.height}
        />
      </td>
      <td>
        {frameTypes.length > 0 ? (
          <LightStyledFormControl
            componentClass="select"
            disabled={disabled}
            onChange={(e) => {
              patchSplice(index, { FrameTypeId: e.target.value });
            }}
            placeholder="Frame Type"
            value={splicedFrame.FrameTypeId}
          >
            {frameTypes.map((frameType) => (
              <option key={frameType.id} value={frameType.id}>
                {frameType.name}
              </option>
            ))}
          </LightStyledFormControl>
        ) : (
          <span>No frame types.</span>
        )}
      </td>
      <td>
        <ButtonGroup className="pull-right">
          <Button
            className="icon-btn transparent pull-right"
            onClick={() => {
              patchSplice(index, { delete: !splicedFrame.delete });
            }}
          >
            {splicedFrame.delete ? (
              <FontAwesomeIcon icon={faUndo} />
            ) : (
              <Image responsive src="/img/icons/trash-white.png" />
            )}
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  ));
}

SplicesSection.propTypes = {
  splicedFrames: PropTypes.arrayOf(
    PropTypes.shape({
      delete: PropTypes.bool,
      height: PropTypes.number,
      FrameTypeId: PropTypes.string,
    }),
  ),
  frameTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  updateSplicedFrames: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isRear: PropTypes.bool,
};
