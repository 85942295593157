import PropTypes from "prop-types";
import { FormGroup, Col, Tabs, Tab } from "react-bootstrap";

import { ControlLabelWithLoading } from "views/components/BuildModeSidebar/SharedControls/Generic";

/**
 * TODO: Don't use bootstrap tabs. Use radix button group.
 */
export default function FieldArrangement({ arrangement, onUpdate, disabled }) {
  return (
    <FormGroup>
      <Col sm={4}>
        <ControlLabelWithLoading disabled={disabled}>
          Label bays
        </ControlLabelWithLoading>
      </Col>
      <Col sm={8}>
        <Tabs
          activeKey={!arrangement ? 1 : 2}
          className="custom-offset-position-selector-tabs"
          defaultActiveKey={1}
          onSelect={(key) => onUpdate({ arrangement: key === 2 })}
        >
          <Tab eventKey={1} title="Left to Right" />
          <Tab eventKey={2} title="Right to Left" />
        </Tabs>
      </Col>
    </FormGroup>
  );
}

FieldArrangement.propTypes = {
  arrangement: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
