import { faArchive, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { GreenButton, OrangeButton } from "./colors";

export default function ArchiveButton({ disabled, onClick, archived }) {
  const Btn = archived ? GreenButton : OrangeButton;
  return (
    <Btn disabled={disabled} onClick={onClick}>
      {archived ? (
        <FontAwesomeIcon icon={faUndo} size="2xl" />
      ) : (
        <FontAwesomeIcon icon={faArchive} />
      )}
      {archived ? "Restore" : "Archive"}
    </Btn>
  );
}

ArchiveButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  archived: PropTypes.bool,
};
