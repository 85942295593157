/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useCallback, useId, useState } from "react";
import { MenuItem, Button, FormControl } from "react-bootstrap";

import { useInspectionActions } from "hooks/actions";
import { useInspection, useInspectionProgress } from "hooks/inspections";
import {
  addClassificationFile,
  resolveAction,
  unresolveAction,
} from "libs/api";

import getOptimisedImageUrl from "libs/getOptimisedImageUrl";

import ConfirmModal from "views/components/Modals/ConfirmModal";
import FlatModal, { ModalPaddedBody } from "views/components/Modals/FlatModal";
import UploadButton from "views/components/UploadButton";
import Classification from "views/pages/Inspection/components/classification";

import { StyledEvidenceImage, StyledDropdownButton } from "../styles";

const WithBottomPadding = styled(ModalPaddedBody)`
  padding-bottom: 10px;
`;

const RESOLVE_MODALS = {
  PHOTOS: "photos",
  CONFIRM: "confirm",
  CANTDO: "cantdo",
};

/**
 * An Action is a view that is generated from a complete inspection. Each
 * Action Line is a Classification from the inspection that has to be done by
 * the user: marking it as resolved as "done", "unresolved" as can't do.
 */
export default function ActionLine({ ActionId, classification }) {
  // ID is required for accessibility
  const id = useId();
  const {
    inspection,
    mutate: refreshInspection,
    isLoading,
  } = useInspection(ActionId);
  const { mutate: refreshProgress } = useInspectionProgress(ActionId);
  const { mutate: refreshActions } = useInspectionActions(ActionId, {
    showGreen: false,
  });
  const [showModal, setShowModal] = useState(null);
  const [imageToAttach, setImageToAttach] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [actionNote, setActionNote] = useState(null);

  // TODO: Include a loading state
  const handleRepairDone = useCallback(
    async (resolve) => {
      if (
        inspection.photoEvidenceRequired &&
        inspection.status === "Unfinished" &&
        !resolve
      ) {
        setShowModal(RESOLVE_MODALS.PHOTOS);
        return;
      }
      await resolveAction(classification.id, false, "");
      if (imageToAttach) {
        await addClassificationFile({
          remotePath: imageToAttach.remotePath,
          ClassificationId: classification.id,
          caption: imageToAttach.caption,
          actionFile: true,
        });
      }
      await refreshActions();
      await refreshInspection();
      await refreshProgress();
      setShowModal(null);
      setImageToAttach(null);
    },
    [
      classification.id,
      imageToAttach,
      refreshActions,
      refreshInspection,
      refreshProgress,
      inspection,
    ],
  );

  // Decides how to resolve, confirm or photo evidence
  const handleResolve = useCallback(() => {
    if (
      inspection.photoEvidenceRequired &&
      inspection.status !== "Unfinished"
    ) {
      setShowModal(RESOLVE_MODALS.PHOTOS);
    } else if (inspection.status === "Unfinished") {
      setShowModal(RESOLVE_MODALS.CONFIRM);
    } else {
      handleRepairDone();
    }
  }, [handleRepairDone, inspection?.photoEvidenceRequired, inspection?.status]);

  const handleUnresolve = useCallback(
    async (id) => {
      if (!id) return;
      await unresolveAction(id);
      // TODO: Images are deleted here on staging, but this should be handled by the API
      await refreshActions();
      await refreshInspection();
      await refreshProgress();
    },
    [refreshActions, refreshInspection, refreshProgress],
  );

  const handleCantDo = useCallback(async () => {
    if (!actionNote) return;
    await resolveAction(classification.id, true, actionNote);
    await refreshActions();
    await refreshInspection();
    await refreshProgress();
    setActionNote("");
    setShowModal(null);
  }, [
    actionNote,
    classification.id,
    refreshActions,
    refreshInspection,
    refreshProgress,
  ]);

  const notResolved = classification.cantResolveAt ? "Can't Do" : "To Do";
  const classificationStatus = classification.resolvedAt ? "Done" : notResolved;

  if (isLoading) return null;
  return (
    <>
      <Classification
        InspectionId={ActionId}
        classification={classification}
        key={classification.id}
        showAction={classificationStatus !== "To Do"}
        showOffloadedLocations={classification.offloadedLocations?.length}
        isRepair
      >
        <StyledDropdownButton
          classificationStatus={classificationStatus}
          disabled={
            inspection.status === "Completed" ||
            (inspection.status === "Unfinished" &&
              classificationStatus === "Done")
          }
          id={id}
          resolvedAt={classification.resolvedAt}
          risk={classification.risk}
          status={inspection.status}
          title={classificationStatus}
        >
          {classificationStatus !== "To Do" &&
          inspection.status !== "Unfinished" ? (
            <MenuItem onClick={() => handleUnresolve(classification.id)}>
              To Do
            </MenuItem>
          ) : null}
          {classificationStatus !== "Done" ? (
            <MenuItem onClick={() => handleResolve()}>Repair Done</MenuItem>
          ) : null}
          {classificationStatus !== "Can't Do" ? (
            <MenuItem onClick={() => setShowModal(RESOLVE_MODALS.CANTDO)}>
              Can&apos;t do repair
            </MenuItem>
          ) : null}
        </StyledDropdownButton>
      </Classification>
      {showModal === RESOLVE_MODALS.PHOTOS && (
        <FlatModal
          closeButton
          onHide={() => {
            setImageToAttach(null);
            setShowModal(null);
          }}
          show
          title="Photo evidence required"
          width={400}
        >
          <WithBottomPadding>
            {!imageToAttach ? (
              <UploadButton
                onFileUploadFailed={() => setIsUploading(false)}
                onFileUploadStarted={() => setIsUploading(true)}
                onFileUploaded={async (file) => {
                  setImageToAttach({
                    remotePath: file.location,
                    caption: "",
                    id: 1,
                    ClassificationId: classification.id,
                  });
                  setIsUploading(false);
                }}
              >
                {isUploading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <FontAwesomeIcon icon={faPlus} />
                )}
              </UploadButton>
            ) : (
              <>
                <StyledEvidenceImage
                  src={
                    imageToAttach.remotePath
                      ? getOptimisedImageUrl(imageToAttach.remotePath)
                      : "/img/defaults/logo.png"
                  }
                />
                <Button
                  onClick={() => {
                    setImageToAttach(null);
                    setShowModal(null);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={() => handleRepairDone(true)}>
                  Confirm Repair
                </Button>
              </>
            )}
          </WithBottomPadding>
        </FlatModal>
      )}
      {showModal === RESOLVE_MODALS.CONFIRM && (
        <ConfirmModal
          closeButton
          confirmButtonText="Repair Done"
          onConfirm={() => handleRepairDone(false)}
          onHide={() => setShowModal(null)}
          show
          title="Confirm Repair Done"
          width={400}
        >
          Are you sure you want to set this action to be &quot;Repair
          Done&quot;? This cannot be undone.
        </ConfirmModal>
      )}
      {showModal === RESOLVE_MODALS.CANTDO && (
        <FlatModal
          closeButton
          onHide={() => setShowModal(null)}
          show
          title="Can't do repair"
          width={400}
        >
          <FormControl
            componentClass="textarea"
            onChange={(e) => setActionNote(e.target.value)}
            placeholder="Description of why you can't carry out the repair..."
            value={actionNote ?? ""}
          />
          <Button onClick={() => setShowModal(null)}>Cancel</Button>
          <Button disabled={!actionNote} onClick={handleCantDo}>
            Can&apos;t do repair
          </Button>
        </FlatModal>
      )}
    </>
  );
}

ActionLine.propTypes = {
  ActionId: PropTypes.number.isRequired,
  classification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    resolvedAt: PropTypes.string,
    cantResolveAt: PropTypes.string,
    risk: PropTypes.string.isRequired,
    offloadedLocations: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
