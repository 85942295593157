/** @jsxImportSource @emotion/react */
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { useInspections } from "hooks/inspections";
import { useCurrentUser } from "hooks/user";

import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import { Table } from "views/components/Tables/styles";
import TableHeader from "views/components/Tables/TableHeader";

import { getInspectionsLink, renderPadding } from "../helpers";

import InspectionRow, { PaddingRow } from "./InspectionRow";

export default function InspectionsTable({
  SiteId,
  ClientId,
  InspectorId,
  filters: additionalFilters = {},
}) {
  const filters = {
    SiteId,
    ClientId,
    InspectorId,
    amount: 4,
    ...additionalFilters,
  };
  const { inspections, isLoading } = useInspections(filters);
  const { total: totalAll } = useInspections({ ...filters, InspectorId: null });

  const { user } = useCurrentUser();

  const paddingAmount = isLoading ? 0 : 4 - inspections.length;
  const showPadding = !isLoading && paddingAmount !== 4; // Loaded, and min 1 row
  const showEmptyTableIndicator =
    isLoading || (!isLoading && inspections.length === 0); // Loading or no rows

  return (
    <>
      <TableHeader
        left={InspectorId === user?.id ? "My Inspections" : "Inspections"}
        right={
          <Link to={getInspectionsLink(ClientId, SiteId, true)}>
            View all {totalAll} inspections{" "}
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        }
      />
      <Table>
        <thead>
          <tr>
            {!ClientId ? <th>Client</th> : null}
            {!SiteId ? <th>Site</th> : null}
            <th>Ref</th>
            <th>Status</th>
            <th>Conducted</th>
            {ClientId ? <th>Completed</th> : null}
          </tr>
        </thead>
        <tbody>
          {inspections.map((inspection) => (
            <InspectionRow
              inspection={inspection}
              key={inspection.id}
              showClient={!ClientId}
              showCompleted={!!ClientId}
              showInspector={false}
              showSite={!SiteId}
            />
          ))}
          {showPadding
            ? renderPadding(
                () => (
                  <PaddingRow
                    showClient={!ClientId}
                    showCompleted={!!ClientId}
                    showInspector={false}
                    showSite={!SiteId}
                  />
                ),
                paddingAmount,
              )
            : null}
        </tbody>
      </Table>
      {showEmptyTableIndicator ? (
        <EmptyTableIndicator isLoading={isLoading} type="Inspections" />
      ) : null}
    </>
  );
}

InspectionsTable.propTypes = {
  SiteId: PropTypes.string,
  ClientId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
  InspectorId: PropTypes.string,
};
