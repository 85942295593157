import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { BlueButton } from "./colors";

export default function EditUserButton({ disabled, onClick }) {
  return (
    <BlueButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={faUserEdit} />
      Edit User
    </BlueButton>
  );
}

EditUserButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
