import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";

import { useDashboard } from "hooks/dashboard";

export default function DamageClassificationCharts({ filters }) {
  const { dashboardData, isLoading, isError } = useDashboard(filters);
  if (isLoading || isError || !dashboardData) return null;

  const { risks } = dashboardData;
  const totalRisks = (risks.Red || 0) + (risks.Amber || 0) + (risks.Green || 0);
  return (
    <>
      <Col className="circular-progress" sm={4}>
        <CircularProgressbar
          className="red"
          text={`${risks.Red || 0}`}
          value={risks.Red ? (risks.Red / totalRisks) * 100 : 0}
        />
      </Col>
      <Col className="circular-progress" sm={4}>
        <CircularProgressbar
          className="amber"
          text={`${risks.Amber || 0}`}
          value={risks.Amber ? (risks.Amber / totalRisks) * 100 : 0}
        />
      </Col>
      <Col className="circular-progress" sm={4}>
        <CircularProgressbar
          className="green"
          text={`${risks.Green || 0}`}
          value={risks.Green ? (risks.Green / totalRisks) * 100 : 0}
        />
      </Col>
    </>
  );
}

DamageClassificationCharts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
};
