import { getLocationReport } from "./api";

export default async function downloadLocationReport(
  SiteId,
  ClientName,
  SiteName,
  filters,
) {
  const res = await getLocationReport(SiteId, filters);
  const blob = await res.blob();
  const fileURL = URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.href = fileURL;
  tempLink.setAttribute("download", `${ClientName} - ${SiteName}.pdf`);
  tempLink.click();
}
