import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { LightStyledFormControl } from "views/components/BuildModeSidebar/SharedControls/Generic";

import UprightSleeve from "./UprightSleeve";

const SacLegHeaderCol = styled(Col)`
  color: #fff;
  margin-bottom: 10px;
`;

export default function SacLegSleeve({
  sacLegHeight,
  sacSpec,
  setSacLegHeight,
  setSacSpec,
  setSleeve,
  setSleeveHeight,
  setSleeveSpacer,
  sleeve,
  sleeveHeight,
  sleeveSpacer,
}) {
  return (
    <>
      <Col sm={6}>Height (mm)</Col>
      <Col sm={6}>
        <LightStyledFormControl
          min="0"
          onChange={(e) => setSacLegHeight(parseInt(e.target.value, 10))}
          type="number"
          value={sacLegHeight ?? 0}
        />
      </Col>
      <UprightSleeve
        setSleeve={setSleeve}
        setSleeveHeight={setSleeveHeight}
        setSleeveSpacer={setSleeveSpacer}
        sleeve={sleeve}
        sleeveHeight={sleeveHeight}
        sleeveSpacer={sleeveSpacer}
      />
      <SacLegHeaderCol sm={12}>Sacrificial Leg Spec</SacLegHeaderCol>
      <Col sm={12}>
        <LightStyledFormControl
          onChange={(e) => setSacSpec(e.target.value)}
          value={sacSpec}
        />
      </Col>
    </>
  );
}

SacLegSleeve.propTypes = {
  sacLegHeight: PropTypes.number,
  sacSpec: PropTypes.string,
  setSacLegHeight: PropTypes.func.isRequired,
  setSacSpec: PropTypes.func,
  setSleeve: PropTypes.func.isRequired,
  setSleeveHeight: PropTypes.func.isRequired,
  setSleeveSpacer: PropTypes.func.isRequired,
  sleeve: PropTypes.bool,
  sleeveHeight: PropTypes.number,
  sleeveSpacer: PropTypes.number,
};
