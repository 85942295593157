import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useReducer, useState } from "react";
import {
  Alert,
  Checkbox,
  Col,
  FormControl,
  Grid,
  Panel,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";

import { useClientSiteGroups } from "hooks/client";
import { useSites } from "hooks/site";
import ButtonWide from "views/components/Buttons/button-wide";
import EditorPanel from "views/components/EditorPanel";
import ConfirmModal from "views/components/Modals/ConfirmModal";

import { AddButton, AddControls, AddFormControl, Group } from "../styles";

const editingSiteGroupReducer = (state = [], action) => {
  switch (action.type) {
    case "ADD_SITE":
      return [
        ...state.filter((s) => s.siteId !== action.id),
        { siteId: action.id, type: "add" },
      ];
    case "REMOVE_SITE":
      return [
        ...state.filter((s) => s.siteId !== action.id),
        { siteId: action.id, type: "remove" },
      ];
    default:
      return state;
  }
};

function EditSiteGroup({ SiteGroupId, ClientId, onClose }) {
  const { groups, handleEditSiteGroup, handleDeleteSiteGroup, mutate: refreshSiteGroups } = useClientSiteGroups(ClientId); // prettier-ignore
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState(null);
  const [nameEdit, setNameEdit] = useState(null);
  const {
    sites,
    isLoading: isLoadingSites,
    handleEditSite,
  } = useSites({ ClientId });

  const siteGroup = groups?.find((g) => g.id === SiteGroupId);
  const currentGroupSiteIds = siteGroup?.sites.map((s) => s.id) ?? [];
  const [editedSitesInGroup, dispatch] = useReducer(
    editingSiteGroupReducer,
    [],
  );

  const addedSiteIds = editedSitesInGroup
    .filter((s) => s.type === "add")
    .map((s) => s.siteId);
  const removedSiteIds = editedSitesInGroup
    .filter((s) => s.type === "remove")
    .map((s) => s.siteId);
  const editedGroupSiteIds = currentGroupSiteIds
    .filter((s) => !removedSiteIds.includes(s))
    .concat(addedSiteIds);

  const handleSave = async () => {
    try {
      await handleEditSiteGroup(SiteGroupId, nameEdit ?? siteGroup.name);

      const sitesToAddToGroup = sites
        .filter((s) => addedSiteIds.includes(s.id))
        .map((s) => ({ ...s, SiteGroupId }));
      const sitesToRemoveFromGroup = sites
        .filter((s) => removedSiteIds.includes(s.id))
        .map((s) => ({ ...s, SiteGroupId: null }));

      // TODO: Refactor API so it accepts a bulk edit of sites
      await Promise.all([
        ...sitesToAddToGroup.map((s) => handleEditSite(s)),
        ...sitesToRemoveFromGroup.map((s) => handleEditSite(s)),
      ]);

      refreshSiteGroups();
      toast.success(`Edited: ${nameEdit ?? siteGroup.name}`);
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDelete = async () => {
    try {
      await handleDeleteSiteGroup(SiteGroupId);
      toast.info(`Deleted ${siteGroup.name}`);
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  if (!siteGroup) return null;
  return (
    <>
      <EditorPanel>
        <Panel.Heading>Editing Site Group</Panel.Heading>
        <Panel.Body>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <FormControl
                  onChange={(e) => setNameEdit(e.target.value)}
                  value={nameEdit ?? siteGroup.name}
                />
                {sites.map((s) => {
                  const isSelected = editedGroupSiteIds.includes(s.id);
                  return (
                    <div key={s.id}>
                      <Checkbox
                        checked={isSelected}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch({ type: "ADD_SITE", id: s.id });
                          } else {
                            dispatch({ type: "REMOVE_SITE", id: s.id });
                          }
                        }}
                      >
                        {s.name}{" "}
                        {s.SiteGroupId && s.SiteGroupId !== SiteGroupId
                          ? `(Current group: ${
                              groups.find((g) => g.id === s.SiteGroupId)?.name
                            })`
                          : null}
                      </Checkbox>
                    </div>
                  );
                })}

                {!isLoadingSites && sites.length === 0 ? "No sites" : null}

                <AddControls>
                  <ButtonWide
                    bsStyle="danger"
                    onClick={() => setShowDeleteModal(true)}
                    size="sm"
                  >
                    Delete
                  </ButtonWide>
                  <div>
                    <ButtonWide onClick={onClose} size="sm">
                      Cancel
                    </ButtonWide>
                    <ButtonWide onClick={handleSave} size="sm">
                      Save
                    </ButtonWide>
                  </div>
                </AddControls>

                {error ? (
                  <Alert bsStyle="danger" hidden={!error}>
                    {error}
                  </Alert>
                ) : null}
              </Col>
            </Row>
          </Grid>
        </Panel.Body>
      </EditorPanel>
      {showDeleteModal ? (
        <ConfirmModal
          onConfirm={handleDelete}
          onHide={() => setShowDeleteModal(false)}
          show
          title="Delete Group"
        >
          <p>Are you sure you want to delete this Site Group?</p>
          <p>
            This can not be undone, and all related data will also be deleted.
          </p>
        </ConfirmModal>
      ) : null}
    </>
  );
}

EditSiteGroup.propTypes = {
  SiteGroupId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  ClientId: PropTypes.string.isRequired,
};

function AddSiteGroup({ onCreateSiteGroup }) {
  const [newGroupName, setNewGroupName] = useState("");
  const [error, setError] = useState(null);

  const handleCreateSiteGroup = async () => {
    try {
      await onCreateSiteGroup(newGroupName);
      toast.success(`Created group: ${newGroupName}`);
      setNewGroupName("");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Grid fluid>
      <Row>
        <Col sm={12}>
          <AddFormControl
            onChange={(e) => setNewGroupName(e.target.value)}
            placeholder="Add a new Group"
            value={newGroupName}
          />
          <AddButton disabled={!newGroupName} onClick={handleCreateSiteGroup}>
            Add
          </AddButton>
        </Col>
      </Row>
      {error ? (
        <Row>
          <Col sm={12}>
            <Alert bsStyle="danger">{error}</Alert>
          </Col>
        </Row>
      ) : null}
    </Grid>
  );
}

AddSiteGroup.propTypes = {
  onCreateSiteGroup: PropTypes.func.isRequired,
};

export default function SiteGroups({ ClientId }) {
  const { groups, handleCreateSiteGroup } = useClientSiteGroups(ClientId);
  const [editingSiteId, setEditingSiteId] = useState(null);
  if (!ClientId) return null;
  if (editingSiteId)
    return (
      <EditSiteGroup
        ClientId={ClientId}
        SiteGroupId={editingSiteId}
        onClose={() => setEditingSiteId(null)}
      />
    );
  return (
    <EditorPanel>
      <Panel.Heading>Site Groups</Panel.Heading>
      <Panel.Body>
        <Grid fluid>
          <Row>
            <Col sm={12}>
              {groups.map((g) => (
                <Group key={g.id}>
                  {g.name} ({g.sites?.length} site
                  {g.sites?.length === 1 ? "" : "s"}
                  {g.sites?.length > 0
                    ? `: ${g.sites.map((s) => s.name).join(", ")}`
                    : null}
                  )
                  <button key={g.id} onClick={() => setEditingSiteId(g.id)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                </Group>
              ))}
            </Col>
          </Row>
        </Grid>
        <AddSiteGroup onCreateSiteGroup={handleCreateSiteGroup} />
      </Panel.Body>
    </EditorPanel>
  );
}

SiteGroups.propTypes = {
  ClientId: PropTypes.string,
};
