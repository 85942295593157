import { Text, Graphics, Container } from "@pixi/react";

import PropTypes from "prop-types";
import React, { useCallback } from "react";

import { SCALE_FACTOR } from "hooks/use-site-blueprint";

const AREA_PADDING = 600 * SCALE_FACTOR;
const areaNameStyle = {
  align: "center",
  fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
  fontSize: 250 * SCALE_FACTOR,
  fontWeight: 300,
  fill: 0x2d72ed,
};

/**
 * A box showing a warehouse area with name.
 *
 * Padding is applied to an area so it is not drawn right up to the edge of the
 * racks, but leaves a space around them.
 *
 */
function Area({ maxX, maxY, minX, minY, name }) {
  const width = maxX - minX;
  const height = maxY - minY;
  /** Takes the minX and minY coordiates and then uses width and height to draw rect */
  const draw = useCallback(
    (g) => {
      g.clear();
      g.lineStyle(5 * SCALE_FACTOR, 0x696969);
      g.beginFill(0x696969, 0.25);
      g.drawRect(
        minX - AREA_PADDING,
        minY - AREA_PADDING,
        width + AREA_PADDING * 2,
        height + AREA_PADDING * 2,
      );
      g.endFill();
    },
    [minX, minY, height, width],
  );

  return (
    <Container eventMode="auto">
      <Graphics draw={draw} />
      <Text
        style={areaNameStyle}
        text={name}
        x={minX - AREA_PADDING * 0.9}
        y={minY - AREA_PADDING * 0.9}
      />
    </Container>
  );
}

Area.propTypes = {
  maxX: PropTypes.number,
  maxY: PropTypes.number,
  minX: PropTypes.number,
  minY: PropTypes.number,
  name: PropTypes.string,
};

export default React.memo(Area);
