import styled from "@emotion/styled";
import PropTypes from "prop-types";

import { LightStyledFormControl } from "views/components/BuildModeSidebar/SharedControls/Generic";

const HeightControl = styled(LightStyledFormControl)`
  width: 90px !important;
`;

export default function FrameTemplateSections({
  disabled,
  frameTemplate,
  frameTypes,
  updateFrameTemplate,
}) {
  return (
    <tr>
      <td>1</td>
      <td>
        <HeightControl
          disabled={disabled}
          onChange={(e) => {
            updateFrameTemplate({ height: parseInt(e.target.value, 10) });
          }}
          type="number"
          value={frameTemplate.height ?? ""}
        />
      </td>
      <td>
        {frameTypes.length > 0 ? (
          <LightStyledFormControl
            componentClass="select"
            disabled={disabled}
            onChange={(e) => {
              updateFrameTemplate({
                FrameTypeId: e.target.value,
                type: frameTemplate.type
                  ? { ...frameTemplate.type, id: e.target.value }
                  : undefined,
              });
            }}
            placeholder="Frame Type"
            value={frameTemplate.FrameTypeId ?? ""}
          >
            <option disabled value="">
              Please select
            </option>
            {frameTypes.map((frameType) => (
              <option key={frameType.id} value={frameType.id}>
                {frameType.name}
              </option>
            ))}
          </LightStyledFormControl>
        ) : (
          <span>No frame types.</span>
        )}
      </td>
      <td />
    </tr>
  );
}

FrameTemplateSections.propTypes = {
  updateFrameTemplate: PropTypes.func.isRequired,
  frameTemplate: PropTypes.shape({
    ManufacturerId: PropTypes.string,
    FrameTypeId: PropTypes.string,
    type: PropTypes.shape({
      id: PropTypes.string,
    }),
    height: PropTypes.number,
  }),
  frameTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
};
