import PropTypes from "prop-types";

import { GrouperRow } from "views/components/Tables/GrouperRow";
import ActionRow from "views/pages/Actions/components/ActionRow";
export default function ActionRows({
  actions = [],
  client,
  getData,
  onRequestPDF,
  onRequestShare,
  onSelectAction,
  site,
}) {
  const bookmarkedActions = actions.filter((action) => action.bookmarked);
  const nonBookmarkedActions = actions.filter((action) => !action.bookmarked);
  const showDividerRow =
    nonBookmarkedActions.length > 0 && bookmarkedActions.length > 0;
  return (
    <>
      {bookmarkedActions.length > 0 ? (
        <GrouperRow>
          <td colSpan="11">Bookmarked Actions</td>
        </GrouperRow>
      ) : null}
      {bookmarkedActions.map((action) => (
        <ActionRow
          action={action}
          getData={getData}
          key={action.id}
          onRequestPDF={() => onRequestPDF(action)}
          onRequestShare={onRequestShare}
          onSelectAction={onSelectAction}
          showClient={!client}
          showSite={!site}
        />
      ))}
      {showDividerRow ? (
        <GrouperRow>
          <td colSpan="11"> </td>
        </GrouperRow>
      ) : null}
      {nonBookmarkedActions.map((action) => (
        <ActionRow
          action={action}
          getData={getData}
          key={action.id}
          onRequestPDF={() => onRequestPDF(action)}
          onRequestShare={() => onRequestShare(action)}
          onSelectAction={onSelectAction}
          showClient={!client}
          showSite={!site}
        />
      ))}
    </>
  );
}

ActionRows.defaultProps = {};

ActionRows.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  site: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object,
  onRequestShare: PropTypes.func,
  onRequestPDF: PropTypes.func,
  onSelectAction: PropTypes.func,
  getData: PropTypes.func,
};
