import styled from "@emotion/styled";
import { Tabs as BsTabs } from "react-bootstrap";

const Tabs = styled(BsTabs)`
  ul > li {
    width: 150px;
    a {
      color: #666666;
      text-align: center;
      border: 0px;
    }
  }
  ul > li.active,
  ul > li:hover,
  ul > li:focus {
    a,
    a:hover,
    a:focus {
      color: #2d71ed;
      border: 0px;
      background-color: #00000000;
    }
    border-bottom: 3px #2d71ed solid;
  }
  .tab-content {
    margin-top: 15px;
  }
`;

export default Tabs;
