import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { useSite } from "hooks/site";
import { useWarehouse } from "hooks/warehouse";
import { addWarehouse, deleteWarehouse, editWarehouse } from "libs/api";

import PageWrapper from "views/components/common/PageWrapper";
import Loading from "views/components/Loading";
import ConfirmModal from "views/components/Modals/ConfirmModal";
import TableHeader from "views/components/Tables/TableHeader";
import WarehouseDetails from "views/pages/WarehousesEditor/Details";

const SizedButton = styled(Button)`
  width: 125px;
`;

const ButtonPanel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SAVE_STATUS = {
  none: "none",
  saving: "saving",
  success: "success",
};

export function WarehouseEditor({
  goToSiteBlueprint,
  goToSiteWarehouses,
  SiteId,
  Warehouse,
  WarehouseId,
}) {
  const {
    warehouse,
    isLoading: isLoadingWarehouse,
    isError,
  } = useWarehouse(WarehouseId);
  const { site, isLoading: isLoadingSite } = useSite(
    SiteId || warehouse?.SiteId,
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editedWarehouse, setEditedWarehouse] = useState({});
  const [saveStatus, setSavedStatus] = useState(SAVE_STATUS.none);
  const [error, setError] = useState(false);

  const returnToWarehouse = !!Warehouse;

  const isEdit = !!warehouse?.id;

  const combinedWarehouse = useMemo(
    () => ({ ...warehouse, ...editedWarehouse }),
    [warehouse, editedWarehouse],
  );

  const save = useCallback(async () => {
    let res = null;
    try {
      if (isEdit) {
        const { name, sizeX, sizeY, id } = combinedWarehouse;
        res = await editWarehouse({ id, name, sizeX, sizeY });
      } else {
        const { name, sizeX, sizeY } = combinedWarehouse;

        res = await addWarehouse({
          SiteId: site?.id,
          name,
          sizeX,
          sizeY,
        });
      }

      if (returnToWarehouse) {
        goToSiteBlueprint(res.SiteId, res.id);
      } else {
        goToSiteWarehouses(res.SiteId);
      }
    } catch (e) {
      setSavedStatus(SAVE_STATUS.none);
      setError(res.status);
    }
  }, [
    combinedWarehouse,
    goToSiteBlueprint,
    goToSiteWarehouses,
    isEdit,
    returnToWarehouse,
    site?.id,
  ]);

  if ((isLoadingWarehouse && WarehouseId) || isLoadingSite) {
    return <Loading height="calc(100vh - 50px)" />;
  }

  if (isError) {
    return <div>There was an error loading this warehouse.</div>;
  }
  return (
    <PageWrapper>
      <TableHeader left={isEdit ? "Edit Warehouse" : "Add Warehouse"} />
      <WarehouseDetails
        setField={(key, val) =>
          setEditedWarehouse((edit) => ({ ...edit, [key]: val }))
        }
        warehouse={{ ...warehouse, ...editedWarehouse }}
      />
      <ButtonPanel>
        <SizedButton className="red" onClick={() => setShowDeleteModal(true)}>
          Delete
        </SizedButton>
        <div>
          {returnToWarehouse ? (
            <SizedButton
              className="cancel"
              onClick={() => goToSiteBlueprint(site.id)}
            >
              Back
            </SizedButton>
          ) : (
            <SizedButton
              className="cancel"
              onClick={() => goToSiteWarehouses(site.id)}
            >
              Cancel
            </SizedButton>
          )}
          <SizedButton
            disabled={
              !combinedWarehouse?.sizeX ||
              !combinedWarehouse?.sizeY ||
              !combinedWarehouse?.name
            }
            onClick={save}
          >
            {isEdit ? "Save" : "Save & Add"}
          </SizedButton>
        </div>
      </ButtonPanel>
      {saveStatus === SAVE_STATUS.success ? "Saved Successfully" : null}
      {error ? `Problem Saving: ${error}` : null}
      {isEdit && (
        <ConfirmModal
          onConfirm={async () => {
            const res = await deleteWarehouse(warehouse.id);
            setShowDeleteModal(false);
            if (res.ok) {
              goToSiteWarehouses(site.id);
            } else {
              setError(res.status);
              setSavedStatus(SAVE_STATUS.none);
            }
          }}
          onHide={() => setShowDeleteModal(false)}
          show={showDeleteModal}
          title="Delete Warehouse"
        >
          <h4>Are you sure you want to delete this Warehouse?</h4>
          <h4>
            This can not be undone, and all related data will also be deleted.
          </h4>
        </ConfirmModal>
      )}
    </PageWrapper>
  );
}

WarehouseEditor.propTypes = {
  goToSiteBlueprint: PropTypes.func.isRequired,
  goToSiteWarehouses: PropTypes.func.isRequired,
  SiteId: PropTypes.string,
  Warehouse: PropTypes.shape({
    id: PropTypes.string,
  }),
  WarehouseId: PropTypes.string,
};

function WarehouseEditorWithParams() {
  const { SiteId, WarehouseId, Warehouse } = useParams();
  const navigate = useNavigate();
  return (
    <WarehouseEditor
      SiteId={SiteId}
      Warehouse={Warehouse}
      WarehouseId={WarehouseId}
      goToSiteBlueprint={(blueprintId, warehouseId) =>
        navigate(
          `/blueprint/${blueprintId}${warehouseId ? `/${warehouseId}` : ""}`,
        )
      }
      goToSiteWarehouses={(id) => navigate(`/clients/sites/${id}/warehouses`)}
    />
  );
}

export default WarehouseEditorWithParams;
