import PropTypes from "prop-types";
import { useState } from "react";

import { useInspection, useInspectionProgress } from "hooks/inspections";

import ButtonCompleteCheck, {
  getActionsCompleteableState,
} from "views/components/Buttons/ButtonCompleteCheck";
import ActionOverviewModal from "views/components/Modals/ActionOverviewModal";
import InspectionComplete from "views/pages/Inspection/components/InspectionCompleteModal";

/**
 * Button that when clicked either shows the ActionOverviewModal to detail
 * which areas are incomplete, or if all areas are complete, shows the
 * InspectionCompleteModal
 */
export default function ButtonCompleteAction({ InspectionId }) {
  const { inspection, isLoading } = useInspection(InspectionId);
  const { progress: inspectionProgress, isLoading: isLoadingProgress } =
    useInspectionProgress(InspectionId);
  const [showModal, setShowModal] = useState(false);
  if (isLoading || isLoadingProgress) return null;
  return (
    <>
      <ButtonCompleteCheck
        // Don't show modal if the inspection has been signed off
        onClick={() =>
          (!inspection?.signedOffAt ||
            (inspection.unfinished && inspectionProgress.isCompletable)) &&
          setShowModal(!showModal)
        }
        state={getActionsCompleteableState(inspectionProgress)}
      />
      {showModal && !inspectionProgress?.isCompletable && (
        <ActionOverviewModal
          InspectionId={InspectionId}
          onHide={() => setShowModal(false)}
        />
      )}
      {showModal &&
      (!inspection.signedOffAt || inspection.unfinished) &&
      inspectionProgress?.isCompletable ? (
        <InspectionComplete
          inspection={inspection}
          isAction
          onHide={() => setShowModal(false)}
          showCompleteInspection
        />
      ) : null}
    </>
  );
}

ButtonCompleteAction.propTypes = {
  InspectionId: PropTypes.string,
};
