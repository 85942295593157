import PropTypes from "prop-types";

import Notes from "../SharedControls/Notes";

import SectionDetails from "./SectionDetails";
import SectionFrameComponents from "./SectionFrameComponents";

export default function Body({
  frameTemplate,
  isCopy,
  updateFrameTemplate,
  siteId,
}) {
  // SeriesId is passed in the body for new frame templates, but is nested in the type object for existing frame templates
  const SeriesId = frameTemplate?.type?.SeriesId ?? frameTemplate.SeriesId;
  const isItemsSectionDisabled = !frameTemplate.ManufacturerId || !SeriesId || !frameTemplate.BasePlateId || !frameTemplate.depth || (!frameTemplate.ColourId && !frameTemplate.colourOther); // prettier-ignore
  return (
    <div>
      <SectionDetails
        SeriesId={SeriesId}
        frameTemplate={frameTemplate}
        isCopy={isCopy}
        siteId={siteId}
        updateFrameTemplate={updateFrameTemplate}
      />
      <SectionFrameComponents
        disabled={isItemsSectionDisabled}
        frameTemplate={frameTemplate}
        updateFrameTemplate={updateFrameTemplate}
      />
      <Notes
        disabled={isItemsSectionDisabled}
        notes={frameTemplate.notes}
        setNotes={(val) => updateFrameTemplate({ notes: val })}
      />
    </div>
  );
}

Body.propTypes = {
  frameTemplate: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    splicedFrames: PropTypes.array,
    notes: PropTypes.string,
    ManufacturerId: PropTypes.string,
    ColourId: PropTypes.string,
    BasePlateId: PropTypes.string,
    sacLegHeight: PropTypes.number,
    sacLeg: PropTypes.bool,
    sleeveHeight: PropTypes.number,
    sleeveSpacer: PropTypes.number,
    colourOther: PropTypes.string,
    depth: PropTypes.number,
    SeriesId: PropTypes.string,
    type: PropTypes.shape({
      SeriesId: PropTypes.string,
    }),
    sleeve: PropTypes.bool,
    sacSpec: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    isFrameTemplateUsed: PropTypes.bool,
  }),
  updateFrameTemplate: PropTypes.func,
  siteId: PropTypes.string,
  isCopy: PropTypes.bool,
};
