import styled from "@emotion/styled";

import HelpLogoutWidget from "views/components/Sidebar/HelpLogoutWidget";
import NavigationWidget from "views/components/Sidebar/NavigationWidget";
import UserWidget from "views/components/Sidebar/UserWidget";

const Wrapper = styled.aside`
  background-color: #30333c;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: space-between;
  margin-top: 50px;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  width: 225px;
  flex: none;
`;

export default function Sidebar() {
  return (
    <Wrapper>
      <div>
        <UserWidget />
        <NavigationWidget />
      </div>
      <HelpLogoutWidget />
    </Wrapper>
  );
}
