import styled from "@emotion/styled";
import {
  faChevronLeft,
  faExpand,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import { useState, useId, useRef, useMemo } from "react";
import { Button, Panel, Tab } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { useInspection } from "hooks/inspections";
import { useScaledBlueprintDisplayClassifications } from "hooks/use-blueprint-display-classifications";
import useBlueprintSizer from "hooks/use-blueprint-sizer";
import { useScaledSiteBlueprint } from "hooks/use-site-blueprint";
import { getDamage } from "libs/blueprint";
import { PRIMARY_DATETIME_FORMAT } from "libs/constants";
import BlueprintFilterOptions from "views/components/Blueprint/BlueprintFilterOptions";
import Area from "views/components/Blueprint/canvas-elements/area";
import Beams from "views/components/Blueprint/canvas-elements/beams";
import DamageClassifications from "views/components/Blueprint/canvas-elements/damage-classifications";
import { FramesForBays } from "views/components/Blueprint/canvas-elements/frames";
import Grid from "views/components/Blueprint/canvas-elements/grid";
import Rack from "views/components/Blueprint/canvas-elements/rack";
import StageViewport from "views/components/Blueprint/canvas-elements/stage-viewport";
import ZoomSelect from "views/components/Blueprint/canvas-elements/zoom-select";
import NavigationBar from "views/components/Blueprint/navigation-bar";
import {
  BackLink,
  MainContainer,
  BottomTabs,
  StageContainer,
  MainAndSidebar,
  Sidebar,
  NavigationSection,
} from "views/components/Blueprint/styles";
import ClientLogo from "views/components/ClientLogo";
import FilterChip from "views/components/FilterChip";
import Loading from "views/components/Loading";
import FlatModal, { ModalPaddedBody } from "views/components/Modals/FlatModal";
import Classification from "views/pages/Inspection/components/classification";

const RoundButton = styled(Button)`
  color: white;
`;

/**
 * Display a read-only warehouse with the latest classifications.
 *
 * Note: The racks display, and then the damage classifications are layered
 * over the top. The hook returns rack information alongside the classifications,
 * so it renders the positions in the same way across both components, it just
 * doesn't draw the racks in the DisplayClassifications component.
 */
export default function PageInspectionBlueprintView({
  SiteId,
  WarehouseId,
  InspectionId,
}) {
  const warehouseTabsId = useId(); // Accessible ID for the tabs
  const viewportRef = useRef(); // Access to the pixi viewport instance
  const navigate = useNavigate();
  const [isPanLocked, setPanLock] = useState(false);

  const { inspection, isLoading: isLoadingInspection } =
    useInspection(InspectionId);

  const gridRef = useRef();

  const { site, warehouse, areas, isLoading } = useScaledSiteBlueprint(
    SiteId,
    WarehouseId,
    InspectionId,
    !!inspection.completedAt,
  );

  const [filters, setFilters] = useState({
    showDisplay: true,
    showNone: false,
    SiteId,
    archival: !!inspection.completedAt,
    InspectionId,
  });
  const { displayClassifications } = useScaledBlueprintDisplayClassifications(
    filters,
    WarehouseId,
    InspectionId,
  );

  const { handleFocus, focusOnWarehouse, stageContainerRef, width, height } = useBlueprintSizer(viewportRef, warehouse, isLoading); // prettier-ignore

  const formattedCompletedAt = useMemo(
    () => moment(inspection?.completedAt).format(PRIMARY_DATETIME_FORMAT),
    [inspection],
  );

  // An array of classifications to display in a modal
  const [modalClassificationLocations, setModalClassifications] =
    useState(false);
  const bay = displayClassifications
    ?.find((c) => c.id === modalClassificationLocations?.RackId)
    ?.classifications?.bays.find(
      (b) => b.BayId === modalClassificationLocations?.BayId,
    );
  const modalClassifications = getDamage(
    bay?.types,
    modalClassificationLocations?.spot,
  )?.classifications;

  const memoDamageClassifications = useMemo(() => {
    return displayClassifications?.map((dc) => (
      <DamageClassifications
        key={dc.id}
        onSelect={(val) => setModalClassifications(val)}
        rack={dc}
      />
    ));
  }, [displayClassifications]);

  const memoRacks = useMemo(() => {
    return warehouse?.racks.map((r) => (
      <Rack
        fullOpacity={
          inspection?.partial || inspection?.isolated ? r.inspected : true
        }
        key={r.id}
        rack={r}
      >
        <Beams rackDepth={r.totalY} rackWidth={r.totalX} />
        <FramesForBays bays={r.bays} />
      </Rack>
    ));
  }, [warehouse?.racks, inspection?.partial, inspection?.isolated]);

  const memoAreas = useMemo(() => {
    return areas?.map((a) => (
      <Area
        key={a.id}
        maxX={a.maxX}
        maxY={a.maxY}
        minX={a.minX}
        minY={a.minY}
        name={a.name}
      />
    ));
  }, [areas]);

  if (isLoading || isLoadingInspection) {
    return <Loading text="Loading Blueprint" />;
  }
  return (
    <MainAndSidebar>
      <Sidebar>
        <Panel.Heading className="blue">
          <h4>Inspection Mode</h4>
        </Panel.Heading>
        <Panel.Body>
          <p>Showing Site Blueprint from {formattedCompletedAt}</p>
          <Button href={`/blueprint/${SiteId}`}>
            Show current site Blueprint
          </Button>
        </Panel.Body>
      </Sidebar>
      <MainContainer>
        <Helmet>
          <title>Completed Inspection Blueprint</title>
        </Helmet>
        <NavigationBar>
          <NavigationSection>
            <BackLink to={`/inspections/view/${InspectionId}`}>
              <FontAwesomeIcon fixedWidth icon={faChevronLeft} /> Inspection
            </BackLink>
            <ClientLogo src={site?.ClientLogoUrl} />
            <FilterChip
              onChipPress={() => navigate(`/clients/sites/${SiteId}/overview`)}
              text={site?.SiteName ?? site?.name}
              type="sites"
            />

            <FilterChip
              onChipPress={() => navigate(`/inspections/view/${InspectionId}`)}
              text={`Ref. ${inspection?.reference}`}
              type="inspections"
            />
          </NavigationSection>
          <NavigationSection>
            <RoundButton
              active={isPanLocked}
              onClick={() => setPanLock((z) => !z)}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </RoundButton>
            <RoundButton>
              <FontAwesomeIcon
                icon={faExpand}
                onClick={() => {
                  focusOnWarehouse();
                }}
              />
            </RoundButton>
            <BlueprintFilterOptions
              components={[]}
              hideAmber={filters.hideAmber}
              hideGreen={filters.hideGreen}
              hideRed={filters.hideRed}
              selectedComponent={filters.searchText}
              setColorFilters={(e) => setFilters((f) => ({ ...f, ...e }))}
              setSelectedComponent={(e) =>
                setFilters((f) => ({ ...f, searchText: e }))
              }
            />
          </NavigationSection>
        </NavigationBar>
        <StageContainer ref={stageContainerRef}>
          <StageViewport
            onScale={(e) => gridRef.current?.setScaleFromPixiEvent(e)}
            ref={viewportRef}
            screenHeight={height}
            screenWidth={width}
            worldHeight={warehouse?.sizeY}
            worldWidth={warehouse?.sizeX}
          >
            <Grid
              ref={gridRef}
              sizeX={warehouse?.sizeX}
              sizeY={warehouse?.sizeY}
            />
            {memoAreas}
            {memoRacks}
            {memoDamageClassifications}
            {isPanLocked ? (
              <ZoomSelect
                height={warehouse?.sizeY}
                width={warehouse?.sizeX}
                zoomTo={handleFocus}
              />
            ) : null}
          </StageViewport>
        </StageContainer>
        <BottomTabs
          activeKey={WarehouseId}
          id={warehouseTabsId}
          onSelect={(key) =>
            navigate(`/inspections/blueprint/${InspectionId}/${key}`)
          }
        >
          {inspection.warehouses.map(({ id, name }) => (
            <Tab eventKey={id} key={id} title={name} />
          ))}
        </BottomTabs>
        {modalClassifications ? (
          <FlatModal
            closeButton
            onHide={() => setModalClassifications(false)}
            show
            title="Classifications"
            width={1000}
          >
            <ModalPaddedBody>
              {modalClassifications.map((c) => (
                <Classification
                  InspectionId={c.InspectionId}
                  classification={c}
                  key={c.id}
                />
              ))}
            </ModalPaddedBody>
          </FlatModal>
        ) : null}
      </MainContainer>
    </MainAndSidebar>
  );
}

PageInspectionBlueprintView.propTypes = {
  SiteId: PropTypes.string.isRequired,
  WarehouseId: PropTypes.string.isRequired,
  InspectionId: PropTypes.string.isRequired,
};
