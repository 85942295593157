import PropTypes from "prop-types";
import {
  Col,
  ControlLabel,
  FormControl,
  Grid,
  Panel,
  Row,
} from "react-bootstrap";

import EditorPanel from "views/components/EditorPanel";

import LogoUploader from "./logo-uploader";

/**
 *
 *          {client.permission &&
              (client.permission.level === "clientAdmin" ||
                client.permission.level.includes("Organisation")) && (
                <>
                  <Col sm={12}>
                    <ControlLabel>
                      Organisation
                      <span className="required">*</span>
                    </ControlLabel>
                  </Col>
                  <Col sm={12}>
                    <OrganisationSearch
                      changeSearchText={async (curSearchText) =>
                        this.setState(
                          { searchText: curSearchText },
                          async () => {
                            const organisationsRes = await getOrganisations({
                              searchText: curSearchText,
                            });
                            organisations = organisationsRes.results;
                            this.setState({
                              organisations: organisationsRes.results,
                            });
                          },
                        )
                      }
                      disabled={false}
                      isNotVerified={
                        organisation &&
                        client.NewOrganisationId === organisation.id
                      }
                      onChangeOrganisation={async (
                        selectedOrganisationId,
                      ) => {
                        organisation = await getOrganisation(
                          selectedOrganisationId,
                        );
                        if (currentOrganisation.id === organisation.id) {
                          client.OrganisationId = selectedOrganisationId;
                          this.setState({
                            client,
                            organisation,
                            organisationChangedId: null,
                            organisationChanged: null,
                          });
                        } else {
                          this.setState({
                            client,
                            organisationChanged: organisation,
                            organisationChangedId: selectedOrganisationId,
                          });
                        }
                      }}
                      onClearOrganisation={async () =>
                        this.setState({
                          organisation: null,
                        })
                      }
                      organisations={organisations}
                      searchText={searchText}
                      selectedOrganisationId={
                        organisation ? organisation.id : null
                      }
                    />
                  </Col>
                </>
              )}
 */
export default function ClientDetails({ client = {}, onChangeField }) {
  return (
    <EditorPanel>
      <Panel.Heading>Client Details</Panel.Heading>
      <Panel.Body>
        <Grid fluid>
          <Row>
            <Col sm={6}>
              <ControlLabel>
                Client Name
                <span className="required">*</span>
              </ControlLabel>

              <FormControl
                onChange={(e) => onChangeField("name", e.target.value)}
                placeholder="Enter Client Name"
                value={client.name}
              />

              <ControlLabel>
                Telephone Number
                <span className="required">*</span>
              </ControlLabel>

              <FormControl
                onChange={(e) => onChangeField("clientNumber", e.target.value)}
                placeholder="Enter Client Telephone"
                value={client.clientNumber}
              />

              <ControlLabel>
                Client Email
                <span className="required">*</span>
              </ControlLabel>

              <FormControl
                onChange={(e) => onChangeField("clientEmail", e.target.value)}
                placeholder="Enter Client Email"
                value={client.clientEmail}
              />

              <ControlLabel>Client Notes</ControlLabel>

              <FormControl
                onChange={(e) => onChangeField("notes", e.target.value)}
                placeholder="Enter Client Notes"
                value={client.notes}
              />
            </Col>
            <Col sm={6}>
              <ControlLabel>
                Client Logo
                <span className="required">*</span>
              </ControlLabel>

              <LogoUploader
                imageUrl={client.logoUrl}
                onAddLogo={(url) => onChangeField("logoUrl", url)}
                onRemove={() => onChangeField("logoUrl", null)}
              />
            </Col>
          </Row>
        </Grid>
      </Panel.Body>
    </EditorPanel>
  );
}

ClientDetails.propTypes = {
  client: PropTypes.shape({
    clientEmail: PropTypes.string,
    clientNumber: PropTypes.string,
    name: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  onChangeField: PropTypes.func.isRequired,
};
