import styled from "@emotion/styled";
import moment from "moment";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom"; // compat

import { useCurrentUser } from "hooks/user";
import { PRIMARY_DATETIME_FORMAT } from "libs/constants";
import { getScheduleTypeLabel } from "libs/schedule";
import MyScheduleActionButtons from "views/components/ActionButtons/set-schedules-my";
import { GrouperRow } from "views/components/Tables/GrouperRow";

const GroupRow = styled.tr`
  td {
    color: ${(props) => (props.isScheduled ? "#666666" : "#B7B7B7")} !important;
  }
`;
export default function ScheduleGroup({
  isScheduledInspection,
  nextDueHeader,
  onScheduledInspectionStart,
  scheduledInspections = [],
  schedules = [],
  setShowActionButtonSchedule,
  setShowConfirmModal,
  setStartScheduledInspectionId,
  setStartScheduleId,
  showActionButtonSchedule,
  showClientName = true,
  showSiteName = true,
}) {
  const navigate = useNavigate();
  const { user } = useCurrentUser();

  if (schedules.length === 0) {
    return null;
  }

  return (
    <>
      <GrouperRow>
        <td colSpan="7">
          <span
            className="headerText"
            style={{
              color: nextDueHeader === "Available now" ? "#2D72ED" : "#666666",
            }}
          >
            {nextDueHeader}
          </span>
        </td>
      </GrouperRow>
      {schedules.map((schedule) => {
        const scheduleKey = `${schedule.ScheduleId}${schedule.dueAt}`;
        const showActionButtons = showActionButtonSchedule === scheduleKey;
        const inProgressInspection = schedule.inspections.find(
          (inspection) => !inspection.completedAt,
        );
        const isActiveSchedule = !!scheduledInspections.find(
          (inspection) => inspection.id === schedule.id,
        );
        const canStartInspection = schedule?.Assignee?.id === user?.id;
        const offset = moment().utcOffset();
        return (
          <GroupRow
            isScheduled={isScheduledInspection}
            key={scheduleKey}
            onMouseDown={() => {
              setShowActionButtonSchedule(scheduleKey);
            }}
            onMouseEnter={() => {
              setShowActionButtonSchedule(scheduleKey);
            }}
          >
            {showClientName && <td>{schedule.client.name}</td>}
            {showSiteName && <td>{schedule.site.name}</td>}
            <td>{schedule.Assigner.name}</td>
            <td>{schedule.Assignee.name}</td>
            <td>{getScheduleTypeLabel(schedule.type)}</td>
            <td>
              {isActiveSchedule ? (
                <span
                  style={{
                    color: inProgressInspection ? "#FB5B50" : "#FF7700",
                  }}
                >
                  {inProgressInspection ? "In Progress" : "Pending"}
                </span>
              ) : (
                <span>-</span>
              )}
            </td>
            <td>
              {isScheduledInspection && inProgressInspection && (
                <span>
                  <strong>Started at: </strong>
                  {moment
                    .utc(inProgressInspection.createdAt)
                    .utcOffset(offset)
                    .format(PRIMARY_DATETIME_FORMAT)}
                  <br />
                  <strong>Due by: </strong>
                  {moment
                    .utc(schedule.nextScheduledAt)
                    .utcOffset(offset)
                    .format(PRIMARY_DATETIME_FORMAT)}
                </span>
              )}
              {isScheduledInspection && !inProgressInspection && (
                <span>
                  <strong>Starts at: </strong>
                  {moment
                    .utc(schedule.currentScheduledAt)
                    .utcOffset(offset)
                    .format(PRIMARY_DATETIME_FORMAT)}
                  <br />
                  <strong>Due by: </strong>
                  {moment
                    .utc(schedule.nextScheduledAt)
                    .utcOffset(offset)
                    .format(PRIMARY_DATETIME_FORMAT)}
                </span>
              )}
              {!isScheduledInspection && (
                <span>
                  <strong>Starts at: </strong>
                  {moment
                    .utc(schedule.dueAt)
                    .utcOffset(offset)
                    .format(PRIMARY_DATETIME_FORMAT)}
                  <br />
                  <strong>Due by: </strong>
                  {moment
                    .utc(schedule.nextScheduledAt)
                    .utcOffset(offset)
                    .format(PRIMARY_DATETIME_FORMAT)}
                </span>
              )}
            </td>
            {showActionButtons && (
              <MyScheduleActionButtons
                available={isScheduledInspection}
                disabled={!canStartInspection}
                inspectionStarted={
                  inProgressInspection && isScheduledInspection
                }
                onContinueClick={() => {
                  navigate(`/inspections/view/${inProgressInspection.id}`);
                }}
                onStartClick={() => {
                  if (isScheduledInspection) {
                    onScheduledInspectionStart(
                      schedule.ScheduleId,
                      schedule.id,
                    );
                  } else {
                    setStartScheduleId(schedule.ScheduleId);
                    setStartScheduledInspectionId(schedule.id);
                    setShowConfirmModal(true);
                  }
                }}
              />
            )}
          </GroupRow>
        );
      })}
    </>
  );
}

ScheduleGroup.propTypes = {
  showClientName: PropTypes.bool,
  showSiteName: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  scheduledInspections: PropTypes.array,
  isScheduledInspection: PropTypes.bool,
  nextDueHeader: PropTypes.string,
  onScheduledInspectionStart: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  schedules: PropTypes.arrayOf(PropTypes.object),
  setShowActionButtonSchedule: PropTypes.func,
  setShowConfirmModal: PropTypes.func,
  setStartScheduleId: PropTypes.func,
  setStartScheduledInspectionId: PropTypes.func,
  showActionButtonSchedule: PropTypes.bool,
};
