/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button } from "react-bootstrap";

import { deleteUser, editUser } from "libs/api";
import ConfirmModal from "views/components/Modals/ConfirmModal";

const SetWidthButton = styled(Button)`
  width: 125px;
`;

/**
 * Displays delete and activate/deactivate buttons. Calls and updates the user
 * when deleted or toggled status.
 *
 * Each of these actions calls an on* function that is awaited before closing
 * the modal.
 */
function UserControls({
  user,
  onDeleted = () => {},
  onToggleActive = () => {},
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [error, setError] = useState(null);

  return (
    <>
      <SetWidthButton
        className={`${user.active ? "red" : "green"}`}
        onClick={() => setShowActiveModal(true)}
      >
        {`${user.active ? "Deactivate" : "Activate"}`}
      </SetWidthButton>
      <SetWidthButton className="red" onClick={() => setShowDeleteModal(true)}>
        Delete
      </SetWidthButton>
      <ConfirmModal
        onConfirm={async () => {
          setError(null);
          const res = await deleteUser(user.id);
          if (res.ok) {
            await onDeleted();
          } else {
            setError(res.status);
          }
          setShowDeleteModal(false);
        }}
        onHide={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        title="Delete User"
      >
        <h4>Are you sure you want to delete this User?</h4>
        <h4>This can not be undone.</h4>
      </ConfirmModal>
      <ConfirmModal
        confirmButtonText={`${user.active ? "Deactivate" : "Activate"}`}
        onConfirm={async () => {
          const res = await editUser({ ...user, active: !user.active });
          if (res.ok) {
            await onToggleActive();
          } else {
            setError(res.status);
          }
          setShowActiveModal(false);
        }}
        onHide={() => setShowActiveModal(false)}
        show={showActiveModal}
        title={`${user.active ? "Deactivate" : "Activate"} User`}
      >
        <h4>{`Are you sure you want to ${
          user.active ? "Deactivate" : "Activate"
        } this User?`}</h4>
        <h5>
          {`${
            user.active
              ? "They will no longer be able to access the app"
              : "They will be able to access the app"
          }`}
        </h5>
      </ConfirmModal>
      {error ? (
        <div>There was a problem (Code: {error}). Please try again.</div>
      ) : null}
    </>
  );
}

UserControls.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
  onDeleted: PropTypes.func,
  onToggleActive: PropTypes.func,
};

export default UserControls;
