import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Checkbox, Col } from "react-bootstrap";

import FooterWrapper from "../SharedControls/Footer";

const StyledCheckbox = styled(Checkbox)`
  color: white;
  font-size: 16px;
`;

export default function Footer({
  addAnotherRack,
  canSave,
  errorMsg,
  isNew,
  onChangeAddAnotherRack,
  onDelete,
  onSave,
}) {
  return (
    <FooterWrapper errorMsg={errorMsg}>
      <Col sm={12}>
        {isNew && (
          <StyledCheckbox
            checked={addAnotherRack}
            onChange={(e) => onChangeAddAnotherRack(e.target.checked)}
          >
            Create Another
          </StyledCheckbox>
        )}
      </Col>
      <Col sm={12}>
        <ButtonGroup>
          {!isNew && (
            <Button className="action-button delete-button" onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button
            className="action-button save-button"
            disabled={!canSave}
            onClick={onSave}
          >
            {isNew ? "Add" : "Save"}
          </Button>
        </ButtonGroup>
      </Col>
    </FooterWrapper>
  );
}

Footer.propTypes = {
  addAnotherRack: PropTypes.bool,
  canSave: PropTypes.bool,
  errorMsg: PropTypes.string,
  isNew: PropTypes.bool,
  onChangeAddAnotherRack: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
