/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { FormControl, Button, InputGroup } from "react-bootstrap";

import { getClients } from "libs/api";
import { RED_COLOUR } from "libs/constants";

const amount = 1000;

/**
 *
 * This file contains two components. The default export holds logic to maybe
 * control the input, synchronising props changes and state.
 *
 * The ControlledClientSearch assumes external control and is mostly responsible
 * for dislay of the UI.
 *
 * TODO: Why did the client search not show loading on the initial load of the
 * component?
 *
 */
// TODO: Re-write this as an emotion-styled component
// eslint-disable-next-line react/prop-types
export function Wrapper({ hasSelection = false, children }) {
  return (
    <div
      css={css`
        .form-control {
          ${!hasSelection ? "border-bottom-left-radius: 0px;" : ""}
          ${!hasSelection ? "border-bottom-right-radius: 0px;" : ""}
        ${!hasSelection ? "border-bottom: 1px solid #aaaaaaaa !important;" : ""}
        margin-bottom: ${!hasSelection ? "0px" : "10px"};
        }
        .single-client-name {
          background-color: #f2f2f6;
          padding: 10px;
          border-radius: 5px;
          margin-bottom: 10px;
          font-size: 10pt;
        }
        .client-selectors {
          max-height: 125px;
          background-color: #f2f2f6;
          overflow-y: auto;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          .client-selector {
            padding: 10px;
            cursor: pointer;
            width: 100%;
            background-color: #f2f2f6;
            border-bottom: 1px solid #99999999;
          }
        }
        .client-list-selectors {
          max-height: 125px;
          background-color: #f2f2f6;
          overflow-y: auto;
          .client-selector {
            padding: 5px;
            cursor: pointer;
            width: 100%;
            background-color: #30333c;
            border: 1px solid #99999999;
            color: #ffffff;
          }
          margin-top: 10px;
        }
        .btn {
          padding: 0px;
          overflow: hidden;
          margin: 0px;
          border-radius: 0px !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          background-color: #2d72ed;
          height: 34px;
          padding-right: 10px;
          padding-left: 10px;
        }
        .input-group-btn {
          vertical-align: top !important;
        }
      `}
    >
      {children}
    </div>
  );
}

function ControlledClientSearch({
  searchText,
  onChangeSearchText,
  onChangeClient,
  onClearClient,
  selectedClientId,
  clients = [],
  disabled,
  isNotVerified,
  UrlClientId,
  UrlSiteId,
  loading,
  isOrganisationSearch,
}) {
  const hasSingleClient =
    (!searchText || !searchText.length) &&
    Array.isArray(clients) &&
    clients.length === 1;

  const selectedClient =
    selectedClientId && clients
      ? clients.find((client) => client.id === selectedClientId)
      : null;

  /**
   * TODO: initialChanged needs documented. From first appearance it looks like
   * the component will call onChangeClient if there is only one client on the
   * first time it loads. Why?
   */
  const [initialChanged, setInitialChanged] = useState(false);
  useEffect(() => {
    if (
      Array.isArray(clients) &&
      clients.length === 1 &&
      !initialChanged &&
      !loading &&
      hasSingleClient
    ) {
      setInitialChanged(true);
      onChangeClient(clients[0].id);
    }
  }, [clients, loading, hasSingleClient, initialChanged, onChangeClient]);

  if (selectedClientId) {
    return (
      <Wrapper hasSelection>
        {hasSingleClient ? (
          <div className="single-client-name">{selectedClient?.name}</div>
        ) : null}

        {/**
         * TODO: How would it occur that we have NOT a single client but also a selected Client ID?
         */}
        {!hasSingleClient && (UrlClientId || UrlSiteId) && (
          <FormControl
            placeholder={`Search for a ${
              isOrganisationSearch ? "Organisation" : "Client"
            }`}
            value={selectedClient ? selectedClient.name : ""}
          />
        )}
        {selectedClientId &&
          !hasSingleClient &&
          !UrlClientId &&
          !UrlSiteId &&
          searchText && (
            <InputGroup
              css={css`
                width: 100%;
              `}
            >
              <FormControl
                placeholder={`Search for a ${
                  isOrganisationSearch ? "Organisation" : "Client"
                }`}
                value={
                  selectedClient
                    ? `${selectedClient.name} ${
                        isNotVerified ? "(Not Verified)" : ""
                      }`
                    : ""
                }
              />
              {clients && clients.length > 1 && onClearClient && (
                <InputGroup.Button>
                  <Button
                    css={css`
                      ${isNotVerified
                        ? `background-color: ${RED_COLOUR} !important;`
                        : ""}
                    `}
                    onClick={async () => {
                      await onChangeSearchText(null);
                      await onClearClient();
                    }}
                  >
                    {!isNotVerified ? "Clear" : "Cancel"}
                  </Button>
                </InputGroup.Button>
              )}
            </InputGroup>
          )}
      </Wrapper>
    );
  }

  /**
   * If there is no selected client
   */
  return (
    <Wrapper>
      <FormControl
        onChange={(e) => onChangeSearchText(e.target.value)}
        placeholder={`Search for a ${
          isOrganisationSearch ? "Organisation" : "Client"
        } (${Array.isArray(clients) ? clients.length : "0"} found)`}
        value={searchText}
      />
      <div className="client-selectors">
        {loading && (!clients || !clients.length) && <div>Loading...</div>}
        {(!clients || !clients.length) && !loading && (
          <div>No Client Found</div>
        )}
        {clients &&
          clients.map((client) => (
            <div
              className="client-selector"
              key={client.id}
              onClick={() => {
                if (!disabled) {
                  onChangeClient(client.id);
                  onChangeSearchText(null);
                }
              }}
            >
              {client.label || client.name}
            </div>
          ))}
      </div>
    </Wrapper>
  );
}

/**
 * Data-connected form field to allow the lookup of clients.
 * This is marked as maybe controlled, as parents may control it or not.
 * This splitting was done so the underlying component logic could be
 * simplified.
 */
export default function MaybeControlledClientSearch({
  searchText: controlledSearchText,
  changeSearchText,
  clients: controlledClients,
  getClientsFunc,
  ...props
}) {
  /**
   * Synchronise our search terms, if we are controlled
   */
  const [searchText, setSearchText] = useState(controlledSearchText);
  useEffect(() => setSearchText(controlledSearchText), [controlledSearchText]);

  /**
   * Synchronize our clients with controlled clients
   */
  const [clients, setClients] = useState(controlledClients);
  useEffect(() => setClients(controlledClients), [controlledClients]);

  const [loading, setLoading] = useState(false);

  /**
   *  Search when Search Text Changes
   */
  useEffect(() => {
    async function getData() {
      setLoading(true);
      const { results } = getClientsFunc
        ? await getClientsFunc({ searchText, amount })
        : await getClients({ searchText, amount });
      setClients(results);
      setLoading(false);
    }
    /** We only search if we are an uncontrolled component */
    if (!controlledClients) getData();
  }, [searchText, controlledClients, getClientsFunc]);

  return (
    <ControlledClientSearch
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      clients={clients}
      loading={loading}
      onChangeSearchText={changeSearchText || setSearchText}
      searchText={searchText}
    />
  );
}

MaybeControlledClientSearch.propTypes = {
  /** Function to update controlled search text */
  changeSearchText: PropTypes.func,
  /** The search function to get clients, { searchText, amount } */
  getClientsFunc: PropTypes.func,
  /** When the input is cleared, this function will be called */
  onClearClient: PropTypes.func,
  /** When the client is selected, this function will be called */
  onChangeClient: PropTypes.func,
  /** Disable the whole control */
  disabled: PropTypes.bool,
  selectedClientId: PropTypes.string,
  UrlSiteId: PropTypes.string,
  UrlClientId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  clients: PropTypes.arrayOf(PropTypes.object),
  isNotVerified: PropTypes.bool,
  searchText: PropTypes.string,
  isOrganisationSearch: PropTypes.bool,
};
