/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useId } from "react";
import { FormControl } from "react-bootstrap";

export default function InspectionWarehouseSelect({
  warehouses,
  selectedWarehouseId,
  onChange,
}) {
  const id = useId();
  if (!warehouses.length) {
    return null;
  }
  return (
    <FormControl
      componentClass="select"
      css={css`
        display: inline-block;
        background-color: #e2e2e4;
        color: #676767;
        box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
        margin-bottom: 10px;
        height: 34px;
        width: 160px;
        margin-left: 5px;
      `}
      id={id}
      onChange={(e) => onChange(e.target.value)}
      value={selectedWarehouseId}
    >
      {warehouses.map((warehouse) => (
        <option key={warehouse.id} value={warehouse.id}>
          {warehouse.name}
        </option>
      ))}
    </FormControl>
  );
}

InspectionWarehouseSelect.propTypes = {
  onChange: PropTypes.func,
  selectedWarehouseId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  warehouses: PropTypes.array,
};
