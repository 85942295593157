import { useState, useCallback, useMemo } from "react";

const baseLineSize = 1;

function getGridSize(scale = 0) {
  if (scale < 0.1) {
    return 400;
  }

  if (scale < 0.2) {
    return 200;
  }

  if (scale < 0.4) {
    return 100;
  }

  return 50;
}

/**
 * The grid background displays different sizes depending on the zoom level.
 * Zoomed out, the squares are larger and the line sizes are bigger to prevent
 * blurred or missing lines.
 * This state records the scale of the viewport, and handle to update it is
 * passed to the viewport. We assume that the scale is the same for x and y,
 * but record y anyway for clarity as to where it comes from.
 */
export default function useGridScale({ x = 1, y = 1 }) {
  const [gridScale, setGridScale] = useState({ x, y });
  const setScaleFromPixiEvent = useCallback((e) => {
    const roundX = Math.round(e.lastViewport.scaleX * 100) / 100;
    const roundY = Math.round(e.lastViewport.scaleY * 100) / 100;

    setGridScale({ x: roundX, y: roundY });
  }, []);

  const gridSize = getGridSize(gridScale.x);

  const lineSize = useMemo(
    () => Math.ceil(baseLineSize * (1 / gridScale.x)),
    [gridScale.x],
  );

  return {
    gridSize,
    lineSize,
    setScaleFromPixiEvent,
  };
}
