/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactChart } from "chartjs-react";
import PropTypes from "prop-types";

import LegendItem from "./LegendItem";

export default function RackingDamageChart({ title, data = {}, colours }) {
  if (Object.keys(data).length === 0) return null;
  const parsedData = {
    labels: Object.keys(data).map(
      (key) => key.charAt(0).toUpperCase() + key.slice(1),
    ),
    datasets: [
      {
        data: Object.keys(data).map((key) => data[key]),
        backgroundColor: colours || null,
        hoverBackgroundColor: colours || null,
      },
    ],
  };
  return (
    <div
      css={css`
        height: 100%;
        .header {
          text-align: center;
          font-size: 14px;
          padding: 5px;
          margin-top: -10px;
          font-weight: bold;
          height: 10%;
        }
        .chart {
          height: 60%;
        }
        .legend {
          height: 30%;
          width: 100%;
          display: inline-block;
          margin: auto;
          text-align: center;
        }
      `}
    >
      <div className="header">{title}</div>
      <div className="chart">
        <ReactChart
          data={parsedData}
          height={400}
          options={{
            animations: false,
            layout: {
              padding: {
                bottom: 25,
              },
            },
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
          }}
          type="doughnut"
        />
      </div>
      <div className="legend">
        {Object.keys(data).map((item, index) => (
          <LegendItem
            colour={colours[index]}
            item={item}
            key={item[0].toUpperCase()}
          />
        ))}
      </div>
    </div>
  );
}

RackingDamageChart.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  colours: PropTypes.arrayOf(PropTypes.string),
};
