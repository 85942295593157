/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { OptionsField, OptionsFieldWithCheckboxes } from "./fields";

const containerStyle = css`
  padding: 0px;
`;

const primaryContainerStyle = css`
  justify-content: center;
  background-color: #e4e4e4;
  border: 1px solid #d7d7d9;
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 16px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 10px;
`;

const headingStyle = css`
  font-size: 1.5rem;
  margin-bottom: 16px;
  padding: 0px 16px;
`;

const flexContainerStyle = css`
  display: flex;
`;

const listWrapperStyle = css`
  flex: 1;
  justify-content: flex-start;
`;

const listItemStyle = css`
  list-style-type: none; /* Removes bullet points */
  list-style-position: inside; /* Aligns bullets with text */
  margin-left: 0px; /* Indent for bullet points */
  padding-left: 0; /* Removes any additional padding */
  display: flex; /* Align items in a row */
  align-items: center; /* Vertically center items */
`;

const checkboxItemStyle = css`
  list-style-type: none; /* Removes bullet points */
  list-style-position: inside; /* Aligns bullets with text */
  margin-left: 0px; /* Indent for bullet points */
  padding-left: 0; /* Removes any additional padding */
  display: flex; /* Align items in a row */
  align-items: center; /* Vertically center items */
  padding: 4px 0; /* Adds spacing between items */
`;

const checkboxWrapperStyle = css`
  display: flex; /* Aligns checkbox and text */
  gap: 8px; /* Space between checkbox and text */
  align-items: center; /* Aligns items vertically in the middle */
`;
const checkboxStyle = css`
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
`;

export function PrimaryLocations({
  groupedByPallet,
  isOffloaded,
  addOffload,
  removeOffload,
}) {
  return (
    <div css={containerStyle}>
      <h2 css={headingStyle}>Primary Locations</h2>
      <div css={primaryContainerStyle}>
        <div css={flexContainerStyle}>
          {Object.entries(groupedByPallet).map(([palletId, items]) => (
            <div key={palletId} css={listWrapperStyle}>
              <ul>
                {items.map((o) => (
                  <li key={o.id} css={checkboxItemStyle}>
                    <div css={checkboxWrapperStyle}>
                      <input
                        css={checkboxStyle}
                        disabled={o.offloadedAt && !o.loadedAt}
                        checked={isOffloaded(o)}
                        onChange={() => {
                          if (isOffloaded(o)) {
                            removeOffload(o);
                          } else {
                            addOffload(o);
                          }
                        }}
                        type="checkbox"
                      />
                      <span>{o.name}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

PrimaryLocations.propTypes = {
  addOffload: PropTypes.func.isRequired,
  groupedByPallet: PropTypes.object.isRequired,
  isOffloaded: PropTypes.func.isRequired,
  removeOffload: PropTypes.func.isRequired,
};

const secondaryContainerStyle = css`
  justify-content: center;
  background-color: #e4e4e4;
  border: 1px solid #d7d7d9;
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 16px;
`;

const ulStyle = css`
  max-height: 210px;
  margin-top: 8px;
  margin-left: 15px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  padding: 8px;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
const removeIconStyle = css`
  cursor: pointer;
  font-size: 2rem;
  color: #f56565; /* Tailwind's red-500 */
  margin-left: 8px;
  line-height: 1; /* Ensures the icon is vertically centered */
`;

export function SecondaryLocations({
  secondaryLocations,
  removeSecondaryOffload,
}) {
  if (secondaryLocations.length > 0) {
    return (
      <div css={containerStyle}>
        <h2 css={headingStyle}>Secondary Locations</h2>
        <div css={secondaryContainerStyle}>
          <ul css={ulStyle}>
            {secondaryLocations.map((o) => (
              <li key={o.id} css={listItemStyle}>
                <span>{o.name}</span>
                <span
                  css={removeIconStyle}
                  onClick={() => removeSecondaryOffload(o)}
                >
                  &times;
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
  return null;
}

SecondaryLocations.propTypes = {
  removeSecondaryOffload: PropTypes.func.isRequired,
  secondaryLocations: PropTypes.array.isRequired,
};

const addSecondaryContainerStyle = css`
  padding: 0px;
  margin-bottom: 16px;
`;

const secondaryLocationsContainerStyle = css`
  justify-content: center;
  background-color: #e4e4e4;
  border: 1px solid #d7d7d9;
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 16px;
`;

const secondaryLocationsFlexStyle = css`
  display: flex;
  align-items: flex-start;
  gap: 16px; /* Space between the items */
`;

const secondaryOptionsFieldStyle = css`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr; /* Single column grid */
  gap: 8px; /* Space between grid items */
`;

export function AddSecondaryLocations({
  handleRackChange,
  getRackLocations,
  getBayLocations,
  racks,
  rackId,
  setBayId,
  bayId,
  frameOffloadLocations,
  selectedValues,
  handleSecondaryLocationChange,
  getSecondaryLocations,
}) {
  return (
    <div css={addSecondaryContainerStyle}>
      <h2 css={headingStyle}>Add Secondary Locations</h2>
      <div css={secondaryLocationsContainerStyle}>
        <div css={secondaryLocationsFlexStyle}>
          {/* Rack dropdown field to filter for bay selection */}
          <OptionsField
            css={secondaryOptionsFieldStyle}
            onChange={handleRackChange}
            options={getRackLocations(racks)}
            title="Rack"
            value={rackId ?? ""}
          />
          {/* Bay dropdown field to filter for locations selection */}
          <OptionsField
            css={secondaryOptionsFieldStyle}
            onChange={setBayId}
            options={getBayLocations(racks, rackId)}
            title="Bay"
            value={bayId ?? ""}
            disabled={!rackId}
          />
          {/* Location checkboxes that are disabled and ticked if they are already offloaded or selected to be offloaded */}
          <OptionsFieldWithCheckboxes
            title="Locations"
            options={getSecondaryLocations(
              racks,
              rackId,
              bayId,
              frameOffloadLocations,
            )}
            selectedValues={selectedValues}
            onChange={handleSecondaryLocationChange}
            disabled={!bayId}
          />
        </div>
      </div>
    </div>
  );
}

AddSecondaryLocations.propTypes = {
  bayId: PropTypes.string,
  frameOffloadLocations: PropTypes.array.isRequired,
  getBayLocations: PropTypes.func.isRequired,
  getRackLocations: PropTypes.func.isRequired,
  getSecondaryLocations: PropTypes.func.isRequired,
  handleRackChange: PropTypes.func.isRequired,
  handleSecondaryLocationChange: PropTypes.func.isRequired,
  rackId: PropTypes.string,
  racks: PropTypes.array.isRequired,
  selectedValues: PropTypes.array.isRequired,
  setBayId: PropTypes.func.isRequired,
};
