import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { FormControl } from "react-bootstrap";

import { useClassificationSuggestions } from "hooks/classifications";
import AutoCompleteSearch from "views/components/common/AutoCompleteSearch";

const StyledFormControl = styled(FormControl)`
  background-color: #f2f2f4;
  border: 0px;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
  color: #747474;
`;

export function AutoCompleteWithSuggestions({
  title,
  fieldType,
  componentType,
  onChange,
  value,
}) {
  const { suggestions } = useClassificationSuggestions(
    fieldType,
    componentType,
    value,
  );
  return (
    <>
      <h5>{title}</h5>
      <AutoCompleteSearch
        disabled={!fieldType || !componentType}
        onChangeSearchText={(e) => onChange(e.target.value)}
        onClear={() => onChange("")}
        onSelectOption={onChange}
        options={suggestions}
        searchText={value}
      />
    </>
  );
}

AutoCompleteWithSuggestions.propTypes = {
  title: PropTypes.string.isRequired,
  componentType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
};

export function ComponentSpecificationField({ value, onChange }) {
  return (
    <>
      <h5>Component Specification</h5>
      <StyledFormControl
        onChange={(e) => onChange(e.target.value)}
        placeholder="Add specification"
        value={value}
      />
    </>
  );
}

ComponentSpecificationField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
