import PropTypes from "prop-types";
import { useState } from "react";

import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import ConfirmModal from "views/components/Modals/ConfirmModal";
import { Table } from "views/components/Tables/styles";

import { groupSchedules, getNextDueHeader, dueAtSorter } from "./helpers";
import ScheduleGroup from "./ScheduleGroup";

export default function MySchedulesTab({
  client,
  isLoading,
  schedules = [],
  onScheduledInspectionStart,
  scheduledInspections = [],
  selectedClientId,
  selectedSiteId,
  site,
  page = 0,
  pageSize = 10,
}) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showActionButtonSchedule, setShowActionButtonSchedule] =
    useState(null);
  const [startScheduleId, setStartScheduleId] = useState(null);
  const [startScheduledInspectionId, setStartScheduledInspectionId] =
    useState(null);

  const scheduledInspectionIds = scheduledInspections.map((s) => s.ScheduleId);

  /**
   * Decide on which schedules to display based on the pagination.
   * Slice from the scheduledInspections first, then pick the remaining from
   * the schedules.
   */
  const displayScheduledInspections = scheduledInspections.slice(
    page * pageSize,
    page * pageSize + pageSize,
  );
  const schedulesStartSlice = Math.max(
    page * pageSize - scheduledInspections.length,
    0,
  );
  const schedulesEndSlice = Math.max(
    page * pageSize + pageSize - scheduledInspections.length,
    schedulesStartSlice,
  );
  const displaySchedules = schedules
    .sort(dueAtSorter)
    .filter((s) => !scheduledInspectionIds.includes(s.ScheduleId))
    .slice(schedulesStartSlice, schedulesEndSlice);

  // Filter out those which are currently active, and so will appear in the "available now" group
  const groupedSchedules = groupSchedules(displaySchedules);
  const FutureSchedules = Object.keys(groupedSchedules || {}).map((group) =>
    Object.keys(groupedSchedules[group]).map((value) => (
      <ScheduleGroup
        isScheduledInspection={false}
        key={`${group}_${value}`}
        nextDueHeader={getNextDueHeader(value, group)}
        onScheduledInspectionStart={onScheduledInspectionStart}
        scheduledInspections={scheduledInspections}
        schedules={groupedSchedules[group][value]}
        setShowActionButtonSchedule={setShowActionButtonSchedule}
        setShowConfirmModal={setShowConfirmModal}
        setStartScheduleId={setStartScheduleId}
        setStartScheduledInspectionId={setStartScheduledInspectionId}
        showActionButtonSchedule={showActionButtonSchedule}
        showClientName={!client && !selectedClientId && !selectedSiteId}
        showSiteName={!site && !selectedSiteId}
      />
    )),
  );
  return (
    <>
      <Table
        onMouseLeave={() => {
          setShowActionButtonSchedule(null);
        }}
        withTabs
      >
        <thead>
          <tr>
            {!client && <th>Client</th>}
            {!site && <th>Site</th>}
            <th>Assigned By</th>
            <th>Assignee</th>
            <th>Repeating</th>
            <th>Status</th>
            <th>Schedule Window</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading && scheduledInspections.length > 0 && (
            <ScheduleGroup
              isScheduledInspection
              nextDueHeader="Available now"
              onScheduledInspectionStart={onScheduledInspectionStart}
              scheduledInspections={scheduledInspections}
              schedules={displayScheduledInspections}
              setShowActionButtonSchedule={setShowActionButtonSchedule}
              setShowConfirmModal={setShowConfirmModal}
              setStartScheduleId={setStartScheduleId}
              setStartScheduledInspectionId={setStartScheduledInspectionId}
              showActionButtonSchedule={showActionButtonSchedule}
              showClientName={!client && !selectedClientId && !selectedSiteId}
              showSiteName={!site && !selectedSiteId}
            />
          )}
          {!isLoading ? FutureSchedules : null}
        </tbody>
      </Table>
      {(isLoading || ![...FutureSchedules].flat().length) &&
        !scheduledInspections.length && (
          <EmptyTableIndicator
            isLoading={isLoading}
            searchable
            type="Schedules"
          />
        )}
      <ConfirmModal
        confirmButtonText="Start"
        onConfirm={async () => {
          onScheduledInspectionStart(
            startScheduleId,
            startScheduledInspectionId,
          );
          setShowConfirmModal(false);
        }}
        onHide={async () => {
          setShowConfirmModal(false);
        }}
        primary
        show={showConfirmModal}
        title="Start Early Inspection"
      >
        <h4>Are you sure you want to start an early inspection?</h4>
      </ConfirmModal>
    </>
  );
}

MySchedulesTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  scheduledInspections: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object,
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  schedules: PropTypes.array,
  onScheduledInspectionStart: PropTypes.func,
  selectedClientId: PropTypes.string,
  selectedSiteId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  site: PropTypes.object,
  page: PropTypes.number,
  pageSize: PropTypes.number,
};
