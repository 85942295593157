import useSWR from 'swr';

import {
  getMissedSchedules,
  getMySchedules,
  getPermission,
  getSchedule,
  getSchedules,
} from '../libs/api';

export function useSchedule(id) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    id ? `/schedule/${id}` : null,
    async () => getSchedule(id),
  );

  return {
    schedule: data,
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useMySchedules(filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    [`/schedule/my/`, filters],
    async () => getMySchedules(filters),
  );
  return {
    mySchedules: data?.results ?? [],
    activeSchedules: data?.activeSchedules ?? [],
    isLoading,
    isError: error,
    total: data?.results?.length ?? 0,
    totalAll: data?.activeSchedules?.length ?? 0,
    mutate,
    isValidating,
  };
}

export function useMissedSchedules(filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    [`/schedule/missed/`, filters],
    async () => getMissedSchedules(filters),
  );

  return {
    missedSchedules: data?.results ?? [],
    total: data?.total ?? 0,
    isLoading,
    error,
    mutate,
    isValidating,
  };
}

export function useManagedSchedules(filters) {
  // Note: this API call uses assigneeSchedules key rather than allSchedules
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    [`/schedule/`, filters],
    async () => getSchedules({ ...filters, assigneeSchedules: !filters?.allSchedules }),
  );

  return {
    managedSchedules: data?.results ?? [],
    total: data?.total ?? 0,
    isLoading,
    error,
    mutate,
    isValidating,
  };
}

export function useSitePermission(SiteId) {
  const { data, error, isValidating, isLoading } = useSWR(
    `/user/permission/site/${SiteId}`,
    async () => getPermission('site', SiteId),
  );
  return {
    permission: data ?? { level: [] },
    isLoading,
    error,
    isValidating,
  };
}
