import moment from "moment";

export const groupInspections = (inspections) => {
  const groupedInspections = {
    days: {},
    weeks: {},
    months: {},
    years: {},
  };
  if (!inspections) {
    return groupedInspections;
  }
  inspections.forEach((inspection) => {
    for (let day = -1; day < 7; day += 1) {
      if (
        moment()
          .subtract(day, "day")
          .isSame(moment(inspection.createdAt), "day")
      ) {
        groupedInspections.days[day]
          ? groupedInspections.days[day].push(inspection)
          : (groupedInspections.days[day] = [inspection]);
        return;
      }
    }
    for (let week = 0; week < 4; week += 1) {
      if (
        moment()
          .subtract(week, "week")
          .isSame(moment(inspection.createdAt), "week")
      ) {
        groupedInspections.weeks[week]
          ? groupedInspections.weeks[week].push(inspection)
          : (groupedInspections.weeks[week] = [inspection]);
        return;
      }
    }
    for (let month = 0; month < 12; month += 1) {
      if (
        moment()
          .subtract(month, "month")
          .isSame(moment(inspection.createdAt), "month")
      ) {
        groupedInspections.months[month]
          ? groupedInspections.months[month].push(inspection)
          : (groupedInspections.months[month] = [inspection]);
        return;
      }
    }
    for (let year = 1; year < 20; year += 1) {
      if (
        moment()
          .subtract(year, "year")
          .isSame(moment(inspection.createdAt).format("YYYY-MM-DD"), "year")
      ) {
        groupedInspections.years[year]
          ? groupedInspections.years[year].push(inspection)
          : (groupedInspections.years[year] = [inspection]);
      }
    }
  });
  return groupedInspections;
};
