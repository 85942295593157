import PropTypes from 'prop-types';
import { FormControl, FormGroup, Col, Row, ControlLabel } from 'react-bootstrap';

import ClientSearch from 'views/components/ClientSearch';

/**
 * Form Control on the ScheduleEditor for selecting a client
 *
 * If a client is provided, then the control is disabled.
 */
export default function ControlClient({
  client,
  clientId,
  scheduleId,
  clientOptions = null,
  disabled,
  clearClient,
  onChangeClient,
}) {
  return (
    <FormGroup controlId="formClient">
      <Row sm={12}>
        <Col sm={12}>
          <Col sm={12}>
            <ControlLabel>Client</ControlLabel>
          </Col>
          <Col sm={12}>
            {client && !clearClient && <FormControl disabled={disabled} value={client.name} />}
            {scheduleId && Array.isArray(clientOptions) && clientOptions.length > 0 && (
              <FormControl disabled readOnly value={clientOptions[0].name} />
            )}
            {clientOptions.length === 1 && !client && !scheduleId && (
              <FormControl componentClass="select" disabled readOnly value={clientOptions[0].id}>
                <option key={clientOptions[0].id} value={clientOptions[0].id}>
                  {clientOptions[0].name}
                </option>
              </FormControl>
            )}
            {clientOptions &&
              clientOptions.length !== 1 &&
              !disabled &&
              !scheduleId &&
              clearClient && (
                <ClientSearch
                  disabled={scheduleId || disabled}
                  onChangeClient={onChangeClient}
                  onClearClient={clearClient}
                  selectedClientId={clientId}
                />
              )}
          </Col>
        </Col>
      </Row>
    </FormGroup>
  );
}

ControlClient.defaultProps = {
  client: null,
  clientOptions: [],
  scheduleId: null,
  clientId: null,
  disabled: false,
};

ControlClient.propTypes = {
  /** A client that has been selected */
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object,
  /** The selected clientId TODO: Why not just use this from the passed client? */
  clientId: PropTypes.string,
  scheduleId: PropTypes.string,
  /** The options from which a client can pick */
  // eslint-disable-next-line react/forbid-prop-types
  clientOptions: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  clearClient: PropTypes.func.isRequired,
  onChangeClient: PropTypes.func.isRequired,
};
