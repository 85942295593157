import styled from '@emotion/styled';
import { Button, Table } from 'react-bootstrap';

export const StyledTable = styled(Table)`
  border-top-left-radius: 0;
`;
export const ControlsWrapper = styled.div`
  display: flex;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const ControlsButtonWrapper = styled.div`
  margin-left: auto;
  padding: 0px;
`;

export const ControlsButton = styled(Button)`
  height: 40px;
  width: 175px;
  padding: 6px 12px;
  margin: 0;
`;
