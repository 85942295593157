import { OTHER_COLOUR_ID } from "constants";

export function isFrameTemplateValid(frameTemplate) {
  const hasRearSplices = frameTemplate.splicedFrames.filter(
    (s) => s.isRear,
  )?.length;
  if (frameTemplate.sleeve && !frameTemplate.sleeveHeight) {
    return true;
  }
  if (
    frameTemplate.width > 0 &&
    frameTemplate.width < 151 &&
    frameTemplate.depth > 0 &&
    frameTemplate.depth <= 2500 &&
    frameTemplate.height > 0 &&
    frameTemplate.BasePlateId &&
    frameTemplate.FrameTypeId &&
    ((frameTemplate.ColourId && frameTemplate.ColourId !== OTHER_COLOUR_ID) ||
      (frameTemplate.colourOther && frameTemplate.colourOther.length > 0)) &&
    ((frameTemplate.sacLeg &&
      frameTemplate.sacSpec &&
      frameTemplate.sacLegHeight > 0 &&
      hasRearSplices) ||
      !frameTemplate.sacLeg) &&
    ((frameTemplate.sacLegRear &&
      frameTemplate.sacSpecRear &&
      frameTemplate.sacLegHeightRear > 0 &&
      hasRearSplices) ||
      !frameTemplate.sacLegRear)
  ) {
    return false;
  }
  return true;
}
