import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button } from "react-bootstrap";

import {
  AMBER_COLOUR,
  DEFAULT_COLOUR,
  GREEN_COLOUR,
  NONE_COLOUR,
  RED_COLOUR,
} from "libs/constants";
import DeleteModal from "views/components/InspectionClassificationTable/DeleteModal";

function getColorForRisk(risk) {
  switch (risk) {
    case "Green":
      return GREEN_COLOUR;
    case "Amber":
      return AMBER_COLOUR;
    case "Red":
      return RED_COLOUR;
    case "None":
      return NONE_COLOUR;
    default:
      return DEFAULT_COLOUR;
  }
}

const StyledDeleteButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "risk",
})`
  background-color: ${(props) => getColorForRisk(props.risk)};
  margin: 5px;
  width: 150px !important;
  &:focus,
  &:active,
  &:hover {
    background-color: ${(props) => getColorForRisk(props.risk)};
  }
`;

export default function DeleteButton({
  risk,
  classificationId,
  onDelete,
  isReclassification,
  children,
}) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <StyledDeleteButton onClick={() => setShowModal(true)} risk={risk}>
        {children ?? "Delete"}
      </StyledDeleteButton>
      {showModal ? (
        <DeleteModal
          classificationId={classificationId}
          isReclassification={isReclassification}
          onDelete={() => {
            setShowModal(false);
            onDelete();
          }}
          onHide={() => setShowModal(false)}
          show
        />
      ) : null}
    </>
  );
}

DeleteButton.propTypes = {
  risk: PropTypes.string.isRequired,
  classificationId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  isReclassification: PropTypes.bool,
  children: PropTypes.node,
};
