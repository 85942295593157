/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import downloadPDF from "libs/downloadPDF";
import formatDueDates from "libs/formatDueDates";

import ActionsActionButtons from "views/components/ActionButtons/set-actions";
import ActionOverdue from "views/components/ActionOverdue";
import ActionStatus from "views/components/ActionStatus";

/**
 * Notifies the user that the report is being exported and downloads the PDF
 */
async function onExportActionPDF(action) {
  const toastId = toast.info(`Exporting report (${action.reference})`, {
    closeButton: false,
    autoClose: false,
  });
  await downloadPDF(action.id, action, !action.preparedAt, true);
  toast.update(toastId, {
    render: `Report exported (${action.reference})`,
    type: toast.TYPE.SUCCESS,
    autoClose: 4000,
  });
}

export function PaddingRow({ showClient, showSite }) {
  return (
    <tr>
      {showClient ? <td /> : null}
      {showSite ? <td /> : null}
      <td />
      <td />
      <td />
      <td />
      <td />
    </tr>
  );
}

PaddingRow.propTypes = {
  showClient: PropTypes.bool,
  showSite: PropTypes.bool,
};

export default function Action({
  action,
  onRefresh,
  setSelectedAction,
  showSite = false,
  showClient = false,
}) {
  if (!action) return null;
  return (
    <tr>
      {showClient && <td>{action.ClientName}</td>}
      {showSite && <td>{action.SiteName}</td>}
      <td>{action.reference}</td>
      <td>{action?.assignees.length > 0 ? action.assignees[0].name : ""}</td>
      <td>
        <ActionStatus action={action} />
      </td>
      <td>{formatDueDates(action.fromDueAt, action.toDueAt)}</td>
      <td>
        <ActionOverdue action={action} />
      </td>
      <ActionsActionButtons
        action={action}
        condensed
        getData={onRefresh}
        onRequestPDF={() => onExportActionPDF(action)}
        onSelectAction={() => setSelectedAction(action)}
      />
    </tr>
  );
}

Action.propTypes = {
  showSite: PropTypes.bool,
  showClient: PropTypes.bool,
  action: PropTypes.shape({
    id: PropTypes.string,
    ClientName: PropTypes.string,
    SiteName: PropTypes.string,
    reference: PropTypes.string,
    assignees: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    fromDueAt: PropTypes.string,
    toDueAt: PropTypes.string,
  }),
  onRefresh: PropTypes.func.isRequired,
  setSelectedAction: PropTypes.func.isRequired,
};
