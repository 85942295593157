import { useCallback, useMemo, useState } from "react";
import { Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useClientSiteGroups } from "hooks/client";

import { useSite } from "hooks/site";
import { createSiteGroup, editSite } from "libs/api";
import ButtonWide from "views/components/Buttons/button-wide";
import LinkButtonWide from "views/components/Buttons/link-button-wide";
import PageWrapper from "views/components/common/PageWrapper";
import TableHeader from "views/components/Tables/TableHeader";
import SiteAddressPanel from "views/pages/SitesEditor/components/site-address-panel";
import SiteDetailsPanel from "views/pages/SitesEditor/components/site-details-panel";

import { ControlsWrapper } from "./styles";

export default function PageEditSite() {
  const { SiteId } = useParams();
  const navigate = useNavigate();
  const { site, mutate: refreshSite } = useSite(SiteId);
  const { groups } = useClientSiteGroups(site?.ClientId);
  const [editedSiteFields, setSiteFields] = useState({});
  const [error, setError] = useState(null);

  const siteFields = useMemo(
    () => ({
      ...site,
      ...editedSiteFields,
    }),
    [site, editedSiteFields],
  );

  const handleSave = useCallback(async () => {
    let { SiteGroupId } = siteFields;
    try {
      if (SiteGroupId === "new-group") {
        const newSiteGroup = await createSiteGroup(
          site.ClientId,
          siteFields.groupName,
        );
        SiteGroupId = newSiteGroup.id;
      }

      if (SiteGroupId === "no-group") {
        SiteGroupId = null;
      }

      await editSite(siteFields);
      refreshSite();

      toast(`Edited site: ${siteFields.name}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        type: toast.TYPE.SUCCESS,
        autoClose: true,
        pauseOnHover: true,
      });

      navigate(`/clients/sites/${SiteId}/overview`);
    } catch (error) {
      setError(error.message);
    }
  }, [siteFields, refreshSite, navigate, SiteId, site?.ClientId]);

  const isDisabled =
    !siteFields.name ||
    !siteFields.contactNumber ||
    !siteFields.contactEmail ||
    !siteFields.addressLine1 ||
    !siteFields.city ||
    !siteFields.postcode;

  return (
    <PageWrapper>
      <Helmet>
        <title>Econform / Sites / Edit</title>
      </Helmet>
      <TableHeader left="Edit Site" />

      <SiteDetailsPanel
        groups={groups}
        onChangeField={(id, val) => setSiteFields((f) => ({ ...f, [id]: val }))}
        site={siteFields}
      />

      <SiteAddressPanel
        onChangeField={(id, val) => setSiteFields((f) => ({ ...f, [id]: val }))}
        site={siteFields}
      />

      <ControlsWrapper>
        <LinkButtonWide to={`/clients/${site?.ClientId}/sites`}>
          Cancel
        </LinkButtonWide>
        <ButtonWide disabled={isDisabled} onClick={handleSave}>
          Save
        </ButtonWide>
      </ControlsWrapper>

      {error ? <Alert bsStyle="danger">{error}</Alert> : null}
    </PageWrapper>
  );
}
