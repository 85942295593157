import styled from '@emotion/styled';

export const StyledTabsWrapper = styled.div`
  div > ul > li {
    padding-top: 0px;
  }

  .clearfix {
    margin-top: 15px;
    margin-bottom: -15px;
  }
  div > ul > li:not(:last-child):not(.active) {
    border-right: 1px solid #999999;
  }

  div > ul > li:nth-of-type(${(props) => props.currentSidebarTab - 1}) {
    border-right: 1px solid #99999900 !important;
  }

  div > ul > li > a {
    margin-top: -15px;
  }

  div > ul > li.active > a:focus,
  div > ul > li.active > a:hover,
  div > ul > li.active > a {
    color: #ffcf33;
    cursor: default;
    background-color: #24262d;
    border-radius: 5px;
    height: 60px;
    border: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: solid #24262d 5px;
    margin-bottom: -10px;
  }

  .type-tabs {
    padding: 0px;
  }

  div > ul > li > a {
    color: #fff;
    cursor: default;
    background-color: #30333c;
    border-bottom-color: transparent;
    border-radius: 0px;
    padding: 10px;
    font-size: 13px;
    font-weight: 600;
    padding-top: 15px;
    /* border-right: 2px solid #7a7c7e; */
  }

  div > ul {
    height: 50px;
  }

  div > ul > li {
    width: 23%;
    text-align: center;
    height: 20px;
    margin: 0px;
    margin-top: 5px;
    margin-left: 5px;
  }
`;
