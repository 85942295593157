/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import fetchRetry from "fetch-retry";
import PropTypes from "prop-types";
import { useRef } from "react";
import { Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import { uploadImage } from "libs/api";

const StyledUploadButton = styled(Button)`
  background-color: #2d72ed;
  border-radius: 0px !important;
  color: #d8d8d8;
  display: inline-block;
  height: ${(props) => props.size ?? "80px"};
  margin: 0 5px 0 0;
  padding: 0;
  width: ${(props) => props.size ?? "80px"};
`;

const isImageUploaded = (url) => {
  const imageFetch = fetchRetry(fetch);
  return imageFetch(`${url}`, {
    retries: 10,
    retryDelay: 500,
    retryOn: [403, 404],
  });
};

export default function UploadButton({
  children,
  disabled,
  onFileUploaded,
  onFileUploadFailed,
  onFileUploadStarted,
  size,
  className,
}) {
  const fileUploadFieldRef = useRef();

  return (
    <>
      <StyledUploadButton
        className={className}
        disabled={disabled}
        onClick={async () => {
          if (!disabled) {
            fileUploadFieldRef.current.click();
          }
        }}
        size={size}
      >
        {children}
      </StyledUploadButton>
      {!disabled && (
        <input
          accept="image/*"
          className="fileInput"
          css={css`
            display: none !important;
          `}
          onChange={async (e) => {
            onFileUploadStarted();
            const file = e.target.files[0];
            // Resets the value of event target, to allow for reupload without refreshing page
            e.target.value = "";
            if (file) {
              const fileId = uuidv4();
              const extension = file.name.split(".").pop();
              const formData = new FormData();
              formData.append("file", file, `${fileId}_original.${extension}`);
              try {
                const image = await uploadImage(formData);
                onFileUploaded(image);
                await isImageUploaded(
                  image.location.replace("original", "optimised"),
                );
              } catch (err) {
                onFileUploadFailed("Failed to upload file");
              }
            }
          }}
          ref={fileUploadFieldRef}
          type="file"
        />
      )}
    </>
  );
}

UploadButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onFileUploadFailed: PropTypes.func,
  onFileUploadStarted: PropTypes.func,
  onFileUploaded: PropTypes.func,
  size: PropTypes.string,
  className: PropTypes.string,
};

UploadButton.defaultProps = {
  children: "Upload",
  onFileUploadStarted: () => {},
  onFileUploadFailed: () => {},
  onFileUploaded: () => {},
  disabled: false,
};
