import PropTypes from "prop-types";

export function CheckboxInput({
  checked,
  onChange,
  disabled,
  children,
  ...props
}) {
  return (
    <input
      type="checkbox"
      checked={checked} /* Ensure checked is explicitly passed */
      onChange={onChange} /* Ensure onChange is passed */
      disabled={disabled} /* Ensure disabled is handled */
      {...props}
    >
      {children}
    </input>
  );
}

CheckboxInput.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};
