import { Text } from "@pixi/react";

import PropTypes from "prop-types";
import React from "react";

import { SCALE_FACTOR } from "hooks/use-site-blueprint";

const style = {
  fontSize: 500 * SCALE_FACTOR,
};
function RackLabels({ rotation, name, width, depth }) {
  return (
    <>
      <Text
        // Start Label
        anchor={0.5}
        angle={-rotation}
        eventMode="auto"
        fill="#000000"
        isSprite
        style={style}
        text={name}
        x={-500 * SCALE_FACTOR}
        y={depth / 2}
      />
      <Text
        // End Label
        anchor={0.5}
        angle={-rotation}
        eventMode="auto"
        fill="#000000"
        isSprite
        style={style}
        text={name}
        x={width + 500 * SCALE_FACTOR}
        y={depth / 2}
      />
    </>
  );
}

export default React.memo(RackLabels);

RackLabels.propTypes = {
  depth: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  rotation: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
