import styled from '@emotion/styled';

export const GrouperRow = styled.tr`
  td {
    background-color: #dddddd;
    height: ${(props) => (props.small ? '20px' : '30px')} !important;
    padding-right: 0 !important;
  }
`;
GrouperRow.displayName = 'GrouperRow';
