import styled from "@emotion/styled";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, Col } from "react-bootstrap";

import { StyledFormControl } from "./Generic";

const Wrapper = styled.div`
  color: ${(props) => (props.disabled ? "#919399" : "#fff")};
  margin: 20px 0;

  .icon i {
    color: ${(props) => (props.disabled ? "#919399" : "#fff")};
  }
`;

const TextWrapper = styled.div`
  padding: 0 10px;
`;

export default function Notes({ disabled, notes, setNotes }) {
  const [showNotes, setShowNotes] = useState(false);
  return (
    <Wrapper disabled={disabled}>
      <Col sm={6}>
        <h4>Notes</h4>
      </Col>
      <Col className="icon" sm={6}>
        <Button
          className="transparent"
          onClick={() => !disabled && setShowNotes(!showNotes)}
        >
          <FontAwesomeIcon icon={showNotes ? faChevronDown : faChevronRight} />
        </Button>
      </Col>
      {showNotes && (
        <TextWrapper>
          <StyledFormControl
            componentClass="textarea"
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Add Notes"
            value={notes}
          />
        </TextWrapper>
      )}
    </Wrapper>
  );
}

Notes.propTypes = {
  disabled: PropTypes.bool,
  notes: PropTypes.string,
  setNotes: PropTypes.func.isRequired,
};
