import { Navigate, useParams } from "react-router-dom";

import { useInspection } from "hooks/inspections";
import Loading from "views/components/Loading";

import PageInspectionBlueprintView from "views/pages/blueprint-inspection/page-bluepint-inspection-view";
import PageInspectionBlueprintActive from "views/pages/blueprint-inspection/page-blueprint-inspection-active";

/**
 * This page has three modes:
 *
 * - Archival Inspection, view only
 * - Current Inspection, add damage
 * - Action mode, view damage, edit actions
 **/
export default function PageInspectionBlueprint() {
  const { InspectionId, WarehouseId } = useParams();
  const { inspection, isLoading } = useInspection(InspectionId);

  if (isLoading) {
    return <Loading text="Loading Blueprint" />;
  }

  /**
   * If we don't have a warehouse ID then redirect to the first warehouse,
   * so we can use the URL for navigation between warehouses, without keeping
   * variables in local state.
   */
  if (!WarehouseId) {
    return (
      <Navigate
        replace
        to={`/inspections/blueprint/${InspectionId}/${inspection.warehouses[0].id}`}
      />
    );
  }

  if (inspection.completedAt) {
    return (
      <PageInspectionBlueprintView
        InspectionId={InspectionId}
        SiteId={inspection.SiteId}
        WarehouseId={inspection.warehouses[0]?.id}
      />
    );
  }

  return (
    <PageInspectionBlueprintActive
      InspectionId={InspectionId}
      SiteId={inspection.SiteId}
      WarehouseId={WarehouseId}
    />
  );
}
