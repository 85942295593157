import styled from "@emotion/styled";
import moment from "moment";
import PropTypes from "prop-types";

import { useCurrentUser } from "hooks/user";
import { unviewMissedInspection, viewMissedInspection } from "libs/api";
import { PRIMARY_DATETIME_FORMAT } from "libs/constants";

import DropDownButton from "views/components/common/DropDownButton";
import ViewedIndicator from "views/components/common/ViewedIndicator";
import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import { Table } from "views/components/Tables/styles";

const StyledTable = styled(Table)`
  .viewed-td {
    width: 75px;
  }
`;

// const totalPages = Math.ceil(totalMissedSchedules / pageSize);
function MissedSchedulesTab({
  schedules,
  isLoading,
  refreshMissed,
  isAllMissed,
}) {
  const currentUser = useCurrentUser();
  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <th>Schedule Missed</th>
            <th>Client</th>
            <th>Site</th>
            <th>Missed By</th>
            <th>Missed On</th>
            <th>
              {!isAllMissed && (
                <DropDownButton
                  buttons={[
                    {
                      title: "Mark All as Viewed",
                      onClick: async () => {
                        await viewMissedInspection();
                        refreshMissed();
                      },
                    },
                    {
                      title: "Mark All as Unviewed",
                      onClick: async () => {
                        await unviewMissedInspection();
                        refreshMissed();
                      },
                    },
                  ]}
                />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            schedules.map((missedSchedule) => {
              const onClickFunction = missedSchedule.viewed
                ? unviewMissedInspection
                : viewMissedInspection;
              return (
                <tr key={missedSchedule.id}>
                  <td>Pallet Racking Schedule</td>
                  <td>{missedSchedule.client.name}</td>
                  <td>{missedSchedule.site.name}</td>
                  <td>{missedSchedule.assignee.name}</td>
                  <td>
                    {moment
                      .utc(missedSchedule.createdAt)
                      .local()
                      .format(PRIMARY_DATETIME_FORMAT)}
                  </td>
                  <td className="viewed-td">
                    <ViewedIndicator
                      disabled={
                        currentUser?.user.id !== missedSchedule.assignee.id
                      }
                      onClick={async () => {
                        // TODO: Does the function set the viewed state? If so,
                        // why update the parameter here? Check and remove.
                        // eslint-disable-next-line no-param-reassign
                        missedSchedule.viewed = !missedSchedule.viewed;
                        await onClickFunction({
                          ScheduleIds: [missedSchedule.id],
                        });
                        refreshMissed();
                      }}
                      viewed={missedSchedule.viewed}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </StyledTable>
      {isLoading ||
        (!schedules.length && (
          <EmptyTableIndicator
            isLoading={isLoading}
            searchable
            type="Schedules"
            typeLabel="missed inspections"
          />
        ))}
    </>
  );
}

MissedSchedulesTab.propTypes = {
  isLoading: PropTypes.bool,
  refreshMissed: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  schedules: PropTypes.array,
  isAllMissed: PropTypes.bool,
};

export default MissedSchedulesTab;
