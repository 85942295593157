import { OTHER_COLOUR_ID } from "constants";

import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Checkbox, Image } from "react-bootstrap";

import { StyledFormControl } from "views/components/BuildModeSidebar/SharedControls/Generic";

const DeleteButton = styled(Button)`
  position: relative !important;
  padding-top: 5px;
  float: right;
`;

const ColourControl = styled(StyledFormControl)`
  width: 90px !important;
`;

export default function BeamTemplate({
  beamTemplate,
  beamTemplateTypes = [],
  clearErrorMessage,
  colours = [],
  disabled,
  index,
  onError,
  updateBeamTemplate,
}) {
  if (!beamTemplate) return null;
  const { ColourId, colourOther } = beamTemplate;
  const isOtherColour =
    ColourId === OTHER_COLOUR_ID || (ColourId === null && colourOther);
  return (
    <>
      <tr>
        <td>{`${index + 1}`.padStart(2, "0")}</td>
        <td>
          <StyledFormControl
            componentClass="select"
            disabled={disabled}
            onChange={(e) =>
              updateBeamTemplate({ BeamTypeId: e.target.value, changed: true })
            }
            placeholder="Bay Type"
            value={beamTemplate.BeamTypeId}
          >
            {beamTemplateTypes.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </StyledFormControl>
        </td>
        <td>
          <Checkbox
            checked={beamTemplate.standardDownweld === null}
            onChange={(e) =>
              updateBeamTemplate({
                changed: true,
                standardDownweld: e.target.checked ? null : 0,
              })
            }
          >
            Std
          </Checkbox>
        </td>
        <td>
          <ColourControl
            componentClass="select"
            disabled={disabled}
            onChange={(e) => {
              updateBeamTemplate({ ColourId: e.target.value, changed: true });
              if (!colourOther || !colourOther.length) {
                onError("Please enter a colour for added beams");
              } else {
                clearErrorMessage();
              }
            }}
            placeholder="Colour"
            value={isOtherColour ? OTHER_COLOUR_ID : ColourId ?? ""}
          >
            {colours.length > 0 && (
              <option disabled value="">
                Select Colour
              </option>
            )}
            {colours.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </ColourControl>
        </td>
        <td>
          <ButtonGroup>
            <DeleteButton
              className="transparent"
              disabled={disabled}
              onClick={() =>
                updateBeamTemplate({ deleted: !beamTemplate.deleted })
              }
            >
              <Image responsive src="/img/icons/trash-white.png" />
            </DeleteButton>
          </ButtonGroup>
        </td>
      </tr>
      <tr>
        <td />
        <td />
        <td>
          {beamTemplate.standardDownweld !== null && (
            <StyledFormControl
              disabled={disabled}
              min="0"
              onChange={(e) => {
                updateBeamTemplate({
                  standardDownweld: parseInt(e.target.value, 10),
                  changed: true,
                });
              }}
              placeholder="Downweld (mm)"
              type="number"
              value={beamTemplate.standardDownweld}
            />
          )}
        </td>
        <td>
          {isOtherColour ? (
            <StyledFormControl
              disabled={disabled}
              onChange={(e) => {
                updateBeamTemplate({
                  colourOther: e.target.value,
                  ColourId: OTHER_COLOUR_ID,
                });
                if (!e.target.value?.length) {
                  onError("Please enter a colour for added beams");
                } else {
                  clearErrorMessage();
                }
              }}
              placeholder="Colour"
              type="text"
              value={colourOther ?? ""}
            />
          ) : null}
        </td>
        <td />
      </tr>
    </>
  );
}

BeamTemplate.propTypes = {
  disabled: PropTypes.bool,
  beamTemplate: PropTypes.shape({
    BeamTypeId: PropTypes.string,
    changed: PropTypes.bool,
    ColourId: PropTypes.string,
    colourOther: PropTypes.string,
    deleted: PropTypes.bool,
    id: PropTypes.string,
    standardDownweld: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  colours: PropTypes.array,
  index: PropTypes.number.isRequired,
  updateBeamTemplate: PropTypes.func.isRequired,
  clearErrorMessage: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  beamTemplateTypes: PropTypes.array,
};
