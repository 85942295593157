import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button } from "react-bootstrap";

import {
  AMBER_COLOUR,
  DEFAULT_COLOUR,
  GREEN_COLOUR,
  NONE_COLOUR,
  RED_COLOUR,
} from "libs/constants";
import FlatModal from "views/components/Modals/FlatModal";
import { ReclassifyEditor } from "views/components/Modals/NewClassificationEditor";

function getColorForRisk(risk) {
  switch (risk) {
    case "Green":
      return GREEN_COLOUR;
    case "Amber":
      return AMBER_COLOUR;
    case "Red":
      return RED_COLOUR;
    case "None":
      return NONE_COLOUR;
    default:
      return DEFAULT_COLOUR;
  }
}

const StyledReclassifyButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "risk",
})`
  background-color: ${getColorForRisk(DEFAULT_COLOUR)};
  margin: 5px;
  width: 150px !important;
  &:focus,
  &:active,
  &:hover {
    background-color: ${(props) => getColorForRisk(props.risk)};
  }
`;

/**
 * Display a reclassify button, and check clicked, pop open the reclassify modal
 */
export default function ReclassifyButton({
  InspectionId,
  classificationId,
  disabled,
}) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <StyledReclassifyButton
        disabled={disabled}
        onClick={() => setShowModal(!showModal)}
      >
        Reclassify
      </StyledReclassifyButton>
      {showModal ? (
        <FlatModal
          closeButton
          onHide={() => setShowModal(false)}
          show
          title="Reclassify Damage"
        >
          <ReclassifyEditor
            InspectionId={InspectionId}
            classificationId={classificationId}
            onHide={() => setShowModal(false)}
          />
        </FlatModal>
      ) : null}
    </>
  );
}

ReclassifyButton.propTypes = {
  risk: PropTypes.string,
  classificationId: PropTypes.string.isRequired,
  InspectionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
