import { Sprite } from "@pixi/react";
import { Texture } from "pixi.js";
import PropTypes from "prop-types";
import React from "react";

/**
 * Display the base of a rack
 */
function RackBase({ width, depth }) {
  return (
    <Sprite
      anchor={0}
      cullable
      height={depth}
      texture={Texture.WHITE}
      tint={"0x515151"}
      width={width}
      x={0}
      y={0}
    />
  );
}

export default React.memo(RackBase);

RackBase.propTypes = {
  width: PropTypes.number.isRequired,
  depth: PropTypes.number.isRequired,
};
