import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { GreenButton } from "./colors";

export default function ShareButton({ disabled, onClick }) {
  return (
    <GreenButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={faShareAlt} size="2xl" />
      Share
    </GreenButton>
  );
}

ShareButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
