import { useState } from "react";
import { useParams } from "react-router-dom";

import { useInspection } from "hooks/inspections";
import { useCurrentUser } from "hooks/user";

import ErrorDisplay from "views/components/Error";
import Loading from "views/components/Loading";
import ActionAssigner from "views/components/Modals/ActionAssigner";
import FlatModal from "views/components/Modals/FlatModal";

import PageActiveInspection from "./page-active-inspection";
import PageCompleteInspection from "./page-complete-inspection";
import PageIsolatedCompleteInspection from "./page-complete-isolated-inspection";
import PageIsolatedInspection from "./page-isolated-inspection";

export default function InspectionPage() {
  const { InspectionId } = useParams();
  const {
    inspection,
    isLoading,
    mutate: refresh,
    isError,
  } = useInspection(InspectionId);
  const { user } = useCurrentUser();

  // We have the action assigner modal here, as it is shown on the transition
  // between active and complete inspection states
  const [showAssignActionModal, setShowAssignActionModal] = useState(false);

  if (!InspectionId) {
    return (
      <ErrorDisplay text="Inspection Page must be passed an Inspection ID" />
    );
  }

  if (isError) {
    return <ErrorDisplay />;
  }

  if (isLoading || !inspection) {
    return <Loading />;
  }

  if (inspection?.completedAt) {
    return (
      <>
        {inspection?.isolated ? (
          <PageIsolatedCompleteInspection InspectionId={InspectionId} />
        ) : (
          <PageCompleteInspection InspectionId={InspectionId} />
        )}
        {showAssignActionModal ? (
          <FlatModal show title="Assign Action" width={500}>
            <ActionAssigner
              getData={() => refresh()}
              inspection={inspection}
              onHide={() => {
                setShowAssignActionModal(false);
              }}
              populateDefaultData
              shouldNavigateOnSave
              user={user}
            />
          </FlatModal>
        ) : null}
      </>
    );
  }

  if (inspection?.isolated) {
    return (
      <PageIsolatedInspection
        InspectionId={InspectionId}
        onComplete={() => setShowAssignActionModal(true)}
      />
    );
  }

  return (
    <PageActiveInspection
      InspectionId={InspectionId}
      onComplete={() => setShowAssignActionModal(true)}
    />
  );
}
