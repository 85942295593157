import { useCallback } from "react";
import useSWR from "swr";

import {
  createSiteGroup,
  deleteSiteGroup,
  editSiteGroup,
  getClient,
  getClients,
  getClientsGroups,
  getClientsToVerify,
} from "libs/api";

export function useClient(id) {
  const { data, error, isLoading } = useSWR(
    id ? `/client/${id}` : null,
    async () => getClient(id),
  );

  return {
    client: data,
    isLoading,
    isError: error,
  };
}

export function useClients(filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    ["/clients", filters],
    async () => getClients(filters),
  );

  return {
    clients: data?.results ?? [],
    total: data?.total ?? 0,
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useClientsToVerify() {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    "/clients-to-verify",
    getClientsToVerify,
  );

  return {
    clientsToVerify: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useClientSiteGroups(ClientId) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    ClientId ? `/client/groups/${ClientId}` : null,
    async () => getClientsGroups(ClientId),
  );

  const handleCreateSiteGroup = useCallback(
    async (name) => {
      try {
        // TODO: Optimistic update of the local data with return from the API
        await createSiteGroup(ClientId, name);
        mutate();
      } catch (e) {
        throw new Error(e.message);
      }
    },
    [ClientId, mutate],
  );

  const handleEditSiteGroup = useCallback(
    async (id, name) => {
      try {
        await editSiteGroup(id, name);
        mutate();
      } catch (e) {
        throw new Error(e.message);
      }
    },
    [mutate],
  );

  const handleDeleteSiteGroup = useCallback(
    async (id) => {
      try {
        await deleteSiteGroup(id);
        mutate();
      } catch (e) {
        throw new Error(e.message);
      }
    },
    [mutate],
  );

  return {
    groups: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
    handleCreateSiteGroup,
    handleEditSiteGroup,
    handleDeleteSiteGroup,
  };
}
