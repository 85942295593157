/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { Button } from "react-bootstrap";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PageNavButton = styled(Button)`
  background-color: #bbb;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  height: 30px;
  padding: 0;
  width: 30px;
  &:disabled {
    background-color: #ddd;
    color: #eee;
  }
`;

const Text = styled.div`
  display: inline-block;
  margin: 0 10px;
`;

/**
 * Pagination buttons for list tables
 *
 * Note: curPage is zero-indexed. Total pages is not.
 */
export default function PageButtons({
  curPage = null,
  totalPages,
  onChangePage,
}) {
  const onFirstPage = useCallback(() => onChangePage(0), [onChangePage]);
  const onLastPage = useCallback(
    () => onChangePage(totalPages - 1),
    [totalPages, onChangePage],
  );
  const onNextPage = useCallback(() => {
    onChangePage(curPage + 1 < totalPages ? curPage + 1 : curPage);
  }, [curPage, onChangePage, totalPages]);
  const onPreviousPage = useCallback(
    () => onChangePage(curPage === 0 ? 0 : curPage - 1),
    [curPage, onChangePage],
  );

  if (
    !Number.isInteger(totalPages) ||
    totalPages - 1 < 1 ||
    !Number.isInteger(curPage)
  ) {
    return null;
  }
  return (
    <Wrapper>
      {onFirstPage && (
        <PageNavButton disabled={curPage === 0} onClick={onFirstPage}>
          <FontAwesomeIcon fixedWidth icon={faAngleDoubleLeft} size="lg" />
        </PageNavButton>
      )}
      <PageNavButton disabled={curPage === 0} onClick={onPreviousPage}>
        <FontAwesomeIcon fixedWidth icon={faAngleLeft} size="lg" />
      </PageNavButton>
      <Text>{`${curPage + 1} of ${totalPages}`}</Text>
      <PageNavButton disabled={totalPages - 1 === curPage} onClick={onNextPage}>
        <FontAwesomeIcon fixedWidth icon={faAngleRight} size="lg" />
      </PageNavButton>
      {onLastPage && (
        <PageNavButton
          disabled={totalPages - 1 === curPage}
          onClick={onLastPage}
        >
          <FontAwesomeIcon fixedWidth icon={faAngleDoubleRight} size="lg" />
        </PageNavButton>
      )}
    </Wrapper>
  );
}

PageButtons.propTypes = {
  curPage: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
};
