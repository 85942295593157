import styled from "@emotion/styled";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  margin: 0 auto;
  max-width: 60em;
`;

export default function ErrorFallback({ error }) {
  const [eventId, setEventId] = useState(null);

  useEffect(() => {
    const id = Sentry.captureException(error);
    setEventId(id);
  }, [error]);

  return (
    <ErrorWrapper role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <p>
        We have been notified about this issue and we will take a look at it
        shortly.
      </p>
      <pre>{eventId}</pre>
      <button
        onClick={() => Sentry.showReportDialog({ eventId })}
        type="button"
      >
        Report feedback
      </button>
    </ErrorWrapper>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};
