import PropTypes from 'prop-types';

export default function Ribbon({ title }) {
  if (!title) return null;
  return <div className="corner-ribbon top-right sticky yellow">{title}</div>;
}

Ribbon.propTypes = {
  title: PropTypes.string.isRequired,
};
