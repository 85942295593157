/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import PropTypes from "prop-types";
import { Wrapper } from "../ClientSearch";

export default function ClientList({ clients }) {
  return (
    <Wrapper>
      <div className="client-list-selectors">
        {(!clients || !clients.length) && <div>No Clients Found</div>}
        {clients &&
          clients.map((client) => (
            <div className="client-selector" key={client.id}>
              {client.label || client.name}
            </div>
          ))}
      </div>
    </Wrapper>
  );
}

ClientList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  clients: PropTypes.arrayOf(PropTypes.object),
};
