/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";

export default function DashboardIsland({
  children,
  Header,
  Link,
  TotalValue,
}) {
  return (
    <div
      css={css`
        width: 100%;
        padding: 10px;
        font-weight: bold;
        font-size: 12px;
        height: 100%;
        background-color: #e2e2e4;
        color: #636363;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        .contents {
          height: 90%;
        }
        .header {
          padding-bottom: 5px;
          padding-left: 5px;
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          height: 10%;
          margin-bottom: 15px;
        }
        .link {
          float: right;
          font-size: 14px;
        }
      `}
    >
      <div className="header">
        <span>
          <span>
            <span
              css={css`
                font-size: 15px;
                display: inline-block;
                width: ${TotalValue || TotalValue === 0 ? "87%" : null};
              `}
            >
              {Header}
            </span>
          </span>
        </span>
        {(TotalValue || TotalValue === 0) && (
          <span
            className="link"
            css={css`
              border-radius: 50% !important;
              width: 25px !important;
              height: 25px !important;
              padding-top: 5px !important;
              background: #e2e2e4 !important;
              border: 2px solid #116ff5 !important;
              text-align: center !important;
              font-size: 9px !important;
              color: #116ff5 !important;
              margin-right: 5px;
            `}
          >
            {TotalValue}
          </span>
        )}
        <span className="link">{Link}</span>
      </div>
      <div className="contents">{children}</div>
    </div>
  );
}

DashboardIsland.propTypes = {
  children: PropTypes.node,
  Header: PropTypes.node,
  Link: PropTypes.node,
  TotalValue: PropTypes.number,
};
