/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import ClientSearch from "views/components/ClientSearch";

function ClientSelector({
  clientId = null,
  clients,
  disabled,
  onChangeClient,
  onChangeSearchText,
  onClearClient,
  searchText,
}) {
  return (
    <ClientSearch
      changeSearchText={onChangeSearchText}
      clients={clients}
      disabled={disabled}
      onChangeClient={onChangeClient}
      onClearClient={onClearClient}
      searchText={searchText}
      selectedClientId={clientId}
    />
  );
}

ClientSelector.propTypes = {
  clientId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  clients: PropTypes.array,
  disabled: PropTypes.bool,
  onChangeClient: PropTypes.func,
  onChangeSearchText: PropTypes.func,
  onClearClient: PropTypes.func,
  searchText: PropTypes.string,
};

export default ClientSelector;
