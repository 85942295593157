import PropTypes from 'prop-types';
import { Component } from 'react';

export default class UserList extends Component {
  render() {
    const { users } = this.props;
    if (!users || !users.length) {
      return '-';
    }
    return users.map((user) => user.name).join(', ');
  }
}

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
};
