import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

const BaseActionButton = styled(Button)`
  color: #fff;
  border-radius: 0 !important;
  height: 55px;
  width: 125px;
  margin: 0;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :disabled {
    padding: 0;
    margin: 0;
    background-color: #cccccc !important;
    opacity: 1;
  }
  & > i,
  & > svg {
    display: block;
    font-size: 2rem;
    padding-bottom: 5px;
    text-align: center;
    margin: 0 auto;
  }
`;

export const PurpleButton = styled(BaseActionButton)`
  background-color: #87009a;
  &:hover {
    background-color: #87009a;
  }
`;

export const GreenButton = styled(BaseActionButton)`
  background-color: #00ae4f;
  &:hover {
    background-color: #009343;
  }
`;

export const GreyButton = styled(BaseActionButton)`
  background-color: #565f62;
  &:hover {
    background-color: #565f62;
  }
`;

export const LightBlueButton = styled(BaseActionButton)`
  background-color: #0094c8;
  &:hover {
    background-color: #007daa;
  }
`;

export const OrangeButton = styled(BaseActionButton)`
  background-color: #f4511e;
  &:hover {
    background-color: #d84315;
  }
`;

export const LightOrangeButton = styled(BaseActionButton)`
  background-color: #ffa433;
  &:hover {
    background-color: #ffa433;
  }
`;

export const DarkBlueButton = styled(BaseActionButton)`
  background-color: #405a86;
  &:hover {
    background-color: #364c71;
  }
`;

export const BlueButton = styled(BaseActionButton)`
  background-color: #405a86;
  &:hover {
    background-color: #364c71;
  }
`;

export const RedButton = styled(BaseActionButton)`
  background-color: #cc3333;
  &:hover {
    background-color: #ad2b2b;
  }
`;
