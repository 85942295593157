/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { Alert, Button, FormControl } from "react-bootstrap";

import useEmailChanger, { statuses } from "hooks/use-email-changer";
import { useCurrentUser } from "hooks/user";
import validateEmail from "libs/validateEmail";

import FlatModal from "views/components/Modals/FlatModal";

const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  padding: 0 0 10px;
`;

const Message = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;

function EnterPassword({ onCheckPassword }) {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  return (
    <>
      <Title>Enter your password</Title>
      <Message>Please confirm your password below</Message>
      <FormControl
        key="password"
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter your Password"
        type="password"
      />
      <Button
        disabled={password.length <= 6}
        onClick={async () => {
          try {
            setError(null);
            await onCheckPassword(password);
          } catch (e) {
            setError(e.message);
          }
        }}
      >
        Request change
      </Button>
      {error ? <Alert bsStyle="danger">{error}</Alert> : null}
    </>
  );
}

EnterPassword.propTypes = {
  onValidPassword: PropTypes.func.isRequired,
};

function EnterCode({ onCheckCode }) {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  return (
    <>
      <Title>Enter code</Title>
      <Message>Please enter the code from your new email address below</Message>
      <FormControl
        onChange={(e) => setCode(e.target.value)}
        placeholder="Enter code"
        type="text"
      />
      <Button
        disabled={!code.match(/^\d{6}$/)}
        onClick={async () => {
          try {
            setError(null);
            await onCheckCode(code);
          } catch (e) {
            setError(e.message);
          }
        }}
      >
        Confirm change
      </Button>
      {error ? <Alert bsStyle="danger">{error}</Alert> : null}
    </>
  );
}

EnterCode.propTypes = {
  onValidCode: PropTypes.func.isRequired,
};

function EnterNewEmail({ onSetNewEmail }) {
  const { user } = useCurrentUser();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (email && email === user.email) {
      setError("New email cannot be the same as previous email");
    }
  }, [email, user]);

  return (
    <>
      <Title>Enter new email</Title>
      <Message>
        Please enter your new email below. A code will be sent to it.
      </Message>
      <input
        className="form-control"
        key="newEmail"
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter New Email"
      />
      <Button
        disabled={!validateEmail(email) || email === user.email}
        onClick={async () => {
          try {
            setError(null);
            await onSetNewEmail(email);
          } catch (e) {
            setError(e.message);
          }
        }}
      >
        Request change
      </Button>
      {error ? <Alert bsStyle="danger">{error}</Alert> : null}
    </>
  );
}

function EmailChangeModal({ onHide }) {
  const {
    status,
    handleCheckPassword,
    handleSetNewEmail,
    handleCheckValidationCode,
  } = useEmailChanger();

  return (
    <FlatModal onHide={onHide} show title="Change your Email">
      <Wrapper>
        {status === statuses.PASSWORD ? (
          <EnterPassword onCheckPassword={handleCheckPassword} />
        ) : null}
        {status === statuses.NEW_EMAIL ? (
          <EnterNewEmail onSetNewEmail={handleSetNewEmail} />
        ) : null}
        {status === statuses.CONFIRM_EMAIL_CODE ? (
          <EnterCode onCheckCode={handleCheckValidationCode} />
        ) : null}
        {status === statuses.SUCCESS ? (
          <>
            <Alert bsStyle="success">Your email has been changed</Alert>

            <Button onClick={onHide}>OK</Button>
          </>
        ) : null}
      </Wrapper>
    </FlatModal>
  );
}

EmailChangeModal.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
  onHide: PropTypes.func,
};

export default EmailChangeModal;
