import PropTypes from "prop-types";
import { Button, ButtonGroup, Col, ListGroupItem, Row } from "react-bootstrap";

import formatBayLocation from "libs/formatBayLocation";

import { EDITOR_MODES } from "../constants";

import ExpandingTable from "../SharedControls/ExpandingTable";

export default function Rack({
  rack,
  area,
  canEdit,
  onSelectRack,
  manufacturerName = "",
}) {
  return (
    <ListGroupItem header={rack.name} key={rack.id}>
      <Row>
        <Col className="item-information" sm={7}>
          <b>Manufacturer:</b> {manufacturerName}
          <br />
          <b>Series:</b> {rack.SeriesName || "N/A"}
          <br />
          <b>Origin:</b> [{rack.originX},{rack.originY}
          ]
          <br />
          <b>Total Length:</b> {rack.totalX}
          mm
          <br />
          <b>Rotation:</b> {rack.rotation}
          °
          <br />
          <b>Area:</b> {rack.AreaId && area ? area.name : "No Area"}
          <br />
        </Col>
        {canEdit && (
          <Col sm={5}>
            <ButtonGroup className="pull-right">
              <Button
                className="text-btn"
                onClick={() => onSelectRack(rack.id, EDITOR_MODES.edit)}
              >
                Edit Rack
              </Button>
              <Button
                className="text-btn"
                onClick={() => onSelectRack(rack.id, EDITOR_MODES.copy)}
              >
                Copy Rack
              </Button>
            </ButtonGroup>
          </Col>
        )}
        <ExpandingTable title={`${rack.bays?.length ?? 0} Bays`}>
          <thead>
            <tr>
              <th>Location</th>
              <th>Bay Type</th>
              <th>Beam Levels</th>
            </tr>
          </thead>
          <tbody>
            {rack.bays
              ? [...rack.bays]
                  .sort((a, b) => (a.location > b.location ? 1 : -1))
                  .map((bay) => (
                    <tr key={bay.id}>
                      <td>
                        {rack.name}
                        {formatBayLocation(bay.location)}
                      </td>
                      <td>{bay.bayTemplate.name}</td>
                      <td>{bay.beamCount}</td>
                    </tr>
                  ))
              : null}
          </tbody>
        </ExpandingTable>
      </Row>
    </ListGroupItem>
  );
}

Rack.propTypes = {
  canEdit: PropTypes.bool,
  onSelectRack: PropTypes.func,
  area: PropTypes.shape({
    name: PropTypes.string,
  }),
  manufacturerName: PropTypes.string,
  rack: PropTypes.shape({
    AreaId: PropTypes.string,
    bays: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    originX: PropTypes.number,
    originY: PropTypes.number,
    SeriesName: PropTypes.string,
    id: PropTypes.string,
    rotation: PropTypes.number,
    totalX: PropTypes.number,
  }),
};
