import styled from "@emotion/styled";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Dialog from "@radix-ui/react-dialog";
import PropTypes from "prop-types";

const DialogContentWithWidth = styled(Dialog.Content)`
  position: relative;
  width: ${(props) => props.width ?? 500}px;
`;

/**
 * FlatModal is the generic modal component that can be reused by other modals
 */
export default function FlatModal({
  children,
  closeButton = true,
  onHide,
  show,
  title,
  width,
}) {
  if (!show) {
    return null;
  }
  return (
    <Dialog.Root onOpenChange={onHide} open={show}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay">
          <DialogContentWithWidth className="DialogContent" width={width}>
            {title ? (
              <Dialog.Title className="DialogTitle">{title}</Dialog.Title>
            ) : null}
            {children}
            {closeButton ? (
              <Dialog.Close asChild>
                <button aria-label="Close" className="IconButton">
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </button>
              </Dialog.Close>
            ) : null}
          </DialogContentWithWidth>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

FlatModal.propTypes = {
  children: PropTypes.node,
  closeButton: PropTypes.bool,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.number,
};

export const ModalPaddedBody = styled.div`
  padding: 10px 10px 10px;
`;

export const ModalControls = styled.div`
  display: flex;
  flex: row;
  justify-content: flex-end;
  padding: 10px;
`;
