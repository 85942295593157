import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useMemo, useReducer, useRef, useState } from "react";
import { Col, Row, Alert, Grid } from "react-bootstrap";

import { useInspection } from "hooks/inspections";
import { RISKS_ARRAY as risks, RISKS } from "libs/constants";
import getOptimisedImageUrl from "libs/getOptimisedImageUrl";
import Loading from "views/components/Loading";

import RiskSlider from "views/components/Modals/ClassificationEditor/RiskSlider";

import { buildClassificationObjectToSend } from "views/components/Modals/NewClassificationEditor/helpers";
import UploaderLightbox from "views/pages/Inspection/components/uploading-lightbox";

import {
  ActionField,
  DefectField,
  ComponentTypeField,
  ComponentField,
  FrameLevelsField,
  PositionField,
  OptionsField,
  QuantityField,
  BeamLevelsField,
  NotesField,
  ExpandableField,
  CustomerReferenceField,
  RunSpacerField,
  ImageUploaderField,
} from "./fields";
import classificationReducer, { ACTION_TYPES, canEditLevel } from "./reducer";
import {
  StyledButtonGroup,
  Previous,
  CancelButton,
  StyledButton,
  StyledImage,
} from "./styles";

/**
 * This is a form that adds damage to a given inspection.
 * It takes fixed damage parameters that correspond to the keys in the form
 * reducer. These values should not be over-ridden by the form. These are used
 * when selecting a particular frame or beam from the blueprint.
 */
export default function AddDamageEditor({
  InspectionId,
  warehouseId,
  RackId,
  onHide,
  fixedDamageParameters = {},
  setShowOffloadModal,
  setFrameLocationId,
  setBeamLevel,
  setClassificationId,
}) {
  const [editableState, dispatch] = useReducer(
    classificationReducer,
    fixedDamageParameters,
  );

  const state = { ...editableState, ...fixedDamageParameters };

  const { inspection, isLoading, addClassificationWithImages } =
    useInspection(InspectionId);

  // State for saving the data
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(null);

  const lightboxRef = useRef();

  // Loadeded, so display the Editor
  const warehouse = inspection?.warehouses?.find((w) => w.id === warehouseId);

  // RackId or state rackId, if we are uncontrolled
  const rack =
    RackId ?? state.rackId
      ? warehouse?.racks?.find((r) => r.id === (RackId ?? state.rackId))
      : null;
  const bay = rack?.bays?.find((b) => b.id === state.location);
  const frame = rack?.locations.frames.find((f) => f.id === state.location);
  const { siteFrameTemplates } = inspection;
  const frameSections = siteFrameTemplates.find(
    (s) => s.id === frame?.frame?.FrameTemplateId,
  )?.sections;
  const locationOptions = useMemo(() => {
    if (!rack) return [];

    // Combine frames and beams into one array to ensure uniqueness
    const allLocations =
      state.componentType === "Frame"
        ? rack.locations?.frames
        : rack.locations?.beams;

    // Use a Set to ensure unique values
    const uniqueLocations = new Map();
    allLocations.forEach((location) => {
      uniqueLocations.set(location.id, {
        value: location.id,
        label: location.name,
      });
    });

    return Array.from(uniqueLocations.values());
  }, [state.componentType, rack]);

  // Loading state
  if (isLoading) {
    return <Loading />;
  }
  const handleSave = async () => {
    try {
      setIsSaving(true);
      const classificationToSend = buildClassificationObjectToSend(InspectionId, state); // prettier-ignore
      const addedClassification = await addClassificationWithImages(
        classificationToSend,
        state.images,
      );
      setIsSaving(false);
      onHide();
      const offloadRiskSelected =
        classificationToSend.risk === "Amber" ||
        classificationToSend.risk === "Red";
      const rackHasOffloadLocations = rack?.offloadLocations.frames.length;
      if (rackHasOffloadLocations && offloadRiskSelected) {
        setClassificationId(addedClassification.id);
        setShowOffloadModal(true);
        setFrameLocationId(state.location);
        setBeamLevel(state.level);
      }
    } catch (e) {
      setIsSaving(false);
      setIsError(e.message);
    }
  };

  const disableFrameLevelsField = !canEditLevel(
    state.componentType,
    state.component,
  );
  return (
    <>
      <Previous>
        {RackId ? (
          <>
            {rack.AreaName ? `${rack.AreaName}, ` : null}
            {rack ? `Rack ${rack?.name}` : null}
          </>
        ) : (
          <>
            <Grid fluid>
              <Row>
                <Col sm={6}>
                  <OptionsField
                    disabled={"areaId" in fixedDamageParameters}
                    onChange={(areaId) =>
                      dispatch({ type: ACTION_TYPES.SET_AREA_ID, areaId })
                    }
                    options={warehouse.areas.map((a) => ({
                      value: a.id,
                      label: a.name,
                    }))}
                    title="Area"
                    value={state.areaId ?? ""}
                  />
                </Col>
                <Col sm={6}>
                  <OptionsField
                    disabled={"rackId" in fixedDamageParameters}
                    onChange={(rackId) => dispatch({ type: ACTION_TYPES.SET_RACK_ID, rackId })} // prettier-ignore
                    options={warehouse.racks
                      .filter((r) =>
                        state.areaId && state.areaId !== "All Areas"
                          ? r.AreaId === state.areaId
                          : true,
                      )
                      .map((r) => ({
                        value: r.id,
                        label: `${r.AreaName ? `${r.AreaName} - ` : ""}${
                          r.name
                        }`,
                      }))}
                    title="Rack"
                    value={state.rackId ?? ""}
                  />
                </Col>
              </Row>
            </Grid>
          </>
        )}
      </Previous>

      <RiskSlider
        disabled={!RackId && !state.rackId}
        isReclassifying
        onChange={(idx) =>
          dispatch({ type: ACTION_TYPES.SET_RISK, risk: risks[idx] })
        }
        risk={state.risk}
      />

      <Grid fluid>
        <Row>
          <Col sm={12}>
            <ComponentTypeField
              disabled={!state.risk || "componentType" in fixedDamageParameters}
              onChange={(componentType) =>
                dispatch({
                  type: ACTION_TYPES.SET_COMPONENT_TYPE,
                  componentType,
                })
              }
              risk={state.risk}
              value={state.componentType}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <OptionsField
              disabled={
                state.componentType === "" ||
                state.risk === RISKS.none ||
                !state.risk ||
                !state.componentType ||
                "location" in fixedDamageParameters
              }
              onChange={(location) =>
                dispatch({ type: ACTION_TYPES.SET_LOCATION, location })
              }
              options={locationOptions}
              title="Location"
              value={state.location ?? ""}
            />
          </Col>
          <Col sm={6}>
            <CustomerReferenceField
              onChange={(customerReference) =>
                dispatch({
                  type: ACTION_TYPES.SET_CUSTOMER_REFERENCE,
                  customerReference,
                })
              }
              value={state.customerReference}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <ComponentField
              componentType={state.componentType}
              disabled={!state.location}
              onChange={(component) =>
                dispatch({ type: ACTION_TYPES.SET_COMPONENT, component })
              }
              risk={state.risk}
              value={state.component}
            />
          </Col>
          <Col sm={6}>
            {state.componentType === "Beam" ? (
              <BeamLevelsField
                disabled={!state.component}
                onChange={(level) =>
                  dispatch({ type: ACTION_TYPES.SET_LEVEL, level })
                }
                options={bay?.beams?.map((b) => ({
                  value: b.id,
                  label: b.name || b.level,
                }))}
                value={state.level ?? ""}
              />
            ) : (
              <FrameLevelsField
                disabled={disableFrameLevelsField}
                onChange={(level) =>
                  dispatch({ type: ACTION_TYPES.SET_LEVEL, level })
                }
                value={state.level ?? ""}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <PositionField
              component={state.component}
              componentType={state.componentType}
              disabled={!state.level}
              frame={frame?.frame}
              onChange={(position) =>
                dispatch({ type: ACTION_TYPES.SET_POSITION, position })
              }
              risk={state.risk}
              value={state.position}
            />
          </Col>
          <Col sm={6}>
            {state.component === "Run Spacer" ? (
              <RunSpacerField
                onChange={(runspacerSize) =>
                  dispatch({ type: ACTION_TYPES.SET_RUN_SPACER, runspacerSize })
                }
                value={state.runspacerSize}
              />
            ) : null}

            {state.component === "Upright" ? (
              <OptionsField
                disabled={!state.position}
                onChange={(section) =>
                  dispatch({ type: ACTION_TYPES.SET_SECTION, section })
                }
                // If it is front and rear, then just use the front
                options={[
                  ...(state.position === "Front" ||
                  state.position === "Front & Rear"
                    ? frameSections?.front?.map((s) => ({
                        value: s.id,
                        label: s.name,
                      })) ?? []
                    : []),
                  ...(state.position === "Rear"
                    ? frameSections?.rear?.map((s) => ({
                        value: s.id,
                        label: s.name,
                      })) ?? []
                    : []),
                ]}
                title="Section"
                value={state.section ?? ""}
              />
            ) : null}
          </Col>
        </Row>

        {state.risk !== "None" ? (
          <Row>
            <Col sm={6}>
              <DefectField
                component={state.component}
                componentType={state.componentType}
                disabled={!state.position}
                onChange={(defect) =>
                  dispatch({ type: ACTION_TYPES.SET_DEFECT, defect })
                }
                position={state.position}
                risk={state.risk}
                value={state.defect}
              />
            </Col>
            <Col sm={6}>
              <ActionField
                component={state.component}
                componentType={state.componentType}
                defect={state.defect}
                disabled={!state.defect}
                onChange={(action) =>
                  dispatch({ type: ACTION_TYPES.SET_ACTION, action })
                }
                position={state.position}
                risk={state.risk}
                value={state.action}
              />
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col sm={6}>
            <QuantityField
              onChange={(quantity) =>
                dispatch({ type: ACTION_TYPES.SET_QUANTITY, quantity })
              }
              value={state.quantity}
            />
          </Col>
        </Row>

        <ExpandableField title="Notes and images">
          <Row>
            <Col sm={6}>
              <NotesField
                onChange={(notes) =>
                  dispatch({ type: ACTION_TYPES.SET_NOTES, notes })
                }
                value={state.notes}
              />
            </Col>

            <Col sm={6}>
              <ImageUploaderField
                images={state.images}
                onAdd={(image) =>
                  dispatch({ type: ACTION_TYPES.ADD_IMAGE, image })
                }
              />
              {state.images?.slice(0, 2).map((image, idx) => (
                <StyledImage
                  key={image.remotePath}
                  onClick={() => lightboxRef?.current?.openWith(idx)}
                  src={
                    image.location
                      ? getOptimisedImageUrl(`${image.location}`)
                      : "/img/defaults/logo.png"
                  }
                />
              ))}
            </Col>
          </Row>
        </ExpandableField>
      </Grid>
      <StyledButtonGroup>
        <CancelButton onClick={onHide} type="button">
          Cancel
        </CancelButton>{" "}
        <StyledButton
          disabled={isSaving || !state.action || !state.quantity}
          onClick={handleSave}
        >
          Add Damage{" "}
          {isSaving ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
        </StyledButton>
      </StyledButtonGroup>

      <UploaderLightbox
        images={state.images?.map((image) => ({
          caption: image.caption,
          src: image.location
            ? getOptimisedImageUrl(`${image.location}`)
            : "/img/defaults/logo.png",
        }))}
        onChangeCaption={(idx, caption) =>
          dispatch({
            type: ACTION_TYPES.SET_IMAGE_CAPTION,
            index: idx,
            caption,
          })
        }
        onDelete={(deleteIdx) =>
          dispatch({ type: ACTION_TYPES.REMOVE_IMAGE, index: deleteIdx })
        }
        ref={lightboxRef}
      />
      {isError ? (
        <Alert bsStyle="danger">{isError}. Please try again.</Alert>
      ) : null}
    </>
  );
}

AddDamageEditor.propTypes = {
  warehouseId: PropTypes.string,
  InspectionId: PropTypes.string,
  RackId: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  fixedDamageParameters: PropTypes.shape(),
  setShowOffloadModal: PropTypes.func,
  setFrameLocationId: PropTypes.func,
  setBeamLevel: PropTypes.func,
  setClassificationId: PropTypes.func,
};
