/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import EnterCode from "views/pages/Login/EnterCode";
import LoginForm from "views/pages/Login/LoginForm";
import NewPassword from "views/pages/Login/NewPassword";
import RequestCode from "views/pages/Login/RequestCode";

const { PUBLIC_URL } = process.env;

function Wrapper({ children, loading, info }) {
  return (
    <div
      // TODO: Fix tab issues
      css={css`
        background-image: url(${`${PUBLIC_URL}/img/login/login-background.jpg`});
        width: 100%;
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .form-description {
          color: white;
          padding-top: 5px;
          padding-bottom: 5px;
        }
        .outer-container {
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
        }
        .inner-container {
          width: 500px;
          background-color: #24262e;
          margin: auto;
          box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
          padding: 40px 5% 45px 5%;
          border-radius: 20px;
        }
        .form-inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 45px;
          margin-top: 20px;
        }
        .error-message {
          color: red;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        .info-message {
          color: white;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        .form-group {
          margin-top: 20px;
        }
        h4 {
          color: white;
        }
        @keyframes loading {
          0% {
            left: -20vw;
          }
          100% {
            left: 120vw;
          }
        }
        .loading {
          position: fixed;
          left: 0px;
          bottom: 0px;
          height: 10px;
          width: 100vw;
          background-color: #116ff5;
          .internal-loader {
            position: fixed;
            width: 20vw;
            height: 10px;
            background-color: #408bf7;
            animation: loading;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
        }
      `}
    >
      <div className="outer-container">
        <div className="inner-container">
          <form>
            <div className="form-inner">
              <img
                alt="Econform Logo"
                className="logo"
                src="/img/logo/econform-light.png"
              />
            </div>
            <div>
              <p className="info-message">{info}</p>
            </div>
            {children}
          </form>
        </div>
        {loading !== "" && (
          <div className="loading">
            <div className="internal-loader" />
          </div>
        )}
      </div>
    </div>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

function Login() {
  const [mode, setMode] = useState("login");
  const [error, setError] = useState(null);
  const [info, setInfo] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  const [passwordResetCode, setPasswordResetCode] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    setError("");
    setInfo("");
    setLoading("");
    /** Password security validation */
    if (mode === "newPassword") {
      const passwordIsValid = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(
        password,
      );
      if (password?.length && (!(password?.length > 6) || !passwordIsValid)) {
        setError(
          "Password must be alphanumeric and be at least 7 characters long",
        );
      } else {
        setError("");
      }
    }
  }, [mode, email, password]);

  useEffect(() => {
    setInfo("");
    setLoading("");
  }, [error]);

  return (
    <Wrapper info={info} loading={loading}>
      {mode === "newPassword" ? (
        <NewPassword
          email={email}
          error={error}
          passwordResetCode={passwordResetCode}
          setError={setError}
          setInfo={setInfo}
          setLoading={setLoading}
          setMode={setMode}
        />
      ) : null}
      {mode === "requestCode" ? (
        <RequestCode
          email={email}
          error={error}
          loading={loading}
          setEmail={setEmail}
          setLoading={setLoading}
          setMode={setMode}
        />
      ) : null}
      {mode === "passwordCode" ? (
        <EnterCode
          email={email}
          error={error}
          passwordResetCode={passwordResetCode}
          setError={setError}
          setLoading={setLoading}
          setMode={setMode}
          setPasswordResetCode={setPasswordResetCode}
        />
      ) : null}
      {mode === "login" || !mode ? (
        <LoginForm
          email={email}
          error={error}
          loading={loading}
          password={password}
          setEmail={setEmail}
          setError={setError}
          setLoading={setLoading}
          setMode={setMode}
          setPassword={setPassword}
        />
      ) : null}
    </Wrapper>
  );
}

export default Login;
