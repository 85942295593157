import styled from "@emotion/styled";
import { Button, FormControl } from "react-bootstrap";

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  justify-items: end;
  align-items: center;
  margin-top: 1em;
`;

export const Group = styled.div`
  background-color: #eeeeee;
  border-bottom: 1px solid grey;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

export const AddFormControl = styled(FormControl)`
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: inline-block;
  height: 45px;
  margin: 0;
  width: calc(100% - 50px) !important;
`;

export const AddButton = styled(Button)`
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  color: white;
  height: 45px;
  margin: -5px 0 0;
  &:disabled {
    margin: -5px 0 0;
  }
  width: 50px;
`;

export const AddControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OrgListWrapper = styled.ul`
  background-color: #f2f2f6;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  list-style: none;
  padding: 0;
  max-height: 125px;
  overflow-y: auto;
  li {
    padding: 10px;
    cursor: pointer;
    width: 100%;
    background-color: #f2f2f6;
    border-bottom: 1px solid #99999999;
  }
`;

export const SearchWithList = styled(FormControl)`
  ${(props) =>
    props.isOpen
      ? `
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          border-bottom: 1px solid #aaaaaaaa !important;
          margin-bottom: 10px;
        `
      : `margin-bottom: 0 !important;`}
`;

export const ClearButton = styled(Button)`
  padding: 0 10px;
  overflow: hidden;
  margin: 0;
  border-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
  background-color: #2d72ed;
  height: 34px;
`;
