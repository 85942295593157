import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  Button,
  FormControl,
  ControlLabel,
  Col,
  Row,
  Form,
  Alert,
} from "react-bootstrap";

import { addArea } from "libs/api";

const StyledForm = styled(Form)`
  color: #fff;
  padding: 20px;
`;

const StyledFormControl = styled(FormControl)`
  background-color: #30333b;
  color: white;
  border: 0;
  margin-top: 5px;
`;

const PaddedCol = styled(Col)`
  padding: 15px 15px 5px;
`;

export default function AddEditArea({
  onAddArea,
  WarehouseId = "",
  name = "",
  setName,
}) {
  const [errorMessage, setErrorMessage] = useState(null);
  return (
    <StyledForm
      horizontal
      onKeyPress={(e) => (e.which === 13 ? e.preventDefault() : "")}
    >
      <Row>
        <Col sm={5}>
          <ControlLabel htmlFor="add-area">Area Name</ControlLabel>
        </Col>
        <Col sm={7}>
          <StyledFormControl
            id="add-area"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Area Name"
            value={name ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <PaddedCol sm={12}>
          {WarehouseId.length > 0 ? (
            <>Create area to add racks</>
          ) : (
            <Alert bsStyle="danger">
              A Warehouse is required to create an area
            </Alert>
          )}
        </PaddedCol>
      </Row>
      <Row>
        <Col sm={12}>
          <Button
            className="action-button area-create-button"
            disabled={!name || !WarehouseId}
            onClick={async () => {
              try {
                setErrorMessage(null);
                const Area = await addArea({ name, WarehouseId });
                if (Area) {
                  onAddArea(Area.id);
                }
              } catch (err) {
                setErrorMessage(
                  "There was a problem creating the area. Please try again later.",
                );
              }
            }}
          >
            Create
          </Button>
        </Col>
      </Row>
      {errorMessage ? (
        <Row>
          <Col sm={12}>
            <Alert bsStyle="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      ) : null}
    </StyledForm>
  );
}

AddEditArea.propTypes = {
  name: PropTypes.string,
  onAddArea: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  WarehouseId: PropTypes.string.isRequired,
};
