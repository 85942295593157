import PropTypes from "prop-types";
import { useState } from "react";

import SectionHeader from "views/components/SectionHeader";

/**
 * Expandable panel for holding groups of classifications
 */
export default function Section({ total = 0, title, icon, children }) {
  const [expanded, setExpanded] = useState(true);
  if (total === 0) return null;
  return (
    <>
      <SectionHeader
        expanded={expanded}
        icon={icon}
        number={total}
        onToggleExpand={() => setExpanded(!expanded)}
        title={title}
      />
      {expanded ? children : null}
    </>
  );
}

Section.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  title: PropTypes.string,
  total: PropTypes.number,
};
