import PropTypes from 'prop-types';

import { Col, ControlLabel, Form, FormControl, FormGroup } from 'react-bootstrap';

export default function RackFilters({
  racksFilterOptions,
  manufacturers = [],
  areas = [],
  setFilterOptions,
}) {
  return (
    <div className="filters">
      <Form horizontal onKeyPress={(e) => (e.which === 13 ? e.preventDefault() : '')}>
        <FormGroup controlId="formHorizontalManufacturer">
          <Col sm={4}>
            <ControlLabel>Manufacturer</ControlLabel>
          </Col>
          <Col sm={8}>
            <FormControl
              componentClass="select"
              onChange={(e) => {
                setFilterOptions('ManufacturerId', e.target.value);
              }}
              placeholder="Frame Template"
              value={racksFilterOptions.ManufacturerId}
            >
              <option value="">Any</option>
              {manufacturers.map((manufacturer) => (
                <option key={manufacturer.id} value={manufacturer.id}>
                  {manufacturer.name}
                </option>
              ))}
            </FormControl>
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalManufacturer">
          <Col sm={4}>
            <ControlLabel>Area</ControlLabel>
          </Col>
          <Col sm={8}>
            <FormControl
              componentClass="select"
              onChange={(e) => {
                setFilterOptions('AreaId', e.target.value);
              }}
              placeholder="Frame Template"
              value={racksFilterOptions.AreaId}
            >
              <option value="">Any</option>
              {areas.map((area) => (
                <option key={area.id} value={area.id}>
                  {area.name}
                </option>
              ))}
            </FormControl>
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalManufacturer">
          <Col sm={4}>
            <ControlLabel>Rack Name</ControlLabel>
          </Col>
          <Col sm={8}>
            <FormControl
              onChange={(e) => {
                setFilterOptions('searchText', e.target.value);
              }}
              placeholder="Search Rack Name"
              type="text"
              value={racksFilterOptions.searchText}
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalManufacturer">
          <Col sm={4}>
            <ControlLabel>Length</ControlLabel>
          </Col>
          <Col sm={4}>
            <FormControl
              min="0"
              onChange={(e) => {
                setFilterOptions('minLength', parseInt(e.target.value, 10));
              }}
              placeholder="Min"
              type="number"
              value={racksFilterOptions.minLength}
            />
          </Col>
          <Col sm={4}>
            <FormControl
              min="0"
              onChange={(e) => {
                setFilterOptions('maxLength', parseInt(e.target.value, 10));
              }}
              placeholder="Max"
              type="number"
              value={racksFilterOptions.maxLength}
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="formHorizontalManufacturer">
          <Col sm={4}>
            <ControlLabel>Bays Qty</ControlLabel>
          </Col>
          <Col sm={4}>
            <FormControl
              min="0"
              onChange={(e) => {
                setFilterOptions('minBays', parseInt(e.target.value, 10));
              }}
              placeholder="Min"
              type="number"
              value={racksFilterOptions.minBays}
            />
          </Col>
          <Col sm={4}>
            <FormControl
              min="0"
              onChange={(e) => {
                setFilterOptions('maxBays', parseInt(e.target.value, 10));
              }}
              placeholder="Max"
              type="number"
              value={racksFilterOptions.maxBays}
            />
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
}

RackFilters.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  racksFilterOptions: PropTypes.shape({
    maxBays: PropTypes.number,
    minBays: PropTypes.number,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    searchText: PropTypes.string,
    AreaId: PropTypes.string,
    ManufacturerId: PropTypes.string,
  }),
  setFilterOptions: PropTypes.func,
  manufacturers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
};
