/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";

import { FILTER_KEYS, useFilters } from "hooks/useFilter";

import DateRangeOption from "./DateRangeOption";
import Option from "./Option";
import StatusOption from "./StatusOption";

const FilterButton = styled(Button)`
  background-color: #ffffff;
  color: #00000088;
  border-radius: 5px !important;
  width: 100px !important;
  margin-top: 1px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-align: left;
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.1),
    0 1px 1px rgba(0, 0, 0, 0.1);
  span {
    padding: 0;
  }
  &:hover,
  &:focus {
    color: #00000088;
    background-color: #ffffff77;
  }
  display: flex;
  justify-content: space-between;
`;

const Underlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
`;

export default function FilterOptions() {
  const {
    clients,
    clientId,
    siteId,
    completedAtEndDate,
    completedAtStartDate,
    createdAtEndDate,
    createdAtStartDate,
    sites,
    inspectionStatus,
    actionStatus,

    setClientId,
    setSiteId,
    setCompletedAtEndDate,
    setCompletedAtStartDate,
    setCreatedAtEndDate,
    setCreatedAtStartDate,
    setInspectorId,
    setInspectionStatus,
    setActionStatus,

    canUpdate,
  } = useFilters();

  const [show, setShow] = useState(false);
  const [clientSearchText, setClientSearchText] = useState("");
  const [siteSearchText, setSiteSearchText] = useState("");
  const [inspectorSearchText, setInspectorSearchText] = useState("");

  const canUpdateClient = canUpdate(FILTER_KEYS.clientId);
  const canUpdateSite = canUpdate(FILTER_KEYS.siteId);
  const canUpdateInspectorId = canUpdate(FILTER_KEYS.inspectorId);
  const canUpdateInspectionStatus = canUpdate(FILTER_KEYS.inspectionStatus);
  const canUpdateActionStatus = canUpdate(FILTER_KEYS.actionStatus);
  const canUpdateCompletedAtStartDate = canUpdate(
    FILTER_KEYS.completedAtStartDate,
  );
  const canUpdateCompletedAtEndDate = canUpdate(FILTER_KEYS.completedAtEndDate);
  const canUpdateCreatedAtStartDate = canUpdate(FILTER_KEYS.createdAtStartDate);
  const canUpdateCreatedAtEndDate = canUpdate(FILTER_KEYS.createdAtEndDate);

  const canUpdateAny = [
    canUpdateClient && !clientId,
    canUpdateSite && !siteId,
    canUpdateInspectorId,
    canUpdateInspectionStatus,
    canUpdateActionStatus,
    canUpdateCompletedAtStartDate,
    canUpdateCompletedAtEndDate,
    canUpdateCreatedAtStartDate,
    canUpdateCreatedAtEndDate,
  ].includes(true);

  // TODO: Determine if anything can be updated. If not, don't show the filter button

  if (!canUpdateAny) return null;

  return (
    <div
      css={css`
        position: relative;
        width: 100px !important;
      `}
    >
      <div
        css={css`
          position: absolute;
          width: 100px !important;
        `}
      >
        <FilterButton
          disabled={!canUpdateAny}
          onClick={() => {
            setShow(!show);
          }}
        >
          Filter
          <span>
            <FontAwesomeIcon icon={show ? faChevronDown : faChevronRight} />
          </span>
        </FilterButton>
        {show && (
          <>
            <div
              css={css`
                background-color: #ffffff;
                z-index: 9999;
                position: absolute;
                left: 5px;
                width: 300px;
                border: 1px solid #dddddd;
              `}
            >
              {canUpdateClient && !clientId && (
                <Option
                  changeFunc={(newClientId) => {
                    setClientId(newClientId);
                    setClientSearchText("");
                  }}
                  options={clients.filter(
                    (client) =>
                      client.name &&
                      client.name
                        .toLowerCase()
                        .includes(clientSearchText.toLowerCase()),
                  )}
                  searchText={clientSearchText}
                  setSearchText={setClientSearchText}
                  title="Client"
                />
              )}

              {canUpdateSite && !siteId && (
                <Option
                  changeFunc={(id) => {
                    setSiteId(id);
                    setSiteSearchText("");
                  }}
                  labelKey={
                    canUpdate(FILTER_KEYS.clientId) && !clientId
                      ? "displayName"
                      : "name"
                  }
                  options={
                    canUpdate(FILTER_KEYS.clientId)
                      ? sites
                          .map((site) => ({
                            ...site,
                            displayName: `[${site.ClientName}] ${site.name}`,
                          }))
                          .filter(
                            (site) =>
                              site.displayName &&
                              site.displayName
                                .toLowerCase()
                                .includes(siteSearchText.toLowerCase()),
                          )
                      : sites
                  }
                  searchText={siteSearchText}
                  setSearchText={setSiteSearchText}
                  title="Site"
                />
              )}
              {canUpdateInspectorId && (
                <Option
                  changeFunc={(id) => {
                    setInspectorId(id);
                    setInspectorSearchText("");
                  }}
                  options={[]}
                  searchText={inspectorSearchText}
                  setSearchText={setInspectorSearchText}
                  title="Inspector"
                />
              )}

              {canUpdateInspectionStatus && !inspectionStatus && (
                <StatusOption
                  changeFunc={setInspectionStatus}
                  options={["Incomplete", "Complete"]}
                  title="Inspection Status"
                />
              )}

              {canUpdateActionStatus && !actionStatus && (
                <StatusOption
                  changeFunc={setActionStatus}
                  options={["To Do", "On Going", "Completed", "Unfinished"]}
                  title="Action Status"
                />
              )}
              {(canUpdateCreatedAtStartDate || canUpdateCreatedAtEndDate) && (
                <DateRangeOption
                  endDate={createdAtEndDate}
                  endDateChangeFunc={setCreatedAtEndDate}
                  startDate={createdAtStartDate}
                  startDateChangeFunc={setCreatedAtStartDate}
                  title="Conducted Date"
                />
              )}
              {(canUpdateCompletedAtStartDate ||
                canUpdateCompletedAtEndDate) && (
                <DateRangeOption
                  endDate={completedAtEndDate}
                  endDateChangeFunc={setCompletedAtEndDate}
                  startDate={completedAtStartDate}
                  startDateChangeFunc={setCompletedAtStartDate}
                  title="Completed Date"
                />
              )}
            </div>
            <Underlay onClick={() => setShow(false)} />
          </>
        )}
      </div>
    </div>
  );
}
