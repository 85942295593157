import PropTypes from "prop-types";

import FilterOptionsAndChips from "../../components/FilterOptionsAndChips";

import {
  ControlsWrapper,
  ControlsButton,
  ControlsButtonWrapper,
} from "./styles";

export default function Controls({
  setShowScheduleEditor,
  showNewScheduleButton,
}) {
  return (
    <ControlsWrapper>
      <FilterOptionsAndChips showSearch />
      <ControlsButtonWrapper>
        {showNewScheduleButton && (
          <ControlsButton onClick={() => setShowScheduleEditor(true)}>
            New Schedule
          </ControlsButton>
        )}
      </ControlsButtonWrapper>
    </ControlsWrapper>
  );
}

Controls.propTypes = {
  setShowScheduleEditor: PropTypes.func,
  showNewScheduleButton: PropTypes.bool,
};
