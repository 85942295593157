import styled from "@emotion/styled";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  AMBER_COLOUR,
  DEFAULT_COLOUR,
  GREEN_COLOUR,
  NONE_COLOUR,
  RED_COLOUR,
} from "libs/constants";

function getColorForRisk(risk) {
  switch (risk) {
    case "Green":
      return GREEN_COLOUR;
    case "Amber":
      return AMBER_COLOUR;
    case "Red":
      return RED_COLOUR;
    case "None":
      return NONE_COLOUR;
    default:
      return DEFAULT_COLOUR;
  }
}

export const RiskButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "showRisk" && prop !== "risk",
})`
  &,
  &:active,
  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.showRisk ? getColorForRisk(props.risk) : DEFAULT_COLOUR};
  }
  margin: 5px;
  width: 150px !important;
`;

export const Wrapper = styled.div`
  padding-top: 20px;
  background-color: #eeeeee;
  position: sticky;
  top: 0;
  .inspection-info {
    height: 50px;
  }
`;

export const AddDamageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;

  .btn-group > .btn:first-of-type:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0px !important;
    width: 110px;
    border-bottom-right-radius: 0px !important;
    margin-right: 0;
  }
  .dropdown-toggle {
    border-bottom-left-radius: 0px !important;
    border-left: 1px solid #1459d4;
    border-top-left-radius: 0px !important;
    width: 25px;
  }
  .btn {
    display: inline-block;
    height: 35px;
    margin: 0;
    padding: 0;
  }
  .btn span {
    padding: 0;
  }
`;

export const RoundLink = styled(Link)`
  align-items: center;
  background-color: #2d72ed;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 20px;
  height: 35px;
  justify-content: center;
  margin: 5px;
  padding: 0;
  width: 35px;

  // Required as the Bootstrap button adds padding to disabled buttons
  &:disabled {
    padding: 0;
  }

  &:active {
    color: #fff;
  }
`;

export const FlexJustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CompleteWrapper = styled.div`
  margin: 10px auto;
  text-align: center;
`;

export const ProgressWrapper = styled.div`
  position: fixed;
  width: calc(100vw - 225px);
  bottom: 0;
  right: 0;
  .progress {
    margin-bottom: 0;
    border-radius: 0;
    height: 10px;
    background-color: #d8d8d8;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    .progress-bar {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      background-color: #fece47;
    }
  }
`;
