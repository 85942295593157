import styled from "@emotion/styled";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const Wrap = styled.div`
  align-items: center;
  background-color: ${({ darkMode }) => (darkMode ? "#4A4C55" : "#00000000")};
  display: flex;
  height: ${({ height }) => height};
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  width: 100%;
`;
const SpinnerWrap = styled.div`
  font-size: 50px;
  color: #2d72ed;
  width: 50px !important;
  margin-left: auto;
  margin-right: auto;
`;

const Text = styled.div`
  color: ${({ darkMode }) => (darkMode ? "white" : "black")};
  text-align: center;
  padding: 15px 0;
`;

function Loading({
  timeOutSeconds = 30000,
  darkMode,
  height = "100%",
  timedOut,
  text,
}) {
  const [isTimedOut, setIsTimedOut] = useState(timedOut);

  useEffect(() => {
    const timer = setTimeout(() => setIsTimedOut(!isTimedOut), timeOutSeconds);
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Wrap darkMode={darkMode} height={height}>
      {timedOut ? (
        <h1>Error loading data, try again.</h1>
      ) : (
        <>
          <SpinnerWrap>
            <FontAwesomeIcon icon={faSpinner} spin />
          </SpinnerWrap>
          {text && <Text darkMode={darkMode}>{text}</Text>}
        </>
      )}
    </Wrap>
  );
}

Loading.propTypes = {
  darkMode: PropTypes.bool,
  height: PropTypes.string,
  text: PropTypes.string,
  timeOutSeconds: PropTypes.number,
  timedOut: PropTypes.bool,
};

export default Loading;
