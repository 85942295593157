import PropTypes from "prop-types";
import { forwardRef, useImperativeHandle } from "react";

import useGridScale from "hooks/use-grid-scale";
import GridClone from "views/components/Blueprint/canvas-elements/grid-clone";

/** @type {React.ForwardRefRenderFunction<?, Button.propTypes> */
const GridWithHandle = forwardRef(function Grid(
  { sizeX, sizeY, onmousemove = null },
  ref,
) {
  const { gridSize, lineSize, setScaleFromPixiEvent } = useGridScale({
    x: 1.5,
    y: 1.5,
  });

  /**
   * The grid allows other components to set the scale of the grid.
   * So in a parent component pass a ref in then:
   *
   * gridRef.current.setScaleFromPixiEvent(event);
   */
  useImperativeHandle(
    ref,
    () => {
      return {
        setScaleFromPixiEvent,
      };
    },
    [setScaleFromPixiEvent],
  );

  return (
    <GridClone
      gridSize={gridSize}
      height={sizeY}
      lineThickness={lineSize}
      onmousemove={onmousemove}
      width={sizeX}
    />
  );
});

GridWithHandle.propTypes = {
  sizeX: PropTypes.number.isRequired,
  sizeY: PropTypes.number.isRequired,
  onmousemove: PropTypes.func,
};

export default GridWithHandle;
