import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import {
  ControlLabelWithLoading,
  StyledFormControl,
} from "../SharedControls/Generic";

const Wrapper = styled.div`
  padding-top: 10px;
`;

export default function EditArea({ name, setName }) {
  return (
    <Wrapper>
      <Col sm={5}>
        <ControlLabelWithLoading>Area Name</ControlLabelWithLoading>
      </Col>
      <Col sm={7}>
        <StyledFormControl
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder="Enter Area Name"
          value={name}
        />
      </Col>
    </Wrapper>
  );
}

EditArea.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func.isRequired,
};
