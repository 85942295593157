// What mode should the editor be in?
export const EDITOR_MODES = {
  new: 'NEW',
  edit: 'EDIT',
  copy: 'COPY',
};

// This function returns a string that describes the current editor mode
export function getTextForMode(mode = EDITOR_MODES.edit) {
  const text = {
    [EDITOR_MODES.new]: 'New',
    [EDITOR_MODES.edit]: 'Edit',
    [EDITOR_MODES.copy]: 'Copy',
  };
  return text[mode];
}

// Which editor should be shown?
export const EDITORS = {
  frames: 'Frames',
  bays: 'Bays',
  racks: 'Racks',
  areas: 'Areas',
};

// Reducer action types for which tab and editor to show
export const ACTION_TYPES = {
  edit: 'edit',
  reset: 'reset',
};
