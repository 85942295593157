import moment from "moment";
import PropTypes from "prop-types";
import { FormGroup, Col, Row, ControlLabel } from "react-bootstrap";
import DatePicker from "react-datepicker";

export default function ControlTime({ startAt, onChange }) {
  return (
    <FormGroup controlId="formTime">
      <Row sm={12}>
        <Col sm={12}>
          <Col sm={12}>
            <ControlLabel>Time</ControlLabel>
          </Col>
          <Col sm={12}>
            <DatePicker
              dateFormat="h:mm aa"
              onChange={onChange}
              onChangeRaw={(e) => e.preventDefault()}
              placeholderText="Enter Time"
              selected={moment(startAt).toDate()}
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              timeIntervals={15}
            />
          </Col>
        </Col>
      </Row>
    </FormGroup>
  );
}

ControlTime.propTypes = {
  onChange: PropTypes.func,
  startAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};
