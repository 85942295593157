import PropTypes from "prop-types";

import { useSite } from "hooks/site";

import FilterChip from ".";

export default function SiteChip({ SiteId, hideRemove, onRemove }) {
  const { site, isLoading } = useSite(SiteId);
  return (
    <FilterChip
      hideRemove={hideRemove}
      isLoading={isLoading}
      onRemove={onRemove}
      text={site?.name}
      type="sites"
    />
  );
}

SiteChip.propTypes = {
  SiteId: PropTypes.string,
  hideRemove: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
};
