import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { LightStyledFormControl } from "views/components/BuildModeSidebar/SharedControls/Generic";

export default function UprightSleeve({
  setSleeve,
  sleeve,
  sleeveHeight,
  setSleeveHeight,
  sleeveSpacer,
  setSleeveSpacer,
}) {
  return (
    <>
      <Col sm={6}>Upright Sleeve</Col>
      <Col sm={6}>
        <LightStyledFormControl
          componentClass="select"
          onChange={(e) => setSleeve(e.target.value)}
          value={sleeve ? 1 : 0}
        >
          <option value={0}>No</option>
          <option value={1}>Yes</option>
        </LightStyledFormControl>
      </Col>
      {sleeve && (
        <>
          <Col sm={6}>Height (mm)</Col>
          <Col sm={6}>
            <LightStyledFormControl
              min="0"
              onChange={(e) => setSleeveHeight(parseInt(e.target.value, 10))}
              type="number"
              value={sleeveHeight ?? 0}
            />
          </Col>
          <Col sm={6}>Spacer (mm)</Col>
          <Col sm={6}>
            <LightStyledFormControl
              min="0"
              onChange={(e) => setSleeveSpacer(parseInt(e.target.value, 10))}
              type="number"
              value={sleeveSpacer}
            />
          </Col>
        </>
      )}
    </>
  );
}

UprightSleeve.propTypes = {
  setSleeve: PropTypes.func.isRequired,
  setSleeveHeight: PropTypes.func.isRequired,
  setSleeveSpacer: PropTypes.func.isRequired,
  sleeve: PropTypes.bool,
  sleeveHeight: PropTypes.number,
  sleeveSpacer: PropTypes.number,
};
