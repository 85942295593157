import {
  AMBER_COLOUR,
  DEFAULT_COLOUR,
  GREEN_COLOUR,
  NONE_COLOUR,
  RED_COLOUR,
} from "libs/constants";

export function getColor(resolvedAt, risk) {
  if (!resolvedAt) {
    return NONE_COLOUR;
  }
  switch (risk) {
    case "Green":
      return GREEN_COLOUR;
    case "Amber":
      return AMBER_COLOUR;
    case "Red":
      return RED_COLOUR;
    default:
      return DEFAULT_COLOUR;
  }
}
