import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { ButtonGroup } from "react-bootstrap";

import { useInspection, useInspectionProgress } from "hooks/inspections";
import FlatModal from "views/components/Modals/FlatModal";
import WarehouseProgress from "views/components/WarehouseProgress";
import { ButtonWrapper, ContinueActionButton } from "views/pages/Action/styles";

const PhotoEvidenceNote = styled.div`
  padding: 10px 20px;
  background-color: #7d8596;
  color: #ffffff;
`;

/**
 * Action Overview modal is used to display the details of an action before
 * it is completable.
 */
export default function ActionOverviewModal({ onHide, InspectionId }) {
  const { inspection, isLoading } = useInspection(InspectionId);
  const { progress: inspectionProgress, isLoading: isLoadingProgress } =
    useInspectionProgress(InspectionId);
  if (isLoading || isLoadingProgress || !inspection || !inspectionProgress)
    return null;

  const warehouseProgress = inspectionProgress.warehouses.map((w) => ({
    id: w.id,
    name: w.name,
    completePercentage: w.completePercentage,
    postProgress: `${
      (status === "Unfinished" ? 0 : w.actionsCantResolve) + w.actionsResolved
    }/${w.actionsTotal}`,
    areas: w.areas.map((a) => ({
      id: a.id,
      name: a.name,
      completePercentage: a.completePercentage,
      postProgress: `${
        (status === "Unfinished" ? 0 : a.actionsCantResolve) + a.actionsResolved
      }/${a.actionsTotal}`,
    })),
  }));

  return (
    <FlatModal
      closeButton
      onHide={onHide}
      show
      title="Ongoing action"
      width={500}
    >
      {inspection.photoEvidenceRequired ? (
        <PhotoEvidenceNote>Photo evidence required</PhotoEvidenceNote>
      ) : null}
      <WarehouseProgress warehouses={warehouseProgress} />
      <ButtonWrapper>
        <ButtonGroup>
          <ContinueActionButton onClick={onHide}>
            Continue Action
          </ContinueActionButton>
        </ButtonGroup>
      </ButtonWrapper>
    </FlatModal>
  );
}
ActionOverviewModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  InspectionId: PropTypes.string,
};
