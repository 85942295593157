import { useMemo, useCallback, useState, useEffect } from "react";
import useMeasure from "react-use-measure";

import { calculateWarehouseDimensions } from "libs/blueprint";
import { SCALE_FACTOR } from "hooks/use-site-blueprint";

/**
 * The blueprint focuser keeps track of the container size, the viewport,
 * and provides functions that allows the focusing of the blueprint to
 * a certain set of coordinates and zoom levels.
 */
export default function useBlueprintFocuser(viewportRef, warehouse, isLoading) {
  const [hasZoomed, setHasZoomed] = useState(false);
  const [
    stageContainerRef,
    { width: containerWidth, height: containerHeight },
  ] = useMeasure(); // Pixi Stage for resizing

  const viewport = viewportRef.current;

  const handleFocus = useCallback(
    (x, y, width, height) => {
      if (
        !viewport ||
        !containerHeight ||
        !containerWidth ||
        !x ||
        !y ||
        !width ||
        !height
      ) {
        return;
      }

      // removeOnComplete unlocks the view from snapping once the animation is complete
      // snapZoom controls zoom, snap controls position
      // https://davidfig.github.io/pixi-viewport/jsdoc/Viewport.html#snap
      const zoomObject = {
        removeOnComplete: true,
        forceStart: true,
      };

      /**
       * Compare the aspect ratios of the container and focus rectange.
       * If the focus rectangle ar is greater than the container ar, then
       * focus on the width, otherwise focus on the height.
       *
       * This ensures that the focus rectangle is always visible.
       */
      const viewportAspectRatio = containerWidth / containerHeight;
      const focusRectangleAspectRatio = width / height;

      if (focusRectangleAspectRatio > viewportAspectRatio) {
        zoomObject.width = width;
      } else {
        zoomObject.height = height;
      }

      // Zoom and focus on the rectangle
      viewport.snapZoom(zoomObject);
      viewport.snap(x, y, {
        removeOnComplete: true,
        forceStart: true,
      });
    },
    [containerWidth, containerHeight, viewport],
  );

  const dimensions = useMemo(
    () => calculateWarehouseDimensions(warehouse),
    [warehouse],
  );

  /**
   * Using the calculated warehosue dimensions, call our focus function
   */
  const focusOnWarehouse = useCallback(() => {
    const x = (dimensions.maxX - dimensions.minX) / 2 + dimensions.minX;
    const y = (dimensions.maxY - dimensions.minY) / 2 + dimensions.minY;
    const width = dimensions.maxX - dimensions.minX + 2000 * SCALE_FACTOR;
    const height = dimensions.maxY - dimensions.minY + 2000 * SCALE_FACTOR;
    handleFocus(x, y, width, height);
  }, [handleFocus, dimensions]);

  /**
   * When the warehouse is loaded, focus on the warehouse. We only want to do
   * this once, so if the warehouse changes, we aren't re-focusing on it.
   */
  useEffect(() => {
    if (
      !isLoading &&
      warehouse &&
      !hasZoomed &&
      containerWidth > 0 &&
      containerHeight > 0
    ) {
      setHasZoomed(true);
      focusOnWarehouse();
    }
  }, [
    focusOnWarehouse,
    warehouse,
    isLoading,
    hasZoomed,
    containerWidth,
    containerHeight,
  ]);

  /**
   * Allow for the resetting of the zoomed flag, to enable refocusing e.g. between
   * warehouse tabs where the component is not remounted.
   */
  const setAllowFocus = useCallback(() => setHasZoomed(false), []);

  return {
    handleFocus,
    focusOnWarehouse,
    stageContainerRef,
    width: containerWidth,
    height: containerHeight,
    setAllowFocus,
  };
}
