import styled from "@emotion/styled";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUser } from "hooks/user";

const HelpSupportLink = styled.a`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  padding: 10px 0;

  &:hover,
  &:active,
  &:focus {
    color: #ffcf33;
    text-decoration: none;
  }

  svg {
    margin-left: 0 !important;
    margin-right: 20px;
  }
`;

const LogoutButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  margin-bottom: 20px;
  padding: 10px 0;

  &:hover,
  &:active,
  &:focus {
    color: #ffcf33;
  }

  svg {
    margin-left: 0 !important;
    margin-right: 20px;
  }
`;

const Wrapper = styled.div`
  padding: 0 15px;
`;

export default function HelpLogoutWidget() {
  const navigate = useNavigate();
  const { user, onLogout } = useCurrentUser();

  useEffect(() => {
    if (user && user.exists === false) {
      onLogout();
    }
    if (user && user.active === false) {
      navigate("/inactive");
    }
  }, [navigate, user, onLogout]);

  if (!user || !user.active) {
    return null;
  }

  // Note: wrapping div is needed for the parent flexbox layout
  return (
    <Wrapper>
      <HelpSupportLink href="https://econform.freshdesk.com">
        <FontAwesomeIcon
          className="icon"
          fixedWidth
          icon={faQuestionCircle}
          pull="left"
          size="2xl"
        />
        Help & Support
      </HelpSupportLink>
      <LogoutButton onClick={onLogout} type="button">
        <FontAwesomeIcon
          className="icon"
          fixedWidth
          icon={faArrowRightFromBracket}
          pull="left"
          size="2xl"
        />
        Log Out
      </LogoutButton>
    </Wrapper>
  );
}
