/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Checkbox } from "react-bootstrap";

function ClientSelectorOption({
  client,
  disabled,
  clientPermissionGrantedIds = [],
  onChange,
}) {
  return (
    <div>
      <Checkbox
        checked={clientPermissionGrantedIds.includes(client.id) || disabled}
        disabled={disabled}
        onChange={(e) => onChange(e.target.checked)}
      >
        {client.name}
      </Checkbox>
    </div>
  );
}

ClientSelectorOption.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  clientPermissionGrantedIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

function OrganisationPermissionSelector({
  clients,
  onAddPermissions,
  onDeletePermissions,
  clientPermissionGrantedIds = [],
  userId,
  allSelectedByDefault,
}) {
  if (!clients) {
    return null;
  }
  const allClientsSelected = !clients
    .map((c) => clientPermissionGrantedIds.includes(c.id))
    .includes(false);

  return (
    <div
      css={css`
        max-height: 200px;
        background-color: #eee;
        border: 1px solid #ddd;
        overflow-y: scroll;
        padding: 2px;
      `}
    >
      <Checkbox
        checked={allSelectedByDefault || allClientsSelected}
        disabled={allSelectedByDefault}
        onChange={(e) =>
          e.target.checked
            ? onAddPermissions(
                userId,
                "clientOrganisation",
                clients.map((client) => client.id),
              )
            : onDeletePermissions(
                userId,
                "clientOrganisation",
                clients.map((client) => client.id),
              )
        }
      >
        {`${clients.length ? "All" : "No"} Clients`}
      </Checkbox>
      {clients.map((client) => (
        <ClientSelectorOption
          client={client}
          clientPermissionGrantedIds={clientPermissionGrantedIds}
          disabled={allSelectedByDefault}
          key={client.id}
          onChange={(added) =>
            added
              ? onAddPermissions(userId, "clientOrganisation", [client.id])
              : onDeletePermissions(userId, "clientOrganisation", [client.id])
          }
        />
      ))}
    </div>
  );
}

OrganisationPermissionSelector.propTypes = {
  allSelectedByDefault: PropTypes.bool,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  clientPermissionGrantedIds: PropTypes.arrayOf(PropTypes.string),
  userId: PropTypes.string,
  onAddPermissions: PropTypes.func,
  onDeletePermissions: PropTypes.func,
};

export default OrganisationPermissionSelector;
