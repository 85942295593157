import styled from "@emotion/styled";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useCallback, useRef, useState } from "react";
import { Alert, Button, FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";

import { useInspection } from "hooks/inspections";
import { useCurrentUser } from "hooks/user";
import { completeInspection } from "libs/api";

import FlatModal, {
  ModalControls,
  ModalPaddedBody,
} from "views/components/Modals/FlatModal";

const StyledFormControl = styled(FormControl)`
  background-color: #f2f2f4;
  color: #747474;
  border: 0px;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
`;

const SignatureWrapper = styled.div`
  background-color: #f2f2f4;
  border-radius: 5px;
  height: 100px;
  width: 100%;

  .sigCanvas {
    width: 100%;
    border-radius: 5px;
    height: 100px;
  }
`;

const SignOffControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SmallButton = styled(Button)`
  padding: 0 10px;
  margin: 0;
`;

/**
 * The signoff modal collects a digital signature, then calls the API to complete the inspection.
 *
 * On completion the onComplete callback function is called, which is used to trigger
 * the action assigner being displayed for a complete inspection.
 */
export default function SignoffModal({ onComplete, InspectionId, onHide }) {
  const { inspection, mutate: refresh } = useInspection(InspectionId);
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const [signatureData, setSignatureData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const sigPadRef = useRef(null);

  const handleCompleteInspection = useCallback(async () => {
    setIsError(false);
    setIsSaving(true);
    const res = await completeInspection(
      InspectionId,
      user.id,
      null,
      signatureData,
      inspection.updatedAt,
    );

    // TODO: Move API call handling into the lib/api
    if (res.ok) {
      const json = await res.json();
      if (json.hasActions) {
        await onComplete();
        await refresh();
        setIsSaving(false);
        return;
      }
      await refresh();
      navigate("/inspections");
    } else {
      setIsError(true);
    }
    setIsSaving(false);
  }, [
    InspectionId,
    user.id,
    signatureData,
    refresh,
    navigate,
    onComplete,
    inspection.updatedAt,
  ]);
  return (
    <FlatModal
      closeButton
      onHide={onHide}
      show
      title="Sign-off Inspection"
      width={600}
    >
      <ModalPaddedBody>
        <h5>Inspection</h5>
        <StyledFormControl
          value={`[${inspection.type}] Inspection ${inspection.reference}`}
        />
        <SignOffControlsWrapper>
          <h6>Digital Signature</h6>
          {signatureData ? (
            <SmallButton
              onClick={() => {
                sigPadRef.current.clear();
                setSignatureData(null);
              }}
            >
              Clear
            </SmallButton>
          ) : null}
        </SignOffControlsWrapper>
        <SignatureWrapper>
          <ReactSignatureCanvas
            canvasProps={{ className: "sigCanvas" }}
            onEnd={() =>
              setSignatureData(
                sigPadRef.current.getTrimmedCanvas().toDataURL("image/png"),
              )
            }
            penColor="black"
            ref={sigPadRef}
          />
        </SignatureWrapper>
      </ModalPaddedBody>
      <ModalControls>
        <Button onClick={onHide}>Cancel</Button>
        <Button
          disabled={
            !signatureData ||
            (!inspection.isCompletable && !inspection.isPartialCompletable)
          }
          onClick={handleCompleteInspection}
        >
          Complete{" "}
          {inspection?.isPartialCompletable && !inspection?.isCompletable
            ? "Partial "
            : ""}
          Inspection
          {isSaving ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
        </Button>
      </ModalControls>
      {isError ? (
        <Alert bsStyle="danger">
          Unable to complete inspection. This inspection has been updated prior
          to sign off. Please view inspection for updated changes.
        </Alert>
      ) : null}
    </FlatModal>
  );
}

SignoffModal.propTypes = {
  InspectionId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
