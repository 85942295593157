/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState, useMemo } from "react";

import { useInspection } from "hooks/inspections";

import Alert from "views/components/Alerts";
import Loading from "views/components/Loading";
import { offloadLocations } from "libs/api/classify";
import {
  getBayLocations,
  getRackLocations,
  getSecondaryLocations,
  groupByPalletId,
} from "./helpers";
import ConfirmModal from "../ConfirmModal";
import { CancelButton, StyledButton } from "./styles";
import {
  AddSecondaryLocations,
  PrimaryLocations,
  SecondaryLocations,
} from "./offload-fields";

/**
 * This is a form that adds damage to a given inspection.
 * It takes fixed damage parameters that correspond to the keys in the form
 * reducer. These values should not be over-ridden by the form. These are used
 * when selecting a particular frame or beam from the blueprint.
 */
export default function OffloadEditor({
  InspectionId,
  ClassificationId,
  warehouseId,
  RackId,
  onHide,
  frameLocationId,
  beamLevel,
}) {
  const { inspection, isLoading } = useInspection(InspectionId);
  const [selectedValues, setSelectedValues] = useState([]);
  // Memoize the filtered frame offload locations
  const frameOffloadLocations = useMemo(() => {
    const warehouse = inspection.warehouses.find((w) => w.id === warehouseId);
    const rack = warehouse.racks.find((r) => r.id === RackId);
    if (beamLevel && beamLevel !== "N/A") {
      const allOffloadLocations = rack.offloadLocations;
      return allOffloadLocations.beams.filter((f) => f.id === beamLevel);
    } else {
      const allOffloadLocations = rack.offloadLocations;
      return allOffloadLocations.frames.filter(
        (f) => f.FrameLId === frameLocationId || f.FrameRId === frameLocationId,
      );
    }
  }, [frameLocationId]);

  const groupedByPallet = useMemo(
    () => groupByPalletId(frameOffloadLocations),
    [frameOffloadLocations],
  );

  const warehouse = inspection?.warehouses.find((w) => w.id === warehouseId);
  const racks = warehouse?.racks;

  // State for saving the data
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(null);
  const [offloaded, setOffloaded] = useState(frameOffloadLocations);
  const [secondaryLocations, setSecondaryLocations] = useState([]);
  const [rackId, setRackId] = useState(null);
  const [bayId, setBayId] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(null);
  // Loading state
  if (isLoading) {
    return <Loading />;
  }
  const handleSave = async () => {
    try {
      setIsSaving(true);
      // We filter out locations that have been offloaded
      const parsedOffloadedLocations = offloaded
        .filter((location) => !location.offloadedAt)
        .map((location) => {
          return {
            ClassificationId,
            PalletId: location.PalletId,
            LocationId: location.id,
          };
        });
      const parsedSecondaryLocations = secondaryLocations.map((location) => {
        return {
          ClassificationId,
          PalletId: location.PalletId,
          LocationId: location.id,
        };
      });
      const combinedLocations = [
        ...parsedOffloadedLocations,
        ...parsedSecondaryLocations,
      ];
      await offloadLocations(combinedLocations, InspectionId);
      setIsSaving(false);
      onHide();
    } catch (e) {
      setIsSaving(false);
      setIsError(e.message);
    }
  };

  const handleSecondaryLocationChange = (location) => {
    setSecondaryLocations((prevSelectedValues) => {
      if (prevSelectedValues.includes(location)) {
        return prevSelectedValues.filter((v) => v !== location);
      }
      return [...prevSelectedValues, location];
    });

    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(location)) {
        return prevSelectedValues.filter((v) => v !== location);
      }
      return [...prevSelectedValues, location];
    });
  };

  const handleRackChange = (id) => {
    setRackId(id);
    // Resets the bay dropdown
    setBayId(null);
  };

  const addOffload = (location) => {
    setOffloaded((prevOffloaded) => [...prevOffloaded, location]);
  };

  const removeOffload = (location) => {
    setOffloaded((prevOffloaded) =>
      prevOffloaded.filter(
        (offloadedLocation) => offloadedLocation !== location,
      ),
    );
  };

  const removeSecondaryOffload = (location) => {
    setSecondaryLocations((prevOffloaded) =>
      prevOffloaded.filter(
        (offloadedLocation) => offloadedLocation !== location,
      ),
    );
    setSelectedValues((prevOffloaded) =>
      prevOffloaded.filter(
        (offloadedLocation) => offloadedLocation !== location,
      ),
    );
  };

  const isOffloaded = (location) => {
    return offloaded.some(
      (offloadedLocation) => offloadedLocation === location,
    );
  };

  const buttonContainerStyle = css`
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 8px; /* Space between buttons */
    padding: 16px; /* Equivalent to Tailwind's p-4 */
  `;

  return (
    <>
      <ConfirmModal
        onConfirm={() => {
          setShowCancelModal(false);
          onHide();
        }}
        onHide={() => setShowCancelModal(false)}
        show={showCancelModal}
        title="No Offloaded Locations"
        confirmButtonText="Yes"
        cancelButtonText="Go back"
      >
        <h4>Are you sure you want to cancel offloading locations?</h4>
        <h4>
          This can not be undone, and there will be no offloaded locations
          associated with this damage.
        </h4>
      </ConfirmModal>
      <PrimaryLocations
        addOffload={addOffload}
        groupedByPallet={groupedByPallet}
        isOffloaded={isOffloaded}
        removeOffload={removeOffload}
      />

      <SecondaryLocations
        removeSecondaryOffload={removeSecondaryOffload}
        secondaryLocations={secondaryLocations}
      />

      <AddSecondaryLocations
        addOffload={handleSecondaryLocationChange}
        groupedByPallet={groupedByPallet}
        isOffloaded={isOffloaded}
        removeOffload={removeSecondaryOffload}
        racks={racks}
        rackId={rackId}
        setBayId={setBayId}
        bayId={bayId}
        frameOffloadLocations={frameOffloadLocations}
        selectedValues={selectedValues}
        handleSecondaryLocationChange={handleSecondaryLocationChange}
        getSecondaryLocations={getSecondaryLocations}
        handleRackChange={handleRackChange}
        getRackLocations={getRackLocations}
        getBayLocations={getBayLocations}
      />

      <div css={buttonContainerStyle}>
        <CancelButton onClick={() => setShowCancelModal(true)}>
          Cancel
        </CancelButton>
        <StyledButton disabled={isSaving} onClick={handleSave}>
          Save
          {isSaving ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
        </StyledButton>
      </div>
      {isError ? (
        <Alert variant="danger">{isError}. Please try again.</Alert>
      ) : null}
    </>
  );
}

OffloadEditor.propTypes = {
  warehouseId: PropTypes.string.isRequired,
  InspectionId: PropTypes.string.isRequired,
  ClassificationId: PropTypes.string.isRequired,
  RackId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  frameLocationId: PropTypes.string,
  beamLevel: PropTypes.string,
};
