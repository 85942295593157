import { useCallback, useReducer } from "react";

import { userPasswordChange } from "libs/api";
import { isPasswordValid } from "libs/passwords";

/** The status of the form and process */
export const statuses = {
  START: "start",
  SAVING: "saving",
  ERROR: "error",
  SUCCESS: "success",
};

/** Actions for the state reducer */
const actions = {
  RESET: "reset",
  RESET_STATUS: "reset_status",
  SET_CURRENT_PASSWORD: "set_current_password",
  SET_NEW_PASSWORD: "set_new_password",
  SET_CONFIRM_PASSWORD: "set_confirm_password",
  SET_SAVING: "set_saving",
  SET_ERROR: "set_error",
  SET_SUCCESS: "set_success",
};

/** Default state for the reducer */
export const defaultState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
  status: statuses.START,
};

/** Reducer to handle form state and submission status */
export function reducer(state = defaultState, action) {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case actions.RESET:
      return defaultState;
    case actions.RESET_STATUS:
      return {
        ...state,
        status: statuses.START,
      };
    case actions.SET_CURRENT_PASSWORD:
      return {
        ...state,
        currentPassword: action.payload,
      };
    case actions.SET_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.payload,
      };
    case actions.SET_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: action.payload,
      };
    case actions.SET_SAVING:
      return {
        ...state,
        status: statuses.SAVING,
        errorMessage: null,
      };
    case actions.SET_ERROR:
      return {
        ...state,
        status: statuses.ERROR,
        errorMessage: action.payload,
      };
    case actions.SET_SUCCESS:
      return {
        ...defaultState,
        status: statuses.SUCCESS,
      };
    default:
      return state;
  }
}

/**
 * Hook to handle the state and submission of the change password form
 */
export default function usePasswordChanger() {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const { currentPassword, newPassword, confirmPassword } = state;

  const setError = useCallback(
    (error) => dispatch({ type: actions.SET_ERROR, payload: error }),
    [],
  );
  const setSaving = useCallback(
    () => dispatch({ type: actions.SET_SAVING }),
    [],
  );
  const setSuccess = useCallback(
    () => dispatch({ type: actions.SET_SUCCESS }),
    [],
  );

  const setCurrentPassword = useCallback(
    (value) =>
      dispatch({
        type: actions.SET_CURRENT_PASSWORD,
        payload: value,
      }),
    [],
  );
  const setNewPassword = useCallback(
    (value) => dispatch({ type: actions.SET_NEW_PASSWORD, payload: value }),
    [],
  );
  const setConfirmPassword = useCallback(
    (value) =>
      dispatch({
        type: actions.SET_CONFIRM_PASSWORD,
        payload: value,
      }),
    [],
  );

  const handleChangePassword = useCallback(async () => {
    if (!isPasswordValid(newPassword)) {
      setError("Password is not valid.");
    } else if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
    } else {
      setSaving();
      try {
        await userPasswordChange(currentPassword, newPassword);
        setSuccess();
      } catch (e) {
        setError(e.message);
      }
    }
  }, [
    confirmPassword,
    currentPassword,
    newPassword,
    setError,
    setSaving,
    setSuccess,
  ]);

  const canChangePassword =
    state.status !== statuses.SAVING &&
    currentPassword.length > 0 &&
    newPassword === confirmPassword &&
    isPasswordValid(newPassword);

  return {
    canChangePassword,
    handleChangePassword,
    setConfirmPassword,
    setCurrentPassword,
    setNewPassword,
    state,
  };
}
