import moment from "moment";
import PropTypes from "prop-types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import { useClient, useClients } from "hooks/client";
import { useSite, useSites } from "hooks/site";

const FiltersContext = createContext();

// TODO: Reset search text on client, siteid change.
// TODO: Can't update clientId if siteId is fixed
/**
 * This reducer maintains a list of enabled filters, controlled filters, and
 * current values.
 *
 * It is a reducer rather than simple state so that it can be easily tested.
 */
// We export this module in order that we can test it
// eslint-disable-next-line import/no-unused-modules
export function filtersReducer(state, action) {
  switch (action.type) {
    case "set":
      return {
        enabledFilters: state.enabledFilters,
        controlled: state.controlled,
        values: [
          ...state.values.filter((item) => item.key !== action.payload.key),
          { key: action.payload.key, value: action.payload.value },
        ].filter((item) => item.value !== null),
      };
    case "reset":
      // TODO: Return the default values
      return {
        enabledFilters: action.payload.enabledFilters,
        controlled: action.payload.controlled,
        values: [],
      };
    default:
      return state;
  }
}

/**
 * These keys are exported for use in components for controlling which filters
 * are enabled, and which filters have controlled values
 */
const FILTER_KEYS = {
  actionStatus: "actionStatus",
  clientId: "ClientId",
  completedAtEndDate: "completedAtEndDate",
  completedAtStartDate: "completedAtStartDate",
  createdAtEndDate: "createdAtEndDate",
  createdAtStartDate: "createdAtStartDate",
  inspectionStatus: "inspectionStatus",
  inspectorId: "InspectorId",
  searchText: "searchText",
  siteId: "SiteId",
};

/**
 * This hook allows components, such as Filter Options to read from a shared
 * state of client, site IDs, and other filters. It augments the FilterProvider
 * with a set of helper functions to make it easier to use, e.g. pre-filled
 * variables such as `clientId` and `siteId`, and setters such as `setClientId`.
 *
 * This hook also maintains a list of clients, and sites, plus the object for
 * the selected client and site. This data will be cached by SWR.
 *
 * It also syncrhonises the ids, so if the list of sites is 1 long, then that
 * site is automatically selected.
 */
function useFilters() {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error("useFilters must be used within a FiltersProvider");
  }

  const { getFilter, setFilter, clearFilters, canUpdate } = context;

  /**
   * Possible filters, values and setters
   */
  const clientId = getFilter(FILTER_KEYS.clientId);
  const setClientId = useCallback(
    (id) => setFilter(FILTER_KEYS.clientId, id),
    [setFilter],
  );

  const siteId = getFilter(FILTER_KEYS.siteId);
  const setSiteId = useCallback(
    (id) => setFilter(FILTER_KEYS.siteId, id),
    [setFilter],
  );

  const inspectorId = getFilter(FILTER_KEYS.inspectorId);
  const setInspectorId = useCallback(
    (id) => setFilter(FILTER_KEYS.inspectorId, id),
    [setFilter],
  );

  const inspectionStatus = getFilter(FILTER_KEYS.inspectionStatus);
  const setInspectionStatus = useCallback(
    (s) => setFilter(FILTER_KEYS.inspectionStatus, s),
    [setFilter],
  );

  const actionStatus = getFilter(FILTER_KEYS.actionStatus);
  const setActionStatus = useCallback(
    (s) => setFilter(FILTER_KEYS.actionStatus, s),
    [setFilter],
  );

  let createdAtEndDate = getFilter(FILTER_KEYS.createdAtEndDate);
  createdAtEndDate = createdAtEndDate
    ? moment(createdAtEndDate).toISOString()
    : null;
  const setCreatedAtEndDate = useCallback(
    (d) => setFilter(FILTER_KEYS.createdAtEndDate, d),
    [setFilter],
  );

  let createdAtStartDate = getFilter(FILTER_KEYS.createdAtStartDate);
  createdAtStartDate = createdAtStartDate
    ? moment(createdAtStartDate).toISOString()
    : null;
  const setCreatedAtStartDate = useCallback(
    (d) => setFilter(FILTER_KEYS.createdAtStartDate, d),
    [setFilter],
  );

  let completedAtEndDate = getFilter(FILTER_KEYS.completedAtEndDate);
  completedAtEndDate = completedAtEndDate
    ? moment(completedAtEndDate).toISOString()
    : null;
  const setCompletedAtEndDate = useCallback(
    (d) => setFilter(FILTER_KEYS.completedAtEndDate, d),
    [setFilter],
  );

  let completedAtStartDate = getFilter(FILTER_KEYS.completedAtStartDate);
  completedAtStartDate = completedAtStartDate
    ? moment(completedAtStartDate).toISOString()
    : null;
  const setCompletedAtStartDate = useCallback(
    (d) => setFilter(FILTER_KEYS.completedAtStartDate, d),
    [setFilter],
  );

  const searchText = getFilter(FILTER_KEYS.searchText);
  const setSearchText = useCallback(
    (text) => setFilter(FILTER_KEYS.searchText, text),
    [setFilter],
  );

  /**
   * Synchronisation between filters
   *
   * Note: Components that load using hooks, e.g. useClient(id), will get the
   * cached version, so there shouldn't be a performance penalty here.
   */
  const { clients } = useClients({ amount: 1000 });
  useEffect(() => {
    if (clients.length === 1) {
      setClientId(clients[0].id);
    }
  }, [clients, setClientId]);

  const { client } = useClient(clientId);
  const { site } = useSite(siteId);

  useEffect(() => {
    if (site && site.ClientId !== clientId) {
      setClientId(site?.ClientId);
    }
  }, [clientId, setClientId, site]);

  // Load the sites for the selected client. Auto-select site if there is only one result
  const { sites } = useSites({ ClientId: clientId, amount: 1000 });
  useEffect(() => {
    if (sites.length === 1) {
      setSiteId(sites[0].id);
    }
  }, [setSiteId, sites]);

  return useMemo(
    () => ({
      clearFilters,
      getFilter,
      setFilter,
      canUpdate,

      actionStatus,
      clientId,
      completedAtEndDate,
      completedAtStartDate,
      createdAtEndDate,
      createdAtStartDate,
      inspectionStatus,
      inspectorId,
      searchText: searchText ?? "",
      siteId,

      setActionStatus,
      setClientId,
      setCompletedAtEndDate,
      setCompletedAtStartDate,
      setCreatedAtEndDate,
      setCreatedAtStartDate,
      setInspectionStatus,
      setInspectorId,
      setSearchText,
      setSiteId,

      clients,
      client,
      site,
      sites,
    }),
    [
      actionStatus,
      canUpdate,
      clearFilters,
      client,
      clientId,
      clients,
      completedAtEndDate,
      completedAtStartDate,
      createdAtEndDate,
      createdAtStartDate,
      getFilter,
      inspectionStatus,
      inspectorId,
      searchText,
      setActionStatus,
      setClientId,
      setCompletedAtEndDate,
      setCompletedAtStartDate,
      setCreatedAtEndDate,
      setCreatedAtStartDate,
      setFilter,
      setInspectionStatus,
      setInspectorId,
      setSearchText,
      setSiteId,
      site,
      siteId,
      sites,
    ],
  );
}

function FiltersProvider({
  enabledFilters = [],
  controlledValues = {},
  ...props
}) {
  const [{ values: filters, controlled }, dispatch] = useReducer(
    filtersReducer,
    {
      enabledFilters: enabledFilters ?? [],
      controlled: controlledValues ?? {},
      values: [],
    },
  );

  /*
  useEffect(() => {
    dispatch({ type: 'reset', payload: { enabledFilters, controlledValues } });
  }, [controlledValues, enabledFilters])
  */

  const setFilter = useCallback(
    (key, value) => {
      dispatch({ type: "set", payload: { key, value } });
    },
    [dispatch],
  );

  const clearFilters = useCallback(() => {
    dispatch({ type: "clear" });
  }, [dispatch]);

  const canUpdate = useCallback(
    (key) => enabledFilters.includes(key) && !controlled[key],
    [enabledFilters, controlled],
  );
  // Use an accessor function, so we can change the data structure if needed
  const getFilter = useCallback(
    (key) => controlled[key] ?? filters.find((f) => f.key === key)?.value,
    [filters, controlled],
  );

  const value = useMemo(
    () => ({
      clearFilters,
      getFilter,
      setFilter,
      canUpdate,
    }),
    [clearFilters, getFilter, setFilter, canUpdate],
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <FiltersContext.Provider value={value} {...props} />;
}

FiltersProvider.propTypes = {
  enabledFilters: PropTypes.arrayOf(PropTypes.string),
  controlledValues: PropTypes.objectOf(PropTypes.shape({})),
};

export { useFilters, FiltersProvider, FILTER_KEYS };
