/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DoughnutController,
  LineController,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
} from "chart.js";
import { ReactChart } from "chartjs-react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "react-circular-progressbar/dist/styles.css";
import "views/styles/Home.css";

import { useActions } from "hooks/actions";
import { useDashboard } from "hooks/dashboard";
import { FiltersProvider, FILTER_KEYS, useFilters } from "hooks/useFilter";
import { useCurrentUser } from "hooks/user";

import PageWrapper from "views/components/common/PageWrapper";
import DashboardIsland from "views/components/DashboardIsland";
import InspectionsTable from "views/components/MiniTables/InspectionsTable";
import { MySchedulesTable } from "views/components/MiniTables/SchedulesTable";
import TableHeader from "views/components/Tables/TableHeader";

import Controls from "./Controls";
import DamageClassificationCharts from "./DamageClassificationCharts";
import RackingDamageCharts from "./RackingDamageCharts";
import TimeSeries from "./TimeSeries";

ReactChart.register(
  DoughnutController,
  LineController,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
);

function DashboardPage() {
  const { user, isLoading } = useCurrentUser();
  const { clientId, siteId, client, site, inspectorId } = useFilters();

  const filters = {
    orderCreatedAtDateDescending: false,
    amount: 4,
    allActions: true,
    InspectorId: inspectorId,
  };

  /**
   * Data Loading Hooks
   */
  const { total: totalActions } = useActions({
    ...filters,
    ClientId: clientId,
    SiteId: siteId,
  });
  const { dashboardData } = useDashboard({
    ...filters,
    ClientId: clientId,
    SiteId: siteId,
  });
  if (isLoading) return null;
  return (
    <PageWrapper>
      <Helmet>
        <title>Econform / Dashboard</title>
      </Helmet>
      <Row>
        <Col sm={12}>
          <Controls />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <TableHeader left="Dashboard" />
        </Col>
      </Row>
      <Row>
        <Col
          css={css`
            margin-top: -5px;
            .col-sm-12,
            .col-sm-6 {
              padding-right: 0px;
              padding-left: 0px;
            }
            .pad-left {
              padding-left: 15px;
            }
            .pad-right {
              padding-right: 15px;
            }
            .full-height {
              height: 40vh;
            }
            .half-height {
              height: 20vh;
            }
            .CircularProgressbar,
            .circular-progress {
              height: 95%;
            }
            .CircularProgressbar.green,
            .CircularProgressbar.green > .CircularProgressbar-path,
            .CircularProgressbar.green > .CircularProgressbar-text {
              stroke: #008b00 !important;
              fill: #008b00 !important;
              background: #00000000;
            }
            .CircularProgressbar.amber,
            .CircularProgressbar.amber > .CircularProgressbar-path,
            .CircularProgressbar.amber > .CircularProgressbar-text {
              stroke: #ff7700 !important;
              fill: #ff7700 !important;
            }
            .CircularProgressbar.red,
            .CircularProgressbar.red > .CircularProgressbar-path,
            .CircularProgressbar.red > .CircularProgressbar-text {
              stroke: #e30021 !important;
              fill: #e30021 !important;
            }
          `}
          sm={12}
        >
          <Col
            className="pad-right full-height"
            css={css`
              margin-bottom: 10px;
            `}
            sm={6}
          >
            <DashboardIsland Header="Racking Damage">
              <RackingDamageCharts
                filters={{ ...filters, ClientId: clientId, SiteId: siteId }}
              />
            </DashboardIsland>
          </Col>
          <Col sm={6}>
            <Col className="half-height" sm={12}>
              <DashboardIsland
                Header="Damage Classifications"
                Link={
                  <Link
                    to={`${client && !site ? `/clients/${client.id}` : ""}${
                      site ? `/clients/sites/${site.id}` : ""
                    }/actions/1`}
                  >
                    {`View All ${
                      totalActions ? `${totalActions} ` : ""
                    }Actions `}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                }
              >
                <DamageClassificationCharts
                  filters={{ ...filters, ClientId: clientId, SiteId: siteId }}
                />
              </DashboardIsland>
            </Col>
            <Col
              className="half-height"
              css={css`
                padding-top: 15px;
              `}
              sm={12}
            >
              <Col
                css={css`
                  padding-right: 5px !important;
                  height: 100%;
                `}
                sm={6}
              >
                <DashboardIsland
                  Header="Total Inspections Conducted"
                  TotalValue={
                    dashboardData?.totalConductedInspections &&
                    Object.keys(dashboardData.totalConductedInspections).reduce(
                      (sum, key) =>
                        sum +
                        parseFloat(
                          dashboardData.totalConductedInspections[key] || 0,
                        ),
                      0,
                    )
                  }
                >
                  <TimeSeries
                    colour="#FFCF33"
                    data={dashboardData?.totalConductedInspections}
                  />
                </DashboardIsland>
              </Col>
              <Col
                css={css`
                  padding-left: 5px !important;
                  height: 100%;
                `}
                sm={6}
              >
                <DashboardIsland
                  Header="Total Missed Inspections"
                  TotalValue={
                    dashboardData?.totalMissedInspections
                      ? Object.keys(
                          dashboardData.totalMissedInspections,
                        ).reduce(
                          (sum, key) =>
                            sum +
                            parseFloat(
                              dashboardData.totalMissedInspections[key] || 0,
                            ),
                          0,
                        )
                      : 0
                  }
                >
                  <TimeSeries
                    colour="#36A2EB"
                    data={dashboardData?.totalMissedInspections}
                  />
                </DashboardIsland>
              </Col>
            </Col>
          </Col>
        </Col>
        <Col
          css={css`
            padding-top: 5px;
            .col-sm-12,
            .col-sm-6 {
              padding-right: 0px;
              padding-left: 0px;
            }
            .pad-left {
              padding-left: 15px;
            }
            .pad-right {
              padding-right: 15px;
            }
          `}
          sm={12}
        >
          <Col className="pad-right" sm={6}>
            <InspectionsTable
              ClientId={clientId}
              InspectorId={user?.id}
              SiteId={siteId}
              filters={{
                orderCreatedAtDateDescending: false,
                allActions: true,
              }}
            />
          </Col>
          <Col sm={6}>
            <MySchedulesTable
              ClientId={clientId}
              InspectorId={user?.id}
              SiteId={siteId}
              filters={{
                orderCreatedAtDateDescending: false,
                totalAllSchedules: true,
                allSchedules: false,
              }}
            />
          </Col>
        </Col>
      </Row>
    </PageWrapper>
  );
}

/**
 * On the dashboard you can alter client and site filters
 */
function DashboardPageWithFilters() {
  const { user } = useCurrentUser();

  // Inspector ID is controlled as the current user if they are a SARI
  const InspectorId = user?.userType === "SARI" ? user.id : null;

  const controlledFilters = {
    [FILTER_KEYS.inspectorId]: InspectorId,
  };

  const enabledFilters = [FILTER_KEYS.clientId, FILTER_KEYS.siteId];

  return (
    <FiltersProvider
      controlledFilters={controlledFilters}
      enabledFilters={enabledFilters}
      key="dashboard"
    >
      <DashboardPage />
    </FiltersProvider>
  );
}

export default DashboardPageWithFilters;
