export function filterFrameTemplates(frameTemplates = [], frameTemplatesFilterOptions = {}) {
  return frameTemplates.filter((frameTemplate) => {
    let allowed = true;
    if (frameTemplatesFilterOptions.searchText.length > 0) {
      allowed = frameTemplate.name.includes(frameTemplatesFilterOptions.searchText);
    }
    if (frameTemplatesFilterOptions.ManufacturerId) {
      if (!(frameTemplate.ManufacturerId === frameTemplatesFilterOptions.ManufacturerId)) {
        allowed = false;
      }
    }
    if (frameTemplatesFilterOptions.minWidth) {
      if (!(frameTemplate.width >= frameTemplatesFilterOptions.minWidth)) {
        allowed = false;
      }
    }
    if (frameTemplatesFilterOptions.maxWidth) {
      if (!(frameTemplate.width <= frameTemplatesFilterOptions.maxWidth)) {
        allowed = false;
      }
    }
    if (frameTemplatesFilterOptions.minHeight) {
      if (!(frameTemplate.totalHeight >= frameTemplatesFilterOptions.minHeight)) {
        allowed = false;
      }
    }
    if (frameTemplatesFilterOptions.maxHeight) {
      if (!(frameTemplate.totalHeight <= frameTemplatesFilterOptions.maxHeight)) {
        allowed = false;
      }
    }
    return allowed;
  });
}
