import { createContext, useMemo, useCallback, useContext, useState } from 'react';

const CheckboxSetContext = createContext([]);

function useCheckboxSet() {
  return useContext(CheckboxSetContext);
}

// eslint-disable-next-line react/prop-types
function CheckboxSetProvider({ children }) {
  const [checked, setChecked] = useState([]);
  const onCheck = useCallback((id) => setChecked((cur) => [...cur, id]), []);
  const onCheckGroup = useCallback((ids) => setChecked((cur) => [...cur, ...ids]), []);
  const onUncheck = useCallback(
    (id) => setChecked((cur) => cur.filter((download) => download !== id)),
    [],
  );
  const onUncheckGroup = useCallback(
    (ids) => setChecked((cur) => cur.filter((check) => !ids.includes(check))),
    [],
  );
  const isChecked = useCallback((id) => checked.includes(id), [checked]);
  const reset = useCallback((ids = []) => setChecked(ids), []);
  const value = useMemo(
    () => ({ onCheck, onUncheck, isChecked, checked, reset, onCheckGroup, onUncheckGroup }),
    [onCheck, onUncheck, isChecked, checked, reset, onCheckGroup, onUncheckGroup],
  );
  return <CheckboxSetContext.Provider value={value}>{children}</CheckboxSetContext.Provider>;
}

function withCheckboxSet(Component) {
  // eslint-disable-next-line react/function-component-definition, react/jsx-props-no-spreading, react/display-name
  return (props) => (<CheckboxSetProvider><Component {...props} /></CheckboxSetProvider>); // prettier-ignore
}

export { useCheckboxSet, withCheckboxSet };
