import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { BlueButton } from "./colors";

export default function SiteOverviewButton({ disabled, onClick }) {
  return (
    <BlueButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={faArrowAltCircleRight} size="2xl" />
      Site Overview
    </BlueButton>
  );
}

SiteOverviewButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
