import PropTypes from 'prop-types';

import { userRequestPassword } from 'libs/api';

export default function RequestCode({ setMode, email, loading, setLoading, error, setEmail }) {
  return (
    <>
      <div className="form-group">
        <h4>Request Password Reset Code</h4>
        <div className="form-description">
          You’ll receive a code to enter here so you can reset your account password.
        </div>
        <input
          className="form-control"
          key="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <div>
          <p className="error-message">{error}</p>
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 40 }}>
        <div
          className="flex items-center justify-between"
          style={{
            flex: 5,
            marginRight: 10,
          }}
        >
          <button
            className="btn btn-primary btn-block cancel"
            onClick={() => setMode('login')}
            type="button"
          >
            Back to Sign In
          </button>
        </div>
        <div
          className="flex items-center justify-between"
          style={{
            flex: 5,
            marginLeft: 10,
          }}
        >
          <button
            className="btn btn-primary btn-block"
            disabled={!email || !email.match(/^[^\s@]+@[^\s@]+$/) || loading === 'request-code'}
            onClick={async () => {
              setLoading('request-code');
              await userRequestPassword(email);
              setMode('passwordCode');
            }}
            type="button"
          >
            Request Code
          </button>
        </div>
      </div>
    </>
  );
}

RequestCode.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  loading: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
};
