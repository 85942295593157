/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Slider from "rc-slider";
import { Col, Row } from "react-bootstrap";

import "rc-slider/assets/index.css";
import {
  AMBER_COLOUR,
  DEFAULT_COLOUR,
  GREEN_COLOUR,
  NONE_COLOUR,
  RED_COLOUR,
  RISKS,
  RISKS_ARRAY as risks,
} from "libs/constants";
import getRiskValidation from "libs/getRiskValidation";
import isRiskInvalid from "libs/isRiskInvalid";

function getColor(risk) {
  switch (risk) {
    case RISKS.green:
      return GREEN_COLOUR;
    case RISKS.amber:
      return AMBER_COLOUR;
    case RISKS.red:
      return RED_COLOUR;
    case RISKS.none:
      return NONE_COLOUR;
    default:
      return DEFAULT_COLOUR;
  }
}

const Wrapper = styled.div`
  background-color: #f2f2f4;
  padding: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
  h5 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h6 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    color: #000000;
  }
  .rc-slider-track {
    background-color: ${({ color }) => color};
  }
  .rc-slider-handle {
    border: 0px;
    background-color: ${({ color }) => color};
    box-shadow: 0 0 0 0px ${({ color }) => color};
  }
  .rc-slider-dot-active {
    border-color: ${({ color }) => color};
  }
`;

/**
 * This slider is used in the Add Damage, Misc Damage and Reclassification modals.
 */
function RiskSlider({
  isReclassifying,
  onChange,
  originalRisk,
  risk,
  disabled,
}) {
  const color = getColor(risk);
  const isNoneInvalid = getRiskValidation(
    RISKS.none,
    isReclassifying,
    originalRisk,
  );
  const isGreenInvalid = getRiskValidation(
    RISKS.green,
    isReclassifying,
    originalRisk,
  );
  const isAmberInvalid = getRiskValidation(
    RISKS.amber,
    isReclassifying,
    originalRisk,
  );
  const isRedInvalid = getRiskValidation(
    RISKS.red,
    isReclassifying,
    originalRisk,
  );
  const riskValidationText = isRiskInvalid(originalRisk, risk, true);

  return (
    <Wrapper color={color}>
      <h5>Risk</h5>
      <div>
        <Slider
          disabled={disabled}
          dots
          max={risks.length - 1}
          min={0}
          onChange={onChange}
          value={risks.indexOf(risk)}
        />
      </div>
      <Row>
        <Col xs={3}>
          <h5
            css={css`
              float: left;
              color: ${isNoneInvalid ? "#ADAEB2" : "#747474"};
            `}
          >
            None
          </h5>
        </Col>
        <Col xs={3}>
          <h5
            css={css`
              margin-right: 35px;
              color: ${isGreenInvalid ? "#ADAEB2" : "#747474"};
            `}
          >
            Green
          </h5>
        </Col>
        <Col xs={3}>
          <h5
            css={css`
              margin-left: 35px;
              color: ${isAmberInvalid ? "#ADAEB2" : "#747474"};
            `}
          >
            Amber
          </h5>
        </Col>
        <Col xs={3}>
          <h5
            css={css`
              float: right;
              color: ${isRedInvalid ? "#ADAEB2" : "#747474"};
            `}
          >
            Red
          </h5>
        </Col>
      </Row>
      {riskValidationText && <h6>{riskValidationText}</h6>}
    </Wrapper>
  );
}

RiskSlider.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  risk: PropTypes.string,
  /** Is the slider being used in the context of add or reclassify */
  isReclassifying: PropTypes.bool,
  originalRisk: PropTypes.string,
};

export default RiskSlider;
