import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import FilterOptionsAndChips from "../../../components/FilterOptionsAndChips";

import {
  ControlsButton,
  ControlsButtonWrapper,
  ControlsWrapper,
  FiltersWrapper,
} from "../styles";

export default function Controls({ redirectUrl, showActionButton }) {
  const navigate = useNavigate();
  return (
    <ControlsWrapper>
      <FiltersWrapper>
        <FilterOptionsAndChips />
      </FiltersWrapper>
      <ControlsButtonWrapper>
        {showActionButton ? (
          <ControlsButton onClick={() => navigate(redirectUrl)}>
            New Warehouse
          </ControlsButton>
        ) : null}
      </ControlsButtonWrapper>
    </ControlsWrapper>
  );
}

Controls.propTypes = {
  redirectUrl: PropTypes.string,
  showActionButton: PropTypes.bool,
};
