import PropTypes from "prop-types";
import { FormGroup, Col } from "react-bootstrap";

import { useManufacturers } from "hooks/manufacturers";
import {
  ControlLabelWithLoading,
  StyledFormControl,
} from "views/components/BuildModeSidebar/SharedControls/Generic";

export default function Manufacturer({ disabled, ManufacturerId, onChange }) {
  const { manufacturers, isLoading } = useManufacturers();
  return (
    <FormGroup>
      <Col sm={6}>
        <ControlLabelWithLoading
          disabled={disabled}
          isLoading={isLoading}
          label="Manufacturer"
        />
      </Col>
      <Col sm={6}>
        <StyledFormControl
          componentClass="select"
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Frame Type"
          value={ManufacturerId ?? ""}
        >
          {manufacturers.length > 0 && (
            <option disabled value="">
              Please select
            </option>
          )}
          {manufacturers.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </StyledFormControl>
      </Col>
    </FormGroup>
  );
}

Manufacturer.propTypes = {
  disabled: PropTypes.bool,
  ManufacturerId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
