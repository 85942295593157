/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function getInspections(filters) {
  const res = await apiCall(`/inspection${filterUrlGenerator(filters)}`);
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  throw new Error(`Problem getting inspections: ${res.status}`);
}

export async function getInspection(InspectionId, filters) {
  const res = await apiCall(
    `/inspection/${InspectionId}${filterUrlGenerator(filters)}`,
  );
  if (res.status === 200) {
    return res.json();
  }
  throw new Error(`Problem getting inspection: ${res.status}`);
}

export async function getInspectionPDF(InspectionId) {
  return apiCall(`/inspection/report/${InspectionId}`);
}

export async function getInspectionReport(InspectionId) {
  const res = await apiCall(`/inspection/reportData/${InspectionId}`);
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting inspection report: ${res.status}`);
  return false;
}

export async function getPreviousClassifications(SiteId, filters) {
  const res = await apiCall(
    `/inspection/classifications/${SiteId}${filterUrlGenerator(filters)}`,
  );
  if (res.status === 200) {
    return res.json();
  }
  throw new Error(`Problem getting previous classifications: ${res.status}`);
}

export async function confirmClassification(ClassificationId, InspectionId) {
  const res = await apiCall("/inspection/confirm", {
    method: "POST",
    body: JSON.stringify({
      ClassificationId,
      InspectionId,
    }),
  });
  if (res.ok) {
    return true;
  }

  throw new Error(`Problem confirming classification: ${res.status}`);
}

export async function unconfirmClassification({
  InspectionId = null,
  ClassificationId = null,
}) {
  const res = await apiCall("/inspection/confirm", {
    method: "DELETE",
    body: JSON.stringify({
      InspectionId,
      ClassificationId,
    }),
  });
  if (res.ok) {
    return true;
  }
  throw new Error(`Problem unconfirming classification: ${res.status}`);
}

export function completeInspection(
  InspectionId,
  AssigneeId,
  signature,
  signatureImage,
  updatedAt,
) {
  return apiCall("/inspection/complete", {
    method: "PUT",
    body: JSON.stringify({
      signature,
      InspectionId,
      AssigneeId,
      signatureImage,
      updatedAt,
    }),
  });
}

export function completeAction(
  InspectionId,
  signature,
  signatureImage,
  updatedAt,
) {
  return apiCall("/inspection/signoff", {
    method: "PUT",
    body: JSON.stringify({
      signature,
      InspectionId,
      signatureImage,
      updatedAt,
    }),
  });
}

export async function createInspection(
  SiteId,
  ScheduleId,
  isolated = false,
  ScheduledInspectionId = null,
) {
  const res = await apiCall("/inspection", {
    method: "POST",
    body: JSON.stringify({
      SiteId,
      ScheduleId,
      ScheduledInspectionId,
      isolated,
    }),
  });
  if (res.ok) {
    const inspection = await res.json();
    return inspection;
  }
  return false;
}

export async function getDisplayClassifications(filters) {
  const res = await apiCall(
    `/inspection/display${filterUrlGenerator(filters)}`,
  );

  if (res.ok) {
    return res.json();
  }

  throw new Error(`Problem getting display classifications: ${res.status}`);
}

export async function deleteInspection(InspectionId) {
  const res = await apiCall(`/inspection/${InspectionId}`, {
    method: "DELETE",
  });

  if (res.ok) {
    return;
  }

  throw new Error(`Problem deleting inspection: ${res.status}`);
}

export async function toggleInspectionArchive(InspectionId, action = false) {
  const res = await apiCall("/inspection/archive", {
    method: "PUT",
    body: JSON.stringify({ InspectionId, action }),
  });
  return res;
}

export async function getShareURL(InspectionId, options = false) {
  const res = await apiCall(
    `/inspection/shareURL/${InspectionId}${filterUrlGenerator(options)}`,
  );
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting share url: ${res.status}`);
  return false;
}

export async function getMaterials(InspectionId) {
  const res = await apiCall(`/inspection/materials/${InspectionId}`);
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting materials: ${res.status}`);
  return res;
}

export async function toggleBookmarkInspection(InspectionId, action = true) {
  const res = await apiCall("/inspection/bookmark", {
    method: "PUT",
    body: JSON.stringify({ InspectionId, action }),
  });
  return res;
}

export async function completeRack(RackId, InspectionId) {
  const res = await apiCall("/inspection/complete/rack", {
    method: "POST",
    body: JSON.stringify({ RackId, InspectionId }),
  });
  if (res.ok) {
    return true;
  }
  throw new Error(`Problem completing rack: ${res.status}`);
}

export async function uncompleteRack(RackId, InspectionId) {
  const res = await apiCall("/inspection/complete/rack", {
    method: "DELETE",
    body: JSON.stringify({ RackId, InspectionId }),
  });
  if (res.ok) {
    return true;
  }
  throw new Error(`Problem uncompleting rack: ${res.status}`);
}
