import PropTypes from "prop-types";
import { useState } from "react";

import { useCheckboxSet } from "hooks/useCheckboxer";
import formatDueDates from "libs/formatDueDates";

import ActionsActionButtons from "views/components/ActionButtons/set-actions";
import ActionOverdue from "views/components/ActionOverdue";
import ActionStatus from "views/components/ActionStatus";
import DamageCircle from "views/components/DamageCircle";
import ActionAssigner from "views/components/Modals/ActionAssigner";
import FlatModal from "views/components/Modals/FlatModal";
import { RowCheckbox } from "views/components/Tables/Checkboxes";
import UserList from "views/components/UserList";
import RiskUpdatedIndicator from "views/components/RiskUpdatedIndicator";

export default function ActionRow({
  action,
  showClient,
  getData,
  onRequestPDF,
  onRequestShare,
  onSelectAction,
  showSite,
}) {
  const { isChecked, onCheck, onUncheck } = useCheckboxSet();

  const selected = isChecked(action.id);
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <tr>
        <td>
          <RowCheckbox
            checked={selected}
            disabled={action.status !== "Completed"}
            onChange={() =>
              selected ? onUncheck(action.id) : onCheck(action.id)
            }
          />
        </td>
        {showClient && <td>{action.ClientName}</td>}
        {showSite && <td>{action.SiteName}</td>}
        <td>
          {action.reference} ({action.type})
        </td>
        <td>
          <UserList users={action.reporters} />
        </td>
        <td>
          <UserList users={action.assignees} />
        </td>
        <td>
          <UserList users={action.resolvers} />
        </td>
        <td className="status-text">
          <ActionStatus action={action} />
        </td>
        {action.isRiskUpdated ? (
          <td>
            <RiskUpdatedIndicator />
          </td>
        ) : (
          <td>
            {action.risks.includes("Amber") && (
              <DamageCircle RiskName="Amber" />
            )}
            {action.risks.includes("Red") && <DamageCircle RiskName="Red" />}
          </td>
        )}

        <td style={{ paddingRight: 20 }}>
          {formatDueDates(action.fromDueAt, action.toDueAt)}
        </td>
        <td>
          <ActionOverdue action={action} />
        </td>
        <ActionsActionButtons
          action={action}
          getData={getData}
          onRequestPDF={onRequestPDF}
          onRequestShare={onRequestShare}
          onSelectAction={() => {
            onSelectAction(action);
            setShowModal(true);
          }}
        />
      </tr>
      {showModal && (
        <FlatModal
          closeButton
          onHide={() => setShowModal(false)}
          show
          title="Assign Action"
          width={500}
        >
          <ActionAssigner
            clientName={action.ClientName}
            getData={getData}
            inspection={action}
            onHide={() => {
              setShowModal(false);
            }}
            shouldNavigateOnSave
          />
        </FlatModal>
      )}
    </>
  );
}

ActionRow.defaultProps = {};

ActionRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  action: PropTypes.object,
  showClient: PropTypes.bool,
  showSite: PropTypes.bool,
  onRequestShare: PropTypes.func,
  onRequestPDF: PropTypes.func,
  onSelectAction: PropTypes.func,
  getData: PropTypes.func,
};
