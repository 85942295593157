import useSWR from "swr";

import { getWarehouse, getWarehouses } from "libs/api";

export function useWarehouse(id, filters) {
  const { data, error, isLoading, mutate } = useSWR(
    id ? `/warehouse/${id}` : null,
    async () => getWarehouse(id, filters),
  );

  return {
    warehouse: data,
    isLoading,
    isError: error,
    mutate,
  };
}

export function useWarehouses(filters) {
  const { data, error, isLoading, mutate } = useSWR(
    [`/warehouse`, filters],
    async () => getWarehouses(filters),
  );

  return {
    warehouses: data?.results ?? [],
    total: data?.total ?? 0,
    isLoading,
    isError: error,
    mutate,
  };
}
