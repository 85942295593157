import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import { SidebarHeader } from "./styles";

export default function Header({ title, onHide }) {
  return (
    <SidebarHeader>
      <Button className="transparent pull-right" onClick={onHide}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>
      <h4>{title}</h4>
    </SidebarHeader>
  );
}

Header.propTypes = {
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
