/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

function SectionHeader({ icon, title, number, expanded, onToggleExpand }) {
  return (
    <div
      css={css`
        background-color: #24262d;
        border-radius: 5px;
        color: #f2f2f4;
        cursor: pointer;
        font-size: 14px;
        margin: 10px 0;
        padding: 10px;
        width: 100%;
        svg,
        i {
          margin-right: 10px;
        }
      `}
      onClick={onToggleExpand}
    >
      <span>{icon}</span>
      <span>{title}</span>
      <span
        css={css`
          color: #fece46;
          margin-left: 5px;
        `}
      >
        {`(${number})`}
      </span>
      <span
        css={{
          float: "right",
        }}
      >
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
      </span>
    </div>
  );
}

SectionHeader.propTypes = {
  expanded: PropTypes.bool,
  icon: PropTypes.node,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onToggleExpand: PropTypes.func,
  title: PropTypes.string,
};

export default SectionHeader;
