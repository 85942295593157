/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import { useDashboard } from "hooks/dashboard";

import RackingDamageChart from "./RackingDamageChart";

const defaultColours = [
  "#673AB7",
  "#2196F3",
  "#f44336",
  "#4CAF50",
  "#3F51B5",
  "#009688",
  "#FF5722",
  "#8BC34A",
  "#03A9F4",
  "#9C27B0",
  "#4CAF50",
];

const colours = {
  Green: "#008b00",
  Amber: "#ff7700",
  Red: "#e30021",
};

const Wrapper = styled.div`
  width: 49%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  vertical-align: top;
`;

export default function RackingDamageCharts({ filters }) {
  const { dashboardData } = useDashboard(filters);
  if (!dashboardData) {
    return null;
  }
  const { risks, componentRisks } = dashboardData;
  const risksSorted = Object.keys(risks).length
    ? Object.keys(risks).sort((first, second) => {
        if (first === "Red") {
          return 1;
        }
        if (second === "Red") {
          return -1;
        }
        return 0;
      })
    : [];
  const risksSortedData = Object.keys(risks).length
    ? Object.entries(risks).sort((first, second) => {
        if (first[0] === "Red") {
          return 1;
        }
        if (second[0] === "Red") {
          return -1;
        }
        return 0;
      })
    : [];
  const riskData = risksSortedData
    ? risksSortedData.reduce((o, [key, value]) => ({ ...o, [key]: value }), {})
    : [];

  return (
    <>
      <Wrapper>
        {Object.keys(componentRisks).length ? (
          <RackingDamageChart
            colours={defaultColours}
            data={componentRisks}
            title="By Component"
          />
        ) : (
          "No Data"
        )}
      </Wrapper>
      <Wrapper>
        {Object.keys(risks).length ? (
          <RackingDamageChart
            colours={risksSorted.map((risk) => colours[risk])}
            data={riskData}
            title="By Risk"
          />
        ) : (
          "No Data"
        )}
      </Wrapper>
    </>
  );
}

RackingDamageCharts.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
};
