import PropTypes from "prop-types";
import { useState } from "react";
import "views/styles/Groups.css";

import Header from "../SharedControls/Header";
import { SidebarBody, SidebarEditorPanel } from "../SharedControls/styles";

import AddArea from "./AddArea";
import DisplayArea from "./DisplayArea";
import EditArea from "./EditArea";
import Footer from "./Footer";

export default function AreasEditor({
  areaId,
  areas = [],
  onDataChanged,
  onHide,
  racks = [],
  setAreaId,
  warehouseId,
}) {
  const title = areaId ? "Edit Area" : "New Area";
  const area = areas.find((a) => a.id === areaId);
  const [name, setName] = useState(null);
  const [overRideRackAreas, setOverrideRackAreas] = useState([]);
  // Display our edited override or what is saved
  const displayName = name && area?.name !== name ? name : area?.name ?? "";

  const updatedRacks = racks.map((r) => {
    const update = overRideRackAreas.find((o) => o.rackId === r.id);
    return update ? { ...r, AreaId: update.areaId, changed: true } : r;
  });

  return (
    <SidebarEditorPanel>
      <Header onHide={onHide} title={title} />
      <SidebarBody>
        {!areaId ? (
          <AddArea
            WarehouseId={warehouseId}
            name={name}
            onAddArea={(newAreaId) => {
              onDataChanged();
              setAreaId(newAreaId);
            }}
            setName={setName}
          />
        ) : (
          <>
            <EditArea name={displayName} setName={setName} />
            <DisplayArea
              changeRackArea={(rackId) =>
                setOverrideRackAreas([
                  ...overRideRackAreas.filter((r) => r.rackId !== rackId),
                  { rackId, areaId: null },
                ])
              }
              racks={updatedRacks.filter((r) => r.AreaId === areaId)}
              racksAreChecked
              title={areas.find((a) => a.id === areaId)?.name}
            />
            <DisplayArea
              changeRackArea={(rackId) =>
                setOverrideRackAreas([
                  ...overRideRackAreas.filter((r) => r.rackId !== rackId),
                  { rackId, areaId },
                ])
              }
              racks={updatedRacks.filter((r) => !r.AreaId)}
              title="Not assigned to an area"
            />
            {areas
              .filter((a) => a.id !== areaId)
              .map((a) => (
                <DisplayArea
                  changeRackArea={(rackId) =>
                    setOverrideRackAreas([
                      ...overRideRackAreas.filter((r) => r.rackId !== rackId),
                      { rackId, areaId },
                    ])
                  }
                  key={a.id}
                  racks={updatedRacks.filter((r) => r.AreaId === a.id)}
                  title={a.name}
                />
              ))}
          </>
        )}
      </SidebarBody>
      {areaId ? (
        <Footer
          area={area}
          areaId={areaId}
          name={displayName}
          onDataChanged={onDataChanged}
          onHide={onHide}
          racks={updatedRacks}
          setAreaId={(addedAreaId) => {
            setAreaId(addedAreaId);
            onDataChanged();
          }}
        />
      ) : null}
    </SidebarEditorPanel>
  );
}

AreasEditor.propTypes = {
  areaId: PropTypes.string,
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ),
  onDataChanged: PropTypes.func.isRequired,
  onHide: PropTypes.func,
  racks: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
  setAreaId: PropTypes.func.isRequired,
  warehouseId: PropTypes.string.isRequired,
};
