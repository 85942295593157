import styled from "@emotion/styled";
import {
  faChevronLeft,
  faExpand,
  faMagnifyingGlass,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  useState,
  useId,
  useRef,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import { MenuItem, Panel, SplitButton, Tab } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { useInspection } from "hooks/inspections";
import { useScaledBlueprintDisplayClassifications } from "hooks/use-blueprint-display-classifications";
import useBlueprintSizer from "hooks/use-blueprint-sizer";
import { useScaledSiteBlueprint } from "hooks/use-site-blueprint";
import { getDamage } from "libs/blueprint";
import BlueprintFilterOptions from "views/components/Blueprint/BlueprintFilterOptions";
import Area from "views/components/Blueprint/canvas-elements/area";
import { BeamsForBays } from "views/components/Blueprint/canvas-elements/beams";
import DamageClassifications from "views/components/Blueprint/canvas-elements/damage-classifications";
import { FramesForBays } from "views/components/Blueprint/canvas-elements/frames";
import Grid from "views/components/Blueprint/canvas-elements/grid";
import Rack from "views/components/Blueprint/canvas-elements/rack";
import StageViewport from "views/components/Blueprint/canvas-elements/stage-viewport";
import ZoomSelect from "views/components/Blueprint/canvas-elements/zoom-select";
import NavigationBar from "views/components/Blueprint/navigation-bar";
import {
  BackLink,
  BottomTabs,
  MainAndSidebar,
  MainContainer,
  NavigationSection,
  RoundButton,
  Sidebar,
  StageContainer,
} from "views/components/Blueprint/styles";
import LogoImage from "views/components/ClientLogo";
import FilterChip from "views/components/FilterChip";
import Loading from "views/components/Loading";
import FlatModal from "views/components/Modals/FlatModal";
import AddDamageEditor from "views/components/Modals/NewClassificationEditor/add-editor";
import MiscDamageEditor from "views/components/Modals/NewClassificationEditor/misc-editor";
import OffloadLocationEditor from "views/components/Modals/NewClassificationEditor/offload-editor.jsx";
import ConfirmButton from "views/pages/Inspection/components/buttons/button-confirm";
import DeleteButton from "views/pages/Inspection/components/buttons/button-delete";
import ReclassifyButton from "views/pages/Inspection/components/buttons/button-reclassify";
import Classification from "views/pages/Inspection/components/classification";
import { AddDamageWrapper } from "views/pages/Inspection/styles";

const Divider = styled.div`
  height: 20px;
  border-left: 1px solid #999;
  margin-left: 5px;
  margin-right: 5px;
`;

const showSidebarStates = {
  NONE: "NONE",
  ADD: "ADD",
  ADD_MISC: "ADD_MISC",
  OFFLOAD: "OFFLOAD",
};

const StyledPanelHeader = styled(Panel.Heading)`
  align-items: center;
  background-color: #2d72ed !important;
  border-radius: 0 !important;
  color: white !important;
  display: flex;
  justify-content: space-between;
`;

const ModalBodyWrapper = styled.div`
  padding: 10px;
`;

/**
 * The sidebar reducer handles the state of the sidebar for adding damage. It
 * also contains 'params' which are passed into the AddDamageEditor component
 * as fixed values that can't be over-ridden by the form.
 */
function sidebarReducer(state = { status: showSidebarStates.NONE }, action) {
  switch (action.type) {
    // case close
    case "close":
      return { ...state, status: showSidebarStates.NONE, params: {} };
    // case open
    case "open":
      return { ...state, status: showSidebarStates.ADD, params: action.params };
    // case open misc
    case "open-misc":
      return { ...state, status: showSidebarStates.ADD_MISC };
    // case open offload
    case "open-offload":
      return {
        ...state,
        status: showSidebarStates.OFFLOAD,
        params: action.params,
      };
    default:
      return state;
  }
}

/**
 * Display a read-only warehouse with the latest classifications.
 *
 * Note: The racks display, and then the damage classifications are layered
 * over the top. The hook returns rack information alongside the classifications,
 * so it renders the positions in the same way across both components, it just
 * doesn't draw the racks in the DisplayClassifications component.
 */
export default function PageInspectionBlueprintActive({
  SiteId,
  WarehouseId,
  InspectionId,
}) {
  const a11yWarehouseTabsId = useId(); // Accessible ID for the tabs
  const a11yAddDamageDropdown = useId(); // Accessible ID for the add damage dropdown
  const viewportRef = useRef(); // Access to the pixi viewport instance
  const navigate = useNavigate();
  const { inspection, mutate: refreshInspection } = useInspection(InspectionId);

  // Lock the pan when the user is zooming
  const [isPanLocked, setPanLock] = useState(false);
  const [frameLocationId, setFrameLocationId] = useState(null);
  const [beamLevel, setBeamLevel] = useState(null);
  const [classificationId, setClassificationId] = useState(null);

  // Reclassified classifications modify added classifications
  const reclassifiedClassifications =
    inspection?.reclassifiedClassifications ?? [];

  let previousToReclassificationIds = new Map();
  reclassifiedClassifications.forEach((r) => {
    previousToReclassificationIds.set(r.ReclassificationId, r.ClassificationId);
  });

  // These are the IDs of the previous classifications, which are being reclassified
  const reclassifiedClassificationPreviousIds = reclassifiedClassifications.map(
    (r) => r.ClassificationId,
  );
  const isReclassified = useCallback((id) => reclassifiedClassificationPreviousIds.includes(id), [reclassifiedClassificationPreviousIds]); // prettier-ignore

  const confirmedClassificationIds = useMemo(() => inspection?.confirmedClassifications?.map((c) => c.ClassificationId) ?? [], [inspection]); // prettier-ignore
  const isConfirmed = useCallback((id) => confirmedClassificationIds.includes(id), [confirmedClassificationIds]); // prettier-ignore

  const addedClassificationIds = useMemo(
    () => inspection.classifications.map((c) => c.id),
    [inspection],
  );
  const isAdded = useCallback((id) => addedClassificationIds.includes(id), [addedClassificationIds]); // prettier-ignore

  const gridRef = useRef();

  const { site, warehouse, areas, isLoading } = useScaledSiteBlueprint(
    SiteId,
    WarehouseId,
    InspectionId,
  );

  const [filters, setFilters] = useState({
    showDisplay: true,
    showNone: false,
    SiteId,
    archival: !!inspection.completedAt,
    InspectionId,
  });
  const { displayClassifications } = useScaledBlueprintDisplayClassifications(
    filters,
    WarehouseId,
  );

  const { handleFocus, focusOnWarehouse, stageContainerRef, width, height } = useBlueprintSizer(viewportRef, warehouse, isLoading); // prettier-ignore

  /**
   * modalClassificationLocations holds the _location_ for the classification
   * not the classification itself. This is because the classification is loaded
   * from SWR and can refresh if edited elsewhere.
   *
   * If we have data in here, then a modal is displayed that looks up the locations
   * and grabs the up-to-date classification from SWR.
   */
  const [modalClassificationLocations, setModalClassificationsLocations] = useState(false); // prettier-ignore

  /**
   * Given the location (RackId, BayId) grab that slice of data from the
   * displayClassifications
   */
  const bay = displayClassifications
    ?.find((c) => c.id === modalClassificationLocations?.RackId)
    ?.classifications?.bays.find(
      (b) => b.BayId === modalClassificationLocations?.BayId,
    );

  /**
   * Extract the damage from the bay, using the spot we have from the
   * modalClassificationLocations state.
   *
   * This crawls down the { frames: { right: { middle } } } structure we have
   *
   * TODO: It appears the API does not label location correctly here. Patch
   * in the bay location from the bay above?
   */
  const modalClassifications = getDamage(
    bay?.types,
    modalClassificationLocations?.spot,
  )?.classifications;

  const [sidebarState, dispatch] = useReducer(sidebarReducer, {
    status: showSidebarStates.NONE,
  });
  const showAddSidebar = sidebarState?.status;

  /**
   * Memorise loops across the racks and classifications to aid performance.
   */
  const memoDamageClassifications = useMemo(() => {
    return displayClassifications.map((dc) => (
      <DamageClassifications
        key={dc.id}
        onSelect={setModalClassificationsLocations}
        rack={dc}
      />
    ));
  }, [displayClassifications]);

  const memoRacks = useMemo(() => {
    return warehouse?.racks.map((r) => (
      <Rack
        fullOpacity={
          (!inspection?.partial && !inspection?.isolated) ||
          r.inspected ||
          (inspection?.isolated &&
            displayClassifications.find((c) => c.id === r.id)?.classifications
              ?.bays?.length) ||
          false
        }
        key={r.id}
        rack={r}
      >
        <BeamsForBays
          bays={r.bays}
          onClick={(location) =>
            dispatch({
              type: "open",
              params: { rackId: r.id, componentType: "Beam", location },
            })
          }
          rackDepth={r.totalY}
        />
        <FramesForBays
          bays={r.bays}
          onClick={(location) =>
            dispatch({
              type: "open",
              params: {
                rackId: r.id,
                componentType: "Frame",
                location,
              },
            })
          }
        />
      </Rack>
    ));
  }, [
    displayClassifications,
    inspection?.isolated,
    inspection?.partial,
    warehouse?.racks,
  ]);

  const memoAreas = useMemo(() => {
    return areas.map((a) => (
      <Area
        key={a.id}
        maxX={a.maxX}
        maxY={a.maxY}
        minX={a.minX}
        minY={a.minY}
        name={a.name}
      />
    ));
  }, [areas]);

  if (isLoading) {
    return <Loading text="Loading Blueprint" />;
  }

  return (
    <MainAndSidebar>
      <Helmet>
        <title>Ongoing Inspection Blueprint</title>
      </Helmet>
      {showAddSidebar === showSidebarStates.ADD ||
      showAddSidebar === showSidebarStates.ADD_MISC ||
      showAddSidebar === showSidebarStates.OFFLOAD ? (
        <Sidebar>
          <Panel>
            <StyledPanelHeader>
              <h4>Add Damage</h4>
              <FontAwesomeIcon
                fixedWidth
                icon={faTimes}
                onClick={() => dispatch({ type: "close" })}
                size="xl"
              />
            </StyledPanelHeader>
            {showAddSidebar === showSidebarStates.ADD ? (
              <AddDamageEditor
                InspectionId={InspectionId}
                RackId={sidebarState?.params?.rackId}
                fixedDamageParameters={sidebarState?.params}
                onHide={() => dispatch({ type: "close" })}
                warehouseId={WarehouseId}
                setShowOffloadModal={() =>
                  dispatch({
                    type: "open-offload",
                    params: {
                      rackId: sidebarState?.params?.rackId,
                    },
                  })
                }
                setFrameLocationId={setFrameLocationId}
                setBeamLevel={setBeamLevel}
                setClassificationId={setClassificationId}
              />
            ) : null}
            {showAddSidebar === showSidebarStates.ADD_MISC ? (
              <MiscDamageEditor
                InspectionId={InspectionId}
                RackId={sidebarState?.params?.rackId}
                fixedDamageParameters={sidebarState?.params}
                onHide={() => dispatch({ type: "close" })}
                warehouseId={WarehouseId}
                setShowOffloadModal={() =>
                  dispatch({
                    type: "open-offload",
                    params: {
                      rackId: sidebarState?.params?.rackId,
                    },
                  })
                }
                setFrameLocationId={setFrameLocationId}
                setBeamLevel={setBeamLevel}
                setClassificationId={setClassificationId}
              />
            ) : null}
            {showAddSidebar === showSidebarStates.OFFLOAD &&
            classificationId ? (
              <OffloadLocationEditor
                InspectionId={InspectionId}
                ClassificationId={classificationId}
                RackId={sidebarState?.params?.rackId}
                onHide={() => dispatch({ type: "close" })}
                warehouseId={WarehouseId}
                frameLocationId={frameLocationId}
                beamLevel={beamLevel}
              />
            ) : null}
          </Panel>
        </Sidebar>
      ) : null}
      <MainContainer>
        <NavigationBar>
          <NavigationSection>
            <BackLink to={`/inspections/view/${InspectionId}`}>
              <FontAwesomeIcon fixedWidth icon={faChevronLeft} /> Inspection
            </BackLink>
            <LogoImage src={site?.ClientLogoUrl ?? "/img/defaults/logo.png"} />
            <FilterChip
              onChipPress={() => navigate(`/clients/sites/${SiteId}/overview`)}
              text={site?.SiteName ?? site?.name}
              type="sites"
            />
          </NavigationSection>
          <NavigationSection>
            <RoundButton
              active={isPanLocked}
              onClick={() => setPanLock((z) => !z)}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </RoundButton>
            <RoundButton onClick={focusOnWarehouse}>
              <FontAwesomeIcon icon={faExpand} />
            </RoundButton>
            <BlueprintFilterOptions
              components={[]}
              hideAmber={filters.hideAmber}
              hideGreen={filters.hideGreen}
              hideRed={filters.hideRed}
              selectedComponent={filters.searchText}
              setColorFilters={(e) => setFilters((f) => ({ ...f, ...e }))}
              setSelectedComponent={(e) =>
                setFilters((f) => ({ ...f, searchText: e }))
              }
            />
            <Divider />
            <AddDamageWrapper>
              <span className="blueprint-button">
                <SplitButton
                  id={a11yAddDamageDropdown}
                  onClick={() => dispatch({ type: "open" })}
                  pullRight
                  title="Add Damage"
                >
                  <MenuItem onClick={() => dispatch({ type: "open-misc" })}>
                    Add Misc Damage
                  </MenuItem>
                </SplitButton>
              </span>
            </AddDamageWrapper>
          </NavigationSection>
        </NavigationBar>
        <StageContainer ref={stageContainerRef} select={isPanLocked}>
          <StageViewport
            drag={!isPanLocked}
            onScale={(e) => gridRef.current?.setScaleFromPixiEvent(e)}
            ref={viewportRef}
            screenHeight={height}
            screenWidth={width}
            worldHeight={warehouse?.sizeY}
            worldWidth={warehouse?.sizeX}
          >
            <Grid
              ref={gridRef}
              sizeX={warehouse?.sizeX}
              sizeY={warehouse?.sizeY}
            />
            {memoAreas}
            {memoRacks}
            {memoDamageClassifications}
            {isPanLocked ? (
              <ZoomSelect
                height={warehouse?.sizeY}
                width={warehouse?.sizeX}
                zoomTo={handleFocus}
              />
            ) : null}
          </StageViewport>
        </StageContainer>
        <BottomTabs
          activeKey={WarehouseId}
          id={a11yWarehouseTabsId}
          onSelect={(key) =>
            navigate(`/inspections/blueprint/${InspectionId}/${key}`)
          }
        >
          {inspection.warehouses.map(({ id, name }) => (
            <Tab eventKey={id} key={id} title={name} />
          ))}
        </BottomTabs>
        {modalClassifications ? (
          <FlatModal
            closeButton
            onHide={() => setModalClassificationsLocations(false)}
            show
            title="Classifications"
            width={1000}
          >
            <ModalBodyWrapper>
              {modalClassifications.map((c) => (
                <Classification
                  InspectionId={InspectionId}
                  classification={c}
                  isActive
                  key={c.id}
                >
                  {isReclassified(c.id) ? (
                    <DeleteButton
                      classificationId={c.id}
                      isReclassification
                      onDelete={refreshInspection}
                      risk={c.risk}
                      showRisk
                    >
                      Reclassified
                    </DeleteButton>
                  ) : (
                    <>
                      {isAdded(c.id) ? (
                        <DeleteButton
                          classificationId={
                            c.reclassifiedAt
                              ? previousToReclassificationIds.get(c.id)
                              : c.id
                          }
                          isReclassification={!!c.reclassifiedAt}
                          onDelete={refreshInspection}
                          risk={c.risk}
                          showRisk
                        />
                      ) : null}
                      {!isAdded(c.id) && !isConfirmed(c.id) ? (
                        <ReclassifyButton
                          InspectionId={InspectionId}
                          classificationId={c.id}
                        />
                      ) : null}
                      {!isAdded(c.id) ? (
                        <ConfirmButton
                          ClassificationId={c.id}
                          InspectionId={InspectionId}
                          isConfirmed={confirmedClassificationIds.includes(
                            c.id,
                          )}
                          onChange={refreshInspection}
                          risk={c.risk}
                        />
                      ) : null}
                    </>
                  )}
                </Classification>
              ))}
            </ModalBodyWrapper>
          </FlatModal>
        ) : null}
      </MainContainer>
    </MainAndSidebar>
  );
}

PageInspectionBlueprintActive.propTypes = {
  SiteId: PropTypes.string.isRequired,
  WarehouseId: PropTypes.string.isRequired,
  InspectionId: PropTypes.string.isRequired,
};
