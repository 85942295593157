/** @jsxImportSource @emotion/react */
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { useManagedSchedules, useMySchedules } from "hooks/schedules";
import { useCurrentUser } from "hooks/user";

import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import { Table } from "views/components/Tables/styles";
import TableHeader from "views/components/Tables/TableHeader";

import { getSchedulesLink, renderPadding } from "../helpers";

import ScheduleRow, { PaddingRow } from "./ScheduleRow";

/**
 * Display schedules, with optional client and site filters
 */
export default function SchedulesTable({
  ClientId,
  SiteId,
  filters: passedFilters = {},
}) {
  const pageSize = 4;
  const filters = {
    ClientId,
    SiteId,
    amount: pageSize,
    allSchedules: true,
    ...passedFilters,
  };

  const {
    mySchedules: upcomingSchedules,
    activeSchedules,
    isLoading,
  } = useMySchedules(filters);
  const { total } = useManagedSchedules(filters);

  const filteredUpcomingSchedules = upcomingSchedules.filter(
    (u) => !activeSchedules.find((a) => a.ScheduleId === u.id),
  );

  const allSchedules = [...activeSchedules, ...filteredUpcomingSchedules].slice(
    0,
    pageSize,
  );
  const paddingAmount = isLoading ? 0 : pageSize - allSchedules.length;
  const showPadding = !isLoading && paddingAmount !== pageSize; // Loaded, and min 1 row
  const showEmptyTableIndicator =
    isLoading || (!isLoading && allSchedules.length === 0); // Loading or no rows
  // Schedules
  return (
    <>
      <TableHeader
        left="Schedules"
        right={
          <Link to={getSchedulesLink(ClientId, SiteId, true)}>
            View all {total} schedules <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        }
      />
      <Table>
        <thead>
          <tr>
            {!ClientId ? <th>Client</th> : null}
            {!SiteId ? <th>Site</th> : null}
            <th>Assignee</th>
            <th>Repeating</th>
            {ClientId ? <th>Status</th> : null}
            <th>Schedule Window</th>
            <th className="hover-buttons">Controls</th>
          </tr>
        </thead>
        <tbody>
          {activeSchedules.slice(0, pageSize).map((schedule) => (
            <ScheduleRow
              isActive={activeSchedules.find((as) => as.id === schedule.id)}
              isScheduledInspection
              key={schedule.id}
              schedule={schedule}
              showAssignedBy={false}
              showAssignee
              showClient={!ClientId}
              showSite={!SiteId}
              showStatus={ClientId}
            />
          ))}
          {filteredUpcomingSchedules
            .slice(0, Math.max(pageSize - activeSchedules.length, 0))
            .map((schedule) => (
              <ScheduleRow
                key={schedule.id}
                schedule={schedule}
                showAssignedBy={false}
                showAssignee
                showClient={!ClientId}
                showSite={!SiteId}
                showStatus={ClientId}
              />
            ))}
          {showPadding
            ? renderPadding(
                () => (
                  <PaddingRow
                    showAssignee={false}
                    showClient={!ClientId}
                    showSite={!SiteId}
                    showStatus={ClientId}
                  />
                ),
                paddingAmount,
              )
            : null}
        </tbody>
      </Table>
      {showEmptyTableIndicator ? (
        <EmptyTableIndicator isLoading={isLoading} type="Schedules" />
      ) : null}
    </>
  );
}

SchedulesTable.propTypes = {
  ClientId: PropTypes.string,
  SiteId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
};

/**
 * Display schedules, with optional client and site filters
 */
export function MySchedulesTable({
  ClientId,
  SiteId,
  filters: passedFilters = {},
}) {
  const { user } = useCurrentUser();
  const pageSize = 4;
  const filters = {
    ClientId,
    SiteId,
    InspectorId: user?.id,
    amount: pageSize,
    ...passedFilters,
  };

  const {
    mySchedules: upcomingSchedules,
    activeSchedules,
    isLoading,
  } = useMySchedules(filters);

  const { totalAll: total } = useMySchedules({
    ...filters,
    allSchedules: true,
  });

  const filteredUpcomingSchedules = upcomingSchedules.filter(
    (u) => !activeSchedules.find((a) => a.ScheduleId === u.id),
  );

  const allSchedules = [...activeSchedules, ...filteredUpcomingSchedules].slice(
    0,
    pageSize,
  );
  const paddingAmount = isLoading ? 0 : pageSize - allSchedules.length;
  const showPadding = !isLoading && paddingAmount !== pageSize; // Loaded, and min 1 row
  const showEmptyTableIndicator =
    isLoading || (!isLoading && allSchedules.length === 0); // Loading or no rows
  // Schedules
  return (
    <>
      <TableHeader
        left="My Schedules"
        right={
          <Link to={getSchedulesLink(ClientId, SiteId, true)}>
            View all {total} schedules <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        }
      />
      <Table>
        <thead>
          <tr>
            {!ClientId ? <th>Client</th> : null}
            {!SiteId ? <th>Site</th> : null}
            <th>Assigned By</th>
            <th>Repeating</th>
            {ClientId ? <th>Status</th> : null}
            <th>Schedule Window</th>
            <th className="hover-buttons">Controls</th>
          </tr>
        </thead>
        <tbody>
          {activeSchedules.slice(0, pageSize).map((schedule) => (
            <ScheduleRow
              isActive={activeSchedules.find((as) => as.id === schedule.id)}
              isScheduledInspection
              key={schedule.id}
              schedule={schedule}
              showAssignee={false}
              showClient={!ClientId}
              showSite={!SiteId}
              showStatus={ClientId}
            />
          ))}
          {filteredUpcomingSchedules
            .slice(0, Math.max(pageSize - activeSchedules.length, 0))
            .map((schedule) => (
              <ScheduleRow
                key={schedule.id}
                schedule={schedule}
                showAssignee={false}
                showClient={!ClientId}
                showSite={!SiteId}
                showStatus={ClientId}
              />
            ))}
          {showPadding
            ? renderPadding(
                () => (
                  <PaddingRow
                    showAssignee={false}
                    showClient={!ClientId}
                    showSite={!SiteId}
                    showStatus={ClientId}
                  />
                ),
                paddingAmount,
              )
            : null}
        </tbody>
      </Table>
      {showEmptyTableIndicator ? (
        <EmptyTableIndicator isLoading={isLoading} type="Schedules" />
      ) : null}
    </>
  );
}

MySchedulesTable.propTypes = {
  ClientId: PropTypes.string,
  SiteId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
};
