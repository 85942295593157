import styled from '@emotion/styled';

// eslint-disable-next-line import/no-unused-modules
export const SidebarEditorPanel = styled.div`
  background-color: #494c55;
  color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 1;
  height: 100%;
  margin-bottom: 0;
  width: 100%;
`;

export const SidebarHeader = styled.div`
  background-color: #2d72ed;
  color: #fff;
  flex-grow: 0;
  margin: 0;
  padding-bottom: 0;
  padding: 10px 15px;
  width: 100%;
`;

export const SidebarBody = styled.div`
  background-color: #494c55;
  overflow-y: scroll;
  flex-grow: 1;
`;

export const SidebarFooter = styled.div`
  background-color: #24262d;
  flex: 0;
  flex-grow: 0;
  padding: 10px 0;
  border: 0;
`;
