import { ReactChart } from "chartjs-react";
import moment from "moment";
import PropTypes from "prop-types";

export default function TimeSeries({ data = {}, colour }) {
  if (Object.keys(data).length === 0) return null;
  const sortedData = Object.keys(data)
    .sort((a, b) => moment(a, "MMM/YY").toDate() - moment(b, "MMM/YY").toDate())
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = data[key];
      return obj;
    }, {});
  const dataParsed = {
    labels: Object.keys(sortedData).map(
      (key) => key.charAt(0).toUpperCase() + key.slice(1),
    ),
    datasets: [
      {
        fill: true,
        lineTension: 0.1,
        backgroundColor: colour,
        borderColor: colour,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: colour,
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colour,
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: Object.keys(data).map((key) => data[key]),
      },
    ],
  };
  return (
    <ReactChart
      data={dataParsed}
      height={100}
      options={{
        // TODO: Once data-loading and re-rendering is fixed, enable animations
        animations: false,
        scales: {
          y: {
            grid: {
              tickLength: 0,
              color: "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              display: false,
            },
          },
          x: {
            grid: {
              tickLength: 0,
              color: "rgba(0, 0, 0, 0.1)",
            },
            ticks: {
              display: false,
            },
          },
        },
        layout: {
          padding: {
            left: 10,
            right: 10,
            bottom: 20,
          },
        },
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      }}
      type="line"
    />
  );
}

TimeSeries.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  colour: PropTypes.string.isRequired,
};
