import { Container } from "@pixi/react";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import BayDamage from "views/components/Blueprint/canvas-elements/bay-damage";
function DamageComponent({ rack: r, onSelect = () => {} }) {
  const handleClick = useCallback(
    (id, spot) => onSelect({ RackId: r.id, BayId: id, spot }),
    [onSelect, r.id],
  );
  return (
    <Container angle={r.rotation} key={r.id} position={[r.originX, r.originY]}>
      {r.bays.map((b) => {
        const bayDamage = r.classifications?.bays.find(
          (classificationBays) => classificationBays.BayId === b.id,
        )?.types;
        return (
          <Container cullable eventMode="passive" key={b.id} x={b.offset} y={0}>
            <BayDamage
              damage={bayDamage}
              depth={r.totalY}
              id={b.id}
              onclick={handleClick}
              orientation={r.orientation}
              width={b.width}
            />
          </Container>
        );
      })}
    </Container>
  );
}

DamageComponent.propTypes = {
  rack: PropTypes.shape({
    bays: PropTypes.arrayOf(
      PropTypes.shape({
        FrameTemplateL: PropTypes.shape({
          depth: PropTypes.number.isRequired,
          width: PropTypes.number.isRequired,
        }).isRequired,
        FrameTemplateR: PropTypes.shape({
          depth: PropTypes.number.isRequired,
          width: PropTypes.number.isRequired,
        }).isRequired,
        id: PropTypes.string.isRequired,
        location: PropTypes.number.isRequired,
        offset: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
      }),
    ).isRequired,
    classifications: PropTypes.shape({
      bays: PropTypes.arrayOf(
        PropTypes.shape({
          BayId: PropTypes.string.isRequired,
        }),
      ),
    }),
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    orientation: PropTypes.bool.isRequired,
    originX: PropTypes.number.isRequired,
    originY: PropTypes.number.isRequired,
    totalX: PropTypes.number.isRequired,
    totalY: PropTypes.number.isRequired,
    rotation: PropTypes.number.isRequired,
  }).isRequired,
  onSelect: PropTypes.func,
};

export default React.memo(DamageComponent);
