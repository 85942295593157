import PropTypes from "prop-types";

import { useClient } from "hooks/client";
import { useSite } from "hooks/site";
import ClientLogo from "views/components/ClientLogo";
import FilterChip from "views/components/FilterChip";

export default function Info({ siteId, reference }) {
  // TODO: Add error states to filter chips for loading these hooks
  const { site, isLoading: isLoadingSite } = useSite(siteId);
  const { client } = useClient(site?.ClientId);

  return (
    <>
      <ClientLogo src={client?.logoUrl} />
      <FilterChip isLoading={isLoadingSite} text={site?.name} type="sites" />
      <FilterChip text={`Ref. ${reference ?? ""}`} type="reference" />
    </>
  );
}

Info.propTypes = {
  siteId: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
};
