/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";

export default function ActionStatus({ action }) {
  if (!action || !action.status) return null;
  return (
    <div
      css={css`
        position: relative;
        background-color: #ededef;
        border-radius: 25px;
        padding: 5px 10px;
        border: 1px #3b3e47 solid;
        color: #3b3e47;
        width: 100px;
        font-weight: bolder;
        text-align: center;
      `}
    >
      {action.status}
    </div>
  );
}

ActionStatus.propTypes = {
  action: PropTypes.shape({
    status: PropTypes.string,
  }),
};
