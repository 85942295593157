export default function getSiteNameWithInfo(site) {
  let siteName = site.name;
  const notes = [];
  if (site.buildMode) {
    notes.push('Build Mode');
  }
  if (!site.warehouses.length && !site.buildMode) {
    notes.push('No Warehouses');
  }
  if (site.inspections.length) {
    notes.push('On-going Inspection');
  }
  if (!site.racks.length && site.warehouses.length && !site.buildMode) {
    notes.push('No Racks');
  }
  if (notes.length !== 0) {
    siteName += ' (';
  }
  notes.forEach((note, i) => {
    siteName += `${note}${i < notes.length - 1 ? ', ' : ')'}`;
  });
  return siteName;
}
