import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";

import { useBayTemplatesDepths } from "hooks/bay-templates";
import { useFrameTemplatesDepths } from "hooks/frame-templates";
import { useAreas, useRack } from "hooks/site";
import { addRack, deleteRack, editRack, getRack } from "libs/api";
import { calculateTotalXFromBays } from "libs/blueprint";
import Loading from "views/components/Loading";

import { EDITOR_MODES, getTextForMode } from "../constants";
import Header from "../SharedControls/Header";
import Notes from "../SharedControls/Notes";
import { SidebarEditorPanel, SidebarBody } from "../SharedControls/styles";

import Footer from "./Footer";
import {
  addBayToBays,
  calculateRelativeOrigins,
  getIsRackValid,
  markBayDeleted,
  updateFrameTemplate,
} from "./helpers";
import SectionBays from "./SectionBays";
import SectionDetails from "./SectionDetails";
import SectionPositioning from "./SectionPositioning";

const defaultFields = {
  name: "",
  ManufacturerId: "",
  originX: 0,
  originY: 0,
  notes: "",
  bays: [],
  rotation: 0,
  relative: true,
  offset: 0,
  offsetDirection: "front",
};

export default function RackEditor({
  mode,
  onHide,
  RackId,
  racks = [],
  SiteId,
  WarehouseId,
  onAddAnother,
}) {
  const [rack, setRack] = useState(null);
  const [addAnotherRack, setAddAnotherRack] = useState(false);
  const [compareRackId, setCompareRackId] = useState();
  const [backToBack, setBackToBack] = useState(!RackId && racks.length > 0);
  const [errorMsg, setErrorMsg] = useState(null);
  const containerRef = useRef(null);

  /**
   * If this is a copy or edit, we need to load the rack data
   */
  useEffect(() => {
    async function load() {
      setRack(null);
      const { name, relative, id, bays, ...rest } = await getRack(RackId);
      // The depth is a property of the bay templates used (which must be the same)
      // not of the rack itself.
      const { depth } = bays[0].bayTemplate;
      setRack({
        ...defaultFields,
        ...rest,
        bays: [...bays.sort((a, b) => (a.order > b.order ? 1 : -1))],
        id: mode === EDITOR_MODES.copy ? null : id,
        depth,
        relative: mode === EDITOR_MODES.copy ? true : relative,
        name: mode === EDITOR_MODES.copy ? `Copy of ${name}` : name,
      });

      // If we are copying, we set the compare rack to the rack we are copying
      if (mode === EDITOR_MODES.copy) {
        setCompareRackId(id);
      } else if (mode === EDITOR_MODES.edit) {
        const firstRackId = racks[racks.length - 1]?.id;
        setCompareRackId(firstRackId);
      }
    }

    if (RackId) {
      load();
    } else if (racks.length > 0) {
      /** If its a new rack, we set the default rack and setting the compare rack */
      const firstRackId = racks.length ? racks[racks.length - 1].id : null;
      setCompareRackId(firstRackId);
      setRack({ ...defaultFields, relative: true, backToBack: true });
    } else {
      setRack({ ...defaultFields, relative: false });
    }
  }, [RackId, mode, racks]);

  const { bayTemplates } = useBayTemplatesDepths(
    rack?.ManufacturerId,
    SiteId,
    rack?.depth,
  );
  const { frameTemplates } = useFrameTemplatesDepths(
    rack?.ManufacturerId,
    SiteId,
    rack?.depth,
  );
  const { areas } = useAreas(WarehouseId);

  // compareRack is that rack which you are positioning the offset
  const { rack: compareRack } = useRack(compareRackId);

  if (!rack) {
    return <Loading darkMode height="calc(100vh - 50px)" />;
  }

  return (
    <SidebarEditorPanel ref={containerRef}>
      <Header
        onHide={onHide}
        title={`${getTextForMode(mode)} Rack${
          mode === EDITOR_MODES.copy ? ` from ${compareRack?.name}` : ""
        }`}
      />
      <SidebarBody>
        <SectionDetails
          SiteId={SiteId}
          areas={areas}
          isCopy={mode === EDITOR_MODES.copy}
          onUpdate={(fields) => setRack({ ...rack, ...fields })}
          rack={rack}
        />
        <div className="horizontal-divider" />
        <SectionPositioning
          areas={areas}
          backToBack={backToBack}
          compareRack={compareRack}
          key={rack.id}
          onChangeBackToBack={(value) => setBackToBack(value)}
          onChangeCompareRack={(id) => setCompareRackId(id)}
          onUpdate={(fields) => setRack({ ...rack, ...fields })}
          rack={rack}
          racks={racks}
        />
        <SectionBays
          bayTemplates={bayTemplates}
          depth={rack.depth}
          frameTemplates={frameTemplates}
          onAddBay={({ BayTemplateId, FrameTemplateId }, numberToAdd = 1) => {
            const updatedBays = addBayToBays(
              BayTemplateId,
              FrameTemplateId,
              numberToAdd,
              rack.bays,
            );
            const totalX = calculateTotalXFromBays(
              updatedBays,
              bayTemplates,
              frameTemplates,
            );
            setRack({ ...rack, bays: updatedBays, totalX });
          }}
          onDeleteBay={(i) => {
            const updatedBays = markBayDeleted(i, rack.bays);
            const totalX = calculateTotalXFromBays(
              updatedBays,
              bayTemplates,
              frameTemplates,
            );
            setRack({ ...rack, bays: updatedBays, totalX });
          }}
          onUpdateBay={(i, fields) => {
            const updatedBays = rack.bays.map((bay, index) => {
              if (index === i) {
                return { ...bay, ...fields };
              }
              return bay;
            });
            const totalX = calculateTotalXFromBays(
              updatedBays,
              bayTemplates,
              frameTemplates,
            );
            setRack({
              ...rack,
              bays: updatedBays,
              totalX,
            });
          }}
          onUpdateFrameTemplate={(index, FrameTemplateId, isLeftFrame) => {
            setRack({
              ...rack,
              bays: updateFrameTemplate(
                index,
                FrameTemplateId,
                isLeftFrame,
                rack.bays,
              ),
            });
          }}
          rack={rack}
        />
        <Notes
          notes={rack.notes}
          setNotes={(notes) => setRack({ ...rack, notes })}
        />
      </SidebarBody>
      <Footer
        addAnotherRack={addAnotherRack}
        canSave={getIsRackValid(rack, racks)}
        errorMsg={errorMsg}
        isNew={mode === EDITOR_MODES.new || mode === EDITOR_MODES.copy}
        onChangeAddAnotherRack={() => setAddAnotherRack(!addAnotherRack)}
        onDelete={async () => {
          await deleteRack(rack.id);
          onHide();
        }}
        onSave={async () => {
          setErrorMsg(null);
          if (rack.relative && compareRack) {
            const { x: originX, y: originY } = calculateRelativeOrigins(
              rack,
              compareRack,
              backToBack,
            );
            console.log("saving rack", rack, backToBack, originX, originY);
            rack.originX = originX;
            rack.originY = originY;
            if (backToBack) {
              rack.orientation = !compareRack.orientation;
            }
          }
          try {
            // Note added rack will be all racks if editing, so don't use it!
            const addedRack =
              mode === EDITOR_MODES.new || mode === EDITOR_MODES.copy
                ? await addRack({
                    ...rack,
                    bays: rack.bays
                      .filter((r) => !r.deleted)
                      .map((b, i) => ({ ...b, location: i + 1 })),
                    WarehouseId,
                  })
                : // For some reason the edit rack function takes an array of all racks!
                  await editRack([rack]);
            if (addAnotherRack) {
              // Trigger a data refresh on the canvas. This will likely not be
              // needed once we implement SWR hooks throughout
              onAddAnother(addedRack.id);
              // If the screen is small, then we need to reset the scroll on the
              // sidebar container, back to the top of the form.
              containerRef?.current?.scroll({
                top: 0,
                behavior: "smooth",
              });
            } else {
              onHide();
            }
          } catch (e) {
            setErrorMsg(e.message);
          }
        }}
      />
    </SidebarEditorPanel>
  );
}

RackEditor.propTypes = {
  mode: PropTypes.oneOf(Object.values(EDITOR_MODES)).isRequired,
  onHide: PropTypes.func.isRequired,
  RackId: PropTypes.string,
  racks: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  SiteId: PropTypes.string.isRequired,
  WarehouseId: PropTypes.string.isRequired,
  onAddAnother: PropTypes.func.isRequired,
};
