import PropTypes from "prop-types";
import {
  Col,
  ControlLabel,
  FormControl,
  Grid,
  Panel,
  Row,
} from "react-bootstrap";

import EditorPanel from "views/components/EditorPanel";

export default function ClientContactDetails({ client = {}, onChangeField }) {
  return (
    <EditorPanel>
      <Panel.Heading>Client Contact Details</Panel.Heading>
      <Panel.Body>
        <Grid fluid>
          <Row>
            <Col sm={6}>
              <ControlLabel>
                Contact Name
                <span className="required">*</span>
              </ControlLabel>
              <FormControl
                onChange={(e) => onChangeField("contactName", e.target.value)}
                placeholder="Enter Contact Name"
                value={client.contactName}
              />
            </Col>

            <Col sm={6}>
              <ControlLabel>
                Contact Number
                <span className="required">*</span>
              </ControlLabel>
              <FormControl
                onChange={(e) => onChangeField("contactNumber", e.target.value)}
                placeholder="Enter Contact Telephone"
                value={client.contactNumber}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <ControlLabel>
                Contact Email
                <span className="required">*</span>
              </ControlLabel>

              <FormControl
                onChange={(e) => onChangeField("contactEmail", e.target.value)}
                placeholder="Enter Contact Email"
                value={client.contactEmail}
              />
            </Col>
          </Row>
        </Grid>
      </Panel.Body>
    </EditorPanel>
  );
}

ClientContactDetails.propTypes = {
  client: PropTypes.object.isRequired,
  onChangeField: PropTypes.func.isRequired,
};
