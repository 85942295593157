import PropTypes from "prop-types";
import { useState } from "react";

import { userRequestConfirmPassword } from "libs/api";

export default function NewPassword({
  email,
  passwordResetCode,
  setMode,
  setError,
  error,
  setLoading,
  setInfo,
}) {
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  return (
    <>
      <div className="form-group">
        <h4>Enter your new password</h4>
        <input
          className="form-control"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="New Password"
          type="password"
        />
        <br />
        <input
          className="form-control"
          onChange={(e) => setVerifyPassword(e.target.value)}
          placeholder="Verify Password"
          type="password"
        />
        <div>
          <p className="error-message">{error}</p>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: 40 }}>
        <div
          className="flex items-center justify-between"
          style={{
            flex: 5,
            marginRight: 10,
          }}
        >
          <button
            className="btn btn-primary btn-block cancel"
            onClick={() => setMode("login")}
            type="button"
          >
            Back to Sign In
          </button>
        </div>
        <div
          className="flex items-center justify-between"
          style={{
            flex: 5,
            marginLeft: 10,
          }}
        >
          <button
            className="btn btn-primary btn-block"
            disabled={
              password !== verifyPassword ||
              !password ||
              !verifyPassword ||
              error
            }
            onClick={async () => {
              setLoading("request-confirm-password");
              try {
                await userRequestConfirmPassword(
                  email,
                  passwordResetCode,
                  password,
                );
                setInfo("Password Successfully Changed, Log in below");
                setMode("login");
              } catch (err) {
                setError(err.message);
              }
            }}
            type="button"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

NewPassword.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  passwordResetCode: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
};
