import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Tabs, Tab } from "react-bootstrap";

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== "indicatorActive",
})`
  && {
    width: ${(props) => (props.width ? props.width : "100%")} !important;
    display: inline-block;
    margin-bottom: -4px;
    ul {
      background-color: #ffffff00;
      li {
        margin-bottom: ${(props) => (props.indicatorActive ? "-4px" : "-2px")};
        a,
        a:focus,
        a:hover {
          background-color: #00000000;
          color: #333;
          border: 0px;
        }
      }
      li:after {
        position: absolute;
        top: 10px;
        bottom: 10px;
        border-right: 1px solid #494c55;
        content: "";
      }
      li:first-of-type:after {
        display: none;
      }
      li.active {
        margin-right: -3px;
        border: 0;
        a,
        a:focus,
        a:hover {
          background-color: #494c55;
          color: #ffcf33;
          border: 0;
        }
      }
    }
  }
`;

function TableTabs({
  onSelectKey,
  titles,
  initialTab,
  width,
  indicatorActive,
}) {
  return (
    <StyledTabs
      defaultActiveKey={Number(initialTab) || 0}
      id={`${titles.join("-")}-tabs`}
      indicatorActive={indicatorActive}
      onSelect={(key) => onSelectKey(key)}
      width={width}
    >
      {titles.map((title, i) => (
        <Tab eventKey={i} key={title} title={title} />
      ))}
    </StyledTabs>
  );
}

TableTabs.propTypes = {
  onSelectKey: PropTypes.func,
  titles: PropTypes.arrayOf(PropTypes.string),
  initialTab: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  indicatorActive: PropTypes.bool,
};

export default TableTabs;
