function sortByLevel(a, b) {
  return a?.level > b?.level ? 1 : -1;
}

export function updateBeamTemplates(bayTemplate, fields, numberToAdd = 1) {
  return [
    ...(bayTemplate.beamTemplates ?? []),
    ...Array.from({ length: numberToAdd }, (_el, idx) => ({
      ...fields,
      level: bayTemplate.beamTemplates
        ? bayTemplate.beamTemplates.filter((bt) => !bt.deleted).length + idx + 1
        : 1,
    })),
  ].sort(sortByLevel);
}
