import isRiskInvalid from './isRiskInvalid';

export default function getRiskValidation(riskColour, reclassificationId, originalRisk) {
  switch (riskColour) {
    case 'Green':
      return (
        (reclassificationId && originalRisk === 'Green') || isRiskInvalid(originalRisk, 'Green')
      );
    default:
      return reclassificationId && isRiskInvalid(originalRisk, riskColour);
  }
}
