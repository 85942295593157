import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom'; // compat

import { ActionButtonsOverlay, EditUserButton } from 'views/components/ActionButtons';

export default function UsersActionButtons({ UserId, ClientId, canEditUser }) {
  const navigate = useNavigate();
  let editUserURL = `/users/${UserId}/edit`;
  if (ClientId) {
    editUserURL = `/users/${UserId}/edit/client/${ClientId}`;
  }
  return (
    <ActionButtonsOverlay>
      {canEditUser && <EditUserButton onClick={() => navigate(editUserURL)} />}
    </ActionButtonsOverlay>
  );
}

UsersActionButtons.propTypes = {
  UserId: PropTypes.string.isRequired,
  ClientId: PropTypes.string,
  canEditUser: PropTypes.bool,
};
