import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export default function RiskUpdatedIndicator({ large }) {
  return (
    <FontAwesomeIcon
      icon={faInfoCircle}
      size={large ? "2xl" : "xl"}
      style={{ color: "#2d72ed", padding: 3 }}
    />
  );
}

RiskUpdatedIndicator.propTypes = {
  large: PropTypes.bool,
};
