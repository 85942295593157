import { useCallback } from "react";
import useSWR, { mutate as globalMutate } from "swr";

import {
  addClassification,
  addClassificationFile,
  getDisplayClassifications,
  getInspection,
  getInspectionProgress,
  getInspections,
  getPreviousClassifications,
} from "../libs/api";

export function useInspections(filters) {
  const { data, error, mutate, isLoading, isValidating } = useSWR(
    [`/inspections/`, filters],
    async () => getInspections(filters),
  );

  return {
    inspections: data?.results ?? [],
    isLoading,
    isValidating,
    isError: error,
    total: data?.total ?? 0,
    mutate,
  };
}

export function useInspection(id) {
  const { data, error, mutate, isLoading, isValidating } = useSWR(
    id ? `/inspections/${id}` : null,
    async () => getInspection(id),
  );
  const mutateWithGlobal = useCallback(() => {
    mutate();
    globalMutate((key) => {
      const shouldMutate =
        Array.isArray(key) &&
        key[0] === "/inspection/display" &&
        key[1].InspectionId === id;
      return shouldMutate;
    });
  }, [id, mutate]);

  const addClassificationWithImages = useCallback(
    async (classification, images) => {
      // TODO: Refactor so JSON goes in the lib
      // TODO: Optimistic mutate
      const res = await addClassification(classification);
      const savedClassification = await res.json();
      await Promise.all(
        images?.map(async (image) =>
          addClassificationFile({
            remotePath: image.location,
            ClassificationId: savedClassification.id,
            caption: image.caption,
          }),
        ) ?? [],
      );
      await mutateWithGlobal();
      return savedClassification;
    },
    [mutateWithGlobal],
  );

  return {
    inspection: data,
    isLoading,
    isError: error,
    isValidating,
    mutate: mutateWithGlobal,
    addClassificationWithImages,
  };
}

export function usePreviousClassifications(SiteId, InspectionId) {
  const { data, error, mutate, isLoading, isValidating } = useSWR(
    SiteId ? [`/inspections/classifications/${SiteId}`, InspectionId] : null,
    async () =>
      getPreviousClassifications(SiteId, {
        CurrentInspectionId: InspectionId,
        lite: true,
      }),
  );

  return {
    previousClassifications: data,
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useDisplayClassifications(filters) {
  const { data, error, mutate, isLoading } = useSWR(
    filters ? [`/inspection/display`, filters] : null,
    async () => getDisplayClassifications(filters),
  );

  return {
    data,
    error,
    mutate,
    isLoading,
  };
}

// use inspection progress hook
export function useInspectionProgress(InspectionId) {
  const { data, error, mutate, isLoading, isValidating } = useSWR(
    InspectionId ? `action/inspection/progress/${InspectionId}` : null,
    async () => getInspectionProgress(InspectionId),
  );

  return {
    progress: data,
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}
