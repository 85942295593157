/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useEffect, useState, useCallback } from "react";
import { Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { getPermission } from "libs/api";

import WithCompatWrap from "libs/with-compact-wrap";

import HeaderClientLinks from "./ClientLinks";
import HeaderSiteLinks from "./SiteLinks";

function Header({ hideHeader, user }) {
  const location = useLocation();
  const [hideBack, setHideBack] = useState(false);

  const splitPath = location.pathname.split("/");

  const getPermissions = useCallback(async () => {
    if (splitPath[2]) {
      const permission = await getPermission("site", splitPath[2]);
      setHideBack(
        permission.level === "client" ||
          permission.level === "clientAdmin" ||
          permission.level === "site" ||
          permission.level === "none",
      );
    }
  }, [splitPath]);

  useEffect(() => {
    getPermissions();
  }, [splitPath, getPermissions]);

  if (hideHeader) {
    return null;
  }

  const showClientsLinks = splitPath[1] === 'clients' && splitPath[2] !== 'sites' && splitPath[2] !== 'add' && splitPath.length > 2; // prettier-ignore
  const showSitesLinks = splitPath[1] === 'clients' && splitPath[2] === 'sites' && splitPath[3] && splitPath[2] !== 'add' && splitPath[2] !== 'edit' && splitPath.length > 2 && !showClientsLinks; // prettier-ignore

  return (
    <Navbar
      css={css`
        padding-left: 0;
      `}
      fluid
      inverse
    >
      <Navbar.Header
        css={css`
          min-width: 210px;
        `}
      >
        <Navbar.Brand>
          <Link to="/">
            <img
              alt="Econform Logo"
              className="logo"
              css={{
                height: 20,
                marginTop: 0,
              }}
              src="/img/logo.png"
            />
          </Link>
        </Navbar.Brand>
        {(showClientsLinks || showSitesLinks) && <Navbar.Toggle />}
      </Navbar.Header>
      <Navbar.Collapse>
        {showClientsLinks && (
          <HeaderClientLinks hideBack={hideBack} url={splitPath} user={user} />
        )}
        {showSitesLinks && <HeaderSiteLinks url={splitPath} user={user} />}
      </Navbar.Collapse>
    </Navbar>
  );
}

Header.propTypes = {
  hideHeader: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

export default WithCompatWrap(Header);
