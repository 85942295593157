import styled from "@emotion/styled";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";

import ButtonWide from "views/components/Buttons/button-wide";
import ClientLogo from "views/components/ClientLogo";
import UploadButton from "views/components/UploadButton";

const UploadWrapper = styled.div`
  margin: 10px 0;
`;

const LogoWrapper = styled.div`
  margin: 10px 0;
`;

export default function LogoUploader({ imageUrl, onRemove, onAddLogo }) {
  const [isUploading, setIsUploading] = useState();
  return (
    <>
      {imageUrl ? (
        <LogoWrapper>
          <ClientLogo size="xl" src={imageUrl} />
        </LogoWrapper>
      ) : null}

      {imageUrl ? (
        <ButtonWide onClick={onRemove}>Remove</ButtonWide>
      ) : (
        <UploadWrapper>
          <UploadButton
            disabled={isUploading}
            onFileUploadFailed={() => setIsUploading(false)}
            onFileUploadStarted={() => setIsUploading(true)}
            onFileUploaded={(file) => {
              onAddLogo(file.location);
              setIsUploading(false);
            }}
          >
            {isUploading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Upload +"
            )}
          </UploadButton>
        </UploadWrapper>
      )}

      <p>
        For best results, we recommend that you upload a square image at 200px
        by 200px.
      </p>
      <p>Supported file formats: JPG, PNG, and GIF</p>
    </>
  );
}

LogoUploader.propTypes = {
  imageUrl: PropTypes.string,
  onAddLogo: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
