function getValidationText(classificationRisk, selectableRisk) {
  return `${classificationRisk} Risk can only be reclassified to ${selectableRisk}!`;
}

export default function isRiskInvalid(classificationRisk, selectedRisk, returnValidationText) {
  if (
    ((classificationRisk === 'Amber' && selectedRisk !== 'Red') || classificationRisk === 'Red') &&
    selectedRisk !== 'None'
  ) {
    const selectableRiskText = classificationRisk === 'Amber' ? 'Red or None' : 'None';
    return returnValidationText ? getValidationText(classificationRisk, selectableRiskText) : true;
  }

  return null;
}
