/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function addArea(Area) {
  const res = await apiCall("/area/", {
    method: "POST",
    body: JSON.stringify(Area),
  });
  if (res.status === 201 || res.status === 200) {
    return res.json();
  }
  throw new Error("Problem adding area");
}

export async function deleteArea(AreaId) {
  const res = await apiCall(`/area/${AreaId}`, {
    method: "DELETE",
  });
  if (res.ok && res.status === 200) {
    return res;
  }
  throw new Error("Problem deleting area");
}

export async function editArea(Area) {
  const res = await apiCall("/area/", {
    method: "PUT",
    body: JSON.stringify(Area),
  });
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem editing area: ${res.status}`);
  return false;
}

export async function getAreas(WarehouseId = false, filters = null) {
  const res = await apiCall(
    `/area${WarehouseId ? `/warehouse/${WarehouseId}` : ""}${filterUrlGenerator(
      filters,
    )}`,
  );
  if (res.ok) {
    return res.json();
  }
  throw new Error("Problem getting areas");
}
