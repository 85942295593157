import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import FieldArrangement from "views/components/BuildModeSidebar/RackEditor/SectionPositioning/fields/arrangement";
import FieldOrientation from "views/components/BuildModeSidebar/RackEditor/SectionPositioning/fields/orientation";
import FieldRelativeAbsolute from "views/components/BuildModeSidebar/RackEditor/SectionPositioning/fields/relative-absolute";
import FieldRotation from "views/components/BuildModeSidebar/RackEditor/SectionPositioning/fields/rotation";

import { YellowHeading } from "../../SharedControls/Generic";

import AbsolutePositioning from "./AbsolutePositioning";
import RelativePositioning from "./RelativePositioning";

export default function SectionPositioning({
  areas,
  backToBack,
  onUpdate,
  rack,
  racks,
  compareRack,
  onChangeCompareRack,
  onChangeBackToBack,
}) {
  return (
    <div className="positioning-info">
      <Form
        horizontal
        onKeyPress={(e) => (e.which === 13 ? e.preventDefault() : "")}
      >
        <YellowHeading disabled={!rack.depth}>Positioning</YellowHeading>
        <FieldRotation
          disabled={!rack.ManufacturerId || !rack.depth}
          onUpdate={onUpdate}
          rotation={rack.rotation}
        />
        {racks.length > 0 ? (
          <>
            <FieldRelativeAbsolute
              disabled={!rack.ManufacturerId || !rack.depth}
              onUpdate={onUpdate}
              relative={rack.relative}
            />
            {rack.relative ? (
              <RelativePositioning
                areas={areas}
                backToBack={backToBack}
                compareRack={compareRack}
                depth={rack.depth}
                onChangeBackToBack={onChangeBackToBack}
                onChangeCompareRack={onChangeCompareRack}
                onUpdate={onUpdate}
                rack={rack}
                racks={racks}
              />
            ) : (
              <AbsolutePositioning
                ManufacturerId={rack.ManufacturerId}
                areas={areas}
                depth={rack.depth}
                onUpdate={onUpdate}
                originX={rack.originX}
                originY={rack.originY}
                rack={rack}
                racks={racks}
              />
            )}
          </>
        ) : (
          <AbsolutePositioning
            ManufacturerId={rack.ManufacturerId}
            depth={rack.depth}
            onUpdate={onUpdate}
            originX={rack.originX}
            originY={rack.originY}
            rack={rack}
          />
        )}
        {!backToBack ? (
          <FieldOrientation
            disabled={!rack.ManufacturerId || !rack.depth}
            onUpdate={onUpdate}
            orientation={rack.orientation}
          />
        ) : null}
        <FieldArrangement
          arrangement={rack.arrangement}
          disabled={!rack.ManufacturerId || !rack.depth} // TODO: This doesn't work?
          onUpdate={onUpdate}
        />
      </Form>
    </div>
  );
}

SectionPositioning.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  rack: PropTypes.shape({
    arrangement: PropTypes.bool,
    id: PropTypes.string,
    ManufacturerId: PropTypes.string,
    offsetFrom: PropTypes.bool,
    orientation: PropTypes.bool,
    rotation: PropTypes.number,
    originX: PropTypes.number,
    originY: PropTypes.number,
    depth: PropTypes.number,
    relative: PropTypes.bool,
    offsetDirection: PropTypes.string, // Local variable
  }),
  // eslint-disable-next-line react/forbid-prop-types
  racks: PropTypes.array,
  depth: PropTypes.number,
  backToBack: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  areas: PropTypes.array,
  compareRack: PropTypes.shape({}),
  onChangeCompareRack: PropTypes.func.isRequired,
  onChangeBackToBack: PropTypes.func.isRequired,
};
