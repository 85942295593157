import { Container, Graphics, Text } from "@pixi/react";

import PropTypes from "prop-types";
import { memo, useCallback, useMemo, useState } from "react";

// eslint-disable-next-line no-unused-vars
import { SCALE_FACTOR } from "hooks/use-site-blueprint";

function DamageIndicator({ id, x, y, text, color, onclick = () => {}, spot }) {
  const [hovered, setHovered] = useState(false);
  const circleSize = hovered ? 300 * SCALE_FACTOR : 200 * SCALE_FACTOR;

  const textStyle = useMemo(
    () => ({
      align: "center",
      fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
      fontSize: circleSize,
      fontWeight: 400,
      wordWrap: true,
      wordWrapWidth: 440 * SCALE_FACTOR,
      fill: 0xffffff,
    }),
    [circleSize],
  );

  const draw = useCallback(
    (g) => {
      g.clear();
      g.beginFill(color, hovered ? 1 : 0.66);
      g.drawCircle(0, 0, circleSize);
    },
    [color, hovered, circleSize],
  );

  const handleClick = useCallback(() => onclick(id, spot), [onclick, id, spot]);
  return (
    <Container
      cursor="pointer"
      eventMode="static"
      onclick={handleClick}
      pointerdown={onclick}
      pointerout={() => setHovered(false)}
      pointerover={() => setHovered(true)}
      x={x}
      y={y}
      zIndex="9999"
    >
      <Graphics draw={draw} />
      <Text anchor={0.5} style={textStyle} text={text} x={0} y={0} />
    </Container>
  );
}

DamageIndicator.propTypes = {
  color: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onMouseDown: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  x: PropTypes.number,
  y: PropTypes.number,
  onclick: PropTypes.func,
  spot: PropTypes.string,
  id: PropTypes.string, // Used as a string is stable, so we can memo calls further up
};

export default memo(DamageIndicator);
