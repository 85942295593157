import styled from "@emotion/styled";
import PropTypes from "prop-types";

import { useBeamTemplateTypes, useColours } from "hooks/types";

import { updateBeamTemplates } from "../helpers";

import AddBeam from "./AddBeam";
import AddedBeams from "./AddedBeams";

const AddBeamsWrapper = styled.div`
  background-color: #30333c;
  padding: 5px 20px;
`;

export default function BeamsSection({
  bayTemplate,
  setErrorMessage,
  setBayTemplate,
}) {
  const filters = {
    showBeamColours: true,
    showFrameColours: false,
    SeriesId: bayTemplate.SeriesId,
  };

  const { colours } = useColours(bayTemplate.ManufacturerId, filters);
  const { beamTemplateTypes } = useBeamTemplateTypes(
    bayTemplate.ManufacturerId,
    filters,
  );

  return (
    <AddBeamsWrapper>
      <AddBeam
        ManufacturerId={bayTemplate.ManufacturerId}
        SeriesId={bayTemplate.SeriesId}
        beamTemplateTypes={beamTemplateTypes}
        colours={colours}
        disabled={
          !bayTemplate.depth || !bayTemplate.width > 0 || !bayTemplate.SeriesId
        }
        onAddNewBeamToBay={(fields, numberToAdd = 1) => {
          setBayTemplate({
            ...bayTemplate,
            beamTemplates: updateBeamTemplates(
              bayTemplate,
              fields,
              numberToAdd,
            ),
          });
        }}
      />
      <AddedBeams
        beamTemplateTypes={beamTemplateTypes}
        beamTemplates={bayTemplate?.beamTemplates}
        colours={colours}
        disabled={!bayTemplate.SeriesId}
        onError={setErrorMessage}
        updateBeamTemplate={(id, index, fields) => {
          // If we have deleted a not-yet-saved template, just remove it
          if (!id && fields.deleted) {
            setBayTemplate({
              ...bayTemplate,
              beamTemplates: [
                ...bayTemplate.beamTemplates.slice(0, index),
                ...bayTemplate.beamTemplates.slice(index + 1),
              ],
            });
          } else {
            setBayTemplate({
              ...bayTemplate,
              beamTemplates: [
                ...bayTemplate.beamTemplates.slice(0, index),
                { ...bayTemplate.beamTemplates[index], ...fields },
                ...bayTemplate.beamTemplates.slice(index + 1),
              ],
            });
          }
        }}
      />
    </AddBeamsWrapper>
  );
}

BeamsSection.propTypes = {
  bayTemplate: PropTypes.shape({
    SeriesId: PropTypes.string,
    ManufacturerId: PropTypes.string,
    beamTemplates: PropTypes.arrayOf(PropTypes.shape({})),
    depth: PropTypes.number,
    width: PropTypes.number,
  }),
  setErrorMessage: PropTypes.func.isRequired,
  setBayTemplate: PropTypes.func.isRequired,
};
