/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Panel } from "react-bootstrap";

export default function SettingsPanel({ children }) {
  return (
    <Panel
      css={css`
        font-size: 12px;
        border-radius: 5px;
        .panel-heading {
          background-color: #494c55;
          color: #ffffff;
        }
        .panel-body {
          color: #494c55;
          background-color: #ffffff;
          padding-left: 0px;
          padding-right: 0px;
          padding-bottom: 9px;
          border-radius: 5px;
        }
        .form-control {
          background-color: #f2f2f4;
          box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
          width: 100%;
          margin-bottom: 15px;
          color: #494c55;
        }
        .col-sm-6 {
          padding: 0px;
        }
      `}
      header="Settings"
    >
      {children}
    </Panel>
  );
}

SettingsPanel.propTypes = {
  children: PropTypes.node,
};
