import { Container } from "@pixi/react";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import BayLabel from "views/components/Blueprint/canvas-elements/bay-label";
import DirectionIndicator from "views/components/Blueprint/canvas-elements/direction-indicator";
import RackBase from "views/components/Blueprint/canvas-elements/rack-base";
import RackLabels from "views/components/Blueprint/canvas-elements/rack-labels";

function RackComponent({ rack: r, fullOpacity = true, children }) {
  const memoBayLabels = useMemo(() => {
    return r.bays
      .sort((a, b) => b.order - a.order)
      .map((b) => {
        return (
          <Container cullable eventMode="auto" key={b.id} x={b.offset} y={0}>
            <BayLabel
              bayDepth={r.totalY}
              bayTemplateName={b.bayTemplate?.name}
              bayWidth={b.width}
              location={b.location}
            />
          </Container>
        );
      });
  }, [r.bays, r.totalY]);

  if (!r) return;

  return (
    <Container
      alpha={fullOpacity ? 1 : 0.5}
      angle={r.rotation}
      eventMode="auto"
      key={r.id}
      position={[r.originX, r.originY]}
    >
      <RackBase depth={r.totalY} width={r.totalX} />
      <RackLabels
        depth={r.totalY}
        name={r.name}
        rotation={r.rotation}
        width={r.totalX}
      />
      {memoBayLabels}
      {children}
      <DirectionIndicator
        flip={r.orientation}
        totalX={r.totalX}
        totalY={r.totalY}
      />
    </Container>
  );
}

RackComponent.propTypes = {
  rack: PropTypes.shape({
    bays: PropTypes.arrayOf(
      PropTypes.shape({
        FrameTemplateL: PropTypes.shape({
          depth: PropTypes.number.isRequired,
          width: PropTypes.number.isRequired,
        }).isRequired,
        FrameTemplateR: PropTypes.shape({
          depth: PropTypes.number.isRequired,
          width: PropTypes.number.isRequired,
        }).isRequired,
        id: PropTypes.string.isRequired,
        location: PropTypes.number.isRequired,
        offset: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
      }),
    ).isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    orientation: PropTypes.bool.isRequired,
    originX: PropTypes.number.isRequired,
    originY: PropTypes.number.isRequired,
    totalX: PropTypes.number.isRequired,
    totalY: PropTypes.number.isRequired,
    rotation: PropTypes.number.isRequired,
  }).isRequired,
  children: PropTypes.node,
  fullOpacity: PropTypes.bool,
};

export default React.memo(RackComponent);
