/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

function getSaveButtonText(isEdit, isSaving) {
  if (isEdit && isSaving) return "Saving";
  if (isEdit) return "Save";

  if (isSaving) return "Adding User...";
  return "Save & Add";
}

/**
 * Simple controls UI
 */
function SaveControls({
  onClickCancel,
  onClickSave,
  isEdit,
  isSaving,
  disableSave,
}) {
  return (
    <div
      css={css`
        float: right;
        button {
          width: 125px;
        }
      `}
    >
      <Button className="cancel" onClick={onClickCancel}>
        Cancel
      </Button>
      <Button disabled={disableSave} onClick={onClickSave}>
        {getSaveButtonText(isEdit, isSaving)}
      </Button>
    </div>
  );
}

SaveControls.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  isSaving: PropTypes.bool,
  disableSave: PropTypes.bool,
};
export default SaveControls;
