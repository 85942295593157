import { useCallback, useState } from "react";
import { Alert, Checkbox } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useClient, useClientSiteGroups } from "hooks/client";

import { addSite, createSiteGroup } from "libs/api";
import ButtonWide from "views/components/Buttons/button-wide";
import LinkButtonWide from "views/components/Buttons/link-button-wide";
import PageWrapper from "views/components/common/PageWrapper";
import TableHeader from "views/components/Tables/TableHeader";
import SiteAddressPanel from "views/pages/SitesEditor/components/site-address-panel";
import SiteDetailsPanel from "views/pages/SitesEditor/components/site-details-panel";

import { ControlsWrapper } from "./styles";

export default function PageAddSite() {
  const { ClientId } = useParams();
  const navigate = useNavigate();
  const { client } = useClient(ClientId);
  const { groups } = useClientSiteGroups(ClientId);
  const [siteFields, setSiteFields] = useState({});
  const [error, setError] = useState(null);
  const handleSave = useCallback(async () => {
    let { SiteGroupId } = siteFields;
    try {
      if (SiteGroupId === "new-group") {
        const newSiteGroup = await createSiteGroup(
          ClientId,
          siteFields.groupName,
        );
        SiteGroupId = newSiteGroup.id;
      }

      if (SiteGroupId === "no-group") {
        SiteGroupId = null;
      }

      const newSite = await addSite({
        ...siteFields,
        SiteGroupId,
        ClientId,
      });

      toast(`Added site: ${newSite.name}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        type: toast.TYPE.SUCCESS,
        autoClose: true,
        pauseOnHover: true,
      });

      navigate(`/clients/sites/${newSite.id}/overview`);
    } catch (error) {
      setError(error.message);
    }
  }, [ClientId, navigate, siteFields]);

  const fillSiteFromClient = useCallback(() => {
    setSiteFields((f) => ({
      ...f,
      contactName: client.contactName,
      contactNumber: client.contactNumber,
      contactEmail: client.contactEmail,
      addressLine1: client.addressLine1,
      addressLine2: client.addressLine2,
      city: client.city,
      postcode: client.postcode,
      country: client.country,
    }));
  }, [client]);

  const isDisabled =
    !siteFields.name ||
    !siteFields.contactNumber ||
    !siteFields.contactEmail ||
    !siteFields.addressLine1 ||
    !siteFields.city ||
    !siteFields.postcode;

  return (
    <PageWrapper>
      <Helmet>
        <title>Econform / Sites / Add</title>
      </Helmet>
      <TableHeader left="Add Site" />

      <div>
        <Checkbox onChange={(e) => e.target.checked && fillSiteFromClient()}>
          {" "}
          Fill details from client
        </Checkbox>
      </div>

      <SiteDetailsPanel
        groups={groups}
        onChangeField={(id, val) => setSiteFields((f) => ({ ...f, [id]: val }))}
        site={siteFields}
      />

      <SiteAddressPanel
        onChangeField={(id, val) => setSiteFields((f) => ({ ...f, [id]: val }))}
        site={siteFields}
      />

      <ControlsWrapper>
        <LinkButtonWide to={`/clients/${ClientId}/sites`}>
          Cancel
        </LinkButtonWide>
        <ButtonWide disabled={isDisabled} onClick={handleSave}>
          Add
        </ButtonWide>
      </ControlsWrapper>

      {error ? <Alert bsStyle="danger">{error}</Alert> : null}
    </PageWrapper>
  );
}
