import PropTypes from 'prop-types';

import { FormGroup, Col, Row, ControlLabel } from 'react-bootstrap';

import UserSearch from '../../UserSearch';

/**
 * Form Control for ScheduleEditor that allows the user to pick an assigned user
 */
export default function ControlUser({
  filters,
  assignee,
  assigneeId,
  user,
  onChangeUser,
  onClearUser,
  groupOptions,
}) {
  return (
    <FormGroup controlId="formUser">
      <Row sm={12}>
        <Col sm={12}>
          <Col sm={12}>
            <ControlLabel>Assigned User</ControlLabel>
          </Col>
          <Col sm={12}>
            <UserSearch
              filters={filters}
              groupOptions={groupOptions}
              isEditSchedule
              onChangeUser={onChangeUser}
              onClearUser={onClearUser}
              selectedUser={
                (assignee && assignee.id === 'self') || (!assignee && assigneeId === 'self')
                  ? user
                  : assignee
              }
              style={{ height: 1000 }}
            />
          </Col>
        </Col>
      </Row>
    </FormGroup>
  );
}

ControlUser.defaultProps = {
  filters: {},
  assignee: null,
  assigneeId: null,
  user: null,
};

ControlUser.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  assignee: PropTypes.object,
  assigneeId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  onChangeUser: PropTypes.func.isRequired,
  onClearUser: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  groupOptions: PropTypes.object,
};
