/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";

import AutoCompleteSearch from "views/components/common/AutoCompleteSearch";

export default function Option({
  title,
  options,
  changeFunc,
  labelKey = "name",
  setSearchText,
  searchText,
}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      css={css`
        padding: 10px;
        padding-bottom: 10px;
        color: #6c6c6c;
        .form-control {
          background-color: #f2f2f4 !important;
          color: #9b9b9c !important;
          border-radius: 5px;
          margin-bottom: 5px;
          margin-top: 10px;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
        }
      `}
    >
      <div onClick={() => setExpanded(!expanded)}>
        {title}
        <span
          css={css`
            float: right;
          `}
        >
          <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
        </span>
      </div>
      {expanded && (
        <div>
          <AutoCompleteSearch
            labelKey={labelKey}
            onChangeSearchText={(e) => {
              setSearchText(e.target.value);
            }}
            onClear={() => {
              setSearchText("");
            }}
            onSelectOption={(values) => {
              setSearchText("");
              changeFunc(values);
            }}
            options={options}
            placeholder={`${title} Name`}
            searchText={searchText}
          />
          <p
            css={css`
              font-size: 12px;
            `}
          >
            Filter by {title} by searching for them in the above search area and
            adding them.
          </p>
        </div>
      )}
    </div>
  );
}

Option.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeFunc: PropTypes.func.isRequired,
  labelKey: PropTypes.string,
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};
