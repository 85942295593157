/* eslint-disable no-console */
import { mutate } from "swr";

import { apiCall, filterUrlGenerator } from "libs/api/helpers";

export async function editClient(client) {
  const res = await apiCall("/client", {
    method: "PUT",
    body: JSON.stringify(client),
  });
  if (res.ok) {
    return res.json();
  }

  throw new Error(`Problem editing client: ${res.status}`);
}

export async function addClient(client) {
  const res = await apiCall("/client", {
    method: "POST",
    body: JSON.stringify(client),
  });
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem adding client: ${res.status}`);
}

export async function deleteClient(ClientId) {
  const res = await apiCall(`/client/${ClientId}`, {
    method: "DELETE",
  });
  if (res.ok) {
    mutate(`/clients`);
    return;
  }
  throw new Error(`Problem deleting client: ${res.status}`);
}

export async function getClientsGroups(ClientId) {
  const res = await apiCall(`/client/groups/${ClientId}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem getting client's groups: ${res.status}`);
}

export async function createSiteGroup(ClientId, name, SiteId = null) {
  const res = await apiCall("/client/groups", {
    method: "POST",
    body: JSON.stringify({ ClientId, name, SiteId }),
  });
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem creating client's groups: ${res.status}`);
}

export async function editSiteGroup(SiteGroupId, name) {
  const res = await apiCall("/client/groups", {
    method: "PUT",
    body: JSON.stringify({ SiteGroupId, name }),
  });
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem creating client's groups: ${res.status}`);
}

export async function deleteSiteGroup(SiteGroupId) {
  const res = await apiCall(`/client/groups/${SiteGroupId}`, {
    method: "DELETE",
  });
  if (res.ok) {
    return res;
  }
  console.error(`Problem deleting client's groups: ${res.status}`);
  return false;
}

export async function getClients(filters) {
  const res = await apiCall(`/client${filterUrlGenerator(filters)}`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem getting clients: ${res.status}`);
}

export async function getClient(ClientId) {
  const res = await apiCall(`/client/${ClientId}`);
  if (res.ok) {
    return res.json();
  }
  console.error(`Problem getting clients: ${res.status}`);
  return false;
}

export async function getClientsToVerify() {
  const res = await apiCall("/client/toVerify", {
    method: "GET",
  });
  if (res.ok) {
    return res.json();
  }
  throw new Error(`Problem getting clients to verify: ${res.status}`);
}
