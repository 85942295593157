import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Wrap = styled.div`
  align-items: center;
  background-color: ${({ darkMode }) => (darkMode ? "#4A4C55" : "#00000000")};
  display: flex;
  height: ${({ height }) => height};
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  flex-direction: column;
  width: 100%;
`;

export default function ErrorDisplay({ text = "Something went wrong" }) {
  return (
    <Wrap role="alert">
      <p>{text}</p>
    </Wrap>
  );
}
ErrorDisplay.propTypes = {
  text: PropTypes.string,
};
