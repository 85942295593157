import PropTypes from "prop-types";
import { FormGroup, Col } from "react-bootstrap";

import {
  ControlLabelWithLoading,
  StyledFormControl,
} from "views/components/BuildModeSidebar/SharedControls/Generic";

export default function AbsolutePositioning({
  depth,
  ManufacturerId,
  onUpdate,
  originX,
  originY,
}) {
  return (
    <>
      <FormGroup>
        <Col sm={4}>
          <ControlLabelWithLoading>Origin X</ControlLabelWithLoading>
        </Col>
        <Col sm={8}>
          <StyledFormControl
            disabled={!ManufacturerId || !depth}
            onChange={(e) => onUpdate({ originX: e.target.value })}
            type="number"
            value={originX}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Col sm={4}>
          <ControlLabelWithLoading>Origin Y</ControlLabelWithLoading>
        </Col>
        <Col sm={8}>
          <StyledFormControl
            disabled={!ManufacturerId || !depth}
            onChange={(e) => onUpdate({ originY: e.target.value })}
            type="number"
            value={originY}
          />
        </Col>
      </FormGroup>
    </>
  );
}

AbsolutePositioning.propTypes = {
  depth: PropTypes.number,
  ManufacturerId: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  originX: PropTypes.number,
  originY: PropTypes.number,
};
