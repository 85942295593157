import styled from "@emotion/styled";
import { Button, ButtonGroup, Image } from "react-bootstrap";

export const Previous = styled.div`
  // TODO: Choose appropriate colour
  background-color: #f5f5f5;
  text-align: center;
  padding: 15px;
  // TODO: Choose appropriate colour
  border-bottom: 1px solid #ddd;
  font-weight: 700;
  line-height: 2;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px 0px;
`;

export const StyledButton = styled(Button)`
  width: 150px;
  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    background-color: #7e8496;
    color: #ffffffaa;
  }
`;

export const CancelButton = styled(StyledButton)`
  background-color: #7e8496;
`;

/**
 * Small thumbnails for the uploader
 */
export const StyledImage = styled(Image)`
  width: 50px !important;
  height: 50px !important;
  margin: 0 5px;
`;
