/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom"; // compat

import { useClient } from "hooks/client";
import { usePermission } from "hooks/permission";
import { useSites } from "hooks/site";
import { FiltersProvider, FILTER_KEYS, useFilters } from "hooks/useFilter";
import { useCurrentUser } from "hooks/user";

import PageWrapper from "views/components/common/PageWrapper";
import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import FilterOptionsAndChips from "views/components/FilterOptionsAndChips";
import PageButtons from "views/components/Tables/PageButtons";
import { Table } from "views/components/Tables/styles";
import TableHeader from "views/components/Tables/TableHeader";

import SiteRow from "./SiteRow";

const amount = 10;

function PageSites() {
  const { ClientId: UrlClientId } = useParams();
  const [clientId, setClientId] = useState(null);
  const [page, setPage] = useState(0);
  const { searchText } = useFilters();
  const { client } = useClient(UrlClientId ?? clientId);
  const {
    sites,
    isLoading,
    total: totalSites,
  } = useSites({
    ClientId: UrlClientId ?? clientId,
    searchText,
    page,
    amount,
  });

  // Reset the page to 0 when the search text changes
  useEffect(() => {
    setPage(0);
  }, [searchText, clientId]);

  const navigate = useNavigate();
  const { permission } = usePermission("client", UrlClientId ?? clientId);
  const { user } = useCurrentUser();
  const totalPages = Math.ceil(totalSites / amount);
  return (
    <PageWrapper>
      <Helmet>
        <title>
          Econform / {UrlClientId && client ? `${client?.name} / ` : ""} Sites
        </title>
      </Helmet>
      <div
        css={{
          height: 40,
          marginTop: 20,
          marginBottom: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FilterOptionsAndChips
          ClientId={UrlClientId}
          hideFilterOptions
          onClientIconPress={
            !UrlClientId
              ? () => {
                  setClientId(null);
                }
              : undefined
          }
          searchText={searchText}
          showSearch
        />

        {(permission.level.includes("organisation") ||
          (permission.level.includes("clientAdmin") &&
            user.userType !== "PRRS")) && (
          <span className="action-button-right">
            <Button
              css={{
                height: 40,
                width: 125,
                padding: "6px 12px",
                margin: 0,
              }}
              onClick={() =>
                navigate(`${clientId ? `/clients/${clientId}/` : ""}add`)
              }
            >
              New Site
            </Button>
          </span>
        )}
      </div>
      <TableHeader
        largeLeftTagline="List of all sites"
        largeLeftTitle="Sites"
        right={`Showing ${sites.length} of ${totalSites || 0} Sites`}
      />
      <Table>
        <thead>
          <tr>
            <th>Site Name</th>
            <th>Address</th>
            <th>Contact Name</th>
            <th>Contact Number</th>
            <th>Warehouses</th>
            <th>Site Status</th>
          </tr>
        </thead>
        <tbody>
          {sites.map((s) => (
            <SiteRow key={s.id} permission={permission} site={s} user={user} />
          ))}
        </tbody>
      </Table>
      {sites.length === 0 ? (
        <EmptyTableIndicator isLoading={isLoading} searchable type="Sites" />
      ) : null}
      <PageButtons
        curPage={page}
        onChangePage={setPage}
        totalPages={totalPages}
      />
    </PageWrapper>
  );
}

/**
 * A sites list page can only appear as part of a client. This component
 * wraps the sites page in a context that controls the ClientId.
 */
function InspectionPageInURLContext() {
  const { ClientId: urlClientId } = useParams();
  const controlledValues = {
    [FILTER_KEYS.clientId]: urlClientId,
  };
  const enabledFilters = [FILTER_KEYS.clientId];
  return (
    <FiltersProvider
      controlledValues={controlledValues}
      enabledFilters={enabledFilters}
    >
      <PageSites />
    </FiltersProvider>
  );
}

export default InspectionPageInURLContext;
