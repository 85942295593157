import { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import {
  useMySchedules,
  useMissedSchedules,
  useManagedSchedules,
} from "hooks/schedules";
import { FiltersProvider, FILTER_KEYS, useFilters } from "hooks/useFilter";
import { useCurrentUser } from "hooks/user";
import { isAdmin } from "libs/permissions";

import PageWrapper from "views/components/common/PageWrapper";
import UnviewedIndicator from "views/components/common/UnviewedIndicator";
import FlatModal from "views/components/Modals/FlatModal";
import { ScheduledInspectionStarter as InspectionStarter } from "views/components/Modals/InspectionStarter";
import ScheduleEditor from "views/components/Modals/ScheduleEditor";
import PageButtons from "views/components/Tables/PageButtons";
import { TableControlsWrapper } from "views/components/Tables/TableControls";
import TableHeader from "views/components/Tables/TableHeader";
import TableTabs from "views/components/Tables/TableTabs";

import Controls from "./Controls";
import ManageSchedulesTab from "./ManageSchedulesTab";
import { MiniFilter } from "./MiniFilter";
import MissedSchedulesTab from "./MissedSchedulesTab";
import MySchedulesTab from "./MySchedulesTab";

const MY_SCHEDULES_TAB = 0;
const MY_MANAGED_SCHEDULES_TAB = 1;
const MY_MISSED_SCHEDULES_TAB = 2;
const PAGE_SIZE = 10;

function PageSchedules() {
  const { user } = useCurrentUser();

  const {
    TabIndex: urlTabIndex,
    ClientId: urlClientId,
    SiteId: urlSiteId,
  } = useParams();

  const [tabSelected, setTabSelected] = useState(
    parseInt(urlTabIndex, 10) === 2 ? 1 : 0,
  );

  const { clientId, client, siteId, site, searchText, setSiteId } =
    useFilters();

  const [allSchedulesSelected, setAllSchedulesSelected] = useState(
    parseInt(urlTabIndex, 10) === 1 || parseInt(urlTabIndex, 10) === 2,
  );
  const [page, setPage] = useState(0);

  const [showScheduleEditor, setShowScheduleEditor] = useState(false);
  const [startScheduleId, setStartScheduleId] = useState(null);
  const [startScheduledInspectionId, setStartScheduledInspectionId] =
    useState(null);

  const userIsAdmin = user ? isAdmin(user) : null;

  const searchFilters = useMemo(
    () => ({
      ClientId: clientId || urlClientId,
      SiteId: siteId || urlSiteId,
      allSchedules: allSchedulesSelected,
      page,
      amount: PAGE_SIZE,
      searchText,
    }),
    [
      clientId,
      urlClientId,
      siteId,
      urlSiteId,
      allSchedulesSelected,
      searchText,
      page,
    ],
  );

  /**
   * Data Loading Hooks
   */
  const {
    mySchedules,
    activeSchedules,
    isLoading: isMySchedulesLoading,
    totalAll,
    mutate: refreshMy,
  } = useMySchedules(searchFilters);
  const {
    missedSchedules,
    isLoading: isLoadingMissed,
    total: totalMissed,
    mutate: refreshMissed,
  } = useMissedSchedules(searchFilters);
  const {
    managedSchedules,
    isLoading: isLoadingManaged,
    total: totalManaged,
    mutate: refreshManaged,
  } = useManagedSchedules(searchFilters);
  const { user: currentUser, mutate: refreshUser } = useCurrentUser();

  const tabTitles = ["Schedules", "Manage Schedules"];
  if ((user && user.userType === "PRRS") || userIsAdmin) {
    tabTitles.push(
      <span>
        Missed Inspections{" "}
        <UnviewedIndicator
          amount={currentUser?.userMissedInspections?.length ?? 0}
          maxAmount={99}
        />
      </span>,
    );
  }

  /**
   * Pagination
   */
  let totalPages = 0;
  switch (tabSelected) {
    case MY_SCHEDULES_TAB:
      totalPages = Math.ceil(totalAll / PAGE_SIZE);
      break;
    case MY_MANAGED_SCHEDULES_TAB:
      totalPages = Math.ceil(totalManaged / PAGE_SIZE);
      break;
    default:
      totalPages = Math.ceil(totalMissed / PAGE_SIZE);
      break;
  }
  return (
    <PageWrapper>
      <Helmet>
        <title>
          {`Econform / ${client ? `${client.name} / ` : ""} ${
            site ? `${site.name} / ` : ""
          } Schedules`}
        </title>
      </Helmet>
      <Controls
        setShowScheduleEditor={setShowScheduleEditor}
        showNewScheduleButton={tabSelected === MY_MANAGED_SCHEDULES_TAB}
      />
      {tabSelected === MY_SCHEDULES_TAB && (
        <TableHeader
          largeLeftTagline={
            allSchedulesSelected
              ? "List of all schedules"
              : "List of scheduled inspections assigned to you"
          }
          largeLeftTitle={
            !allSchedulesSelected ? "My Schedules" : "All Schedules"
          }
          right={
            isMySchedulesLoading || !activeSchedules
              ? ""
              : `Showing ${
                  activeSchedules ? activeSchedules.length : 0
                } of ${totalAll} Inspections`
          }
        />
      )}
      {tabSelected === MY_MANAGED_SCHEDULES_TAB && (
        <TableHeader
          largeLeftTagline="Create and edit schedules"
          largeLeftTitle={
            !allSchedulesSelected
              ? "My Managed Schedules"
              : "All Managed Schedules"
          }
          right={
            isLoadingManaged || !managedSchedules
              ? ""
              : `Showing ${
                  managedSchedules ? managedSchedules.length : 0
                } of ${totalManaged} Inspections`
          }
        />
      )}
      {tabSelected === MY_MISSED_SCHEDULES_TAB && (
        <TableHeader
          largeLeftTagline="List of missed inspections"
          largeLeftTitle={
            !allSchedulesSelected
              ? "My Missed Inspections"
              : "All Missed Inspections"
          }
          right={
            isLoadingMissed || !missedSchedules
              ? ""
              : `Showing ${
                  missedSchedules ? missedSchedules.length : 0
                } of ${totalMissed} Inspections`
          }
        />
      )}
      <TableControlsWrapper>
        <TableTabs
          initialTab={parseInt(urlTabIndex, 10) === 2 ? 1 : 0}
          onSelectKey={(key) => {
            if (key !== tabSelected) {
              setPage(0);
              setTabSelected(key);
            }
          }}
          tabSelected={tabSelected}
          titles={tabTitles}
          width="49%"
        />
        <MiniFilter
          allSchedulesSelected={allSchedulesSelected}
          setAllSchedulesSelected={setAllSchedulesSelected}
          setPage={setPage}
          tabSelected={tabSelected}
        />
      </TableControlsWrapper>
      <>
        {tabSelected === MY_SCHEDULES_TAB && (
          <MySchedulesTab
            client={client}
            isLoading={isMySchedulesLoading}
            onScheduledInspectionStart={(scheduleId, scheduledInspectionId) => {
              setSiteId(siteId);
              setStartScheduleId(scheduleId);
              setStartScheduledInspectionId(scheduledInspectionId);
            }}
            page={page}
            pageSize={PAGE_SIZE}
            scheduledInspections={activeSchedules}
            schedules={mySchedules}
            selectedClientId={clientId}
            selectedSiteId={siteId}
            showScheduleEditor={showScheduleEditor}
            site={site}
          />
        )}
        {tabSelected === MY_MANAGED_SCHEDULES_TAB && (
          <ManageSchedulesTab
            client={client}
            isLoading={isLoadingManaged}
            onScheduleDeleted={() => {
              refreshManaged();
              refreshMy();
            }}
            onSchedulePauseResume={() => {
              refreshManaged();
              refreshMy();
            }}
            schedules={managedSchedules}
            selectedClientId={clientId}
            selectedSiteId={siteId}
            site={site}
            urlClientId={urlClientId}
            urlSiteId={urlSiteId}
          />
        )}
        {tabSelected === MY_MISSED_SCHEDULES_TAB && (
          <MissedSchedulesTab
            isAllMissed={allSchedulesSelected}
            isLoading={isLoadingMissed}
            refreshMissed={() => {
              refreshMissed();
              refreshUser();
            }}
            schedules={missedSchedules}
          />
        )}
        <PageButtons
          curPage={page}
          onChangePage={setPage}
          totalPages={totalPages}
        />
      </>
      {startScheduleId && (
        <InspectionStarter
          onHide={() => setStartScheduleId(null)}
          startScheduleId={startScheduleId}
          startScheduledInspectionId={startScheduledInspectionId}
        />
      )}
      <FlatModal
        closeButton
        onHide={() => {
          setShowScheduleEditor(false);
        }}
        show={showScheduleEditor}
        title="Add Schedule"
      >
        <ScheduleEditor
          Client={client}
          ClientId={urlClientId || clientId}
          SiteId={urlSiteId || siteId}
          clientSelectorDisabled={!!urlClientId}
          onHide={() => {
            setShowScheduleEditor(false);
          }}
          onSaveSuccessful={() => {
            setShowScheduleEditor(false);
            refreshManaged();
            refreshMy();
          }}
          siteSelectorDisabled={!!urlSiteId}
        />
      </FlatModal>
    </PageWrapper>
  );
}

/**
 * A schedules page can be the global one, as part of a client, or as part of a
 * site. This wrapping component provides the filter context for the page.
 */
function SchedulesPageInURLContext() {
  const { ClientId: urlClientId, SiteId: urlSiteId } = useParams();

  const controlledValues = {
    [FILTER_KEYS.clientId]: urlClientId,
    [FILTER_KEYS.siteId]: urlSiteId,
  };

  const enabledFilters = [
    !urlClientId && !urlSiteId && FILTER_KEYS.clientId, // Don't allow changes when client or site Id is specified
    !urlSiteId && FILTER_KEYS.siteId,
  ].filter(Boolean);

  return (
    <FiltersProvider
      controlledValues={controlledValues}
      enabledFilters={enabledFilters}
      key={`${urlClientId}_${urlSiteId}`}
    >
      <PageSchedules />
    </FiltersProvider>
  );
}

export default SchedulesPageInURLContext;
