import { Container, Graphics } from "@pixi/react";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";

function computeDimensions(x, x2, y, y2) {
  const newX = Math.min(x, x2);
  const newY = Math.min(y, y2);
  const width = Math.abs(x2 - x);
  const height = Math.abs(y2 - y);

  return {
    x: newX,
    y: newY,
    width: width,
    height: height,
  };
}

/**
 * The zoom select component allows the user to select an area of the blueprint,
 * which the viewport will then zoom to.
 *
 * This component is responsible for tracking the selection box, and drawing it
 * on the canvas. When the user clicks a second time then the zoom function is
 * called.
 *
 * Note: the viewport pan will be paused when this component is active.
 */
export default function ZoomSelect({ height, width, zoomTo }) {
  const [selectBox, setSelectBox] = useState(null);

  const draw = useCallback(
    (g) => {
      g.clear();
      if (selectBox) {
        const dimensions = computeDimensions(
          selectBox.x,
          selectBox.x2,
          selectBox.y,
          selectBox.y2,
        );
        g.beginFill(0x00ff00, 0.3);
        g.drawRect(
          dimensions.x,
          dimensions.y,
          dimensions.width,
          dimensions.height,
        );
        g.endFill();
      }
    },
    [selectBox],
  );

  const drawFull = useCallback(
    (g) => {
      g.clear();
      // Must have a fill in order to capture events
      g.beginFill(0x00ff00, 0.01);
      g.drawRect(0, 0, width, height);
      g.endFill();
    },
    [height, width],
  );

  const handleZoom = useCallback(() => {
    const width = selectBox.x2 - selectBox.x;
    const height = selectBox.y2 - selectBox.y;
    const x = selectBox.x + width / 2;
    const y = selectBox.y + height / 2;

    zoomTo(x, y, width, height);
    setSelectBox(null);
  }, [selectBox, zoomTo]);

  // Shouldn't select if we can't zoom
  if (!zoomTo) return null;

  return (
    <Container
      eventMode="static"
      onpointerdown={(e) => {
        const sprite = e.currentTarget;
        const position = e.data.getLocalPosition(sprite.parent);
        const { x, y } = position;

        // Click to start, click to end
        if (!selectBox) {
          setSelectBox({
            x,
            y,
            y2: y,
            x2: x,
          });
        } else {
          handleZoom();
        }
      }}
      onpointermove={(e) => {
        if (selectBox) {
          const sprite = e.currentTarget;
          const position = e.data.getLocalPosition(sprite.parent);
          setSelectBox({
            x: selectBox.x,
            y: selectBox.y,
            x2: position.x,
            y2: position.y,
          });
        }
      }}
    >
      <Graphics
        // A transparent rectangle to capture the pointer events
        // as containers wrap to the size of their children
        draw={drawFull}
      />
      <Graphics draw={draw} />
    </Container>
  );
}

ZoomSelect.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  zoomTo: PropTypes.func.isRequired,
};
