/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Component } from "react";

import { getUsers } from "libs/api";
import AutoCompleteSearch from "views/components/common/AutoCompleteSearch";

export default class UserSearch extends Component {
  state = {
    users: [],
    searchText: "",
  };

  componentDidMount() {
    this.getUsers(true);
  }

  async getUsers(initialLoad) {
    this.setState({ loading: true });
    const { filters, onChangeUser } = this.props;
    if (!initialLoad) {
      onChangeUser(null);
    }
    const { searchText } = this.state;
    const combinedFilters = Object.assign(filters, {
      searchText,
      amount: 1000,
    });
    const userResponse = await getUsers(combinedFilters);
    let users = userResponse.results;

    users = users.map((user) => {
      const installerType = user.userType === "InstallerAdmin" ? "Admin " : "";
      if (user.userType === "InstallerAdmin" || user.userType === "Installer") {
        user.name = `${installerType}${user.name} (${user.mainInstallerOrganisationName})`;
      }
      return user;
    });
    users = users.sort(
      (a, b) =>
        (a.userType === "InstallerAdmin") * -1 ||
        b.userType == "InstallerAdmin",
    );
    this.setState({ users, loading: false });
  }

  render() {
    const { selectedUser, onChangeUser, onClearUser, groupOptions } =
      this.props;
    const { users, searchText, loading } = this.state;
    return (
      <div
        css={css`
          width: 100%;
        `}
      >
        <AutoCompleteSearch
          groupOptions={groupOptions}
          loading={loading}
          onChangeSearchText={(newSearchText) =>
            this.setState({ searchText: newSearchText.target.value }, () =>
              this.getUsers(false),
            )
          }
          onClear={() => {
            this.setState({ searchText: "" }, () => this.getUsers(false));
            if (onClearUser) {
              onClearUser();
            }
          }}
          onSelectOption={(optionId) => {
            const user = users.find((curUser) => curUser.id === optionId);
            this.setState({ searchText: user.name }, () => onChangeUser(user));
          }}
          options={
            Array.isArray(users)
              ? users.map((user) => ({
                  id: user.id,
                  label: user.name,
                  userType:
                    user.userType === "InstallerAdmin"
                      ? "Installer"
                      : user.userType,
                }))
              : []
          }
          searchText={selectedUser ? selectedUser.name : searchText}
          selectedOptionId={selectedUser && selectedUser.id}
        />
      </div>
    );
  }
}

UserSearch.propTypes = {
  filters: PropTypes.shape({
    SiteId: PropTypes.string,
    ClientId: PropTypes.string,
  }),
  onChangeUser: PropTypes.func,
  onClearUser: PropTypes.func,
  selectedUser: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};
