import useSWR from "swr";

import { getOrganisations } from "libs/api";

export function useOrganisations(filters) {
  const { data, isLoading, error, mutate } = useSWR(
    ["/organisation", filters],
    async () => getOrganisations(filters),
  );

  return {
    organisations: data?.results ?? [],
    isLoading,
    isError: error,
    mutate,
  };
}
