import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useId } from "react";

import { StyledFormControl } from "views/components/BuildModeSidebar/SharedControls/Generic";

const Group = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const Label = styled.label`
  color: white;
  flex: 1;
  font-weight: bold;
  padding-right: 5px;
`;

const Control = styled.div`
  flex: 1;
  text-align: left;
  padding-left: 5px;
`;
export default function OffsetPositioning({ onUpdate, offset = 0 }) {
  const offsetId = useId();
  return (
    <>
      <Group>
        <Label htmlFor={offsetId}>Offset by</Label>
        <Control>
          <StyledFormControl
            id={offsetId}
            min={0}
            onChange={(e) =>
              onUpdate({
                offset: parseInt(e.target.value, 10),
              })
            }
            type="number"
            value={offset}
          />
        </Control>
      </Group>
    </>
  );
}

OffsetPositioning.propTypes = {
  offset: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
