import styled from "@emotion/styled";
import { DropdownButton, Button, Image } from "react-bootstrap";

import { getColor } from "./helpers";

export const StyledEvidenceImage = styled(Image)`
  margin-top: 15px;
  width: 80px;
  height: 80px;
  margin-right: 5px;
`;

export const StyledDropdownButton = styled(DropdownButton, {
  shouldForwardProp: (prop) =>
    prop !== "resolvedAt" &&
    prop !== "risk" &&
    prop !== "status" &&
    prop !== "classificationStatus",
})`
  span {
    padding: 0;
  }

  background-color: ${(props) => getColor(props.resolvedAt, props.risk)};
  &:disabled,
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => getColor(props.resolvedAt, props.risk)};
  }

  width: 150px;
  .caret {
    display: ${(props) =>
      props.status === "Completed" ||
      (props.status === "Unfinished" && props.classificationStatus === "Done")
        ? "none"
        : "inline-flex"};
  }
`;

export const ContinueActionButton = styled(Button)`
  background-color: #7e8496;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: 10px;
  .btn {
    width: 170px;
  }
`;
