import PropTypes from 'prop-types';
import { Button, ButtonGroup, Col, Row, ListGroupItem } from 'react-bootstrap';

export default function Area({ canEdit, name, numberOfRacks = 0, onClickEdit }) {
  return (
    <ListGroupItem header={name}>
      <Row>
        <Col className="item-information" sm={7}>
          <b>Racks:</b> {numberOfRacks}
        </Col>
        {canEdit && (
          <Col sm={5}>
            <ButtonGroup className="pull-right">
              <Button className="text-btn" onClick={onClickEdit}>
                Edit Area
              </Button>
            </ButtonGroup>
          </Col>
        )}
      </Row>
    </ListGroupItem>
  );
}

Area.propTypes = {
  name: PropTypes.string,
  numberOfRacks: PropTypes.number,
  canEdit: PropTypes.bool,
  onClickEdit: PropTypes.func,
};
