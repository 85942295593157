import PropTypes from "prop-types";
import { useEffect } from "react";
import { FormGroup, Col } from "react-bootstrap";

import { useManufacturerSeries } from "hooks/types";

import {
  ControlLabelWithLoading,
  StyledFormControl,
} from "../../SharedControls/Generic";

export default function Series({
  disabled,
  ManufacturerId,
  onChange,
  SeriesId,
  SiteId,
}) {
  const { series, isLoading } = useManufacturerSeries(ManufacturerId, {
    type: "frame",
    SiteId,
  });

  // If we only have one series returned then select it automatically
  useEffect(() => {
    if (series.length === 1 && series[0].id !== SeriesId) {
      onChange(series[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series]);

  const localDisabled =
    !(ManufacturerId && !isLoading && series.length > 0) || disabled;
  return (
    <FormGroup className={localDisabled ? "" : "greyed"}>
      <Col sm={6}>
        <ControlLabelWithLoading
          disabled={localDisabled}
          isLoading={isLoading}
          label="Series"
        />
      </Col>
      <Col sm={6}>
        <StyledFormControl
          componentClass="select"
          disabled={localDisabled}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Series"
          value={SeriesId ?? ""}
        >
          {!isLoading && series.length === 0 ? (
            <option disabled value="">
              No series found
            </option>
          ) : null}
          {series.length > 0 && (
            <option disabled value="">
              Select Series
            </option>
          )}
          {series.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </StyledFormControl>
      </Col>
    </FormGroup>
  );
}

Series.propTypes = {
  disabled: PropTypes.bool,
  ManufacturerId: PropTypes.string,
  /** Called with the SeriesId */
  onChange: PropTypes.func.isRequired,
  SeriesId: PropTypes.string,
  SiteId: PropTypes.string,
};
