import { Graphics } from "@pixi/react";

import PropTypes from "prop-types";
import { memo } from "react";

import { SCALE_FACTOR } from "hooks/use-site-blueprint";

const lineSize = 200 * SCALE_FACTOR;

/**
 * Direction indicator is a small arrow that shows the "front" of the rack.
 */
// eslint-disable-next-line no-unused-vars
function DirectionIndicator({ totalX, totalY, flip = false }) {
  if (!totalX || !totalY) return null;

  const centerX = totalX / 2;
  const triXBaseA = centerX - lineSize;
  const triXBaseB = centerX + lineSize;
  const triXPoint = centerX;

  const triYBase = flip ? totalY + lineSize / 2 : -lineSize / 2;
  const triYPoint = flip ? totalY + 1.5 * lineSize : -1.5 * lineSize;

  return (
    <Graphics
      cursor="pointer"
      draw={(g) => {
        g.clear();
        g.beginFill(0x337ab7);

        g.moveTo(triXBaseA, triYBase);
        g.lineTo(triXBaseB, triYBase);
        g.lineTo(triXPoint, triYPoint);

        g.endFill();
      }}
      eventMode="auto"
    />
  );
}
export default memo(DirectionIndicator);

DirectionIndicator.propTypes = {
  totalX: PropTypes.number.isRequired,
  totalY: PropTypes.number.isRequired,
  flip: PropTypes.bool,
};
