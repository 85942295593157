import PropTypes from "prop-types";
import {
  Col,
  ControlLabel,
  FormControl,
  Grid,
  Panel,
  Row,
} from "react-bootstrap";

import EditorPanel from "views/components/EditorPanel";

export default function ClientAddress({ client, onChangeField }) {
  return (
    <EditorPanel>
      <Panel.Heading>Client Address</Panel.Heading>
      <Panel.Body>
        <Grid fluid>
          <Row>
            <Col sm={6}>
              <ControlLabel>
                Address Line 1<span className="required">*</span>
              </ControlLabel>
              <FormControl
                onChange={(e) => onChangeField("addressLine1", e.target.value)}
                placeholder="Enter Address Line 1"
                value={client.addressLine1}
              />
            </Col>
            <Col sm={6}>
              <ControlLabel>Address Line 2</ControlLabel>
              <FormControl
                onChange={(e) => onChangeField("addressLine2", e.target.value)}
                placeholder="Enter Address Line 2"
                value={client.addressLine2}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <ControlLabel>
                City
                <span className="required">*</span>
              </ControlLabel>
              <FormControl
                onChange={(e) => onChangeField("city", e.target.value)}
                placeholder="Enter City"
                value={client.city}
              />
            </Col>
            <Col sm={6}>
              <ControlLabel>
                Postcode
                <span className="required">*</span>
              </ControlLabel>
              <FormControl
                onChange={(e) => onChangeField("postcode", e.target.value)}
                placeholder="Enter Postcode"
                value={client.postcode}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <ControlLabel>Country</ControlLabel>
              <FormControl
                onChange={(e) => onChangeField("country", e.target.value)}
                placeholder="Enter Country"
                value={client.country}
              />
            </Col>
          </Row>
        </Grid>
      </Panel.Body>
    </EditorPanel>
  );
}

ClientAddress.propTypes = {
  client: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    postcode: PropTypes.string,
  }),
  onChangeField: PropTypes.func.isRequired,
};
