import useSWR from "swr";

import { getManufacturers, getSeries } from "libs/api";

export function useManufacturers(filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    [`/manufacturers`, filters],
    async () => getManufacturers(filters),
  );
  return {
    manufacturers: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export function useSeries(ManufacturerId, filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    ManufacturerId
      ? [`/manufacturers/series/${ManufacturerId}`, filters]
      : null,
    async () => getSeries(ManufacturerId, filters),
  );

  return {
    series: data ?? [],
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}
