import { Container, Sprite, Text } from "@pixi/react";

import { Texture } from "pixi.js";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { SCALE_FACTOR } from "hooks/use-site-blueprint";

const textStyle = {
  align: "center",
  fontFamily: '"Source Sans Pro", Helvetica, sans-serif',
  fontSize: 130 * SCALE_FACTOR,
  fontWeight: 500,
  fill: 0xffffff,
};

export function FrameLabel({ depth, width, x, label }) {
  if (!label) return null;
  return (
    <>
      <Sprite
        // Notch bechind label
        anchor={[0, 0.5]}
        cullable
        height={160 * SCALE_FACTOR}
        texture={Texture.WHITE}
        tint={"0x000000"}
        width={width + 200 * SCALE_FACTOR + 15 * SCALE_FACTOR * label.length}
        x={
          x -
          (width + 200 * SCALE_FACTOR + 15 * SCALE_FACTOR * label.length) / 2
        }
        y={depth / 2}
      />
      <Text
        anchor={[0.5, 0.5]}
        isSprite
        style={textStyle}
        text={label}
        x={x}
        y={depth / 2}
      />
    </>
  );
}

FrameLabel.propTypes = {
  depth: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

function Frame({ depth, width, x, onClick }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Sprite
      cullable
      cursor={onClick ? "pointer" : "default"}
      eventMode={onClick ? "static" : "none"}
      height={depth}
      pointerdown={onClick}
      pointerout={() => setIsHovered(false)}
      pointerover={() => setIsHovered(!!onClick)}
      texture={Texture.WHITE}
      tint={isHovered ? "0x337ab7" : "0x000000"}
      width={width}
      x={x}
    />
  );
}

Frame.propTypes = {
  depth: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

// TODO: If onClick is not present, we don't need individual frames.
// We could draw all the frames in one, optimising.
function FramesForBaysComponent({ bays = [], onClick }) {
  return bays.map((b, i) => {
    return (
      <Container cullable key={b.id} x={b.offset} y={0}>
        {i === 0 ? (
          <Frame
            depth={b.FrameTemplateL.depth}
            onClick={onClick ? () => onClick(b.frameL.id) : undefined}
            width={b.FrameTemplateL.width}
            x={0}
          />
        ) : null}
        <Frame
          depth={b.FrameTemplateR.depth}
          onClick={onClick ? () => onClick(b.frameR.id) : undefined}
          width={b.FrameTemplateR.width}
          x={b.width - b.FrameTemplateR.width / 2}
        />
      </Container>
    );
  });
}

export const FramesForBays = React.memo(FramesForBaysComponent);
FramesForBaysComponent.propTypes = {
  bays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      offset: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      frameL: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      frameR: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      FrameTemplateL: PropTypes.shape({
        depth: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
      }).isRequired,
      FrameTemplateR: PropTypes.shape({
        depth: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ),
  onClick: PropTypes.func,
};
