import {
  faArrowDownWideShort,
  faArrowUpWideShort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { RoundLink } from "views/pages/Inspection/styles";

export default function SortButton({ onClick, isDescending }) {
  return (
    <RoundLink onClick={() => onClick(!isDescending)}>
      <FontAwesomeIcon
        icon={isDescending ? faArrowUpWideShort : faArrowDownWideShort}
      />
    </RoundLink>
  );
}

SortButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDescending: PropTypes.bool.isRequired,
};
