/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from 'libs/api/helpers';

export async function getReport(InspectionId, options = false) {
  const res = await apiCall(`/report/${InspectionId}${filterUrlGenerator(options)}`);
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  console.error(`Problem getting report: ${res.status}`);
  return res;
}

export async function editReport(body) {
  const res = await apiCall('/report', {
    method: 'PUT',
    body: JSON.stringify(body),
  });

  if (res.ok) {
    return;
  }

  throw new Error(`Problem saving report`);
}

export async function completeReport(body) {
  const res = await apiCall('/report/complete', {
    method: 'PUT',
    body: JSON.stringify(body),
  });
  return res;
}
