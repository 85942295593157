import { Navigate, useParams } from "react-router-dom";

import { useInspection } from "hooks/inspections";
import Loading from "views/components/Loading";

import PageInspectionBlueprintActions from "./page-blueprint-action";

/**
 * Page for Blueprint displaying actions
 **/
export default function PageBlueprintActions() {
  const { InspectionId, WarehouseId } = useParams();
  const { inspection, isLoading } = useInspection(InspectionId);

  if (isLoading) {
    return <Loading text="Loading Blueprint" />;
  }

  /**
   * If we don't have a warehouse ID then redirect to the first warehouse,
   * so we can use the URL for navigation between warehouses, without keeping
   * variables in local state.
   *
   * In order to have actions, there must be at least one warehouse, so we don't
   * need to check for existence here.
   */
  if (!WarehouseId) {
    return (
      <Navigate
        replace
        to={`/actions/blueprint/${InspectionId}/${inspection.warehouses[0].id}`}
      />
    );
  }

  return (
    <PageInspectionBlueprintActions
      InspectionId={InspectionId}
      SiteId={inspection.SiteId}
      WarehouseId={WarehouseId}
    />
  );
}
