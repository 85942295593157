import PropTypes from "prop-types";
import { Image, Col, Grid, Row } from "react-bootstrap";

import { RoundIconLink, Stat } from "../styles";

/**
 * Displays an icon link with a stat and label
 */
export default function InfoSectionIcon({
  ClientId,
  extraInfo,
  initialTab = "",
  type,
  value,
}) {
  return (
    <Grid fluid>
      <Row>
        <Col sm={4}>
          <RoundIconLink
            to={`/clients/${ClientId}/${type.toLowerCase()}/${initialTab}`}
          >
            <Image
              src={`/img/sidebar/${type.toLowerCase()}-icon.png`}
              width={30}
            />
          </RoundIconLink>
        </Col>
        <Col sm={8}>
          <Stat>{value || "-"}</Stat>
          <div>{type}</div>
          {extraInfo && <div>({extraInfo})</div>}
        </Col>
      </Row>
    </Grid>
  );
}

InfoSectionIcon.propTypes = {
  ClientId: PropTypes.number.isRequired,
  extraInfo: PropTypes.string,
  initialTab: PropTypes.number,
  type: PropTypes.oneOf(["Sites", "Inspections", "Schedules", "Actions"]),
  value: PropTypes.number.isRequired,
};
