/* eslint-disable no-unused-vars */
import { useCallback, useState } from "react";

import { useCurrentUser } from "hooks/user";
import {
  userEmailVerifyChange,
  userLogIn,
  userRequestEmailChange,
} from "libs/api";

export const statuses = {
  PASSWORD: "PASSWORD",
  NEW_EMAIL: "NEW_EMAIL",
  CONFIRM_EMAIL_CODE: "CONFIRM_EMAIL_CODE",
  SUCCESS: "SUCCESS",
};

/**
 * This hook manages the step process for a user changing their email address.
 *
 * We confirm that the user knows their password, and we save it for later.
 * We then ask the user to enter their new email address, and save it.
 * We then ask the user to confirm their new email address via their email code.
 */
export default function useEmailChanger() {
  const { user } = useCurrentUser();
  const [currentPassword, setCurrentPassword] = useState("");
  const [hasSetEmail, setHasSetEmail] = useState(false);
  const [hasValidatedCode, setHasValidatedCode] = useState(false);

  const handleCheckPassword = useCallback(
    async (password) => {
      try {
        await userLogIn(user.email, password);
        setCurrentPassword(password);
      } catch (e) {
        throw new Error("Password is incorrect");
      }
    },
    [user],
  );

  const handleSetNewEmail = useCallback(
    async (email) => {
      try {
        await userRequestEmailChange(email, currentPassword);
        setHasSetEmail(true);
      } catch (e) {
        throw new Error("Problem updating new email");
      }
    },
    [currentPassword],
  );

  const handleCheckValidationCode = useCallback(
    async (code) => {
      try {
        await userEmailVerifyChange(currentPassword, code);
      } catch (e) {
        throw new Error("Code is incorrect");
      }
    },
    [currentPassword],
  );

  const currentStatus = currentPassword
    ? hasSetEmail
      ? statuses.CONFIRM_EMAIL_CODE
      : statuses.NEW_EMAIL
    : statuses.PASSWORD;

  return {
    status: currentStatus,
    handleCheckPassword,
    handleSetNewEmail,
    handleCheckValidationCode,
  };
}
