import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { Button, FormControl } from "react-bootstrap";

import {
  deleteClassificationFile,
  editClassificationFileCaption,
  unresolveAction,
} from "libs/api";
import { PRIMARY_DATETIME_FORMAT_LONG } from "libs/constants";
import getOptimisedImageUrl from "libs/getOptimisedImageUrl";
import FlatModal, { ModalPaddedBody } from "views/components/Modals/FlatModal";

import {
  ContentWrapper,
  DeleteButton,
  DisplayImage,
  ImageControls,
  ImageWrapper,
  UnderImageControls,
} from "./styles";

/**
 * The lightbox is not responsible for managing the images or current image.
 * TODO: We might want to fix this so everything is contained in here.
 */
export default function ImageLightBox({
  currentImage,
  getImages,
  hideSaveButton,
  images,
  isOpen,
  onChangeCaption,
  onClose,
  onDelete,
  onNext,
  onPrevious,
  readOnly,
}) {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [saving, setSaving] = useState(false);
  const onSaveCaption = useCallback(async () => {
    setSaving(true);
    const image = images[currentImage];

    try {
      await editClassificationFileCaption(image.id, image.caption);
      if (getImages) {
        await getImages();
      }
      setSaving(false);
    } catch (e) {
      // TODO: Display an error to the user
      console.error(e);
      setSaving(false);
    }
  }, [currentImage, getImages, images]);

  if (!isOpen) {
    return null;
  }
  const image = images[currentImage];
  const showShouldDeleteButton = onDelete && !readOnly && !showConfirmDelete;
  const isFirstImage = currentImage === 0;
  const isLastImage = currentImage + 1 === images.length;

  // We can save the caption if we have a save function, we are not hiding the button, the image isn't from an action and it's not read-only.
  const canSaveCaption = onChangeCaption && !hideSaveButton && image && !image.actionFile && !readOnly; // prettier-ignore

  return (
    <FlatModal
      onHide={onClose}
      show={isOpen}
      title={`Image ${currentImage + 1} of ${images.length}`}
      width="800px"
    >
      <ContentWrapper>
        <ImageWrapper>
          <DisplayImage src={getOptimisedImageUrl(image?.src)} />
          <UnderImageControls>
            <Button disabled={isFirstImage} onClick={onPrevious}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <div>
              {moment(image.createdAt).format(PRIMARY_DATETIME_FORMAT_LONG)} (
              <a href={image.src} rel="noreferrer" target="_blank">
                View Original
              </a>
              )
            </div>
            <Button disabled={isLastImage} onClick={onNext}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </UnderImageControls>
        </ImageWrapper>
        <ImageControls>
          <ModalPaddedBody>
            {!image.actionFile ? (
              <FormControl
                componentClass="textarea"
                disabled={!onChangeCaption || readOnly}
                key={image.src}
                onChange={(e) => onChangeCaption(image.id, e.target.value)}
                placeholder={onChangeCaption ? "Add Caption" : "No Caption"}
                value={image.caption ?? ""}
              />
            ) : null}

            {canSaveCaption && (
              <Button onClick={onSaveCaption}>
                {saving ? "Saving..." : "Save Caption"}
              </Button>
            )}
            {image.newImage ? <div>Captions are saved as you type</div> : null}

            {showShouldDeleteButton ? (
              <DeleteButton onClick={() => setShowConfirmDelete(true)}>
                Delete Image
              </DeleteButton>
            ) : null}

            {showConfirmDelete ? (
              <>
                {image.actionFile ? (
                  <p>
                    Are you sure you want to delete this image as this will
                    change the Action Status back to &quot;To Do&quot;
                  </p>
                ) : (
                  <p>Are you sure you want to delete this Image?</p>
                )}
                <DeleteButton
                  onClick={async () => {
                    if (image.newImage) {
                      await onDelete();
                      setShowConfirmDelete(false);
                      onClose();
                    } else {
                      const res = await deleteClassificationFile(image.id);
                      if (image.actionFile) {
                        await unresolveAction(image.ClassificationId);
                      }
                      if (res.ok) {
                        onDelete();
                        setShowConfirmDelete(false);
                      }
                    }
                  }}
                >
                  Yes, Delete
                </DeleteButton>
                <Button onClick={() => setShowConfirmDelete(false)}>
                  Cancel
                </Button>
              </>
            ) : null}
          </ModalPaddedBody>
        </ImageControls>
      </ContentWrapper>
    </FlatModal>
  );
}

ImageLightBox.propTypes = {
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onClose: PropTypes.func,
  currentImage: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      newImage: PropTypes.bool,
      ClassificationId: PropTypes.string,
      src: PropTypes.string,
      actionFile: PropTypes.bool,
      caption: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  ),
  isOpen: PropTypes.bool,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool,
  hideSaveButton: PropTypes.bool,
  onChangeCaption: PropTypes.func,
  getImages: PropTypes.func,
};
