/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FormControl } from "react-bootstrap";

import { useFilters } from "hooks/useFilter";

export default function SearchBar() {
  const { searchText, setSearchText } = useFilters();
  return (
    <div
      css={css`
        display: inline-block;
        .form-control {
          background-color: #ffffff;
          box-shadow:
            0 1px 1px rgba(0, 0, 0, 0.1),
            0 1px 1px rgba(0, 0, 0, 0.1);
          color: #000000;
          height: 40px;
          margin-top: 1px;
          margin-right: 5px;
          width: 250px;
        }
      `}
    >
      <FormControl
        autoFocus={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        placeholder="Search"
        type="text"
        value={searchText ?? ""}
      />
    </div>
  );
}
