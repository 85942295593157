import styled from "@emotion/styled";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const ContactLine = styled.div`
  padding-bottom: 6px;
  span {
    font-weight: 700;
    display: inline-block;
    margin-right: 5px;
  }

  &:last-of-type {
    padding-bottom: 26px;
  }
`;

export const ClientTitle = styled.h1`
  margin-top: 0;
`;

export const ClientHeadingPanel = styled(Row)`
  color: "#666666";
  padding: 45px 0;
`;

export const SpacedRow = styled(Row)`
  margin-bottom: 1.5em;
`;

export const RoundIconLink = styled(Link)`
  align-items: center;
  background-color: #2d72ed;
  border-radius: 50%;
  color: white;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 60px;
`;

export const Stat = styled.div`
  font-size: 30px;
`;
