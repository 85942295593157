/* eslint-disable no-console */
import { apiCall } from 'libs/api/helpers';

export async function userRequestPassword(email) {
  const res = await apiCall('/reset/request', {
    method: 'PUT',
    body: JSON.stringify({ email }),
  });
  return res;
}

export async function userRequestConfirmPassword(email, passwordResetCode, password) {
  const res = await apiCall('/reset/confirm', {
    method: 'PUT',
    body: JSON.stringify({
      email,
      passwordResetCode,
      password,
    }),
  });

  if (res.ok) {
    return res.json();
  }

  throw new Error('Error requesting password reset');
}

export async function userCheckResetCode(email, passwordResetCode) {
  const res = await apiCall('/reset/check', {
    method: 'PUT',
    body: JSON.stringify({
      email,
      passwordResetCode,
    }),
  });
  return res;
}
