import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useReducer, useRef, useState } from "react";
import { Col, Row, Alert, Grid } from "react-bootstrap";

import { useInspection } from "hooks/inspections";
import getOptimisedImageUrl from "libs/getOptimisedImageUrl";
import Loading from "views/components/Loading";

import RiskSlider from "views/components/Modals/ClassificationEditor/RiskSlider";
import { buildMiscClassificationObjectToSend } from "views/components/Modals/NewClassificationEditor/helpers";
import {
  AutoCompleteWithSuggestions,
  ComponentSpecificationField,
} from "views/components/Modals/NewClassificationEditor/misc-fields";
import UploaderLightbox from "views/pages/Inspection/components/uploading-lightbox";

import {
  ComponentTypeField,
  OptionsField,
  NotesField,
  ExpandableField,
  BeamLevelsField,
  QuantityField,
  ImageUploaderField,
  CustomerReferenceField,
} from "./fields";
import classificationReducer, { ACTION_TYPES } from "./reducer";
import {
  StyledButtonGroup,
  Previous,
  CancelButton,
  StyledButton,
  StyledImage,
} from "./styles";

// TODO: Pass these as constants, not indexes
const risks = ["None", "Green", "Amber", "Red"];

export default function MiscDamageEditor({
  InspectionId,
  warehouseId,
  RackId,
  onHide,
  fixedDamageParameters = {},
  setShowOffloadModal,
  setFrameLocationId,
  setBeamLevel,
  setClassificationId,
}) {
  const [editableState, dispatch] = useReducer(
    classificationReducer,
    fixedDamageParameters,
  );
  const state = { ...editableState, ...fixedDamageParameters };
  const { inspection, isLoading, addClassificationWithImages } =
    useInspection(InspectionId);

  // State for saving the data
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(null);

  const lightboxRef = useRef();

  // Loading state
  if (isLoading) {
    return <Loading />;
  }

  // Loadeded, so display the Editor
  const warehouse = inspection?.warehouses?.find((w) => w.id === warehouseId);
  const rack =
    RackId ?? state.rackId
      ? warehouse?.racks?.find((r) => r.id === (RackId ?? state.rackId))
      : null;
  const bay = rack?.bays?.find((b) => b.id === state.location);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      // Frames and beams have different fields
      const classificationToSend = buildMiscClassificationObjectToSend(
        InspectionId,
        state,
      );
      const addedClassification = await addClassificationWithImages(
        classificationToSend,
        state.images,
      );
      setIsSaving(false);
      onHide();
      const offloadRiskSelected =
        classificationToSend.risk === "Amber" ||
        classificationToSend.risk === "Red";
      const rackHasOffloadLocations = rack?.offloadLocations.frames.length;
      if (rackHasOffloadLocations && offloadRiskSelected) {
        setClassificationId(addedClassification.id);
        setShowOffloadModal(true);
        setFrameLocationId(state.location);
        setBeamLevel(state.level);
      }
    } catch (e) {
      setIsSaving(false);
      setIsError(e.message);
    }
  };

  return (
    <>
      <Previous>
        {RackId ? (
          <>
            {rack.AreaName ? `${rack.AreaName}, ` : null}
            {rack ? `Rack ${rack?.name}` : null}
          </>
        ) : (
          <>
            <Grid fluid>
              <Row>
                <Col sm={6}>
                  <OptionsField
                    disabled={"areaId" in fixedDamageParameters}
                    onChange={(areaId) =>
                      dispatch({ type: ACTION_TYPES.SET_AREA_ID, areaId })
                    }
                    options={warehouse.areas.map((a) => ({
                      value: a.id,
                      label: a.name,
                    }))}
                    title="Area"
                    value={state.areaId ?? ""}
                  />
                </Col>
                <Col sm={6}>
                  <OptionsField
                    disabled={"rackId" in fixedDamageParameters}
                    onChange={(rackId) => dispatch({ type: ACTION_TYPES.SET_RACK_ID, rackId })} // prettier-ignore
                    options={warehouse.racks
                      .filter((r) =>
                        state.areaId && state.areaId !== "All Areas"
                          ? r.AreaId === state.areaId
                          : true,
                      )
                      .map((r) => ({
                        value: r.id,
                        label: `${r.AreaName ? `${r.AreaName} - ` : ""}${
                          r.name
                        }`,
                      }))}
                    title="Rack"
                    value={state.rackId ?? ""}
                  />
                </Col>
              </Row>
            </Grid>
          </>
        )}
      </Previous>

      <RiskSlider
        isReclassifying
        onChange={(idx) =>
          dispatch({ type: ACTION_TYPES.SET_RISK, risk: risks[idx] })
        }
        risk={state.risk}
      />

      <Grid fluid>
        <Row>
          <Col sm={12}>
            <ComponentTypeField
              disabled={!state.risk}
              onChange={(componentType) =>
                dispatch({
                  type: ACTION_TYPES.SET_COMPONENT_TYPE,
                  componentType,
                })
              }
              risk={state.risk}
              value={state.componentType}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <OptionsField
              disabled={!state.componentType}
              onChange={(location) =>
                dispatch({ type: ACTION_TYPES.SET_LOCATION, location })
              }
              options={
                state.componentType === "Frame"
                  ? rack?.locations?.frames.map((f) => ({
                      value: f.id,
                      label: f.name,
                    }))
                  : rack?.locations?.beams.map((b) => ({
                      value: b.id,
                      label: b.name,
                    }))
              }
              title="Location"
              value={state.location ?? ""}
            />
          </Col>
          <Col sm={6}>
            <CustomerReferenceField
              onChange={(customerReference) =>
                dispatch({
                  type: ACTION_TYPES.SET_CUSTOMER_REFERENCE,
                  customerReference,
                })
              }
              value={state.customerReference}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <AutoCompleteWithSuggestions
              componentType={state.componentType}
              fieldType="component"
              onChange={(component) =>
                dispatch({ type: ACTION_TYPES.SET_COMPONENT, component })
              }
              title="Component"
              value={state.component}
            />
          </Col>
          <Col sm={6}>
            {state.componentType === "Beam" ? (
              <BeamLevelsField
                disabled={!state.componentType || !state.risk}
                onChange={(level) =>
                  dispatch({ type: ACTION_TYPES.SET_LEVEL, level })
                }
                options={bay?.beams?.map((b) => ({
                  value: b.id,
                  label: b.level,
                }))}
                value={state.level ?? ""}
              />
            ) : (
              <AutoCompleteWithSuggestions
                componentType={state.componentType}
                disabled={!state.componentType || !state.risk}
                fieldType="level"
                onChange={(level) =>
                  dispatch({ type: ACTION_TYPES.SET_LEVEL, level })
                }
                title="Level"
                value={state.level}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <AutoCompleteWithSuggestions
              componentType={state.componentType}
              fieldType="position"
              onChange={(position) =>
                dispatch({ type: ACTION_TYPES.SET_POSITION, position })
              }
              title="Position"
              value={state.position}
            />
          </Col>
          <Col sm={6}>
            <AutoCompleteWithSuggestions
              componentType={state.componentType}
              fieldType="defect"
              onChange={(defect) =>
                dispatch({ type: ACTION_TYPES.SET_DEFECT, defect })
              }
              title="Defect"
              value={state.defect}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <AutoCompleteWithSuggestions
              componentType={state.componentType}
              fieldType="action"
              onChange={(action) =>
                dispatch({ type: ACTION_TYPES.SET_ACTION, action })
              }
              title="Action"
              value={state.action}
            />
          </Col>
          <Col sm={6}>
            <QuantityField
              onChange={(quantity) =>
                dispatch({ type: ACTION_TYPES.SET_QUANTITY, quantity })
              }
              value={state.quantity}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <ComponentSpecificationField
              onChange={(componentSpecification) =>
                dispatch({
                  type: ACTION_TYPES.SET_COMPONENT_SPECIFICATION,
                  componentSpecification,
                })
              }
              value={state.componentSpecification}
            />
          </Col>
        </Row>

        <ExpandableField title="Notes and images">
          <Row>
            <Col sm={6}>
              <NotesField
                onChange={(notes) =>
                  dispatch({ type: ACTION_TYPES.SET_NOTES, notes })
                }
                value={state.notes}
              />
            </Col>
            <Col sm={6}>
              <ImageUploaderField
                images={state.images}
                onAdd={(image) =>
                  dispatch({ type: ACTION_TYPES.ADD_IMAGE, image })
                }
              />
              {state.images?.slice(0, 2).map((image, idx) => (
                <StyledImage
                  key={image.remotePath}
                  onClick={() => lightboxRef?.current?.openWith(idx)}
                  src={
                    image.location
                      ? getOptimisedImageUrl(`${image.location}`)
                      : "/img/defaults/logo.png"
                  }
                />
              ))}
            </Col>
          </Row>
        </ExpandableField>
      </Grid>
      <StyledButtonGroup>
        <CancelButton onClick={onHide} type="button">
          Cancel
        </CancelButton>{" "}
        <StyledButton
          disabled={
            !state.risk ||
            !state.componentType ||
            !state.location ||
            !state.component ||
            !state.defect ||
            !state.action ||
            !state.quantity ||
            !state.componentSpecification
          }
          onClick={handleSave}
        >
          Add Damage{" "}
          {isSaving ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
        </StyledButton>
      </StyledButtonGroup>

      <UploaderLightbox
        images={state.images?.map((image) => ({
          caption: image.caption,
          src: image.location
            ? getOptimisedImageUrl(`${image.location}`)
            : "/img/defaults/logo.png",
        }))}
        onChangeCaption={(idx, caption) =>
          dispatch({
            type: ACTION_TYPES.SET_IMAGE_CAPTION,
            index: idx,
            caption,
          })
        }
        onDelete={(deleteIdx) =>
          dispatch({ type: ACTION_TYPES.REMOVE_IMAGE, index: deleteIdx })
        }
        ref={lightboxRef}
      />

      {isError ? (
        <Alert bsStyle="danger">{isError}. Please try again.</Alert>
      ) : null}
    </>
  );
}

MiscDamageEditor.propTypes = {
  warehouseId: PropTypes.string,
  InspectionId: PropTypes.string,
  RackId: PropTypes.string,
  onHide: PropTypes.func,
  fixedDamageParameters: PropTypes.shape({}),
  setShowOffloadModal: PropTypes.func,
  setFrameLocationId: PropTypes.func,
  setBeamLevel: PropTypes.func,
  setClassificationId: PropTypes.func,
};
