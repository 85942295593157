import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { LightOrangeButton } from "./colors";

export default function EditActionButton({ disabled, onClick }) {
  return (
    <LightOrangeButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={faUsers} />
      Edit Action
    </LightOrangeButton>
  );
}

EditActionButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
