export const PRIMARY_DATE_FORMAT = 'ddd Do MMM YY';
export const PRIMARY_DATETIME_FORMAT = 'HH:mm ddd Do MMM YY';
export const PRIMARY_DATETIME_FORMAT_LONG = 'HH:mm:ss ddd Do MMM YYYY';
export const PRIMARY_DATETIME_FORMAT_SHORT = 'HH:mm DD/MM/YYYY';
export const SHORT_DATE_FORMAT = 'DD/MM/YYYY';
export const RED_COLOUR = '#F44336';
export const GREEN_COLOUR = '#4CAF50';
export const AMBER_COLOUR = '#F57C00';
export const NONE_COLOUR = '#bbb';
export const DEFAULT_COLOUR = '#7e8496';

export const RISKS = {
  red: 'Red',
  amber: 'Amber',
  green: 'Green',
  none: 'None',
};
export const RISKS_ARRAY = [RISKS.none, RISKS.green, RISKS.amber, RISKS.red];
