import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import SearchBar from 'views/components/SearchBar';

const Wrapper = styled.div`
  height: 40px;
  margin: 20px 0;
`;

const StyledButton = styled(Button)`
  height: 40px;
  margin: 0;
  padding: 6px 12px;
  width: 175px;
`;

const ActionButtonRight = styled.div`
  float: right;
`;

export default function Controls() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <SearchBar />
      <ActionButtonRight>
        <StyledButton onClick={() => navigate('/clients/add')}>New Client</StyledButton>
      </ActionButtonRight>
    </Wrapper>
  );
}
