import styled from '@emotion/styled';

export const ScheduleEditorWrapper = styled.div`
  color: #686868 !important;
  input,
  .form-control {
    color: #686868 !important;
    background-color: #f2f2f4 !important;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
    margin-bottom: 5px;
  }
  .btn-group {
    padding-top: 10px;
    padding-right: 10px;
    float: right;
  }
  .bottom-btn {
    width: 125px;
  }
`;
