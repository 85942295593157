import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import Rack from './Rack';

function filterRacks(racks = [], racksFilterOptions = {}) {
  return racks.filter((rack) => {
    let allowed = true;
    if (racksFilterOptions.searchText.length > 0) {
      allowed = rack.name.toLowerCase().includes(racksFilterOptions.searchText.toLowerCase());
    }
    if (racksFilterOptions.ManufacturerId) {
      if (!(rack.ManufacturerId === racksFilterOptions.ManufacturerId)) {
        allowed = false;
      }
    }
    if (racksFilterOptions.AreaId) {
      if (!(rack.AreaId === racksFilterOptions.AreaId)) {
        allowed = false;
      }
    }
    if (racksFilterOptions.minLength) {
      if (!(rack.totalX >= racksFilterOptions.minLength)) {
        allowed = false;
      }
    }
    if (racksFilterOptions.maxLength) {
      if (!(rack.totalX <= racksFilterOptions.maxLength)) {
        allowed = false;
      }
    }
    if (racksFilterOptions.minBays) {
      if (!(rack.bays.length >= racksFilterOptions.minBays)) {
        allowed = false;
      }
    }
    if (racksFilterOptions.maxBays) {
      if (!(rack.bays.length <= racksFilterOptions.maxBays)) {
        allowed = false;
      }
    }
    return allowed;
  });
}

export default function RacksList({
  areas = [],
  canEdit,
  manufacturers = [],
  onSelectRack,
  racks = [],
  racksFilterOptions,
}) {
  const filteredRacks = filterRacks(racks, racksFilterOptions);
  return (
    <ListGroup className="scroll-bar-contents">
      {filteredRacks.length ? (
        filteredRacks.map((rack) => (
          <Rack
            area={areas.find((a) => a.id === rack.AreaId)}
            canEdit={canEdit}
            key={rack.id}
            manufacturerName={manufacturers.find((m) => m.id === rack.ManufacturerId)?.name}
            onSelectRack={onSelectRack}
            rack={rack}
          />
        ))
      ) : (
        <ListGroupItem>
          <div>No Racks Found</div>
        </ListGroupItem>
      )}
    </ListGroup>
  );
}

RacksList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  racks: PropTypes.array,
  racksFilterOptions: PropTypes.shape({}),
  // eslint-disable-next-line react/forbid-prop-types
  areas: PropTypes.array,
  canEdit: PropTypes.bool,
  onSelectRack: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  manufacturers: PropTypes.array,
};
