import PropTypes from 'prop-types';
import { useState } from 'react';

import { parseAddress } from 'libs/parseAddress';

import SitesActionButtons from 'views/components/ActionButtons/set-site';

export default function SiteRow({ site, permission, user }) {
  const [showButtons, setShowButtons] = useState(false);
  return (
    <tr onMouseEnter={() => setShowButtons(true)} onMouseLeave={() => setShowButtons(false)}>
      <td>{site.name}</td>
      <td>{parseAddress(site)}</td>
      <td>{site.contactName}</td>
      <td>{site.contactNumber}</td>
      <td>{site.warehouses?.length || 0}</td>
      <td>{site.buildMode ? 'Build Mode' : 'Inspect Mode'}</td>
      {showButtons && <SitesActionButtons permission={permission} site={site} user={user} />}
    </tr>
  );
}

SiteRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  site: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  permission: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
};
