import styled from "@emotion/styled";
import PropTypes from "prop-types";

import { Table } from "react-bootstrap";

import { YellowHeading } from "views/components/BuildModeSidebar/SharedControls/Generic";

import BeamTemplate from "./BeamTemplate";

// TODO: Refactor forms so styles are encapsulated at the component level
const Wrapper = styled.div`
  > div > table > tbody > tr > td > .form-control {
    width: 82px;
    padding-left: 3px;
    padding-right: 3px;
    border: 0px;
    background-color: #494c55 !important;
  }
  .checkbox label,
  .radio label {
    min-height: 20px;
    padding-left: 30px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
  }
`;

const StyledTable = styled(Table)`
  background-color: #30333c !important;
  min-height: 0.01%;
  overflow-x: hidden;
  overflow-y: hidden;
  thead > tr > th,
  thead:first-child > tr:first-child > th {
    border: 0 !important;
    padding: 0;
  }
  tbody > tr > td {
    padding-top: 5px;
    padding-bottom: 0px;
    padding-left: 0px !important;
  }
`;

export default function AddedBeams({
  beamTemplates = [],
  beamTemplateTypes,
  colours,
  onError,
  disabled,
  updateBeamTemplate,
}) {
  let nonDeletedIndex = -1;
  return (
    <Wrapper>
      <YellowHeading>Added Beams</YellowHeading>
      <StyledTable responsive>
        <thead>
          <tr>
            <th style={{ left: 0 }}>L</th>
            <th>Type</th>
            <th>Dnweld</th>
            <th>Colour</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {beamTemplates.length > 0 ? (
            beamTemplates
              .map((bt) => {
                if (!bt.deleted) {
                  nonDeletedIndex += 1;
                }
                return { ...bt, levelIndex: nonDeletedIndex };
              })
              .map((beamTemplate, index) => {
                if (beamTemplate.deleted) return null;
                return (
                  <BeamTemplate
                    beamTemplate={beamTemplate}
                    beamTemplateTypes={beamTemplateTypes}
                    clearErrorMessage={() => onError(null)}
                    colours={colours}
                    disabled={disabled}
                    index={beamTemplate.levelIndex}
                    key={`${beamTemplate.id}_${beamTemplate.levelIndex}`}
                    onError={onError}
                    updateBeamTemplate={(fields) =>
                      updateBeamTemplate(beamTemplate.id, index, { ...fields })
                    }
                  />
                );
              })
          ) : (
            <tr>
              <td colSpan="4">
                <h5>Added Beams will appear here</h5>
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </Wrapper>
  );
}

AddedBeams.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  beamTemplates: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  beamTemplateTypes: PropTypes.array,
  colours: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  updateBeamTemplate: PropTypes.func.isRequired,
};
