/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const StatusButton = styled(Button)`
  padding: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #ffffff;
  color: #333333;
  margin: 0px;
  margin-left: 10px;
  border-radius: 5px !important;
  span {
    padding: 0 !important;
  }
  .status-text {
    color: #6a6a6a;
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .status-count {
    color: #397af2;
    font-weight: 900 !important;
    margin-right: 10px;
    margin-left: 10px;
  }
  .button-vertical-container {
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
  }
  .button-vertical-divider {
    position: absolute;
    bottom: 2px;
    left: 0;
    height: 70%;
    border-left: 2px solid #397af2;
  }
  .selected {
    background-color: #d9dfee;
    color: #2870ed;
  }
`;

export default function ActionStatusButton({ onClick, count, text, status }) {
  return (
    <StatusButton
      className={`${status === text ? "selected" : ""}`}
      onClick={() => onClick(text)}
    >
      <span className="status-text">{text}</span>
      <span className="button-vertical-container">
        <span className="button-vertical-divider" />
      </span>
      <span className="status-count">{count}</span>
    </StatusButton>
  );
}

ActionStatusButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  onClick: PropTypes.func,
  text: PropTypes.string,
  status: PropTypes.string,
  count: PropTypes.number,
};
