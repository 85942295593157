import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Col, Checkbox } from "react-bootstrap";

const Wrapper = styled.div`
  color: #fff;
`;

export default function DisplayArea({
  title,
  racks = [],
  changeRackArea,
  racksAreChecked = false,
}) {
  return (
    <Wrapper>
      <Col sm={12}>
        <h4>{title}</h4>
        {racks.length === 0 ? (
          <div>None</div>
        ) : (
          racks.map((r) => (
            <div key={r.id}>
              <Checkbox
                checked={racksAreChecked}
                id={r.id}
                onChange={() => changeRackArea(r.id)}
              >
                <label htmlFor={r.id}>{r.name}</label>
              </Checkbox>
            </div>
          ))
        )}
      </Col>
    </Wrapper>
  );
}

DisplayArea.propTypes = {
  changeRackArea: PropTypes.func.isRequired,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  racks: PropTypes.array,
  // Are the racks default checked or unchecked (for in/out the area)
  racksAreChecked: PropTypes.bool,
};
