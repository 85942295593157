import Component from '.';

export default {
  title: 'Components/Share Modal',
  component: Component,
  parameters: {
    storyshots: {
      disable: true,
    },
  },
};

export const ShareModal = (args) => <Component {...args} />;
