import { OTHER_COLOUR_ID } from "constants";

import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState } from "react";
import { Table, ButtonGroup, Checkbox } from "react-bootstrap";

import {
  StyledFormControl,
  TextButton,
} from "views/components/BuildModeSidebar/SharedControls/Generic";

const StyledTable = styled(Table)`
  // TODO: Find out why important is needed here!
  margin-bottom: 40px !important;
  background-color: #30333c !important;
  min-height: 0.01%;
  overflow-x: hidden;
  overflow-y: hidden;

  th {
    padding: 0 !important;
    border-top: 0px solid #999 !important;
    border-bottom: 0px solid #999 !important;
  }
`;

const ColourControl = styled(StyledFormControl)`
  width: 90px !important;
`;

const QuantityControl = styled(StyledFormControl)`
  width: 43px !important;
`;

const TypeControl = styled(StyledFormControl)`
  width: 90px !important;
`;

const Test = styled.div`
  .table > tbody > tr > td > select {
    background-color: #494c55 !important;
  }
  > div > table > tbody > tr > td {
    padding-top: 5px;
    padding-bottom: 0px;
    padding-left: 0px !important;
  }
  > div > table > tbody > tr > td > .form-control {
    width: 82px;
    padding-left: 3px;
    padding-right: 3px;
    border: 0px;
    background-color: #494c55 !important;
  }
  > div > .bay-table > tbody > tr > td > .form-control {
    max-width: 50px;
  }
  > div > table > tbody > tr > td > {
    .form-control[type="number"] {
      width: 50px;
      padding-left: 5px;
    }
    .form-control[type="checkbox"] {
      width: 50px;
      padding-left: 15px;
    }
    .form-control[type="select"] {
      width: 50px;
      padding-left: 5px;
    }
  }

  > div > Table > thead > tr > th,
  > div > Table > thead:first-child > tr:first-child > th {
  }
  .checkbox label,
  .radio label {
    min-height: 20px;
    padding-left: 30px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
  }
`;

export default function AddBeam({
  ManufacturerId,
  SeriesId,
  disabled,
  beamTemplateTypes = [],
  colours = [],
  onAddNewBeamToBay,
}) {
  const [numberToAdd, setNumberToAdd] = useState(1);
  const [BeamTypeId, setBeamTypeId] = useState(null);
  const [standardDownweld, setStandardDownweld] = useState(null);
  const [ColourId, setColourId] = useState();
  const [colourOther, setColourOther] = useState();

  const isOtherColour =
    ColourId === OTHER_COLOUR_ID || (ColourId === null && colourOther !== null);
  return (
    <Test>
      <h4 className="yellow">Add Beams</h4>
      <StyledTable responsive>
        <thead>
          <tr>
            <th>Qty</th>
            <th>Type</th>
            <th>Dnweld</th>
            <th>Colour</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <QuantityControl
                disabled={!ManufacturerId}
                min="0"
                onChange={(e) => setNumberToAdd(parseInt(e.target.value, 10))}
                type="number"
                value={numberToAdd ?? 1}
              />
            </td>
            <td>
              <TypeControl
                componentClass="select"
                disabled={!SeriesId}
                onChange={(e) => setBeamTypeId(e.target.value)}
                placeholder="Bay Type"
                value={BeamTypeId ?? ""}
              >
                {beamTemplateTypes.length > 1 ? (
                  <option disabled value="">
                    Please select
                  </option>
                ) : null}
                {beamTemplateTypes.map((beamType) => (
                  <option key={beamType.id} value={beamType.id}>
                    {beamType.name}
                  </option>
                ))}
              </TypeControl>
            </td>
            <td>
              <Checkbox
                checked={standardDownweld === null}
                className="blue-square"
                disabled={!SeriesId}
                onChange={(e) => {
                  setStandardDownweld(e.target.checked ? null : 0);
                }}
              >
                Std
              </Checkbox>
            </td>
            <td>
              <ColourControl
                componentClass="select"
                disabled={!SeriesId}
                onChange={(e) => {
                  if (e.target.value === OTHER_COLOUR_ID) {
                    setColourId(null);
                  } else {
                    setColourId(e.target.value);
                  }
                }}
                placeholder="Colour"
                value={isOtherColour ? OTHER_COLOUR_ID : ColourId ?? ""}
              >
                {colours.length > 1 ? (
                  <option disabled value="">
                    Please select a colour
                  </option>
                ) : null}
                {colours.map((colour) => (
                  <option key={colour.id} value={colour.id}>
                    {colour.name}
                  </option>
                ))}
              </ColourControl>
            </td>
          </tr>
          <tr>
            <td>
              <ButtonGroup>
                <TextButton
                  disabled={disabled || (!ColourId && !colourOther)}
                  onClick={() => {
                    onAddNewBeamToBay(
                      {
                        standardDownweld,
                        BeamTypeId: BeamTypeId || beamTemplateTypes[0].id,
                        ColourId,
                        colourOther,
                      },
                      numberToAdd,
                    );
                  }}
                >
                  Add Beam
                </TextButton>
              </ButtonGroup>
            </td>
            <td />
            <td>
              {standardDownweld !== null && (
                <StyledFormControl
                  disabled={!SeriesId}
                  min="0"
                  onChange={(e) => {
                    setStandardDownweld(parseInt(e.target.value, 10));
                  }}
                  placeholder="Downweld (mm)"
                  type="number"
                  value={standardDownweld}
                />
              )}
            </td>
            <td>
              {ColourId === null || ColourId === OTHER_COLOUR_ID ? (
                <StyledFormControl
                  disabled={!SeriesId}
                  onChange={(e) => {
                    setColourOther(e.target.value);
                  }}
                  placeholder="Colour"
                  type="text"
                  value={colourOther}
                />
              ) : null}
            </td>
            <td />
          </tr>
        </tbody>
      </StyledTable>
    </Test>
  );
}
AddBeam.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  beamTemplateTypes: PropTypes.array,
  ManufacturerId: PropTypes.string,
  SeriesId: PropTypes.string,
  colours: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  onAddNewBeamToBay: PropTypes.func.isRequired,
};
