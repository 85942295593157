import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import {
  ControlLabelWithLoading,
  LightStyledFormControl,
} from "views/components/BuildModeSidebar/SharedControls/Generic";

export default function HasSacLeg({ disabled, isSelected, onChange }) {
  return (
    <>
      <Col sm={6}>
        <ControlLabelWithLoading disabled={disabled} label="Sacrificial Leg" />
      </Col>
      <Col sm={6}>
        <LightStyledFormControl
          componentClass="select"
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          value={isSelected ? 1 : 0}
        >
          <option selected={!isSelected} value={0}>
            No
          </option>
          <option selected={isSelected} value={1}>
            Yes
          </option>
        </LightStyledFormControl>
      </Col>
    </>
  );
}

HasSacLeg.propTypes = {
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
