import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, Checkbox, Table } from "react-bootstrap";

import { LightStyledFormControl } from "views/components/BuildModeSidebar/SharedControls/Generic";

const StyledTable = styled(Table)`
  background-color: #30333c !important;

  > tbody > tr > td > input {
    width: 90px !important;
    background-color: #494c55;
  }

  &.table > thead > tr > th {
    border-bottom: 0px;
  }
`;

const YellowHeading = styled.h4`
  color: ${(props) => (props.disabled ? "#7e6d43" : "#ffcf33")};
  display: inline-block;
  padding-right: 68px;
`;
const CheckboxContainer = styled.div`
  .checkbox input[type="checkbox"] {
    margin-top: 12px;
    cursor: pointer;
  }
`;

export default function RearFrame({
  disabled,
  frameTypes = [],
  onAddRearFrame,
  setSpliceFrameTypeId,
  setRearHeight,
  spliceFrameTypeId,
  rearHeight,
  frontSacLeg,
  hasRearFrames,
}) {
  const [showAddFrame, setShowAddFrame] = useState(hasRearFrames);
  // TODO: Ensure that a frame type is selected
  return (
    <>
      <CheckboxContainer>
        <Checkbox
          checked={showAddFrame || frontSacLeg}
          onChange={() => setShowAddFrame(!showAddFrame)}
          style={{ marginRight: "10px" }}
        >
          <YellowHeading disabled={disabled}>
            Configure Rear Frame
          </YellowHeading>
        </Checkbox>
      </CheckboxContainer>
      {(showAddFrame || frontSacLeg) && (
        <StyledTable>
          <thead>
            <tr>
              <th>Height</th>
              <th>Type</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <LightStyledFormControl
                  disabled={disabled}
                  onChange={(e) => setRearHeight(parseInt(e.target.value, 10))}
                  type="number"
                  value={rearHeight}
                />
              </td>
              <td>
                {frameTypes.length ? (
                  <LightStyledFormControl
                    componentClass="select"
                    disabled={disabled}
                    onChange={(e) => setSpliceFrameTypeId(e.target.value)}
                    placeholder="Bay Type"
                    value={spliceFrameTypeId ?? ""}
                  >
                    <option disabled value="">
                      Please select
                    </option>
                    {frameTypes.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </LightStyledFormControl>
                ) : (
                  <span>No frame types.</span>
                )}
              </td>
              <td>
                <Button
                  className="action-button"
                  disabled={disabled || !rearHeight || !spliceFrameTypeId}
                  onClick={() =>
                    onAddRearFrame({
                      height: rearHeight,
                      FrameTypeId: spliceFrameTypeId,
                      isRear: true,
                    })
                  }
                >
                  Add Splice
                </Button>
              </td>
            </tr>
          </tbody>
        </StyledTable>
      )}
    </>
  );
}

RearFrame.propTypes = {
  disabled: PropTypes.bool,
  onAddRearFrame: PropTypes.func.isRequired,
  frameTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  rearHeight: PropTypes.number,
  setRearHeight: PropTypes.func.isRequired,
  setSpliceFrameTypeId: PropTypes.func.isRequired,
  spliceFrameTypeId: PropTypes.string,
  frontSacLeg: PropTypes.bool,
  hasRearFrames: PropTypes.bool,
};
