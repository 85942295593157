import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";

const Wrapper = styled.div`
  position: fixed;
  width: calc(100vw - 225px);
  bottom: 0px;
  .progress {
    margin-bottom: 0px;
    border-radius: 0px;
    height: 10px;
    background-color: #d8d8d8;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    .progress-bar {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
      background-color: #fece47;
    }
  }
`;

/**
 * Component description
 */
export default function FixedProgressBar({ percentage = 0 }) {
  return (
    <Wrapper>
      <ProgressBar now={percentage} />
    </Wrapper>
  );
}
FixedProgressBar.propTypes = {
  percentage: PropTypes.number,
};
