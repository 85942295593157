import useSWR from "swr";

import { getDashboardData } from "libs/api";

export function useDashboard(filters) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    [`/dashboard`, filters],
    async () => getDashboardData(filters),
  );
  return {
    dashboardData: data,
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}
