import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useInspections } from "hooks/inspections";
import { useCheckboxSet, withCheckboxSet } from "hooks/useCheckboxer";
import { useDownloader } from "hooks/useDownloader";
import { useFilters, FiltersProvider, FILTER_KEYS } from "hooks/useFilter";
import { useCurrentUser } from "hooks/user";
import { toggleInspectionArchive, deleteInspection } from "libs/api";

import downloadPDF from "libs/downloadPDF";

import PageWrapper from "views/components/common/PageWrapper";
import ActionAssigner from "views/components/Modals/ActionAssigner";

import FlatModal from "views/components/Modals/FlatModal";

import PageButtons from "views/components/Tables/PageButtons";
import {
  MiniFilterButton,
  TableControlsWrapper,
  MiniFilterWrapper,
} from "views/components/Tables/TableControls";
import TableHeader from "views/components/Tables/TableHeader";
import TableTabs from "views/components/Tables/TableTabs";
import Controls from "views/pages/Inspections/Controls";

import List from "./List";
import MiniFilter from "./MiniFilter";

const PAGE_SIZE = 10;

function InspectionsPage() {
  const { user } = useCurrentUser();

  const [Archived, setArchived] = useState(false);
  const [ClientName] = useState(null);

  const [page, setPage] = useState(0);
  const [selectedAssignInspection, setSelectedAssignInspection] =
    useState(null);

  const { TabIndex: urlTabIndex } = useParams();

  // Checkbox provider wraps the page, reset checkboxes on page navigate
  const { checked: selectedInspections, reset: resetCheckboxes } =
    useCheckboxSet();
  useEffect(() => resetCheckboxes(), [page, resetCheckboxes]);

  // Share download state with the row action buttons and table controls
  const { onStart, onEnd, downloads } = useDownloader();

  const {
    client,
    clientId,
    completedAtEndDate,
    completedAtStartDate,
    createdAtEndDate,
    createdAtStartDate,
    inspectionStatus: status,
    inspectorId,
    searchText,
    setInspectorId,
    site,
    siteId,
  } = useFilters();

  const searchFilters = useMemo(
    () => ({
      ClientId: clientId,
      SiteId: siteId,
      page,
      amount: PAGE_SIZE,
      Archived,
      searchText,
      status,
      createdAtStartDate,
      createdAtEndDate,
      completedAtStartDate,
      completedAtEndDate,
      InspectorId: !Archived && inspectorId,
    }),
    [
      clientId,
      siteId,
      searchText,
      Archived,
      completedAtStartDate,
      completedAtEndDate,
      createdAtEndDate,
      createdAtStartDate,
      status,
      inspectorId,
      page,
    ],
  );

  // Reset the page when the filters change
  useEffect(
    () => setPage(0),
    [
      completedAtStartDate,
      completedAtEndDate,
      createdAtStartDate,
      createdAtEndDate,
      status,
    ],
  );

  useEffect(
    () => setInspectorId(urlTabIndex ? null : user?.id),
    [user, urlTabIndex, setInspectorId],
  );

  /**
   * Data Loading Hooks
   */
  const {
    inspections,
    isLoading: isInspectionsLoading,
    isValidating,
    total: totalInspections,
    mutate: refreshInspections,
  } = useInspections(searchFilters);

  /** Callbacks for Table Header Buttons */
  const toggleInspectionArchivalStatus = useCallback(async () => {
    await Promise.all(
      selectedInspections.map(async (selectedInspection) => {
        await toggleInspectionArchive(selectedInspection);
      }),
    );
    resetCheckboxes();
    refreshInspections();
  }, [refreshInspections, resetCheckboxes, selectedInspections]);

  const downloadSelectedPDFs = useCallback(async () => {
    await Promise.all(
      selectedInspections.map(async (inspectionId) => {
        const inspection = inspections.find((i) => i.id === inspectionId);
        const toastId = toast.info(
          `Exporting Inspection report (${inspection.reference})`,
          {
            closeButton: false,
            autoClose: false,
          },
        );
        onStart(inspectionId);
        await downloadPDF(inspectionId, inspection);
        onEnd(inspectionId);
        toast.update(toastId, {
          render: `Report exported (${inspection.reference})`,
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
      }),
    );
  }, [inspections, onEnd, onStart, selectedInspections]);

  const deleteSelectedInspections = useCallback(async () => {
    // TODO: add a prompt first
    await Promise.all(
      selectedInspections.map(async (selectedInspection) => {
        await deleteInspection(selectedInspection);
      }),
    );
    refreshInspections();
    resetCheckboxes();
  }, [refreshInspections, resetCheckboxes, selectedInspections]);

  const totalPages = Math.ceil(totalInspections / PAGE_SIZE);
  const tableTabs = ["Inspections", "Archived"];
  const title = `Econform / ${client ? `${client.name} / ` : ''} ${site ? `${site.name} / ` : ''} Inspections`; // prettier-ignore
  return (
    <PageWrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Controls />
      <TableHeader
        largeLeftTagline={inspectorId ? 'List of your inspections' : 'List of all inspections'} // prettier-ignore
        largeLeftTitle={inspectorId ? "My Inspections" : "All Inspections"}
        right={`Showing ${inspections?.length ?? 0} of ${
          totalInspections ?? 0
        } Inspections`}
      />
      <TableControlsWrapper>
        <TableTabs
          initialTab={0}
          onSelectKey={(key) => {
            setPage(0);
            setArchived(key === 1);
          }}
          titles={tableTabs}
          width="29%"
        />
        <MiniFilterWrapper>
          {!Archived ? (
            <MiniFilter
              selected={inspectorId ? "my" : "all"}
              toggle={() => setInspectorId(inspectorId ? null : user?.id)}
            />
          ) : null}
          <MiniFilterButton
            disabled={!selectedInspections.length}
            onClick={downloadSelectedPDFs}
            selected={selectedInspections.length}
          >
            {downloads.length > 0 ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : null}
            Export
          </MiniFilterButton>
          <MiniFilterButton
            disabled={!selectedInspections.length}
            onClick={toggleInspectionArchivalStatus}
            selected={selectedInspections.length}
          >
            {!Archived ? "Archive" : "Restore"}
          </MiniFilterButton>
          {Archived && (
            <MiniFilterButton
              disabled={!selectedInspections.length}
              onClick={deleteSelectedInspections}
              selected={selectedInspections.length}
            >
              Delete
            </MiniFilterButton>
          )}
          <MiniFilterButton onClick={() => refreshInspections()}>
            {isInspectionsLoading || isValidating ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : null}{" "}
            Refresh
          </MiniFilterButton>
        </MiniFilterWrapper>
      </TableControlsWrapper>
      <List filters={searchFilters} onRefresh={() => refreshInspections()} />
      <PageButtons
        curPage={page}
        onChangePage={setPage}
        totalPages={totalPages}
      />
      {selectedAssignInspection && (
        <FlatModal
          closeButton
          onHide={() => setSelectedAssignInspection(false)}
          show
          title="Assign Action"
          width={500}
        >
          <ActionAssigner
            clientName={ClientName}
            getData={() => refreshInspections()}
            onHide={() => setSelectedAssignInspection(null)}
          />
        </FlatModal>
      )}
    </PageWrapper>
  );
}

/**
 * An inspection page can appear as a standalone, for all inspections, as part
 * of a client, or as part of a site. This component determines the context in
 * which this page is being rendered, and passes the appropriate controlled
 * values to the filters.
 */
function InspectionPageInURLContext() {
  const { ClientId: urlClientId, SiteId: urlSiteId } = useParams();

  const controlledValues = {
    [FILTER_KEYS.clientId]: urlClientId,
    [FILTER_KEYS.siteId]: urlSiteId,
  };

  const enabledFilters = [
    !urlClientId && !urlSiteId && FILTER_KEYS.clientId, // Don't allow changes when client or site Id is specified
    !urlSiteId && FILTER_KEYS.siteId,
    FILTER_KEYS.inspectionStatus,
    FILTER_KEYS.createdAtStartDate,
    FILTER_KEYS.createdAtEndDate,
    FILTER_KEYS.completedAtStartDate,
    FILTER_KEYS.completedAtEndDate,
    FILTER_KEYS.createdAtStartDate,
    FILTER_KEYS.createdAtEndDate,
  ].filter(Boolean);

  // Key is required here so that the context is re-mounted when going from a client
  // inspections page to a general, etc. The context "sticks" otherwise
  return (
    <FiltersProvider
      controlledValues={controlledValues}
      enabledFilters={enabledFilters}
      key={`${urlClientId}_${urlSiteId}`}
    >
      <InspectionsPage />
    </FiltersProvider>
  );
}

export default withCheckboxSet(InspectionPageInURLContext);
