import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import {
  BlueButton,
  LightBlueButton,
  GreenButton,
  DarkBlueButton,
} from "views/components/ActionButtons/colors";

export function ButtonBlueprint({
  onClick,
  isBuildMode,
  isStarted,
  canUserBuild,
}) {
  const isDisabled = !(!isBuildMode || canUserBuild);
  if (isBuildMode && isStarted)
    return (
      <DarkBlueButton disabled={isDisabled} onClick={onClick}>
        <FontAwesomeIcon icon={faWarehouse} />
        Continue
      </DarkBlueButton>
    );

  if (isBuildMode && !isStarted)
    return (
      <GreenButton disabled={isDisabled} onClick={onClick}>
        <FontAwesomeIcon icon={faWarehouse} />
        Start
      </GreenButton>
    );

  return (
    <LightBlueButton disabled={isDisabled} onClick={onClick}>
      <FontAwesomeIcon icon={faWarehouse} />
      View
    </LightBlueButton>
  );
}

ButtonBlueprint.propTypes = {
  canUserBuild: PropTypes.bool.isRequired,
  isStarted: PropTypes.bool.isRequired,
  isBuildMode: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export function ButtonSiteOverview({ onClick }) {
  return (
    <BlueButton onClick={onClick}>
      <FontAwesomeIcon icon={faArrowAltCircleRight} />
      Site Overview
    </BlueButton>
  );
}

ButtonSiteOverview.propTypes = {
  onClick: PropTypes.func.isRequired,
};
