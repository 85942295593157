import PropTypes from "prop-types";

import FieldBackToBackOffset from "views/components/BuildModeSidebar/RackEditor/SectionPositioning/fields/b2b-offset";
import FieldOffsetDirection from "views/components/BuildModeSidebar/RackEditor/SectionPositioning/fields/offset-direction";
import FieldOffsetFromRack from "views/components/BuildModeSidebar/RackEditor/SectionPositioning/fields/offset-from-rack";
import OffsetPositioning from "views/components/BuildModeSidebar/RackEditor/SectionPositioning/offset-positioning";

export default function RelativePositioning({
  areas,
  compareRack,
  racks,
  backToBack,
  onChangeCompareRack,
  onChangeBackToBack,
  onUpdate,
  rack,
}) {
  const { depth } = rack;
  return (
    <>
      <FieldOffsetFromRack
        areas={areas}
        disabled={!rack.ManufacturerId || !rack.depth}
        onChangeCompareRack={onChangeCompareRack}
        racks={racks}
        relativeToRackId={compareRack?.id}
      />
      <FieldBackToBackOffset
        backToBack={backToBack}
        disabled={!rack.ManufacturerId || !depth}
        onChangeBackToBack={onChangeBackToBack}
        onUpdate={onUpdate}
      />
      {!backToBack ? (
        <>
          <FieldOffsetDirection
            offsetDirection={rack.offsetDirection}
            onUpdate={onUpdate}
          />
          <OffsetPositioning offset={rack.offset} onUpdate={onUpdate} />
        </>
      ) : null}
    </>
  );
}

RelativePositioning.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  backToBack: PropTypes.bool,
  rack: PropTypes.shape({
    offsetDirection: PropTypes.oneOf(["left", "right", "front", "back"]),
    ManufacturerId: PropTypes.string,
    depth: PropTypes.number,
    orientation: PropTypes.bool,
    offset: PropTypes.number,
  }),
  onUpdate: PropTypes.func.isRequired,
  onChangeCompareRack: PropTypes.func.isRequired,
  onChangeBackToBack: PropTypes.func.isRequired,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  racks: PropTypes.array,
  compareRack: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};
