import styled from '@emotion/styled';

export const RoleName = styled.div`
  font-weight: bold;
`;

export const RoleDescription = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 10pt;
`;
