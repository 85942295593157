import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { GreenButton } from "./colors";

export default function InspectionReportButton({
  disabled,
  onClick,
  started = false,
}) {
  return (
    <GreenButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={faEdit} size="2xl" />
      {!started ? "Start" : "Edit"} Report
    </GreenButton>
  );
}
InspectionReportButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  started: PropTypes.bool,
};
