/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { useClient } from "hooks/client";
import { useSitePermission } from "hooks/schedules";
import { useSite } from "hooks/site";
import { FiltersProvider, FILTER_KEYS } from "hooks/useFilter";
import { useCurrentUser } from "hooks/user";
import { useWarehouses } from "hooks/warehouse";
import WarehousesActionButton from "views/components/ActionButtons/set-warehouse";
import PageWrapper from "views/components/common/PageWrapper";
import EmptyTableIndicator from "views/components/EmptyTableIndicator";
import PageButtons from "views/components/Tables/PageButtons";
import { Table } from "views/components/Tables/styles";
import TableHeader from "views/components/Tables/TableHeader";

import Controls from "./components/Controls";

const amount = 10;

function WarehousesPage() {
  const { user } = useCurrentUser();
  const [page, setPage] = useState(0);

  // We could get these from the filter, but SWR will cache anyway.
  const { SiteId } = useParams();
  const { site, isLoading: isLoadingSite } = useSite(SiteId);
  const { client } = useClient(site?.ClientId);
  const { permission } = useSitePermission(SiteId, user?.id);

  const filters = {
    SiteId,
    page,
    amount,
    searchText: "",
  };

  const {
    warehouses,
    isLoading: isLoadingWarehouses,
    total: totalWarehouses,
  } = useWarehouses(filters);

  const totalPages = Math.ceil(totalWarehouses / amount);
  const isLoading = isLoadingSite || isLoadingWarehouses;

  return (
    <PageWrapper>
      <Helmet>
        <title>{`Econform / ${client?.name} ${site?.name} Warehouses`}</title>
      </Helmet>
      <Controls
        redirectUrl={`/clients/sites/${SiteId}/warehouses/add`}
        showActionButton={
          (permission.level.includes("organisation") ||
            permission.level.includes("clientAdmin")) &&
          site &&
          site.hasAdminPermission &&
          site.buildMode
        }
      />
      <TableHeader
        largeLeftTagline="List of all warehouses"
        largeLeftTitle="Warehouses"
        right={totalWarehouses ? `${totalWarehouses} Warehouses Added` : ""}
      />
      <Table>
        <thead>
          <tr>
            <th>Warehouse Name</th>
            <th>Dimensions</th>
          </tr>
        </thead>
        <tbody>
          {warehouses.map((warehouse) => (
            <tr key={warehouse.id}>
              <td>{warehouse.name}</td>
              <td>
                {`${(warehouse.sizeX / 1000).toFixed(0)}m x ${(
                  warehouse.sizeY / 1000
                ).toFixed(0)}m`}
              </td>
              <WarehousesActionButton
                WarehouseId={warehouse.id}
                permission={permission}
                site={site}
                user={user}
              />
            </tr>
          ))}
        </tbody>
      </Table>
      {!warehouses.length && (
        <EmptyTableIndicator
          isLoading={isLoading}
          searchable
          type="Warehouses"
        />
      )}
      <PageButtons
        curPage={page}
        onChangePage={setPage}
        totalPages={totalPages}
      />
    </PageWrapper>
  );
}

/**
 * The warehouses page only appears in a site, so no filter values can be altered.
 * We are pass
 */
function WarehousesPageInURLContext() {
  const { SiteId: urlSiteId } = useParams();

  const controlledValues = {
    [FILTER_KEYS.siteId]: urlSiteId,
  };

  const enabledFilters = [].filter(Boolean);

  return (
    <FiltersProvider
      controlledValues={controlledValues}
      enabledFilters={enabledFilters}
    >
      <WarehousesPage />
    </FiltersProvider>
  );
}

export default WarehousesPageInURLContext;
