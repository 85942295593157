import PropTypes from 'prop-types';
import { Panel, ListGroup, ListGroupItem } from 'react-bootstrap';

import Area from './Area';

export default function AreasList({ areas = [], canEdit, onClickEdit }) {
  return (
    <Panel>
      <Panel.Body className="no-padding-panel">
        <ListGroup className="scroll-bar-contents">
          {areas.length ? (
            areas.map((area) => (
              <Area
                canEdit={canEdit}
                key={area.id}
                name={area.name}
                numberOfRacks={area.racks?.length}
                onClickEdit={() => onClickEdit(area.id)}
              />
            ))
          ) : (
            <ListGroupItem>
              <div>No Areas Found</div>
            </ListGroupItem>
          )}
        </ListGroup>
      </Panel.Body>
    </Panel>
  );
}

AreasList.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      racks: PropTypes.array,
      name: PropTypes.string,
    }),
  ),
  canEdit: PropTypes.bool,
  onClickEdit: PropTypes.func,
};
