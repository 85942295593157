/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useSite } from "hooks/site";
import { css } from "@emotion/react";
/**
 * Displays the site build mode status and the warehouses, and allows the user
 * to toggle build mode before viewing the blueprint.
 */
export default function SiteLocationReport({
  SiteId,
  offloadedRequired,
  reopenedRequired,
  noDateRange,
  setOffoadedRequired,
  setReopenedRequired,
  setNoDateRange,
  setFromDate,
  setToDate,
}) {
  const { site } = useSite(SiteId);

  const handleStartDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleNoDateRange = () => {
    setNoDateRange(!noDateRange);
    setFromDate(null);
    setToDate(null);
  };

  if (!site) return null;
  // Define Emotion styles
  const containerStyles = css`
    padding: 16px;
  `;

  const flexRowStyles = css`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
  `;

  const checkboxStyles = css`
    width: 16px;
    height: 16px;
    color: #2d72ed;
    border: 1px solid #ccc;
    border-radius: 4px;
    &:focus {
      outline: none;
      border-color: #2d72ed;
      box-shadow: 0 0 0 2px rgba(45, 114, 237, 0.5);
    }
  `;

  const dateInputContainerStyles = css`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
  `;

  const dateInputStyles = css`
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 8px;
    &:focus {
      outline: none;
      border-color: #2d72ed;
      box-shadow: 0 0 0 2px rgba(45, 114, 237, 0.5);
    }
  `;

  const labelStyles = css`
    margin-bottom: 4px;
  `;

  return (
    <div css={containerStyles}>
      <div css={flexRowStyles}>
        <span>Offloaded Locations</span>
        <input
          checked={offloadedRequired}
          onClick={() => setOffoadedRequired(!offloadedRequired)}
          type="checkbox"
          css={checkboxStyles}
        />
      </div>
      <div css={flexRowStyles}>
        <span>Reopened Locations</span>
        <input
          checked={reopenedRequired}
          onClick={() => setReopenedRequired(!reopenedRequired)}
          type="checkbox"
          css={checkboxStyles}
        />
      </div>
      <div css={flexRowStyles}>
        <span>No Date Range</span>
        <input
          checked={noDateRange}
          onClick={handleNoDateRange}
          type="checkbox"
          css={checkboxStyles}
        />
      </div>
      {!noDateRange && (
        <div css={dateInputContainerStyles}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <label css={labelStyles}>From</label>
            <input
              type="date"
              css={dateInputStyles}
              onChange={handleStartDateChange}
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <label css={labelStyles}>To</label>
            <input
              type="date"
              css={dateInputStyles}
              onChange={handleEndDateChange}
            />
          </div>
        </div>
      )}
    </div>
  );
}

SiteLocationReport.propTypes = {
  SiteId: PropTypes.string.isRequired,
  offloadedRequired: PropTypes.bool.isRequired,
  reopenedRequired: PropTypes.bool.isRequired,
  noDateRange: PropTypes.bool.isRequired,
  setOffoadedRequired: PropTypes.func.isRequired,
  setReopenedRequired: PropTypes.func.isRequired,
  setNoDateRange: PropTypes.func.isRequired,
  setFromDate: PropTypes.func,
  setToDate: PropTypes.func,
};
