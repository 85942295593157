import { useCallback, useState } from "react";
import { Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { addClient } from "libs/api";
import ButtonWide from "views/components/Buttons/button-wide";
import LinkButtonWide from "views/components/Buttons/link-button-wide";
import PageWrapper from "views/components/common/PageWrapper";

import TableHeader from "views/components/Tables/TableHeader";

import ClientAddress from "./components/client-address";
import ClientContactDetails from "./components/client-contact-details";
import ClientDetails from "./components/client-details";

import { ControlsWrapper } from "./styles";

export default function PageAddClient() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  /**
   * State that holds the new client fields, and a callback
   * function that updates a single named field
   * TODO move this to a reducer that validates the field names
   */
  const [client, setClient] = useState({});
  const handleFieldChange = useCallback(
    (field, value) => {
      setClient((curr) => ({
        ...curr,
        [field]: value,
      }));
    },
    [setClient],
  );

  const handleSave = useCallback(async () => {
    try {
      const newClient = await addClient(client);
      toast(`Added client: ${newClient.name}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        type: toast.TYPE.SUCCESS,
        autoClose: true,
        pauseOnHover: true,
      });

      navigate(`/clients/${newClient.id}/overview`);
    } catch (error) {
      setError(error.message);
    }
  }, [navigate, client]);

  const isAddDisabled =
    !client.name ||
    !client.clientNumber ||
    !client.clientEmail ||
    !client.contactName ||
    !client.contactNumber ||
    !client.contactEmail ||
    !client.addressLine1 ||
    !client.city ||
    !client.postcode;

  return (
    <PageWrapper>
      <Helmet>
        <title>Econform / Clients / Add</title>
      </Helmet>
      <TableHeader left="Add Client" />

      <ClientDetails client={client} onChangeField={handleFieldChange} />
      <ClientContactDetails client={client} onChangeField={handleFieldChange} />
      <ClientAddress client={client} onChangeField={handleFieldChange} />

      <span className="required">* Mandatory Fields</span>
      <ControlsWrapper>
        <LinkButtonWide to="/clients">Cancel</LinkButtonWide>
        <ButtonWide disabled={isAddDisabled} onClick={handleSave} primary>
          Add Client
        </ButtonWide>
      </ControlsWrapper>

      {error ? <Alert bsStyle="danger">{error}</Alert> : null}
    </PageWrapper>
  );
}
