import PropTypes from "prop-types";

import {
  ActionButtonsOverlay,
  EditScheduleButton,
  InspectionDeleteButton,
  PauseResumeScheduleButton,
} from "views/components/ActionButtons";

function ManageScheduleActionButtons({
  onEditClick,
  onPauseResumeClick,
  onDeleteClick,
  isPaused,
}) {
  return (
    <ActionButtonsOverlay>
      {onEditClick && <EditScheduleButton onClick={onEditClick} />}
      {onPauseResumeClick && (
        <PauseResumeScheduleButton
          isPaused={isPaused}
          onClick={onPauseResumeClick}
        />
      )}
      <InspectionDeleteButton onClick={onDeleteClick} />
    </ActionButtonsOverlay>
  );
}

ManageScheduleActionButtons.propTypes = {
  isPaused: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onPauseResumeClick: PropTypes.func,
};

export default ManageScheduleActionButtons;
