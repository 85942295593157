/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-unused-modules
export default function LegendItem({ item, colour }) {
  if (!item || !colour) return null;
  return (
    <div
      css={css`
        display: inline-block;
        margin: auto;
        height: 13px;
        .rectangle {
          height: 13px;
          width: 35px;
          background-color: ${colour};
          border-style: solid;
          border-color: #fff;
          border-width: 2px;
          display: inline-block;
          margin-right: 5px;
        }
      `}
    >
      <div
        css={css`
          margin-right: 10px;
          height: 13px;
        `}
      >
        <div className="rectangle" />
        <h6
          css={css`
            display: inline-block;
            height: 13px;
          `}
        >
          {
            // eslint-disable-next-line react/destructuring-assignment
            item[0].toUpperCase() + item.slice(1)
          }
        </h6>
      </div>
    </div>
  );
}

LegendItem.propTypes = {
  item: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
};
