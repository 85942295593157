import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { getDamage } from "libs/blueprint";
import DamageIndicator from "views/components/Blueprint/canvas-elements/damage-indicator";
// eslint-disable-next-line import/order, no-unused-vars
import { SCALE_FACTOR } from "hooks/use-site-blueprint";

// Used to translate a display into a colour value
// TODO: Move this into the damage indicator, and then on to a referenced config file
const risksValues = {
  Amber: 0xf57c00,
  Red: 0xf44336,
  Green: 0x4caf50,
  None: 0xbbbbbb,
};

const SPOT_KEYS = {
  BEAMS_FRONT: ["beams", "front"],
  BEAMS_MIDDLE: ["beams", "middle"],
  BEAMS_REAR: ["beams", "rear"],
  FRAMES_LEFT_FRONT: ["frames", "left", "front"],
  FRAMES_LEFT_MIDDLE: ["frames", "left", "middle"],
  FRAMES_LEFT_REAR: ["frames", "left", "rear"],
  FRAMES_RIGHT_FRONT: ["frames", "right", "front"],
  FRAMES_RIGHT_MIDDLE: ["frames", "right", "middle"],
  FRAMES_RIGHT_REAR: ["frames", "right", "rear"],
};

/**
 * Damage is an object tree:
 * {
 *  beams: { front, middle, rear }
 * }
 *
 * We can pick "spots" out of this tree to assign the damage to a specific
 * location
 */
function BayDamage({ id, orientation, width, depth, damage = {}, onclick }) {
  // the x,y coordinates of the damage indicators on this bay
  const frontY = orientation ? depth : 0;
  const rearY = !orientation ? depth : 0;
  const middleY = depth / 2;
  const leftX = orientation ? 0 : width; //-(30 * SCALE_FACTOR);
  const rightX = !orientation ? 0 : width;
  const middleX = width / 2;

  // an array of what damage can be assigned where
  const spots = useMemo(
    () => [
      { key: SPOT_KEYS.BEAMS_FRONT, x: middleX, y: frontY },
      { key: SPOT_KEYS.BEAMS_REAR, x: middleX, y: rearY },
      { key: SPOT_KEYS.FRAMES_LEFT_REAR, x: leftX, y: rearY },
      { key: SPOT_KEYS.FRAMES_RIGHT_REAR, x: rightX, y: rearY },
      { key: SPOT_KEYS.FRAMES_LEFT_FRONT, x: leftX, y: frontY },
      { key: SPOT_KEYS.FRAMES_RIGHT_FRONT, x: rightX, y: frontY },
      { key: SPOT_KEYS.BEAMS_MIDDLE, x: middleX, y: middleY },
      { key: SPOT_KEYS.FRAMES_LEFT_MIDDLE, x: leftX, y: middleY },
      { key: SPOT_KEYS.FRAMES_RIGHT_MIDDLE, x: rightX, y: middleY },
    ],
    [frontY, leftX, middleX, middleY, rearY, rightX],
  );

  // Translate the spots into damage indicators
  const damages = useMemo(
    () =>
      spots
        .map((s) => {
          const damageForSpot = getDamage(damage, s.key);
          return {
            ...s,
            damageForSpot,
            spot: s.key,
            display: damageForSpot.displayDamage,
            number: damageForSpot.classifications?.length ?? 0,
          };
        })
        .filter((d) => d.number > 0),
    [damage, spots],
  );
  return (
    <>
      {damages.map((d) => (
        <DamageIndicator
          color={risksValues[d.display]}
          id={id} // This is the bay id, multiple damages may be present
          key={d.key.join("-")}
          onclick={onclick}
          spot={d.spot}
          text={d.number}
          x={d.x}
          y={d.y}
        />
      ))}
    </>
  );
}

export default React.memo(BayDamage);
BayDamage.propTypes = {
  orientation: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  depth: PropTypes.number.isRequired,
  damage: PropTypes.shape({}),
  onclick: PropTypes.func,
  id: PropTypes.string.isRequired,
};
