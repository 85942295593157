import validateEmail from "libs/validateEmail";

import { USER_PERMISSION_TYPES, emptyPermissions } from "./config";

export function getIsValidUser(user, addedPermissions, deletedPermissions) {
  let totalUsersCurrentPermissions = 0;
  if (!user) {
    return false;
  }
  if (user.permissions) {
    Object.keys(user.permissions).forEach((permission) => {
      totalUsersCurrentPermissions += user.permissions[permission].length;
    });
  }
  const totalPermissions =
    totalUsersCurrentPermissions -
    addedPermissions.length -
    deletedPermissions.length;
  return (
    user.name &&
    user.email &&
    validateEmail(user.email) &&
    (addedPermissions.length > 0 || totalPermissions > 0)
  );
}

export function getOrganisationIdFromUserDetails(
  initialOrganisationId,
  currentUserDetails,
) {
  if (
    !!currentUserDetails &&
    currentUserDetails.organisations.length +
      currentUserDetails.organisationsAdmin.length +
      currentUserDetails.clientOrganisation.length ===
      1 &&
    !initialOrganisationId
  ) {
    if (currentUserDetails.organisationsAdmin.length === 1) {
      return currentUserDetails.organisationsAdmin[0].id;
    }
    if (currentUserDetails.organisations.length === 1) {
      return currentUserDetails.organisations[0].id;
    }
    if (currentUserDetails.clientOrganisation.length === 1) {
      return currentUserDetails.clientOrganisation[0].OrganisationId;
    }
  }
  return null;
}

export function getClientIdFromUserDetails(currentUserDetails) {
  if (
    currentUserDetails.clientAdmin.length +
      currentUserDetails.client.length +
      currentUserDetails.siteClient.length +
      currentUserDetails.siteGroupClient.length ===
    1
  ) {
    if (currentUserDetails.clientAdmin.length === 1) {
      return currentUserDetails.clientAdmin[0].id;
    }
    if (currentUserDetails.client.length === 1) {
      return currentUserDetails.client[0].id;
    }
    if (currentUserDetails.siteGroupClient.length === 1) {
      return currentUserDetails.siteGroupClient[0].ClientId;
    }
    if (currentUserDetails.siteClient.length === 1) {
      return currentUserDetails.siteClient[0].ClientId;
    }
  }
  return null;
}

export function getPermissionsTypeFromPermissions(
  permissions = emptyPermissions,
  clients = [],
  client = null,
  sites = [],
  siteGroups = [],
  organisation = null,
) {
  if (organisation && permissions.organisationAdmin.includes(organisation.id)) {
    return USER_PERMISSION_TYPES.ORGADMIN;
  }

  if (organisation && permissions.organisation.includes(organisation.id)) {
    return USER_PERMISSION_TYPES.ORGUSER;
  }

  if (client && permissions.clientAdmin.includes(client.id)) {
    return USER_PERMISSION_TYPES.ADMIN;
  }

  if (client && permissions.client.includes(client.id)) {
    return USER_PERMISSION_TYPES.USER;
  }

  if (
    siteGroups &&
    siteGroups.find((siteGroup) => permissions.siteGroup.includes(siteGroup.id))
  ) {
    return USER_PERMISSION_TYPES.SITEGROUP;
  }

  if (sites && sites.find((site) => permissions.site.includes(site.id))) {
    return USER_PERMISSION_TYPES.SITE;
  }

  if (
    clients &&
    clients.find((c) => permissions.clientOrganisation.includes(c.id))
  ) {
    return USER_PERMISSION_TYPES.USER;
  }

  if (permissions?.clientOrganisation?.length > 0) {
    return USER_PERMISSION_TYPES.CLIENTORGUSER;
  }

  return USER_PERMISSION_TYPES.NONE;
}

/**
 * This function takes the original permissions object, and applies our updates
 * (additions and deletions) to it in order to produce what the new permissions
 * object for the user is.
 *
 * Original is a permissions object of { [type]: [...relationIds]}
 * Added and Deleted are [{ type, relationId,  UserId }
 */
export function applyPermissionUpdatesToOriginalPermissions(
  original,
  added = [],
  deleted = [],
) {
  /**
   * Take the original permissions object, and remove any permissions that we
   * have marked as to be deleted.
   *
   * Loop with a reduce through each deletion, applying that deletion
   * to the original permissions object, which is then passed to the next iteration
   * To delete, we reach in to the [type] field, and filter the array of IDs we
   * find inside it.
   */
  const originalWithoutDeleted = deleted.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.type]: prev[curr.type]?.filter((rId) => !curr.relationId === rId),
    }),
    original,
  );

  /**
   * We can now take the permissions object with the deletions applied and now
   * apply the additiions. So loop with a reduce through each addition, over-writing
   * the [type] field with a new array of what is currently there plus our addition.
   *
   * As a safety check, we filter the array with a boolean to remove any "falsy"
   * values.
   *
   * The object returned is now our original permissions with deletions and
   * additions applied.
   */
  return {
    ...added.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.type]: [...prev[curr.type], curr.relationId].filter(Boolean),
      }),
      originalWithoutDeleted,
    ),
  };
}
