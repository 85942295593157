import styled from "@emotion/styled";

export const ControlsWrapper = styled.div`
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
`;

export const ControlsButtonWrapper = styled.div`
  margin-left: auto;
`;
