import PropTypes from 'prop-types';
import { Alert, Col } from 'react-bootstrap';

import { SidebarFooter } from './styles';

export default function FooterWrapper({ errorMsg, children }) {
  return (
    <SidebarFooter>
      {errorMsg ? (
        <Col sm={12}>
          <Alert bsStyle="danger">{errorMsg}</Alert>
        </Col>
      ) : null}
      <Col sm={12}>{children}</Col>
    </SidebarFooter>
  );
}

FooterWrapper.propTypes = {
  errorMsg: PropTypes.string,
  children: PropTypes.node.isRequired,
};
