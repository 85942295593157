import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { deleteClient } from "libs/api";

import ButtonWide from "views/components/Buttons/button-wide";
import ConfirmModal from "views/components/Modals/ConfirmModal";

export default function DeleteButtonWithModal({ clientId }) {
  const [, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleDelete = useCallback(async () => {
    try {
      await deleteClient(clientId);
      toast.info("Successfully deleted client");
      navigate("/clients");
    } catch (e) {
      toast.error("Error deleting client");
      setError(e);
    }
  }, [clientId, navigate]);
  return (
    <>
      <ConfirmModal
        onConfirm={handleDelete}
        onHide={() => setShowModal(false)}
        show={showModal}
        title="Delete Client"
      >
        <p>Are you sure you want to delete this client?</p>
        <p>This cannot be undone, and all related data will also be deleted!</p>
      </ConfirmModal>
      <ButtonWide bsStyle="danger" onClick={() => setShowModal(true)}>
        Delete
      </ButtonWide>
    </>
  );
}

DeleteButtonWithModal.propTypes = {
  clientId: PropTypes.string.isRequired,
};
