import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { FormGroup, Col, Form } from "react-bootstrap";

import { useManufacturers, useSeries } from "hooks/manufacturers";
import { useBayDepths } from "hooks/templates";
import {
  ControlLabelWithLoading,
  StyledFormControl,
  YellowHeading,
} from "views/components/BuildModeSidebar/SharedControls/Generic";

const Wrapper = styled.div`
  padding: 5px 20px;
`;

export default function SectionDetails({
  areas,
  isCopyRack,
  onUpdate,
  rack,
  SiteId,
}) {
  const { manufacturers, isLoading: isLoadingManufacturers } = useManufacturers(
    {
      SiteId,
      type: "rack",
    },
  );

  const { series, isLoading: isLoadingSeries } = useSeries(
    rack.ManufacturerId,
    {
      SiteId,
      type: "bay",
    },
  );
  const { depths } = useBayDepths(rack.ManufacturerId, SiteId, rack.SeriesId);

  return (
    <Wrapper>
      <Form
        horizontal
        onKeyPress={(e) => (e.which === 13 ? e.preventDefault() : "")}
      >
        <YellowHeading>Rack Details</YellowHeading>
        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading bsClass="left-align-label">
              Rack Name
            </ControlLabelWithLoading>
          </Col>
          <Col sm={8}>
            <StyledFormControl
              onChange={(e) => onUpdate({ name: e.target.value })}
              placeholder="Enter Rack Name"
              type="text"
              value={rack.name}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading
              disabled={rack.id || isCopyRack}
              isLoading={isLoadingManufacturers}
            >
              Manufacturer
            </ControlLabelWithLoading>
          </Col>
          <Col sm={8}>
            <StyledFormControl
              componentClass="select"
              disabled={rack.id || isCopyRack}
              onChange={(e) => onUpdate({ ManufacturerId: e.target.value })}
              value={rack.ManufacturerId ?? ""}
            >
              <option value="">Select Manufacturer</option>
              {manufacturers.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </StyledFormControl>
          </Col>
        </FormGroup>
        <FormGroup
          className={`${rack.ManufacturerId && rack.name ? "" : "greyed"}`}
        >
          <Col sm={4}>
            <ControlLabelWithLoading
              disabled={
                !rack.ManufacturerId || rack.id || isCopyRack || !rack.name
              }
              isLoading={isLoadingSeries}
            >
              Series
            </ControlLabelWithLoading>
          </Col>
          <Col sm={8}>
            <StyledFormControl
              componentClass="select"
              disabled={
                !rack.ManufacturerId || rack.id || isCopyRack || !rack.name
              }
              onChange={(e) => onUpdate({ SeriesId: e.target.value })}
              value={rack.SeriesId}
            >
              {!rack.SeriesId && <option>Select Series</option>}
              {series.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </StyledFormControl>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading
              disabled={
                !rack.SeriesId ||
                rack.id ||
                isCopyRack ||
                rack.bays.length > 0 ||
                !rack.ManufacturerId ||
                !rack.name
              }
            >
              Rack Depth
            </ControlLabelWithLoading>
          </Col>
          <Col sm={8}>
            <StyledFormControl
              componentClass="select"
              disabled={
                !rack.SeriesId ||
                rack.id ||
                isCopyRack ||
                rack.bays.length > 0 ||
                !rack.ManufacturerId ||
                !rack.name
              }
              onChange={(e) =>
                onUpdate({ depth: parseInt(e.target.value, 10) })
              }
              placeholder="Rack Depth"
              value={rack.depth}
            >
              {!rack.depth && <option>Select Depth</option>}
              {depths.map((d) => (
                <option key={d} value={d}>
                  {d}
                </option>
              ))}
            </StyledFormControl>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading
              disabled={!rack.name || !rack.ManufacturerId}
            >
              Rack Area
            </ControlLabelWithLoading>
          </Col>
          <Col sm={8}>
            <StyledFormControl
              componentClass="select"
              disabled={!rack.name || !rack.ManufacturerId}
              onChange={(e) => {
                const updatedAreaId =
                  e.target.value === "No Area" ? null : e.target.value;
                onUpdate({ AreaId: updatedAreaId });
              }}
              placeholder="Rack Area"
              value={rack.AreaId}
            >
              <option value={null}>No Area</option>
              {areas.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </StyledFormControl>
          </Col>
        </FormGroup>
      </Form>
    </Wrapper>
  );
}

SectionDetails.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onUpdate: PropTypes.func.isRequired,
  rack: PropTypes.shape({
    AreaId: PropTypes.string,
    id: PropTypes.string,
    ManufacturerId: PropTypes.string,
    name: PropTypes.string,
    SeriesId: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    bays: PropTypes.array,
    depth: PropTypes.number,
  }),
  isCopyRack: PropTypes.bool,
  SiteId: PropTypes.string,
};
