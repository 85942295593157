import * as Sentry from "@sentry/browser";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { getCurrentUser, getUsers, userLogOut } from "libs/api";

function getUserPosition(newUser) {
  if (!newUser) return;

  let position = "No Role";
  if (newUser.organisationsAdmin.length) {
    position = "Org Admin";
  }
  if (newUser.organisations.length) {
    position = "Org Management";
  }
  if (newUser.clientOrganisation.length) {
    position = "Org User";
  }
  if (
    !newUser.organisations.length &&
    !newUser.organisationsAdmin.length &&
    !!newUser.client.length
  ) {
    position = "Management User";
  }
  if (
    !newUser.organisations.length &&
    !newUser.organisationsAdmin.length &&
    !!newUser.clientAdmin.length
  ) {
    position = "Admin User";
  }
  if (
    !newUser.organisations.length &&
    !newUser.organisationsAdmin.length &&
    !!newUser.siteClient.length
  ) {
    position = "Site User";
  }
  if (
    !newUser.organisations.length &&
    !newUser.organisationsAdmin.length &&
    !newUser.siteClient.length &&
    !!newUser.siteGroupClient.length
  ) {
    position = "Group User";
  }

  return position;
}

export function useCurrentUser() {
  const { data, error, isLoading, mutate } = useSWR(`/user`, getCurrentUser);

  if (!data) {
    Sentry.configureScope((scope) => scope.setUser(null));
  } else {
    Sentry.setUser({ id: data.id, email: data.email });
  }

  const navigate = useNavigate();
  const onLogout = useCallback(async () => {
    await userLogOut();
    mutate();
    navigate("/");
  }, [mutate, navigate]);

  // Provide logout function
  // Redirect if inactive

  const userWithPosition = useMemo(
    () => (data ? { ...data, position: getUserPosition(data) } : null),
    [data],
  );

  return {
    user: userWithPosition,
    isLoading,
    isError: error,
    mutate,
    onLogout,
  };
}

export function useUsers(filters) {
  const { data, error, isLoading, mutate } = useSWR(
    [`/users`, filters],
    async () => getUsers(filters),
  );

  return {
    users: data?.results ?? [],
    total: data?.total ?? 0,
    isLoading,
    isError: error,
    mutate,
  };
}
