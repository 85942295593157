/* eslint-disable no-console */
import { apiCall, filterUrlGenerator } from 'libs/api/helpers';

export async function getSchedules(filters) {
  const res = await apiCall(`/schedule${filterUrlGenerator(filters)}`);
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  throw new Error('Problem getting schedules');
}

export async function getSchedule(ScheduleId) {
  const res = await apiCall(`/schedule/${ScheduleId}`);
  if (res.status === 200) {
    return res.json();
  }
  throw new Error('Problem getting schedule');
}

export async function getMissedSchedules(filters) {
  const res = await apiCall(`/schedule/missed${filterUrlGenerator(filters)}`);
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  throw new Error('Problem getting missed schedules');
}

export async function createSchedule(schedule) {
  const res = await apiCall('/schedule', {
    method: 'POST',
    body: JSON.stringify(schedule),
  });
  return res.ok;
}

export async function editSchedule(schedule) {
  const res = await apiCall('/schedule', {
    method: 'PUT',
    body: JSON.stringify(schedule),
  });
  return res.ok;
}

export async function deleteSchedule(ScheduleId) {
  const res = await apiCall(`/schedule/${ScheduleId}`, {
    method: 'DELETE',
  });
  return res;
}

export async function getMySchedules(filters) {
  const res = await apiCall(`/schedule/my${filterUrlGenerator(filters)}`);
  if (res.ok) {
    const body = await res.json();
    return body;
  }
  throw new Error('Problem getting my schedules');
}

export async function toggleStatusSchedule(ScheduleId) {
  const res = await apiCall('/schedule/status', {
    method: 'PUT',
    body: JSON.stringify({ ScheduleId }),
  });
  return res;
}

export async function viewMissedInspection(body) {
  const res = await apiCall('/schedule/view', {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return res;
}

export async function unviewMissedInspection(body) {
  const res = await apiCall('/schedule/unview', {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return res;
}
