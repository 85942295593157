import { faFileArrowDown, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { DarkBlueButton } from "./colors";

export default function InspectionExportPDFButton({
  disabled,
  onClick,
  isDownloading = false,
}) {
  return (
    <DarkBlueButton disabled={disabled || isDownloading} onClick={onClick}>
      {isDownloading ? (
        <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
      ) : (
        <FontAwesomeIcon icon={faFileArrowDown} size="2xl" />
      )}
      {isDownloading ? "Exporting..." : "Export PDF"}
    </DarkBlueButton>
  );
}

InspectionExportPDFButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,

  isDownloading: PropTypes.bool,
};
