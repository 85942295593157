import PropTypes from "prop-types";

import { useClient } from "hooks/client";

import FilterChip from ".";

export default function ClientChip({ ClientId, hideRemove, onRemove }) {
  const { client, isLoading } = useClient(ClientId);
  return (
    <FilterChip
      hideRemove={hideRemove}
      isLoading={isLoading}
      onRemove={onRemove}
      text={client?.name}
      type="clients"
    />
  );
}

ClientChip.propTypes = {
  ClientId: PropTypes.string,
  hideRemove: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
};
