import useSWR from "swr";

import {
  getFrameDepths,
  getBayDepths,
  getNewBayName,
  getNewFrameName,
} from "libs/api";

// Get the "next" frame name for a to-be-added frame
export function useNewFrameName(SiteId) {
  const { data, error, mutate, isLoading } = useSWR(
    SiteId ? `/template/frameName/${SiteId}` : null,
    async () => getNewFrameName(SiteId),
  );
  return {
    name: data,
    isLoading,
    isError: error,
    mutate,
  };
}

// Get the "next" bay name, for a to-be-added by
export function useNewBayName(SiteId) {
  const { data, error, mutate, isLoading } = useSWR(
    SiteId ? `/template/bayName/${SiteId}` : null,
    async () => getNewBayName(SiteId),
  );
  return {
    name: data,
    isLoading,
    isError: error,
    mutate,
  };
}

export function useBayDepths(ManufacturerId, SiteId, SeriesId) {
  const { data, error, mutate, isLoading } = useSWR(
    ManufacturerId && SiteId && SeriesId
      ? `/template/bay/depth/${SiteId}/${ManufacturerId}/${SeriesId}`
      : null,
    async () => getBayDepths(ManufacturerId, SiteId, SeriesId),
  );

  return {
    depths: data ?? [],
    isError: error,
    isLoading,
    mutate,
  };
}

export function useFrameDepths(ManufacturerId, SiteId, SeriesId) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    ManufacturerId && SiteId && SeriesId
      ? `/template/frame/depth/${SiteId}/${ManufacturerId}/${SeriesId}`
      : null,
    async () => getFrameDepths(ManufacturerId, SiteId, SeriesId),
  );
  return {
    depths: data ?? [],
    isError: error,
    isLoading,
    mutate,
    isValidating,
  };
}
