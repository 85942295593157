import styled from "@emotion/styled";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, Table, Col, Row } from "react-bootstrap";

const StyledTable = styled(Table)`
  background-color: #30333b !important;
  color: #fff;
  padding-bottom: 10px;

  & > thead > tr > th,
  & > thead:first-child > tr:first-child > th {
    border-bottom: 1px solid #999;
    border-top: 1px solid #999;
    padding: 10px;
    text-align: center;
  }

  & > tbody > tr > td {
    border-top: 0px solid #999 !important;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
  }
`;

const StyledCol = styled(Col)`
  padding: 5px 15px 5px 5px;
  width: 100%;
`;

export default function ExpandingTable({ title, children }) {
  const [showBeams, setShowBeams] = useState(false);

  return (
    <StyledCol>
      <Button
        className="btn beams-btn"
        onClick={() => setShowBeams(!showBeams)}
      >
        <Row>
          <span className="pull-left">{title}</span>
          <span className="pull-right">
            <FontAwesomeIcon
              icon={showBeams ? faChevronDown : faChevronRight}
            />
          </span>
        </Row>
        {showBeams ? (
          <StyledTable condensed responsive>
            {children}
          </StyledTable>
        ) : null}
      </Button>
    </StyledCol>
  );
}

ExpandingTable.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
