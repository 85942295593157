import styled from "@emotion/styled";
import {
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  ControlLabel,
  FormControl,
  Button,
  Radio as BsRadio,
} from "react-bootstrap";

export const YellowHeading = styled.h4`
  color: ${(props) => (props.disabled ? "#7e6d43" : "#ffcf33")};
`;

export const YellowSubHeading = styled.h5`
  color: ${(props) => (props.disabled ? "#7e6d43" : "#ffcf33")};
  margin-left: 10px;
`;

export const Radio = styled(BsRadio)`
  color: ${(props) => (props.disabled ? "#919399" : "#fff")};
`;

const StyledLabel = styled(ControlLabel)`
  color: ${(props) => (props.disabled ? "#919399" : "white")};
  text-align: left !important;
  width: 100%;
  i {
    margin-top: 2px;
    float: right;
  }
`;

export function ControlLabelWithLoading({
  htmlFor,
  label,
  isLoading,
  disabled,
  isError = false,
  children,
}) {
  return (
    <StyledLabel disabled={disabled} htmlFor={htmlFor}>
      {label}
      {children}
      {isLoading ? <FontAwesomeIcon icon={faSpinner} pulse /> : null}
      {isError ? <FontAwesomeIcon icon={faExclamationTriangle} /> : null}
    </StyledLabel>
  );
}

ControlLabelWithLoading.propTypes = {
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node,
  htmlFor: PropTypes.string,
};

export const StyledFormControl = styled(FormControl)`
  background-color: ${(props) =>
    props.disabled ? "#3e414b" : "#30333b"} !important;
  border: 0;
  color: ${(props) => (props.disabled ? "#aaa" : "white")} !important;
`;

export const LightStyledFormControl = styled(FormControl)`
  background-color: ${(props) =>
    props.disabled ? "#24262a" : "#494c55"} !important;
  margin-bottom: 10px;
  border: 0;
  color: ${(props) => (props.disabled ? "#aaa" : "white")} !important;
`;

export const TextButton = styled(Button)`
  background-color: ${(props) => (props.disabled ? "#304d81" : "#2d72ed")};
  color: ${(props) => (props.disabled ? "#6f7788" : "inherit")};
  font-weight: 600;
  height: 40px;
  margin: 5px 0;
  width: 100px;
`;
