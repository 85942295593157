import PropTypes from "prop-types";

export default function Alert({ className, children, variant }) {
  return (
    <div
      className={[
        variant === "danger" && "bg-red-100 text-red-800",
        variant === "success" && "bg-green-100 text-green-800",
        className,
      ]}
    >
      {children}
    </div>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["primary", "secondary", "danger", "success"]),
};
