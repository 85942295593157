/**
 * These action types are used in the add and reclassify editors to dispatch
 * actions to this store.
 */
export const ACTION_TYPES = {
  SET_RISK: "SET_RISK",
  SET_COMPONENT_TYPE: "SET_COMPONENT_TYPE",
  SET_COMPONENT: "SET_COMPONENT",
  SET_POSITION: "SET_POSITION",
  SET_DEFECT: "SET_DEFECT",
  SET_ACTION: "SET_ACTION",
  SET_LOCATION: "SET_LOCATION",
  SET_QUANTITY: "SET_QUANTITY",
  SET_LEVEL: "SET_LEVEL",
  SET_NOTES: "SET_NOTES",
  SET_CUSTOMER_REFERENCE: "SET_CUSTOMER_REFERENCE",
  SET_COMPONENT_SPECIFICATION: "SET_COMPONENT_SPECIFICATION",
  SET_RUN_SPACER: "SET_RUN_SPACER",
  SET_SECTION: "SET_SECTION",
  ADD_IMAGE: "ADD_IMAGE",
  REMOVE_IMAGE: "REMOVE_IMAGE",
  SET_IMAGE_CAPTION: "SET_IMAGE_CAPTION",
  SET_ACTION_NOTE: "SET_ACTION_NOTE",
  SET_RACK_ID: "SET_RACK_ID",
  SET_AREA_ID: "SET_AREA_ID",
};

export const componentsWhereLevelShouldBeNA = [
  "Baseplate",
  "Floor Fixing",
  "Frame",
  "Levelling Shim",
  "Loading Sign",
  "Run Spacer",
  "Splice",
  "Upright",
];

export const COMPONENT_TYPES = {
  frame: "Frame",
  beam: "Beam",
};

/**
 * Some components have a fixed level field. This function returns whether or
 * not the level field should be disabled.
 */
export function canEditLevel(componentType, component) {
  if (!componentType || !component) return false;
  return !(
    componentType === COMPONENT_TYPES.frame &&
    componentsWhereLevelShouldBeNA.includes(component)
  );
}

/* eslint-disable no-unused-vars */
export default function classificationReducer(
  state = {
    images: [],
  },
  action = {},
) {
  switch (action.type) {
    case ACTION_TYPES.SET_RISK: {
      const {
        componentType,
        component,
        position,
        defect,
        location,
        action: a,
        level,
        ...rest
      } = state;
      return {
        ...rest,
        risk: action.risk,
      };
    }
    case ACTION_TYPES.SET_COMPONENT_TYPE: {
      const {
        component,
        position,
        defect,
        action: a,
        location,
        level,
        ...rest
      } = state;
      return {
        ...rest,
        componentType: action.componentType,
      };
    }
    case ACTION_TYPES.SET_COMPONENT: {
      const { position, defect, action: a, runspacerSize, ...rest } = state;
      const level = componentsWhereLevelShouldBeNA.includes(action.component)
        ? "N/A"
        : "";
      return {
        ...rest,
        component: action.component,
        level,
        runspacerSize: action.component === "Run Spacer" ? runspacerSize : null,
      };
    }
    case ACTION_TYPES.SET_POSITION: {
      const { defect, action: a, section, ...rest } = state;
      return {
        ...rest,
        position: action.position,
      };
    }
    case ACTION_TYPES.SET_DEFECT: {
      const { action: a, ...rest } = state;
      return {
        ...rest,
        defect: action.defect,
      };
    }
    case ACTION_TYPES.SET_ACTION:
      return {
        ...state,
        action: action.action,
      };
    case ACTION_TYPES.SET_LOCATION: {
      return {
        ...state,
        location: action.location,
      };
    }
    case ACTION_TYPES.SET_QUANTITY: {
      return {
        ...state,
        quantity: action.quantity,
      };
    }

    case ACTION_TYPES.SET_LEVEL: {
      const level = componentsWhereLevelShouldBeNA.includes(state.component)
        ? "N/A"
        : action.level;
      return {
        ...state,
        level,
      };
    }
    case ACTION_TYPES.SET_NOTES: {
      return {
        ...state,
        notes: action.notes,
      };
    }
    case ACTION_TYPES.SET_CUSTOMER_REFERENCE: {
      return {
        ...state,
        customerReference: action.customerReference,
      };
    }
    case ACTION_TYPES.SET_COMPONENT_SPECIFICATION: {
      return {
        ...state,
        componentSpecification: action.componentSpecification,
      };
    }
    case ACTION_TYPES.SET_RUN_SPACER: {
      // You can't set the run spacer if the component is not a run spacer
      if (state.component !== "Run Spacer") return state;
      return {
        ...state,
        runspacerSize: action.runspacerSize,
      };
    }
    case ACTION_TYPES.SET_SECTION: {
      return {
        ...state,
        section: action.section,
      };
    }
    case ACTION_TYPES.ADD_IMAGE: {
      return {
        ...state,
        images: [...(state.images ?? []), action.image],
      };
    }
    case ACTION_TYPES.REMOVE_IMAGE: {
      return {
        ...state,
        images: state.images.filter((_, i) => i !== action.index),
      };
    }
    case ACTION_TYPES.SET_IMAGE_CAPTION: {
      return {
        ...state,
        images: state.images.map((image, i) => {
          if (i !== action.index) return image;
          return {
            ...image,
            caption: action.caption,
          };
        }),
      };
    }
    case ACTION_TYPES.SET_ACTION_NOTE: {
      return {
        ...state,
        actionNote: action.actionNote,
      };
    }
    case ACTION_TYPES.SET_RACK_ID: {
      return {
        ...state,
        rackId: action.rackId,
      };
    }
    case ACTION_TYPES.SET_AREA_ID: {
      return {
        ...state,
        areaId: action.areaId,
      };
    }
    default:
      return state;
  }
}
