import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

const LogoImage = styled(Image)`
  border-radius: 50%;
  height: ${(props) => (props.size === "xl" ? "100px" : "40px")};
  margin: 0 5px 5px;
  width: ${(props) => (props.size === "xl" ? "100px" : "40px")};
`;

/**
 * Displays a round client logo with (currently) 2 size variants.
 * If no src is provided, a default logo is used.
 */
export default function ClientLogo({ src, size = "md" }) {
  return <LogoImage size={size} src={src ?? "/img/defaults/logo.png"} />;
}

ClientLogo.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf(["xl", "md"]),
};
