/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUser } from "hooks/user";
import { PRIMARY_DATETIME_FORMAT } from "libs/constants";
import { getScheduleTypeLabel } from "libs/schedule";

import MyScheduleActionButtons from "views/components/ActionButtons/set-schedules-my";
import { ScheduledInspectionStarter as InspectionStarter } from "views/components/Modals/InspectionStarter";

const GroupRow = styled.tr`
  td {
    color: ${(props) =>
      props.isScheduledInspection ? "#666666" : "#B7B7B7"} !important;
  }
`;

export function PaddingRow({ showClient, showSite, showStatus, showAssignee }) {
  return (
    <tr>
      {showClient ? <td /> : null}
      {showSite ? <td /> : null}
      {showAssignee ? <td /> : null}
      <td />
      {showStatus ? <td /> : null}
      <td />
      <td />
    </tr>
  );
}

PaddingRow.propTypes = {
  showClient: PropTypes.bool,
  showSite: PropTypes.bool,
  showStatus: PropTypes.bool,
  showAssignee: PropTypes.bool,
};

export default function ScheduleRow({
  isActive = false,
  isScheduledInspection = false,
  padding = false,
  schedule,
  showClient = false,
  showSite = false,
  showStatus = false,
  showAssignee = true,
  showAssignedBy = true,
}) {
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const [showStarterModal, setShowStarterModal] = useState(false);
  const inProgressInspection =
    schedule?.inspections &&
    schedule.inspections.find((inspection) => !inspection.completedAt);
  const offset = moment().utcOffset();
  const canStartInspection = schedule?.Assignee?.id === user?.id;

  if (!schedule) return null;

  return (
    <>
      <GroupRow
        className={padding ? "padding-tr" : ""}
        css={css`
          ${!padding ? "cursor: pointer;" : ""}
        `}
        isScheduledInspection={isScheduledInspection}
      >
        {showClient ? <td>{schedule.client.name}</td> : null}
        {showSite ? <td>{schedule.site.name}</td> : null}
        {showAssignedBy && (
          <td>{schedule.Assigner && schedule.Assigner.name}</td>
        )}
        {showAssignee && <td>{schedule.Assignee && schedule.Assignee.name}</td>}
        <td>{getScheduleTypeLabel(schedule.type)}</td>
        {showStatus && !padding ? (
          <td>
            <span>
              {isActive ? (
                <span
                  style={{
                    color: inProgressInspection ? "#FB5B50" : "#FF7700",
                  }}
                >
                  {inProgressInspection ? "In Progress" : "Pending"}
                </span>
              ) : (
                <span>-</span>
              )}
            </span>
          </td>
        ) : null}
        <td>
          {isScheduledInspection &&
            inProgressInspection &&
            schedule.nextScheduledAt && (
              <span>
                <strong>Started at: </strong>
                {moment
                  .utc(inProgressInspection.createdAt)
                  .utcOffset(offset)
                  .format(PRIMARY_DATETIME_FORMAT)}
                <br />
                <strong>Due by: </strong>
                {moment
                  .utc(schedule.nextScheduledAt)
                  .utcOffset(offset)
                  .format(PRIMARY_DATETIME_FORMAT)}
              </span>
            )}
          {isScheduledInspection &&
            !inProgressInspection &&
            schedule.nextScheduledAt && (
              <span>
                <strong>Starts: </strong>
                {moment
                  .utc(schedule.startAt || schedule.currentScheduledAt)
                  .utcOffset(offset)
                  .format(PRIMARY_DATETIME_FORMAT)}
                <br />
                <strong>Due: </strong>
                {moment
                  .utc(schedule.nextScheduledAt)
                  .utcOffset(offset)
                  .format(PRIMARY_DATETIME_FORMAT)}
              </span>
            )}
          {!isScheduledInspection && schedule.nextScheduledAt && (
            <span>
              <strong>Starts: </strong>
              {moment
                .utc(schedule.dueAt)
                .utcOffset(offset)
                .format(PRIMARY_DATETIME_FORMAT)}
              <br />
              <strong>Due: </strong>
              {moment
                .utc(schedule.nextScheduledAt)
                .utcOffset(offset)
                .format(PRIMARY_DATETIME_FORMAT)}
            </span>
          )}
        </td>
        <MyScheduleActionButtons
          available={isScheduledInspection}
          disabled={!canStartInspection}
          inspectionStarted={inProgressInspection && isScheduledInspection}
          onContinueClick={() => {
            navigate(`/inspections/view/${inProgressInspection.id}`);
          }}
          onStartClick={() => {
            if (isScheduledInspection) {
              setShowStarterModal(true);
            }
          }}
        />
      </GroupRow>
      {showStarterModal && (
        <InspectionStarter
          onHide={() => setShowStarterModal(false)}
          startScheduleId={schedule.ScheduleId}
          startScheduledInspectionId={schedule.id}
        />
      )}
    </>
  );
}

ScheduleRow.propTypes = {
  schedule: PropTypes.shape({
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    Assigner: PropTypes.shape({
      name: PropTypes.string,
    }),
    Assignee: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    type: PropTypes.string,
    nextScheduledAt: PropTypes.string,
    startAt: PropTypes.string,
    currentScheduledAt: PropTypes.string,
    dueAt: PropTypes.string,
    inspections: PropTypes.arrayOf(
      PropTypes.shape({
        completedAt: PropTypes.string,
      }),
    ),
    site: PropTypes.shape({
      name: PropTypes.string,
    }),
    id: PropTypes.string,
    ScheduleId: PropTypes.string,
  }),
  padding: PropTypes.bool,
  isScheduledInspection: PropTypes.bool,
  isActive: PropTypes.bool,
  showSite: PropTypes.bool,
  showClient: PropTypes.bool,
  showStatus: PropTypes.bool,
  showAssignee: PropTypes.bool,
  showAssignedBy: PropTypes.bool,
};
