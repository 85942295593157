/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import PropTypes from "prop-types";

function Switch({ isOn, handleToggle, onColor, label }) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 5px;
        .switch-text {
          padding-right: 15px;
        }
        .switch-checkbox {
          height: 0;
          width: 0;
          visibility: hidden;
        }

        .switch-label {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          width: 70px;
          height: 30px;
          background: #dbdadf;
          border-radius: 100px;
          position: relative;
          transition: background-color 0.2s;
          margin-top: 5px;
        }

        .switch-label .switch-button {
          content: "";
          position: absolute;
          top: 2px;
          left: 2px;
          width: 25px;
          height: 25px;
          border-radius: 25px;
          transition: 0.2s;
          background: #fff;
          box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
        }

        .switch-checkbox:checked + .switch-label .switch-button {
          left: calc(100% - 2px);
          transform: translateX(-100%);
        }

        .switch-label:active .switch-button {
          width: 60px;
        }
      `}
    >
      <h5>
        <span className="switch-text">{label}</span>
      </h5>

      <input
        checked={isOn}
        className="switch-checkbox"
        id="switch-new"
        onChange={handleToggle}
        type="checkbox"
      />
      <label
        className="switch-label"
        htmlFor="switch-new"
        style={{ background: isOn && onColor }}
      >
        <span className="switch-button" />
      </label>
    </div>
  );
}

Switch.propTypes = {
  handleToggle: PropTypes.func,
  isOn: PropTypes.bool,
  label: PropTypes.string,
  onColor: PropTypes.string,
};

export default Switch;
