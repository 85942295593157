/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { getOrganisations } from "libs/api";
import ClientSearch from "views/components/ClientSearch";

function OrganisationSelector({
  disabled,
  onChangeClient,
  onChangeSearchText,
  onClearClient,
  organisationId = null,
  organisations,
  searchText,
}) {
  return (
    <ClientSearch
      changeSearchText={onChangeSearchText}
      clients={organisations}
      disabled={disabled}
      getClientsFunc={getOrganisations}
      isOrganisationSearch
      onChangeClient={onChangeClient}
      onClearClient={onClearClient}
      searchText={searchText}
      selectedClientId={organisationId}
    />
  );
}

OrganisationSelector.propTypes = {
  disabled: PropTypes.bool,
  organisationId: PropTypes.string,
  searchText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  organisations: PropTypes.array,
  onChangeSearchText: PropTypes.func,
  onClearClient: PropTypes.func,
  onChangeClient: PropTypes.func,
  isInstallerAdmin: PropTypes.bool,
};

export default OrganisationSelector;
