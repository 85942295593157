import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { useInspection } from "hooks/inspections";

import PageWrapper from "views/components/common/PageWrapper";
import Loading from "views/components/Loading";
import InspectionRackSelect from "views/components/RackSelector";
import AddDamageButton from "views/pages/Inspection/components/buttons/button-add";
import BlueprintButton from "views/pages/Inspection/components/buttons/button-blueprint";
import CompleteButton from "views/pages/Inspection/components/buttons/button-complete";
import DeleteButton from "views/pages/Inspection/components/buttons/button-delete";
import SortButton from "views/pages/Inspection/components/buttons/button-sort";
import Classification from "views/pages/Inspection/components/classification";
import Info from "views/pages/Inspection/components/Info";
import InspectionAreaSelect from "views/pages/Inspection/components/InspectionAreaSelect";
import InspectionWarehouseSelect from "views/pages/Inspection/components/InspectionWarehouseSelect";
import NoDamage from "views/pages/Inspection/components/NoDamage";
import Section from "views/pages/Inspection/components/section";
import { sortClassifications } from "views/pages/Inspection/helpers";
import useInspectionView from "views/pages/Inspection/inspectionViewReducer";
import { FlexJustifyEnd, Wrapper } from "views/pages/Inspection/styles";

/**
 * Conversation about endpoints for data loading
 * https://econform.slack.com/archives/CD46MQW8M/p1677061767076179?thread_ts=1677014956.596709&cid=CD46MQW8M
 */
export default function PageIsolatedInspection({ InspectionId, onComplete }) {
  // Data
  const {
    inspection,
    isLoading,
    isValidating,
    mutate: refreshInspection,
  } = useInspection(InspectionId);

  // View reducer hook
  const {
    warehouseId,
    areaId,
    rackId,
    setWarehouseId,
    setAreaId,
    setRackId,
    selectArea,
  } = useInspectionView(inspection);

  const [isDescending, setIsDescending] = useState(false);

  if (isLoading) {
    return <Loading />;
  }

  const warehouse = inspection?.warehouses?.find((w) => w.id === warehouseId);
  const areas = warehouse?.areas;
  const rack = warehouse?.racks?.find((r) => r.id === rackId);

  const addedClassificationsForRack =
    inspection?.classifications
      .filter((c) => c.RackId === rack?.id)
      .sort((a, b) => sortClassifications(a, b, isDescending)) ?? [];

  const showAddDamage = inspection.hasInspectionWriteAccess;

  return (
    <PageWrapper>
      <Wrapper>
        <Row>
          <Col sm={8}>
            <Info
              reference={inspection?.reference}
              siteId={inspection?.SiteId}
            />
            <InspectionWarehouseSelect
              onChange={setWarehouseId}
              selectedWarehouseId={warehouseId}
              warehouses={inspection?.warehouses}
            />
            <InspectionAreaSelect
              areas={areas}
              onChange={(id) =>
                id === "All Areas" ? setAreaId(null) : setAreaId(id)
              }
              selectedAreaId={areaId}
            />
            {isLoading || isValidating ? (
              <span>
                <FontAwesomeIcon icon={faSpinner} spin />
              </span>
            ) : null}
          </Col>
          <Col sm={4}>
            <FlexJustifyEnd>
              {showAddDamage ? (
                <AddDamageButton
                  InspectionId={InspectionId}
                  RackId={rackId}
                  warehouseId={warehouseId}
                />
              ) : null}

              <SortButton
                isDescending={isDescending}
                onClick={(direction) => setIsDescending(direction)}
              />
              <BlueprintButton
                inspectionId={InspectionId}
                siteId={inspection?.SiteId}
                warehouseId={warehouseId}
              />
              <CompleteButton
                InspectionId={InspectionId}
                onComplete={onComplete}
              />
            </FlexJustifyEnd>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <InspectionRackSelect
              areas={areas}
              isCompleted={inspection.completedAt}
              onClickArea={selectArea}
              onClickRack={setRackId}
              racks={
                inspection?.warehouses.find((w) => w.id === warehouseId)?.racks
              }
              selectedAreaId={areaId}
              selectedRackId={rackId}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {addedClassificationsForRack.length === 0 ? (
              <NoDamage RackId={rackId} inspection={inspection} />
            ) : null}

            <Section
              icon={<FontAwesomeIcon icon={faPlus} />}
              title="Added Damage"
              total={addedClassificationsForRack.length}
            >
              {addedClassificationsForRack.map((c) => (
                <Classification
                  InspectionId={InspectionId}
                  classification={c}
                  isActive
                  key={c.id}
                >
                  <DeleteButton
                    classificationId={c.id}
                    onDelete={() => refreshInspection()}
                    risk={c.risk}
                  />
                </Classification>
              ))}
            </Section>
          </Col>
        </Row>
      </Wrapper>
    </PageWrapper>
  );
}

PageIsolatedInspection.propTypes = {
  InspectionId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
};
