import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { BlueButton } from './colors';

export default function InspectionContinueButton({ disabled, onClick }) {
  return (
    <BlueButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={faArrowAltCircleRight} />
      Cont. Inspection
    </BlueButton>
  );
}
InspectionContinueButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
