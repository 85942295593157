/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { faFilePdf, faPencilRuler } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { Image, Col, Row, Button, ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useActions } from "hooks/actions";
import { useClient } from "hooks/client";
import { useInspections } from "hooks/inspections";
import { useManagedSchedules, useSitePermission } from "hooks/schedules";
import { useSite } from "hooks/site";
import { parseAddress } from "libs/parseAddress";

import FlatModal from "views/components/Modals/FlatModal";
import SiteBlueprintViewer from "views/components/Modals/SiteBlueprintViewer";
import downloadLocationReport from "libs/downloadLocationReport";
import SiteLocationReport from "./components/site-location-report/index.jsx";

function IconDetail({
  siteId,
  type,
  value,
  initialTab,
  children,
  unclickable,
}) {
  const navigate = useNavigate();
  if (!siteId) {
    return null;
  }
  return (
    <div
      css={{
        height: 100,
        width: 200,
      }}
    >
      <Col className="no-padding" sm={12}>
        <Col className="no-padding" sm={4}>
          <Button
            css={{
              height: 60,
              width: 60,
              borderRadius: 50,
            }}
            onClick={() =>
              !unclickable &&
              navigate(
                `/clients/sites/${siteId}/${type.toLowerCase()}/${initialTab}`,
              )
            }
          >
            <Image
              css={{
                width: "30px",
              }}
              src={`/img/sidebar/${type.toLowerCase()}-icon.png`}
            />
          </Button>
        </Col>
        <Col sm={8}>
          <div
            css={{
              fontSize: 30,
            }}
          >
            {value ?? "-"}
          </div>
          <div>{type}</div>
          {children}
        </Col>
      </Col>
    </div>
  );
}

IconDetail.propTypes = {
  siteId: PropTypes.string,
  type: PropTypes.string.isRequired,
  initialTab: PropTypes.number,
  children: PropTypes.node,
  value: PropTypes.number,
  unclickable: PropTypes.bool,
};

const ModalFooter = styled(ButtonGroup)`
  border-bottom: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  background-color: #494c55;
  display: block;
  position: static;

  .btn {
    float: none;
    width: 150px;
    margin-left: 20px;
  }
`;

function BlueprintIcon({ siteId, onRefresh }) {
  const { permission } = useSitePermission(siteId);
  const { site } = useSite(siteId);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  if (!siteId) return null;
  return (
    <>
      <div
        css={{
          height: 100,
          width: 200,
        }}
      >
        <Col className="no-padding" sm={12}>
          <Col className="no-padding" sm={12}>
            <Button
              css={css`
                height: 60px;
                width: 150px;
                border-radius: 50px;
                background-color: #2d72ed;
                &:hover,
                &:focus {
                  background-color: #2d72ed;
                }
              `}
              disabled={
                !permission.level.includes("organisation") &&
                !site.warehouses.length
              }
              onClick={() => setShowModal(true)}
            >
              <FontAwesomeIcon
                icon={faPencilRuler}
                size="2xl"
                style={{ marginRight: 10 }}
              />
              Blueprint
            </Button>
          </Col>
        </Col>
      </div>
      <FlatModal
        closeButton
        onHide={() => {
          setShowModal(false);
          onRefresh();
        }}
        show={showModal}
        title="Blueprint Information"
      >
        <SiteBlueprintViewer
          SiteId={siteId}
          getData={onRefresh}
          onHide={() => {
            setShowModal(false);
            onRefresh();
          }}
          permission={permission}
        />
        <ModalFooter>
          <Button
            className="cancel"
            onClick={() => {
              setShowModal(false);
              onRefresh();
            }}
          >
            Cancel
          </Button>
          <Button onClick={() => navigate(`/blueprint/${siteId}`)}>View</Button>
        </ModalFooter>
      </FlatModal>
    </>
  );
}

BlueprintIcon.propTypes = {
  siteId: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

function ReportIcon({ siteId }) {
  const { permission } = useSitePermission(siteId);
  const { site } = useSite(siteId);
  const [showModal, setShowModal] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [noDateRange, setNoDateRange] = useState(true);
  const [offloadedRequired, setOffoadedRequired] = useState(true);
  const [reopenedRequired, setReopenedRequired] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const exportButtonDisabled =
    exporting || (!noDateRange && (!fromDate || !toDate));

  const handleExportReport = async () => {
    setExporting(true);
    const filters = {
      offloadedRequired,
      reopenedRequired,
      fromDate,
      toDate,
    };
    // The client and site name are passed to the downloadLocationReport function to generate file name
    await downloadLocationReport(siteId, site.client.name, site.name, filters);
    setExporting(false);
    handleModalClose();
  };

  const handleModalClose = () => {
    // Close the modal
    setShowModal(false);
    // Reset the exporting state
    setExporting(false);
    // Reset the filters
    setNoDateRange(true);
    setOffoadedRequired(true);
    setReopenedRequired(true);
    setFromDate(null);
    setToDate(null);
  };

  if (!siteId) return null;
  return (
    <>
      <div
        css={{
          height: 100,
          width: 200,
        }}
      >
        <Col className="no-padding" sm={12}>
          <Col className="no-padding" sm={12}>
            <Button
              css={css`
                height: 60px;
                width: 180px;
                border-radius: 50px;
                background-color: #2d72ed;
                &:hover,
                &:focus {
                  background-color: #2d72ed;
                }
              `}
              disabled={
                !permission.level.includes("organisation") &&
                !site.warehouses.length
              }
              onClick={() => setShowModal(true)}
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                size="2xl"
                style={{ marginRight: 10 }}
              />
              Location Report
            </Button>
          </Col>
        </Col>
      </div>
      <FlatModal
        closeButton
        onHide={() => {
          setShowModal(false);
        }}
        show={showModal}
        title="Location Report"
      >
        <SiteLocationReport
          SiteId={siteId}
          offloadedRequired={offloadedRequired}
          reopenedRequired={reopenedRequired}
          noDateRange={noDateRange}
          setOffoadedRequired={setOffoadedRequired}
          setReopenedRequired={setReopenedRequired}
          setNoDateRange={setNoDateRange}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
        <ModalFooter>
          <Button className="cancel" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button onClick={handleExportReport} disabled={exportButtonDisabled}>
            {exporting ? `Exporting...` : `Export`}
          </Button>
        </ModalFooter>
      </FlatModal>
    </>
  );
}

ReportIcon.propTypes = {
  siteId: PropTypes.string.isRequired,
};

export default function Info({ siteId }) {
  const navigate = useNavigate();
  const { site, isLoading: isLoadingSite, mutate } = useSite(siteId);
  const { client } = useClient(site?.ClientId);
  const filters = {
    SiteId: siteId,
    amount: 4,
    allSchedules: "true",
    allActions: "true",
  };

  const { total: totalSchedules } = useManagedSchedules(filters);
  const { total: totalInspections } = useInspections(filters);
  const { total: totalActions } = useActions({
    ...filters,
    showGreen: "false",
  });

  const { totalCurrentOffloadedLocations } = site;
  if (!siteId || isLoadingSite) return null;
  const isTescoDemo = site?.ClientName === "Tesco Demo";
  return (
    <div
      css={{
        paddingTop: 45,
        paddingBottom: 45,
        color: "#666666",
        fontSize: 14,
      }}
    >
      <Row>
        <Col sm={6}>
          <Row sm={6} style={{ display: "flex", marginBottom: "15px" }}>
            <Image
              css={{
                display: "inline",
                width: "60px",
                marginLeft: "30px",
                marginRight: "25px",
                borderRadius: "50%",
                boxShadow:
                  "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              }}
              responsive
              src={
                client?.logoUrl ? `${client.logoUrl}` : "/img/defaults/logo.png"
              }
            />
            <span style={{ display: "flex", flexDirection: "column" }}>
              <span
                css={{
                  fontSize: 16,
                  marginTop: "5px",
                }}
              >
                {site?.ClientName}
              </span>

              <span
                css={{
                  fontSize: 20,
                  fontWeight: "bolder",
                  marginTop: "5px",
                }}
              >
                {site.name}
              </span>
            </span>
          </Row>
          <Col sm={8}>
            <div css={{ paddingBottom: 10 }}>{parseAddress(site)}</div>
            <div css={{ paddingBottom: 5 }}>
              <span css={{ fontWeight: "bold" }}>Contact Name:</span>{" "}
              <span>{site.contactName}</span>
            </div>
            <div css={{ paddingBottom: 5 }}>
              <span css={{ fontWeight: "bolder" }}>Contact Number:</span>{" "}
              <span>{site.contactNumber}</span>
            </div>
            <div css={{ paddingBottom: 5 }}>
              <span css={{ fontWeight: "bolder" }}>Contact Email:</span>{" "}
              <span>{site.contactEmail || "Not Provided"}</span>
            </div>
            {site.hasAdminPermission && (
              <Button
                css={{
                  width: 175,
                  height: 40,
                  fontSize: 12,
                  margin: 0,
                  marginTop: 10,
                }}
                onClick={() => navigate(`/clients/sites/${site.id}/edit`)}
              >
                Edit Details
              </Button>
            )}
          </Col>
        </Col>
        <Col sm={6}>
          <Row>
            <Col sm={5}>
              <BlueprintIcon onRefresh={mutate} siteId={siteId} />
              {isTescoDemo ? <ReportIcon siteId={siteId} /> : null}
            </Col>
            <Col sm={5}>
              <IconDetail
                initialTab={1}
                siteId={siteId}
                type="Inspections"
                value={totalInspections}
              />
            </Col>
            <Col sm={5}>
              <IconDetail
                initialTab={2}
                siteId={siteId}
                type="Schedules"
                value={totalSchedules}
              >
                Manage
              </IconDetail>
            </Col>
            {isTescoDemo ? (
              <Col sm={5}>
                <IconDetail
                  siteId={siteId}
                  type="Offloaded"
                  value={totalCurrentOffloadedLocations}
                  unclickable
                />
              </Col>
            ) : null}
            <Col sm={5}>
              <IconDetail
                initialTab={1}
                siteId={siteId}
                type="Actions"
                value={totalActions}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

Info.propTypes = {
  siteId: PropTypes.string.isRequired,
};
