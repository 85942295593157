import PropTypes from "prop-types";
import { Col, Grid } from "react-bootstrap";

import { useActions } from "hooks/actions";
import { useInspections } from "hooks/inspections";
import { useManagedSchedules } from "hooks/schedules";
import { useSites } from "hooks/site";

import { SpacedRow } from "../styles";

import InfoSectionIcon from "./info-section-icon";

export default function InfoIcons({ ClientId }) {
  const filters = { ClientId, allSchedules: true };
  const { total: totalSchedules } = useManagedSchedules(filters);
  const { total: totalInspections } = useInspections(filters);
  const { total: totalActions } = useActions({
    ...filters,
    showGreen: "false",
    allActions: true,
  });
  const { total: totalSites } = useSites(filters);

  return (
    <Grid fluid>
      <SpacedRow>
        <Col sm={6}>
          <InfoSectionIcon
            ClientId={ClientId}
            type="Sites"
            value={totalSites}
          />
        </Col>
        <Col sm={6}>
          <InfoSectionIcon
            ClientId={ClientId}
            initialTab={1}
            type="Inspections"
            value={totalInspections}
          />
        </Col>
      </SpacedRow>
      <SpacedRow>
        <Col sm={6}>
          <InfoSectionIcon
            ClientId={ClientId}
            extraInfo="Manage"
            initialTab={2}
            type="Schedules"
            value={totalSchedules}
          />
        </Col>
        <Col sm={6}>
          <InfoSectionIcon
            ClientId={ClientId}
            initialTab={1}
            type="Actions"
            value={totalActions}
          />
        </Col>
      </SpacedRow>
    </Grid>
  );
}

InfoIcons.propTypes = {
  ClientId: PropTypes.string.isRequired,
};
