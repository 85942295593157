import PropTypes from "prop-types";
import { Checkbox, Col, Grid, Panel, Row } from "react-bootstrap";

import { useInstallerOrganisations } from "hooks/installer-organisations";

import EditorPanel from "views/components/EditorPanel";

export default function InstallerOrganisations({
  onChangeOrganisations,
  clientInstallerOrganisations = [],
}) {
  const { installerOrganisations } = useInstallerOrganisations();
  return (
    <EditorPanel>
      <Panel.Heading>Installer Organisations</Panel.Heading>
      <Panel.Body>
        <Grid fluid>
          <Row>
            <Col sm={12}>
              {installerOrganisations?.length === 0 ? (
                "No installer organisations found."
              ) : (
                <>
                  <h5>Organisations ({clientInstallerOrganisations.length})</h5>
                  <div>
                    {installerOrganisations.map((org) => {
                      const isInstallerOrgSelected =
                        clientInstallerOrganisations.find(
                          (installerOrg) => installerOrg.id === org.id,
                        );
                      return (
                        <div key={org.id}>
                          <Checkbox
                            checked={isInstallerOrgSelected}
                            onChange={async (e) => {
                              if (e.target.checked) {
                                onChangeOrganisations((orgs) => [...orgs, org]);
                              } else {
                                onChangeOrganisations((orgs) =>
                                  orgs.filter(
                                    (installerOrg) =>
                                      installerOrg.id !== org.id,
                                  ),
                                );
                              }
                            }}
                          >
                            {org.name}
                          </Checkbox>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Grid>
      </Panel.Body>
    </EditorPanel>
  );
}

InstallerOrganisations.propTypes = {
  onChangeOrganisations: PropTypes.func.isRequired,
  clientInstallerOrganisations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
