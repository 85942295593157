import styled from "@emotion/styled";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import PropTypes from "prop-types";

const Wrapper = styled(ToggleGroup.Root)`
  display: flex;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
`;

const Item = styled(ToggleGroup.Item)`
  // Reset
  all: unset;

  background-color: #656566;
  flex-grow: 1;
  height: 35px;
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 15px;
  margin-left: 1px;
  text-align: center;
  color: #fff;

  &:first-child {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &[data-state="on"] {
    background-color: #2d72ed;
    color: white;
  }
`;

const Label = styled.div`
  color: #fff;
  font-weight: 800;
`;

export default function FieldOffsetDirection({ offsetDirection, onUpdate }) {
  return (
    <>
      <Label>Offset from (relative to the rack)</Label>
      <Wrapper
        aria-label="Offset from"
        defaultValue="front"
        onValueChange={(value) => {
          onUpdate({ offsetDirection: value });
        }}
        type="single"
        value={offsetDirection}
      >
        <Item aria-label="Left" value="left">
          Left
        </Item>
        <Item aria-label="Front" value="front">
          Front
        </Item>
        <Item aria-label="Back" value="back">
          Back
        </Item>
        <Item aria-label="Right" value="right">
          Right
        </Item>
      </Wrapper>
    </>
  );
}

FieldOffsetDirection.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  offsetDirection: PropTypes.oneOf(["left", "front", "back", "right"]),
};
