import useSWR from "swr";

import {
  getClientInstallerOrganisations,
  getInstallerOrganisations,
} from "libs/api/organisation";

export function useInstallerOrganisations() {
  const { data, error, isLoading } = useSWR(
    "/organisation/installers/",
    getInstallerOrganisations,
  );

  return {
    installerOrganisations: data ?? [],
    isLoading,
    isError: error,
  };
}

export function useClientInstallerOrganisations(ClientId) {
  const { data, error, isLoading } = useSWR(
    ClientId ? `/organisation/installers/${ClientId}` : null,
    async () => getClientInstallerOrganisations(ClientId),
  );

  return {
    clientInstallerOrganisations: data ?? [],
    isLoading,
    isError: error,
  };
}
