/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Button, Col } from "react-bootstrap";

const Caps = styled.span`
  text-transform: capitalize;
`;

function PermissionChanges({
  addedPermissions,
  deletedPermissions,
  clearPermissions,
  userId,
}) {
  if (!addedPermissions.length && !deletedPermissions.length) return null;

  return (
    <Col sm={12}>
      <Col sm={6}>
        {!!addedPermissions.length && (
          <>
            <h5>Added Permissions:</h5>
            {addedPermissions.map((permission) => (
              <div key={`${permission.type}_${permission.name}`}>
                <Caps>{permission.type}</Caps>: {permission.name}
              </div>
            ))}
          </>
        )}
      </Col>
      <Col sm={6}>
        {!!deletedPermissions.length && (
          <>
            <h5>Deleted Permissions:</h5>
            {deletedPermissions.map((permission) => (
              <div key={`${permission.type}_${permission.name}`}>
                <Caps>{permission.type}</Caps>: {permission.name}
              </div>
            ))}
          </>
        )}
      </Col>
      {userId && (
        <Col sm={12}>
          <div
            css={css`
              padding-top: 15px;
              float: right;
            `}
          >
            <Button onClick={clearPermissions}>Clear Changes</Button>
          </div>
        </Col>
      )}
    </Col>
  );
}

PermissionChanges.propTypes = {
  addedPermissions: PropTypes.arrayOf(PropTypes.shape({})),
  deletedPermissions: PropTypes.arrayOf(PropTypes.shape({})),
  clearPermissions: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

export default PermissionChanges;
