import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom"; // compat

import { useClient } from "hooks/client";
import { useSite } from "hooks/site";

import PageWrapper from "views/components/common/PageWrapper";
import Loading from "views/components/Loading";
import ActionsTable from "views/components/MiniTables/ActionsTable";
import InspectionsTable from "views/components/MiniTables/InspectionsTable";
import SchedulesTable from "views/components/MiniTables/SchedulesTable";

import Info from "./Info";
import Ribbon from "./Ribbon";
import Users from "./Users";

export default function Site() {
  const { SiteId } = useParams();
  const { site, isLoading: isLoadingSite } = useSite(SiteId);
  const { client, loading: isLoadingClient } = useClient(site?.ClientId);
  if (isLoadingSite || isLoadingClient)
    return <Loading height="calc(100vh - 50px)" />;
  return (
    <PageWrapper>
      <Helmet>
        <title>
          Econform / {client ? `${client?.name ?? "Client"} / ` : ""}{" "}
          {site?.name ?? "Site"} / Overview
        </title>
      </Helmet>
      {site?.buildMode ? <Ribbon title="Build Mode" /> : null}
      {!isLoadingClient ? <Info client={client} siteId={SiteId} /> : null}
      <Row>
        <Col sm={12}>
          <Row>
            <Col sm={6} xs={12}>
              <InspectionsTable SiteId={SiteId} />
            </Col>
            <Col sm={6} xs={12}>
              <SchedulesTable SiteId={SiteId} />
            </Col>
          </Row>
        </Col>
        <Col sm={12}>
          <Row>
            <Col sm={6} xs={12}>
              <ActionsTable
                filters={{ allActions: true, showGreen: false }}
                siteId={SiteId}
              />
            </Col>
            <Col sm={6} xs={12}>
              <Users siteId={SiteId} />
            </Col>
          </Row>
        </Col>
      </Row>
    </PageWrapper>
  );
}
