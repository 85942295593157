import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { LightBlueButton } from "./colors";

export default function DoActionButton({ disabled, onClick, started }) {
  return (
    <LightBlueButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={faArrowAltCircleRight} size="2xl" />
      {started ? "Cont." : "Start"} Action
    </LightBlueButton>
  );
}

DoActionButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  started: PropTypes.bool,
};
