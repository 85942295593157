import PropTypes from "prop-types";
import { NavItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { useSite } from "hooks/site";

import { StyledNav, BackLink } from "./styles";

function SiteLinks({ url, user }) {
  const { site } = useSite(url[3]);

  if (!user) {
    return null;
  }
  const isOrganisationUser =
    !!user.organisations.length ||
    !!user.clientOrganisation.length ||
    !!user.organisationsAdmin.length;

  const isSingleSiteUser =
    !user.client.length &&
    !user.clientAdmin.length &&
    !isOrganisationUser &&
    user.siteClient.length === 1;

  return (
    <StyledNav>
      {!isSingleSiteUser && (
        <BackLink to={`/clients/${site?.ClientId}/overview`} />
      )}
      <NavItem>
        <NavLink to={`/clients/sites/${url[3]}/overview`}>
          Site Overview
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={`/clients/sites/${url[3]}/warehouses`}>Warehouses</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={`/clients/sites/${url[3]}/inspections`}>
          Inspections
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={`/clients/sites/${url[3]}/schedules`}>Schedules</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={`/clients/sites/${url[3]}/actions`}>Actions</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={`/clients/sites/${url[3]}/users`}>Users</NavLink>
      </NavItem>
    </StyledNav>
  );
}

export default SiteLinks;

SiteLinks.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  url: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

SiteLinks.defaultProps = {
  url: [],
  user: null,
};
