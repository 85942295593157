import PropTypes from "prop-types";
import { FormGroup, Col, Radio } from "react-bootstrap";

export default function FieldRelativeAbsolute({
  relative,
  disabled,
  onUpdate,
}) {
  return (
    <FormGroup className="position-radio-buttons">
      <Col sm={6}>
        <Radio
          checked={relative}
          disabled={disabled}
          inline
          onChange={() => onUpdate({ relative: true })}
        >
          Relative
        </Radio>
      </Col>
      <Col sm={6}>
        <Radio
          checked={!relative}
          disabled={disabled}
          inline
          onChange={() => onUpdate({ relative: false })}
        >
          Absolute
        </Radio>
      </Col>
    </FormGroup>
  );
}

FieldRelativeAbsolute.propTypes = {
  disabled: PropTypes.bool,
  relative: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
};
