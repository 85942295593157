/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import {
  Col,
  ControlLabel,
  FormControl,
  Grid,
  Panel,
  Row,
} from "react-bootstrap";

import EditorPanel from "views/components/EditorPanel";

export default function SiteDetailsPanel({
  site,
  onChangeField,
  groups = [],
  SiteGroupId,
  NewGroupName,
}) {
  if (!site) return null;
  return (
    <EditorPanel>
      <Panel.Heading>Site Details</Panel.Heading>
      <Panel.Body>
        <Grid fluid>
          <Row>
            <Col sm={6}>
              <ControlLabel>
                Site Name
                <span className="required">*</span>
              </ControlLabel>
              <FormControl
                onChange={(e) => onChangeField("name", e.target.value)}
                placeholder="Enter Site Name (e.g. Glasgow)"
                value={site.name}
              />
            </Col>
            <Col sm={6}>
              <ControlLabel>Site Contact</ControlLabel>

              <FormControl
                onChange={(e) => onChangeField("contactName", e.target.value)}
                placeholder="Enter Contact Name"
                value={site.contactName}
              />
            </Col>

            <Col sm={6}>
              <ControlLabel>
                Contact Number
                <span className="required">*</span>
              </ControlLabel>

              <FormControl
                onChange={(e) => onChangeField("contactNumber", e.target.value)}
                placeholder="Enter Contact Number"
                value={site.contactNumber}
              />
            </Col>
            <Col sm={6}>
              <ControlLabel>
                Site Email
                <span className="required">*</span>
              </ControlLabel>

              <FormControl
                onChange={(e) => onChangeField("contactEmail", e.target.value)}
                placeholder="Enter Contact Email"
                value={site.contactEmail}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div>
                <ControlLabel>Site Group</ControlLabel>
                <FormControl
                  componentClass="select"
                  onChange={(e) =>
                    this.setState({ SiteGroupId: e.target.value })
                  }
                  value={SiteGroupId || site.SiteGroupId}
                >
                  <option value="no-group">No Group</option>
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                  <option value="new-group">Create New Group...</option>
                </FormControl>
              </div>
            </Col>
          </Row>
          {SiteGroupId === "new-group" && (
            <Row>
              <Col sm={12}>
                <div>
                  <ControlLabel>New Group</ControlLabel>
                </div>
                <div>
                  <FormControl
                    onChange={(e) =>
                      this.setState({ NewGroupName: e.target.value })
                    }
                    placeholder="New Group Name"
                    value={NewGroupName}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Grid>
      </Panel.Body>
    </EditorPanel>
  );
}

SiteDetailsPanel.propTypes = {
  onChangeField: PropTypes.func.isRequired,
};
