import { faFileDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { DarkBlueButton } from "./colors";

export default function ExportButton({ disabled, onClick, isDownloading }) {
  return (
    <DarkBlueButton disabled={disabled || isDownloading} onClick={onClick}>
      {isDownloading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <FontAwesomeIcon icon={faFileDownload} />
      )}
      Export
    </DarkBlueButton>
  );
}

ExportButton.propTypes = {
  disabled: PropTypes.bool,
  isDownloading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
