export function getSplicedFrameHeight(frameTemplate, isRear) {
  let totalHeight = isRear ? 0 : frameTemplate.height;
  const {
    splicedFrames,
    sacLeg,
    sacLegHeight,
    sleeve,
    sleeveHeight,
    sleeveSpacer,
    sacLegRear,
    sacLegHeightRear,
    sleeveRear,
    sleeveHeightRear,
    sleeveSpacerRear,
  } = frameTemplate;
  const frontFrames = splicedFrames.filter((f) => !f.isRear);
  const rearFrames = splicedFrames.filter((f) => f.isRear);
  if (isRear && rearFrames) {
    for (let i = 0; i < rearFrames.length; i += 1) {
      totalHeight += rearFrames[i].height;
    }
    if (sacLegRear && !sleeveRear) {
      totalHeight += sacLegHeightRear;
    }
    if (sleeveRear) {
      totalHeight += sleeveHeightRear || 0;
      totalHeight += sleeveSpacerRear || 0;
    }
  } else {
    if (frontFrames) {
      for (let i = 0; i < frontFrames.length; i += 1) {
        totalHeight += frontFrames[i].height;
      }
    }
    if (sacLeg && !sleeve) {
      totalHeight += sacLegHeight;
    }
    if (sleeve) {
      totalHeight += sleeveHeight || 0;
      totalHeight += sleeveSpacer || 0;
    }
  }
  return totalHeight;
}

export function getSplicedFrameTypes(frameTemplate) {
  const { splicedFrames } = frameTemplate;
  const frameTemplateTypes = [frameTemplate.type.name];
  if (splicedFrames) {
    for (let i = 0; i < splicedFrames.length; i += 1) {
      frameTemplateTypes.push(splicedFrames[i].type.name);
    }
  }
  return frameTemplateTypes.length > 1
    ? `[${frameTemplateTypes}]`
    : frameTemplate.type.name;
}
