/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";

import { AMBER_COLOUR, GREEN_COLOUR, RED_COLOUR } from "libs/constants";

function RiskCircles({ hideRed, hideAmber, hideGreen, type }) {
  const amberRisk = AMBER_COLOUR;
  const redRisk = RED_COLOUR;
  const greenRisk = GREEN_COLOUR;
  const circleSize = 20;
  const circleStyle = {
    display: "flex",
    borderRadius: "50%",
    width: circleSize,
    height: circleSize,
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
  };
  const circleTextStyle = {
    fontSize: "9px",
    textAlign: "center",
    color: "white",
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {!hideAmber && (
        <div
          css={css`
            background-color: ${amberRisk};
          `}
          style={circleStyle}
        >
          <h4 style={circleTextStyle}>A</h4>
        </div>
      )}
      {!hideRed && (
        <div
          css={css`
            background-color: ${redRisk};
          `}
          style={circleStyle}
        >
          <h4 style={circleTextStyle}>R</h4>
        </div>
      )}
      {!hideGreen && type !== "action" && (
        <div
          css={css`
            background-color: ${greenRisk};
          `}
          style={circleStyle}
        >
          <h4 style={circleTextStyle}>G</h4>
        </div>
      )}
    </div>
  );
}

RiskCircles.propTypes = {
  hideAmber: PropTypes.bool,
  hideGreen: PropTypes.bool,
  hideRed: PropTypes.bool,
  type: PropTypes.string,
};

export default RiskCircles;
