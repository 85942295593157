import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

import { getSplicedFrameHeight } from "libs/getSplicedFrameData";

import { LightStyledFormControl } from "../../SharedControls/Generic";

import FrameTemplateSections from "./FrameTemplateSections";
import SplicesSection from "./SplicesSection";

const StyledTable = styled(Table)`
  color: ${(props) => (props.disabled ? "#919399" : "#fff")};
  background-color: #30333c !important;
  border-bottom: 0;
  .form-control {
    margin-bottom: 0px;
  }

  thead tr th {
    border-bottom: 0 !important;
  }
`;

export default function Splices({
  disabled,
  frameTemplate,
  updateFrameTemplate,
  frameTypes,
  isRear,
}) {
  const totalHeight = getSplicedFrameHeight(frameTemplate, isRear);
  const sacLeg = isRear ? frameTemplate.sacLegRear : frameTemplate.sacLeg;
  const sleeve = isRear ? frameTemplate.sleeveRear : frameTemplate.sleeve;
  const sacLegHeight = isRear
    ? frameTemplate.sacLegHeightRear
    : frameTemplate.sacLegHeight;
  const sleeveHeight = isRear
    ? frameTemplate.sleeveHeightRear
    : frameTemplate.sleeveHeight;
  const sleeveSpacer = isRear
    ? frameTemplate.sleeveSpacerRear
    : frameTemplate.sleeveSpacer;
  return (
    <StyledTable disabled={disabled}>
      <thead>
        <tr>
          <th>Section</th>
          <th>Height (mm)</th>
          <th>Type</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {sacLeg && !sleeve && (
          <tr>
            <td>Sac Leg</td>
            <td>
              <LightStyledFormControl
                disabled={disabled}
                min={0}
                onChange={(e) => {
                  isRear
                    ? updateFrameTemplate({
                        sacLegHeightRear: parseInt(e.target.value, 10),
                      })
                    : updateFrameTemplate({
                        sacLegHeight: parseInt(e.target.value, 10),
                      });
                }}
                type="number"
                value={sacLegHeight ?? 0}
              />
            </td>
          </tr>
        )}
        {sleeve && (
          <tr>
            <td>Sleeve</td>
            <td>
              <LightStyledFormControl
                disabled={disabled}
                min={0}
                onChange={(e) =>
                  isRear
                    ? updateFrameTemplate({
                        sleeveHeightRear: parseInt(e.target.value, 10),
                      })
                    : updateFrameTemplate({
                        sleeveHeight: parseInt(e.target.value, 10),
                      })
                }
                type="number"
                value={sleeveHeight}
              />
            </td>
          </tr>
        )}
        {sleeve && sleeveSpacer > 0 && (
          <tr>
            <td>Spacer</td>
            <td>
              <LightStyledFormControl
                disabled={disabled}
                min={0}
                onChange={(e) => {
                  isRear
                    ? updateFrameTemplate({
                        sleeveSpacerRear: parseInt(e.target.value, 10),
                      })
                    : updateFrameTemplate({
                        sleeveSpacer: parseInt(e.target.value, 10),
                      });
                }}
                type="number"
                value={sleeveSpacer}
              />
            </td>
          </tr>
        )}
        {!isRear && (
          <FrameTemplateSections
            disabled={disabled}
            frameTemplate={frameTemplate}
            frameTypes={frameTypes}
            updateFrameTemplate={updateFrameTemplate}
          />
        )}
        <SplicesSection
          disabled={disabled}
          frameTypes={frameTypes}
          splicedFrames={
            isRear
              ? frameTemplate.splicedFrames.filter((s) => s.isRear)
              : frameTemplate.splicedFrames.filter((s) => !s.isRear)
          }
          updateSplicedFrames={(newSplicedFrames) => {
            updateFrameTemplate({ splicedFrames: newSplicedFrames });
          }}
          isRear={isRear}
        />
        <tr>
          <td>Total</td>
          <td>{`${totalHeight || 0}mm`}</td>
        </tr>
      </tbody>
    </StyledTable>
  );
}

Splices.propTypes = {
  disabled: PropTypes.bool,
  frameTemplate: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    splicedFrames: PropTypes.array,
    sleeveSpacer: PropTypes.number,
    sleeve: PropTypes.bool,
    sleeveHeight: PropTypes.number,
    sacLegHeight: PropTypes.number,
    sacLeg: PropTypes.bool,
    sleeveSpacerRear: PropTypes.number,
    sleeveRear: PropTypes.bool,
    sleeveHeightRear: PropTypes.number,
    sacLegHeightRear: PropTypes.number,
    sacLegRear: PropTypes.bool,
    rearFrames: PropTypes.arrayOf(
      PropTypes.shape({
        height: PropTypes.number,
        FrameTypeId: PropTypes.string,
      }),
    ),
  }),
  updateFrameTemplate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  frameTypes: PropTypes.array,
  isRear: PropTypes.bool,
};
