import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Form, FormGroup, Col } from "react-bootstrap";

import { useManufacturers } from "hooks/manufacturers";

import { useFrameDepths } from "hooks/templates";
import { useManufacturerSeries } from "hooks/types";

import {
  ControlLabelWithLoading,
  StyledFormControl,
  YellowHeading,
} from "../SharedControls/Generic";

const Wrapper = styled.div`
  font-size: 13px;
  line-height: 175%;
  padding: 5px 20px;
`;

export default function DetailsSection({
  bayTemplate,
  isCopyBay,
  updateBayTemplate,
  SiteId,
}) {
  const { manufacturers, isLoading: isLoadingManufacturers } = useManufacturers(
    {
      SiteId,
      type: "bay",
    },
  );
  const { series } = useManufacturerSeries(bayTemplate.ManufacturerId);
  const { depths } = useFrameDepths(
    bayTemplate.ManufacturerId,
    SiteId,
    bayTemplate.SeriesId,
  );

  /**
   * Effects: If we only have one [manufactuer, series, depth] then auto-select
   */

  // Series
  useEffect(() => {
    if (series.length === 1) {
      const onlyId = series[0].id;
      if (onlyId !== bayTemplate.SeriesId) {
        updateBayTemplate({ SeriesId: onlyId });
      }
    }
  }, [bayTemplate.SeriesId, series, updateBayTemplate]);

  // Depth
  useEffect(() => {
    if (depths.length === 1) {
      if (depths[0] !== bayTemplate.depth) {
        updateBayTemplate({ depth: depths[0] });
      }
    }
  }, [depths, bayTemplate, updateBayTemplate]);

  // Manufactuer
  useEffect(() => {
    if (manufacturers.length === 1) {
      const onlyId = manufacturers[0].id;
      if (onlyId !== bayTemplate.ManufacturerId) {
        updateBayTemplate({ ManufacturerId: onlyId });
      }
    }
  }, [bayTemplate.ManufacturerId, manufacturers, updateBayTemplate]);

  return (
    <Wrapper>
      <Form
        horizontal
        onKeyPress={(e) => (e.which === 13 ? e.preventDefault() : "")}
      >
        <YellowHeading>Bay Details</YellowHeading>
        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading disabled label="Name" />
          </Col>
          <Col sm={8}>
            <StyledFormControl
              disabled
              onChange={(e) => updateBayTemplate({ name: e.target.value })}
              placeholder="Name"
              type="text"
              value={bayTemplate.name ?? ""}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading
              disabled={bayTemplate.id || isCopyBay}
              htmlFor="manufacturer-select"
              isLoading={isLoadingManufacturers}
              label="Manufacturer"
            />
          </Col>
          <Col sm={8}>
            <StyledFormControl
              componentClass="select"
              disabled={bayTemplate.id || isCopyBay}
              id="manufacturer-select"
              onChange={(e) =>
                updateBayTemplate({ ManufacturerId: e.target.value })
              }
              value={bayTemplate.ManufacturerId ?? ""}
            >
              {manufacturers.length > 1 ? (
                <option disabled value="">
                  Select Manufacturer
                </option>
              ) : null}
              {manufacturers.map((manufacturer) => (
                <option key={manufacturer.id} value={manufacturer.id}>
                  {manufacturer.name}
                </option>
              ))}
            </StyledFormControl>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading
              disabled={
                !bayTemplate.ManufacturerId || bayTemplate.id || isCopyBay
              }
              htmlFor="series-select"
              label="Series"
            />
          </Col>
          <Col sm={8}>
            <StyledFormControl
              componentClass="select"
              disabled={
                !bayTemplate.ManufacturerId || bayTemplate.id || isCopyBay
              }
              id="series-select"
              onChange={(e) => updateBayTemplate({ SeriesId: e.target.value })}
              value={bayTemplate.SeriesId ?? ""}
            >
              {series.length > 1 ? (
                <option disabled value="">
                  Select Series
                </option>
              ) : null}
              {series.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </StyledFormControl>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading
              disabled={!bayTemplate.SeriesId || !bayTemplate.ManufacturerId}
              label="Width (mm)"
            />
          </Col>
          <Col sm={8}>
            <StyledFormControl
              disabled={!bayTemplate.SeriesId || !bayTemplate.ManufacturerId}
              min="0"
              onChange={(e) =>
                updateBayTemplate({ width: parseInt(e.target.value, 10) })
              }
              type="number"
              value={bayTemplate.width ?? 0}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading
              disabled={
                !bayTemplate.SeriesId ||
                bayTemplate.id ||
                isCopyBay ||
                !bayTemplate.ManufacturerId
              }
              label="Depth (mm)"
            />
          </Col>
          <Col sm={8}>
            <StyledFormControl
              componentClass="select"
              disabled={
                !bayTemplate.SeriesId ||
                bayTemplate.id ||
                isCopyBay ||
                !bayTemplate.ManufacturerId
              }
              onChange={(e) =>
                updateBayTemplate({ depth: parseInt(e.target.value, 10) })
              }
              placeholder="Bay Depth"
              value={bayTemplate.depth ?? ""}
            >
              {depths.length > 1 ? (
                <option value="">Select Depth</option>
              ) : null}
              {depths.map((depth) => (
                <option key={depth} value={depth}>
                  {depth}
                </option>
              ))}
            </StyledFormControl>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading
              disabled={!bayTemplate.ManufacturerId}
              label="1st Beam pitch (mm)"
            />
          </Col>
          <Col sm={8}>
            <StyledFormControl
              disabled={!bayTemplate.ManufacturerId}
              min="0"
              onChange={(e) =>
                updateBayTemplate({
                  firstPitch: parseInt(e.target.value, 10) ?? 0,
                })
              }
              placeholder="(optional)"
              type="number"
              value={bayTemplate.firstPitch || null}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Col sm={4}>
            <ControlLabelWithLoading
              disabled={!bayTemplate.ManufacturerId}
              label="Beam pitch (mm)"
            />
          </Col>
          <Col sm={8}>
            <StyledFormControl
              disabled={!bayTemplate.ManufacturerId}
              min="0"
              onChange={(e) =>
                updateBayTemplate({ pitch: parseInt(e.target.value, 10) ?? 0 })
              }
              placeholder="(optional)"
              type="number"
              value={bayTemplate.pitch || null}
            />
          </Col>
        </FormGroup>
      </Form>
    </Wrapper>
  );
}

DetailsSection.propTypes = {
  bayTemplate: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    ManufacturerId: PropTypes.string,
    SeriesId: PropTypes.string,
    pitch: PropTypes.number,
    width: PropTypes.number,
    depth: PropTypes.number,
    firstPitch: PropTypes.number,
  }),
  SiteId: PropTypes.string.isRequired,
  isCopyBay: PropTypes.bool,
  updateBayTemplate: PropTypes.func.isRequired,
};
