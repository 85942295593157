import styled from '@emotion/styled';
import { Checkbox } from 'react-bootstrap';

export const RowCheckbox = styled(Checkbox)`
  margin: 0 0 0 20px !important;
  width: '0px';
  label {
    input {
      background-color: #00000000;
      border-radius: 0px;
      border: solid 1px #979797;
      height: 15px;
      margin-left: 10px;
      width: 15px;
    }
    input:checked {
      background-color: #ffcf33;
    }
    input:after {
      border-radius: 0px;
      height: 7px;
      left: 3px;
      top: 3px;
      width: 7px;
    }
  }
`;
