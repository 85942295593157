import PropTypes from "prop-types";
import { NavItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { StyledNav, BackLink } from "./styles";

function ClientLinks(props) {
  const { url, user, hideBack } = props;
  if (!user) {
    return null;
  }
  return (
    <StyledNav>
      {!hideBack && <BackLink to="/clients" />}
      <NavItem>
        <NavLink to={`/clients/${url[2]}/overview`}>Client Overview</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={`/clients/${url[2]}/sites`}>Sites</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={`/clients/${url[2]}/inspections`}>Inspections</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={`/clients/${url[2]}/schedules`}>Schedules</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={`/clients/${url[2]}/actions`}>Actions</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to={`/clients/${url[2]}/users`}>Users</NavLink>
      </NavItem>
    </StyledNav>
  );
}

ClientLinks.propTypes = {
  hideBack: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  url: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

export default ClientLinks;
