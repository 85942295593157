import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

// Wrap Tabs and Table Header Buttons
export const TableControlsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

// Wrap Table Header Buttons
export const MiniFilterWrapper = styled.div`
  display: flex;
`;

export const MiniFilterButton = styled(Button)`
  background-color: ${(props) => (props.selected ? 'rgb(45, 114, 237)' : '#ffffff')} !important;
  border-radius: 5px !important;
  color: #333333;
  margin: 5px 0 10px 5px !important;
  padding: 5px 20px !important;
  color: ${(props) => (props.selected ? '#fff' : '#333333')} !important;

  svg {
    margin-right: 5px;
    color: ${(props) => (props.selected ? '#fff' : '#2870ed')} !important;
  }

  &:focus {
    color: ${(props) => (props.selected ? '#fff' : '#333333')} !important;
  }

  &:disabled {
    background-color: #ffffff !important;
    color: #333333 !important;
    transition: background-color 0.3s;
    cursor: not-allowed;
  }
`;

export const MiniFilterTab = styled(Button)`
  background-color: ${(props) => (props.selected ? 'rgb(217, 223, 238)' : '#ffffff')} !important;
  border-radius: 5px !important;
  margin: 5px 0 10px 5px !important;
  padding: 5px 20px !important;
  color: ${(props) => (props.selected ? 'rgb(40, 112, 237);' : '#333333')} !important;

  &:hover,
  &:active,
  &:focus {
    color: ${(props) => (props.selected ? 'rgb(40, 112, 237);' : '#333333')} !important;
  }

  svg {
    margin-right: 5px;
    color: #2870ed;
  }

  &:disabled {
    background-color: #ffffff !important;
    color: #333333 !important;
    transition: background-color 0.3s;
    cursor: not-allowed;
  }
`;
