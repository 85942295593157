import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import BasePlate from "./BasePlate";
import Colour from "./Colour";
import FrameDepth from "./FrameDepth";
import Manufacturer from "./Manufacturer";
import Name from "./Name";
import Series from "./Series";

const PaddedForm = styled(Form)`
  padding: 10px 20px 0px 20px;

  .control-label {
    text-align: left !important;
  }
`;

export default function SectionDetails({
  frameTemplate,
  isCopy,
  updateFrameTemplate,
  siteId,
  SeriesId,
}) {
  return (
    <PaddedForm
      horizontal
      onKeyPress={(e) => (e.which === 13 ? e.preventDefault() : "")}
    >
      <Name name={frameTemplate.name} />
      <Manufacturer
        ManufacturerId={frameTemplate.ManufacturerId}
        disabled={!!frameTemplate.id || isCopy}
        onChange={(val) =>
          updateFrameTemplate({
            ManufacturerId: val,
            SeriesId: null,
            BasePlateId: null,
          })
        }
      />
      <Series
        ManufacturerId={frameTemplate.ManufacturerId}
        SeriesId={SeriesId}
        SiteId={siteId}
        disabled={!!frameTemplate.id || isCopy}
        onChange={(val) =>
          updateFrameTemplate({ SeriesId: val, BasePlateId: null })
        }
      />
      <BasePlate
        BasePlateId={frameTemplate.BasePlateId}
        ManufacturerId={frameTemplate.ManufacturerId}
        SeriesId={SeriesId}
        disabled={!frameTemplate.ManufacturerId || !SeriesId}
        onChange={(val) => updateFrameTemplate({ BasePlateId: val })}
      />
      <FrameDepth
        depth={frameTemplate.depth}
        disabled={
          !frameTemplate.ManufacturerId ||
          !SeriesId ||
          isCopy ||
          frameTemplate.isFrameTemplateUsed
        }
        onChange={(val) => updateFrameTemplate({ depth: val })}
      />
      <Colour
        ColourId={frameTemplate.ColourId}
        ManufacturerId={frameTemplate.ManufacturerId}
        colourOther={frameTemplate.colourOther}
        disabled={!frameTemplate.ManufacturerId || !SeriesId}
        onChange={updateFrameTemplate}
      />
    </PaddedForm>
  );
}

SectionDetails.propTypes = {
  frameTemplate: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    ManufacturerId: PropTypes.string,
    SeriesId: PropTypes.string,
    BasePlateId: PropTypes.string,
    depth: PropTypes.number,
    ColourId: PropTypes.string,
    colourOther: PropTypes.string,
    isFrameTemplateUsed: PropTypes.bool,
  }).isRequired,
  isCopy: PropTypes.bool,
  updateFrameTemplate: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  SeriesId: PropTypes.string,
};
