import { faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { GreenButton, LightBlueButton, DarkBlueButton } from "./colors";

export default function ViewWarehouseButton({
  disabled,
  onClick,
  isBuildMode,
  hasWarehouses,
}) {
  let ColouredButton = GreenButton;
  let text = "Start";
  if (!isBuildMode) {
    ColouredButton = LightBlueButton;
    text = "View";
  }
  if (isBuildMode && hasWarehouses) {
    ColouredButton = DarkBlueButton;
    text = "Continue";
  }
  return (
    <ColouredButton disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon icon={faWarehouse} />
      {text}
    </ColouredButton>
  );
}

ViewWarehouseButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isBuildMode: PropTypes.bool,
  hasWarehouses: PropTypes.bool,
};
