import moment from "moment";

import { getInspectionPDF, getActionPDF } from "./api";
import { PRIMARY_DATE_FORMAT } from "./constants";

export default async function downloadPDF(
  InspectionId,
  inspection,
  draft = false,
  action = false,
) {
  const res = action
    ? await getActionPDF(InspectionId)
    : await getInspectionPDF(InspectionId);
  const blob = await res.blob();
  const fileURL = URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.href = fileURL;
  tempLink.setAttribute(
    "download",
    `${inspection.ClientName} - ${inspection.SiteName} - ${
      action ? "Action" : "Insp"
    } - ${inspection.reference} - ${moment(
      action ? inspection.signedOffAt || new Date() : inspection.completedAt,
    ).format(PRIMARY_DATE_FORMAT)} ${draft ? "(DRAFT)" : ""}.pdf`,
  );
  tempLink.click();
}
