import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom'; // compat

import {
  ActionButtonsOverlay,
  EditWarehouseButton,
  ViewWarehouseButton,
} from 'views/components/ActionButtons';

export default function WarehousesActionButton({ site, permission, WarehouseId, user }) {
  const navigate = useNavigate();
  const { warehouses, buildMode } = site;
  const viewDisabled = warehouses.length && !permission.level.includes('organisation');
  const { permissions } = user;
  const { clientAdmin, organisation } = permissions;
  const userCanBuild =
    !organisation.filter((c) => c === site.ClientId).length ||
    !clientAdmin.filter((c) => c === site.ClientId).length;
  return (
    <ActionButtonsOverlay>
      <ViewWarehouseButton
        disabled={!(!buildMode || userCanBuild)}
        hasPermission={!permission.level.includes('client')}
        hasWarehouses={warehouses?.length}
        isBuildMode={buildMode}
        onClick={() => navigate(`/blueprint/${site.id}/${WarehouseId}`)}
      />
      <EditWarehouseButton
        disabled={viewDisabled}
        onClick={() => navigate(`/warehouses/${WarehouseId}/edit`)}
      />
    </ActionButtonsOverlay>
  );
}

WarehousesActionButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  site: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  permission: PropTypes.object.isRequired,
  WarehouseId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
};
