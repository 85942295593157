import { faPencilRuler } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { LightBlueButton } from "./colors";

export default function ViewBlueprintButton({ disabled, href }) {
  return (
    <LightBlueButton disabled={disabled} href={href}>
      <FontAwesomeIcon icon={faPencilRuler} />
      View Blueprint
    </LightBlueButton>
  );
}

ViewBlueprintButton.propTypes = {
  disabled: PropTypes.bool,
  href: PropTypes.string.isRequired,
};
