/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Button, ButtonGroup } from "react-bootstrap";

import { userLogOut, userPasswordChange } from "libs/api";

import FlatModal from "views/components/Modals/FlatModal";

function ChangePasswordModal({ user, onHide }) {
  const [error, setError] = useState(null);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  useEffect(() => {
    setError("");
    const passwordIsValid = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(
      newPassword,
    );
    /** Password security validation */
    if (
      newPassword?.length &&
      (!(newPassword?.length > 6) || !passwordIsValid)
    ) {
      setError(
        "Password must be alphanumeric and be at least 7 characters long",
      );
    } else {
      setError("");
    }
  }, [currentPassword, newPassword, confirmPassword]);
  const changePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
    } else {
      try {
        await userPasswordChange(currentPassword, newPassword);
        setError("");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        localStorage.removeItem("user");
        window.location = "/";
      } catch (e) {
        setError(e.message ?? "Problem changing password, try again");
      }
    }
  };

  const onLogout = async () => {
    try {
      await userLogOut();
      window.location.assign("/");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
    }
  };

  return (
    <FlatModal onHide={onHide} show title="Change your Password">
      <div
        css={css`
          .error-message {
            color: red;
          }
          .info-message {
            font-size: 12pt;
            margin-bottom: 5px;
          }
          .btn-group {
            float: right;
          }
          .btn {
            width: 150px;
            margin: 10px 5px;
          }
          .title {
            font-size: 14pt;
            padding: 10px 0px;
          }
          .form-control {
            background-color: #eee;
            color: #000;
            margin: 10px 0px;
          }
          color: black;
        `}
      >
        <div className="title">You must change your password to continue</div>
        <div className="info">
          Reason: {user.resetReason || "No Reason Provided"}
        </div>
        <div className="input">
          <input
            className="form-control"
            onChange={(e) => setCurrentPassword(e.target.value)}
            placeholder="Current Password"
            type="password"
            value={currentPassword}
          />
          <input
            className="form-control"
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="New Password"
            type="password"
            value={newPassword}
          />
          <input
            className="form-control"
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
            type="password"
            value={confirmPassword}
          />
        </div>
        <ButtonGroup>
          <Button onClick={onLogout}>Log Out</Button>
          <Button
            disabled={!currentPassword || !newPassword || !confirmPassword}
            onClick={changePassword}
          >
            Change Password
          </Button>
        </ButtonGroup>
        <div className="error-message">{error}</div>
      </div>
    </FlatModal>
  );
}

ChangePasswordModal.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    resetReason: PropTypes.string,
  }),
  onHide: PropTypes.func,
};
export default ChangePasswordModal;
