import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Button, ButtonGroup } from "react-bootstrap";

import FooterWrapper from "views/components/BuildModeSidebar/SharedControls/Footer";

const ActionButton = styled(Button)`
  width: 100px;
`;

export default function Footer({
  disableSave,
  errorMsg,
  isNew,
  onDelete,
  onSave,
}) {
  return (
    <FooterWrapper errorMsg={errorMsg}>
      <ButtonGroup>
        <ActionButton
          className={isNew ? "cancel" : "delete-button"}
          onClick={onDelete}
        >
          {isNew ? "Cancel" : "Delete"}
        </ActionButton>
        <ActionButton disabled={disableSave} onClick={onSave}>
          {isNew ? "Add" : "Save"}
        </ActionButton>
      </ButtonGroup>
    </FooterWrapper>
  );
}

Footer.propTypes = {
  disableSave: PropTypes.bool,
  errorMsg: PropTypes.string,
  isNew: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
