/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import {
  Col,
  FormControl,
  Panel,
  FormGroup,
  ControlLabel,
  Checkbox,
} from "react-bootstrap";

import validateEmail from "libs/validateEmail";
import FormValidationText from "views/components/common/FormValidationText";
import EditorPanel from "views/components/EditorPanel";

function UserDetails({
  user = {},
  isCurrentUserCreator,
  ClientId,
  UrlSiteId,
  onUpdateUserField,
  clearPermissions,
  isInstallerOrganisation,
}) {
  const isEdit = !!user.id;

  return (
    <EditorPanel>
      <Panel.Heading>User Details</Panel.Heading>
      <Panel.Body>
        <FormGroup controlId="formHorizontalWidth">
          <Col sm={6}>
            <Col sm={12}>
              <ControlLabel>
                User Name
                <span className="required">*</span>
              </ControlLabel>
            </Col>
            <Col sm={12}>
              <FormControl
                disabled={isEdit}
                onChange={(e) => onUpdateUserField("name", e.target.value)}
                value={user.name}
              />
            </Col>
          </Col>
          <Col sm={6}>
            <Col sm={12}>
              <ControlLabel>
                User Email
                <span className="required">*</span>
              </ControlLabel>
            </Col>
            <Col sm={12}>
              <FormControl
                disabled={isEdit}
                onChange={(e) => onUpdateUserField("email", e.target.value)}
                value={user.email}
              />
              <FormValidationText
                show={user.email && !validateEmail(user.email)}
                text="Invalid Email"
              />
            </Col>
          </Col>
          <Col sm={6}>
            <Col sm={12}>
              <Col sm={6}>
                <ControlLabel>
                  User Type
                  <span className="required">*</span>
                </ControlLabel>
              </Col>
              <Col sm={6}>
                {user.userType === "SARI" && (
                  <ControlLabel>SARI Reg. Number</ControlLabel>
                )}
              </Col>
            </Col>
            <Col sm={6}>
              <Col sm={12}>
                {(!ClientId || UrlSiteId) && !isInstallerOrganisation && (
                  <Checkbox
                    checked={user.userType === "SARI"}
                    disabled={ClientId || user?.id}
                    onChange={(e) => {
                      onUpdateUserField(
                        "userType",
                        e.target.checked ? "SARI" : null,
                      );
                      clearPermissions();
                    }}
                  >
                    SARI
                  </Checkbox>
                )}
                {!isInstallerOrganisation && (
                  <Checkbox
                    checked={user.userType === "PRRS"}
                    disabled={ClientId || user?.id}
                    onChange={(e) => {
                      onUpdateUserField(
                        "userType",
                        e.target.checked ? "PRRS" : null,
                      );
                      clearPermissions();
                    }}
                  >
                    PRRS
                  </Checkbox>
                )}
                {!isInstallerOrganisation && (
                  <Checkbox
                    checked={user.userType === "InstallerAdmin"}
                    disabled={ClientId || user?.id}
                    onChange={(e) => {
                      onUpdateUserField(
                        "userType",
                        e.target.checked ? "InstallerAdmin" : null,
                      );
                      clearPermissions();
                    }}
                  >
                    Installer Admin
                  </Checkbox>
                )}
                {isInstallerOrganisation && (
                  <Checkbox
                    checked
                    disabled
                    onChange={(e) => {
                      onUpdateUserField(
                        "userType",
                        e.target.checked ? "Installer" : null,
                      );
                    }}
                  >
                    Installer
                  </Checkbox>
                )}
              </Col>
            </Col>
            <Col sm={6}>
              {user.userType === "SARI" && (
                <Col
                  css={css`
                    padding-left: 0px;
                  `}
                  sm={12}
                >
                  <FormControl
                    onChange={(e) =>
                      onUpdateUserField("SARILicenseNumber", e.target.value)
                    }
                    value={user.SARILicenseNumber}
                  />
                </Col>
              )}
            </Col>
          </Col>
          {user.creator && (
            <Col sm={6}>
              <Col sm={12}>
                <ControlLabel>User Creator</ControlLabel>
              </Col>
              <Col sm={12}>{`${user.creator.name} ${
                isCurrentUserCreator ? "(You)" : ""
              }`}</Col>
            </Col>
          )}
        </FormGroup>
      </Panel.Body>
    </EditorPanel>
  );
}

UserDetails.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    userType: PropTypes.string,
    SARILicenseNumber: PropTypes.string,
    email: PropTypes.string,
    creator: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  /** Update a named field in the controlled user object */
  onUpdateUserField: PropTypes.func,
  isCurrentUserCreator: PropTypes.bool,
  ClientId: PropTypes.string,
  UrlSiteId: PropTypes.string,
  clearPermissions: PropTypes.func,
  isInstallerOrganisation: PropTypes.bool,
};

export default UserDetails;
