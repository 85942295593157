import { Col, Button } from 'react-bootstrap';

import { useCurrentUser } from 'hooks/user';

export default function InactivePage() {
  const { onLogout, mutate } = useCurrentUser();

  return (
    <Col sm={12}>
      <h3>Your Econform account is currently inactive.</h3>
      <h5>Please contact your administrator if you believe this is a mistake</h5>
      <Button onClick={mutate}>Refresh</Button>
      <Button onClick={onLogout}>Log Out</Button>
    </Col>
  );
}
