/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
/* eslint-disable react/prop-types */
import styled from "@emotion/styled";

import {
  faChevronDown,
  faChevronRight,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useId, useState } from "react";
import { FormControl } from "react-bootstrap";

import { useAllComponentClassifications } from "hooks/classifications";
import UploadButton from "views/components/UploadButton";
import { CheckboxInput } from "views/components/inputs.jsx";

/**
 * Filter down available options, based on data from the hook and what field we currently
 * have selected.
 */
export function useSelectedComponentClassifications({
  risk,
  componentType,
  component,
  position,
  defect,
}) {
  const { componentClassifications } = useAllComponentClassifications();
  const componentTypes = componentClassifications?.find((cc) => cc.risk === risk)?.componentTypes ?? []; // prettier-ignore
  const components = componentTypes.find((ct) => ct.componentType === componentType)?.components ?? []; // prettier-ignore
  let positions =
    components.find((c) => c.component === component)?.positions ?? [];
  positions = positions.filter((p) =>
    componentType === "Beam" ? p : p.position !== "Front & Rear",
  );
  const defects = positions.find((p) => p.position === position)?.defects ?? [];
  const actions = defects.find((d) => d.defect === defect)?.actions ?? [];
  return {
    risk,
    componentTypes,
    components,
    positions,
    defects,
    actions,
  };
}

const StyledFormControl = styled(FormControl)`
  background-color: #f2f2f4;
  border: 0px;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
  color: #747474;
`;

export function RunSpacerField({ onChange, value = "" }) {
  return (
    <>
      <h5>Run Spacer</h5>
      <StyledFormControl
        onChange={(e) => onChange(e.target.value)}
        placeholder="Size (mm)"
        type="number"
        value={value ?? ""}
      />
    </>
  );
}

export function QuantityField({ onChange, value = "" }) {
  return (
    <>
      <h5>Quantity</h5>
      <StyledFormControl
        onChange={(e) => onChange(e.target.value)}
        placeholder="Add Quantity"
        type="number"
        value={value}
      />
    </>
  );
}

const DisableableWrapper = styled.div`
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

export function OptionsField({
  title = "",
  options = [],
  onChange,
  value,
  disabled,
}) {
  const a11yId = useId();
  return (
    <DisableableWrapper disabled={disabled}>
      <h5>{title}</h5>
      <StyledFormControl
        componentClass="select"
        disabled={disabled}
        id={a11yId}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        {options.length > 0 ? (
          <option disabled value="">
            Select {title.toLowerCase()}
          </option>
        ) : null}
        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
        {options.length === 0 ? (
          <option disabled value="">
            No {title.toLowerCase()}s found
          </option>
        ) : null}
      </StyledFormControl>
    </DisableableWrapper>
  );
}

export function ComponentTypeField({
  risk = "None",
  onChange,
  value = "",
  disabled,
}) {
  const { componentTypes } = useSelectedComponentClassifications({ risk });
  return (
    <OptionsField
      disabled={disabled}
      onChange={onChange}
      options={componentTypes.map((ct) => ({
        value: ct.componentType,
        label: ct.componentType,
      }))}
      title="Component Type"
      value={value}
    />
  );
}

export function ComponentField({
  risk = "None",
  componentType,
  onChange,
  value = "",
  disabled,
}) {
  const { components } = useSelectedComponentClassifications({
    risk,
    componentType,
  });
  return (
    <OptionsField
      disabled={disabled}
      onChange={onChange}
      options={components.map((c) => ({
        value: c.component,
        label: c.component,
      }))}
      title="Component"
      value={value}
    />
  );
}

export function BeamLevelsField({ value, onChange, options = [], disabled }) {
  return (
    <DisableableWrapper disabled={disabled}>
      <h5>Level</h5>
      <StyledFormControl
        componentClass="select"
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        <option disabled value="">
          Please select
        </option>
        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </StyledFormControl>
    </DisableableWrapper>
  );
}

export function FrameLevelsField({
  value,
  onChange,
  disabled,
  required = true,
}) {
  return (
    <DisableableWrapper disabled={disabled}>
      <h5>Level</h5>
      <StyledFormControl
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        placeholder={`Add level${!required ? " (if required)" : ""}`}
        value={value}
      />
    </DisableableWrapper>
  );
}

export function PositionField({
  risk,
  componentType,
  component,
  frame,
  onChange,
  value = "",
  disabled,
}) {
  const { positions } = useSelectedComponentClassifications({
    risk,
    componentType,
    component,
    frame,
  });

  return (
    <OptionsField
      disabled={disabled}
      onChange={onChange}
      options={positions.map((p) => ({ value: p.position, label: p.position }))}
      title="Position"
      value={value}
    />
  );
}

export function DefectField({
  risk,
  componentType,
  component,
  position,
  onChange,
  value = "",
  disabled,
}) {
  const { defects } = useSelectedComponentClassifications({
    risk,
    componentType,
    component,
    position,
  });
  return (
    <OptionsField
      disabled={disabled}
      onChange={onChange}
      options={defects.map((d) => ({ value: d.defect, label: d.defect }))}
      title="Defect"
      value={value}
    />
  );
}

export function ActionField({
  risk,
  componentType,
  component,
  position,
  defect,
  onChange,
  value = "",
  disabled,
}) {
  const { actions } = useSelectedComponentClassifications({
    risk,
    componentType,
    component,
    position,
    defect,
  });
  return (
    <OptionsField
      disabled={disabled}
      onChange={onChange}
      options={actions.map((a) => ({ value: a.id, label: a.action }))}
      title="Action"
      value={value}
    />
  );
}

export function NotesField({ value, onChange }) {
  return (
    <>
      <h5>Notes</h5>
      <FormControl
        componentClass="textarea"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder="Add Notes"
        value={value}
      />
    </>
  );
}

export function CustomerReferenceField({ value, onChange }) {
  return (
    <>
      <h5>Customer ID</h5>
      <StyledFormControl
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder="Add Customer ID"
        value={value}
      />
    </>
  );
}

const ExpandableWrapper = styled.div`
  background-color: #f2f2f4;
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;

export function ExpandableField({ title, children }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <ExpandableWrapper>
      <Title onClick={() => setExpanded(!expanded)}>
        {title}{" "}
        <FontAwesomeIcon
          fixedWidth
          icon={expanded ? faChevronDown : faChevronRight}
        />{" "}
      </Title>
      {expanded ? children : null}
    </ExpandableWrapper>
  );
}

export function ImageUploaderField({ onAdd }) {
  const [isUploading, setIsUploading] = useState(false);
  return (
    <>
      <h5>Images</h5>
      <UploadButton
        onFileUploadFailed={() => setIsUploading(false)}
        onFileUploadStarted={() => setIsUploading(true)}
        onFileUploaded={(image) => {
          setIsUploading(false);
          onAdd(image);
        }}
        size="50px"
      >
        {isUploading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <FontAwesomeIcon icon={faPlus} />
        )}
      </UploadButton>
    </>
  );
}

const optionsFieldContainerStyle = (disabled) => css`
  display: grid;
  align-items: center;
  gap: 8px;
  ${disabled ? "opacity: 0.3;" : ""} /* Reduced opacity if disabled */
  margin-top: 6px;
`;

const optionsListContainerStyle = css`
  grid-column: span 1; /* Span both columns */
  display: grid;
  grid-template-columns: 1fr; /* Single column grid */
  gap: 4px; /* Space between list items */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
`;

const optionItemStyle = css`
  list-style: none; /* Removes bullet points */
  display: flex; /* Align checkbox and text */
  align-items: center; /* Vertically center items */
  gap: 8px; /* Space between checkbox and label */
`;

const noOptionsStyle = css`
  display: flex;
  height: 32px; /* Equal to h-8 in Tailwind */
  align-items: center; /* Center vertically */
`;

export function OptionsFieldWithCheckboxes({
  title = "",
  options = [],
  onChange,
  selectedValues = [],
  disabled = false,
  className = "",
  isChecked = () => false,
}) {
  return (
    <div css={optionsFieldContainerStyle(disabled)} className={className}>
      {title}
      <ul css={optionsListContainerStyle}>
        {options.length > 0 ? (
          options.map((option) => (
            <li key={option.value} css={optionItemStyle}>
              <CheckboxInput
                checked={
                  selectedValues.includes(option.value) ||
                  isChecked(option) ||
                  option.disabled
                }
                onChange={() => onChange(option.value)}
                disabled={disabled || option.disabled}
              />
              <span>{option.label}</span>
            </li>
          ))
        ) : (
          <div css={noOptionsStyle}>N/A</div>
        )}
      </ul>
    </div>
  );
}
