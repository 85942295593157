/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import Loading from "views/components/Loading";

export default function EmptyTableIndicator({
  type,
  isLoading,
  searchable,
  noTabs,
  typeLabel,
}) {
  const searchableCheck = searchable ? "440px" : "220px";
  return (
    <div
      css={css`
        width: 100%;
        height: ${noTabs ? "480px" : searchableCheck};
        margin-top: -20px;
        background-color: #ffffff;
        text-align: center;
        vertical-align: middle;
        box-shadow:
          0 1px 1px rgba(0, 0, 0, 0.1),
          0 1px 1px rgba(0, 0, 0, 0.1);
        padding-top: 25px;
        padding-bottom: 20px;
        color: #666666;
        img {
          margin: 20px;
          width: 55px;
        }
      `}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
            marginTop: searchable ? -20 : 0,
          }}
        >
          <div>
            <Image src={`/img/misc/no-${type.toLowerCase()}.png`} />
          </div>
          <h4>{`No ${typeLabel || type} ${
            searchable ? "found" : "created"
          }`}</h4>
          {searchable ? (
            <h5>There are no {typeLabel || type} matching your search</h5>
          ) : (
            <p>{`Created ${type} will appear here`}</p>
          )}
        </div>
      )}
    </div>
  );
}

EmptyTableIndicator.propTypes = {
  type: PropTypes.oneOf([
    "Actions",
    "Clients",
    "Forms",
    "Inspections",
    "Racks",
    "Schedules",
    "Sites",
    "Users",
    "Warehouses",
  ]),
  isLoading: PropTypes.bool,
  searchable: PropTypes.bool,
  noTabs: PropTypes.bool,
  typeLabel: PropTypes.string,
};
