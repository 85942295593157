import PropTypes from "prop-types";
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Panel,
  Row,
} from "react-bootstrap";

import EditorPanel from "views/components/EditorPanel";

export default function WarehouseDetails({ warehouse, setField }) {
  return (
    <EditorPanel>
      <Panel.Heading>Warehouse Details</Panel.Heading>
      <Panel.Body>
        <FormGroup controlId="formHorizontalWidth">
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <ControlLabel>
                  Warehouse Name
                  <span className="required">*</span>
                </ControlLabel>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormControl
                  onChange={(e) => setField("name", e.target.value)}
                  placeholder="Enter Warehouse Name (e.g. Glasgow)"
                  value={warehouse?.name}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <ControlLabel>
                  Dimensions
                  <span className="required">*</span>
                </ControlLabel>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormControl
                  onChange={(e) => setField("sizeX", e.target.value)}
                  placeholder="Width (mm)"
                  value={warehouse?.sizeX}
                />
              </Col>
              <Col sm={6}>
                <FormControl
                  onChange={(e) => setField("sizeY", e.target.value)}
                  placeholder="Depth (mm)"
                  value={warehouse?.sizeY}
                />
              </Col>
            </Row>
          </Grid>
        </FormGroup>
      </Panel.Body>
    </EditorPanel>
  );
}

WarehouseDetails.propTypes = {
  setField: PropTypes.func.isRequired,
  warehouse: PropTypes.shape({
    sizeX: PropTypes.string.isRequired,
    sizeY: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
