export function filterBayTemplates(bayTemplates = [], bayTemplatesFilterOptions = {}) {
  return bayTemplates.filter((bayTemplate) => {
    let allowed = true;
    if (bayTemplatesFilterOptions.searchText.length > 0) {
      allowed = bayTemplate.name.includes(bayTemplatesFilterOptions.searchText);
    }
    if (bayTemplatesFilterOptions.ManufacturerId) {
      if (!(bayTemplate.ManufacturerId === bayTemplatesFilterOptions.ManufacturerId)) {
        allowed = false;
      }
    }
    if (bayTemplatesFilterOptions.minWidth) {
      if (!(bayTemplate.width >= bayTemplatesFilterOptions.minWidth)) {
        allowed = false;
      }
    }
    if (bayTemplatesFilterOptions.maxWidth) {
      if (!(bayTemplate.width <= bayTemplatesFilterOptions.maxWidth)) {
        allowed = false;
      }
    }
    if (bayTemplatesFilterOptions.minDepth) {
      if (!(bayTemplate.depth >= bayTemplatesFilterOptions.minDepth)) {
        allowed = false;
      }
    }
    if (bayTemplatesFilterOptions.maxDepth) {
      if (!(bayTemplate.depth <= bayTemplatesFilterOptions.maxDepth)) {
        allowed = false;
      }
    }
    if (bayTemplatesFilterOptions.minPitch) {
      if (!(bayTemplate.pitch >= bayTemplatesFilterOptions.minPitch)) {
        allowed = false;
      }
    }
    if (bayTemplatesFilterOptions.maxPitch) {
      if (!(bayTemplate.pitch <= bayTemplatesFilterOptions.maxPitch)) {
        allowed = false;
      }
    }
    if (bayTemplatesFilterOptions.minBeams) {
      if (!(bayTemplate.beams.length >= bayTemplatesFilterOptions.minBeams)) {
        allowed = false;
      }
    }
    if (bayTemplatesFilterOptions.maxBeams) {
      if (!(bayTemplate.beams.length <= bayTemplatesFilterOptions.maxBeams)) {
        allowed = false;
      }
    }
    if (bayTemplatesFilterOptions.numberOfLevels) {
      if (!(bayTemplate.beamTemplates.length === bayTemplatesFilterOptions.numberOfLevels)) {
        allowed = false;
      }
    }
    return allowed;
  });
}
