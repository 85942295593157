import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useCallback, useMemo, useReducer, useRef, useState } from "react";
import { Col, Row, Grid } from "react-bootstrap";

import { useActions } from "hooks/actions";
import { useInspection, usePreviousClassifications } from "hooks/inspections";

import { addClassificationFile, reclassifyClassification } from "libs/api";
import { RISKS_ARRAY as risks } from "libs/constants";

import getOptimisedImageUrl from "libs/getOptimisedImageUrl";
import Loading from "views/components/Loading";
import RiskSlider from "views/components/Modals/ClassificationEditor/RiskSlider";
import ConfirmModal from "views/components/Modals/ConfirmModal";
import {
  buildMiscReclassificationObjectToSend,
  buildReclassificationObjectToSend,
} from "views/components/Modals/NewClassificationEditor/helpers";
import { AutoCompleteWithSuggestions } from "views/components/Modals/NewClassificationEditor/misc-fields";

import UploaderLightbox from "views/pages/Inspection/components/uploading-lightbox";

import {
  ActionField,
  CustomerReferenceField,
  DefectField,
  ExpandableField,
  ImageUploaderField,
  NotesField,
} from "./fields";
import classificationReducer, { ACTION_TYPES } from "./reducer";
import {
  StyledButton,
  StyledButtonGroup,
  Previous,
  CancelButton,
  StyledImage,
} from "./styles";

function DeleteButton({ onConfirm }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <StyledButton onClick={() => setShowModal(true)}>Delete</StyledButton>
      <ConfirmModal
        onConfirm={onConfirm}
        onHide={() => setShowModal(false)}
        show={showModal}
        title="Reclassify to None"
      >
        Are you sure you want to delete this damage classification?
      </ConfirmModal>
    </>
  );
}

DeleteButton.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

const DefectsWrapper = styled.div`
  padding: 5px;
`;

const DefectWrapper = styled.span`
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

export default function ReclassifyEditor({
  classificationId,
  InspectionId,
  onHide,
}) {
  const [state, dispatch] = useReducer(classificationReducer, {});
  const {
    inspection,
    isLoading,
    mutate: refreshInspection,
  } = useInspection(InspectionId);
  const { mutate: refreshActions } = useActions();
  const { previousClassifications } = usePreviousClassifications(
    inspection?.SiteId,
    InspectionId,
  );
  const [showReclassifyModal, setShowReclassifyModal] = useState(false);

  const lightboxRef = useRef();

  // Find the previous classification
  const classification = useMemo(
    () =>
      previousClassifications?.find((pc) => pc.id === classificationId) ?? {},
    [classificationId, previousClassifications],
  );

  // Callback to send to the API
  const handleReclassify = useCallback(async () => {
    const objectToSend = classification.misc
      ? buildMiscReclassificationObjectToSend(
          InspectionId,
          classification,
          state,
        )
      : buildReclassificationObjectToSend(InspectionId, classification, state);
    // TODO: Refactor so JSON goes in lib
    const res = await reclassifyClassification(objectToSend);
    const reclassifiedClassification = await res.json();
    await Promise.all(
      state.images?.map(async (image) =>
        addClassificationFile({
          remotePath: image.location,
          ClassificationId: reclassifiedClassification.id,
          caption: image.caption,
        }),
      ) ?? [],
    );
    refreshInspection();
    // To ensure the action list no longer includes a classification if it has been deleted
    refreshActions();
    onHide();
  }, [InspectionId, classification, onHide, refreshInspection, state]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Previous>
        Rack {classification?.RackName}
        {classification?.location}
        <br />
        {classification?.componentName}
        {(classification?.defect && classification?.action) ||
        (classification?.miscDefect && classification?.miscAction) ? (
          <DefectsWrapper>
            <DefectWrapper>
              {classification?.defect ?? classification?.miscDefect}
            </DefectWrapper>{" "}
            <DefectWrapper>
              {classification?.action ?? classification?.miscAction}
            </DefectWrapper>
          </DefectsWrapper>
        ) : null}
      </Previous>
      <RiskSlider
        isReclassifying
        onChange={(idx) =>
          dispatch({ type: ACTION_TYPES.SET_RISK, risk: risks[idx] })
        }
        originalRisk={classification?.risk}
        risk={state.risk ?? classification?.risk}
      />

      <Grid fluid>
        <Row>
          <Col sm={6}>
            <CustomerReferenceField
              onChange={(customerReference) =>
                dispatch({
                  type: ACTION_TYPES.SET_CUSTOMER_REFERENCE,
                  customerReference,
                })
              }
              value={
                state.customerReference ??
                classification?.customerReference ??
                ""
              }
            />
          </Col>
        </Row>

        {state.risk !== "None" && classification?.misc === false ? (
          <Row>
            <Col sm={6}>
              <DefectField
                component={state.component ?? classification?.component}
                componentType={
                  state.componentType ?? classification?.componentType
                }
                onChange={(defect) =>
                  dispatch({ type: ACTION_TYPES.SET_DEFECT, defect })
                }
                position={state.position ?? classification?.position}
                risk={state.risk ?? classification?.risk}
                value={
                  state.risk === classification.risk
                    ? classification?.defect
                    : state.defect
                }
              />
            </Col>
            <Col sm={6}>
              <ActionField
                component={state.component ?? classification?.component}
                componentType={
                  state.componentType ?? classification?.componentType
                }
                defect={state.defect ?? classification?.defect}
                onChange={(action) =>
                  dispatch({ type: ACTION_TYPES.SET_ACTION, action })
                }
                position={state.position ?? classification?.position}
                risk={state.risk ?? classification?.risk}
                value={
                  state.risk === classification.risk
                    ? classification?.defect
                    : state.action
                }
              />
            </Col>
          </Row>
        ) : null}
        {state.risk !== "None" && classification?.misc === true ? (
          <Row>
            <Col sm={6}>
              <AutoCompleteWithSuggestions
                componentType={classification?.BeamId ? "Beam" : "Frame"}
                fieldType="defect"
                onChange={(defect) =>
                  dispatch({ type: ACTION_TYPES.SET_DEFECT, defect })
                }
                title="Defect"
                value={state.defect}
              />
            </Col>
            <Col sm={6}>
              <AutoCompleteWithSuggestions
                componentType={classification?.BeamId ? "Beam" : "Frame"}
                fieldType="action"
                onChange={(action) =>
                  dispatch({ type: ACTION_TYPES.SET_ACTION, action })
                }
                title="Action"
                value={state.action}
              />
            </Col>
          </Row>
        ) : null}

        <ExpandableField title="Notes and images">
          <Row>
            <Col sm={6}>
              <NotesField
                onChange={(notes) =>
                  dispatch({ type: ACTION_TYPES.SET_NOTES, notes })
                }
                value={state.notes}
              />
            </Col>

            <Col sm={6}>
              <ImageUploaderField
                images={state.images}
                onAdd={(image) =>
                  dispatch({ type: ACTION_TYPES.ADD_IMAGE, image })
                }
              />
              {state.images?.slice(0, 2).map((image, idx) => (
                <StyledImage
                  key={image.remotePath}
                  onClick={() => lightboxRef?.current?.openWith(idx)}
                  src={
                    image.location
                      ? getOptimisedImageUrl(`${image.location}`)
                      : "/img/defaults/logo.png"
                  }
                />
              ))}
            </Col>
          </Row>
        </ExpandableField>
      </Grid>
      <StyledButtonGroup>
        <CancelButton onClick={onHide} type="button">
          Cancel
        </CancelButton>{" "}
        {state.risk === "None" ? (
          <DeleteButton onConfirm={handleReclassify} />
        ) : (
          <>
            <StyledButton
              disabled={
                !state.action ||
                !state.defect ||
                !state.risk ||
                state.risk === classification?.risk
              }
              onClick={() => {
                return state.risk === "Red" && classification?.risk === "Amber"
                  ? setShowReclassifyModal(true)
                  : handleReclassify();
              }}
            >
              Reclassify
            </StyledButton>
            <ConfirmModal
              confirmButtonText="Update"
              onConfirm={handleReclassify}
              onHide={() => setShowReclassifyModal(false)}
              show={showReclassifyModal}
              title="Reclassify to Red from Amber"
            >
              This will update the previous amber action to be a red risk. Are
              you sure?
            </ConfirmModal>
          </>
        )}
      </StyledButtonGroup>

      <UploaderLightbox
        images={state.images?.map((image) => ({
          caption: image.caption,
          src: image.location
            ? getOptimisedImageUrl(`${image.location}`)
            : "/img/defaults/logo.png",
        }))}
        onChangeCaption={(idx, caption) =>
          dispatch({
            type: ACTION_TYPES.SET_IMAGE_CAPTION,
            index: idx,
            caption,
          })
        }
        onDelete={(deleteIdx) =>
          dispatch({ type: ACTION_TYPES.REMOVE_IMAGE, index: deleteIdx })
        }
        ref={lightboxRef}
      />
    </>
  );
}

ReclassifyEditor.propTypes = {
  classificationId: PropTypes.string,
  InspectionId: PropTypes.string,
  onHide: PropTypes.func.isRequired,
};
