/** @jsxImportSource @emotion/react */
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import {
  addUser,
  editUser,
  getClient,
  getClients,
  getClientsGroups,
  getInstallerClients,
  getInstallerOrgsById,
  getOrganisation,
  getOrganisations,
  getSites,
  getUser,
} from "libs/api";
import WithCompatWrap from "libs/with-compact-wrap";
import PageWrapper from "views/components/common/PageWrapper";

import Loading from "views/components/Loading";

import TableHeader from "views/components/Tables/TableHeader";

import ClientPermissions from "./ClientPermissions";
import { PAGE_SIZE, emptyPermissions } from "./config";
import ErrorsDisplay from "./ErrorsDisplay";
import {
  getIsValidUser,
  getClientIdFromUserDetails,
  getOrganisationIdFromUserDetails,
  getPermissionsTypeFromPermissions,
  applyPermissionUpdatesToOriginalPermissions,
} from "./helpers";
import OrganisationPermissions from "./OrganisationPermissions";
import SaveControls from "./SaveControls";
import UserControls from "./UserControls";
import UserDetails from "./UserDetails";

/**
 *
 * The UserEditor component loads the current user, and a user given by ID
 * if that user is a PRRS user they can select between one set of permission
 * options for admin, client, site group or sites. If they are a SARI user
 * then they also get organisation options. This component allows the
 * current logged in user (with permissions) to select the permissions for the
 * given user, then calls the user patching endpoint on the API with the new
 * details.
 *
 * TODO: Clarify what these options are for each type of user.
 *
 * Permissions are stored in
 *
 * updatedPermissions
 * originalPermissions
 *
 * in the form of { UserId, type, relationId, direction }
 *
 * type can be client, site, siteGroup etc
 */
class UserEditor extends React.Component {
  state = {
    ClientId: this.props.match.params.ClientId,
    clients: [],
    updatedPermissions: [],
    errors: [],
    initialLoad: true,
    loading: true,
    loadingClient: false,
    OrganisationId: this.props.match.params.OrganisationId,
    searchText: "",
    siteGroups: [],
    installerOrganisations: [],
    installeOrganisation: null,
    InstallerOrganisationId: null,
    installerAdminSelected: false,
    sites: [],
    user: {
      id: null,
      userType: this.props.match.params.OrganisationId ? "SARI" : null,
    },
    UserId: this.props.match.params.UserId,
  };

  componentDidMount() {
    this.getData();
  }

  onUpdatePermissions = (direction, userId, type, relationIds) => {
    this.setState(({ updatedPermissions, originalPermissions }) => ({
      updatedPermissions: [
        // Remove any previous permission with matching user, type and relation
        ...updatedPermissions.filter(
          (p) =>
            !(
              p.userId === userId &&
              p.type === type &&
              relationIds.includes(p.relationId)
            ),
        ),
        // Add the new permissions
        ...relationIds
          .map((relationId) => {
            // If we are removing and the originalPermissions didn't have the permission, no update
            // If we are adding and the originalPermissions has it already, no update
            if (
              direction === "remove" &&
              !originalPermissions[type]?.includes(relationId)
            ) {
              return false;
            }
            if (
              direction === "add" &&
              originalPermissions[type]?.includes(relationId)
            ) {
              return false;
            }
            return { direction, userId, type, relationId };
          })
          .filter(Boolean), // Filter so false entries are removed
      ],
    }));
  };

  onGetClients = async () => {
    const { searchText, user, OrganisationId } = this.state;
    const clients = await getClients({
      searchText,
      amount: PAGE_SIZE,
      OrganisationId,
    });
    const parsedClients = clients.results.map((client) => {
      let ending = "";
      let hasPermissions = false;

      let totalSites = 0;
      if (user.permissions) {
        if (user.permissions.organisation.includes(client.id)) {
          ending = "(Organisation Client User)";
          hasPermissions = true;
        }
        if (user.permissions.clientAdmin.includes(client.id)) {
          ending = "(Admin)";
          hasPermissions = true;
        }
        if (user.permissions.client.includes(client.id)) {
          ending = "(User)";
          hasPermissions = true;
        }
        client.sites.map((site) => {
          if (user.permissions.site.includes(site.id)) {
            totalSites += 1;
          }
          if (totalSites > 0) {
            ending = `(Site User: ${totalSites} Sites)`;
            hasPermissions = true;
          }
          return null;
        });
      }
      return { label: `${client?.name} ${ending}`, hasPermissions, ...client };
    });
    return parsedClients;
  };

  // Used by PRRS
  onChangeClient = async (selectedClientId) => {
    this.setState({ loadingClient: true }, async () => {
      const newClient = await getClient(selectedClientId);
      const sites = await getSites({
        ClientId: newClient.id,
        amount: PAGE_SIZE,
      });
      const siteGroups = await getClientsGroups(newClient.id);
      this.setState({
        client: newClient,
        siteGroups,
        sites: sites.results,
        updatedPermissions: [],
        loadingClient: false,
        initialLoad: false,
      });
    });
  };

  onChangeClientSARI = async (selectedOrganisationId) => {
    this.setState({ loadingClient: true }, async () => {
      const curOrganisation = await getOrganisation(selectedOrganisationId);
      const clientsRes = await getClients({
        OrganisationId: curOrganisation.id,
        amount: PAGE_SIZE,
      });
      this.setState({
        organisation: {
          ...curOrganisation,
          installerOrgId: curOrganisation.id,
        },
        clients: clientsRes.results,
        loading: false,
        loadingClient: false,
        initialLoad: false,
      });
    });
  };

  onChangeInstallerOrg = async (selectedOrganisationId) => {
    this.setState({ loadingClient: true }, async () => {
      const curOrganisation = await getOrganisation(selectedOrganisationId);
      const loggedInUser = this.props.user;
      const clientsRes = await getInstallerClients({
        OrganisationId: selectedOrganisationId,
        InstallerOrganisationId: loggedInUser.organisationsAdmin[0].id,
        amount: PAGE_SIZE,
      });
      this.setState({
        organisation: curOrganisation,
        clients: clientsRes.results,
        loading: false,
        loadingClient: false,
        initialLoad: false,
      });
    });
  };

  getData = async () => {
    this.setState({ loading: true }, async () => {
      let { sites, client, clients, siteGroups, ClientId, OrganisationId } =
        this.state;
      const { UserId, user, initialLoad } = this.state;
      let organisation = null;
      const curRes = await getUser();
      const currentUser = await curRes.json();
      const userRes = await getUser(UserId);
      const currentUserDetails = await userRes.json();

      /**
       * Note: This is a partial refactoring. This code _still_ overwrites the state variable, and
       * so should be further refactored not to do that!
       */
      OrganisationId =
        getOrganisationIdFromUserDetails(
          this.props.match.params.OrganisationId,
          currentUserDetails,
        ) ?? OrganisationId;
      // TODO: Make this nicer
      const adminOrgId =
        this.props.user.organisationsAdmin[0]?.id || OrganisationId;
      let isInstallerOrganisation = false;
      if (adminOrgId) {
        const adminOrganisation = await getOrganisation(adminOrgId);
        isInstallerOrganisation = adminOrganisation.isInstaller;
        OrganisationId = adminOrgId;
      }
      const installerOrganisations = await getInstallerOrgsById(adminOrgId);

      if (OrganisationId && !isInstallerOrganisation) {
        organisation = await getOrganisation(OrganisationId);
      }
      clients = await this.onGetClients();

      /**
       * Note: This is a partial refactoring. This code _still_ overwrites the state variable, and
       * so should be further refactored not to do that!
       */
      ClientId = getClientIdFromUserDetails(currentUserDetails) ?? ClientId;

      if (ClientId || client) {
        client = await getClient(ClientId || client.id);
        sites = await getSites({
          ClientId: ClientId || client.id,
          amount: PAGE_SIZE,
        });
        siteGroups = await getClientsGroups(client.id);
        if (ClientId) {
          user.userType = "PRRS";
        }
      }
      const organisationsRes = await getOrganisations();
      const organisations = organisationsRes.results;

      if (UserId) {
        const res = await getUser(UserId);
        if (res.ok) {
          const newUser = await res.json();
          if (!newUser) {
            this.setState({ errorMsg: newUser.errors });
          }
          document.title = `Econform / ${newUser.name} / Edit`;
          if (newUser) {
            let originalPermissions = newUser.permissions;
            if (originalPermissions.installerOrganisationClient.length) {
              originalPermissions.clientOrganisation = [
                ...originalPermissions.clientOrganisation,
                ...originalPermissions.installerOrganisationClient,
              ];
            }
            this.setState(
              {
                ClientId,
                OrganisationId,
                organisations,
                currentUser,
                user: newUser,
                client,
                sites: client ? sites.results : [],
                siteGroups,
                originalPermissions,
                organisation,
                clients,
                loading: false,
                isInstallerOrganisation,
                installerOrganisations,
              },
              async () => {
                if (!ClientId) {
                  clients = await this.onGetClients();
                  const selectedClient = clients.find(
                    (curClient) => curClient.hasPermissions,
                  );
                  ClientId = selectedClient?.id;
                  this.setState({ ClientId });
                }
                if (organisations && OrganisationId) {
                  this.setState({ initialLoad: false });
                }
                if (ClientId) {
                  this.onChangeClient(ClientId);
                }
              },
            );
          }
        } else {
          const { errors } = await res.json();
          this.setState({ errors });
        }
      } else {
        document.title = "Econform / Users / Add";
        user.creator = currentUser;
        user.userType = ClientId ? "PRRS" : user.userType;

        if (isInstallerOrganisation) {
          user.userType = isInstallerOrganisation ? "Installer" : user.userType;
        }
        this.setState(
          {
            ClientId,
            OrganisationId,
            organisation,
            user,
            currentUser,
            currentUserDetails,
            client,
            originalPermissions: emptyPermissions,
            organisations,
            installerOrganisations,
            isInstallerOrganisation,
          },
          async () => {
            if (!ClientId) {
              clients = await this.onGetClients();
            }
            if (organisations && OrganisationId) {
              this.setState({ initialLoad: false });
            }
            this.setState({
              clients,
              sites: client ? sites.results : [],
              loading: false,
              initialLoad: user.id ? initialLoad : false,
            });
          },
        );
      }
    });
  };

  save = async () => {
    this.setState({ saving: true }, async () => {
      let res = null;
      const {
        user: { id, name, email, userType, SARILicenseNumber },
        updatedPermissions,
        client,
        OrganisationId,
        organisation,
        installerAdminSelected,
      } = this.state;

      let addedPermissions = updatedPermissions.filter(
        (p) => p.direction === "add",
      );
      const deletedPermissions = updatedPermissions.filter(
        (p) => p.direction === "remove",
      );
      if (userType === "Installer" || userType === "InstallerAdmin") {
        addedPermissions = updatedPermissions.map((p) => ({
          ...p,
          type:
            p.type === "organisationAdmin"
              ? "organisationAdmin"
              : "installerOrganisationClient",
        }));
      }
      const isEdit = !!id;
      const toastId = toast.info("Saving user", {
        // eslint-disable-next-line react/no-unstable-nested-components
        icon: () => <FontAwesomeIcon icon={faSpinner} spin />,
        autoClose: false,
      });

      if (isEdit) {
        const editedUser = {
          id,
          name,
          email,
          userType,
          addedPermissions,
          deletedPermissions,
          SARILicenseNumber,
        };
        res = await editUser(editedUser);
      } else {
        const newUser = {
          name,
          email,
          userType,
          addedPermissions,
          SARILicenseNumber,
          OrganisationId: installerAdminSelected
            ? organisation.id
            : OrganisationId,
        };
        res = await addUser(newUser);
      }
      if (res.ok) {
        if (isEdit) {
          this.setState(
            {
              saving: false,
              savedSuccessfully: true,
              updatedPermissions,
            },
            () => this.getData(),
          );
        } else {
          const clientUserType =
            userType !== "Installer" && this.props.match.params.ClientId
              ? "PRRS"
              : null;
          this.setState({
            saving: false,
            savedSuccessfully: true,
            updatedPermissions: [],
            SARILicenseNumber: null,
            client: this.props.match.params.ClientId ? client : null,
            ClientId: this.props.match.params.ClientId,
            errors: [],
            OrganisationId: this.props.match.params.OrganisationId,
            searchText: "",
            user: {
              id: null,
              userType: this.props.match.params.OrganisationId
                ? "SARI"
                : clientUserType,
              email: "",
              name: "",
            },
            UserId: this.props.match.params.UserId,
            currentUser: null,
            currentUserDetails: null,
          });
        }
        toast.update(toastId, {
          render: "User saved successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 4000,
        });
      } else {
        const body = await res.json();
        this.setState({
          saving: false,
          savedSuccessfully: false,
          errors: body ? body.errors : null,
        });
      }
    });
  };

  clearPermissionData = () => {
    this.setState({
      updatedPermissions: [],
    });
  };

  addPermissionName = (p) => {
    const permission = this.state[
      `${p.type.replace("clientOrganisation", "client").replace("Admin", "")}s`
    ]?.find((item) => item.id === p.relationId);

    return { ...p, name: permission?.name };
  };

  render() {
    const loggedInUser = this.props.user;
    const {
      client,
      ClientId,
      clients,
      currentUser,
      errors,
      initialLoad,
      loading,
      loadingClient,
      organisation,
      originalPermissions,
      savedSuccessfully,
      saving,
      searchText,
      siteGroups,
      sites,
      user,
      OrganisationId,
      organisations,
      updatedPermissions,
      installerOrganisations,
      InstallerOrganisationId,
      isInstallerOrganisation,
      installerAdminSelected,
    } = this.state;

    const addedPermissions = updatedPermissions
      .filter((p) => p.direction === "add")
      .map(this.addPermissionName);
    const deletedPermissions = updatedPermissions
      .filter((p) => p.direction === "remove")
      .map(this.addPermissionName);

    const permissions = applyPermissionUpdatesToOriginalPermissions(
      originalPermissions,
      addedPermissions,
      deletedPermissions,
    );
    const userPermissionType = getPermissionsTypeFromPermissions(
      permissions,
      clients,
      client,
      sites,
      siteGroups,
      organisation,
    );

    const isEdit = !!user.id;
    const {
      match: {
        params: { SiteId: UrlSiteId },
      },
    } = this.props;
    return (
      <PageWrapper>
        {(initialLoad || loading) && (
          <Loading height="calc(100vh - 50px)" text="Loading User..." />
        )}
        {!loading && (
          <Row>
            <Col sm={12}>
              <TableHeader left={isEdit ? "Edit User" : "Add User"} />

              <UserDetails
                ClientId={ClientId}
                UrlSiteId={UrlSiteId}
                clearPermissions={this.clearPermissionData}
                isCurrentUserCreator={
                  currentUser && user && currentUser.id === user?.creator.id
                }
                isInstallerOrganisation={isInstallerOrganisation}
                onUpdateUserField={(field, value) => {
                  const updated = { ...user, [field]: value };
                  this.setState({ user: updated, savedSuccessfully: false });
                }}
                user={user}
              />
              {user.userType === "PRRS" ? (
                <ClientPermissions
                  ClientId={ClientId}
                  addedPermissions={addedPermissions}
                  canEditUser={user.canEditUser}
                  clearPermissions={this.clearPermissionData}
                  client={client}
                  clients={clients}
                  curUser={currentUser}
                  deletedPermissions={deletedPermissions}
                  onAddPermissions={(...args) =>
                    this.onUpdatePermissions("add", ...args)
                  }
                  onChangeClient={this.onChangeClient}
                  onChangeSearchText={async (curSearchText) =>
                    this.setState({ searchText: curSearchText }, async () => {
                      const newClients = await this.onGetClients();
                      this.setState({ clients: newClients });
                    })
                  }
                  onClearClient={
                    !this.props.match.params.OrganisationId &&
                    (async () =>
                      this.setState({
                        organisation: null,
                        OrganisationId: null,
                        client: null,
                        permission: [],
                        updatedPermissions: [],
                      }))
                  }
                  onDeletePermissions={(...args) =>
                    this.onUpdatePermissions("remove", ...args)
                  }
                  onToggleSiteGroups={(newSiteGroups) =>
                    this.setState({
                      siteGroups: newSiteGroups,
                    })
                  }
                  permissions={permissions}
                  saving={saving}
                  searchText={searchText}
                  siteGroups={siteGroups}
                  sites={sites}
                  userId={user.id}
                  userPermissionType={userPermissionType}
                />
              ) : null}
              {user.userType === "SARI" &&
                !ClientId &&
                !isInstallerOrganisation && (
                  <OrganisationPermissions
                    OrganisationId={OrganisationId}
                    addedPermissions={addedPermissions}
                    clearPermissions={this.clearPermissionData}
                    clients={clients}
                    curUser={this.props.user}
                    deletedPermissions={deletedPermissions}
                    loading={loading}
                    onAddPermissions={(...args) =>
                      this.onUpdatePermissions("add", ...args)
                    }
                    onChangeClient={this.onChangeClientSARI}
                    onChangeSearchText={async (curSearchText) =>
                      this.setState({ searchText: curSearchText }, async () => {
                        const organisationsRes = await getOrganisations({
                          searchText: curSearchText,
                        });
                        this.setState({
                          organisations: organisationsRes.results,
                        });
                      })
                    }
                    onClearClient={
                      !this.props.match.params.OrganisationId &&
                      (async () =>
                        this.setState({
                          organisation: null,
                          OrganisationId: null,
                          client: null,
                          permission: [],
                          updatedPermissions: [],
                        }))
                    }
                    onDeletePermissions={(...args) =>
                      this.onUpdatePermissions("remove", ...args)
                    }
                    organisation={organisation}
                    organisations={organisations}
                    permissions={permissions}
                    searchText={searchText}
                    user={user}
                    userPermissionType={userPermissionType}
                  />
                )}
              {user.userType === "InstallerAdmin" && (
                <OrganisationPermissions
                  OrganisationId={InstallerOrganisationId}
                  UserId={user.id}
                  addedPermissions={addedPermissions}
                  clearPermissions={this.clearPermissionData}
                  clients={clients}
                  curUser={this.props.user}
                  deletedPermissions={deletedPermissions}
                  isInstallerAdmin
                  loading={loading}
                  onAddPermissions={(...args) =>
                    this.onUpdatePermissions("add", ...args)
                  }
                  onChangeClient={this.onChangeClientSARI}
                  onClearClient={async () =>
                    this.setState({
                      organisation: null,
                      InstallerOrganisationId: null,
                      client: null,
                      permission: [],
                      updatedPermissions: [],
                    })
                  }
                  onDeletePermissions={(...args) =>
                    this.onUpdatePermissions("remove", ...args)
                  }
                  onUpdateInstallerUserField={(checked) => {
                    this.setState({ installerAdminSelected: checked });
                  }}
                  organisation={organisation}
                  organisations={organisations}
                  permissions={permissions}
                  searchText={searchText}
                  user={user}
                  userPermissionType={
                    installerAdminSelected
                      ? "installerAdmin"
                      : userPermissionType
                  }
                />
              )}
              {isInstallerOrganisation && (
                <OrganisationPermissions
                  OrganisationId={InstallerOrganisationId}
                  UserId={user.id}
                  addedPermissions={addedPermissions}
                  clearPermissions={this.clearPermissionData}
                  clients={clients}
                  curUser={this.props.user}
                  deletedPermissions={deletedPermissions}
                  isInstaller
                  isInstallerUser
                  loading={loading}
                  onAddPermissions={(...args) =>
                    this.onUpdatePermissions("add", ...args)
                  }
                  onChangeClient={this.onChangeInstallerOrg}
                  onClearClient={async () =>
                    this.setState({
                      organisation: null,
                      InstallerOrganisationId: null,
                      client: null,
                      permission: [],
                      updatedPermissions: [],
                    })
                  }
                  onDeletePermissions={(...args) =>
                    this.onUpdatePermissions("remove", ...args)
                  }
                  organisation={organisation}
                  organisations={installerOrganisations}
                  permissions={permissions}
                  searchText={searchText}
                  user={user}
                  userPermissionType={userPermissionType}
                />
              )}
              {user.canEditUser && user.id !== loggedInUser.id && (
                <UserControls
                  onDeleted={() => this.props.history.goBack()}
                  onToggleActive={this.getData}
                  user={user}
                />
              )}
              <SaveControls
                disableSave={
                  !getIsValidUser(user, addedPermissions, deletedPermissions) ||
                  saving ||
                  (isEdit && !user.canEditUser)
                }
                isEdit={isEdit}
                isSaving={saving}
                onClickCancel={() => this.props.history.goBack()}
                onClickSave={this.save}
              />
              {!savedSuccessfully && errors.length > 0 ? (
                <ErrorsDisplay errors={errors} />
              ) : null}
            </Col>
          </Row>
        )}
        {loadingClient && (
          <div className="loading">
            <div className="internal-loader" />
          </div>
        )}
      </PageWrapper>
    );
  }
}

UserEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    isSuperAdmin: PropTypes.bool,
    organisationsAdmin: PropTypes.shape({
      id: PropTypes.arrayOf(PropTypes.string),
    }),
    permissions: PropTypes.shape({
      clientAdmin: PropTypes.arrayOf(PropTypes.string),
      organisationAdmin: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

export default WithCompatWrap(UserEditor);
