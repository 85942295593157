import { useCallback, useEffect, useReducer } from "react";

function getFirstAreaAlphabetically(areas) {
  const alphabeticalAreas = [...areas]
    .filter((a) => !!a.id)
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  // Choose the first alphabetical area, with a fallback of All Areas, if no areas have been created
  return alphabeticalAreas[0] ?? areas[0];
}

// eslint-disable-next-line import/no-unused-modules
export const ACTION_TYPES = {
  setWarehouseId: "setWarehouseId",
  setAreaId: "setAreaId",
  setRackId: "setRackId",
  selectFirstRackInArea: "selectFirstRackInArea",
};

// eslint-disable-next-line import/no-unused-modules
export function inspectionViewReducer(state, action) {
  if (!action.warehouses) return state;
  switch (action.type) {
    case ACTION_TYPES.setWarehouseId: {
      if (!action.id) return state;

      const warehouse = action.warehouses.find((w) => w.id === action.id);
      if (!warehouse) return state;

      // Sort the areas alphabetically, filtering out "All Areas" which has a null ID
      const firstArea = getFirstAreaAlphabetically(warehouse?.areas);

      // Choose a rack from the first area
      // Note: we don't set an area ID here, as we are not filtering the areas, just selecting a rack
      const firstRack = warehouse?.racks?.filter(
        (r) => r.AreaId === firstArea.id,
      )?.[0];
      return {
        ...state,
        warehouseId: action.id,
        areaId: null,
        rackId: firstRack.id,
      };
    }
    // Set area id: we are now filtering on the area
    case ACTION_TYPES.setAreaId: {
      // TODO: Check that area is within current warehouse. Otherwise, find the warehouse that contains the area
      const warehouse = !state.warehouseId
        ? action.warehouses[0]
        : action.warehouses.find((w) => w.id === state.warehouseId);
      // If the area is null then we are in "All Areas", so select the first rack of the first alphabetical area
      const areaIdOrFirstAreaId =
        action.id ?? getFirstAreaAlphabetically(warehouse.areas).id;
      const firstRack = warehouse?.racks?.filter(
        (r) => r.AreaId === areaIdOrFirstAreaId,
      )?.[0];
      return { ...state, areaId: action.id, rackId: firstRack?.id };
    }
    // Select an area: choose the first rack in that area
    case ACTION_TYPES.selectFirstRackInArea: {
      const warehouse = action.warehouses.find(
        (w) => w.id === state.warehouseId,
      );
      const firstRack = warehouse?.racks?.filter(
        (r) => r.AreaId === action.id,
      )?.[0];
      return { ...state, rackId: firstRack.id };
    }
    case ACTION_TYPES.setRackId: {
      // TODO: Check the rack is in the warehouse and area that we have, otherwise set them too

      // Find the warehouse that contains the rack
      const warehouse = action.warehouses.find((w) =>
        w.racks?.find((r) => r.id === action.id),
      );
      return { ...state, rackId: action.id, warehouseId: warehouse.id };
    }
    default:
      return state;
  }
}

/**
 * This hook manages the current view for an inspection, controlling the currently
 * displayed warehouse, area and rack.
 *
 * Expects inspection to include { warehouses: { areas } : { racks }}
 */
export default function useInspectionView(inspection) {
  const [state, dispatch] = useReducer(inspectionViewReducer, {});

  const { warehouseId, areaId, rackId } = state;

  const { warehouses } = inspection ?? {};

  const setWarehouseId = useCallback((id) => dispatch({ type: ACTION_TYPES.setWarehouseId, id, warehouses }),[dispatch, warehouses]); // prettier-ignore
  const setAreaId = useCallback(
    (id) => dispatch({ type: ACTION_TYPES.setAreaId, id, warehouses }),
    [dispatch, warehouses],
  );
  const setRackId = useCallback(
    (id) => dispatch({ type: ACTION_TYPES.setRackId, id, warehouses }),
    [dispatch, warehouses],
  );
  const selectArea = useCallback((id) => dispatch({ type: ACTION_TYPES.selectFirstRackInArea, id, warehouses }), [dispatch, warehouses]); // prettier-ignore

  useEffect(() => {
    if (warehouses && !warehouseId) {
      setWarehouseId(warehouses?.[0]?.id);
    }
  }, [setRackId, setWarehouseId, warehouseId, warehouses]);

  return {
    state,
    dispatch,
    warehouseId,
    areaId,
    rackId,
    setWarehouseId,
    setAreaId,
    setRackId,
    selectArea,
  };
}
