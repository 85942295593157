import PropTypes from "prop-types";

import FilterOptionsAndChips from "views/components/FilterOptionsAndChips";
import ActionStatusButtons from "views/pages/Actions/components/ActionStatusButtons";

import { ControlsButtonWrapper, ControlsWrapper } from "../styles";

export default function Controls({ actionStats }) {
  return (
    <ControlsWrapper>
      <span>
        <FilterOptionsAndChips showSearch />
      </span>
      <ControlsButtonWrapper>
        <ActionStatusButtons actionStats={actionStats} />
      </ControlsButtonWrapper>
    </ControlsWrapper>
  );
}

Controls.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actionStats: PropTypes.object,
};
