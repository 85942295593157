import styled from "@emotion/styled";
import PropTypes from "prop-types";

import InspectionCompleteRack from "./InspectionCompleteRack";
import { Image } from "react-bootstrap";

const Wrapper = styled.div`
  color: #666666;
  padding-top: 150px;
  text-align: center;

  h2 {
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 2em;
  }

  img {
    height: 100px;
    width: 100px;
  }
`;

/**
 * When a rack has no damage assigned we display this message and
 * provide the option to complete the rack
 */
export default function NoDamage({ inspection, RackId }) {
  return (
    <Wrapper>
      <Image src="/img/misc/no-racks.png" />
      <h4>No Damage Assigned</h4>
      {!inspection.completedAt ? (
        <>
          <p>
            To add damage to this rack, tap the add damage button at the top of
            the screen
          </p>
          {!inspection.isolated && (
            <InspectionCompleteRack
              InspectionId={inspection.id}
              RackId={RackId}
            />
          )}
        </>
      ) : (
        <p>No damage was added to this Rack during this inspection</p>
      )}
    </Wrapper>
  );
}

NoDamage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inspection: PropTypes.object,
  RackId: PropTypes.string,
};
