/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useDownloader } from "hooks/useDownloader";
import { useCurrentUser } from "hooks/user";
import { deleteInspection } from "libs/api";
import downloadPDF from "libs/downloadPDF";

import {
  ActionButtonsOverlay,
  ArchiveButton,
  EditActionButton,
  InspectionContinueButton,
  InspectionDeleteButton,
  InspectionExportPDFButton,
  InspectionReportButton,
  InspectionRestoreButton,
  InspectionViewButton,
  ShareInspectionButton,
  ViewBlueprintButton,
} from "views/components/ActionButtons";
import ActionAssigner from "views/components/Modals/ActionAssigner";
import ConfirmModal from "views/components/Modals/ConfirmModal";

import FlatModal from "views/components/Modals/FlatModal";

/**
 * Notifies the user that the report is being exported and downloads the PDF
 */
async function onExportInspectionPDF(inspection) {
  const toastId = toast.info(
    `Exporting Inspection report (${inspection.reference})`,
    {
      closeButton: false,
      autoClose: false,
    },
  );
  await downloadPDF(inspection.id, inspection);
  toast.update(toastId, {
    render: `Report exported (${inspection.reference})`,
    type: toast.TYPE.SUCCESS,
    autoClose: 4000,
  });
}

export default function InspectionActionButtons({
  inspection,
  UserId,
  condensed = false,
  onArchiveInspection,
  onRefresh,
}) {
  const navigate = useNavigate();
  const {
    hasSections,
    completedAt,
    preparedAt,
    archive,
    archivedAt,
    actions,
    hasOnlyGreen,
    canEditActions,
    hasInspectionWriteAccess,
    permission,
  } = inspection;

  const { user } = useCurrentUser();
  const showDelete = !condensed && (!completedAt || archivedAt);
  const showEdit = !condensed && completedAt && !archivedAt && actions && actions.length && !hasOnlyGreen && canEditActions; // prettier-ignore
  const showPrepare = completedAt && !preparedAt && !archive;
  const showView = completedAt;

  const showShare = !condensed && preparedAt && !archivedAt;
  const showArchive = !condensed && preparedAt && !archivedAt;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditActionModal, setShowEditActionModal] = useState(false);

  const { onStart, onEnd, isDownloading } = useDownloader();
  const isReportDownloading = isDownloading(inspection.id);
  return (
    <ActionButtonsOverlay>
      {showDelete ? (
        <>
          <InspectionDeleteButton
            disabled={
              !(
                inspection.UserId === UserId ||
                permission.level === "organisationAdmin" ||
                permission.level === "clientAdmin"
              )
            }
            onClick={() => setShowDeleteModal(true)}
          />
          <ConfirmModal
            onConfirm={async () => {
              await deleteInspection(inspection.id);
              onRefresh();
              setShowDeleteModal(false);
            }}
            onHide={() => setShowDeleteModal(false)}
            show={showDeleteModal}
            title="Delete Inspection"
          >
            <h4>Are you sure you want to delete this Inspection?</h4>
            <h4>
              This can not be undone, and all related data will also be deleted.
            </h4>
          </ConfirmModal>
        </>
      ) : null}
      {showEdit ? (
        <>
          <EditActionButton onClick={() => setShowEditActionModal(true)} />
          {showEditActionModal ? (
            <FlatModal
              closeButton
              onHide={() => setShowEditActionModal(false)}
              show
              title="Assign Action"
              width={500}
            >
              <ActionAssigner
                getData={onRefresh}
                inspection={inspection}
                onHide={() => setShowEditActionModal(false)}
                populateDefaultData
                shouldNavigateOnSave
                user={user}
              />
            </FlatModal>
          ) : null}
        </>
      ) : null}
      {showPrepare ? (
        <InspectionReportButton
          disabled={!hasInspectionWriteAccess}
          onClick={() => navigate(`/inspection/edit/${inspection.id}`)}
          started={hasSections}
        />
      ) : null}
      {!archive &&
        (((permission.level.includes("organisation") ||
          inspection.UserId === UserId) &&
          preparedAt) ||
          preparedAt) && (
          <InspectionExportPDFButton
            disabled={!preparedAt && !hasSections}
            isDownloading={isReportDownloading}
            onClick={async () => {
              onStart(inspection.id);
              await onExportInspectionPDF(inspection);
              onEnd(inspection.id);
            }}
          />
        )}
      {!condensed && archivedAt ? (
        <InspectionRestoreButton
          disabled={
            !(
              inspection.UserId === UserId ||
              permission.level === "organisationAdmin" ||
              permission.level === "clientAdmin"
            )
          }
          onClick={onArchiveInspection}
        />
      ) : null}
      {showView && (
        <InspectionViewButton
          onClick={() => navigate(`/inspections/view/${inspection.id}`)}
        />
      )}
      {showShare && <ShareInspectionButton InspectionId={inspection.id} />}
      {showArchive ? (
        <ArchiveButton
          disabled={
            !(
              inspection.UserId === UserId ||
              permission.level === "organisationAdmin" ||
              permission.level === "clientAdmin"
            )
          }
          onClick={onArchiveInspection}
        />
      ) : null}
      {!completedAt && (
        <>
          <ViewBlueprintButton
            disabled={!inspection.hasInspectionWriteAccess}
            href={`/inspections/blueprint/${inspection.id}`}
          />
          <InspectionContinueButton
            disabled={!inspection.hasInspectionWriteAccess}
            onClick={() => navigate(`/inspections/view/${inspection.id}`)}
          />
        </>
      )}
    </ActionButtonsOverlay>
  );
}

InspectionActionButtons.propTypes = {
  UserId: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
};
