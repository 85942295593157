import { Col, Grid, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { useClient } from "hooks/client";
import { parseAddress } from "libs/parseAddress";

import LinkButtonWide from "views/components/Buttons/link-button-wide";
import ClientLogo from "views/components/ClientLogo";
import PageWrapper from "views/components/common/PageWrapper";
import Loading from "views/components/Loading";
import ActionsTable from "views/components/MiniTables/ActionsTable";
import InspectionsTable from "views/components/MiniTables/InspectionsTable";
import SchedulesTable from "views/components/MiniTables/SchedulesTable";
import SitesTable from "views/components/MiniTables/SitesTable";

import InfoIcons from "./components/info-icons";
import { ClientHeadingPanel, ClientTitle, ContactLine } from "./styles";

export default function ClientPage() {
  const { ClientId } = useParams();
  const { client, isLoading } = useClient(ClientId);
  if (isLoading || !client) {
    return <Loading height="calc(100vh - 50px)" />;
  }
  return (
    <PageWrapper>
      <Helmet>
        <title>Econform / {client?.name} / Overview</title>
      </Helmet>

      <Grid fluid>
        <ClientHeadingPanel>
          <Col sm={6}>
            <Col sm={4}>
              <ClientLogo size="xl" src={client.logoUrl} />
            </Col>
            <Col sm={8}>
              <ClientTitle>{client.name}</ClientTitle>
              <ContactLine>{parseAddress(client)}</ContactLine>
              <ContactLine>
                <span>Contact Name:</span>
                {client.contactName}
              </ContactLine>
              <ContactLine>
                <span>Contact Number:</span>
                {client.contactNumber}
              </ContactLine>
              <ContactLine>
                <span>Contact Email:</span>
                {client.contactEmail}
              </ContactLine>
              {client.hasAdminPermission && (
                <LinkButtonWide primary to={`/clients/${ClientId}/edit`}>
                  Edit Details
                </LinkButtonWide>
              )}
            </Col>
          </Col>
          <Col sm={6}>
            <InfoIcons ClientId={ClientId} />
          </Col>
        </ClientHeadingPanel>
        <Row>
          <Col sm={12}>
            <Row>
              <Col sm={6} xs={12}>
                <SitesTable ClientId={ClientId} />
              </Col>
              <Col sm={6} xs={12}>
                <InspectionsTable ClientId={ClientId} />
              </Col>
            </Row>
          </Col>
          <Col sm={12}>
            <Row>
              <Col sm={6} xs={12}>
                <SchedulesTable ClientId={ClientId} />
              </Col>
              <Col sm={6} xs={12}>
                <ActionsTable
                  ClientId={ClientId}
                  filters={{ allActions: true, showGreen: false }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </PageWrapper>
  );
}
