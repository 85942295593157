/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Col, Panel, FormGroup, ControlLabel, Checkbox } from "react-bootstrap";

import { getClientsGroups } from "libs/api";
import EditorPanel from "views/components/EditorPanel";

import ClientSelector from "./ClientSelector";
import { USER_PERMISSION_TYPES } from "./config";
import PermissionChanges from "./PermissionChanges";
import SiteGroupSelector from "./SiteGroupSelector";
import SiteSelector from "./SiteSelector";

import { RoleName, RoleDescription } from "./styles";

/**
 * Used to determine which selection panel to display where the user has not
 * yet selected an individual site or group. i.e. they haven't given themselves
 * any of the permissions yet, which would by default set the panel to display.
 */
const SHOW_OVERRIDE = {
  NONE: "NONE",
  GROUP: "GROUP",
  SITE: "SITE",
};

function ClientPermissions({
  addedPermissions = [],
  canEditUser,
  clearPermissions,
  client,
  ClientId,
  clients = [],
  curUser,
  deletedPermissions = [],
  onAddPermissions,
  onChangeClient,
  onChangeSearchText,
  onClearClient,
  onDeletePermissions,
  onToggleSiteGroups,
  permissions,
  searchText,
  siteGroups = [],
  sites = [],
  userId,
  userPermissionType,
  saving,
}) {
  const isClientUser = userPermissionType === USER_PERMISSION_TYPES.USER;
  const isClientAdmin = userPermissionType === USER_PERMISSION_TYPES.ADMIN;
  const isSiteGroupUser =
    userPermissionType === USER_PERMISSION_TYPES.SITEGROUP;
  const isSiteUser = userPermissionType === USER_PERMISSION_TYPES.SITE;

  const [showPanel, setShowPanel] = useState(SHOW_OVERRIDE.NONE);

  useEffect(() => {
    setShowPanel(SHOW_OVERRIDE.NONE);
  }, [saving]);

  return (
    <EditorPanel>
      <Panel.Heading>Client and Site Permissions</Panel.Heading>
      <Panel.Body>
        <Col sm={12}>
          {permissions ? (
            <ClientSelector
              clientId={client?.id}
              clients={clients}
              disabled={addedPermissions.length || deletedPermissions.length}
              onChangeClient={onChangeClient}
              onChangeSearchText={onChangeSearchText}
              onClearClient={!userId ? onClearClient : null}
              searchText={searchText}
            />
          ) : null}
        </Col>
        {client && (
          <Col sm={12}>
            <Col sm={6}>
              <ControlLabel>
                Client Permissions
                <span className="required">*</span>
              </ControlLabel>
              <Checkbox
                checked={isClientAdmin}
                disabled={
                  !canEditUser &&
                  !curUser?.permissions.clientAdmin.includes(ClientId) &&
                  userId
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    onDeletePermissions(userId, "client", [client.id]);
                    onDeletePermissions(userId, 'site', sites.map((site) => site.id)); // prettier-ignore
                    onDeletePermissions(userId, 'siteGroup', siteGroups.map((group) => group.id)); // prettier-ignore
                    onAddPermissions(userId, "clientAdmin", [client.id]);
                  } else {
                    onDeletePermissions(userId, "clientAdmin", [client.id]);
                  }
                }}
              >
                <RoleName>Admin User</RoleName>
                <RoleDescription>
                  Can edit sites, add new sites and add users to the client.
                </RoleDescription>
              </Checkbox>
              <Checkbox
                checked={isClientUser}
                disabled={!canEditUser && userId}
                onChange={(e) => {
                  if (e.target.checked) {
                    onDeletePermissions(userId, "clientAdmin", [client.id]);
                    onDeletePermissions(userId, 'site', sites.map((site) => site.id)); // prettier-ignore
                    onDeletePermissions(userId, 'siteGroup', siteGroups.map((group) => group.id)); // prettier-ignore
                    onAddPermissions(userId, "client", [client.id]);
                  } else {
                    onDeletePermissions(userId, "client", [client.id]);
                  }
                }}
              >
                <RoleName>Management User</RoleName>
                <RoleDescription>
                  Can create and modify any inspection created for this client.
                </RoleDescription>
              </Checkbox>
              <Checkbox
                checked={
                  (showPanel === SHOW_OVERRIDE.GROUP || isSiteGroupUser) &&
                  !isClientAdmin &&
                  !isClientUser &&
                  !isSiteUser
                }
                disabled={!siteGroups.length || (userId && !canEditUser)}
                onChange={async (e) => {
                  if (e.target.checked) {
                    setShowPanel(SHOW_OVERRIDE.GROUP);
                    onDeletePermissions(userId, "client", [client.id]);
                    onDeletePermissions(userId, "clientAdmin", [client.id]);
                    onDeletePermissions(userId, 'site', sites.map((site) => site.id)); // prettier-ignore
                  } else {
                    setShowPanel(SHOW_OVERRIDE.NONE);
                    onDeletePermissions(userId, 'siteGroup', siteGroups.map((g) => g.id)); // prettier-ignore
                  }
                  const newSiteGroups = await getClientsGroups(client.id);
                  onToggleSiteGroups(newSiteGroups);
                }}
              >
                <RoleName>Group User</RoleName>
                <RoleDescription>
                  Can create and modify any inspection created for the sites in
                  their specified group.
                </RoleDescription>
              </Checkbox>
              <Checkbox
                checked={
                  (showPanel === SHOW_OVERRIDE.SITE || isSiteUser) &&
                  !isClientAdmin &&
                  !isClientUser &&
                  !isSiteGroupUser
                }
                disabled={!sites.length || (userId && !canEditUser)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setShowPanel(SHOW_OVERRIDE.SITE);
                    onDeletePermissions(userId, "client", [client.id]);
                    onDeletePermissions(userId, "clientAdmin", [client.id]);
                    onDeletePermissions(userId, 'siteGroup', siteGroups.map((group) => group.id)); // prettier-ignore
                  } else {
                    setShowPanel(SHOW_OVERRIDE.NONE);
                    onDeletePermissions(userId, 'site', sites.map((site) => site.id)); // prettier-ignore
                  }
                }}
              >
                <RoleName>Site User</RoleName>
                <RoleDescription>
                  Can create and modify any inspection created for any of their
                  specified sites.
                </RoleDescription>
              </Checkbox>
            </Col>
            {(isSiteUser || showPanel === SHOW_OVERRIDE.SITE) && (
              <Col sm={6}>
                <FormGroup controlId="formControlsSelectMultiple">
                  <ControlLabel>Individual Sites</ControlLabel>
                  <SiteSelector
                    onAddPermissions={onAddPermissions}
                    onDeletePermissions={onDeletePermissions}
                    permissions={permissions}
                    sites={sites}
                    userId={userId}
                  />
                </FormGroup>
              </Col>
            )}
            {(isSiteGroupUser || showPanel === SHOW_OVERRIDE.GROUP) && (
              <Col sm={6}>
                <FormGroup controlId="formControlsSelectMultiple">
                  <ControlLabel>Individual Groups</ControlLabel>
                  <SiteGroupSelector
                    onAddPermissions={onAddPermissions}
                    onDeletePermissions={onDeletePermissions}
                    permissions={permissions}
                    siteGroups={siteGroups}
                    userId={userId}
                  />
                </FormGroup>
              </Col>
            )}
          </Col>
        )}
        <PermissionChanges
          addedPermissions={addedPermissions}
          clearPermissions={() => {
            setShowPanel(SHOW_OVERRIDE.NONE);
            clearPermissions();
          }}
          deletedPermissions={deletedPermissions}
          userId={userId}
        />
      </Panel.Body>
    </EditorPanel>
  );
}

ClientPermissions.propTypes = {
  userId: PropTypes.string,
  canEditUser: PropTypes.bool,
  saving: PropTypes.bool,
  permissions: PropTypes.shape({}),
  client: PropTypes.shape({
    id: PropTypes.string,
  }),
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  addedPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      UserId: PropTypes.string,
      relationId: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  deletedPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      UserId: PropTypes.string,
      relationId: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  userPermissionType: PropTypes.oneOf(Object.values(USER_PERMISSION_TYPES)),
  searchText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  sites: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  siteGroups: PropTypes.array,
  ClientId: PropTypes.string,
  curUser: PropTypes.shape({
    id: PropTypes.string,
    permissions: PropTypes.shape({
      clientAdmin: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  onChangeSearchText: PropTypes.func,
  onClearClient: PropTypes.func,
  onChangeClient: PropTypes.func,
  clearPermissions: PropTypes.func,
  onAddPermissions: PropTypes.func,
  onDeletePermissions: PropTypes.func,
  onToggleSiteGroups: PropTypes.func,
};

export default ClientPermissions;
