import moment from 'moment';

export const getScheduleTypeLabel = (type) => {
  const labels = {
    daily: 'Daily',
    weekdaily: 'Every Weekday',
    weekly: 'Weekly',
    fortnightly: 'Fortnightly',
    monthly: 'Monthly',
    yearly: 'Yearly',
  };

  return labels[type];
};

/**
 * Note: this doesn't check the current datetime, it just provides the next
 * due from the startAt given date.
 */
export const getNextScheduleDate = (type, startAt) => {
  const date = moment(startAt);
  switch (type) {
    case 'yearly':
      return date.add(1, 'years').toDate();
    case 'monthly':
      return date.add(1, 'months').toDate();
    case 'fortnightly':
      return date.add(2, 'weeks').toDate();
    case 'weekly':
      return date.add(1, 'weeks').toDate();
    case 'weekdaily':
      if (date.day() === 6) {
        return date.add(2, 'days').toDate();
      }
      if (date.day() === 5) {
        return date.add(3, 'days').toDate();
      }
      return date.add(1, 'days').toDate();
    case 'daily':
      return date.add(1, 'days').toDate();
    default:
      throw Error('Schedule type not recognised');
  }
};
