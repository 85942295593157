import PropTypes from "prop-types";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUser } from "hooks/user";
import { userLogIn } from "libs/api";

export default function LoginForm({
  setLoading,
  loading,
  setEmail,
  setPassword,
  email,
  password,
  setError,
  error,
  setMode,
}) {
  const navigate = useNavigate();
  const { mutate: refreshUser } = useCurrentUser();

  const onLogIn = useCallback(async () => {
    setLoading("logging-in");
    try {
      await userLogIn(email, password);
      refreshUser();
      navigate("/");
    } catch (e) {
      setLoading("");
      setError("Incorrect email or password");
    }
  }, [setLoading, email, password, navigate, refreshUser, setError]);

  return (
    <>
      <div className="form-group">
        <input
          className="form-control"
          key="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          type="text"
        />
      </div>
      <div className="form-group">
        <input
          className="form-control"
          key="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          type="password"
        />
        <div>
          <p className="error-message">{error}</p>
        </div>
        <p
          className="text-grey-dark text-xs"
          style={{ color: "#7d8497", marginTop: 15, marginBottom: 25 }}
        >
          Forgot your password?{" "}
          <a
            className="btn-text text-indigo cursor-pointer hover:text-indigo-darker"
            onClick={() => setMode("requestCode")}
          >
            Reset Password
          </a>
        </p>
      </div>
      <div className="flex items-center justify-between">
        <button
          className="btn btn-primary btn-block"
          disabled={loading === "logging-in"}
          onClick={() => onLogIn()}
          type="button"
        >
          {loading === "logging-in" ? "Signing In..." : "Sign In"}
        </button>
      </div>
    </>
  );
}

LoginForm.propTypes = {
  email: PropTypes.string,
  error: PropTypes.string,
  password: PropTypes.string,
  setError: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  loading: PropTypes.string,
};
